export const facilitySamples = [
  {
    id: "1614",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "3PL Warehouse",
      site_code: "WHKPCA",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "119",
    type: "facilities",
    attributes: {
      campus_id: 1,
      health_system_id: 53,
      name: "Anaheim Medical Center",
      site_code: "AMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "121",
    type: "facilities",
    attributes: {
      campus_id: 1,
      health_system_id: 53,
      name: "Anaheim Surgery Center",
      site_code: "ASC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "142",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Antelope Valley",
      site_code: "AV",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1659",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Antelope Valley Medical Office",
      site_code: "AVMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "143",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Antelope Valley Surgery Center",
      site_code: "AVS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "144",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Av Womens Infant Pavilion",
      site_code: "AVW",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "145",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Bakersfield Spec Center Contracted Loc",
      site_code: "BSC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "165",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Baldwin Hills Crenshaw Medical Office Building",
      site_code: "BAL",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "127",
    type: "facilities",
    attributes: {
      campus_id: 12,
      health_system_id: 53,
      name: "Baldwin Park Medical Center",
      site_code: "BP",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "190",
    type: "facilities",
    attributes: {
      campus_id: 12,
      health_system_id: 53,
      name: "Baldwin Park Medical Offices",
      site_code: "BP2",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "163",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Barcelona",
      site_code: "BAR",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "184",
    type: "facilities",
    attributes: {
      campus_id: 2,
      health_system_id: 53,
      name: "Barranca Medical Office",
      site_code: "BME",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "132",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Bellflower Medical Offices and Surgery Center",
      site_code: "BMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1636",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Berkley Warehouse",
      site_code: "DVRBWH",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1635",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "CalMed Ambulatory Surgery Center",
      site_code: "CMASU",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "183",
    type: "facilities",
    attributes: {
      campus_id: 15,
      health_system_id: 53,
      name: "Carson Medical Offices ASU",
      site_code: "CARASU",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1649",
    type: "facilities",
    attributes: {
      campus_id: 15,
      health_system_id: 53,
      name: "Carson Medical Offices MOB",
      site_code: "CARMOB",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "146",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Channel Islands SX Location Oxnard",
      site_code: "CI",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "193",
    type: "facilities",
    attributes: {
      campus_id: 13,
      health_system_id: 53,
      name: "Chester Medical Office Building",
      site_code: "CHS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "147",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Community Memorial Hosp Contract Loc",
      site_code: "CMH",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "166",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Corona Medical Offices",
      site_code: "CMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "123",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Corona Summit Surgical Center",
      site_code: "CS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "194",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Dalen Warehouse",
      site_code: "DW",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "124",
    type: "facilities",
    attributes: {
      campus_id: 6,
      health_system_id: 53,
      name: "Downey Medical Center",
      site_code: "DMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "182",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Electronics Warehouse",
      site_code: "KPW",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1690",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Exclusive Express Warehouse",
      site_code: "EEW",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "114",
    type: "facilities",
    attributes: {
      campus_id: 9,
      health_system_id: 53,
      name: "Fontana Medical Center",
      site_code: "FMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1670",
    type: "facilities",
    attributes: {
      campus_id: 9,
      health_system_id: 53,
      name: "Fontana Medical Offices 1",
      site_code: "FM1",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "187",
    type: "facilities",
    attributes: {
      campus_id: 9,
      health_system_id: 53,
      name: "Fontana Medical Offices 3",
      site_code: "FM3",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "171",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "FREEDOM VISION SURG CTR CONTRAC LOC",
      site_code: "FV",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1650",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Garden Grove Medical Offices",
      site_code: "GGMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "133",
    type: "facilities",
    attributes: {
      campus_id: 6,
      health_system_id: 53,
      name: "Garden Medical Offices",
      site_code: "GMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "140",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Garfield Specialty Center",
      site_code: "GSC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "148",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Henry Mayo Surgical Center Contract",
      site_code: "HMS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "118",
    type: "facilities",
    attributes: {
      campus_id: 2,
      health_system_id: 53,
      name: "Irvine Medical Center",
      site_code: "IMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "122",
    type: "facilities",
    attributes: {
      campus_id: 2,
      health_system_id: 53,
      name: "Irvine Surgery Center",
      site_code: "ISC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "172",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Irwindale Medical Offices",
      site_code: "IMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1699",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Medical School",
      site_code: "KMS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1576",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Antioch Medical Center",
      site_code: "DVRAMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1577",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Fremont Medical Center",
      site_code: "DVRFMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1578",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Fresno Medical Center",
      site_code: "DVRFRM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1631",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Livermore",
      site_code: "DVRKPL",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1579",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Manteca Medical Center",
      site_code: "DVRMMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1580",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Medical Center",
      site_code: "DVRMC",
      time_zone: "Pacific/Honolulu",
    },
  },
  {
    id: "1581",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Oakland Medical Center",
      site_code: "DVROMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1582",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Redwood City Medical Center",
      site_code: "DVRRCM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1583",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Roseville Medical Center",
      site_code: "DVRRMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1584",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Sacramento Medical Center",
      site_code: "DVRSMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1585",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente San Francisco Medical Center",
      site_code: "DVRSFM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1586",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente San Jose Medical Center",
      site_code: "DVRSJM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1587",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente San Leandro Medical Center",
      site_code: "DVRSLM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1588",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente San Rafael Medical Center",
      site_code: "DVRSRA",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1589",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Santa Clara Medical Center",
      site_code: "DVRSCM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1590",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Santa Rosa Medical Center",
      site_code: "DVRSRO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1591",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente South Sacramento Medical Center",
      site_code: "DVRSSM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1592",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente South San Francisco",
      site_code: "DVRSSF",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1593",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Vacaville Medical Center",
      site_code: "DVRVMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1594",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Vallejo Medical Center",
      site_code: "DVRVM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1595",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Permanente Walnut Creek Medical Center",
      site_code: "DVRWMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1596",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Sunnyside Medical Center",
      site_code: "DVRSUN",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1597",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kaiser Westside Medical Center",
      site_code: "DVRWSM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "176",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "KP Exchange",
      site_code: "KPX",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "186",
    type: "facilities",
    attributes: {
      campus_id: 1,
      health_system_id: 53,
      name: "Kraemer Medical Offices",
      site_code: "KMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1598",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Kula Hospital",
      site_code: "DVRKH",
      time_zone: "Pacific/Honolulu",
    },
  },
  {
    id: "179",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "La Cienega Vision Essentials",
      site_code: "LVE",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1599",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Lanai Community Hospital",
      site_code: "DVRLCH",
      time_zone: "Pacific/Honolulu",
    },
  },
  {
    id: "167",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Loma Linda University Medical Center",
      site_code: "LLU",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "129",
    type: "facilities",
    attributes: {
      campus_id: 14,
      health_system_id: 53,
      name: "Los Angeles Medical Center",
      site_code: "LA",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "136",
    type: "facilities",
    attributes: {
      campus_id: 14,
      health_system_id: 53,
      name: "Los Angeles Surgery Center",
      site_code: "LAS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1600",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Maui Memorial Medical Center",
      site_code: "DVRMMM",
      time_zone: "Pacific/Honolulu",
    },
  },
  {
    id: "164",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Mission Trails Warehouse",
      site_code: "MTW",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "117",
    type: "facilities",
    attributes: {
      campus_id: 4,
      health_system_id: 53,
      name: "Moreno Valley Medical Center",
      site_code: "MVM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "141",
    type: "facilities",
    attributes: {
      campus_id: 4,
      health_system_id: 53,
      name: "Moreno Valley Medical Center Medical Office Building",
      site_code: "MVO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "162",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Murrieta Medical Center",
      site_code: "MUR",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "149",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Oasis Surgery Center",
      site_code: "OSC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "115",
    type: "facilities",
    attributes: {
      campus_id: 3,
      health_system_id: 53,
      name: "Ontario Medical Center",
      site_code: "OMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1655",
    type: "facilities",
    attributes: {
      campus_id: 3,
      health_system_id: 53,
      name: "Ontario Medical Office D",
      site_code: "OMD",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "116",
    type: "facilities",
    attributes: {
      campus_id: 3,
      health_system_id: 53,
      name: "Ontario Vineyard Ambulatory Surgical Center",
      site_code: "OV",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "174",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Orange Health Pavilion",
      site_code: "OHP",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "175",
    type: "facilities",
    attributes: {
      campus_id: 6,
      health_system_id: 53,
      name: "Orchard Medical Offices",
      site_code: "ORC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "139",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Otay Mesa Medical Offices",
      site_code: "OM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "150",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Palmdale Regional Medical Center",
      site_code: "PRM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "134",
    type: "facilities",
    attributes: {
      campus_id: 11,
      health_system_id: 53,
      name: "Panorama City Medical Center",
      site_code: "PC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1660",
    type: "facilities",
    attributes: {
      campus_id: 11,
      health_system_id: 53,
      name: "Panorama City Medical Offices 2",
      site_code: "PC2",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "189",
    type: "facilities",
    attributes: {
      campus_id: 11,
      health_system_id: 53,
      name: "Panorama City Medical Offices 4",
      site_code: "PC4",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "185",
    type: "facilities",
    attributes: {
      campus_id: 8,
      health_system_id: 53,
      name: "Parkview Medical Office",
      site_code: "PRK",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1672",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Pasadena Medical Offices",
      site_code: "PMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1572",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Physicians' Surgery Center",
      site_code: "PSC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1601",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Rialto Under Armour Warehouse",
      site_code: "DVRRAU",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "151",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Riverlakes Surgical Center",
      site_code: "RSM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "113",
    type: "facilities",
    attributes: {
      campus_id: 10,
      health_system_id: 53,
      name: "Riverside Medical Center",
      site_code: "RMC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "188",
    type: "facilities",
    attributes: {
      campus_id: 10,
      health_system_id: 53,
      name: "Riverside Medical Offices  2",
      site_code: "RM3",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "137",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "San Diego Medical Center",
      site_code: "SD",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "152",
    type: "facilities",
    attributes: {
      campus_id: 13,
      health_system_id: 53,
      name: "San Joaquin Community Hospital OR",
      site_code: "SJC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "153",
    type: "facilities",
    attributes: {
      campus_id: 13,
      health_system_id: 53,
      name: "San Joaquin Outpatient Surgery Ctr",
      site_code: "SJS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "138",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "San Marcos Medical Offices",
      site_code: "SM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "168",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Santa Clarita Medical Office Building",
      site_code: "SCM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "126",
    type: "facilities",
    attributes: {
      campus_id: 8,
      health_system_id: 53,
      name: "South Bay Medical Center",
      site_code: "SBM",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "154",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Summit Surgical Center",
      site_code: "SSC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "173",
    type: "facilities",
    attributes: {
      campus_id: 14,
      health_system_id: 53,
      name: "Sunset Medical Offices",
      site_code: "SMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "128",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Temecula Valley Day Surgery Center",
      site_code: "TV",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1573",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "TORRANCE SURGERY CENTER",
      site_code: "TSC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1571",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "TOURNEY PLAZA SURGICAL CENTER\t",
      site_code: "TPSC",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "130",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Tustin Ranch Medical Offices",
      site_code: "TR",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "155",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "United Medical Endoscopy Center",
      site_code: "UNE",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1602",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "UPS Supply Chain - Moreno Valley",
      site_code: "DVRUMV",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1574",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "UPS Supply Chain - Tracy",
      site_code: "DVRUT",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "156",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Ventura Endoscopy Location",
      site_code: "VE",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "1346",
    type: "facilities",
    attributes: {
      campus_id: 14,
      health_system_id: 53,
      name: "Vermont Medical Office",
      site_code: "VMO",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "195",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Vyaire Medical Inc.",
      site_code: "VMI",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "157",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "West Hills Hospital Contract",
      site_code: "WHH",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "120",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "West Los Angeles Medical Center",
      site_code: "WLA",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "135",
    type: "facilities",
    attributes: {
      campus_id: 5,
      health_system_id: 53,
      name: "Woodland Hills Medical Center",
      site_code: "WH",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "158",
    type: "facilities",
    attributes: {
      campus_id: 5,
      health_system_id: 53,
      name: "Woodland Hills Surgery Center",
      site_code: "WHS",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "131",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Yorba Linda Medical Offices",
      site_code: "YL",
      time_zone: "America/Los_Angeles",
    },
  },
  {
    id: "125",
    type: "facilities",
    attributes: {
      campus_id: null,
      health_system_id: 53,
      name: "Zion Medical Center",
      site_code: "ZMC",
      time_zone: "America/Los_Angeles",
    },
  },
];
