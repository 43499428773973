import styled from "styled-components";
import { grey2, grey3, grey8 } from "../../../utils/base";

export const AAddMore = styled.a`
  font-size: 12px;

  &.disabled {
    color: ${grey3};
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: ${grey3};
    }
  }
`;

export const CellGroup = styled.div`
  height: 100%

  &.procedures, &.physicians {
    width: 35%;
    .remove-physician-button {
      padding-right: 15px;
    }
  }

  .badge-and {
    &:before {
      position: relative;
      left: 50%;
      top: 50%;
      display: block;
      margin: 3px 0 0 -12px;
      padding-top: 5px;
      width: 24px;
      height: 16px;
      background: ${grey8};
      content: 'and';
      text-align: center;
      font-size: 11px;
      line-height: 10px;
      color: #fff;
      border-radius: 4px;
      z-index: 5;
    }
  h3 & {
    margin-top: 0;
  }
`;

export const Li = styled.li`
  position: relative;
  margin: 0px 0px 10px -12px;
  padding-left: 35px;

  .minus {
    display: none;
    position: absolute;
    right: 0px;
    top: 8px;
  }
  &:hover {
    .minus {
      display: block;
    }
  }

  .plus {
    padding-right: 40px;
  }

  &:after {
    position: absolute;
    left: 11px;
    top: 16px;
    width: 19px;
    height: 1px;
    background: ${grey3};
    content: "";
    z-index: 4;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  &.first-item {
    &:before {
      display: none;
    }
  }

  &.select-field-placeholder {
    line-height: 35px;
  }

  .plus {
    padding-right: 24px;
  }
`;

export const DivLabel = styled.div`
  padding-left: 5px;
  padding-top: 7px;
`;

export const UlSelectFields = styled.ul`
  margin: 0 0 10px 12px;
  list-style: none;
  border-left: 1px solid ${grey3};

  .fields:nth-of-type(n + 2) {
    span {
      &.or {
        &:before {
          background: ${grey2};
          border-radius: 4px;
          color: ${grey8};
          content: "or";
          font-size: 11px;
          height: 14px;
          left: 0;
          line-height: 10px;
          padding-top: 4px;
          position: absolute;
          text-align: center;
          top: 9px;
          width: 22px;
          z-index: 5;
        }
      }
      &.and {
        &:before {
          background: ${grey2};
          border-radius: 4px;
          color: ${grey8};
          content: "and";
          font-size: 11px;
          height: 14px;
          left: 0;
          line-height: 10px;
          padding-top: 4px;
          position: absolute;
          text-align: center;
          top: 9px;
          width: 24px;
          z-index: 5;
        }
      }
    }
  }
`;
