import React, { useState } from "react";
import styled from "styled-components";

export default function UglyComponent(props) {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <StyledUglyComponent
      className="ugly-component"
      collapsed={collapsed}
      extraPadding={props.extraPadding}
      onClick={collapsed ? toggleCollapsed : null}
    >
      {collapsed && props.title}

      <StyledControl collapsed={collapsed}>
        <i
          className="icon-chevron-down collapsible-component"
          onClick={collapsed ? null : toggleCollapsed}
        />
      </StyledControl>
      {collapsed ? (
        <div className="hidden">{props.children}</div>
      ) : (
        props.children
      )}
    </StyledUglyComponent>
  );
}

const StyledUglyComponent = styled.div`
  font-size: 12px;
  ${(props) => {
    return props.collapsed
      ? `
        cursor: pointer;
        padding-bottom: 10px;
        padding-left: 25px;
        padding-right: 14px;
        padding-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
      `
      : `
        padding-left: 30px;
        padding-right: 19px;
        padding-bottom: 12px;
      `;
  }}
  ${(props) => props.extraPadding && "margin-bottom: 12px;"}

  &:hover {
    .collapsible-component {
      color: #9d9d9d;
    }
  }

  &:not(:last-child) {
    border-bottom: solid 1px #d4d4d4;
  }

  &:not(:first-child) {
    margin-top: 12px;
  }

  label {
    cursor: default;
  }
`;

const StyledControl = styled.div`
  position: relative;
  i {
    color: #dbdbdb;
    float: right;
    padding: 9px;
    position: absolute;
    right: -10px;
    top: ${(props) => (props.collapsed ? "-25" : "-11")}px;
    ${(props) => props.collapsed && "transform: rotate(270deg);"}

    &:hover {
      cursor: pointer;
    }
  }
`;
