import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Select, { components, createFilter } from "react-select-3";

import { DEFAULT_SELECT_STYLES } from "./select-styles";
import {
  getQuery,
  useQueryParam,
  EQM_K,
  II_K,
} from "../utils/use_query_params";
import UglyComponent from "./ugly_component";

const hasQueryParamsDisconnect = (queryParam, propVal) =>
  queryParam && !propVal;

export default function Equipment({ data, onChange, selected }) {
  return (
    <UglyComponent title="Equipment">
      <label>Equipment:</label>
      <EquipmentMasterSelect
        healthSystemId={data.healthSystemId}
        onChange={onChange}
        options={data.equipmentMasters}
        value={selected.equipmentMaster}
      />
      <label>Inventory:</label>
      <InventoryItemSelect
        equipmentMaster={selected.equipmentMaster}
        healthSystemId={data.healthSystemId}
        onChange={onChange}
        options={data.inventoryItems}
        value={selected.inventoryItems}
      />
    </UglyComponent>
  );
}

// react-select speed improvements
// - filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
// https://github.com/JedWatson/react-select/issues/3128
// Also, there is virtualization search for larger datasets >10000

function EquipmentMasterSelect({ healthSystemId, onChange, options, value }) {
  const [queryStr, setQueryString] = useQueryParam(EQM_K, "");

  let tiedValue = null;
  const tiedOptions = options.filter((opt) => {
    if (healthSystemId && !opt.data.health_system_ids.includes(healthSystemId))
      return false;
    if (value && opt.value == value.value) tiedValue = value;
    return true;
  });

  useEffect(() => {
    if (hasQueryParamsDisconnect(queryStr, value)) setQueryString(null);
  });

  return (
    <Select
      className="filter-input"
      filterOption={createFilter({ ignoreAccents: false })}
      id="equipment-master-id"
      isClearable
      noOptionsMessage={() => "No options for equipment master"}
      onChange={(change) => {
        setQueryString(change);
        onChange("equipmentMaster", change);
      }}
      options={tiedOptions}
      placeholder=""
      styles={DEFAULT_SELECT_STYLES}
      value={tiedValue}
    />
  );
}

function InventoryItemSelect({
  equipmentMaster,
  healthSystemId,
  onChange,
  options,
  value,
}) {
  const [queryStr, setQueryString] = useQueryParam(II_K, "");

  let tiedValue = null;
  const selectedIds = value?.map((opt) => opt.value);
  const tiedOptions = equipmentMaster
    ? options.filter((opt) => {
        if (opt.data.equipment_master_id != equipmentMaster.value) return false;
        if (healthSystemId && healthSystemId != opt.data.health_system_id)
          return false;
        if (selectedIds?.includes(opt.value)) {
          tiedValue = tiedValue || [];
          tiedValue.push(opt);
        }
        return true;
      })
    : [];

  useEffect(() => {
    const query = getQuery();
    const queryIIIds = query.get(II_K);

    if (
      hasQueryParamsDisconnect(queryStr, value) ||
      hasQueryParamsDisconnect(queryIIIds, value)
    ) {
      setQueryString(null);
    }
  });

  return (
    <Select
      className="filter-input"
      closeMenuOnSelect={false}
      components={{
        ClearIndicator: () => null,
        Option: InventoryOption,
        MultiValueLabel: InventoryMultiValueLabel,
      }}
      filterOption={createFilter({ ignoreAccents: false })}
      id="inventory-item-id"
      isMulti
      isClearable
      noOptionsMessage={() => "No options for inventory"}
      onChange={(change) => {
        setQueryString(change);
        onChange("inventoryItems", change);
      }}
      options={tiedOptions}
      placeholder=""
      styles={inventoryStyles}
      value={tiedValue}
    />
  );
}

const inventoryStyles = {
  ...DEFAULT_SELECT_STYLES,
  multiValue: (provided, state) => ({
    ...provided,
    position: "relative",
    width: "100%",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: "11px",
    overflow: "inherit",
    textOverflow: "inherit",
    whiteSpace: "inherit",

    ".collab-purch-icon": {
      marginLeft: "2px",
      color: "#666",
    },
    ".not-shareable-icon": {
      marginLeft: "2px",
      marginBottom: "2px",
    },
    ".sprite-inactive-s-grey": {
      marginLeft: "2px",
      marginBottom: "2px",
    },
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    height: "100%",
    position: "absolute",
    right: "0px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "auto",
    padding: "0px",
  }),
};

const IconNotShareable = () => (
  <i className="not-shareable-icon sprite-icon sprite-not-shareable-xs-grey" />
);

const IconCollabPurchased = () => (
  <i className="collab-purch-icon icon icon-star" />
);

const IconInactive = () => <i className="sprite-icon sprite-inactive-s-grey" />;

const InventoryOption = (props) => {
  const {
    collaboratively_purchased,
    facility,
    rental_company,
    status,
    serial,
  } = props.data.data;
  if (rental_company) {
    return (
      <components.Option {...props}>
        <div>{rental_company}</div>
        <div>
          <span>(Rental)</span>
        </div>
      </components.Option>
    );
  } else {
    const serialLabel = `(SN: ${serial || "n/a"})`;
    return (
      <components.Option {...props}>
        <div>{facility}</div>
        <div>
          <span>{serialLabel}</span>
          {status === "not-shareable" && <IconNotShareable />}
          {collaboratively_purchased && <IconCollabPurchased />}
          {status === "inactive" && <IconInactive />}
        </div>
      </components.Option>
    );
  }
};

const InventoryMultiValueLabel = (props) => {
  const {
    collaboratively_purchased,
    rental_company,
    status,
    site_code,
    serial,
  } = props.data.data;

  if (rental_company) {
    return (
      <components.MultiValueLabel {...props}>
        <span>{rental_company} Rental</span>
      </components.MultiValueLabel>
    );
  } else {
    const serialLabel = ` (SN: ${serial || "n/a"})`;
    return (
      <components.MultiValueLabel {...props}>
        <span>{site_code + serialLabel}</span>
        {status === "not-shareable" && <IconNotShareable />}
        {collaboratively_purchased && <IconCollabPurchased />}
        {status === "inactive" && <IconInactive />}
      </components.MultiValueLabel>
    );
  }
};

// TODO: Proptypes
Equipment.propTypes = {};
