import React, { useContext } from "react";
import PropTypes from "prop-types";

import {
  findInventoryShareability,
  InventoryLabels,
  renderInventoryCodes,
  RentalIcon,
} from "./utils";
import { PillContent } from "./styled";
import { RequestContext } from "../../../../utils/capiClient";
import { EquipmentContext } from "../../useEquipmentContext";

export default function Show({
  components,
  selected,
  selected: {
    accessoryMasters,
    codes,
    collaborativelyPurchased,
    // disposableNotes, No disposables for prefs
    equipmentMaster,
    // fallback, No fallbacks for prefs
    homeFacility,
    status,
    url,
    usage,
    type,
  },
}) {
  const {
    params: { facilityId },
  } = useContext(RequestContext);
  const { inventoryShareability } = useContext(EquipmentContext);
  const isRental = type === "RentableInventory";

  const renderAccessories = () => {
    const key = Math.random() * 1000;

    return [
      <strong key={`accessories-label-${key}`}>Accessories:</strong>,
      isRental ? (
        <i key={`accessories-rental-${key}`}>
          {" "}
          Must specify with rental company{" "}
        </i>
      ) : (
        ` ${
          accessoryMasters
            ?.map((ac) => `${ac.name} (${ac.quantity})`)
            ?.join(", ") ?? "(0)"
        } `
      ),
    ];
  };

  // const renderDisposables = () =>
  //   isRental
  //     ? null
  //     : [
  //         <strong key={Math.random()}>Disposables:</strong>,
  //         ` ${disposableNotes || "No"} `,
  //       ];

  const isNotSharedWithFacility =
    !!facilityId &&
    !findInventoryShareability(selected, facilityId, inventoryShareability);

  return (
    <PillContent
      showError={isNotSharedWithFacility}
      className="selected-inventory-item expandable"
    >
      <div className="source-master row-fluid">
        <div className="master-info">
          <InventoryLink name={equipmentMaster.name} url={url} />
        </div>
        <span className="source-location">
          {isRental ? <RentalIcon /> : homeFacility?.siteCode}
        </span>
      </div>
      <div className="source-info" style={{ textAlign: "initial" }}>
        {!isRental && (
          <InventoryLabels
            collaborativelyPurchased={collaborativelyPurchased}
            homeSiteCode={homeFacility?.siteCode}
            status={status}
            usage={usage}
          />
        )}
        {codes && renderInventoryCodes(codes)}
      </div>
      <div className="source-items">
        <div className="source-meta">{renderAccessories()}</div>
        <SourceUsage {...usage} />
      </div>
      {components?.Controls()}
    </PillContent>
  );
}

// function Fallback({ name }) {
//   return (
//     <div className="source-items">
//       <div className="source-meta">
//         <i className="icon icon-mail-forward" />
//         <strong> Fallback for: </strong>
//         {name}
//       </div>
//     </div>
//   );
// }

function InventoryLink({ name, url }) {
  return (
    <a className="pill-link" target="_blank" href={url}>
      {name}
    </a>
  );
}

const SourceUsage = ({ duration, endOfCase, isModified, startTime }) => {
  let content;
  const isFullCaseUse = endOfCase && startTime == 0;
  if (isFullCaseUse) {
    content = "Entire case";
  } else {
    const displayFriendlyDuration = (dur) => {
      const absDuration = Math.abs(dur);
      const hours = Math.floor(absDuration / 60);
      const mins = absDuration % 60;

      return `${hours}h ${mins}m`;
    };
    const startTimeParsed = parseInt(startTime);
    content = displayFriendlyDuration(startTimeParsed);
    content +=
      startTimeParsed >= 0 ? " after case start, " : " before case start, ";
    content += duration
      ? `for ${displayFriendlyDuration(duration)}`
      : "until end of case";
  }

  return (
    <div className="in-use">
      <strong>{`${isModified ? "*" : ""}In use: `}</strong>
      {content}
    </div>
  );
};

Show.propTypes = {
  components: PropTypes.shape({
    Controls: PropTypes.func.isRequired,
  }),
  selected: PropTypes.shape({
    accessoryMasters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
      }).isRequired
    ),
    codes: PropTypes.shape({
      assetTag: PropTypes.string,
      cohealoTag: PropTypes.string,
      serial: PropTypes.string,
    }).isRequired,
    collaborativelyPurchased: PropTypes.bool,
    // disposableNotes, No disposables for prefs
    equipmentMaster: PropTypes.object.isRequired,
    homeFacility: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      siteCode: PropTypes.string,
    }).isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string,
    url: PropTypes.string.isRequired,
    usage: PropTypes.shape({
      duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      endOfCase: PropTypes.bool,
      startTime: PropTypes.number,
    }),
  }),
};
