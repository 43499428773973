import React from "react";

import Form from "./Index";
import { equipmentMasterSamples } from "../../specSamples/equipmentMaster.samples";
import { equipmentMasterFallbackSamples } from "../../specSamples/equipmentMasterFallback.samples";
import { facilitySamples } from "../../specSamples/facility.samples";
import { inventoryItemSamples } from "../../specSamples/inventoryItem.samples";
import mockRequestContext from "../../specSamples/mockRequestContext";
import { physicianSample } from "../../specSamples/physician.samples";
import { procedureSamples } from "../../specSamples/procedure.samples";
import { serviceLineSamples } from "../../specSamples/serviceLine.samples";
import { userSamples } from "../../specSamples/user.samples";

import {
  EQUIPMENT_MASTERS_PATH,
  EQUIPMENT_MASTER_FALLBACKS_PATH,
  EQUIPMENT_PREFERENCES_PATH,
  FACILITIES_PATH,
  INVENTORY_ITEMS_PATH,
  PHYSICIANS_PATH,
  PROCEDURES_PATH,
  SERVICE_LINES_PATH,
  USERS_PATH,
} from "../../utils/capi";
import { requestSamples } from "./inventory_items/pill/const.samples";

const REAL_CAPI_TOKEN = "";

const adminUser = {
  id: 1,
  name: "Admin Foo",
  roles: {
    author: ["clinician", "admin"],
    verifier: [
      "biomed",
      "clinician",
      "program_manager",
      "patient_care_controller",
      "patient_care_program_manager",
      "patient_care_site_coordinator",
      "patient_care_staff",
    ],
  },
};
const stubbedPrefId = 2;
const mockedGets = [
  [
    [EQUIPMENT_PREFERENCES_PATH, stubbedPrefId, "inventory_items"].join("/"),
    requestSamples,
  ],
  [PHYSICIANS_PATH, physicianSample],
  [PROCEDURES_PATH, procedureSamples],
  [EQUIPMENT_MASTERS_PATH, equipmentMasterSamples],
  [EQUIPMENT_MASTER_FALLBACKS_PATH, equipmentMasterFallbackSamples],
  [INVENTORY_ITEMS_PATH, inventoryItemSamples],
  [SERVICE_LINES_PATH, serviceLineSamples],
  [FACILITIES_PATH, facilitySamples],
  [USERS_PATH, userSamples],
].map((req) => ({ path: req[0], sample: req[1] }));

const { withRequestContext } = mockRequestContext({
  context: {
    params: {
      id: stubbedPrefId, // require prefId to load associations
      facilityId: 123, // require facId to build fallbacks
    },
    setContext: () => {},
  },
  onGet: mockedGets,
});

export default {
  argTypes: { handleChange: { action: "Form Attributes" } },
  component: Form,
  decorators: [
    (Story) => (
      <div id="equipment-preferences-new-page">
        <Story />
      </div>
    ),
    // withRequestContext,
  ],
  title: "Equipment Preference/Form",
};

const Template = (args) => <Form {...args} />;

export const Default = Template.bind({});
Default.args = {
  capiToken: REAL_CAPI_TOKEN.length ? REAL_CAPI_TOKEN : "fake_token",
  healthSystemId: 53,
  user: adminUser,
};

export const Id = Template.bind({});
Id.args = {
  ...Default.args,
  id: 6104,
};

export const CloneId = Template.bind({});
CloneId.args = {
  ...Default.args,
  cloneId: 6104,
};
