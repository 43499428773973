import React from "react";
import PropTypes from "prop-types";

class MovesContactPillForm extends React.Component {
  componentDidMount() {
    $(
      `#moves-contacts-tooltip-${this.props.pickUpOrDropOff}-${this.props.index}`
    ).tooltip();
  }

  componentDidUpdate(prevProps) {
    // re-initialize tooltips if pill has changed to form previously.
    if (prevProps.primary !== this.props.primary) {
      const id = `#moves-contacts-tooltip-${this.props.pickUpOrDropOff}-${this.props.index}`;
      const msg = this.props.primary
        ? "Primary contact"
        : "Toggle primary contact";
      $(id).tooltip("destroy");
      $(id).tooltip({ title: msg });
    }
  }
  /*
      Validators
  */
  renderErrorClass = () =>
    this.props.email || this.props.phone ? "" : "pill-error";

  /*
      Renderers
  */
  renderRemoveIcon = () => {
    return (
      <span onClick={this.props.handleRemovePill}>
        <i className="sprite-icon sprite-x-s-black remove-icon" />
      </span>
    );
  };

  renderPrimaryIcon = () => {
    const { index } = this.props;
    if (this.props.primary) {
      return (
        <span>
          <i
            id={`moves-contacts-tooltip-${this.props.pickUpOrDropOff}-${index}`}
            data-title={"Primary contact"}
            data-placement="left"
            data-delay={{ show: 1000, hide: 100 }}
            className={"sprite-icon sprite-user-s-blue no-edit"}
          />
        </span>
      );
    }
    return (
      <span onClick={this.props.handleSelPrimary}>
        <i
          id={`moves-contacts-tooltip-${this.props.pickUpOrDropOff}-${index}`}
          data-title={"Toggle primary contact"}
          data-placement="left"
          data-delay={{ show: 1000, hide: 100 }}
          className={"sprite-icon sprite-user-s-outline"}
        />
      </span>
    );
  };

  render() {
    const { name, email, description, phone, primary } = this.props;

    return (
      <div
        className={`moves-contact-row inline-block ${this.renderErrorClass()}`}
      >
        <div
          className={
            primary
              ? "moves-contact-icon btn btn-link no-edit"
              : "moves-contact-icon btn btn-link"
          }
        >
          {this.renderPrimaryIcon()}
        </div>

        <div
          className="move-contact-info"
          onClick={this.props.handleEditingPill}
        >
          <div className="primary-information">
            {name}
            {description ? ", " + description : null}
          </div>
          <div className="secondary-information">
            <small className="truncate">
              {email ? email + (phone ? ", " + phone : "") : phone}
            </small>
          </div>
        </div>

        <div className="moves-contact-control btn btn-link">
          {this.renderRemoveIcon()}
        </div>
      </div>
    );
  }
}

MovesContactPillForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  primary: PropTypes.bool,
  index: PropTypes.number,
  handleEditingPill: PropTypes.func,
  handleSelPrimary: PropTypes.func,
  handleRemovePill: PropTypes.func,
};

export default MovesContactPillForm;
