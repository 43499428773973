import React from "react";
import EquipmentSelect from "./equipment_select";
import { components } from "react-select-3";

export default function EquipmentMasterComponent(props) {
  const { onChange, options, value } = props;

  return (
    <div className="control-group clearfix">
      <div className="col-left">
        <label>Equipment:</label>
      </div>
      <div className="col-right">
        <EquipmentSelect
          components={{ Option: Option }}
          className="equipment-master-select"
          options={options}
          onChange={onChange}
          placeholder="Please select equipment"
          renderContent={renderDataContent}
          value={value}
        />
      </div>
    </div>
  );
}

const renderDataContent = (data) => [
  <div className="thumb-content" key={`thumb-${data.id}`}>
    <img height="44" src={data.urlImageThumb} width="44" />
  </div>,
  <div className="content" key={`content-${data.id}`}>
    <div className="primary">
      <span className="manufacturer-name">{data.manufacturerName}</span>
    </div>
    <div className="secondary">
      <span className="model-title">{data.modelTitle} </span>
      <span className="title-description">{data.titleDescription}</span>
    </div>
  </div>,
];

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const { data, ...restData } = props.data;
  const newProps = {
    ...props,
    data: restData,
    innerProps: rest,
  };

  return (
    <components.Option {...newProps}>
      {renderDataContent(data)}
    </components.Option>
  );
};
