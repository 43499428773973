import React from "react";

import InventoryItems from "./InventoryItems";

import mockRequestContext from "../../../specSamples/mockRequestContext";
import {
  EQUIPMENT_MASTERS_PATH,
  EQUIPMENT_MASTER_FALLBACKS_PATH,
  EQUIPMENT_PREFERENCES_PATH,
} from "../../../utils/capi";
import { requestSamples } from "./pill/const.samples";
import { equipmentMasterSamples } from "../../../specSamples/equipmentMaster.samples";
import { equipmentMasterFallbackSamples } from "../../../specSamples/equipmentMasterFallback.samples";

const stubbedPrefId = 12345;
const path = [
  EQUIPMENT_PREFERENCES_PATH,
  stubbedPrefId,
  "inventory_items",
].join("/");

const { withRequestContext } = mockRequestContext({
  context: {
    params: {
      id: stubbedPrefId, // require prefId to load associations
      facilityId: 123, // require facId to build fallbacks
    },
  },
  path,
  onGet: [
    {
      path,
      sample: requestSamples,
    },
    {
      path: EQUIPMENT_MASTERS_PATH,
      sample: equipmentMasterSamples,
    },
    {
      path: EQUIPMENT_MASTER_FALLBACKS_PATH,
      sample: equipmentMasterFallbackSamples,
    },
  ],
});

export default {
  argTypes: {
    handleChange: { action: "Input value changed" },
    handleFallbackChange: { action: "Fallback was changed" },
  },
  component: InventoryItems,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <Story />
        </div>
      </div>
    ),
    withRequestContext,
  ],
  title: "Equipment Preference/Form/Inventory Items",
};

const Template = (args) => <InventoryItems {...args} />;

export const Default = Template.bind({});
Default.args = {
  handleChange: () => {},
  handleFallbackChange: () => {},
};

export const NotEditable = Template.bind({});
NotEditable.args = {
  ...Default.args,
  isEditable: false,
};

export const LoadAssociations = Template.bind({});
LoadAssociations.args = {
  ...Default.args,
  loadAssociations: true, // !isNew
};

export const LoadAssociationsNotEditable = Template.bind({});
LoadAssociationsNotEditable.args = {
  ...LoadAssociations.args,
  ...NotEditable.args,
};

// TODO: add value state
