import React, { createContext, useContext, useMemo } from "react";

import axios from "axios";
import { growl, renderAlerts, setLocalStorageAlerts } from "./alert";
import { CAPI_BASE_URI } from "./capi";
import { openInCurrentTab } from "./window";
import { Loader } from "../equipment_preferences/form/utils/utils";

export const RequestContext = createContext({
  extras: {},
  options: {},
  params: {},
  request: null,
});

export const RequestProvider = ({
  children,
  extras,
  options,
  params,
  request,
}) => {
  const {
    extras: _extras,
    options: _options,
    params: _params,
    request: _request,
  } = useContext(RequestContext);

  const value = useMemo(
    () => ({
      extras: { ..._extras, ...extras },
      params: { ..._params, ...params },
      options: { ..._options, ...options },
      request: _request || request, // parent request has higher precedence
    }),
    [extras, options, params, request]
  );

  return request ? (
    <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
  ) : (
    <Loader />
  );
};

export function capiClient(capiToken) {
  const client = axios.create({
    baseURL: CAPI_BASE_URI,
    headers: {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": capiToken,
    },
  });
  /**
   * The nested layers of response
   * > response includes headers, config, and status information
   *   delivered by the web server
   *
   * > > response.data contains platform (non/)serialized information
   *     includes keys related to serialized json apis like included, meta, etc
   *
   * > > > response.data.data can be an object or an array of objects
   *       that are serialized by the api platform
   */
  client.interceptors.response.use(
    function (response) {
      const { data, meta, options } = response.data;

      if (options?.linkTo) {
        // TODO: does not support render_response from CAPI as a growl
        if (meta?.alerts) setLocalStorageAlerts(meta.alerts);

        // TODO: Due to ENG-7334, there's currently no need for redirect
        // to previous page. Please utilize as needed.
        if (options.linkTo == "previous") {
          window.history.length == 1
            ? location.reload()
            : window.history.go(-1);
        } else if (options.linkTo == "reload") {
          location.reload();
        } else {
          location.href = options?.linkTo;
        }
      } else {
        // Render the list of alerts
        if (meta?.alerts) renderAlerts(meta.alerts);

        // The following is handled in 'main.js.coffee' since
        // deferring alerts in React will not be displayed
        // outside of React pages.
        //
        // DEV: Hold for future implementation
        //
        // const deferredAlerts = getLocalStorageAlerts();
        // if (deferredAlerts) {
        //   renderAlerts(deferredAlerts);
        //   wipeLocalStorageAlerts();
        // }

        // Render one-off responses as alerts
        if (typeof data === "string" || data instanceof String) growl(data);
      }

      return response;
    },
    function (error) {
      const { response: errRes } = error;
      const errMessages = errRes?.data?.errors;

      switch (errRes.status) {
        case 404:
          openInCurrentTab("/errors/not_found");
          break;
        // if (errRes.status == 401) window.location.href = `${CAPI_BASE_URI}/login`;
        case 422: // Unprocessable entity: usually validation errors
          growl(
            "Validation Failed",
            errMessages.map((err) => err.detail).join(", "),
            {
              status: "warning",
              icon: "remove",
            }
          );
          return error;
        case 500:
          growl(
            errMessages,
            "Code 500: Please see an admin or try again later.",
            {
              icon: "remove",
              status: "danger",
            }
          );
          break;
        default:
          if (errMessages?.length) {
            console.log(errMessages.map((err) => err.detail)?.join(", "));
          } else if (errRes) {
            console.log(
              `Error with status code ${errRes.status}: ${
                errRes.statusText ?? `issue retrieving ${errRes.config.url}`
              }. Please see an admin.`
            );
          } else {
            console.log("Unable to contact the platform, please see an admin.");
          }
      }
      return Promise.reject(error);
    }
  );
  return client;
}
