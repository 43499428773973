export const serviceLineSamples = [
  {
    id: "1",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Anesthesiology",
    },
  },
  {
    id: "65",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Audiology",
    },
  },
  {
    id: "30",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Bariatrics",
    },
  },
  {
    id: "31",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Bone Marrow Transplant",
    },
  },
  {
    id: "2",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Cardiac Surgery",
    },
  },
  {
    id: "3",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Cardiology",
    },
  },
  {
    id: "32",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Cardiothoracic",
    },
  },
  {
    id: "33",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Cardiovascular",
    },
  },
  {
    id: "4",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Dentistry",
    },
  },
  {
    id: "34",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Dermatology",
    },
  },
  {
    id: "35",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Electrophysiology",
    },
  },
  {
    id: "5",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Gastroenterology",
    },
  },
  {
    id: "6",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "General Surgery",
    },
  },
  {
    id: "7",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Gynecology",
    },
  },
  {
    id: "8",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Head and Neck",
    },
  },
  {
    id: "36",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Interventional Pain Management",
    },
  },
  {
    id: "37",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Interventional Radiology",
    },
  },
  {
    id: "9",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Maxillofacial",
    },
  },
  {
    id: "38",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Neurology",
    },
  },
  {
    id: "10",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Neurosurgery",
    },
  },
  {
    id: "11",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Obstetrics",
    },
  },
  {
    id: "39",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Oncology General",
    },
  },
  {
    id: "40",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Oncology GYN",
    },
  },
  {
    id: "41",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Oncology Hematology",
    },
  },
  {
    id: "12",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Ophthalmology",
    },
  },
  {
    id: "13",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Organ Procurement",
    },
  },
  {
    id: "14",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Orthopedics",
    },
  },
  {
    id: "42",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Cardiac",
    },
  },
  {
    id: "43",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Cardiology",
    },
  },
  {
    id: "29",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Gastroenterology",
    },
  },
  {
    id: "15",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric General Surgery",
    },
  },
  {
    id: "44",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Head and Neck",
    },
  },
  {
    id: "45",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Neuro",
    },
  },
  {
    id: "16",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Oncology",
    },
  },
  {
    id: "46",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Ophthalmology",
    },
  },
  {
    id: "47",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Ortho",
    },
  },
  {
    id: "48",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pediatric Urology",
    },
  },
  {
    id: "17",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Plastics",
    },
  },
  {
    id: "18",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Podiatry",
    },
  },
  {
    id: "49",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Private Ophthalmology",
    },
  },
  {
    id: "19",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Pulmonary",
    },
  },
  {
    id: "50",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Radiation Oncology",
    },
  },
  {
    id: "51",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Radiology",
    },
  },
  {
    id: "64",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Red Room",
    },
  },
  {
    id: "60",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS",
    },
  },
  {
    id: "119",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - BARIATRICS",
    },
  },
  {
    id: "56",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS - COLO",
    },
  },
  {
    id: "70",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - COLO",
    },
  },
  {
    id: "234",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - FOREGUT",
    },
  },
  {
    id: "229",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - GEN ONC",
    },
  },
  {
    id: "69",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS - GEN SUR",
    },
  },
  {
    id: "57",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS - GYN",
    },
  },
  {
    id: "71",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - GYN",
    },
  },
  {
    id: "52",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS - GYN ONC",
    },
  },
  {
    id: "236",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - HERNIA/AWR",
    },
  },
  {
    id: "117",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - THORACIC",
    },
  },
  {
    id: "58",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS - TORS",
    },
  },
  {
    id: "59",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS - URO",
    },
  },
  {
    id: "72",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Robotics - URO",
    },
  },
  {
    id: "53",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS - URO GYN",
    },
  },
  {
    id: "68",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "ROBOTICS-THORACIC",
    },
  },
  {
    id: "28",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Spine",
    },
  },
  {
    id: "25",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Thoracic",
    },
  },
  {
    id: "54",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Transplant",
    },
  },
  {
    id: "63",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Trauma",
    },
  },
  {
    id: "55",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "URO Gynecology",
    },
  },
  {
    id: "26",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Urology",
    },
  },
  {
    id: "27",
    type: "service_lines",
    attributes: {
      health_system_id: 53,
      name: "Vascular",
    },
  },
];
