import React, { useCallback, useEffect, useState } from "react";
import useId from "@mui/material/utils/useId";
import { useGridRootProps } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material";

// Source:
// https://github.com/mui/mui-x/blob/next/packages/grid/x-data-grid/src/components/panel/filterPanel/GridFilterInputBoolean.tsx

// Dev: Need to override boolean InputComponent to fix label styling
const BooleanOperatorContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  [`& button`]: {
    margin: "auto 0px 5px 5px",
  },
});

function GridFilterInputBoolean(props) {
  const {
    item,
    applyValue,
    apiRef,
    focusElementRef,
    isFilterActive,
    clearButton,
    tabIndex,
    label: labelProp,
    InputLabelProps,
    ...others
  } = props;
  const [filterValueState, setFilterValueState] = useState(item.value || "");
  const rootProps = useGridRootProps();

  const labelId = useId();
  const selectId = useId();

  const baseSelectProps = rootProps.slotProps?.baseSelect || {};
  // Not used for now. Please see
  // const isSelectNative = baseSelectProps.native ?? false;

  const baseSelectOptionProps = rootProps.slotProps?.baseSelectOption || {};

  const onFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      setFilterValueState(value);
      applyValue({ ...item, value });
    },
    [applyValue, item]
  );

  useEffect(() => {
    setFilterValueState(item.value || "");
  }, [item.value]);

  const label =
    labelProp ?? apiRef.current.getLocaleText("filterPanelInputLabel");

  // DEV: shrink and notched props used together to address outline select bug
  return (
    <BooleanOperatorContainer>
      <rootProps.slots.baseFormControl fullWidth size="small">
        <rootProps.slots.baseInputLabel
          {...rootProps.slotProps?.baseInputLabel}
          id={labelId}
          shrink
        >
          {label}
        </rootProps.slots.baseInputLabel>
        <rootProps.slots.baseSelect
          autoWidth
          displayEmpty
          id={selectId}
          inputProps={{ ref: focusElementRef, tabIndex }}
          label={label}
          labelId={labelId}
          notched
          onChange={onFilterChange}
          value={filterValueState}
          {...others}
          {...baseSelectProps}
        >
          <rootProps.slots.baseSelectOption
            {...baseSelectOptionProps}
            disabled
            value=""
          >
            <em>{apiRef.current.getLocaleText("filterValueAny")}</em>
          </rootProps.slots.baseSelectOption>
          <rootProps.slots.baseSelectOption
            {...baseSelectOptionProps}
            value="true"
          >
            {apiRef.current.getLocaleText("filterValueTrue")}
          </rootProps.slots.baseSelectOption>
          <rootProps.slots.baseSelectOption
            {...baseSelectOptionProps}
            value="false"
          >
            {apiRef.current.getLocaleText("filterValueFalse")}
          </rootProps.slots.baseSelectOption>
        </rootProps.slots.baseSelect>
      </rootProps.slots.baseFormControl>
    </BooleanOperatorContainer>
  );
}

export { GridFilterInputBoolean };
