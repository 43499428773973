import { blueLight, grey9, selected, white } from "../../utils/base";

// TODO: DRY up MIN_CHARACTER_LIMIT
const MIN_CHARACTER_LIMIT = 3;
export const withinMinCharacterLimit = (input) =>
  input?.length >= MIN_CHARACTER_LIMIT;

export const SINGLE_SELECT_STYLES = {
  clearIndicator: (provided, state) => ({
    ...provided,
    height: "17px",
    marginBottom: "3px",
    padding: "0px",
    width: "17px",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "13px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
    paddingRight: "5px",
  }),
  indicatorSeparator: () => {},
  input: (provided, state) => ({
    ...provided,
    // https://github.com/JedWatson/react-select/issues/2774#issuecomment-1481483479
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    input: {
      borderColor: `${white} !important`, // override needed due to select2/bootstrap dep
      boxShadow: "none !important",
      margin: "0px !important",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: "13px",
    margin: "0px",
  }),
  option: (provided, state) => {
    // Monkey patch
    // when creatable, the default creatable option is in a
    // highlighted state
    const creatableStyles =
      state.data.__isNew__ &&
      state.selectProps.className.includes("creatable") &&
      !withinMinCharacterLimit(state.data.value);

    const textColor = creatableStyles
      ? {
          backgroundColor: white,
          color: "hsl(0, 0%, 60%)",
          textAlign: "center",
        }
      : state.isSelected
      ? {
          backgroundColor: selected,
          color: white,
        }
      : {
          "&:hover": {
            backgroundColor: blueLight,
            color: grey9,
          },
        };

    return {
      ...provided,
      ...textColor,
      borderTop: "1px solid #d6d6d6",
      "&:first-of-type": {
        borderTop: "none",
      },
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    overflow: "visible",
    width: "100%",
    whiteSpace: "normal",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    paddingLeft: "6px",
  }),
};
