import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import AsyncSelect from "react-select-3/async";
import AsyncCreatable from "react-select-3/async-creatable";
import { RequestContext } from "../../utils/capiClient";

import { SINGLE_SELECT_STYLES } from "./styles";

const MIN_CHARACTER_LIMIT = 3;

export const LazySelect = ({
  alias = {
    objectId: null, // physicianId
    name: null, // physician
    many: null, // Procedures
  },
  creatable,
  filterableIds = [],
  focusOnMount = false,
  handleChange,
  isDisabled = false,
  requestUrl,
  selected,
}) => {
  const selectRef = useRef();

  const [value, setValue] = useState(selected);
  const [input, setInput] = useState("");
  const { request, params } = useContext(RequestContext);

  useEffect(() => {
    if (focusOnMount) selectRef?.current.focus();
  }, []);

  const loadOptions = useCallback(
    _.debounce((excludeObjectIds, inputValue, requestConf, callback) => {
      getOptions(excludeObjectIds, inputValue, requestConf).then((opt) =>
        callback(opt)
      );
    }, 1500),
    []
  );

  const changeProps = {
    inputValue: input,
    onChange: (change) => {
      if (change.value == value.value) return;

      const newValue = Object.assign(value, {
        ...change,
        [alias.objectId ?? "objectId"]: change.value,
      });
      setValue(newValue);
      handleChange(newValue);
    },
    onInputChange: (newInput) => setInput(newInput),
    value: value,
  };

  const messageProps = {
    loadingMessage: () =>
      withinMinCharacterLimit(input)
        ? `Finding${alias.many ? ` ${alias.many}` : ""}...`
        : charactersLeftMessage(input),
    noOptionsMessage: () =>
      input.length === 0
        ? `${charactersLeftMessage()}${
            alias.many ? ` to find ${alias.many}` : ""
          }`
        : withinMinCharacterLimit(input)
        ? alias.many
          ? `Cannot find any ${alias.many} matching '${input}'`
          : `Input matching '${input}' cannot be found`
        : charactersLeftMessage(input),
    placeholder: `Please select${alias.name ? ` a ${alias.name}` : ""}...`,
  };

  let klassName = "input-block-level criteria-select";
  if (alias.name)
    klassName = klassName.concat(` ${alias.name}-selection`, " ", alias.name);
  if (creatable) klassName = klassName.concat(" creatable");

  const defaultConfig = {
    ...changeProps,
    ...messageProps,
    className: klassName,
    classNamePrefix: "select",
    ...(isDisabled && { isDisabled }),
    defaultOptions: [],
    loadOptions: (inputValue, callback) =>
      loadOptions(
        filterableIds,
        inputValue,
        {
          client: request,
          url: requestUrl,
          params: { healthSystemId: params?.healthSystemId },
        },
        callback
      ),
    openMenuOnFocus: true,
    ref: selectRef,
    styles: SINGLE_SELECT_STYLES,
  };

  if (creatable) {
    const formatCreateLabel = (inputValue) =>
      withinMinCharacterLimit(inputValue) ? (
        <span>
          <u>{inputValue}</u>
          <i style={{ marginLeft: "10px" }}>Match all procedures</i>
          <span className="label label-success">Most flexible</span>
        </span>
      ) : (
        <span>
          {`${charactersLeftMessage(inputValue)}${
            inputValue.length == 0 ? ` to find ${alias.many}` : ""
          }`}
        </span>
      );

    return (
      <AsyncCreatable
        {...defaultConfig}
        allowCreateWhileLoading
        createOptionPosition="first"
        formatCreateLabel={formatCreateLabel}
        isValidNewOption={(input) => withinMinCharacterLimit(input)}
      />
    );
  } else {
    return <AsyncSelect {...defaultConfig} />;
  }
};

const charactersLeftMessage = (input) => {
  const numCharsLeft = input
    ? MIN_CHARACTER_LIMIT - input.length
    : MIN_CHARACTER_LIMIT;
  return `Please enter ${numCharsLeft} or more characters`;
};

export const getOptions = (excludeObjectIds, inputValue, request) => {
  const canExcludeOption = ({ value }) =>
    excludeObjectIds.includes(parseInt(value));
  const containsInputValue = ({ label }) =>
    label.toLowerCase().includes(inputValue.toLowerCase());

  return inputValue.length < MIN_CHARACTER_LIMIT
    ? Promise.resolve([])
    : request.client
        .get(request.url, {
          params: { ...request.params, tokens: inputValue },
        })
        .then(({ data }) => {
          const results = data?.data?.reduce((memo, option) => {
            const {
              attributes,
              attributes: { coded_name, name },
              id,
            } = option;

            const _option = {
              label: (coded_name || name) ?? "",
              value: id,
              data: attributes,
            };

            if (!canExcludeOption(_option) && containsInputValue(_option))
              memo.push(_option);

            return memo;
          }, []);
          return results ?? [];
        });
};

export const withinMinCharacterLimit = (input) =>
  input?.length >= MIN_CHARACTER_LIMIT;

LazySelect.propTypes = {
  alias: PropTypes.shape({
    many: PropTypes.string,
    name: PropTypes.string,
    objectId: PropTypes.string,
  }),
  creatable: PropTypes.bool,
  filterableIds: PropTypes.arrayOf(PropTypes.number),
  focusOnMount: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  requestUrl: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    data: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
