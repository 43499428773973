import React from "react";
import Index from "./Index.js";
import { withQuery } from "@storybook/addon-queryparams";

export default {
  argTypes: { handleChange: { action: "change triggered" } },
  component: Index,
  decorators: [
    (Story) => (
      <div id="index-page">
        <Story />
      </div>
    ),
    // withRequestContext,
  ],
  title: "Patient Cases/index",
};

const Template = (args) => <Index {...args} />;

const REAL_CAPI_TOKEN = "";
const adminUser = {
  id: 123,
  name: "Admin Foo",
  // timeZone: "America/New_York",
  timeZone: "America/Los_Angeles",
};

export const Default = Template.bind({});
Default.args = {
  token: REAL_CAPI_TOKEN.length ? REAL_CAPI_TOKEN : "storybook_token",
  user: adminUser,
};

export const WithHealthSystemId = Template.bind({});
WithHealthSystemId.args = {
  ...Default.args,
  health_system_id: 53,
};

export const UrlQueryParams = Template.bind({});
UrlQueryParams.args = {
  ...Default.args,
  data: {
    setQueryParams: undefined,
    queryParams: {
      columnVisibilityModel: {
        mobilized: true,
        externalId: false,
        room: false,
        updatedBy: true,
      },
      filterModel: {
        items: [
          {
            field: "serviceLine",
            operator: "contains",
            value: "Bariatrics",
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      },
      sortModel: [],
    },
  },
};
UrlQueryParams.decorators = [
  (Story, context) => {
    // Storybook specific: Generate a unique key based on q params
    // When user changes q params on 'Controls', it will force a remount
    // of this storybook component.

    // NOTE: Query Params are grabbed and set outside of the Table component
    // to reduce additional bloat and customizations by the parent component
    const { columnVisibilityModel, filterModel } =
      context.args.data.queryParams;
    const cvm = Object.keys(columnVisibilityModel)
      .map((k) => `${k}-${columnVisibilityModel[k] ? "t" : "f"}`)
      .join("&");
    const fmi = filterModel.items
      .map((props) => Object.values(props).join("-"))
      .join("&");

    return (
      <div id="index-page">
        <Story key={`${cvm}&&${fmi}`} />
      </div>
    );
  },
  withQuery,
];
