import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select-3";
import _ from "lodash";

import { USERS_PATH } from "../../../utils/capi";
import { RequestContext } from "../../../utils/capiClient";
import { findSelectedOption } from "../../../utils/finders";
import { displayDate } from "../../../utils/datetime";

import {
  QUICK_EDIT_SELECT_STYLES,
  StyledDisplay,
  StyledDiv,
  StyledForm,
  StyledNotVerified,
  StyledVerified,
} from "./styled";

export default function VerifiedSelect({
  handleChange,
  isDisabled,
  isEditable,
  verifiedById,
  verifiedName,
  verifiedOnDate,
}) {
  const { options, params, request } = useContext(RequestContext);
  const [state, setState] = useState({
    isLoaded: null,
    roleOptions: [],
    selectOptions: [],
    showClear: !!(verifiedName || verifiedOnDate),
    showError: false,
    showForm: false,
  });
  const idRef = useRef(null);

  const handleCancel = () => {
    if (verifiedOnDate) {
      $("#verified_on_date").datepicker("setDate", new Date(verifiedOnDate));
    } else {
      $("#verified_on_date").datepicker("clearDates");
    }
    idRef?.current?.setValue(
      findSelectedOption(verifiedById, state.selectOptions)
    );
    setState({
      ...state,
      showClear: !!(verifiedById || verifiedOnDate),
      showError: false,
      showForm: false,
    });
  };

  const handleClear = () => {
    idRef?.current?.clearValue();
    $("#verified_on_date").datepicker("clearDates");
    setState({ ...state, showClear: false, showError: false });
  };

  const handleSelectChange = (change) => {
    toggleClear({
      selectedDate: $("#verified_on_date").datepicker("getDate"),
      selectedId: change?.value || null,
    });
  };

  const handleSubmit = () => {
    let vDate = null;
    let vId = null;

    const selectedDate = $("#verified_on_date").datepicker("getDate");
    const selectedId = idRef?.current?.getValue()[0];
    if (selectedDate && selectedId) {
      vDate = displayDate(selectedDate);
      vId = selectedId.value;
    } else if (!selectedDate && !selectedId) {
      // Allow both values to be cleared/removed
    } else {
      setState({ ...state, showClear: true, showError: true });
      return;
    }

    handleChange({
      verifiedById: vId,
      verifiedOnDate: vDate,
    });

    setState({ ...state, showForm: false });
  };

  const handleTextClick = () => {
    setState({ ...state, showForm: !state.showForm });
  };

  const toggleClear = (change) => {
    if (change.selectedDate || change.selectedId)
      setState({ ...state, showClear: true });
    else setState({ ...state, showClear: false });
  };

  $("#verified_on_date")
    .datepicker({
      autoclose: true,
      clearBtn: true,
      format: "mm/dd/yy",
    })
    .on("changeDate", function () {
      toggleClear({
        selectedDate: $("#verified_on_date").datepicker("getDate"),
        selectedId: idRef?.current?.getValue()[0]?.value || null,
      });
    });

  // Load once on supplied request
  useEffect(() => {
    if (!isEditable || !request) return;

    request
      .get(USERS_PATH, {
        params: {
          healthSystemIds: params.healthSystemId,
          roles: params.verifierRoles,
        },
      })
      .then(({ data }) => {
        const resp = data?.data?.length
          ? data.data.map((opt) => ({
              label: opt.attributes.name,
              value: opt.id,
            }))
          : [];

        setState({
          ...state,
          roleOptions: resp,
        });
      });
  }, [!!request, isEditable]); // check for presence change

  useEffect(() => {
    if (isEditable && state.selectOptions?.length && verifiedById) {
      idRef.current?.setValue(
        findSelectedOption(verifiedById, state.selectOptions)
      );
    }
  }, [state.selectOptions, verifiedById, verifiedName]);

  useEffect(() => {
    const combinedOptions = _.sortBy(
      [...state?.roleOptions, ...options?.admins],
      "label"
    );

    setState({
      ...state,
      isLoaded: true,
      selectOptions: combinedOptions,
    });
  }, [options?.admins?.length, state.roleOptions?.length]);

  const { isLoaded, showClear, showError, selectOptions, showForm } = state;
  let name = null;
  const isClickable = !isDisabled && isEditable;

  if (isEditable && selectOptions?.length && verifiedById) {
    const selectedOption = findSelectedOption(verifiedById, selectOptions);
    name = selectedOption?.label || null;
  }

  const isVerified = !!(verifiedOnDate && (verifiedName || name));
  const formattedDate = verifiedOnDate
    ? moment.utc(verifiedOnDate, "YYYY-MM-DD").format("MM/DD/YY")
    : "";

  return (
    <StyledDiv>
      {
        // verifiedName order matters
      }
      <StyledDisplay id="verified-editable-display">
        {isClickable ? (
          <ClickableText
            formattedDate={formattedDate}
            handleCancel={handleCancel}
            handleChange={handleSelectChange}
            handleClear={handleClear}
            handleClick={!isDisabled && handleTextClick}
            handleSubmit={handleSubmit}
            idRef={idRef}
            isDisabled={isDisabled}
            isLoaded={isLoaded}
            isVerified={isVerified}
            options={selectOptions}
            showClear={showClear}
            showError={showError}
            showForm={showForm}
            toggleClear={toggleClear}
            verifiedById={verifiedById}
            verifiedName={name || verifiedName}
            verifiedOnDate={verifiedOnDate}
          />
        ) : (
          <StaticText
            isVerified={isVerified}
            verifiedName={verifiedName}
            verifiedOnDate={verifiedOnDate}
          />
        )}
      </StyledDisplay>
    </StyledDiv>
  );
}

const ClickableText = ({
  formattedDate,
  handleCancel,
  handleChange,
  handleClear,
  handleClick,
  handleSubmit,
  idRef,
  isDisabled,
  isLoaded,
  isVerified,
  options,
  showClear,
  showError,
  showForm,
  verifiedById,
  verifiedName,
  verifiedOnDate,
}) => {
  return (
    <div>
      <div className="dynamic" onClick={handleClick}>
        {isVerified ? (
          <VerifiedText
            verifiedName={verifiedName}
            verifiedOnDate={verifiedOnDate}
          />
        ) : (
          <span>+ Add Verification</span>
        )}
        <i className="icon icon-edit" />
      </div>
      <StyledForm
        className="fade in editable-container hintarrow arrow-center"
        id="verification-editable-form"
        style={{
          display: `${showForm ? "block" : "none"}`,
        }}
      >
        <div className="popover-title">Verification</div>
        <div className="popover-content">
          <div className="fields">
            <Select
              className="input-block-level"
              classNamePrefix="select"
              isClearable
              isDisabled={isDisabled || !isLoaded}
              key={`verification-${verifiedById}`}
              onChange={handleChange}
              options={options}
              placeholder="User"
              ref={idRef}
              styles={QUICK_EDIT_SELECT_STYLES}
            />
            <input
              className="input-mini date date-start input-block-level"
              defaultValue={formattedDate || ""}
              id="verified_on_date"
              name="equipment_preference[verified_on_date]"
              placeholder="Date"
              tabIndex="100"
              type="text"
            />
          </div>
          {showError && (
            <div className="errors">
              Both user and date are required to verify preference.
            </div>
          )}
          <div className="buttons">
            {showClear && (
              <div id="verification-clear" onClick={handleClear}>
                Clear
              </div>
            )}
            <span className="to-right">
              <div
                className="btn btn-mini btn-primary"
                id="verification-submit"
                onClick={handleSubmit}
              >
                <i className="icon-ok icon-white" />
              </div>
              <div
                className="btn btn-mini"
                id="verification-cancel"
                onClick={handleCancel}
              >
                <i className="icon-remove" />
              </div>
            </span>
          </div>
        </div>
      </StyledForm>
    </div>
  );
};

const StaticText = ({ isVerified, verifiedName, verifiedOnDate }) => {
  return isVerified ? (
    <VerifiedText verifiedName={verifiedName} verifiedOnDate={verifiedOnDate} />
  ) : (
    <StyledNotVerified>Not Verified</StyledNotVerified>
  );
};

const VerifiedText = ({ verifiedName, verifiedOnDate }) => {
  const date = displayDate(verifiedOnDate, { format: "DATE" });
  return (
    <StyledVerified>
      <img
        src="/images/icon-verified.png"
        alt="Verified"
        width="16"
        height="20"
      />
      <b> Verified</b>
      {` \u2022 ${verifiedName} \u2022 ${date}`}
    </StyledVerified>
  );
};

VerifiedSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  verifiedById: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  verifiedName: PropTypes.string,
  verifiedOnDate: PropTypes.string,
};
