import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Select from "react-select";
import "react-select/dist/react-select.css";

import FallbackPill from "./fallback_pill";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 0,
  margin: 0,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "white",
  background: "#FCF8E3",

  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "#FCF8E3",
  background: "#FCF8E3",
  padding: 8,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class EquipmentMasterFallbackForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryId: this.props.primaryId,
      fallbacks: this.props.fallbacks,
      newFallbackPill: false,
      reorderingEnabled: false,
      originalFallbacksOrder: [],
      filteredHealthSystem: null,
      filteredFacilities: [],
      filterFacilityOptions: this.props.filterFacilityOptions,
      preventSave: false,
      hoverDisabled: false,
    };
  }

  componentDidMount() {
    $(".tip").tooltip();

    if (this.props.healthSystems.length == 1) {
      this.handleFilterHealthSystem(this.props.healthSystems[0]);
    }
  }

  componentDidUpdate() {
    $(".tip").tooltip();
  }

  disableHover = (disabled) => {
    this.setState({ hoverDisabled: disabled });
  };

  onClickDelete = (id) => {
    const params = {
      equipment_master_fallback: {
        primary_equipment_master_id: this.state.primaryId,
      },
    };

    $.ajax({
      url: `../equipment_master_fallbacks/${id}`,
      data: params,
      method: "DELETE",
      success: (fallbacks) => {
        this.setState({
          preventSave: true,
        });

        Cohealo.Growl("", {
          header: "Fallback Successfully Deleted",
          status: "success",
          icon: "ok",
        });

        // Reload page so fallback change appears in UI table
        window.location.reload();
      },
      error: (event, data) => {
        this.setState({
          preventSave: false,
        });

        Cohealo.Growl("", {
          header: "Fallback Delete Failed",
          status: "danger",
          icon: "remove",
        });
      },
    });
  };

  onClickNew = () => {
    this.setState({
      newFallbackPill: true,
      filteredHealthSystem: null,
      filteredFacilities: [],
      filterFacilityOptions: this.props.filterFacilityOptions,
    });
  };

  onCancelCreate = () => {
    this.setState({ newFallbackPill: false });
  };

  onClickCreate = (
    allowedFacilities,
    restrictedFacilities,
    fallbackId,
    pill
  ) => {
    if (this.state.preventSave) return;

    const params = {
      equipment_master_fallback: {
        primary_equipment_master_id: this.state.primaryId,
        allowed_facilities: allowedFacilities,
        restricted_facilities: restrictedFacilities,
        fallback_equipment_master_id: fallbackId,
        position: this.state.fallbacks.length + 1,
      },
    };

    this.setState({ preventSave: true });

    $.ajax({
      url: `../equipment_master_fallbacks/`,
      method: "POST",
      data: params,
      success: (fallbacks) => {
        // Commenting out so can be reimplemented later when refactoring to not need page refresh
        // Page reload resets state, so this is not needed

        // this.setState({
        //   fallbacks,
        //   newFallbackPill: false,
        //   preventSave: false
        // });

        Cohealo.Growl("", {
          header: "Fallback Successfully Created",
          status: "success",
          icon: "ok",
        });

        // Reload page so fallback change appears in UI table
        window.location.reload();
      },

      error: (event, data) => {
        pill.setState({ preventSave: false });
        this.setState({ preventSave: false });

        Cohealo.Growl(event.responseJSON.errors.join(", "), {
          header: "Fallback Create Failed",
          status: "danger",
          icon: "remove",
        });
      },
    });
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const fallbacks = reorder(
      this.state.fallbacks,
      result.source.index,
      result.destination.index
    );

    this.setState({
      fallbacks,
    });
  };

  fallbackPills = (draggable = false) => {
    const elements = [];
    const newPosition = this.state.fallbacks.length + 1;

    if (draggable) {
      this.state.fallbacks.map((fb, index) => {
        elements.push(
          <Draggable key={fb.id} draggableId={fb.id} index={index}>
            {(provided, snapshot) => (
              <div
                className="fallback-draggable-wrapper"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <FallbackPill
                  key={fb.id}
                  id={fb.id}
                  primaryId={fb.primary_equipment_master.id}
                  fallbackId={fb.fallback_equipment_master.id}
                  name={fb.fallback_equipment_master.name}
                  photo={fb.fallback_equipment_master.photo}
                  allowedFacilities={fb.allowed_facilities}
                  restrictedFacilities={fb.restricted_facilities}
                  position={fb.position}
                  link={fb.fallback_equipment_master.link}
                  equipmentMasters={this.props.equipmentMasters}
                  facilities={this.props.facilities}
                  onClickDelete={this.onClickDelete}
                  reorderingEnabled={this.state.reorderingEnabled}
                  editable={this.props.editable}
                />
              </div>
            )}
          </Draggable>
        );
      });
    } else {
      const filteredFacilities =
        this.state.filteredFacilities.length > 0
          ? this.state.filteredFacilities.map((f) => f.value)
          : this.state.filterFacilityOptions.map((f) => f.value);

      this.state.fallbacks.map((fb) => {
        const restrictedFacilityMap = fb.restricted_facilities.map(
          (f) => f.value
        );
        const allowedFacilityMap = fb.allowed_facilities.map((f) => f.value);
        if (
          !allowedFacilityMap.length ||
          filteredFacilities.some((f) => allowedFacilityMap.includes(f))
        ) {
          if (
            !filteredFacilities.every((f) => restrictedFacilityMap.includes(f))
          ) {
            elements.push(
              <FallbackPill
                key={fb.id}
                id={fb.id}
                primaryId={fb.primary_equipment_master.id}
                fallbackId={fb.fallback_equipment_master.id}
                name={fb.fallback_equipment_master.name}
                photo={fb.fallback_equipment_master.photo}
                allowedFacilities={fb.allowed_facilities}
                restrictedFacilities={fb.restricted_facilities}
                position={fb.position}
                link={fb.fallback_equipment_master.link}
                equipmentMasters={this.props.equipmentMasters}
                facilities={this.props.facilities}
                onClickDelete={this.onClickDelete}
                reorderingEnabled={this.state.reorderingEnabled}
                editable={this.props.editable}
                filteredFacilities={filteredFacilities}
                disableHover={this.disableHover}
                hoverDisabled={this.state.hoverDisabled}
              />
            );
          }
        }
      });
    }

    if (this.state.newFallbackPill) {
      elements.push(
        <FallbackPill
          key={newPosition}
          onCancelCreate={this.onCancelCreate}
          onClickCreate={this.onClickCreate}
          primaryId={this.state.primaryId}
          position={newPosition}
          equipmentMasters={this.props.equipmentMasters}
          facilities={this.props.facilities}
          editing={true}
          disableHover={this.disableHover}
          hoverDisabled={this.state.hoverDisabled}
        />
      );
    }

    return elements;
  };

  renderDragAndDrop = () => {
    const saveButtonText = this.state.preventSave ? "Saving..." : "Save";
    return (
      <div
        style={{
          background: "#FCF8E3",
        }}
      >
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div
                  style={{
                    color: "#C09853",
                    marginBottom: 10,
                    fontWeight: "700",
                  }}
                >
                  <i className="icon-move" /> Drag & Drop to reorder
                </div>
                {this.fallbackPills(true)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 8,
            paddingBottom: 8,
          }}
        >
          <a
            onClick={this.onClickSaveReordering}
            className="btn btn-primary btn-mini"
            disabled={this.state.preventSave}
          >
            {saveButtonText}
          </a>
          <a
            onClick={() =>
              this.setState({
                reorderingEnabled: false,
                fallbacks: this.state.originalFallbacksOrder,
              })
            }
            className="btn btn-mini"
            style={{ marginLeft: 10 }}
          >
            Cancel
          </a>
        </div>
      </div>
    );
  };

  onClickSaveReordering = () => {
    const params = {
      equipment_master_fallbacks_ids: this.state.fallbacks.map((f) => f.id),
    };

    this.setState({ preventSave: true });

    $.ajax({
      url: `../equipment_master_fallbacks/update_positions`,
      method: "PUT",
      data: params,
      success: (fallbacks) => {
        Cohealo.Growl("", {
          header: "Positions Successfully Updated",
          status: "success",
          icon: "ok",
        });
        // May be reenabled following upgrade to rails 2.6 and no longer need window.location.reload()

        // this.setState({
        //   fallbacks,
        //   reorderingEnabled: false
        // });

        // Reload page so fallback change appears in table
        window.location.reload();
      },
      error: (event, data) => {
        Cohealo.Growl("", {
          header: "Fallback Positions Update Failed",
          status: "danger",
          icon: "remove",
        });
      },
    });
  };

  handleFilterHealthSystem = (selectedHealthSystem) => {
    if (selectedHealthSystem) {
      const filterFacilityOptions = this.props.filterFacilityOptions.filter(
        (f) => f.health_system_id == selectedHealthSystem.value
      );
      this.setState({
        filteredHealthSystem: selectedHealthSystem,
        filteredFacilities: [],
        filterFacilityOptions: filterFacilityOptions,
      });
    } else {
      this.setState({
        filteredHealthSystem: null,
        filteredFacilities: [],
        filterFacilityOptions: this.props.filterFacilityOptions,
      });
    }
  };

  handleFilterFacilities = (selectedFacilities) => {
    this.setState({ filteredFacilities: selectedFacilities });
  };

  onClickReorder = () => {
    if (this.state.reorderingEnabled) {
      this.setState({
        reorderingEnabled: false,
        fallbacks: this.state.originalFallbacksOrder,
      });
    } else {
      this.setState({
        reorderingEnabled: true,
        originalFallbacksOrder: this.state.fallbacks,
      });
    }
  };

  fallbackBtns = () => {
    const disabledReorder =
      this.state.fallbacks.length <= 1 ||
      this.state.newFallbackPill ||
      this.state.preventSave;
    if (this.props.editable) {
      return (
        <div className="fallbacks-btns">
          <a
            onClick={this.onClickNew}
            className="btn btn-info btn-mini"
            disabled={this.state.reorderingEnabled || this.state.preventSave}
          >
            Add
          </a>
          <a
            onClick={disabledReorder ? undefined : this.onClickReorder}
            className="btn btn-mini"
            style={{ marginLeft: 10 }}
            disabled={disabledReorder}
          >
            {this.state.reorderingEnabled ? "Cancel" : "Reorder"}
          </a>
        </div>
      );
    }
  };

  filterSearchBoxes = () => {
    const facilityPlaceholder = this.state.filteredHealthSystem
      ? "All Facilities"
      : "Select a Health System";
    const selectArgs = {
      value: this.state.filteredFacilities,
      onChange: this.handleFilterFacilities,
      options: this.state.filterFacilityOptions,
      placeholder: facilityPlaceholder,
      disabled: !this.state.filteredHealthSystem,
      className: "react-select-container format-rich",
    };

    if (this.state.fallbacks.length > 0 && !this.state.newFallbackPill) {
      if (this.props.healthSystems.length > 1) {
        return (
          <div className="fallback-filter-container pull-right">
            <div className="fallback-filter health-system">
              <Select
                value={this.state.filteredHealthSystem}
                onChange={this.handleFilterHealthSystem}
                options={this.props.healthSystems}
                placeholder="All Health Systems"
                className="react-select-container format-rich"
                classNamePrefix="react-select"
              />
            </div>
            <div className="fallback-filter facility">
              <Select {...selectArgs} multi classNamePrefix="react-select" />
            </div>
          </div>
        );
      } else {
        return (
          <div className="fallback-filter-container pull-right">
            <div className="fallback-filter facility">
              <Select {...selectArgs} multi />
            </div>
          </div>
        );
      }
    }
  };

  render() {
    const emptyBoxClasses = `text-center ${
      this.fallbackPills()?.length == 0 && !this.state.newFallbackPill
        ? ""
        : "hidden"
    }`;

    return (
      <div className="row-fluid">
        <div className="fallback-title">
          <h2>Fallbacks</h2>
        </div>
        <div className="inventory-info-title">
          <div className="fallbacks-controls">
            <div>
              When unavailable, the platform will fallback to the following
              equipment
            </div>
            {this.fallbackBtns()}
          </div>
        </div>
        <div className="inventory-info-table">
          {this.filterSearchBoxes()}
          <div className="row-fluid">
            <div className="span12">
              <div className="row-fluid table-headers">
                <div className="span6 table-heading">
                  <h4>Fallback Equipment</h4>
                </div>
                <div className="span3 table-heading text-center">
                  <h4>Allowed at</h4>
                </div>
                <div className="span3 table-heading text-center">
                  <h4>Denied at</h4>
                </div>
              </div>
              <div className="row-fluid">
                <div className="fallbacks-content">
                  <div className="row-fluid fallbacks-container">
                    <div className={`${emptyBoxClasses}`}>
                      <div className="no-fallback-box">
                        <div className="no-fallback-content">
                          <img src="/images/box-swap@1x.png" alt="" />
                          No equipment listed
                        </div>
                      </div>
                    </div>
                    <div className="row-fluid fallbacks-list">
                      {this.state.reorderingEnabled
                        ? this.renderDragAndDrop()
                        : this.fallbackPills()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EquipmentMasterFallbackForm.propTypes = {
  primaryId: PropTypes.number,
  fallbacks: PropTypes.array,
  equipmentMasters: PropTypes.array,
  healthSystems: PropTypes.array,
  filterFacilityOptions: PropTypes.array,
  facilities: PropTypes.array,
  editable: PropTypes.bool,
};

export default EquipmentMasterFallbackForm;
