import React from "react";

import _ from "lodash";
import { Box, Tooltip } from "@mui/material";
import clsx from "clsx";

import {
  EquipmentSelect,
  LazySelect,
  Select,
} from "../../index/tables/filterPanel/GridFilterAutocomplete";
import { buildOperatorItems, getValueAsStringInventory } from "./utils";
import ActionCell from "../../patient_cases/index/cells/Action";
import { nameOrKeyValueFmt, namesOrKeyValueFmt } from "../tables/utils";
import EquipmentPill, { NoEquipmentPill } from "../cells/EquipmentPill";
import {
  PHYSICIANS_PATH,
  PROCEDURES_PATH,
  SERVICE_LINES_PATH,
} from "../../utils/capi";

// Common Column fields
export const COL_ACTION = {
  disableReorder: true,
  field: "actions",
  type: "actions",
  // headerName: "Actions",
  cellClassName: "patient-case-cell-action",
  renderCell: (params) => <ActionCell value={params} />,
  resizable: false,
  maxWidth: 50,
};

// NOTE: getValueAsString for some reason does not transform
// correctly on the tooltip, appearing as
// Campus is any of [Object object]
// Please adjust accordingly as needed
const getValueAsString = (val) =>
  val.map((sel) => sel.attributes.name).join(", ");

export const COL_CAMPUS = {
  field: "campus",
  filterOperators: buildOperatorItems([
    {
      value: "isAnyOf",
      getValueAsString,
      InputComponent: Select,
      InputComponentProps: {
        getOptionLabel: (opt) => opt.attributes.name,
        label: "Campus",
        multiple: true,
        optionType: "campuses",
      },
    },
  ]),
  headerName: "Campus",
  maxWidth: 150,
  renderCell: (params) =>
    params.value && (
      <Tooltip placement="top" title={params.value.name}>
        <span>{params.value.siteCode}</span>
      </Tooltip>
    ),
  type: "singleSelectCampus",
  valueFormatter: (params) => nameOrKeyValueFmt(params, "siteCode"),
};

export const COL_FACILITY = {
  field: "facility",
  filterOperators: buildOperatorItems([
    {
      value: "isAnyOf",
      getValueAsString,
      InputComponent: Select,
      InputComponentProps: {
        getOptionLabel: (opt) =>
          `${opt.attributes.name} (${opt.attributes.siteCode})`,
        label: "Facility",
        multiple: true,
        optionType: "facilities",
      },
    },
  ]),
  headerName: "Facility",
  maxWidth: 150,
  renderCell: (params) => (
    <Tooltip placement="top" title={params.value.name}>
      <span>{params.value.siteCode}</span>
    </Tooltip>
  ),
  type: "singleSelectFacility",
  valueFormatter: (params) => nameOrKeyValueFmt(params, "siteCode"),
};

export const COL_INVENTORY_ITEMS = {
  field: "inventoryItems",
  cellClassName: (_) => clsx("MuiDataGrid-cell", { list: true }),
  description: "Manually or automatically reserved equipment",
  filterOperators: buildOperatorItems([
    {
      value: "isAnyOf",
      getValueAsString: getValueAsStringInventory,
      InputComponent: EquipmentSelect,
    },
    {
      value: "isReserved",
    },
    {
      value: "isNotReserved",
    },
  ]),
  headerName: "Reserved Equipment",
  minWidth: 100,
  renderCell: (params) =>
    params.value?.map((ii, index) => {
      const { equipmentPreference, patientCase } = ii.attributes;
      const key = `${patientCase?.id || equipmentPreference?.id}-${index}`;
      return (
        <EquipmentPill
          colDef={params.colDef}
          extras={{ facilityId: params.row.facility.id }}
          key={key}
          rowId={params.id}
          value={ii}
        />
      );
    }) ??
    (!params.row.requiresEquipment && (
      <NoEquipmentPill
        rowId={params.id}
        value={params.row.requiresEquipmentEquipmentPreferenceId}
      />
    )),
  width: 450,
};

export const COL_PROCEDURES = {
  field: "procedures",
  description: "Procedures",
  flex: 1,
  cellClassName: (_) => clsx("MuiDataGrid-cell", { list: true }),
  filterOperators: buildOperatorItems([
    {
      value: "containAnyOf",
      InputComponent: LazySelect,
      InputComponentProps: {
        freeSolo: true,
        label: "Procedure",
        multiple: true,
        requestUrl: PROCEDURES_PATH,
      },
    },
  ]),
  headerName: "Procedures",
  minWidth: 100,
  renderCell: (params) =>
    params.value?.map((v) => (
      <Box key={`${params.id}-procedure-${v.id}`}>{v.coded_name}</Box>
    )),
  type: "multiSelectProcedure",
  valueFormatter: namesOrKeyValueFmt,
  valueGetter: (params) => params.value,
  width: 250,
};

export const COL_PHYSICIANS = {
  field: "physicians",
  description: "Physicians",
  cellClassName: (_) => clsx("MuiDataGrid-cell", { list: true }),
  filterOperators: buildOperatorItems([
    {
      value: "containAnyOf",
      InputComponent: LazySelect,
      InputComponentProps: {
        freeSolo: true,
        label: "Physician",
        multiple: true,
        requestUrl: PHYSICIANS_PATH,
      },
    },
  ]),
  headerName: "Physicians",
  minWidth: 100,
  renderCell: (params) =>
    params.value?.map((v) => (
      <Box key={`${params.id}-physician-${v.id}`}>{v.coded_name}</Box>
    )),
  type: "multiSelectPhysician",
  valueFormatter: namesOrKeyValueFmt,
  valueGetter: (params) => params.value,
  width: 200,
};

export const COL_SERVICE_LINE = {
  field: "serviceLine",
  filterOperators: buildOperatorItems([
    {
      value: "containAnyOf",
      InputComponent: LazySelect,
      InputComponentProps: {
        label: "Service Line",
        multiple: true,
        optionType: "serviceLines",
        requestDataParser: (data) => {
          const uniqueMap = {};
          data.forEach((sl) => {
            const name = (sl.label ?? sl.attributes.name)
              .split(" ")
              .reduce((memo, word) => {
                memo += ` ${_.capitalize(word)}`;
                return memo;
              }, "");
            if (uniqueMap[name] === undefined)
              uniqueMap[name] = { data: { name }, label: name, value: name };
          });
          return Object.values(uniqueMap);
        },
        requestTimeout: 800,
        requestUrl: SERVICE_LINES_PATH,
      },
    },
  ]),
  headerName: "Service Line",
  maxWidth: 200,
  type: "singleSelectServiceLine",
  valueFormatter: nameOrKeyValueFmt,
  valueGetter: (params) => params.value?.name,
};

export const COL_SERVICE_LINE_HS = {
  field: "serviceLine",
  filterOperators: buildOperatorItems([
    {
      value: "isAnyOf",
      getValueAsString,
      InputComponent: Select,
      InputComponentProps: {
        getOptionLabel: (opt) => opt.attributes.name,
        label: "Service Line",
        multiple: true,
        optionType: "serviceLines",
      },
    },
  ]),
  headerName: "Service Line",
  maxWidth: 200,
  type: "singleSelectServiceLine",
  valueFormatter: nameOrKeyValueFmt,
  valueGetter: (params) => params.value?.name,
};

// TODO: Perhaps COL_USER
export const COL_UPDATED_BY = {
  field: "updatedBy",
  headerName: "Updated By",
  maxWidth: 150,
  valueFormatter: nameOrKeyValueFmt,
  valueGetter: (params) => params.value?.name,
};
