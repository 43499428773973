import React from "react";
import PropTypes from "prop-types";

const InventoryInfo = ({ inventory }) => {
  if (inventory.isRentableInventory) {
    return (
      <span className="label small status-rental">
        <span className="content">Rental</span>
      </span>
    );
  } else {
    return (
      <React.Fragment>
        {inventory.serial && (
          <span className="label serial-number">
            <strong>SN:</strong> {inventory.serial}
          </span>
        )}
        {inventory.assetTag && (
          <span className="label serial-number">
            <strong>Asset:</strong> {inventory.assetTag}
          </span>
        )}
        {inventory.cohealoTag && (
          <span className="label serial-number">
            <strong>Cohealo:</strong> {inventory.cohealoTag}
          </span>
        )}
      </React.Fragment>
    );
  }
};

InventoryInfo.propTypes = {
  inventory: PropTypes.object.isRequired
};

export default InventoryInfo;
