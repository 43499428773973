import React from "react";
import PropTypes from "prop-types";

import { useDelayedInput } from "../../utils/useDelayedChange";

export default function Input({
  className,
  disabled = false,
  name,
  onChange,
  placeholder,
  type,
  value,
}) {
  const [inputValue, handleInputChange] = useDelayedInput(value, onChange);

  return (
    <input
      className={className ?? "input-block-level"}
      disabled={disabled}
      {...(name && { name: name })}
      {...(placeholder && { placeholder: placeholder })}
      onChange={handleInputChange}
      type={type ?? "text"}
      value={inputValue}
    />
  );
}

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};
