import React from "react";
import PropTypes from "prop-types";

import {
  UnavailableWarningLabel,
  UnavailableErrorLabel,
} from "./inventory_select_label";

class InventorySelect extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // TODO: Remove use of select2 😠 and use react select instead.
    $(".inventory-pill-form select.equipment-master")
      .select2({
        formatResult: this.formatEquipment,
        formatSelection: this.formatEquipment,
        escapeMarkup: function (m) {
          return m;
        },
      })
      .on("select2-selecting", (event) => {
        const eqm = $(event.choice.element[0]).data("item");
        this.props.onChangeEquipmentMaster(
          parseInt(event.val),
          eqm.weightInfo,
          eqm.dimensionInfo
        );
      });

    $(".inventory-pill-form select.equipment-source")
      .select2({
        formatResult: this.formatInventory,
        formatSelection: this.formatInventory,
        escapeMarkup: function (m) {
          return m;
        },
      })
      .on("select2-selecting", (event) => {
        if (!event.choice) {
          return;
        }

        const inventory = $(event.choice.element[0]).data("item");

        inventory.unavailable && inventory.id !== this.props.initialInventory.id
          ? this.props.onSelectUnavailableInventory()
          : this.props.onSelectAvailableInventory();
        this.props.onChangeInventory(inventory);
      });
    this.props.onChangeInventory(this.props.savedInventory);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchingInventories !== this.props.fetchingInventories) {
      $(".inventory-pill-form select.equipment-source").select2(
        "enable",
        !this.props.fetchingInventories
      );
    }

    if (
      prevProps.equipmentMasterId !== this.props.equipmentMasterId ||
      prevProps.fetchingInventories !== this.props.fetchingInventories
    ) {
      $(".inventory-pill-form select.equipment-master").trigger("change");
      $(".inventory-pill-form select.equipment-source").trigger("change");
      this.props.onSelectUnavailableInventory();
    }
  }

  formatEquipment = (state) => {
    const originalOption = state.element;
    const item = $(originalOption).data("item");

    return `<span class="equipment-master format-rich selected"
                  date-dimensions="${item.dimensionInfo}"
                  date-weight="${item.weightInfo}">
              <span data-model="master-thumb">
                <img
                  src=${item.imageUrl}
                  alt=${item.name}
                  width="44"
                  height="44"
                />
              </span>
              <span class="primary">
                <span class="manufacturer-name">
                  ${item.manufacturerName}
                </span>
              </span>
              <span class="secondary">
                <span class="model-title">
                  ${item.name}
                </span>
              </span>
            </span>`;
  };

  formatInventory = (state) => {
    const originalOption = state.element;
    const item = $(originalOption).data("item");
    const unavailableBadge =
      item.unavailable && item.id !== this.props.initialInventory.id
        ? this.renderUnavailableBadge(item)
        : "";
    const notShareableIcon =
      item.status == "not-shareable" ? this.renderNotShareableIcon() : "";
    const lenderName = this.renderLenderName(item);
    const inventoryInfo = this.renderInventoryInfo(item);
    const accessories = this.renderAccessories(item);

    return `<span class="equipment-source format-rich selected">
              <span class="primary">
                ${unavailableBadge}
                <span class="shareable-icon">
                  ${notShareableIcon}
                </span>
                <span class="source-lender">
                  ${lenderName}
                </span>
                <span class="pull-right">
                  ${inventoryInfo}
                </span>
              </span>
              <span class="secondary">
                <div class="source-meta">
                  Accessories:
                  <span class="accessory-total">
                    ${accessories}
                  </span>
                </div>
              </span>
            </span>`;
  };

  renderUnavailWarningBadge = () => {
    return `<span class="symbol" style="top: 1px; position: relative;">
                <i class="sprite-icon sprite-warning-xs-fullcolor" style="vertical-align: top;"></i>
              </span>`;
  };

  renderUnavailErrorBadge = () => {
    return `<span class="label small status-unavailable" style="display: inline-block; line-height: 14px; margin-left: 0; padding: 0 2px;">
              <span class="symbol" style="top: 1px; position: relative;">
                <i class="sprite-icon sprite-unavailable-xs-white" style="margin-right: 0;"></i>
              </span>
            </span>`;
  };

  renderUnavailableBadge = ({ id, unavailable }) => {
    if (unavailable && id !== this.props.initialInventory.id)
      return this.props.canSwapInventory
        ? this.renderUnavailWarningBadge()
        : this.renderUnavailErrorBadge();
    else {
      return "";
    }
  };

  renderNotShareableIcon = () => {
    return `<i class="not-shareable-icon sprite-icon sprite-not-shareable-xs-grey" />`;
  };

  renderLenderName = ({
    isRentableInventory,
    homeFacilityName,
    homeFacilitySiteCode,
  }) => {
    return isRentableInventory
      ? `${homeFacilityName}`
      : `${homeFacilityName} (${homeFacilitySiteCode})`;
  };

  renderInventoryInfo = ({
    isRentableInventory,
    cohealoTag,
    assetTag,
    serial,
    collaborativelyPurchased,
    collabLabel,
    hsShareable,
  }) => {
    if (isRentableInventory) {
      return `<span class="label small status-rental">
                <span class="content">Rental</span>
              </span>`;
    } else {
      let tags = "";

      if (collaborativelyPurchased) {
        tags += `<span class="label collab pull-right">
                    <span class="symbol">
                      <i class="icon icon-star"/>
                    </span>
                    <span class="content">${collabLabel}</span>
                 </span>`;
      }
      if (cohealoTag) {
        tags += `<span class="label serial-number pull-right">
                   <strong>Cohealo:</strong> ${cohealoTag}
                 </span>`;
      }

      if (assetTag) {
        tags += `<span class="label serial-number pull-right">
                   <strong>Asset:</strong> ${assetTag}
                 </span>`;
      }

      if (serial) {
        tags += `<span class="label serial-number pull-right">
                   <strong>SN:</strong> ${serial}
                 </span>`;
      }

      if (hsShareable) {
        tags += `<span class="shareable-icon pull-right">
                   <i class="icon-globe" />
                 </span>`;
      }

      return tags;
    }
  };

  renderAccessories = ({ isRentableInventory, accessories }) => {
    if (isRentableInventory) {
      return "<i>Must specify with rental company</i>";
    } else {
      return accessories.length > 0
        ? accessories.map((am) => `${am.name} (${am.quantity})`).join(", ")
        : "(0)";
    }
  };

  onChangeEquipmentMaster = (event) => {};

  onChangeInventory = (event) => {};

  onClickRecheck = (event) => {
    $(".inventory-pill-form select.equipment-source").select2("disable");
    $(".unavailable-container").addClass("refresh-container");
    $(".refresh-container").removeClass("unavailable-container");
    $(".unavailable-label").addClass("hidden");
    $(".refresh-label").removeClass("hidden");
    this.props.onClickRecheck();
  };

  onClickOK = (event) => {
    event.preventDefault();
    this.props.onSaveInventory();
    this.props.onFinishedEditing();
  };

  onClickCancel = (event) => {
    event.preventDefault();
    this.props.onCancelInventory();
  };

  render() {
    const {
      editing,
      disableOKButton,
      equipmentMasterId,
      equipmentMasters,
      initialInventory,
      inventories,
      formObjectName,
      savedInventory,
      selectedInventory,
      unavailableLink,
      canSwapInventory,
    } = this.props;

    const inventoryPillClasses = `inventory-pill ${editing ? "" : "hidden"}`;

    let unavailableUrl = "";
    if (selectedInventory) {
      unavailableUrl = `${unavailableLink}&equipment_master_id=${equipmentMasterId}&inventory_item_id=${selectedInventory.id}`;
    }
    let unavailableState = canSwapInventory
      ? "unavail-warning"
      : "unavail-error";
    let unavailableContainerClasses = "span10";
    let unavailableLabelClasses = `unavailable-label ${unavailableState}`;

    if (
      selectedInventory &&
      selectedInventory.unavailable &&
      selectedInventory.id !== initialInventory.id
    ) {
      unavailableContainerClasses += ` unavailable-container ${unavailableState}`;
    } else {
      unavailableLabelClasses += " hidden";
    }

    return (
      <div className={inventoryPillClasses}>
        <div className="row-fluid">
          <div className="span2">Equipment: </div>
          <div className="span10">
            <select
              className="input-block-level format-rich equipment-master"
              value={equipmentMasterId}
              onChange={this.onChangeEquipmentMaster}
            >
              {equipmentMasters &&
                equipmentMasters.map((em) => {
                  return (
                    <option
                      key={em.id}
                      value={em.id}
                      data-item={JSON.stringify(em)}
                    >
                      {em.manufacturerName} {em.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="row-fluid" style={{ marginTop: "20px" }}>
          <div className="span2">Inventory: </div>
          <div className={unavailableContainerClasses}>
            {canSwapInventory ? (
              <UnavailableWarningLabel
                unavailableLabelClasses={unavailableLabelClasses}
              />
            ) : (
              <UnavailableErrorLabel
                unavailableLabelClasses={unavailableLabelClasses}
                unavailableUrl={unavailableUrl}
                onClickRecheck={this.onClickRecheck}
              />
            )}
            <div className="refresh-label hidden">
              <span className="symbol">
                <img
                  src="/images/loading-mini.gif"
                  alt="loading"
                  height="13"
                  width="13"
                />
              </span>
              <span> Checking availability...</span>
            </div>

            <select
              className="input-block-level format-rich equipment-source"
              value={savedInventory.id}
              name={formObjectName}
              onChange={this.onChangeInventory}
              data-placeholder={"Select Inventory..."}
            >
              <option />
              {inventories &&
                inventories.map((inv) => {
                  return (
                    <option
                      key={inv.id}
                      value={inv.id}
                      data-item={JSON.stringify(inv)}
                    >
                      {inv.homeFacilityName} {inv.serial} {inv.assetTag}{" "}
                      {inv.cohealoTag}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="row-fluid" style={{ marginTop: "20px" }}>
          <div className="pull-right">
            <button
              className="btn btn-small btn-primary"
              onClick={this.onClickOK}
              disabled={disableOKButton}
            >
              OK
            </button>
            <button
              className="btn btn-small"
              style={{ marginLeft: "15px" }}
              onClick={this.onClickCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

InventorySelect.propTypes = {
  editing: PropTypes.bool,
  equipmentMasterId: PropTypes.number,
  equipmentMasters: PropTypes.array.isRequired,
  disableOKButton: PropTypes.bool,
  fetchingInventories: PropTypes.bool,
  formObjectName: PropTypes.string.isRequired,
  initialEquipmentMaster: PropTypes.object,
  initialInventory: PropTypes.object,
  inventories: PropTypes.array.isRequired,
  onChangeEquipmentMaster: PropTypes.func,
  onChangeInventory: PropTypes.func,
  onFinishedEditing: PropTypes.func,
  onSaveInventory: PropTypes.func,
  onClickRecheck: PropTypes.func,
  onSelectAvailableInventory: PropTypes.func,
  onSelectUnavailableInventory: PropTypes.func,
  savedInventory: PropTypes.object,
  selectedInventory: PropTypes.object,
  unavailableLink: PropTypes.string,
  canSwapInventory: PropTypes.bool,
};

export default InventorySelect;
