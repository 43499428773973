import React from "react";
import PropTypes from "prop-types";

class MovesContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameErr: this.initError(props.name),
      emailErr: this.initError(props.email),
      phoneErr: this.initError(props.phone),
      name: props.name || "",
      description: props.description || "",
      email: props.email || "",
      phone: props.phone || "",
    };
  }

  componentDidMount() {
    if (this.props.focusable) this.focusTextInput();
  }

  /*
      Initializers
  */
  initError = (attr) => (attr ? !attr.length : true);

  /*
      Misc
  */
  focusTextInput = () => this.textInput.focus();

  /*
      Validators
  */

  // simple validation of presence
  // name is required
  // Either email or phone is required

  // before:render validation
  validateRequiredFields = () => {
    const nameErr = !this.state.name.length;
    const emailErr = !this.state.email.length;
    const phoneErr = !this.state.phone.length;

    return !(nameErr || (emailErr && phoneErr));
  };

  /*
      Handlers
  */
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.validateRequiredFields()) {
      this.props.handleFormSubmit({
        name: this.state.name,
        description: this.state.description,
        email: this.state.email,
        phone: this.state.phone,
      });
    }
    // else inputs with errors will be highlighted
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      // enter
      this.handleSubmit(e);
    }
  };

  /* Form Input handlers */
  handlePhoneChange = (e) => {
    this.setState({ phone: e.target.value }, () => {
      this.setState({ phoneErr: !this.state.phone?.length });
    });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value }, () => {
      this.setState({ nameErr: !this.state.name?.length });
    });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value }, () => {
      this.setState({ emailErr: !this.state.email?.length });
    });
  };

  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  };

  /*
      Renderers
  */

  renderTextInput = (input, inputString, handler, hasError) => {
    const controlClass = `control-group${hasError ? " error" : ""}`;

    return (
      <div className={controlClass}>
        <input
          className="input-block-level moves-contact-form-input"
          type="text"
          placeholder={
            inputString.charAt(0).toUpperCase() + inputString.slice(1)
          }
          defaultValue={input}
          onChange={handler}
          onKeyUp={this.handleKeyUp}
          formNoValidate
          ref={
            inputString === "name"
              ? (input) => {
                  this.textInput = input;
                }
              : null
          }
        />
      </div>
    );
  };

  renderHelpBlock = () => {
    const errMsg = this.validateRequiredFields() ? null : (
      <div>
        <i className="sprite-icon sprite-warning-s-fullcolor-red" />
        &nbsp;Name and either email or phone are required.
      </div>
    );

    return <div className="text-center help-block">{errMsg}</div>;
  };

  render() {
    const { name, nameErr, description, email, emailErr, phone, phoneErr } =
      this.state;

    const validateSecondaryInfo = emailErr && phoneErr;

    return (
      <div className="form-inline">
        {this.renderHelpBlock()}
        <div className="row-fluid">
          <span className="span6">
            {this.renderTextInput(name, "name", this.handleNameChange, nameErr)}
          </span>
          <span className="span6">
            {this.renderTextInput(
              description,
              "position",
              this.handleDescriptionChange,
              false
            )}
          </span>
        </div>
        <div className="row-fluid">
          <span className="span6">
            {this.renderTextInput(
              email,
              "email",
              this.handleEmailChange,
              validateSecondaryInfo
            )}
          </span>
          <span className="span6">
            {this.renderTextInput(
              phone,
              "phone",
              this.handlePhoneChange,
              validateSecondaryInfo
            )}
          </span>
        </div>
        <div className="text-right">
          <div
            className="btn btn-link btn-mini collapse-pill"
            onClick={this.props.handleFormCancel}
          >
            Cancel
          </div>
          <button className="btn btn-mini btn-save" onClick={this.handleSubmit}>
            Add
          </button>
        </div>
      </div>
    );
  }
}

MovesContactForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  focusable: PropTypes.bool,
  handleFormSubmit: PropTypes.func,
  handleFormCancel: PropTypes.func,
};

export default MovesContactForm;
