export const DEFAULT_SELECT_STYLES = {
  clearIndicator: (provided, state) => ({
    ...provided,
    height: "17px",
    marginBottom: "3px",
    padding: "0px",
    width: "17px",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    minHeight: "32px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
    paddingRight: "5px",
  }),
  indicatorSeparator: () => {},
  input: (provided, state) => ({
    ...provided,
    input: {
      borderColor: "#FFF !important", // override needed due to select2/bootstrap dep
      boxShadow: "none !important",
      margin: "0px !important",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    margin: "0px",

    ".collab-purch-icon": {
      color: "#666",
      marginLeft: "2px",
    },
    ".not-shareable-icon": {
      marginLeft: "2px",
      marginBottom: "2px",
    },
    ".sprite-inactive-s-grey": {
      marginLeft: "2px",
      marginBottom: "2px",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: "1px solid #d6d6d6",

    "&:first-of-type": {
      borderTop: "none",
    },
  }),
};
