import React from "react";

// Renders a (JQuery) Growl Alert onto the screen
//
// metaAlerts is the meta object received from
// the response from CAPI.
// Usually, keyed as data.meta.alerts from an axios response
// It is generally an array of alerts.

export const renderAlerts = (metaAlerts = []) =>
  metaAlerts.forEach(({ alert_type, heading, message, options }) => {
    growl(heading, message, { status: alert_type, ...options });
  });

// Deferred Alerts
//
// Utilize localstorage to defer alerts to the next page
const deferredAlertKey = "alerts";

export const getLocalStorageAlerts = () => {
  const alerts = localStorage.getItem(deferredAlertKey);

  return alerts?.length ? JSON.parse(alerts) : alerts;
};

export const wipeLocalStorageAlerts = () =>
  localStorage.removeItem(deferredAlertKey);

export const setLocalStorageAlerts = (alerts) =>
  localStorage.setItem(deferredAlertKey, JSON.stringify(alerts));

// Fire off a growl message. For example:
//
//  Cohealo.Growl("hi there", {
//    header: 'A Message',
//    status: 'success',
//    icon: 'money' })
//
//  Cohealo.Growl("hi there", {
//    header: 'A Message',
//    status: 'danger',
//    icon: 'globe' })
const defaultOptions = {
  handlers: {
    closeHandler: null,
    enableClose: true,
    enableCloseAll: true,
  },
  header: "",
  icon: "warning-sign",
  status: "success",
};

// defaults
const alertTypeToIcon = {
  danger: "warning-sign",
  success: "ok",
};

export const growl = (headerMessage, message = "", options = {}) => {
  const { icon, status, ...rest } = options;

  Cohealo.Growl(message, {
    header: headerMessage,
    icon: (icon || alertTypeToIcon[status]) ?? "ok",
    status: status,
    ...rest,
  });

  // TODO: Growls look funny for some reason. Will use Cohealo.Growl for now
  // until deprecated
  // options = Object.keys(options).length
  //   ? {
  //       ...defaultOptions,
  //       ...options,
  //       ...defaultOptions.handlers,
  //       ...options.handlers,
  //     }
  //   : defaultOptions;
  // const { handlers, ...rest } = options;
  //
  // $.jGrowl.defaults.closer = handlers.enableCloseAll;
  // const growlConfig = {
  //   ...rest,
  //   life: 5000,
  //   sticky: options.status != "success",
  //   theme: options.status,
  //   closeTemplate: handlers.enableClose ? "x" : "",
  //   beforeOpen: (e, m, o) => {
  //     if (o.closeTemplate == "") $(e).find(".jGrowl-close").remove();
  //   },
  //   close: handlers.closeHandler ? () => handlers.closeHandler : null,
  // };

  // $.jGrowl(iconString(options.icon, message), growlConfig);
};

const iconString = (icon, message) =>
  `<i className="icon icon-${icon}"}></i>${message}`;
