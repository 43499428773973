import { useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

export default function ReactPortal({ children, wrapperId }) {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);

    // TODO: handle error
    if (!element) {
      console.log("ReactPortal error");
    }
    setWrapperElement(element);
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
}
