import React from "react";
import PropTypes from "prop-types";

import SelectFields, {
  LiField,
} from "../../../forms/selects/SelectFields/SelectFields";
import Preview from "./Preview";
import { PROCEDURES_PATH } from "../../../utils/capi";
import { Container } from "./styles";

export default function Procedures({
  handleChange,
  isDisabled = false,
  isEditable = true,
  selected,
}) {
  const attributes =
    selected?.filter((sel) => !sel?._destroy && sel?.procedurePattern) ?? [];

  const onChange = (changes) => {
    const newAttributes = changes.map((field) => {
      const { _destroy, id, label } = field;

      const updatedField = {
        ...(_destroy && { _destroy }),
        id,
        procedurePattern: label,
      };

      return updatedField;
    });

    handleChange([
      ...selected?.filter((sel) => !sel?.procedurePattern || sel?._destroy),
      ...newAttributes,
    ]);
  };

  const fields = attributes.map(({ id, procedurePattern }) => ({
    id,
    label: procedurePattern,
    value: procedurePattern,
  }));

  return (
    <Container hasPlaceholder={!fields.length}>
      <Fields
        className="span6"
        handleChange={onChange}
        isDisabled={isDisabled}
        isEditable={isEditable}
        selected={fields}
      />
      <Preview className="span6" selected={fields.map((sel) => sel.label)} />
    </Container>
  );
}

const defaultComponents = {
  FieldLabel: () => (
    <LiField className="select-field-placeholder" hasBoolLabel={false}>
      <span className="muted">Please select a procedure</span>
    </LiField>
  ),
  Placeholder: () => (
    <LiField className="select-field-placeholder">
      <span className="muted">Please select a procedure</span>
    </LiField>
  ),
};

const Fields = ({
  className,
  handleChange,
  isDisabled,
  isEditable,
  selected,
}) => {
  return (
    <SelectFields
      alias={{
        objectId: "procedurePattern",
        name: "procedure",
        many: "Procedures",
      }}
      classNameExtras={className}
      creatable
      components={defaultComponents}
      handleChange={handleChange}
      id="procedure-select-field"
      isDisabled={isDisabled}
      isEditable={isEditable}
      requestUrl={PROCEDURES_PATH}
      required
      selected={selected}
    />
  );
};

Fields.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.object),
};
