import React from "react";
import PropTypes from "prop-types";

import InventoryPill from "./inventory_pill";
import InventorySelect from "./inventory_select";

class InventoryPillForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableOKButton: false,
      editing: false,
      equipmentMasterId: this.props.equipmentMaster
        ? this.props.equipmentMaster.id
        : 0,
      fetchingInventories: false,
      initialEquipmentMaster: this.props.equipmentMaster,
      initialInventory: this.props.inventory,
      savedInventory: this.props.inventory,
      selectedInventory: this.props.inventory,
      weightInfo: this.props.equipmentMaster.weightInfo,
      dimensionInfo: this.props.equipmentMaster.dimensionInfo,
      refreshCount: 0,
    };
  }

  componentDidMount() {
    $(".tip").tooltip();
  }

  componentDidUpdate() {
    $(".tip").tooltip();
  }

  onClickEdit = () => {
    this.setState({ editing: true });
  };

  onFinishedEditing = () => {
    this.setState({ editing: false });
  };

  onChangeEquipmentMaster = (
    equipmentMasterId,
    weightInfo,
    dimensionInfo,
    setInventory = null
  ) => {
    this.setState({
      equipmentMasterId,
      fetchingInventories: true,
      dimensionInfo: dimensionInfo,
      weightInfo: weightInfo,
    });

    const params = {
      equipment_master_id: equipmentMasterId,
      destination_facility_id: this.props.destinationFacilityId,
      busy_start_date: this.props.busyStartDate,
      busy_end_date: this.props.busyEndDate,
      include_rentables: false,
    };

    $.ajax({
      url: this.props.findInventoriesUrl,
      data: params,
      success: (inventories) => {
        this.setState({
          inventories,
          selectedInventory: setInventory,
          fetchingInventories: false,
        });
      },
    });
  };

  onChangeInventory = (selectedInventory) => {
    this.setState({ selectedInventory });
  };

  onSaveInventory = () => {
    this.setState({ savedInventory: this.state.selectedInventory });
  };

  onCancelInventory = () => {
    this.onChangeEquipmentMaster(
      this.state.initialEquipmentMaster.id,
      this.state.initialEquipmentMaster.weightInfo,
      this.state.initialEquipmentMaster.dimensionInfo,
      this.state.initialInventory
    );

    this.setState({ savedInventory: this.state.initialInventory });
    this.onFinishedEditing();
  };

  onSelectAvailableInventory = () => {
    this.setState({ disableOKButton: false });
  };

  onSelectUnavailableInventory = () => {
    const disableOKButton = !this.props.canSwapInventory;
    this.setState({ disableOKButton: disableOKButton });
  };

  onClickRecheck = () => {
    this.setState({ fetchingInventories: true });
    const params = {
      equipment_master_id: this.state.equipmentMasterId,
      destination_facility_id: this.props.destinationFacilityId,
      busy_start_date: this.props.busyStartDate,
      busy_end_date: this.props.busyEndDate,
      include_rentables: false,
    };

    $.ajax({
      url: this.props.findInventoriesUrl,
      data: params,
      success: (inventories) => {
        const matchedInventory = inventories.find(
          (x) => x.id == this.state.selectedInventory.id
        );
        this.setState({
          inventories,
          fetchingInventories: false,
          savedInventory: matchedInventory,
          refreshCount: this.state.refreshCount + 1,
        });
        matchedInventory.unavailable
          ? this.onSelectUnavailableInventory()
          : this.onSelectAvailableInventory();
      },
    });
  };

  render() {
    return (
      <div className="inventory-pill-form">
        <InventoryPill
          editing={this.state.editing}
          enableEditing={this.props.formObjectName ? true : false}
          inventory={this.state.savedInventory}
          onClickEdit={this.onClickEdit}
          dimensionInfo={this.state.dimensionInfo}
          weightInfo={this.state.weightInfo}
          fallbackFor={this.props.fallbackFor}
        />
        {this.props.formObjectName ? (
          <InventorySelect
            key={this.state.refreshCount}
            editing={this.state.editing}
            onChangeEquipmentMaster={this.onChangeEquipmentMaster}
            onChangeInventory={this.onChangeInventory}
            onFinishedEditing={this.onFinishedEditing}
            onSaveInventory={this.onSaveInventory}
            onCancelInventory={this.onCancelInventory}
            onClickRecheck={this.onClickRecheck}
            onSelectAvailableInventory={this.onSelectAvailableInventory}
            onSelectUnavailableInventory={this.onSelectUnavailableInventory}
            {...this.props}
            {...this.state}
          />
        ) : null}
      </div>
    );
  }
}

InventoryPillForm.propTypes = {
  busyEndDate: PropTypes.string,
  busyStartDate: PropTypes.string,
  destinationFacilityId: PropTypes.number,
  equipmentMaster: PropTypes.object,
  findInventoriesUrl: PropTypes.string,
  inventory: PropTypes.object.isRequired,
  fallbackFor: PropTypes.string,
  canSwapInventory: PropTypes.bool,
};

export default InventoryPillForm;
