import { grey1, grey1b, grey2, grey3, greyHeader } from "../../utils/base";

export const sxStyles = {
  backgroundColor: grey1,
  border: "none",
  fontSize: "12px",
  height: "100%",
  lineHeight: "16px",
  width: "100%",
  ".MuiDataGrid-cell": {
    alignItems: "flex-start",
    p: "10px 5px",
    "&:focus": {
      backgroundColor: "unset",
      // boxShadow: "0 0 3px 0 rgba(0,0,0,0.15)",
      // outline: `solid ${grey3b} 1px`,
    },
    "&:focus-within": {
      outline: "none",
    },
  },
  // TODO: extract to case specific styling to case component
  "& .MuiDataGrid-cell.patient-case-cell-action": {
    alignItems: "center",
    backgroundColor: grey1,
    p: "20px",
    position: "relative",
    "& .row-edit-icon": {
      display: "none",
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
  "& .MuiDataGrid-cell.duringOn": {
    ".arrow": {
      marginTop: "-1px",
      padding: "0px 5px",
    },
    ".datetimez": {
      textWrap: "balance",
    },
  },
  "& .MuiDataGrid-cell.duringOnWrap": {
    flexWrap: "wrap",
  },
  "& .MuiDataGrid-cell.justifyContentCenter": {
    justifyContent: "center",
  },
  "& .MuiDataGrid-cell.list": {
    alignItems: "stretch",
    flexDirection: "column",
    gap: "5px",
    wordBreak: "break-all",
  },
  ".MuiDataGrid-columnHeader": {
    cursor: "pointer",
    height: "40px !important",
    maxHeight: "40px",
    "&:focus": { outline: "none" },
    "&:focus-within": { outline: "none" },
    "&:hover": {
      backgroundColor: grey2,
    },
  },
  ".MuiDataGrid-columnHeaders": {
    backgroundColor: greyHeader,
    lineHeight: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    textTransform: "uppercase",
  },
  "& .MuiDataGrid-main": {
    border: "1px solid rgb(224, 224, 224)",
    borderRadius: "4px",
  },
  "& .MuiDataGrid-pinnedColumns": {
    backgroundColor: grey1,
    borderLeft: `solid 1px ${grey2}`,
    borderRight: `solid 1px ${grey2}`,
    boxShadow: "0px 0px 19px -4px rgba(0, 0, 0, 0.1)",
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    backgroundColor: "inherit",
    boxShadow: "none",
    top: "7px",
  },
  // TODO: extract to case specific styling to case component
  "& .MuiDataGrid-row.Mui-hovered": {
    backgroundColor: grey1b,
    "& .MuiDataGrid-cell.patient-case-cell-action": {
      backgroundColor: grey1b,
      "& .row-edit-icon": {
        display: "block",
        "&:hover": {
          backgroundColor: grey3,
        },
      },
    },
  },
};
