import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { LazySelect } from "../LazySelect";
import { AAddMore, CellGroup, DivLabel, Li, UlSelectFields } from "./styled";
import _ from "lodash";

export default function SelectFields({
  alias = {
    objectId: "itemId", // physicianId
    name: "item", // physician
    many: "Items", // Procedures
  },
  classNameExtras,
  components = {
    FieldLabel: () => (
      <LiField className="select-field-placeholder" hasBoolLabel={false}>
        None selected
      </LiField>
    ),
    Placeholder: () => (
      <LiField className="select-field-placeholder">None selected</LiField>
    ),
  },
  creatable = false,
  handleChange,
  id,
  isDisabled = false,
  isEditable = true,
  requestUrl,
  required = false,
  // If objects in selected contain 'id', these objects set _destroy on removal.
  // This prevents the fields from losing committed selects from memory.
  // In addition, fields with _destroy => true are filtered from SelectFields.
  selected,
}) {
  const [newField, setNewField] = useState(null);
  const fields = selected ?? [];

  const addNewField = () =>
    setNewField({
      label: "",
      value: null,
    });

  const handleRemove = (index) => {
    if (fields[index]) {
      fields[index]._destroy = true;
      onChange(fields[index], index);
    } else {
      setNewField(null);
    }
  };

  const onChange = (change, index) => {
    if (typeof change?.value !== "number" && !change?.value?.length) return;

    // sanitize key-value pairs of undefined values
    for (const attr in change)
      if ([undefined, null].includes(change[attr])) delete change[attr];

    // Selected "change" are updated in-memory when id is present
    // Therefore, `fields` may not need to be updated for `newFields`
    //
    // handleChange needs to be called regardless of changes in `fields`
    // in order to trigger the parent handlers to side-effect
    const nextFields = [...fields];

    // Following for non-associated selections
    const isNewField = newField === change;
    if (isNewField) setNewField(null);

    if (!isNewField && change._destroy && !change.id) {
      nextFields.splice(index, 1);
    } else {
      nextFields[index] = change;
    }

    handleChange([...nextFields]);
  };

  const selectedWithNewField = (
    newField ? fields.concat([newField]) : fields
  )?.filter((field) => !field._destroy);
  const filterableIds = selectedWithNewField.map((sel) => parseInt(sel.value));

  const mapLabels = ({ id, label }) => (
    <LiField key={`fld-${label || id}`}>
      <DivLabel>{label || id}</DivLabel>
    </LiField>
  );

  const mapSelects = (attr, index) => (
    <div className="fields" key={`fld-${alias.name}-${attr.id || attr.value}`}>
      <LiField>
        <div className="plus">
          <LazySelect
            alias={alias}
            {...(creatable && { creatable: true })}
            {...(attr === newField && { focusOnMount: true })}
            filterableIds={filterableIds.filter((id) => id != attr.id)}
            handleChange={(change) => onChange(change, index)}
            isDisabled={isDisabled}
            requestUrl={requestUrl}
            selected={attr}
          />
        </div>
        {isEditable && !isDisabled && (
          <RemoveButton
            handleClick={() => handleRemove(index)}
            name={alias.name}
          />
        )}
      </LiField>
    </div>
  );

  const fieldsClassName = [alias.many?.toLowerCase(), classNameExtras]
    .filter(Boolean)
    .join(" ");

  const Header = alias.many ? (
    <h3 {...(required && { className: "required" })}>{alias.many}:</h3>
  ) : null;

  return (
    <CellGroup className={fieldsClassName}>
      <div {...(id && { id })} className="control-group">
        {Header}
        <UlSelectFields id={`equipment-preference-${alias.name}-list`}>
          {selectedWithNewField.length
            ? selectedWithNewField.map(isEditable ? mapSelects : mapLabels)
            : isEditable
            ? components.FieldLabel()
            : components.Placeholder()}
        </UlSelectFields>
        {!newField && (
          <AddMoreLink
            handleClick={addNewField}
            isDisabled={isDisabled}
            isEditable={isEditable}
            label={alias.name}
          />
        )}
      </div>
    </CellGroup>
  );
}

const AddMoreLink = ({ handleClick, isDisabled, isEditable, label }) =>
  isEditable ? (
    <AAddMore
      className={isDisabled ? "disabled" : ""}
      href="#"
      id={`add-${label}-button`}
      onClick={(e) => {
        e.preventDefault();
        if (!isDisabled) handleClick();
      }}
    >
      + Add {label}
    </AAddMore>
  ) : null;

const RemoveButton = ({ handleClick }) => (
  <a
    className="minus"
    href="#"
    onClick={(e) => {
      e.preventDefault();
      handleClick();
    }}
  >
    <i className="sprite-icon sprite-x-s-red" />
  </a>
);

// const SpanAnd = <span className="and" />;
const SpanOr = <span className="or" />;

export const LiField = ({ children, className, hasBoolLabel = true, id }) => (
  <Li {...(className && { className: className })} {...(id && { id: id })}>
    {hasBoolLabel && SpanOr}
    {children}
  </Li>
);

// For criteria only
export const transformDataToOption = ({
  id,
  attributes,
  attributes: { name },
  label,
  value,
}) => {
  return {
    data: attributes,
    id,
    label: label || name,
    value: value || id,
  };
};

SelectFields.propTypes = {
  alias: PropTypes.shape({
    many: PropTypes.string,
    name: PropTypes.string,
    objectId: PropTypes.string,
  }),
  classNameExtras: PropTypes.string,
  components: PropTypes.shape({
    FieldLabel: PropTypes.func,
    Placeholder: PropTypes.func,
  }),
  creatable: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  requestUrl: PropTypes.string,
  required: PropTypes.bool,
  selected: PropTypes.array,
};
