import React from "react";
import PropTypes from "prop-types";

import EquipmentMasterComponent from "./equipment_master_component";
import InventoryItemComponent from "./inventory_item_component";
import UsageComponent from "./usage_component";
import { AutoresIcon } from "./utils";

class InventoryItemEdit extends React.Component {
  constructor(props) {
    super(props);

    const initialState = this.emptyState();
    for (let k of Object.keys(initialState)) {
      const propVal = this.props[k];
      if (propVal) initialState[k] = propVal;
    }
    initialState.usage = {
      fullCaseTimeUsage: true, // default
      ...initialState.usage,
    };
    initialState.options = {
      equipmentMasters: [], // default
      inventoryItems: [], // default
      ...initialState.options,
    };
    initialState.dataVersion = this.props.dataVersion;
    this.state = initialState;
  }

  emptyState = () => ({
    accessoryMasters: [],
    autoreserved: false,
    codes: {},
    collabPurchased: false,
    disposableNotes: "",
    equipmentMaster: {},
    equipmentPreference: {},
    fallback: null,
    homeFacility: {},
    owningFacility: {},
    id: this.props.id,
    inventoryItemId: undefined,
    options: {
      inventoryItems: [],
      equipmentMasters: [],
      ...this.props.options,
    },
    rentalCompany: {},
    rentalConfirmationNumber: "",
    reservedBy: this.props.currentUser,
    status: undefined,
    type: undefined,
    unavailabilities: [],
    url: undefined,
    urlImgThumb: undefined,
    usage: {},
    usages: [],
  });

  componentDidUpdate(prevProps) {
    // IF external "Updated By" changes, inventory's editor must
    // change to the new editor
    if (prevProps.currentUser.id != this.props.currentUser.id) {
      if (this.state.reservedBy.id == prevProps.currentUser.id) {
        this.setState({
          ...this.state,
          reservedBy: this.props.currentUser,
        });
      }
    }
  }

  handleEquipmentMasterChange = (change) => {
    this.setState({
      ...this.emptyState(),
      disposableNotes: this.state.disposableNotes,
      equipmentMaster: {
        id: change.value,
        name: change.label,
      },
      usage: this.state.usage,
    });
  };

  handleInventoryItemChange = (change) => {
    // equivalence is intentional "123" == 123
    const isAutoreserved =
      this.state.autoreserved && this.state.inventoryItemId == change.value;
    this.setState({
      ...this.emptyState(),
      ...change.data,
      autoreserved: isAutoreserved,
      disposableNotes: this.state.disposableNotes,
      equipmentMaster: this.state.equipmentMaster,
      inventoryItemId: change.value,
      reservedBy: this.props.currentUser,
      usage: this.state.usage,
    });
  };

  handleUsageChange = (newUsage) => {
    const { autoreserved, usage } = this.props;
    // isModified is mapped to autoreserved_usage, which is a flag to determine if autores inventory
    // is manually modified due to user's partial use changes
    let isModified = usage ? usage.isModified : false;
    if (!isModified && autoreserved) {
      isModified =
        usage.duration != newUsage.duration ||
        usage.startTime != newUsage.startTime;
    }
    this.setState({
      ...this.state,
      dataVersion: new Date().getTime(),
      reservedBy: this.props.currentUser,
      usage: { ...newUsage, isModified: isModified },
    });
  };

  getSelectedInventory = () =>
    this.state.inventoryItemId
      ? {
          data: {
            accessoryMasters: this.state.accessoryMasters,
            codes: this.state.codes,
            collabPurchased: this.state.collabPurchased,
            homeFacility: this.state.homeFacility,
            owningFacility: this.state.owningFacility,
            rentalCompany: this.state.rentalCompany,
            status: this.state.state,
            type: this.state.type,
            unavailabilities: this.state.unavailabilities,
            url: this.state.url,
            urlImgThumb: this.state.urlImgThumb,
            usages: this.state.usages,
          },
          label: "",
          value: this.state.inventoryItemId,
        }
      : null;

  handlePillSubmit = () => {
    const { inventoryItemId } = this.state;
    if (inventoryItemId) {
      this.props.handleSubmit({
        ...this.state,
        fieldId: this.props.fieldId,
        isNew: false,
      });
    }
  };

  handlePillCancel = () => {
    this.props.handleCancel({
      ...this.state,
      fieldId: this.props.fieldId,
      isNew: this.props.isNew,
    });
  };

  render() {
    const Controls = this.props.controlComponent;
    const { dataVersion, equipmentMaster, inventoryItemId, usage } = this.state;
    const { equipmentMasters } = this.state.options;
    const selectedEqm = equipmentMasters.find(
      (eqm) => eqm.value == equipmentMaster.id
    );
    const selectedInv = this.getSelectedInventory();
    // options needs to be fetched to populate.
    // By default, users should see the original selected inv
    const invOptions = [];
    if (selectedInv) invOptions.push(selectedInv);

    const latestDataVersion =
      dataVersion > this.props.dataVersion
        ? dataVersion
        : this.props.dataVersion;
    const isContainerReservedByViewable =
      this.state.reservedBy.isNonDefaultUser || this.props.id;

    return (
      <div className="equipment-block" id="editable">
        <div className="equipment-info">
          <UsageComponent usage={usage} onChange={this.handleUsageChange} />
          <EquipmentMasterComponent
            options={equipmentMasters}
            onChange={this.handleEquipmentMasterChange}
            value={selectedEqm}
          />
          <InventoryItemComponent
            data={{
              equipmentMasterId: selectedEqm && equipmentMaster.id,
              url: this.props.urlInventoryItems,
              usage: this.state.usage,
              version: latestDataVersion,
            }}
            filterableInventory={this.props.filterableInventory}
            options={invOptions}
            onChange={this.handleInventoryItemChange}
            value={selectedInv}
          />
          {isContainerReservedByViewable && (
            <ContainerReservedBy {...this.state.reservedBy} />
          )}
          {this.state.rentalCompany.id && (
            <RentalConfirmationNumber
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  rentalConfirmationNumber: e.target.value,
                })
              }
              value={this.state.rentalConfirmationNumber}
            />
          )}
          <div className="control-group clearfix">
            <div className="col-left">
              <label>Disposable Notes:</label>
            </div>
            <div className="col-right">
              <textarea
                rows="2"
                className="input-block-level disposable-notes"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    disposableNotes: e.target.value,
                  })
                }
                value={this.state.disposableNotes}
              />
            </div>
          </div>
          <div className="row-fluid clearfix">
            <div className="pull-right">
              <button
                className="btn btn-small btn-primary ok-equipment-button"
                disabled={!inventoryItemId}
                name="button"
                onClick={this.handlePillSubmit}
                style={{ marginRight: "10px" }}
                type="button"
              >
                Add equipment
              </button>
              <button
                className="btn btn-small"
                name="button"
                onClick={this.handlePillCancel}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
          {this.state.autoreserved && (
            <AutoresIcon {...this.props.equipmentPreference} />
          )}
        </div>
        {Controls && <Controls />}
      </div>
    );
  }
}

export default InventoryItemEdit;

function RentalConfirmationNumber({ onChange, value }) {
  return (
    <div className="control-group clearfix">
      <div className="col-left">
        <label style={{ marginTop: "0px" }}>Rental Confirmation Number:</label>
      </div>
      <div className="col-right">
        <input
          className="input-mini rental-confirmation-number"
          onChange={onChange}
          type="text"
          value={value}
        />
      </div>
    </div>
  );
}

function ContainerReservedBy({ name, isNonDefaultUser }) {
  return (
    <div className="control-group clearfix">
      <div className="col-left">
        <label>Reserved By:</label>
      </div>
      <div className="col-right" style={{ paddingTop: "12px" }}>
        {name}
        {isNonDefaultUser ? "*" : ""}
      </div>
    </div>
  );
}
