import React from "react";
import EquipmentSelect from "./equipment_select";
import { components } from "react-select-3";
import { InventoryLabels, renderInventoryCodes } from "./utils";

const PLACEHOLDER_DEFAULT = "Please select inventory";
const PLACEHOLDER_ERROR =
  "Error retrieving inventory options. Please try re-opening pill or refreshing the page.";
const PLACEHOLDER_LOADING = "Finding inventories...";
const PLACEHOLDER_NO_MASTER_SEL =
  "Options will populate after equipment selection";
const PLACEHOLDER_NO_INVENT_SEL =
  "There are no inventories found for this equipment. Please select another equipment.";

class InventoryItemComponent extends React.Component {
  constructor(props) {
    super(props);

    const disabledState = !props.data.equipmentMasterId;
    this.state = {
      isDisabled: disabledState,
      isLoading: false,
      options: props.options,
      placeholder: props.data.equipmentMasterId
        ? PLACEHOLDER_DEFAULT
        : PLACEHOLDER_NO_MASTER_SEL,
    };
  }

  getEquipmentMasterId = () => {
    this.props.data.equipmentMasterId;
  };

  componentDidMount() {
    if (this.props.data.equipmentMasterId) this.setInventoryItemsOptions(false);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.data.equipmentMasterId) return; // undefined or null

    const oldVer = prevProps.data.version;
    const newVer = this.props.data.version;
    const isDataVersionChanged =
      oldVer == undefined
        ? !!newVer
        : newVer == undefined
        ? false
        : newVer > oldVer;

    if (
      prevProps.data.equipmentMasterId !== this.props.data.equipmentMasterId ||
      isDataVersionChanged
    ) {
      this.setState({
        ...this.state,
        isDisabled: true,
        isLoading: true,
        placeholder: PLACEHOLDER_LOADING,
      });
      this.setInventoryItemsOptions(isDataVersionChanged);
    }
  }

  setInventoryItemsOptions = (isStaleSelected) => {
    const { equipmentMasterId, url, usage } = this.props.data;
    if (!equipmentMasterId) return;

    const startDate = moment(
      $("#patient_case_during_at_begin_date").val(),
      "MM-DD-YY"
    ).format("YYYY-MM-DD");
    const startTime = $("#patient_case_during_at_begin_time").val();
    const endDate = moment(
      $("#patient_case_during_at_end_date").val(),
      "MM-DD-YY"
    ).format("YYYY-MM-DD");
    const endTime = $("#patient_case_during_at_end_time").val();

    $.ajax({
      url: url,
      method: "GET",
      data: {
        end_at: `${endDate} ${endTime}`,
        equipment_master_id: equipmentMasterId,
        facility_id: $("#patient_case_facility_id").val(),
        location_id: $("#patient_case_location_id").val(),
        patient_case_id: $("#edit_patient_case").data("patientCaseId"),
        room_id: $("#patient_case_room_id").val(),
        start_at: `${startDate} ${startTime}`,
        type: "edit",
        usages: {
          0: {
            duration: usage.duration || 0,
            start_time: usage.startTime || 0,
          },
        },
      },
      success: (response) => {
        const data = JSON.parse(response.data);
        const options = data.length ? data : [];

        this.setState(
          {
            ...this.state,
            isLoading: false,
            isDisabled: false,
            placeholder: PLACEHOLDER_DEFAULT,
            options: options,
          },
          () => {
            const { onChange } = this.props;

            // Select the first option by default
            if (!this.getSelectedValue() && options.length) {
              onChange(options[0]);
            }
            // External input dependencies requires the selected value
            // to refresh with a more recent state (unavail, usage, etc)
            if (isStaleSelected) {
              const currValue = this.getSelectedValue();
              if (currValue) onChange(currValue);
            }
          }
        );
      },
      error: (response) => {
        this.setState({
          isLoading: true,
          isDisabled: true,
          options: [],
          placeholder: PLACEHOLDER_ERROR,
        });
      },
    });
  };

  getSelectedValue = () => {
    const { value } = this.props;
    return value && this.state.options.find((ii) => ii.value == value.value);
  };

  filterOptions = () =>
    this.state.options.filter((opt) => {
      return !this.props.filterableInventory.includes(parseInt(opt.value));
    });

  render() {
    const { isDisabled, isLoading, placeholder } = this.state;
    const { onChange } = this.props;

    const filteredOptions = this.filterOptions();

    const hasNoInventory =
      this.props.data.equipmentMasterId &&
      !filteredOptions.length &&
      !isLoading;
    const selectState = hasNoInventory
      ? {
          isDisabled: true,
          isLoading: false,
          placeholder: PLACEHOLDER_NO_INVENT_SEL,
        }
      : {
          isDisabled: isDisabled,
          isLoading: isLoading,
          placeholder: placeholder,
        };
    return (
      <div className="control-group clearfix">
        <div className="col-left">
          <label>Inventory:</label>
        </div>
        <div className="col-right">
          <EquipmentSelect
            {...selectState}
            components={{ Option: Option }}
            className="inventory-item-select"
            onChange={onChange}
            options={filteredOptions}
            renderContent={renderDataContent}
            value={this.getSelectedValue()}
          />
        </div>
      </div>
    );
  }
}

export default InventoryItemComponent;

const handleMouseDown = (e) => {
  const isUsageLabel = $(e.target).closest(".usage-labels").length;
  const isUnavailLabel = $(e.target).closest("#unavail[href]").length;

  if (isUsageLabel || isUnavailLabel) {
    e.stopPropagation();
    e.preventDefault();
  }
};

const renderDataContent = (data) => [
  <div className="thumb-content" key={`thumb-${data.url}`}>
    <img height="44" src={data.urlImgThumb} width="44" />
  </div>,
  <div className="content" key={`content-${data.url}`}>
    <div className="source">
      <span className="source-location">{data.homeFacility.name}</span>
      <span className="source-location-code">
        {` ${
          data.homeFacility.siteCode ||
          (data.rentalCompany && data.rentalCompany.name)
        }`}
      </span>
    </div>
    <div className="inventory-code">
      {renderInventoryCodes(data.codes)}
      <InventoryLabels
        isRental={data.type === "RentableInventory"}
        collabPurchased={data.collabPurchased}
        homeSiteCode={data.homeFacility.siteCode}
        isRight
        status={data.status}
        unavailabilities={data.unavailabilities}
        usages={data.usages}
      />
    </div>
    <div className="inventory-meta">
      <strong>Accessories: </strong>
      <span className="accessories">
        {data.type === "RentableInventory" ? (
          <i>Must specify with rental company</i>
        ) : data.accessoryMasters.length ? (
          data.accessoryMasters
            .map((am) => `${am.name} (${am.quantity})`)
            .join(", ")
        ) : (
          "(0)"
        )}
      </span>
    </div>
  </div>,
];

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const { data, ...restData } = props.data;
  const newProps = {
    ...props,
    data: restData,
    innerProps: rest,
  };
  return (
    <div onMouseDown={(e) => handleMouseDown(e)}>
      <components.Option {...newProps}>
        {renderDataContent(data)}
      </components.Option>
    </div>
  );
};
