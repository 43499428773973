// Please use this as a reference for mock data.
// Optionally, by importing this file in UglyCalendar.js,
// you can add to the configuration to view mock data.
// <Calendar events={INITIAL_EVENTS} />
//
// NOTE: this may be stale due to updates with with events_controller#event_props
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    driver_name: "Jane Doe",
    end_at: todayStr + "T13:00:00",
    facilityProps: [
      { name: "FMC", isHomed: false },
      { name: "RMC", isHomed: false },
    ],
    has_fallback: false,
    inventoryProps: {
      assetTag: "",
      cohealoTag: "5555",
      collaborativelyPurchased: true,
      equipmentMasterId: 14,
      id: 9999,
      name: "Stryker Video Tower W/ SPY-PHI & PINPOINT",
      serial: "RAN-PI362391",
    },
    isBot: null,
    objectId: 1111,
    start_at: todayStr + "T12:00:00",
    tags: [],
    timeline_size: null,
    type: "Move",
    url: "/moves/12345/edit.json",
  },
  {
    driver_name: "Jane Doe",
    end_at: todayStr + "T18:00:00",
    facilityProps: [{ name: "FMC", isHomed: false }],
    has_fallback: false,
    inventoryProps: {
      assetTag: "",
      cohealoTag: "5555",
      collaborativelyPurchased: true,
      equipmentMasterId: 14,
      id: 9999,
      name: "Stryker Video Tower W/ SPY-PHI & PINPOINT",
      serial: "RAN-PI362391",
    },
    isBot: null,
    objectId: 1111,
    start_at: todayStr + "T13:00:00",
    tags: [],
    timeline_size: null,
    type: "Booking",
    url: "/bookings/54321/edit.json",
  },
  // ...
];
