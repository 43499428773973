import { useContext, useEffect, useState } from "react";
import { RequestContext } from "../../../utils/capiClient";

export default function usePreviewMatches(fetchNow = true, url) {
  const {
    request,
    params: { healthSystemId },
  } = useContext(RequestContext);

  const [data, setData] = useState(null);
  const [flags, setFlags] = useState({
    isLoading: false,
    canFetch: fetchNow,
  });
  const [inputParams, setInputParams] = useState({
    healthSystemId,
  });

  const fetchData = async () => {
    setFlags({ ...flags, isLoading: true });
    try {
      const result = await request
        .get(url, { params: inputParams })
        .then(({ data }) =>
          data.data && data.data.length
            ? data.data.map((obj) => obj.attributes.coded_name)
            : []
        );
      setData(result);
    } catch (error) {
      console.log(error);
    }
    setFlags({ ...flags, isLoading: false, canFetch: false });
  };

  useEffect(() => {
    if (flags.canFetch && !!request) fetchData();
  }, [flags.canFetch && !!request]);

  const fetch = () => setFlags({ ...flags, canFetch: true });
  const setParams = (newParams) => {
    if (newParams) {
      setInputParams({ ...inputParams, healthSystemId, ...newParams });
      fetch();
    } else {
      setData(null);
    }
  };

  // Typical use case
  return [
    { data, isLoading: flags.isLoading },
    { fetch, setParams },
  ];
}
