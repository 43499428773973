import React, { useState } from "react";
import PropTypes from "prop-types";

import Select from "react-select-3";
import { STYLES } from "./styles";

//     usage: { duration: 0, endOfCase: true, startTime: -30 },
const fullCaseUseProps = {
  duration: null,
  endOfCase: true,
  startTime: 0,
};

export default function Usage({ selected = {}, onChange }) {
  // when flipping between radios, partial use values are not reset
  const [usage, setUsage] = useState(Object.assign(fullCaseUseProps, selected));

  const isPartialUse = !(
    usage.endOfCase &&
    usage.startTime == 0 &&
    usage.duration == null
  );
  // const isPartialUse = usage.duration != null || usage.startTime != 0;

  const [isPartialUseChecked, setPartialUseChecked] = useState(isPartialUse);

  const handleFullCaseChange = () => {
    if (isPartialUseChecked) {
      onChange(fullCaseUseProps);
      setPartialUseChecked(false);
    }
  };

  const handlePartialCaseChange = () => {
    if (!isPartialUseChecked) setPartialUseChecked(true);
    // const usageChange = { ...usage, endOfCase: false };

    if (isPartialUse) onChange(usage);
  };

  const handlePartialUse = (change) => {
    if (change.endOfCase && change.duration) change.endOfCase = false;
    onChange(change);
    setUsage(change);
  };

  const randId = Math.floor(Math.random() * 10000);

  return (
    <div className="control-group in-use-container">
      <div className="row-fluid">
        <label className="radio" htmlFor={`full_case_${randId}`}>
          <input
            checked={!isPartialUseChecked}
            className="entire-case-radio"
            id={`full_case_${randId}`}
            onChange={handleFullCaseChange}
            type="radio"
            value="entire_case"
          />
          Reserve for full case
        </label>
      </div>
      <div className="row-fluid">
        <label
          className="inline partial-case-label radio"
          htmlFor={`partial_case_${randId}`}
        >
          <input
            checked={isPartialUseChecked}
            className="partial-case-radio"
            id={`partial_case_${randId}`}
            onChange={handlePartialCaseChange}
            type="radio"
            value="partial_case"
          />
          Reserve for partial case for
        </label>
        {isPartialUseChecked && (
          <PartialUsageSection onChange={handlePartialUse} usage={usage} />
        )}
      </div>
    </div>
  );
}

const optionsForUsage = () => {
  // options for start time
  const optionsStartTime = [...Array(33).keys()].map((x) => {
    const mins = x * 15;
    return {
      label: `${Math.floor(mins / 60)}h ${mins % 60}m`,
      value: mins,
    };
  });

  // options for duration (first option is 'until end of case')
  const optionsDuration = JSON.parse(JSON.stringify(optionsStartTime));
  optionsDuration[0].label = "until end of case";

  // options for '+/-' start time
  const optionsBeforeAfter = [
    { label: "before", value: "before" },
    { label: "after", value: "after" },
  ];

  return {
    beforeAfterOpts: optionsBeforeAfter,
    durationOpts: optionsDuration,
    startTimeOpts: optionsStartTime,
  };
};

const PartialUsageSection = ({ onChange, usage }) => {
  const [options, _] = useState(optionsForUsage());
  const [isBefore, setIsBefore] = useState(parseInt(usage.startTime) < 0);

  const handleBeforeAfterChange = () => {
    setIsBefore(!isBefore);

    if (usage.startTime != 0)
      onChange({ ...usage, startTime: usage.startTime * -1 });
  };

  const handleDurationChange = (change) =>
    onChange({ ...usage, duration: change.value === 0 ? null : change.value });

  // change.value is always positive
  const handleStartTimeChange = (change) =>
    onChange({
      ...usage,
      startTime: isBefore ? change.value * -1 : change.value,
    });

  const { duration, startTime } = usage;
  const { beforeAfterOpts, durationOpts, startTimeOpts } = options;

  const absStartTime = Math.abs(startTime);
  const startTimeValue =
    startTimeOpts.find((opt) => opt.value == absStartTime) || startTimeOpts[0];
  const durationValue = duration
    ? durationOpts.find((opt) => opt.value == duration)
    : durationOpts[0];

  return (
    <div className="in-use-select-wrapper inline">
      <Select
        className="basic-single start-time span2"
        classNamePrefix="select"
        isSearchable={false}
        onChange={handleStartTimeChange}
        options={startTimeOpts}
        styles={STYLES}
        value={startTimeValue}
      />
      <Select
        className="basic-single span2"
        classNamePrefix="select"
        isSearchable={false}
        onChange={handleBeforeAfterChange}
        options={beforeAfterOpts}
        styles={STYLES}
        value={beforeAfterOpts[isBefore ? 0 : 1]}
      />
      {" start, for "}
      <Select
        className="basic-single span3"
        classNamePrefix="select"
        isSearchable={false}
        onChange={handleDurationChange}
        options={durationOpts}
        styles={STYLES}
        value={durationValue}
      />
    </div>
  );
};

Usage.propTypes = {
  selected: PropTypes.shape({
    endOfCase: PropTypes.bool,
    startTime: PropTypes.number,
    duration: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
};
