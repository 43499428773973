import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import ButtonGroup, { ButtonIcon } from "./controls/utils";
import EventInfo from "./controls/event_info";
import { useQueryParam, EI_K } from "./utils/use_query_params";

export default function DeliveriesControls(props) {
  const { events_path, hauls_path, controls } = props;
  const [search, setSearch] = useQueryParam(EI_K, "");

  const handleChange = (change) => {
    // Handles all event info (eihide) changes here for use_query_params
    // We want to capture params for hiding
    let option;
    const hideableItems = [];
    for (var controlKey of Object.keys(props.controls)) {
      // always skip the version controlKey
      if (controlKey !== "version") {
        option = props.controls[controlKey];
        // change's key is checked or if the rest of the options are checked
        if (controlKey === change.name) {
          if (!change.value) {
            hideableItems.push({ value: controlKey });
          }
        } else {
          if (!option.isChecked) {
            hideableItems.push({ value: controlKey });
          }
        }
      }
    }
    setSearch(hideableItems);
    props.handleChange(change);
  };

  return (
    <div className="header-content">
      <EventInfo controls={controls} handleControlChange={handleChange} />
      <ButtonGroup className="btn-group">
        <ButtonIcon
          isActive={true}
          href={events_path}
          iconClass="sprite-icon sprite-calendar-s-black"
          title="Calendar"
        />
        <ButtonIcon href={hauls_path} iconClass="icon-list" />
      </ButtonGroup>
      <ButtonGroup className="btn-group right">
        {/*
            TODO: Somewhat broken format. Please fix this or remove print capability if not used.
            NOTE: Warning on javascript within href: please use an event handler
            https://stackoverflow.com/questions/30135387/how-to-print-react-component-on-click-of-a-button
         */}
        <ButtonIcon
          iconClass="icon-print"
          onClick={() => {
            window.print();
          }}
        />
      </ButtonGroup>
      <ButtonGroup>
        <ButtonIcon
          buttonClass=" visible-phone view-filter-toggle"
          onClick={() => $("body").toggleClass("sidebar-slideout")}
          href="#"
          iconClass="icon icon-filter"
        />
      </ButtonGroup>
    </div>
  );
}

// TODO: Proptypes
DeliveriesControls.propTypes = {};
