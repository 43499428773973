import React, { useEffect, useRef, useState } from "react";
// TODO: Please refactor to useModal
// import { useModal } from "../../../utils/modals";
import TooltipWrapper from "../../../utils/TooltipWrapper";

export default function SaveButton() {
  const [isSaving, setIsSaving] = useState(false);
  const [tipAttributes, setTipAttributes] = useState({
    id: "submit-button-tip",
    options: { "data-placement": "left" },
  });
  const buttonRef = useRef(null);

  const updateTipAttributes = (attrs) => {
    setTipAttributes({
      ...tipAttributes,
      ...attrs,
    });
  };

  const toggleDisableButton = (bool) => {
    if (buttonRef) buttonRef.current.disabled = bool;
  };

  useEffect(() => {
    const handlerProps = {
      confirm: () => {
        toggleDisableButton(true);
        $(modalEl).modal("hide");
        setIsSaving(true);
        form.submit();
      },
    };
    mountConfirmationModal({ ...handlerProps });

    return () => unmountConfirmationModal({ ...handlerProps });
  }, []);

  const onClick = (e) => {
    if (hasUnavailableEquipment()) {
      updateTipAttributes({
        method: "show",
        title: "You have selected unavailable equipment. Please modify.",
      });
      toggleDisableButton(true);
      setTimeout(() => {
        updateTipAttributes({ method: "destroy" });
        toggleDisableButton(false);
      }, 6000);
      e.preventDefault();
      return;
    }

    if (hasUnsavedEquipment()) {
      $(modalEl).modal("show");
      e.preventDefault();
    } else {
      // proceed with form submit
      setIsSaving(true);
    }
  };

  return (
    <TooltipWrapper {...tipAttributes}>
      <button
        className="btn btn-block btn-primary"
        data-disable-with="Saving..."
        id="submit-button"
        onClick={isSaving ? null : onClick}
        ref={buttonRef}
        type="submit"
      >
        {isSaving ? "Saving..." : "Save"}
      </button>
    </TooltipWrapper>
  );
}

const form = document.querySelector("form.patient-case-form");
const modalEl = document.getElementById("confirmation-modal");

const mountConfirmationModal = ({ cancel, confirm }) => {
  if (!modalEl) return modalEl;

  modalEl.querySelector(".modal-header h3.subject").textContent =
    "Unsaved changes";
  let parent = modalEl.querySelector(".icon-container");
  while (parent.firstElementChild) parent.removeChild(parent.firstElementChild);
  const icon = document.createElement("i");
  icon.className = "icon-warning-sign";
  parent.appendChild(icon);
  modalEl.querySelector(".modal-body .message").textContent =
    "You have unsaved equipment changes. If you save now, they will not be reflected.";
  modalEl.querySelector(".btn.confirm").textContent = "Save";
  modalEl.querySelector(".btn.cancel").textContent = "Back to edit";

  modalEl.querySelector(".cancel").addEventListener("click", cancel);
  modalEl.querySelector(".close").addEventListener("click", cancel);
  modalEl.querySelector(".confirm").addEventListener("click", confirm);
};

const unmountConfirmationModal = ({ cancel, confirm }) => {
  modalEl.querySelector(".cancel").removeEventListener("click", cancel);
  modalEl.querySelector(".close").removeEventListener("click", cancel);
  modalEl.querySelector(".confirm").removeEventListener("click", confirm);
};

const hasUnavailableEquipment = () =>
  document.querySelectorAll(".unavailable-inventory").length > 0;
const hasUnsavedEquipment = () =>
  document.querySelectorAll("#editable.equipment-block").length > 0;
