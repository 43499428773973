import React from "react";
import PropTypes from "prop-types";

export default function Versions({ replacedById, replacesId }) {
  return (
    <div id="equipment-preference-versions">
      <h3 className="subtle">Versions</h3>
      <div className="content">
        {replacedById && (
          <div>
            <a href={"/equipment_preferences/" + replacedById}>
              View up-to-date version
            </a>
          </div>
        )}
        {replacesId ? (
          <div>
            <a href={"/equipment_preferences/" + replacesId}>
              View previous version
            </a>
          </div>
        ) : (
          <p className="empty">No previous version</p>
        )}
      </div>
    </div>
  );
}

Versions.propTypes = {
  replacedById: PropTypes.string,
  replacesId: PropTypes.string,
};
