import React, { useState, useEffect, useMemo } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import PropTypes from "prop-types";

import {
  MobilizationSection,
  TileLayout,
  TaglistSection,
} from "../ugly_painter";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

const [
  BADGE_TOG,
  CODE_SERIAL_TOG,
  CODE_COHEALO_TOG,
  CODE_ASSET_TOG,
  DRIVER_TOG,
  OBJECT_TOG,
] = ["Badges", "serial", "cohealo-tag", "asset-tag", "driver", "event-id"];

const TRANSITION_TIMEOUT_MS = 300;

/**
 *  Tile is the abstracted parent class for all different tile types.
 *  e.g. MoveTile and BookingTile inherits from Tile
 *
 *  Tile contains all the shared section components that are used by
 *  child commponents. Specific tile styling/sections should be used within the
 *  the child component itself -- unless shared between all tiles here or in
 *  UglyPainter.
 *
 *  Some examples of shared tile elements: css-transitions, date time sections,
 *  and general full calendar event styling
 */
export function Tile(props) {
  const [seconds, setSeconds] = useState(0);
  const [isHighlighting, enableHighlighting] = useState(false);
  const [menuFocus, setMenuFocus] = useState(false);
  const timelineId = props.event.groupId;
  let canHighlight = true;

  function findTileLayoutElement(e) {
    const eventClass = e.target.className;
    const tileLayoutRegex = new RegExp(/^ugly_painter__TileLayout/);
    const targetEl = tileLayoutRegex.test(eventClass)
      ? e.target
      : e.target.closest('div[class^="ugly_painter__TileLayout"]');

    return targetEl;
  }

  function handleOnMouseEnter(e) {
    const targetEl = findTileLayoutElement(e);

    enableHighlighting(true);
    setMenuFocus(true);
    if (targetEl) targetEl.closest("a.fc-daygrid-event").style.zIndex = "100";
  }

  function handleOnMouseLeave(e) {
    const targetEl = findTileLayoutElement(e);

    if (targetEl)
      targetEl.closest("a.fc-daygrid-event").style.zIndex = "initial";

    enableHighlighting(false);
    setSeconds(0);
    setTimelineHighlight(false);
  }

  function setTimelineHighlight(enable) {
    if (enable && canHighlight) {
      $(`.event[data-timeline-id!='${timelineId}']`).fadeTo(300, 0.3);
      canHighlight = !enable;
    } else if (!enable && !canHighlight) {
      $(`.event[data-timeline-id!='${timelineId}']`).fadeTo(300, 1);
      canHighlight = !enable;
    }
  }

  useEffect(() => {
    let interval = null;
    if (isHighlighting) {
      if (seconds > 1) {
        setTimelineHighlight(true);
        clearInterval(interval);
      } else {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds + 1);
        }, 800);
      }
    } else if (!isHighlighting && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isHighlighting, seconds]);

  function handleTileClick(e) {
    if (e.target.closest("button")) {
      if (isHighlighting) handleOnMouseLeave(e);
    }
    return e;
  }

  return (
    <CSSTransition
      appear
      classNames="event"
      href="localhost:3000"
      in={props.in}
      timeout={TRANSITION_TIMEOUT_MS}
      onClick={handleTileClick}
      unmountOnExit
    >
      <TileLayout
        className="event"
        data-timeline-id={timelineId}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={(e) => {
          handleOnMouseLeave(e);
          setMenuFocus(false);
        }}
        isHighlighting={isHighlighting}
        menuFocus={menuFocus}
        styles={props.styles}
      >
        {props.children}
      </TileLayout>
    </CSSTransition>
  );
}

function Delimiter() {
  return <i className="icon icon-chevron-right" />;
}

function FacilityInfo({ tooltipMessage, children }) {
  return (
    <span className="tip" data-toggle="tooltip" title={tooltipMessage}>
      {children}
    </span>
  );
}

const TimelineQuickLink = ({
  handleInventoryTimeline,
  handleMasterTimeline,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const options = useMemo(
    () => [
      {
        label: "Master Timeline",
        handleClick: handleMasterTimeline,
        value: "master-timeline",
      },
      {
        label: "Inventory Timeline",
        handleClick: handleInventoryTimeline,
        value: "inventory-timeline",
      },
    ],
    []
  );

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Box sx={{ display: "inline-block" }}>
      <Tooltip placement="top" title="Filter to the equipment timeline">
        <IconButton className="tile-icon" onClick={handleClick} size="small">
          {open ? (
            <MenuOpenIcon fontSize="small" sx={{ color: "white" }} />
          ) : (
            <MenuIcon fontSize="small" sx={{ color: "white" }} />
          )}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((opt) => (
          <MenuItem key={opt.value} onClick={opt.handleClick}>
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export function MobilizationInfo(props) {
  return (
    <MobilizationSection className="section" styles={props.styles}>
      <div className="facilities">
        {props.facilities.map(({ isHomed, name, siteCode }, index) => {
          const tooltipMessage = isHomed ? `${name} (home)` : name;
          return (
            <li key={index}>
              {index % 2 == 1 && <Delimiter />}
              {isHomed && <i className="icon-home icon-white" />}
              <FacilityInfo tooltipMessage={tooltipMessage}>
                {siteCode}
              </FacilityInfo>
            </li>
          );
        })}
      </div>
      <div className="tile-icons">
        {props.children}
        <TimelineQuickLink
          handleInventoryTimeline={() =>
            props.handleEquipmentChange(
              props.equipmentMasterId,
              props.inventoryId
            )
          }
          handleMasterTimeline={() =>
            props.handleEquipmentChange(props.equipmentMasterId)
          }
          styles={props.styles}
        />
      </div>
    </MobilizationSection>
  );
}

export function EquipmentName({ inventory }) {
  if (inventory.name) {
    const sectionLabel = `${inventory.name} (${
      inventory.rentalCompany
        ? `${inventory.rentalCompany}, Rental`
        : inventory.homeSiteCode
    })`;
    return <div className="section">{sectionLabel}</div>;
  } else {
    return <em className="empty">{inventory}</em>;
  }
}

// Order matters
const EQUIP_CODE_LIST = [CODE_SERIAL_TOG, CODE_ASSET_TOG, CODE_COHEALO_TOG];
const EQUIP_CODE_MAP = {
  [CODE_SERIAL_TOG]: { propName: "serial", label: "Serial: ", klass: "serial" },
  [CODE_COHEALO_TOG]: {
    propName: "cohealoTag",
    label: "Cohealo: ",
    klass: "cohealo-tag",
  },
  [CODE_ASSET_TOG]: {
    propName: "assetTag",
    label: "Asset: ",
    klass: "asset-tag",
  },
};

export function EquipmentCodes(props) {
  const { inventory } = props;

  if (!inventory.name) {
    return null;
  }

  const getDisplays = () => {
    const map = {};
    for (const codeK of EQUIP_CODE_LIST) {
      map[codeK] = props.display[codeK].isChecked;
    }
    return map;
  };
  const [displays, setDisplays] = useState(getDisplays());

  // Following functions determine the visibility of sections
  // based off of input props from the parent event info control
  const sectionEnabled = () =>
    EQUIP_CODE_LIST.some((codeKey) => displays[codeKey]);

  useEffect(() => {
    // TODO: Efficiency Update
    // A version is passed via props to help determine staleness.
    // Allow a return if props.display.version differs from the current
    // state of version. Also, ensure that appropriate elements change
    // as expected
    //
    // return if (props.display.version)
    const newDisplays = getDisplays();
    if (JSON.stringify(newDisplays) !== JSON.stringify(displays)) {
      setDisplays(newDisplays);
    }
  });

  return sectionEnabled ? (
    <CSSTransition
      classNames="section"
      in={sectionEnabled()}
      timeout={TRANSITION_TIMEOUT_MS}
      appear
      unmountOnExit
    >
      <TransitionGroup className="section equipment-codes">
        {EQUIP_CODE_LIST.filter((codeK) => displays[codeK]).map((codeK) => {
          const { klass, label, propName } = EQUIP_CODE_MAP[codeK];
          const value = props.inventory[propName] ? (
            props.inventory[propName]
          ) : (
            <em className="empty">Not specified</em>
          );
          const enabled = displays[codeK];

          return enabled ? (
            <CSSTransition
              key={codeK}
              classNames="code"
              in={enabled}
              timeout={TRANSITION_TIMEOUT_MS}
              appear
              unmountOnExit
            >
              <div className={`${klass} code`}>
                {label}
                {value}
              </div>
            </CSSTransition>
          ) : null;
        })}
      </TransitionGroup>
    </CSSTransition>
  ) : null;
}

export function ObjectId(props) {
  const [enabled, setEnabled] = useState(props.display[OBJECT_TOG].isChecked);

  useEffect(() => {
    if (props.display[OBJECT_TOG].isChecked != enabled) {
      setEnabled(!enabled);
    }
  });

  return enabled ? (
    <CSSTransition
      classNames="section"
      in={enabled}
      timeout={TRANSITION_TIMEOUT_MS}
      appear
      unmountOnExit
    >
      <div className="section object-id">Event ID: {props.id}</div>
    </CSSTransition>
  ) : null;
}

export function TimeRange(props) {
  const { start_at, end_at } = props;
  // TODO: determine need to blank out times based off of
  // the whodunnit on the event object. Also, double check
  // with prod on the visual elements of the calendar visible
  // by role
  //
  // relevant ruby code: created_by_system --:--

  return (
    <div className="section move-times">
      <i className="icon-time" />
      &nbsp;
      {`${start_at} - ${end_at}`}
    </div>
  );
}

export function Driver(props) {
  const [enabled, setEnabled] = useState(props.display[DRIVER_TOG].isChecked);

  useEffect(() => {
    if (props.display[DRIVER_TOG].isChecked != enabled) {
      setEnabled(!enabled);
    }
  });

  return enabled ? (
    <CSSTransition
      classNames="section"
      in={enabled}
      timeout={TRANSITION_TIMEOUT_MS}
      appear
      unmountOnExit
    >
      <div className="section driver-name">
        <i className="sprite-icon sprite-truck-xs-grey" />
        &nbsp;
        {props.name || <em className="empty">Unassigned</em>}
      </div>
    </CSSTransition>
  ) : null;
}

// Currently, only MoveTile takes advantage of TagList. However,
// based on future features, this may change.
export function TagList(props) {
  const [enabled, setEnabled] = useState(props.display[BADGE_TOG].isChecked);

  useEffect(() => {
    if (props.tags.length && props.display[BADGE_TOG].isChecked != enabled) {
      setEnabled(!enabled);
    }
  });

  return enabled ? (
    <CSSTransition
      classNames="section"
      in={enabled}
      timeout={TRANSITION_TIMEOUT_MS}
      appear
      unmountOnExit
    >
      <TaglistSection className="section taglist">
        {props.tags.map((tag) => (
          <i className={`sprite-icon sprite-${tag}-s-black`} key={tag} />
        ))}
      </TaglistSection>
    </CSSTransition>
  ) : null;
}

Driver.propTypes = {};
EquipmentCodes.propTypes = {};
EquipmentName.propTypes = {};
FacilityInfo.propTypes = {};
MobilizationInfo.propTypes = {};
ObjectId.propTypes = {};
TagList.propTypes = {};
Tile.propTypes = {};
TimelineQuickLink.propTypes = {};
TimeRange.propTypes = {};

export default Tile;
