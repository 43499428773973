import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import "react-select/dist/react-select.css";

class FallbackSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      primaryId: this.props.primaryId,
      fallbackId: this.props.fallbackId,
      name: this.props.name,
      allowedFacilities: this.props.allowedFacilities,
      restrictedFacilities: this.props.restrictedFacilities,
      position: this.props.position,
      photo: this.props.photo,
      link: this.props.link,
      selectedAllowedFacilities: null,
      selectedRestrictedFacilities: [],
      disabledSelect: null,
      preventSave: false,
    };
  }

  componentDidMount() {
    const fallbackSelectId = `select-box-${this.state.id}`;
    // TODO: Remove use of select2 😠 and use react select instead.
    $(`#${fallbackSelectId}`).select2({
      formatResult: this.formatEquipment,
      formatSelection: this.formatEquipment,
      escapeMarkup: function (m) {
        return m;
      },
    });
    this.setFacilityOptions();
  }

  formatEquipment = (state) => {
    const originalOption = state.element;
    const item = $(originalOption).data("item");

    return `<span class="equipment-master format-rich selected">
              <span data-model="master-thumb">
                <img
                  src=${item.imageUrl}
                  alt=${item.name}
                  width="44"
                  height="44"
                />
              </span>
              <span class="primary">
                <span class="manufacturer-name">
                  ${item.manufacturerName}
                </span>
              </span>
              <span class="secondary">
                <span class="model-title">
                  ${item.name}
                </span>
              </span>
            </span>`;
  };

  formatOption = (em) => {
    if (em.id != this.state.primaryId) {
      return (
        <option key={em.id} value={em.id} data-item={JSON.stringify(em)}>
          {em.manufacturerName} {em.name}
        </option>
      );
    }
  };

  handleAllowedFacilitiesChange = (selectedAllowedFacilities) => {
    const disableSelect =
      selectedAllowedFacilities.length > 0 ? "restricted" : null;
    this.setState({
      selectedAllowedFacilities: selectedAllowedFacilities,
      disabledSelect: disableSelect,
    });
  };

  handleRestrictedFacilitiesChange = (selectedRestrictedFacilities) => {
    const disableSelect =
      selectedRestrictedFacilities.length > 0 ? "allowed" : null;
    this.setState({
      selectedRestrictedFacilities: selectedRestrictedFacilities,
      disabledSelect: disableSelect,
    });
  };

  setFacilityOptions = () => {
    var disableSelect = null;
    if (this.state.allowedFacilities.length) {
      disableSelect = "restricted";
    } else if (this.state.restrictedFacilities.length) {
      disableSelect = "allowed";
    }
    this.setState({
      selectedAllowedFacilities: this.state.allowedFacilities || [],
      selectedRestrictedFacilities: this.state.restrictedFacilities || [],
      disabledSelect: disableSelect,
    });
  };

  onClickCancel = () => {
    const fallbackSelectId = `select-box-${this.state.id}`;

    this.setFacilityOptions();
    $(`#${fallbackSelectId}`).val(`${this.state.fallbackId}`);
    $(`#${fallbackSelectId}`).trigger("change");
    this.props.onCancelEdit();
  };

  onClickSubmit = (event) => {
    if (this.state.preventSave) return;

    const fallbackSelectId = `select-box-${this.state.id}`;

    const fallbackId = parseInt($(`#${fallbackSelectId}`).val());

    this.setState({ preventSave: true });

    this.props.onClickSubmit(
      this.state.selectedAllowedFacilities,
      this.state.selectedRestrictedFacilities,
      fallbackId,
      this
    );
  };

  render() {
    const fallbackSelectId = `select-box-${this.state.id}`;
    const facilityOptions = this.props.facilities.map((f) => {
      return { value: f.id, label: f.name };
    });
    const disableAllowed =
      this.state.disabledSelect == "allowed" ? true : false;
    const disableRestricted =
      this.state.disabledSelect == "restricted" ? true : false;

    const saveButtonText = this.state.preventSave ? "Saving..." : "Save";

    return (
      <div className="fallback-select-container">
        <div className="fallback-equipment-master-pill edit-pill">
          <div className="span12">
            <label>Fallback Equipment Master:</label>
            <select
              className="input-block-level format-rich equipment-master"
              id={fallbackSelectId}
              defaultValue={this.state.fallbackId}
            >
              {this.props.equipmentMasters &&
                this.props.equipmentMasters.map((em) => {
                  return this.formatOption(em);
                })}
            </select>
          </div>
          <div className="facility-selects">
            <div className="span5">
              <label>Allowed at:</label>
              <Select
                value={this.state.selectedAllowedFacilities}
                onChange={this.handleAllowedFacilitiesChange}
                options={facilityOptions}
                multi
                disabled={disableAllowed}
                className="input-block-level format-rich"
              />
            </div>
            <div className="span1">
              <b>OR</b>
            </div>
            <div className="span6">
              <label>Denied at:</label>
              <Select
                value={this.state.selectedRestrictedFacilities}
                onChange={this.handleRestrictedFacilitiesChange}
                options={facilityOptions}
                multi
                disabled={disableRestricted}
                className="input-block-level format-rich"
              />
            </div>
          </div>
          <div className="edit-pill-controls">
            <a
              className="btn btn-mini btn-primary fallback-edit-button"
              onClick={this.onClickSubmit}
              disabled={this.state.preventSave}
            >
              {saveButtonText}
            </a>
            <a
              className="btn btn-mini fallback-delete-button"
              onClick={this.onClickCancel}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    );
  }
}

FallbackSelect.propTypes = {
  id: PropTypes.number,
  onCancelEdit: PropTypes.func,
  onClickSubmit: PropTypes.func,
  primaryId: PropTypes.number,
  fallbackId: PropTypes.number,
  name: PropTypes.string,
  allowedFacilities: PropTypes.array,
  restrictedFacilities: PropTypes.array,
  position: PropTypes.number,
  photo: PropTypes.string,
  link: PropTypes.string,
  equipmentMasters: PropTypes.array,
  facilities: PropTypes.array,
};

export default FallbackSelect;
