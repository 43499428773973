import React from "react";
import PropTypes from "prop-types";

import DeliveriesControls from "./deliveries_controls";
import UglyCalendar from "./ugly_calendar";
import UglyFilter from "./ugly_filter";

import { alertFailure } from "../utils/window";
import styled from "styled-components";

import {
  getQuery,
  setQueryString,
  syncQueryParams,
  EI_K,
  ET_K,
  EQM_K,
  FCD_K,
  FCN_K,
  II_K,
} from "./utils/use_query_params";
import { Content } from "./styled";

const getDataVersion = () => new Date().getTime();

class Events extends React.Component {
  constructor(props) {
    super(props);

    const defaultSelected = syncQueryParams(
      this.defaultSelectedFilters(),
      this.props.filters
    );

    this.state = {
      // Will be dropped on nextState
      currentUser: this.checkUserPermissions(defaultSelected),
      selected: defaultSelected,
      eventViewControls: {
        handleChange: this.handleEventViewControlChange,
        controls: this.initDefaultEventInfo(),
      },
      formData: {
        ...this.formatToFormData(defaultSelected),
        version: 0,
      },
      initialView: this.initCalendarView(),
    };
  }

  checkUserPermissions = (options) => {
    const currentUser = { ...this.props.current_user, canCreateMove: false };
    if (options) {
      const { inventoryItems } = options;
      if (currentUser.can_manage_deliveries) {
        if (inventoryItems?.length == 1) {
          // rentals will disallow adding moves
          currentUser.canCreateMove = !inventoryItems[0].data.rental_company_id;
        }
      }
    }
    return currentUser;
  };

  initCalendarView = () => {
    const query = getQuery();
    const validTypes = ["dayGridDay", "dayGridWeek", "dayGridMonth"];

    const date = query.get(FCD_K);
    const type = query.get(FCN_K);

    return {
      date: date || null,
      type: validTypes.includes(type) ? type : null,
    };
  };

  initDefaultEventInfo = () => {
    // keys are formatted this way because they'll be displayed
    // as ids in html
    const defaultEventInfo = {
      serial: { isChecked: true, label: "ID: Serial Number" },
      "asset-tag": { isChecked: true, label: "ID: Asset Tag" },
      "cohealo-tag": { isChecked: true, label: "ID: Cohealo Tag" },
      driver: { isChecked: true, label: "Driver" },
      "event-id": { isChecked: true, label: "Event ID" },
      Badges: { isChecked: true, label: "Badges" },
      version: 0,
    };

    // Update defaults with params from query string
    const query = getQuery();
    let hideableInfo = query.get(EI_K);
    if (hideableInfo?.length) {
      for (let ei of hideableInfo.split(",")) {
        if (defaultEventInfo[ei]) {
          defaultEventInfo[ei].isChecked = false;
        }
      }
    }
    return defaultEventInfo;
  };

  // Clearing filters will default to values here
  defaultSelectedFilters = () => {
    const { event_types, facility_types, health_systems } = this.props.filters;
    facility_types.forEach((opt) => (opt.value = true));
    const defaultHealthSystem =
      health_systems.length == 1 ? health_systems[0] : null;

    return {
      equipmentMaster: null,
      eventTypes: event_types,
      facilities: null,
      facilityTypes: facility_types,
      healthSystem: defaultHealthSystem,
      inventoryItems: null,
      moveBadges: null,
      moveDrivers: null,
      moveStates: null,
    };
  };

  formatToFormData = (selected) => {
    const {
      equipmentMaster,
      eventTypes,
      facilities,
      facilityTypes,
      healthSystem,
      inventoryItems,
      moveBadges,
      moveDrivers,
      moveStates,
    } = selected;

    const formData = {
      equipment_master_id: equipmentMaster ? equipmentMaster.value : null,
      event_types: eventTypes
        .filter((opt) => opt.value)
        .map((opt) => opt.data.id),
      facility_ids: facilities ? facilities.map((opt) => opt.value) : null,
      facility_types: facilityTypes
        .filter((opt) => !opt.value)
        .map((opt) => opt.data.id),
      health_system_id: healthSystem ? healthSystem.value : null,
      inventory_item_ids: inventoryItems
        ? inventoryItems.map((opt) => opt.value)
        : null,
      move_badge_ids: moveBadges ? moveBadges.map((opt) => opt.value) : null,
      move_driver_ids: moveDrivers ? moveDrivers.map((opt) => opt.value) : null,
      move_states: moveStates ? moveStates.map((opt) => opt.value) : null,
    };
    return formData;
  };

  // Validation is ranked in terms of importance
  //
  validateFilterChange = (selected) => {
    const { equipmentMaster, facilities, healthSystem, inventoryItems } =
      selected;
    const valSelect = { ...selected };
    valSelect.healthSystem = healthSystem;
    valSelect.facilities = null;
    valSelect.equipmentMaster = null;
    valSelect.inventoryItems = null;

    const hsId = healthSystem?.value;
    if (facilities) {
      valSelect.facilities = hsId
        ? facilities.filter((opt) => opt.data.health_system_id == hsId)
        : facilities;
      // sake of consistency, keep empty arrays as null. Selects treat [] and null as the same
      if (valSelect.facilities.length == 0) valSelect.facilities = null;
    }

    if (equipmentMaster) {
      // If HS is selected, filter masters based of that. Otherwise, all masters are used
      if (!hsId || equipmentMaster.data.health_system_ids.includes(hsId)) {
        valSelect.equipmentMaster = equipmentMaster;

        // inventory must be of the same master
        if (valSelect.equipmentMaster && inventoryItems) {
          valSelect.inventoryItems = inventoryItems.filter((opt) => {
            if (
              (hsId &&
                opt.data.health_system_id != valSelect.healthSystem.value) ||
              opt.data.equipment_master_id != valSelect.equipmentMaster.value
            )
              return false;
            return true;
          });
          if (valSelect.inventoryItems.length == 0)
            valSelect.inventoryItems = null;
        }
      }
    }

    return valSelect;
  };

  // Note: selected is null when clearing filter
  handleFilterChange = (selected) => {
    const valSelection = selected
      ? this.validateFilterChange(selected)
      : this.defaultSelectedFilters();

    if (!selected)
      setQueryString(ET_K, [{ label: "booking", value: "booking" }]);

    this.setState({
      currentUser: this.checkUserPermissions(selected),
      formData: {
        ...this.formatToFormData(valSelection),
        version: getDataVersion(),
      },
      selected: valSelection,
    });
  };

  handleEventViewControlChange = (selected) => {
    const updatedState = this.state;
    updatedState.eventViewControls.controls[selected.name] = {
      ...updatedState.eventViewControls.controls[selected.name],
      isChecked: selected.value,
      version: getDataVersion(),
    };

    this.setState({ ...updatedState });
  };

  handleEquipmentChange = (equipmentMasterId, inventoryItemId) => {
    const { equipment_masters, inventory_items } = this.props.filters;
    const equipmentMasterOption = equipment_masters.find(
      (em) => em.value === equipmentMasterId
    );
    const inventoryItemOption = inventoryItemId
      ? inventory_items.find((ii) => ii.value === inventoryItemId)
      : null;

    // inventoryItemOption === null is OK; undefined indicates find() didn't return a value
    if (equipmentMasterOption && inventoryItemOption !== undefined) {
      const ets = this.state.selected.eventTypes;
      const queryOptions = [
        { key: EQM_K, value: equipmentMasterOption },
        { key: II_K, value: inventoryItemOption },
      ];
      const etsTypeParams = [];

      ets.forEach((opt) => {
        const { id } = opt.data;

        if (id === "booking") opt.value = true;

        if (!opt.value) etsTypeParams.push({ label: id, value: id });
      });

      queryOptions.push({ key: ET_K, value: etsTypeParams });

      queryOptions.map((opt) => setQueryString(opt.key, opt.value));

      this.handleFilterChange({
        ...this.state.selected,
        equipmentMaster: equipmentMasterOption,
        eventTypes: ets,
        inventoryItems: inventoryItemOption ? [inventoryItemOption] : null,
      });
    } else {
      alertFailure(
        "Failed to filter on inventory",
        `${equipmentMasterOption ? equipmentMasterOption.label : ""}`
      );
    }
  };

  showDeliveryNotice = () => {
    const { selected } = this.state;
    const filterOnBooking = !!selected.eventTypes.find(
      (et) => et.data.id == "booking" && et.value
    );

    return filterOnBooking && !selected.equipmentMaster;
  };

  render() {
    return (
      <div className="template list-with-left-nav">
        <UglyFilter
          {...this.props.filters}
          selected={this.state.selected}
          handleSubmit={this.handleFilterChange}
        />
        <section id="content">
          <Content className="primary-content">
            <Header
              {...this.props.delivery_controls}
              {...this.state.eventViewControls}
              showDeliveryNotice={this.showDeliveryNotice()}
            />
            <div className="row-fluid">
              <div className="span12">
                <div className="row-fluid" id="calendar-wrap">
                  <UglyCalendar
                    currentUser={this.state.currentUser}
                    data={this.state.formData}
                    eventSources={this.props.events}
                    eventDisplay={this.state.eventViewControls.controls}
                    timelines={this.props.timelines}
                    initialView={this.state.initialView}
                    handleEquipmentChange={this.handleEquipmentChange}
                  />
                </div>
              </div>
            </div>
          </Content>
        </section>
      </div>
    );
  }
}

const StyledHeader = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .header-content {
    margin-bottom: 1em;
  }
`;

function Header(props) {
  return (
    <StyledHeader>
      <h1 className="events header-content page-title">
        Delivery Manager&nbsp;
        <Loader />
      </h1>
      {props.showDeliveryNotice && <DeliveryWarnBanner />}
      <DeliveriesControls {...props} />
    </StyledHeader>
  );
}

function DeliveryWarnBanner() {
  return (
    <div
      className="alert alert-small header-content tip"
      data-toggle="tooltip"
      title="To view all bookings, filter to specific equipment"
    >
      <div className="content">
        <i className="icon icon-eye-open"></i>
        <p style={{ cursor: "default" }}>
          Displaying bookings that relate to deliveries
        </p>
      </div>
    </div>
  );
}

function Loader() {
  return (
    <img
      id="ugly-calendar-loader"
      alt="/images/loading-mini.gif"
      height="20"
      src="/images/loading-mini.gif"
      style={{ display: "none" }}
      width="20"
    />
  );
}

// TODO: Proptypes
Events.propTypes = {};

export default Events;
