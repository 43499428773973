import React from "react";

export function UnavailableWarningLabel({ unavailableLabelClasses, hoverOn, hoverOff }) {
  return (
    <div
      className={unavailableLabelClasses}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
    >
      This inventory is assigned to future events. By selecting this inventory, you will initiate an inventory swap.
      <div
        className="tip"
        data-toggle="tooltip"
        data-placement="top"
        data-original-title="An inventory swap is when two inventory items switch places on all future events until the units are restored to their respective home locations."
      >
        <i
          className="sprite-icon sprite-question-xs-grey-light"
          style={{ marginLeft: "2px" }}
        />
      </div>
    </div>
  );
};

export function UnavailableErrorLabel({ unavailableLabelClasses, unavailableUrl, onClickRecheck }) {
  return (
    <div className={unavailableLabelClasses}>
      <span className="symbol">
        <i
          className="sprite-icon sprite-unavailable-s-red"
          style={{ marginRight: "2px" }}
        />
      </span>
      <strong>Unavailable. </strong>
      <a href="#" onClick={onClickRecheck}>
        Recheck
      </a>
      {" | "}
      <a href={unavailableUrl} target="_blank">
        View conflicts
      </a>
    </div>
  );
};