import React, { useState } from "react";
import {
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { grey1b } from "../../../utils/base";

export default function ActionCell({ value }) {
  const [anchorEl, setAnchorEl] = useState();
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const hrefShow = value.row.links.show;
  const hrefEdit = value.row.links.edit;

  const menuIconProps = {
    component: Link,
    onClick: handleClose,
    rel: "noreferrer",
    sx: {
      color: "black",
      fontSize: 14,
      padding: "5px 10px",
      "&:hover": { color: "black" },
      "&:focus": {
        color: "black",
        textDecoration: "none",
        outline: "none",
      },
    },
    target: "_blank",
    underline: "none",
  };

  return hrefEdit ? (
    <>
      <IconButton
        className="row-edit-icon"
        href={hrefEdit}
        rel="noreferrer"
        sx={{
          background: grey1b,
          borderRadius: 0,
          p: "1px",
          fontSize: 10,
          "&:hover, &:focus": {
            color: "rgba(0, 0, 0, 0.54)", // Mui defs
          },
        }}
        target="_blank"
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="more-info"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem {...menuIconProps} href={hrefShow}>
          <ListItemIcon>
            <OpenInNewIcon sx={{ fontSize: 16 }} />
          </ListItemIcon>
          View
        </MenuItem>
        <MenuItem {...menuIconProps} href={hrefEdit}>
          <ListItemIcon>
            <EditIcon sx={{ fontSize: 16 }} />
          </ListItemIcon>
          Edit
        </MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <IconButton
        className="row-edit-icon"
        href={hrefShow}
        rel="noreferrer"
        sx={{
          background: grey1b,
          borderRadius: 0,
          p: "1px",
          fontSize: 10,
          "&:hover, &:focus": {
            color: "rgba(0, 0, 0, 0.54)", // Mui defs
          },
        }}
        target="_blank"
      >
        <OpenInNewIcon sx={{ fontSize: 16 }} />
      </IconButton>
    </>
  );
}
