import styled, { css } from "styled-components";
import { red0, red3 } from "../../../../utils/base";

// TODO: Once the component that uses Pill is
// completely migrated over. Please remove !important
// due to CSS prcedence of assets > styled.js

export const PillContent = styled.div`
  ${(props) => {
    if (props.showError) {
      return css`
        background-color: ${red0} !important;
        border-color: ${red3} !important;

        .source-items {
          background-color: ${red0} !important;
          border-top-color: ${red3} !important;
        }
      `;
    }
  }}
`;

// Unavailable style will be treated as an error style
//
// Existing
//
// &.unavailable-inventory
//   .equipment-block
//     .label.status-unavailable
//       +inline-block
//   .selected-inventory-item
//     background-color: $red-0
//     border-color: $red-3
//     .source-items
//       background-color: $red-0
//       border-top-color: $red-1
//     .label.status-unavailable
//       +inline-block
