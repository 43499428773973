import React from "react";

import { EQUIPMENT_PREFERENCES_PATH } from "../../../utils/capi";
import { useModal } from "../../../utils/modals";
import TooltipWrapper from "../../../utils/TooltipWrapper";

export default function FormButton({
  children,
  className,
  disabled,
  components,
  formId,
  href,
  isActive,
  onClick,
  requiresConfirm,
}) {
  const handleSubmit = () => {
    if (href) return;
    const url = `${EQUIPMENT_PREFERENCES_PATH}${formId ? `/${formId}` : ""}`;
    onClick(url, "equipment_preference");
  };

  // subject: ,
  // icon: "trash",
  // confirm: "<strong>Are you sure you want to delete this equipment preference?</strong><br /><br /><em>This action cannot be undone.</em>",
  // "confirm-label": "Delete",
  // method: "delete",
  const { showModal, toggleMount } = useModal(
    components && { components, href, onConfirm: handleSubmit }
  );

  const handleClick = () => {
    if (disabled) return;

    if (requiresConfirm && showModal) {
      toggleMount();
      showModal();
    } else {
      handleSubmit();
    }
  };

  const Button = (
    <a
      className={`${className}${disabled ? " disabled" : ""}`}
      {...(disabled && { disabled })}
      {...(href && !requiresConfirm && { href })}
      onClick={handleClick}
    >
      {children}
    </a>
  );

  return isActive || isActive === undefined ? (
    Button
  ) : (
    <TooltipWrapper
      id={formId}
      options={{ "data-placement": "top" }}
      style={{ cursor: "not-allowed", display: "inline-block" }}
      title={"Preference is being processed"}
    >
      {Button}
    </TooltipWrapper>
  );
}
