import styled from "styled-components";
import {
  borderRadiusDefault,
  fontColorBody,
  grey1,
  grey2,
  grey3,
  grey4,
  grey7,
  greyMuted,
  success,
  white,
} from "../../../../../utils/base";

export const Component = styled.div`
  margin: 5px 50px 20px 50px;

  .title {
    &:after {
      content: ":";
    }
  }
`;

export const EnabledIcon = styled.span`
  background-color: ${(props) => (props.enabled ? success : greyMuted)};
  border-radius: 4px;
  display: inline-block;
  height: 7px;
  margin-right: 4px;
  width: 7px;
`;

export const Link = styled.a`
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${fontColorBody};
  }
`;

export const MasterName = styled.div`
  font-size: 13px;
  .excluded & {
    text-decoration-line: line-through;
    text-decoration-color: ${grey7};
    span {
      color: ${grey7};
    }
  }
`;

export const Pill = styled.div`
  position: relative;
  margin: 5px 0;
  padding: 15px;
  border: 1px solid ${grey4};
  background: ${grey1};
  border-radius: ${borderRadiusDefault};

  &.excluded {
    border-color: ${grey3};
  }
`;

export const Control = styled.div`
  color: ${grey7};
  cursor: ${(props) => !props.isDisabled && `pointer`};
  font-size: 11px;
  margin-right: 3px;
  position: absolute;
  right: 15px;
  top: 15px;

  &:hover {
    text-decoration: ${(props) => !props.isDisabled && `underline`};
  }
`;
