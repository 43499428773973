import React from "react";
import PropTypes from "prop-types";

import MovesContactPill from "./moves_contact_pill";
import MovesContactPillForm from "./moves_contact_pill_form";
import MovesContactForm from "./moves_contact_form";

// this component manages the different displays of a moves_contact
class MovesContact extends React.Component {
  /*
  componentDidUpdate(prevProps) {
    // re-initialize tooltips if pill has changed to form previously.
    if (prevProps.editing && !this.props.editing) {
      this.initPrimaryTooltip();
    }
  }
  */
  /*
      Handlers
  */

  // controls for pill
  handleSelPrimary = () => this.props.handlePrimaryContactSel(this.props.index);

  handleEditingPill = () =>
    this.props.handleUpdatedSelectedContacts({
      ...this.props,
      hideable: true,
      editing: true,
    });

  handleRemovePill = () => this.props.handleRemovePill(this.props.index);

  // controls for form
  handleFormSubmit = (formProps) =>
    this.props.handleUpdatedSelectedContacts({
      ...formProps,
      value: this.props.value,
      label: this.props.label,
      primary: this.props.primary,
      index: this.props.index,
      editing: false,
      hideable: true,
    });

  handleFormCancel = () =>
    this.props.handleUpdatedSelectedContacts({
      ...this.props,
      hideable: true,
      editing: false,
    });

  /*
      Support for form submission
  */

  renderFormName = () =>
    this.props.isPickUp
      ? `move[moves_contacts][is_pick_up][${this.props.index}]`
      : `move[moves_contacts][is_drop_off][${this.props.index}]`;

  // generate for edit modal
  renderHiddenInputs = () => {
    const {
      moves_contact_id,
      value,
      primary,
      name,
      email,
      description,
      phone,
    } = this.props;

    const attrs = {
      moves_contact_id: moves_contact_id === null ? "" : moves_contact_id,
      contact_id: value || "",
      primary: primary || false,
      name: name || "",
      email: email || "",
      description: description || "",
      phone: phone || "",
    };

    return (
      <div>
        <input
          type="hidden"
          name={this.renderFormName() + "[moves_contact_id]"}
          value={attrs.moves_contact_id}
        />
        <input
          type="hidden"
          name={this.renderFormName() + "[contact_id]"}
          value={attrs.contact_id}
        />
        <input
          type="hidden"
          name={this.renderFormName() + "[primary]"}
          value={attrs.primary}
        />
        <input
          type="hidden"
          name={this.renderFormName() + "[name]"}
          value={attrs.name}
        />
        <input
          type="hidden"
          name={this.renderFormName() + "[email]"}
          value={attrs.email}
        />
        <input
          type="hidden"
          name={this.renderFormName() + "[description]"}
          value={attrs.description}
        />
        <input
          type="hidden"
          name={this.renderFormName() + "[phone]"}
          value={attrs.phone}
        />
      </div>
    );
  };

  // generated for pill removal
  renderHiddenRemoveInputs = () => {
    return (
      <div>
        <input
          type="hidden"
          name={this.renderFormName() + "[moves_contact_id]"}
          value={this.props.moves_contact_id}
        />
        <input
          type="hidden"
          name={this.renderFormName() + "[_destroy]"}
          value="1"
        />
      </div>
    );
  };

  /* There are 4 states to this pill
       1. renders showPill when on show modal
       2. renders removeInputs and nothing else when removeBtn is clicked
       3. renders showPill when showing existing moves contacts on the move
       4. renders pillForm when editing any moves contacts (new/existing)
  */
  render() {
    var pickUpOrDropOff = "";

    if (this.props.isPickUp) {
      pickUpOrDropOff = "pick-up";
    } else {
      pickUpOrDropOff = "drop-off";
    }

    if (this.props.action === "show") {
      return (
        <MovesContactPill
          name={this.props.name}
          description={this.props.description}
          phone={this.props.phone}
          email={this.props.email}
          primary={this.props.primary}
          index={this.props.index}
        />
      );
    }

    if (this.props.removable) {
      return this.renderHiddenRemoveInputs();
    }

    const movesContactObject = this.props.editing ? (
      <MovesContactForm
        name={this.props.name}
        description={this.props.description}
        email={this.props.email}
        phone={this.props.phone}
        handleFormSubmit={this.handleFormSubmit}
        handleFormCancel={this.handleFormCancel}
        focusable={this.props.focusable}
      />
    ) : (
      <MovesContactPillForm
        phone={this.props.phone}
        name={this.props.name}
        email={this.props.email}
        description={this.props.description}
        primary={this.props.primary}
        index={this.props.index}
        handleEditingPill={this.handleEditingPill}
        handleSelPrimary={this.handleSelPrimary}
        handleRemovePill={this.handleRemovePill}
        pickUpOrDropOff={pickUpOrDropOff}
      />
    );

    return (
      <div className="moves-contact">
        {this.renderHiddenInputs()}
        {movesContactObject}
      </div>
    );
  }
}

MovesContact.propTypes = {
  index: PropTypes.number,
  action: PropTypes.string,
  isPickUp: PropTypes.bool,
  handleRemovePill: PropTypes.func,
  handlePrimaryContactSel: PropTypes.func,
  handleUpdatedSelectedContacts: PropTypes.func,
};

export default MovesContact;
