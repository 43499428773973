import React from "react";
import PropTypes from "prop-types";

const AlertNotice = ({ iconClass, id = "", message, link = null }) => (
  <div className="alert">
    <div className="content" id={id}>
      {iconClass && <i className={iconClass} />}
      {message}
      {link && (
        <a href={link.href} {...(link.onClick && { onClick: link.onClick })}>
          {link.message}
        </a>
      )}
    </div>
  </div>
);

export const CloneAlert = () => (
  <AlertNotice
    iconClass="sprite-icon sprite-warning-m-fullcolor"
    id="clone-notice"
    message="You are viewing an unsaved equipment preference clone."
  />
);

export const InProcessingAlert = () => (
  <AlertNotice
    iconClass="sprite-icon sprite-warning-m-fullcolor"
    link={{
      href: "#",
      message: "Please refresh after a few minutes.",
      onClick: () => location.reload(false),
    }}
    message="This preference is being processed and cannot be edited. This process may take several minutes."
  />
);

export const ReplacedByAlert = ({ replacedById }) => (
  <AlertNotice
    iconClass="sprite-icon sprite-warning-m-fullcolor"
    link={{
      href: `/equipment_preferences/${replacedById}`,
      message: "View up-to-date equipment preference.",
    }}
    message="A more up-to-date version of this equipment preference is available. "
  />
);

AlertNotice.propTypes = {
  iconClass: PropTypes.string,
  message: PropTypes.string,
  href: PropTypes.any,
};

ReplacedByAlert.propTypes = {
  replacedById: PropTypes.string.isRequired,
};
