import React from "react";

import clsx from "clsx";
import moment from "moment-timezone";
import _ from "lodash";
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";
import { Box } from "@mui/material";

import DateRange from "./cells/DateRange";
import { nameOrKeyValueFmt } from "../../index/tables/utils";
import { buildOperatorItems } from "../../index/columns/utils";
import {
  COL_ACTION,
  COL_CAMPUS,
  COL_FACILITY,
  COL_INVENTORY_ITEMS,
  COL_PHYSICIANS,
  COL_PROCEDURES,
  COL_SERVICE_LINE,
  COL_UPDATED_BY,
} from "../../index/columns/components";
import {
  CreatableMultiSelect,
  LazySelect,
} from "../../index/tables/filterPanel/GridFilterAutocomplete";
import { GridFilterInputBoolean } from "../../index/tables/filterPanel/GridFilterInputBoolean";
import { LOCATIONS_PATH, ROOMS_PATH } from "../../utils/capi";

const COL_DURING_AT = {
  field: "duringOn",
  cellClassName: (params) => {
    return clsx("MuiDataGrid-cell", {
      duringOn: true,
      duringOnWrap: params.colDef.width <= 80,
    });
  },
  description: "Case start and end",
  filterOperators: getGridDateOperators(),
  headerName: "Date",
  maxWidth: 250,
  renderCell: (params) => <DateRange colDef={params.colDef} value={params} />,
  valueGetter: ({ row }) =>
    row.duringAt.map((dt) => {
      const zone = row.facility.timeZone;
      const foo = zone ? moment.tz(dt, zone) : moment.tz(dt);
      return foo;
    }),
  width: 150,
};

const COL_LOCATION = {
  field: "location",
  filterOperators: buildOperatorItems([
    {
      value: "containAnyOf",
      InputComponent: LazySelect,
      InputComponentProps: {
        freeSolo: true,
        label: "Location",
        multiple: true,
        requestTimeout: 800,
        requestUrl: LOCATIONS_PATH,
      },
    },
  ]),
  headerName: "Location",
  maxWidth: 150,
  valueFormatter: nameOrKeyValueFmt,
  valueGetter: (params) => params.value?.name,
};

const COL_ROOM = {
  field: "room",
  filterOperators: buildOperatorItems([
    {
      value: "containAnyOf",
      InputComponent: LazySelect,
      InputComponentProps: {
        freeSolo: true,
        label: "Room",
        multiple: true,
        requestTimeout: 800,
        requestUrl: ROOMS_PATH,
      },
    },
  ]),
  headerName: "Room",
  maxWidth: 150,
  valueFormatter: nameOrKeyValueFmt,
  valueGetter: (params) => params.value?.name,
};

const COL_SURGICAL_EQUIPMENT = {
  field: "surgicalEquipment",
  cellClassName: (_) => clsx("MuiDataGrid-cell", { list: true }),
  description: "EMR Surgical Equipment",
  filterOperators: buildOperatorItems([
    getGridStringOperators().find((operator) => operator.value === "contains"),
  ]),
  headerName: "Surgical Equipment",
  minWidth: 100,
  renderCell: (params) =>
    params.value?.map((v) => (
      <Box key={`${params.id}-surgical-equipment-${v.id}`}>{v.description}</Box>
    )),
  valueGetter: (params) => params.value,
  width: 200,
};

// Order Matters for columns
export const columns = [
  {
    description: "Only patient stays can be closed",
    field: "closed",
    filterOperators: buildOperatorItems([
      {
        value: "is",
        InputComponent: GridFilterInputBoolean,
      },
    ]),
    headerName: "Closed",
    maxWidth: 150,
    type: "boolean",
  },
  {
    field: "mobilized",
    cellClassName: () =>
      clsx("MuiDataGrid-cell", { justifyContentCenter: true }),
    description: "Case contains mobilzable equipment",
    filterOperators: buildOperatorItems([
      {
        value: "is",
        InputComponent: GridFilterInputBoolean,
      },
    ]),
    headerName: "Mobilized",
    maxWidth: 150,
    type: "boolean",
  },
  {
    field: "id",
    // For some reason, type 'number' is right aligned
    align: "left",
    description: "Cohealo ID",
    filterOperators: getGridNumericOperators().reduce((memo, operator) => {
      if (["isEmpty", "isNotEmpty", "="].includes(operator.value))
        memo.push(operator);

      if (operator.value === "isAnyOf")
        memo.push({ ...operator, InputComponent: CreatableMultiSelect });

      return memo;
    }, []),
    headerAlign: "left",
    headerName: "ID",
    maxWidth: 100,
    type: "number",
  },
  COL_DURING_AT,
  {
    field: "externalId",
    description: "EMR ID",
    headerName: "EMR",
    maxWidth: 100,
  },
  COL_PROCEDURES,
  COL_PHYSICIANS,
  COL_SERVICE_LINE,
  COL_INVENTORY_ITEMS,
  COL_SURGICAL_EQUIPMENT,
  COL_FACILITY,
  COL_CAMPUS,
  COL_LOCATION,
  COL_ROOM,
  {
    field: "notes",
    description: "Case notes",
    headerName: "Case Notes",
  },
  {
    field: "procedureNotes",
    description: "Procedure notes",
    headerName: "Procedure Notes",
  },
  COL_UPDATED_BY,
  COL_ACTION,
];
