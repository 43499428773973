import React, { useState } from "react";
import PropTypes from "prop-types";

import SelectFields, {
  LiField,
} from "../../../forms/selects/SelectFields/SelectFields";
import { PHYSICIANS_PATH } from "../../../utils/capi";

export default function Physicians({
  handleChange,
  isDisabled = false,
  isEditable = true,
  selected,
}) {
  const attributes =
    selected?.filter((sel) => !sel?._destroy && sel?.physicianId) ?? [];

  const onChange = (change) => {
    const newAttributes = change.map((field) => {
      const { _destroy, id, label, value } = field;

      const updatedField = {
        ...(_destroy && { _destroy }),
        data: { name: label },
        id,
        physicianId: value,
      };

      return updatedField;
    });

    handleChange([
      ...selected?.filter((sel) => !sel?.physicianId || sel?._destroy),
      ...newAttributes,
    ]);
  };

  const fields = attributes.map(
    ({ id, data: { coded_name, name, normalized_name }, physicianId }) => ({
      id,
      label: coded_name || name || normalized_name,
      value: physicianId,
    })
  );

  return (
    <SelectFields
      alias={{
        objectId: "physicianId",
        name: "physician",
        many: "Physicians",
      }}
      classNameExtras="row-fluid"
      components={defaultComponents}
      handleChange={onChange}
      isDisabled={isDisabled}
      isEditable={isEditable}
      requestUrl={PHYSICIANS_PATH}
      selected={fields}
    />
  );
}

const defaultComponents = {
  FieldLabel: () => (
    <LiField className="select-field-placeholder" hasBoolLabel={false}>
      All Physicians
    </LiField>
  ),
  Placeholder: () => (
    <LiField className="select-field-placeholder">None selected</LiField>
  ),
};

Physicians.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.object),
};
