import React from "react";
import styled from "styled-components";

const COLOR_DEGREE = 12;

// Tile and Section layout
const ContentSection = styled.div`
  padding: 10px 8px 5px 8px;

  ${(props) => props.styles && props.styles.content}
  .section {
    &:last-of-type {
      border-bottom: 0px;
      padding-bottom: 10px;
    }

    &:nth-of-type(n + 1) {
      padding: 5px 3px 5px 3px;
    }

    ${(props) => props.styles?.section}
  }
`;

export function ContentContainer({ styles, children }) {
  return (
    <ContentSection className="content" styles={styles}>
      {children}
    </ContentSection>
  );
}

export const TileLayout = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  display: block;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 5px;
  position: relative;
  ${(props) => props.styles?.tile}

  .section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    ${(props) => props.styles?.section}
  }

  .tile-icons {
    align-items: center;
    display: inline-flex;
    height: 32px;
  }

  .tile-icon {
    display: ${(props) => (props.menuFocus ? "inline-flex" : "none")};
  }
`;

export const MobilizationSection = styled.div`
  align-items: center;
  display: flex;
  font-size: 11px;
  font-weight: bold;
  justify-content: space-between;

  li {
    display: inline;
    list-style-type: none;
    i {
      font-size: 10px;
      margin: 0px 3px;
    }
    i.icon-home {
      font-size: 13px;
    }
  }

  .facilities {
    white-space: nowrap;
    overflow: hidden;
  }

  .tile-icons {
    @media screen and (max-width: 768px) {
      top: 0;
    }
    .tile-icon:not(:only-child) {
      margin-right: 4px;
    }
  }

  ${(props) => props.styles && props.styles.style}
`;

export const TaglistSection = styled.div`
  i {
    margin: 0px 2px;
  }
`;

export function getRandomPalette() {
  const lightVar = [0.4, 0.5, 0.8]; // [primary, secondary, tertiary]
  const hue = Math.floor(Math.random() * 360);

  return lightVar.map((li) =>
    $.Color({
      hue: hue, // 0 - 360
      saturation: 0.65, // 0 - black, 1.0 candy
      lightness: li, // 1.0 invisible
      alpha: 1,
    }).toHexString()
  );
}

// https://www.w3schools.com/colors/colors_hsl.asp
export function generatePaletteConstant(size = 60) {
  let palette = [];
  let degreeVar = 6;
  let lightVar = [0.4, 0.5, 0.8]; // [primary, secondary, tertiary]
  let satVar = [0.5, 0.65, 0.75];

  for (let i = 0; i < size; i++) {
    const deg = (i * degreeVar) % 360;

    for (let si of satVar) {
      const paletteColor = lightVar.map((li) =>
        $.Color({
          hue: deg, // 0 - 360
          saturation: si, // 0 - black, 1.0 candy
          lightness: li, // 1.0 invisible
          alpha: 1,
        }).toHexString()
      );
      palette.push(paletteColor);
    }
  }

  // Knuth Shuffle
  // https://github.com/Daplie/knuth-shuffle
  const shuffleThis = (array) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };
  return shuffleThis(palette);
}

// Used for dynamically generating a color palette of 'size'
// Please defer to const COLOR_PALETTE for static color values
export function buildColorPalette(size) {
  // size randome hues with 12 degs
  const palette = [];
  let i;
  for (i = 1; i <= size; i++) {
    // Math.floor(Math.random() * size)
    palette.push(
      $.Color({
        hue: i * COLOR_DEGREE,
        saturation: 0.9, // 0 - black, 1.0 candy
        lightness: 0.3, // 1.0 invisible
        alpha: 1,
      }).toHexString()
    );
  }
  return palette;
}

// const color = rainbowStop(h);
// return color;
// const COLOR_SPECTRUM_SIZE = 30; // 360 / 12
// }

// Unused color palette visual
// Some IDEs will display the colors inline.
// Please maintain this
const COLOR_PALETTE_ORDERED = [
  ["#993333", "#bf4040", "#e6b3b3"],
  ["#a82424", "#d22d2d", "#edabab"],
  ["#b31919", "#df2020", "#f2a6a6"],
  ["#993d33", "#bf4c40", "#e6b8b3"],
  ["#a83124", "#d23d2d", "#edb1ab"],
  ["#b32919", "#df3320", "#f2ada6"],
  ["#994733", "#bf5940", "#e6bdb3"],
  ["#a83e24", "#d24e2d", "#edb8ab"],
  ["#b33819", "#df4620", "#f2b5a6"],
  ["#995233", "#bf6640", "#e6c2b3"],
  ["#a84b24", "#d25e2d", "#edbfab"],
  ["#b34719", "#df5920", "#f2bda6"],
  ["#995c33", "#bf7340", "#e6c7b3"],
  ["#a85924", "#d26f2d", "#edc5ab"],
  ["#b35719", "#df6c20", "#f2c4a6"],
  ["#996633", "#bf7f40", "#e6ccb3"],
  ["#a86624", "#d27f2d", "#edccab"],
  ["#b36619", "#df7f20", "#f2cca6"],
  ["#997033", "#bf8c40", "#e6d1b3"],
  ["#a87324", "#d2902d", "#edd3ab"],
  ["#b37519", "#df9320", "#f2d4a6"],
  ["#997a33", "#bf9940", "#e6d6b3"],
  ["#a88124", "#d2a12d", "#edd9ab"],
  ["#b38519", "#dfa620", "#f2dba6"],
  ["#998533", "#bfa640", "#e6dbb3"],
  ["#a88e24", "#d2b12d", "#ede0ab"],
  ["#b39419", "#dfb920", "#f2e3a6"],
  ["#998f33", "#bfb240", "#e6e0b3"],
  ["#a89b24", "#d2c22d", "#ede7ab"],
  ["#b3a319", "#dfcc20", "#f2eba6"],
  ["#999933", "#bfbf40", "#e5e6b3"],
  ["#a8a824", "#d2d22d", "#ededab"],
  ["#b3b319", "#dfdf20", "#f2f2a6"],
  ["#8f9933", "#b3bf40", "#e0e6b3"],
  ["#9ba824", "#c2d22d", "#e7edab"],
  ["#a3b319", "#ccdf20", "#ebf2a6"],
  ["#859933", "#a6bf40", "#dbe6b3"],
  ["#8ea824", "#b1d22d", "#e0edab"],
  ["#94b319", "#b9df20", "#e3f2a6"],
  ["#7a9933", "#99bf40", "#d6e6b3"],
  ["#81a824", "#a1d22d", "#d9edab"],
  ["#85b319", "#a6df20", "#dbf2a6"],
  ["#709933", "#8cbf40", "#d1e6b3"],
  ["#73a824", "#90d22d", "#d3edab"],
  ["#75b319", "#93df20", "#d4f2a6"],
  ["#669933", "#80bf40", "#cce6b3"],
  ["#66a824", "#80d22d", "#ccedab"],
  ["#66b319", "#80df20", "#ccf2a6"],
  ["#5c9933", "#73bf40", "#c7e6b3"],
  ["#59a824", "#6fd22d", "#c5edab"],
  ["#57b319", "#6cdf20", "#c4f2a6"],
  ["#529933", "#66bf40", "#c2e6b3"],
  ["#4ba824", "#5ed22d", "#bfedab"],
  ["#47b319", "#59df20", "#bdf2a6"],
  ["#479933", "#59bf40", "#bde6b3"],
  ["#3ea824", "#4ed22d", "#b8edab"],
  ["#38b319", "#46df20", "#b5f2a6"],
  ["#3d9933", "#4dbf40", "#b8e6b3"],
  ["#31a824", "#3dd22d", "#b1edab"],
  ["#29b319", "#33df20", "#adf2a6"],
  ["#339933", "#40bf40", "#b3e6b3"],
  ["#24a824", "#2dd22d", "#abedab"],
  ["#1ab319", "#20df20", "#a6f2a6"],
  ["#33993d", "#40bf4c", "#b3e6b8"],
  ["#24a831", "#2dd23d", "#abedb1"],
  ["#19b329", "#20df33", "#a6f2ad"],
  ["#339947", "#40bf59", "#b3e6bd"],
  ["#24a83e", "#2dd24e", "#abedb8"],
  ["#19b338", "#20df46", "#a6f2b5"],
  ["#339952", "#40bf66", "#b3e6c2"],
  ["#24a84b", "#2dd25e", "#abedbf"],
  ["#19b347", "#20df59", "#a6f2bd"],
  ["#33995c", "#40bf73", "#b3e6c7"],
  ["#24a859", "#2dd26f", "#abedc5"],
  ["#19b357", "#20df6c", "#a6f2c4"],
  ["#339966", "#40bf80", "#b3e6cc"],
  ["#24a866", "#2dd280", "#abedcc"],
  ["#19b366", "#20df80", "#a6f2cc"],
  ["#339970", "#40bf8c", "#b3e6d1"],
  ["#24a873", "#2dd290", "#abedd3"],
  ["#19b375", "#20df93", "#a6f2d4"],
  ["#33997a", "#40bf99", "#b3e6d6"],
  ["#24a881", "#2dd2a1", "#abedd9"],
  ["#19b385", "#20dfa6", "#a6f2db"],
  ["#339985", "#40bfa6", "#b3e6db"],
  ["#24a88e", "#2dd2b1", "#abede0"],
  ["#19b394", "#20dfb9", "#a6f2e3"],
  ["#33998f", "#40bfb2", "#b3e6e0"],
  ["#24a89b", "#2dd2c2", "#abede7"],
  ["#19b3a3", "#20dfcc", "#a6f2eb"],
  ["#339999", "#40bfbf", "#b3e5e6"],
  ["#24a8a8", "#2dd2d2", "#abeded"],
  ["#19b3b3", "#20dfdf", "#a6f2f2"],
  ["#338f99", "#40b3bf", "#b3e0e6"],
  ["#249ba8", "#2dc2d2", "#abe7ed"],
  ["#19a3b3", "#20ccdf", "#a6ebf2"],
  ["#338599", "#40a6bf", "#b3dbe6"],
  ["#248ea8", "#2db1d2", "#abe0ed"],
  ["#1994b3", "#20b9df", "#a6e3f2"],
  ["#337a99", "#4099bf", "#b3d6e6"],
  ["#2481a8", "#2da1d2", "#abd9ed"],
  ["#1985b3", "#20a6df", "#a6dbf2"],
  ["#337099", "#408cbf", "#b3d1e6"],
  ["#2473a8", "#2d90d2", "#abd3ed"],
  ["#1975b3", "#2093df", "#a6d4f2"],
  ["#336699", "#407fbf", "#b3cce6"],
  ["#2466a8", "#2d7fd2", "#abcced"],
  ["#1966b3", "#207fdf", "#a6ccf2"],
  ["#335c99", "#4073bf", "#b3c7e6"],
  ["#2459a8", "#2d6fd2", "#abc5ed"],
  ["#1957b3", "#206cdf", "#a6c4f2"],
  ["#335299", "#4066bf", "#b3c2e6"],
  ["#244ba8", "#2d5ed2", "#abbfed"],
  ["#1947b3", "#2059df", "#a6bdf2"],
  ["#334799", "#4059bf", "#b3bde6"],
  ["#243ea8", "#2d4ed2", "#abb8ed"],
  ["#1938b3", "#2046df", "#a6b5f2"],
  ["#333d99", "#404dbf", "#b3b8e6"],
  ["#2431a8", "#2d3dd2", "#abb1ed"],
  ["#1929b3", "#2033df", "#a6adf2"],
  ["#333399", "#4040bf", "#b3b3e6"],
  ["#2424a8", "#2d2dd2", "#ababed"],
  ["#191ab3", "#2020df", "#a6a6f2"],
  ["#3d3399", "#4c40bf", "#b8b3e6"],
  ["#3124a8", "#3d2dd2", "#b1abed"],
  ["#2919b3", "#3320df", "#ada6f2"],
  ["#473399", "#5940bf", "#bdb3e6"],
  ["#3e24a8", "#4e2dd2", "#b8abed"],
  ["#3819b3", "#4620df", "#b5a6f2"],
  ["#523399", "#6640bf", "#c2b3e6"],
  ["#4b24a8", "#5e2dd2", "#bfabed"],
  ["#4719b3", "#5920df", "#bda6f2"],
  ["#5c3399", "#7340bf", "#c7b3e6"],
  ["#5924a8", "#6f2dd2", "#c5abed"],
  ["#5719b3", "#6c20df", "#c4a6f2"],
  ["#663399", "#7f40bf", "#ccb3e6"],
  ["#6624a8", "#7f2dd2", "#ccabed"],
  ["#6619b3", "#7f20df", "#cca6f2"],
  ["#703399", "#8c40bf", "#d1b3e6"],
  ["#7324a8", "#902dd2", "#d3abed"],
  ["#7519b3", "#9320df", "#d4a6f2"],
  ["#7a3399", "#9940bf", "#d6b3e6"],
  ["#8124a8", "#a12dd2", "#d9abed"],
  ["#8519b3", "#a620df", "#dba6f2"],
  ["#853399", "#a640bf", "#dbb3e6"],
  ["#8e24a8", "#b12dd2", "#e0abed"],
  ["#9419b3", "#b920df", "#e3a6f2"],
  ["#8f3399", "#b240bf", "#e0b3e6"],
  ["#9b24a8", "#c22dd2", "#e7abed"],
  ["#a319b3", "#cc20df", "#eba6f2"],
  ["#993399", "#bf40bf", "#e6b3e5"],
  ["#a824a8", "#d22dd2", "#edabed"],
  ["#b319b3", "#df20df", "#f2a6f2"],
  ["#99338f", "#bf40b3", "#e6b3e0"],
  ["#a8249b", "#d22dc2", "#edabe7"],
  ["#b319a3", "#df20cc", "#f2a6eb"],
  ["#993385", "#bf40a6", "#e6b3db"],
  ["#a8248e", "#d22db1", "#edabe0"],
  ["#b31994", "#df20b9", "#f2a6e3"],
  ["#99337a", "#bf4099", "#e6b3d6"],
  ["#a82481", "#d22da1", "#edabd9"],
  ["#b31985", "#df20a6", "#f2a6db"],
  ["#993370", "#bf408c", "#e6b3d1"],
  ["#a82473", "#d22d90", "#edabd3"],
  ["#b31975", "#df2093", "#f2a6d4"],
  ["#993366", "#bf4080", "#e6b3cc"],
  ["#a82466", "#d22d80", "#edabcc"],
  ["#b31966", "#df2080", "#f2a6cc"],
  ["#99335c", "#bf4073", "#e6b3c7"],
  ["#a82459", "#d22d6f", "#edabc5"],
  ["#b31957", "#df206c", "#f2a6c4"],
  ["#993352", "#bf4066", "#e6b3c2"],
  ["#a8244b", "#d22d5e", "#edabbf"],
  ["#b31947", "#df2059", "#f2a6bd"],
  ["#993347", "#bf4059", "#e6b3bd"],
  ["#a8243e", "#d22d4e", "#edabb8"],
  ["#b31938", "#df2046", "#f2a6b5"],
  ["#99333d", "#bf404d", "#e6b3b8"],
  ["#a82431", "#d22d3d", "#edabb1"],
  ["#b31929", "#df2033", "#f2a6ad"],
];

// Static colors for events
// Each element contain 3 pairable color variances
// usually secondary and tertiary colors are
// for hover, focus, or background colors
export const COLOR_PALETTE = [
  ["#b32919", "#df3320", "#f2ada6"],
  ["#19b329", "#20df33", "#a6f2ad"],
  ["#a85924", "#d26f2d", "#edc5ab"],
  ["#5924a8", "#6f2dd2", "#c5abed"],
  ["#a82431", "#d22d3d", "#edabb1"],
  ["#995233", "#bf6640", "#e6c2b3"],
  ["#2431a8", "#2d3dd2", "#abb1ed"],
  ["#29b319", "#33df20", "#adf2a6"],
  ["#993d33", "#bf4c40", "#e6b8b3"],
  ["#339952", "#40bf66", "#b3e6c2"],
  ["#337a99", "#4099bf", "#b3d6e6"],
  ["#b35719", "#df6c20", "#f2c4a6"],
  ["#853399", "#a640bf", "#dbb3e6"],
  ["#94b319", "#b9df20", "#e3f2a6"],
  ["#a82481", "#d22da1", "#edabd9"],
  ["#2473a8", "#2d90d2", "#abd3ed"],
  ["#59a824", "#6fd22d", "#c5edab"],
  ["#b319a3", "#df20cc", "#f2a6eb"],
  ["#33998f", "#40bfb2", "#b3e6e0"],
  ["#669933", "#80bf40", "#cce6b3"],
  ["#1929b3", "#2033df", "#a6adf2"],
  ["#24a881", "#2dd2a1", "#abedd9"],
  ["#7519b3", "#9320df", "#d4a6f2"],
  ["#2459a8", "#2d6fd2", "#abc5ed"],
  ["#99338f", "#bf40b3", "#e6b3e0"],
  ["#33997a", "#40bf99", "#b3e6d6"],
  ["#2919b3", "#3320df", "#ada6f2"],
  ["#3ea824", "#4ed22d", "#b8edab"],
  ["#a88124", "#d2a12d", "#edd9ab"],
  ["#3124a8", "#3d2dd2", "#b1abed"],
  ["#999933", "#bfbf40", "#e5e6b3"],
  ["#a83124", "#d23d2d", "#edb1ab"],
  ["#335c99", "#4073bf", "#b3c7e6"],
  ["#3d3399", "#4c40bf", "#b8b3e6"],
  ["#a3b319", "#ccdf20", "#ebf2a6"],
  ["#5719b3", "#6c20df", "#c4a6f2"],
  ["#99337a", "#bf4099", "#e6b3d6"],
  ["#85b319", "#a6df20", "#dbf2a6"],
  ["#333399", "#4040bf", "#b3b3e6"],
  ["#993333", "#bf4040", "#e6b3b3"],
  ["#a87324", "#d2902d", "#edd3ab"],
  ["#1ab319", "#20df20", "#a6f2a6"],
  ["#a82473", "#d22d90", "#edabd3"],
  ["#2481a8", "#2da1d2", "#abd9ed"],
  ["#479933", "#59bf40", "#bde6b3"],
  ["#473399", "#5940bf", "#bdb3e6"],
  ["#81a824", "#a1d22d", "#d9edab"],
  ["#3819b3", "#4620df", "#b5a6f2"],
  ["#703399", "#8c40bf", "#d1b3e6"],
  ["#339999", "#40bfbf", "#b3e5e6"],
  ["#993399", "#bf40bf", "#e6b3e5"],
  ["#24a84b", "#2dd25e", "#abedbf"],
  ["#1994b3", "#20b9df", "#a6e3f2"],
  ["#244ba8", "#2d5ed2", "#abbfed"],
  ["#99335c", "#bf4073", "#e6b3c7"],
  ["#19b366", "#20df80", "#a6f2cc"],
  ["#b37519", "#df9320", "#f2d4a6"],
  ["#47b319", "#59df20", "#bdf2a6"],
  ["#8124a8", "#a12dd2", "#d9abed"],
  ["#339985", "#40bfa6", "#b3e6db"],
  ["#8519b3", "#a620df", "#dba6f2"],
  ["#24a873", "#2dd290", "#abedd3"],
  ["#b33819", "#df4620", "#f2b5a6"],
  ["#243ea8", "#2d4ed2", "#abb8ed"],
  ["#b31947", "#df2059", "#f2a6bd"],
  ["#19b338", "#20df46", "#a6f2b5"],
  ["#4719b3", "#5920df", "#bda6f2"],
  ["#1975b3", "#2093df", "#a6d4f2"],
  ["#339970", "#40bf8c", "#b3e6d1"],
  ["#663399", "#7f40bf", "#ccb3e6"],
  ["#b39419", "#dfb920", "#f2e3a6"],
  ["#24a83e", "#2dd24e", "#abedb8"],
  ["#993370", "#bf408c", "#e6b3d1"],
  ["#249ba8", "#2dc2d2", "#abe7ed"],
  ["#a89b24", "#d2c22d", "#ede7ab"],
  ["#24a866", "#2dd280", "#abedcc"],
  ["#337099", "#408cbf", "#b3d1e6"],
  ["#993347", "#bf4059", "#e6b3bd"],
  ["#339966", "#40bf80", "#b3e6cc"],
  ["#a88e24", "#d2b12d", "#ede0ab"],
  ["#1966b3", "#207fdf", "#a6ccf2"],
  ["#b31994", "#df20b9", "#f2a6e3"],
  ["#24a8a8", "#2dd2d2", "#abeded"],
  ["#335299", "#4066bf", "#b3c2e6"],
  ["#339933", "#40bf40", "#b3e6b3"],
  ["#338599", "#40a6bf", "#b3dbe6"],
  ["#b31966", "#df2080", "#f2a6cc"],
  ["#5c3399", "#7340bf", "#c7b3e6"],
  ["#5c9933", "#73bf40", "#c7e6b3"],
  ["#2466a8", "#2d7fd2", "#abcced"],
  ["#b38519", "#dfa620", "#f2dba6"],
  ["#19b385", "#20dfa6", "#a6f2db"],
  ["#a8243e", "#d22d4e", "#edabb8"],
  ["#994733", "#bf5940", "#e6bdb3"],
  ["#19b3b3", "#20dfdf", "#a6f2f2"],
  ["#998f33", "#bfb240", "#e6e0b3"],
  ["#1985b3", "#20a6df", "#a6dbf2"],
  ["#529933", "#66bf40", "#c2e6b3"],
  ["#24a88e", "#2dd2b1", "#abede0"],
  ["#a83e24", "#d24e2d", "#edb8ab"],
  ["#33993d", "#40bf4c", "#b3e6b8"],
  ["#4b24a8", "#5e2dd2", "#bfabed"],
  ["#859933", "#a6bf40", "#dbe6b3"],
  ["#b31919", "#df2020", "#f2a6a6"],
  ["#75b319", "#93df20", "#d4f2a6"],
  ["#b3b319", "#dfdf20", "#f2f2a6"],
  ["#b31938", "#df2046", "#f2a6b5"],
  ["#24a824", "#2dd22d", "#abedab"],
  ["#8e24a8", "#b12dd2", "#e0abed"],
  ["#7a9933", "#99bf40", "#d6e6b3"],
  ["#b34719", "#df5920", "#f2bda6"],
  ["#336699", "#407fbf", "#b3cce6"],
  ["#a8248e", "#d22db1", "#edabe0"],
  ["#9ba824", "#c2d22d", "#e7edab"],
  ["#a319b3", "#cc20df", "#eba6f2"],
  ["#73a824", "#90d22d", "#d3edab"],
  ["#b31957", "#df206c", "#f2a6c4"],
  ["#709933", "#8cbf40", "#d1e6b3"],
  ["#19a3b3", "#20ccdf", "#a6ebf2"],
  ["#523399", "#6640bf", "#c2b3e6"],
  ["#6624a8", "#7f2dd2", "#ccabed"],
  ["#a82424", "#d22d2d", "#edabab"],
  ["#998533", "#bfa640", "#e6dbb3"],
  ["#19b347", "#20df59", "#a6f2bd"],
  ["#a8249b", "#d22dc2", "#edabe7"],
  ["#4ba824", "#5ed22d", "#bfedab"],
  ["#997a33", "#bf9940", "#e6d6b3"],
  ["#3d9933", "#4dbf40", "#b8e6b3"],
  ["#995c33", "#bf7340", "#e6c7b3"],
  ["#9419b3", "#b920df", "#e3a6f2"],
  ["#19b3a3", "#20dfcc", "#a6f2eb"],
  ["#31a824", "#3dd22d", "#b1edab"],
  ["#993385", "#bf40a6", "#e6b3db"],
  ["#a8244b", "#d22d5e", "#edabbf"],
  ["#b36619", "#df7f20", "#f2cca6"],
  ["#24a831", "#2dd23d", "#abedb1"],
  ["#b31929", "#df2033", "#f2a6ad"],
  ["#19b375", "#20df93", "#a6f2d4"],
  ["#a824a8", "#d22dd2", "#edabed"],
  ["#1947b3", "#2059df", "#a6bdf2"],
  ["#a82459", "#d22d6f", "#edabc5"],
  ["#38b319", "#46df20", "#b5f2a6"],
  ["#2424a8", "#2d2dd2", "#ababed"],
  ["#993366", "#bf4080", "#e6b3cc"],
  ["#19b394", "#20dfb9", "#a6f2e3"],
  ["#b319b3", "#df20df", "#f2a6f2"],
  ["#8ea824", "#b1d22d", "#e0edab"],
  ["#b31985", "#df20a6", "#f2a6db"],
  ["#997033", "#bf8c40", "#e6d1b3"],
  ["#334799", "#4059bf", "#b3bde6"],
  ["#6619b3", "#7f20df", "#cca6f2"],
  ["#66b319", "#80df20", "#ccf2a6"],
  ["#7324a8", "#902dd2", "#d3abed"],
  ["#19b357", "#20df6c", "#a6f2c4"],
  ["#a82466", "#d22d80", "#edabcc"],
  ["#24a859", "#2dd26f", "#abedc5"],
  ["#a86624", "#d27f2d", "#edccab"],
  ["#a84b24", "#d25e2d", "#edbfab"],
  ["#8f3399", "#b240bf", "#e0b3e6"],
  ["#1938b3", "#2046df", "#a6b5f2"],
  ["#24a89b", "#2dd2c2", "#abede7"],
  ["#b3a319", "#dfcc20", "#f2eba6"],
  ["#339947", "#40bf59", "#b3e6bd"],
  ["#191ab3", "#2020df", "#a6a6f2"],
  ["#57b319", "#6cdf20", "#c4f2a6"],
  ["#9b24a8", "#c22dd2", "#e7abed"],
  ["#8f9933", "#b3bf40", "#e0e6b3"],
  ["#b31975", "#df2093", "#f2a6d4"],
  ["#3e24a8", "#4e2dd2", "#b8abed"],
  ["#338f99", "#40b3bf", "#b3e0e6"],
  ["#993352", "#bf4066", "#e6b3c2"],
  ["#333d99", "#404dbf", "#b3b8e6"],
  ["#7a3399", "#9940bf", "#d6b3e6"],
  ["#33995c", "#40bf73", "#b3e6c7"],
  ["#a8a824", "#d2d22d", "#ededab"],
  ["#248ea8", "#2db1d2", "#abe0ed"],
  ["#66a824", "#80d22d", "#ccedab"],
  ["#99333d", "#bf404d", "#e6b3b8"],
  ["#1957b3", "#206cdf", "#a6c4f2"],
  ["#996633", "#bf7f40", "#e6ccb3"],
];
