import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { css } from "styled-components";

import {
  EquipmentCodes,
  EquipmentName,
  Driver,
  MobilizationInfo,
  ObjectId,
  TagList,
  Tile,
  TimeRange,
} from "./tile";
import { ContentContainer } from "../ugly_painter";
import MoveStatus from "./MoveStatus";

const tileStyles = css`
  background: #fff;
  border-color: ${(props) => props.styles.theme.primary};
  border-top-width: 4px;
  color: #333;
  width: 100%;

  &:hover {
    background: ${(props) => props.styles.theme.tertiary};
  }

  &:focus {
    background: ${(props) => props.styles.theme.tertiary};
  }
`;

const sectionStyles = css`
  &:first-of-type {
    padding-top: 0px;
  }
`;

// https://stackoverflow.com/questions/45482793/css-weird-white-space-in-border-of-image
// Fix zoom and weird spacing. SMH.
// margin: -1px;
const mobilizationSectionStyles = css`
  background-color: ${(props) => props.styles.theme.primary};
  border-bottom: 0px !important;
  color: #fff;
  margin: -1px;
  padding: 0px 5px 2px 5px;
`;

export function MoveTile(props) {
  const [entered, setEntered] = useState(true);
  const {
    facilityProps,
    moveStatus,
    inventoryProps,
    start_at,
    end_at,
    driver_name,
    objectId,
    tags,
  } = props.event.extendedProps;
  const { display } = props;

  useEffect(() => {
    return () => setEntered(!entered);
  }, []);

  return (
    <Tile
      {...props}
      in={entered}
      handleIn={setEntered}
      styles={{
        theme: props.theme,
        tile: tileStyles,
        section: sectionStyles,
      }}
    >
      <MobilizationInfo
        equipmentMasterId={inventoryProps.equipmentMasterId}
        facilities={facilityProps}
        inventoryId={inventoryProps.id}
        isFarRightViewTile={props.isFarRightViewTile}
        styles={{
          theme: props.theme,
          style: mobilizationSectionStyles,
        }}
        handleEquipmentChange={props.handleEquipmentChange}
      >
        <MoveStatus
          id={objectId}
          data={{
            disabled: props.isFuture || !props.canManage,
            iconsOnly: true,
          }}
          value={moveStatus}
        />
      </MobilizationInfo>
      <ContentContainer>
        <EquipmentName inventory={inventoryProps} />
        <EquipmentCodes inventory={inventoryProps} display={display} />
        <TimeRange start_at={start_at} end_at={end_at} />
        <Driver name={driver_name} display={display} />
        <ObjectId id={objectId} display={display} />
        <TagList tags={tags} display={display} />
      </ContentContainer>
    </Tile>
  );
}

// TODO: PropTypes
MoveTile.propTypes = {};

export default MoveTile;
