import React from "react";
import PropTypes from "prop-types";

class RentableInventoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      healthSystemOptions: [],
      healthSystemSelected: "",
      rentalCompanyOptions: [],
      rentalCompanySelected: "",
      disableRentalCompanySelect: true,
      disableCreateButton: true,
      errors: {},
    };
  }

  componentDidMount() {
    const { healthSystemsRentalCompanies, rentableInventories } = this.props;

    // Remove health systems with no rental companies
    let removeIfNoRentalCompanies = (toFilter) =>
      toFilter.filter((hsElement) => hsElement.rental_companies.length);

    // start with this b/c no need to check them
    let firstPassHealthSystemsRentalCompanies = removeIfNoRentalCompanies(
      healthSystemsRentalCompanies
    );

    if (rentableInventories.length) {
      // Traverse equipment master's existing list of rentable inventory and remove from list of options
      // Will update firstPassHealthSystemsRentalCompanies since using splice()
      rentableInventories.forEach((rentableInventory) => {
        let foundHealthSystemIndex,
          foundRentalCompanyIndex,
          removedRentalCompany;

        foundHealthSystemIndex =
          firstPassHealthSystemsRentalCompanies.findIndex(
            (hsElement) =>
              rentableInventory.health_system_id === hsElement.health_system.id
          );

        if (foundHealthSystemIndex > -1) {
          //look for rental company
          foundRentalCompanyIndex = firstPassHealthSystemsRentalCompanies[
            foundHealthSystemIndex
          ].rental_companies.findIndex(
            (rcElement) => rentableInventory.rental_company_id === rcElement.id
          );

          if (foundRentalCompanyIndex > -1) {
            //found rental company; now remove
            removedRentalCompany = firstPassHealthSystemsRentalCompanies[
              foundHealthSystemIndex
            ].rental_companies.splice(foundRentalCompanyIndex, 1);
          }
        }
      });
    }

    // filter again to remove above checks that cleared out any health systems
    let finalPassHealthSystemsRentalCompanies = removeIfNoRentalCompanies(
      firstPassHealthSystemsRentalCompanies
    );

    this.setState({
      healthSystemOptions: finalPassHealthSystemsRentalCompanies,
    });
  }

  validateFormField = (fieldName, fieldDescription, fieldData) => {
    if (fieldData === "") {
      let newError = { [fieldName]: `You must select a ${fieldDescription}.` };
      this.setState({ errors: Object.assign(this.state.errors, newError) });
      return false;
    } else {
      let errorState = this.state.errors;
      delete errorState.formField;
      this.setState({ errors: errorState });
      return true;
    }
  };

  onClickCreate = (event) => {
    event.preventDefault();

    const { healthSystemSelected, rentalCompanySelected } = this.state;

    const { equipmentMasterId, createRentableInventoriesUrl } = this.props;

    const validateHealthSystemSelected = this.validateFormField(
      "healthSystem",
      "Health System",
      healthSystemSelected
    );
    const validateRentalCompanySelected = this.validateFormField(
      "rentalCompany",
      "Rental Company",
      rentalCompanySelected
    );

    if (validateHealthSystemSelected && validateRentalCompanySelected) {
      const params = {
        equipment_master_id: equipmentMasterId,
        health_system_id: healthSystemSelected,
        rental_company_id: rentalCompanySelected,
      };

      // Ajax responses will be 1 (success) or 0 (error). ActiveRecord error full_messages not passed (future enhancement)
      $.ajax({
        url: createRentableInventoriesUrl,
        method: "POST",
        data: params,
        success: (response) => {
          // Reload page so newly-added rentable inventory appears in table
          Cohealo.Growl("", {
            header: "Rentable Inventory added successfully.",
            status: "success",
            icon: "ok",
          });
          window.location.reload();
        },
        error: (errorResponse) => {
          Cohealo.Growl("", {
            header: "Rentable Inventory not added due to error.",
            status: "danger",
            icon: "warning-sign",
          });
        },
      });
    }
  };

  onClickClear = (event) => {
    event.preventDefault();

    this.setState({
      healthSystemSelected: "",
      rentalCompanyOptions: [],
      rentalCompanySelected: "",
      disableRentalCompanySelect: true,
      disableCreateButton: true,
      errors: {},
    });
  };

  onChangeHealthSystem = (event) => {
    //grab associated rental companies based on health system selected
    //#find will return entire element
    const healthSystemSelectedId = event.target.value;

    if (healthSystemSelectedId === "prompt") {
      // Dealing with default prompt
      this.setState({
        healthSystemSelected: healthSystemSelectedId,
        rentalCompanyOptions: [],
        disableRentalCompanySelect: true,
        disableCreateButton: true,
      });
      return;
    } else {
      const foundHealthSystem = this.props.healthSystemsRentalCompanies.find(
        (element) => {
          if (String(element.health_system.id) === healthSystemSelectedId) {
            return element;
          }
        }
      );

      if (foundHealthSystem != undefined) {
        this.setState({
          healthSystemSelected: healthSystemSelectedId,
          rentalCompanyOptions: foundHealthSystem.rental_companies,
          disableRentalCompanySelect: false,
        });
      }
    }
  };

  onChangeRentalCompany = (event) => {
    this.setState({
      rentalCompanySelected: event.target.value,
      disableCreateButton: false,
    });
  };

  render() {
    const {
      healthSystemOptions,
      healthSystemSelected,
      rentalCompanyOptions,
      rentalCompanySelected,
      disableRentalCompanySelect,
      disableCreateButton,
      errors,
    } = this.state;

    const numErrors = Object.keys(this.state.errors).length;

    const { equipmentMasterId, equipmentMasterName } = this.props;

    let healthSystemOptionElements = healthSystemOptions.map((option) => {
      return (
        <option key={option.health_system.id} value={option.health_system.id}>
          {option.health_system.name}
        </option>
      );
    });

    let rentalCompanyOptionElements = rentalCompanyOptions.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      );
    });

    if (numErrors > 0) {
      let errorItems;

      errorItems = Object.values(errors).join("\n");
      Cohealo.Growl(errorItems, {
        header: `Rentable Inventory not added due to error${
          numErrors > 1 ? "s" : null
        }.`,
        status: "danger",
        icon: "globe",
      });
    }

    return (
      <form onSubmit={this.onClickCreate} id="rentable-inventory-form">
        <div className="row-fluid control-group field">
          <div className="span2">
            <label htmlFor="equipmentMaster" className="control-label">
              Equipment:
            </label>
          </div>
          <div className="span10">
            <input
              className="input-block-level form-control-plaintext"
              type="text"
              name="equipmentMaster"
              value={equipmentMasterName}
              readOnly
            />
          </div>
        </div>
        <div className="row-fluid control-group field">
          <div className="span2">
            <label htmlFor="healthSystem" className="control-label">
              Health System<span className="required">*</span>:
            </label>
          </div>
          <div className="span10">
            <select
              name="healthSystem"
              value={healthSystemSelected}
              onChange={this.onChangeHealthSystem}
            >
              <option value="prompt">Select Health System</option>
              {healthSystemOptionElements}
            </select>
          </div>
        </div>
        <div className="row-fluid control-group field">
          <div className="span2">
            <label htmlFor="rentalCompany" className="control-label">
              Rental Company<span className="required">*</span>:
            </label>
          </div>
          <div className="span10">
            <select
              name="rentalCompany"
              className="form-control"
              value={rentalCompanySelected}
              onChange={this.onChangeRentalCompany}
              disabled={disableRentalCompanySelect}
            >
              <option value="" />
              {rentalCompanyOptionElements}
            </select>
          </div>
        </div>
        <div className="row-fluid control-group">
          <button className="btn btn-small clear" onClick={this.onClickClear}>
            Clear
          </button>
          <button
            className="btn btn-small btn-primary"
            onClick={this.onClickCreate}
            disabled={disableCreateButton}
          >
            Create Rentable Inventory
          </button>
        </div>
      </form>
    );
  }
}

RentableInventoryForm.propTypes = {
  createRentableInventoriesUrl: PropTypes.string.isRequired,
  healthSystemsRentalCompanies: PropTypes.array.isRequired,
  equipmentMasterId: PropTypes.number.isRequired,
  equipmentMasterName: PropTypes.string.isRequired,
  rentableInventories: PropTypes.array.isRequired,
};

export default RentableInventoryForm;
