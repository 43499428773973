/**
Utility for displaying datetime value strings using Moment.js for formatting and time zone (TZ) conversions.

IMPORTANT NOTE: Many TZs share the same offset, therefore it's difficult to distinguish TZ abbreviation
from an offset alone (https://stackoverflow.com/questions/42194571/identifying-time-zones-in-iso-8601). The explicit input TZ
may be passed as option.datetimeArgZone to avoid ambiguity.
 
Arguments:
* datetime*
  ** datetimeArg: Expecting one of three formats; all string representations:
                    *** ISO 8601 without TZ offset - i.e. "2023-11-23T16:21:03.019Z"
                    *** ISO 8601 with TZ offset - i.e. "2022-12-19T07:18:00.000-08:00"
                    *** "YYYY-MM-DD" - Moment.js default - i.e. "2023-01-15"
  ** datetimeRangeArg: 2-element array with min/lower-bound and max/upper-bound of a tsrange value.
                       Each array element is an ISO 8601 string - i.e. ["2022-12-19T15:18:00.000Z", "2022-12-19T17:20:00.000Z"])
 
* options object:
{
  ** datetimeArgZone: Expected TZ string to parse datetime* input. Applicable for methods where time is displayed.
  ** format: Corresponds to string value of DATE_TIME_FORMATS key. DATE_TIME_FORMATS map to Moment.js formats.
  ** zone: Applicable for methods where time is displayed.
}

Examples:
displayDate(createdAtDate, { format: "DATE"})};   // datetimeArg = "2023-09-06T19:56:18.509Z" (and current year = 2023)
                                                  // output = "Sep 6"

displayTime({
  endDateTime,                                    // datetimeArg = "2023-09-06T19:56:18.509Z"
  { zone: facilityTimeZone }                      // zone = "America/Los_Angeles"
});                                               // output = "19:56 UTC"

displayTime({
  endDateTime,                                    // datetimeArg = "2023-09-06T19:56:18.509Z"
  {
    datetimeArgZone: "America/Los_Angeles",
    zone: facilityTimeZone,                       // zone = "America/Los_Angeles"
  }
});                                               // output = "19:56"
**/

const DATE_TIME_FORMATS = {
  DATE: "MMM D, YYYY", // Jul 4, 1776
  DATE_DEFAULT: "YYYY-MM-DD", // 2017-05-19
  DATE_SHORT: "MMM D", // Jul 4
  US_STD: "MM/DD/YYYY", // 07/04/1776
  US_STD_SHORT: "MM/DD", // 07/04
  US_STD_YY: "MM/DD/YY", // 07/04/76
  MIL_TIME: "HH:mm", // 14:22 or 09:22
  MIL_TIME_ZONE: "HH:mm z", // 14:22 EST or 09:22 EST
};

const MAP_TO_SHORT = {
  DATE: "DATE_SHORT",
  MIL_TIME_ZONE: "MIL_TIME",
  US_STD_YY: "US_STD_SHORT",
};

export const displayDate = (datetimeArg, options = {}) => {
  if (!datetimeArg) return null;

  const opts = Object.assign({ format: "DATE_DEFAULT" }, options);

  const m = moment.parseZone(datetimeArg);

  const format = m.isSame(moment(), "year")
    ? shortenFormat(opts.format)
    : opts.format;

  return m.format(DATE_TIME_FORMATS[format]);
};

export const displayDateRange = (datetimeRangeArg, options = {}) => {
  if (!datetimeRangeArg) return null;

  const opts = Object.assign({ format: "DATE" }, options);

  const [startDateTime, endDateTime] = datetimeRangeArg;

  if (!endDateTime || !startDateTime) return null;

  if (
    moment.parseZone(startDateTime).isSame(moment.parseZone(endDateTime), "day")
  ) {
    return displayDate(startDateTime, { format: opts.format });
  } else {
    // startDateTime will be shortened, therefore make sure range dates are in the same year where applicable
    return `${displayDate(startDateTime, {
      format: shortenFormat(opts.format),
    })} - ${displayDate(endDateTime, opts)}`;
  }
};

export const displayDateTime = (datetimeArg, options = {}) => {
  if (!datetimeArg) return null;

  const opts = Object.assign(
    {
      datetimeArgZone: null,
      dateFormat: "DATE_DEFAULT",
      timeFormat: "MIL_TIME_ZONE",
      zone: null,
    },
    options
  );

  return `${displayDate(datetimeArg, { format: opts.dateFormat })} - 
    ${displayTime(datetimeArg, {
      datetimeArgZone: opts.datetimeArgZone,
      format: opts.timeFormat,
      zone: opts.zone,
    })}`;
};

export const displayTime = (datetimeArg, options = {}) => {
  if (!datetimeArg) return null;

  const opts = Object.assign(
    {
      datetimeArgZone: null,
      format: "MIL_TIME_ZONE",
      zone: null,
    },
    options
  );

  const m = moment.parseZone(datetimeArg);

  const format =
    opts.datetimeArgZone && opts.zone && opts.datetimeArgZone === opts.zone
      ? shortenFormat(opts.format)
      : opts.format;

  return opts.datetimeArgZone
    ? m.tz(opts.datetimeArgZone).format(DATE_TIME_FORMATS[format])
    : m.format(DATE_TIME_FORMATS[format]);
};

export const displayTimeRange = (datetimeRangeArg, options = {}) => {
  if (!datetimeRangeArg) return null;

  const opts = Object.assign(
    {
      datetimeArgZone: null,
      format: "MIL_TIME_ZONE",
      zone: null,
    },
    options
  );

  const [startDateTime, endDateTime] = datetimeRangeArg;

  if (!endDateTime || !startDateTime) return null;

  if (
    moment
      .parseZone(startDateTime)
      .isSame(moment.parseZone(endDateTime), "minute")
  ) {
    return displayTime(startDateTime, opts);
  } else {
    return `${displayTime(startDateTime, {
      datetimeArgZone: opts.datetimeArgZone,
      format: shortenFormat(opts.format),
      zone: opts.zone,
    })} - ${displayTime(endDateTime, opts)}`;
  }
};

const shortenFormat = (format) => {
  return MAP_TO_SHORT[format] || format;
};
