import React from "react";

import Table, { CellStack } from "./Table.js";

import moment from "moment-timezone";

import { withQuery } from "@storybook/addon-queryparams";
import { nameOrKeyValueFmt } from "./utils.js";
// import { buildPatientCase } from "./TableHelper.stories.js";

export default {
  argTypes: { handleChange: { action: "change triggered" } },
  component: Table,
  decorators: [
    (Story) => (
      <div id="index-page">
        <Story />
      </div>
    ),
  ],
  title: "Index/Table",
};

const buildPatientCase = (foo) => ({});
const rows = [...Array(20)].map(buildPatientCase);
const columns = [
  {
    field: "date",
    flex: 1,
    valueGetter: ({ row }) => {
      const min = moment(row.during_at_tz_min);
      const max = moment(row.during_at_tz_max);
      return [min, max];
    },
    valueFormatter: (params) => {
      const dateFormat = "MM/DD HH:mm";
      // const newYork = moment.tz("2014-06-01 12:00", "America/New_York");
      const { value } = params;

      return `${value[0].format(dateFormat)} -> ${value[1].format(dateFormat)}`;
    },
  },
  {
    field: "externalId",
    headerName: "EMR",
  },
  {
    field: "procedures",
    flex: 1,
    headerName: "Procedures",
    renderCell: (params) => <CellStack id={params.id} value={params.value} />,
    valueFormatter: (params) => {
      const { value } = params;

      if (!value) return value;
      return value.map((v) => v.name);
    },
    valueGetter: (params) => params.value?.map((p) => p.coded_name),
  },
  {
    field: "physicians",
    flex: 1,
    headerName: "Physicians",
    renderCell: (params) => <CellStack id={params.id} value={params.value} />,
    valueFormatter: (params) => {
      const { value } = params;

      if (!value) return value;
      return value.map((v) => v.name);
    },
    valueGetter: (params) => params.value?.map((p) => p.coded_name),
  },
  {
    field: "serviceLine",
    flex: 1,
    headerName: "Service Line",
    valueFormatter: nameOrKeyValueFmt,
    valueGetter: (params) => params.value?.name,
  },
  {
    field: "facility",
    flex: 1,
    headerName: "Facility",
    valueFormatter: nameOrKeyValueFmt,
    valueGetter: (params) => params.value?.name,
  },
  {
    field: "room",
    headerName: "Room",
  },
];

const Template = (args) => <Table {...args} />;

export const Default = Template.bind({});
Default.args = {
  columns: [],
  rows: [],
};

export const Rows = Template.bind({});
Rows.args = {
  ...Default.args,
  columns,
  rows,
};

// const foo = {
//   columns: {
//     columnVisibilityModel: {},
//   },
//   // preferencePanel: {
//   //   open: false,
//   // },
//   filter: {
//     filterModel: {
//       items: [],
//       logicOperator: "and",
//       quickFilterValues: [],
//       quickFilterLogicOperator: "and",
//     },
//   },
//   sorting: {
//     sortModel: [],
//   },
//   // pagination: {
//   //   paginationModel: {
//   //     page: 0,
//   //     pageSize: 100,
//   //   },
//   // },
// };
export const UrlQueryParams = Template.bind({});
UrlQueryParams.args = {
  ...Rows.args,
  data: {
    setQueryParams: undefined,
    queryParams: {
      columnVisibilityModel: {
        date: false,
        facility: false,
      },
      filterModel: {
        items: [
          {
            field: "serviceLine",
            operator: "equals",
            value: rows[0].serviceLine.name,
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      },
      sortModel: [],
    },
  },
};
UrlQueryParams.decorators = [
  (Story, context) => {
    // Storybook specific: Generate a unique key based on q params
    // When user changes q params on 'Controls', it will force a remount
    // of this storybook component.

    // NOTE: Query Params are grabbed and set outside of the Table component
    // to reduce additional bloat and customizations by the parent component
    const { columnVisibilityModel, filterModel } =
      context.args.data.queryParams;
    const cvm = Object.keys(columnVisibilityModel)
      .map((k) => `${k}-${columnVisibilityModel[k] ? "t" : "f"}`)
      .join("&");
    const fmi = filterModel.items
      .map((props) => Object.values(props).join("-"))
      .join("&");

    return (
      <div id="index-page">
        <Story key={`${cvm}&&${fmi}`} />
      </div>
    );
  },
  withQuery,
];
