import React from "react";
import TooltipWrapper from "../../utils/TooltipWrapper";
import _ from "lodash";

const INV_CODES_MAP = {
  assetTag: {
    class: "asset-tag",
    label: "Asset:",
  },
  cohealoTag: {
    class: "cohealo-tag",
    label: "Cohealo:",
  },
  serial: {
    class: "serial",
    label: "Serial:",
  },
};
const NOT_SHAREABLE_STATUS = "not-shareable";
const INACTIVE_STATUS = "inactive";
export const NON_SHAREABLE_STATUSES = [NOT_SHAREABLE_STATUS, INACTIVE_STATUS];

export function AutoresIcon({ deleted, name, url }) {
  const isDeleted = deleted || !name;
  const title = name
    ? deleted
      ? `Equipment Preference (${name}) is archived`
      : name
    : "Preference cannot be found";

  return (
    <TooltipWrapper
      id="autores"
      title={title}
      options={{ "data-placement": "top" }}
      style={{
        cursor: url ? "pointer" : "default",
        marginLeft: "5px",
      }}
      url={url}
    >
      <span
        className={`label small${
          isDeleted ? " deleted" : ""
        } equipment-preference tip`}
      >
        <span className="symbol">
          <i className="sprite-icon sprite-bolt-s-white" />
        </span>
      </span>
    </TooltipWrapper>
  );
}

export function InventoryLabels({
  collabPurchased,
  homeSiteCode,
  isRental,
  isRight,
  status,
  unavailabilities,
  usages,
}) {
  return (
    <span className="labels" style={isRight && { float: "right" }}>
      {isRental && <RentalIcon />}
      {collabPurchased && <CollabPurchasedLabel siteCode={homeSiteCode} />}
      {NON_SHAREABLE_STATUSES.includes(status) && (
        <NonShareableStatusLabel status={status} />
      )}
      {usages?.length > 0 && <UsageLabels usages={usages} />}
      {unavailabilities?.length > 0 && (
        <UnavailableLabel unavailabilities={unavailabilities} />
      )}
    </span>
  );
}

export const CollabPurchasedLabel = ({ siteCode }) => (
  <TooltipWrapper id="collab" title="Collaboratively purchased">
    <span className="collab label small">
      <span className="symbol">
        <i className="icon icon-star" />
      </span>
      <span className="content">{siteCode}</span>
    </span>
  </TooltipWrapper>
);

export const NonShareableStatusLabel = ({ status }) => {
  const color = status === INACTIVE_STATUS ? "grey" : "white";
  return (
    <span
      className={`label small status-${status}`}
      style={{ display: "inline-block" }}
    >
      <span className="symbol">
        <i className={`sprite-icon sprite-${status}-xs-${color}`}></i>
      </span>
      <span className="content">{status}</span>
    </span>
  );
};

export const renderInventoryCodes = (list) => {
  const delimitList = [];
  Object.keys(list)
    .filter((ck) => list[ck] && list[ck].length)
    .forEach((ck) => {
      if (delimitList.length) delimitList.push(" | ");

      const klass = INV_CODES_MAP[ck].class;
      const value = list[ck];
      delimitList.push(
        <span className={klass} key={`${klass}-${value}`}>
          {`${INV_CODES_MAP[ck].label} ${value}`}
        </span>
      );
    });
  return delimitList;
};

export const RentalIcon = () => (
  <span className="label small status-rental">
    <span className="symbol">R</span>
    <span className="content">ental</span>
  </span>
);

export const UnavailableLabel = ({ unavailabilities }) => {
  const unvCount = unavailabilities.length;
  let content = "Unavailable";

  const toolTipProps = {
    id: "unavail",
    title: "Booked at ",
  };

  if (unvCount > 1) {
    content += ` (${unvCount})`;
    toolTipProps.title += _.uniq(
      unavailabilities.map((u) => u.facility_site_code)
    ).join(", ");
  } else {
    const mainUnavailability = unavailabilities[0];
    if (mainUnavailability) {
      toolTipProps.title = mainUnavailability.message
        ? mainUnavailability.message
        : toolTipProps.title + mainUnavailability.facility_site_code;
      if (mainUnavailability.booking_id)
        toolTipProps.url = `/bookings/${mainUnavailability.booking_id}`;
    }
  }

  return (
    <TooltipWrapper {...toolTipProps}>
      <span
        className="label small status-unavailable"
        style={{ display: "inline-block" }}
      >
        <span className="symbol">
          <i className="sprite-icon sprite-unavailable-xs-white" />
        </span>
        <span className="content">{content}</span>
      </span>
    </TooltipWrapper>
  );
};

export const UsageLabels = ({ usages }) => {
  return usages.length > 3 ? (
    <TooltipWrapper
      key="0"
      id="in-use-0"
      title={
        usages.length > 9
          ? "Equipment is in use at too many cases. Please select a different equipment."
          : `In use conflicts at: \n${usages
              .map((u) => u.during_at)
              .join(", ")}`
      }
    >
      <span className="label small in-use" style={{ display: "inline-block" }}>
        <span className="content">{`In Use: ${usages.length} time conflicts`}</span>
      </span>
    </TooltipWrapper>
  ) : (
    <span className="usage-labels">
      {usages.map((usage, index) => (
        <TooltipWrapper
          key={index + 1}
          id={`in-use-${index}`}
          title={`In use at ${usage.facility}`}
          style={{ cursor: "pointer" }}
          url={usage.url}
        >
          <span
            className="label small in-use"
            defaultValue={usage.url}
            style={{ display: "inline-block" }}
          >
            <span className="content">In Use: {usage.during_at}</span>
          </span>
        </TooltipWrapper>
      ))}
    </span>
  );
};
