import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select-3";

import { SINGLE_SELECT_STYLES } from "./styles";
import { RequestContext } from "../../utils/capiClient";

const defaultComponents = {
  Label: (attr) => (attr?.label || attr?.name) ?? "",
};

export default function Select({
  components = defaultComponents,
  handleChange,
  isDisabled,
  placeholder,
  requestParams,
  requestUrl,
  value,
  ...rest
}) {
  const { request } = useContext(RequestContext);
  // Load once on supplied request
  // Do not load when not editable
  const [state, setState] = useState({
    isLoaded: null,
    options: null,
  });

  const [selected, setSelected] = useState(value);
  const onChange = (change) => {
    setSelected(change);
    handleChange(change);
  };

  const { isLoaded, options } = state;

  useEffect(() => {
    if (!request) return;

    request.get(requestUrl, { params: requestParams }).then(({ data }) => {
      const responseData = data.data;
      if (responseData) {
        const options = responseData.map((opt) => ({
          label: components.Label(opt.attributes),
          value: opt.id,
        }));

        setState({
          ...state,
          isLoaded: true,
          options: options.length ? options : [],
        });

        if (value) {
          const selOption = options.find((opt) => opt.value == value);
          if (selOption) setSelected(selOption);
        }
      }
    });
  }, [!!request]); // check for presence change

  return (
    <ReactSelect
      className="input-block-level"
      classNamePrefix="select"
      isDisabled={isDisabled || !isLoaded}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={SINGLE_SELECT_STYLES}
      value={selected}
      {...rest}
    />
  );
}

Select.propTypes = {
  components: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  requestUrl: PropTypes.string,
  requestParams: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
