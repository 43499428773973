import styled from "styled-components";
import { blueLight, grey9, selected, white } from "../../utils/base";

export const EQUIPMENT_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    flexDirection: "row-reverse",
    fontSize: "12px",
    margin: "8px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
    paddingRight: "5px",
  }),
  input: (provided, state) => ({
    ...provided,
    alignSelf: "center",
    input: {
      borderColor: `${white} !important`, // override needed due to select2/bootstrap dep
      boxShadow: "none !important",
      marginBottom: "0px",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    boxShadow:
      "0 0 0 1px hsl(218deg 50% 10% / 10%), 0 4px 11px hsl(218deg 50% 10% / 10%)",
    borderRadius: "0px",
    fontSize: "12px",
    ".option-wrapper": {
      borderTop: "1px solid #d6d6d6",
      "&:first-of-type": {
        borderTop: "none",
      },
    },
  }),
  option: (provided, { isSelected }) => {
    return {
      ...provided,
      alignItems: "center",
      borderTop: "1px solid #d6d6d6",
      display: "flex",
      justifyContent: "start",
      minHeight: "70px",
      "&:first-of-type": {
        borderTop: "none",
      },
      ".content": {
        flex: 1,
        lineHeight: "17px",
        marginLeft: "9px",
        ".inventory-meta": {
          fontSize: "9px",
        },
        ".source": {
          fontSize: "14px",
          ".source-location-code": {
            fontSize: "11px",
          },
        },
      },
      ".labels": {
        ".content": {
          flex: "none",
          marginLeft: "0px",
        },
      },
      ".thumb-content": {
        height: "44px",
        width: "44px",
        img: {
          height: "100%",
        },
      },
      ".inventory-code": {
        color: "#777",
        fontSize: "12px",
      },
      ...(isSelected
        ? {
            backgroundColor: selected,
            color: white,
            ".inventory-code": {
              color: white,
            },
          }
        : {
            "&:hover": {
              backgroundColor: blueLight,
              color: grey9,
            },
          }),
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    overflow: "visible",
    padding: "8px 0px",
    position: "relative",
    transform: "none",
    width: "100%",
    whiteSpace: "normal",
    ".content": {
      flex: 1,
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    alignSelf: "stretch",
    paddingLeft: "6px",
    cursor: "text",
  }),
};

const disabledControlDefaults = (props) =>
  props.isDisabled
    ? `
      background-color: hsl(0,0%,95%);
      border-color: hsl(0,0%,90%);
      cursor: default;
    `
    : `
      &:hover {
        border-color: hsl(0, 0%, 70%);
      }
    `;

export const StyledBlanket = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const StyledControl = styled.div`
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: ${(props) => (props.menuIsOpen ? "4px 4px 0px 0px" : "4px")};
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: space-between;
  min-height: 65px;
  position: relative;
  transition: all 100ms;

  ${disabledControlDefaults}
`;

export const StyledDropdownIndicator = styled.div`
  svg {
    max-width: 24px;
    max-height: 24px;
    padding-left: 5px;
    margin-top: 5px;
    fill: hsl(0, 0%, 45%);
  }
`;

export const StyledIndicator = styled.div`
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  padding-right: 5px;

  .svg {
    display: inline-block;
    fill: hsl(0, 0%, 80%);
    line-height: 1;
    stroke: hsl(0, 0%, 80%);
    stroke-width: 0;

    &:hover {
      fill: hsl(0, 0%, 50%);
      stroke: hsl(0, 0%, 50%);
    }
  }
`;
const shadow = "hsla(218, 50%, 10%, 0.1)";
export const StyledMenu = styled.div`
  background-color: white;
  box-shadow: 0 0 0 1px ${shadow}, 0 4px 11px ${shadow};
  position: absolute;
  width: 100%;
  z-index: 2;
`;

export const StyledValue = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 2px 8px;
  padding-left: 6px;
  position: relative;
  overflow: visible;

  .content {
    flex: 1;
    line-height: 17px;
    margin-left: 9px;

    .inventory-meta {
      font-size: 9px;
    }
    .source {
      font-size: 14px;
      .source-location-code {
        font-size: 11px;
      }
    }
  }

  .labels {
    .content {
      flex: none;
      margin-left: 0px;
    }
  }

  .thumb-content {
    height: 44px;
    width: 44px;
    img {
      height: 100%;
    }
  }

  .placeholder {
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
  }
`;

export const USAGE_STYLES = {
  clearIndicator: (provided, state) => ({
    ...provided,
    height: "17px",
    marginBottom: "3px",
    padding: "0px",
    width: "17px",
  }),
  container: (provided, state) => ({
    ...provided,
    marginLeft: "10px !important",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    minHeight: "32px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
    paddingRight: "5px",
  }),
  indicatorSeparator: () => {},
  menu: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    margin: "0px",
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: "1px solid #d6d6d6",

    "&:first-of-type": {
      borderTop: "none",
    },
  }),
};
