import React from "react";

import Pill from "./Pill";

import mockRequestContext from "../../../../specSamples/mockRequestContext";
import {
  EQUIPMENT_MASTERS_PATH,
  EQUIPMENT_MASTER_FALLBACKS_PATH,
  INVENTORY_ITEMS_PATH,
} from "../../../../utils/capi";
import { EquipmentProvider } from "../../useEquipmentContext";

import { equipmentMasterSamples } from "../../../../specSamples/equipmentMaster.samples";
import { equipmentMasterFallbackSamples } from "../../../../specSamples/equipmentMasterFallback.samples";
import { inventoryItemSamples } from "../../../../specSamples/inventoryItem.samples";
import { selected, selectedWithFallback } from "./const.samples";
import { buildEpiiProps } from "../../getForm";

const { withRequestContext } = mockRequestContext({
  context: {
    params: { facilityId: 123 }, // fallbacks require facilityId
  },
  onGet: [
    {
      path: INVENTORY_ITEMS_PATH,
      sample: inventoryItemSamples,
    },
    {
      path: EQUIPMENT_MASTER_FALLBACKS_PATH,
      sample: equipmentMasterFallbackSamples,
    },
  ],
  path: EQUIPMENT_MASTERS_PATH,
  sample: equipmentMasterSamples,
});

export default {
  argTypes: { onChange: { action: "Input value changed" } },
  component: Pill,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <EquipmentProvider>
            <div id="inventory_items_details">
              <Story />
            </div>
          </EquipmentProvider>
        </div>
      </div>
    ),
    withRequestContext,
  ],
  title: "Equipment Preference/Form/Inventory Items/Pill",
};

const Template = (args) => <Pill {...args} />;

export const Default = Template.bind({});
Default.args = {
  // disabledFallbacks,
  // filterableInventoryItemIds,
  handleCancel: () => {},
  handleEdit: () => {},
  handleFallbackChange: () => {},
  handleRemove: () => {},
  handleSubmit: () => {},
  selected: buildEpiiProps(selected),
};

export const DefaultWithFallbacks = Template.bind({});
DefaultWithFallbacks.args = {
  ...Default.args,
  selected: buildEpiiProps(selectedWithFallback),
};

export const NotEditable = Template.bind({});
NotEditable.args = {
  ...Default.args,
  isEditable: false,
};

export const IsEditing = Template.bind({});
IsEditing.args = {
  ...Default.args,
  isEditing: true,
};
