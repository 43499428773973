import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import styled from "styled-components";

import { ET_K, useQueryParam } from "../utils/use_query_params";
import UglyComponent from "./ugly_component";

const hasQueryParamsDisconnect = (queryParam, latestParam) =>
  (!Array.isArray(queryParam) && queryParam != latestParam[0]) ||
  !_.isEqual(queryParam, latestParam);

export default function EventType({ onChange, options }) {
  const [search, setSearch] = useQueryParam(ET_K, "");

  useEffect(() => {
    const params = [];
    options.forEach((opt) => {
      if (!opt.value) params.push(opt.data.id);
    });

    if (hasQueryParamsDisconnect(search, params))
      setSearch(params.map((id) => ({ label: id, value: id })));
  }, []);

  const handleTypeChange = (change) => {
    const typeParams = [];
    const types = [];
    options.forEach((opt) => {
      const { id } = opt.data;
      if (id === change.target.id) opt.value = change.target.checked;
      if (!opt.value) typeParams.push({ label: id, value: id });
      types.push(opt);
    });
    setSearch(typeParams);
    onChange(types);
  };

  return (
    <UglyComponent title="Event Types" extraPadding={true}>
      <label>Event Type:</label>
      {options.map(({ data, label, value }) => (
        <TypeInput
          checked={value}
          key={data.id}
          id={data.id}
          label={label}
          onChange={handleTypeChange}
        />
      ))}
    </UglyComponent>
  );
}

const TypeInput = ({ checked, id, label, onChange }) => (
  <div className="filter-checkbox-row">
    <input
      checked={checked}
      className="filter-checkbox"
      id={id}
      onChange={onChange}
      type="checkbox"
    />
    <label className="filter-checkbox-label" htmlFor={id}>
      {label}
    </label>
  </div>
);

// TODO: Proptypes
EventType.propTypes = {};
