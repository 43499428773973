import React from "react";
import PropTypes from "prop-types";

import { openInNewTab } from "../../../utils/window";
import { StyledDateTimeRangeTable, StyledCaseTable } from "./styled";
import { displayDate, displayTime } from "../../../utils/datetime";
import TooltipWrapper from "../../../utils/TooltipWrapper";

export const CaseTable = ({
  canEdit,
  localeExternalId,
  patientCases,
  timeZone,
}) => {
  return (
    <StyledCaseTable className="table key-value table-clickable-rows table-hover content">
      <thead>
        <tr>
          <th>Date/Time</th>
          <th>Facility</th>
          <th>
            <TooltipWrapper
              id="case-table-ehr"
              options={{ "data-placement": "top", "data-container": "body" }}
              style={{ display: "inline-block" }}
              title={localeExternalId}
            >
              EHR
            </TooltipWrapper>
          </th>
          <th>ID</th>
        </tr>
      </thead>
      <tbody>
        {patientCases.map((pc) => {
          return (
            <tr
              key={pc.id}
              onClick={() =>
                openInNewTab(`/patient_cases/${pc.id}${canEdit ? "/edit" : ""}`)
              }
            >
              <td>
                <DateTimeRangeTable
                  duringAt={pc.duringAt}
                  facilityTimeZone={pc.facilityTimeZone}
                  timeZone={timeZone}
                />
              </td>
              <td>{pc.facilityName || ""}</td>
              <td>{pc.externalId || "n/a"}</td>
              <td>{pc.id}</td>
              <td>
                <i className="sprite-icon sprite-box-arrow-s-grey" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </StyledCaseTable>
  );
};

const DateTimeRangeTable = ({ duringAt, facilityTimeZone, timeZone }) => {
  let endDate = null;
  let endTime = null;
  let startDate = null;
  let startTime = null;

  if (Array.isArray(duringAt) && duringAt.length == 2) {
    const [startDateTime, endDateTime] = duringAt;

    if (startDateTime) {
      startDate = displayDate(startDateTime, { format: "US_STD_YY" });
      startTime = displayTime(startDateTime, {
        datetimeArgZone: facilityTimeZone,
        format: "MIL_TIME_ZONE",
        zone: timeZone,
      });
    }

    if (endDateTime) {
      endDate = displayDate(endDateTime, { format: "US_STD_YY" });
      endTime = displayTime(endDateTime, {
        datetimeArgZone: facilityTimeZone,
        format: "MIL_TIME_ZONE",
        zone: timeZone,
      });
    }
  }

  const haveDateValues = endDate && endTime && startDate && startTime;

  return (
    <StyledDateTimeRangeTable>
      {haveDateValues ? (
        <tbody>
          <tr>
            <td className="date">{startDate}</td>
            <td className="arrow" rowSpan="2">
              &rarr;
            </td>
            <td className="date">{endDate}</td>
          </tr>
          <tr>
            <td className="time">{startTime}</td>
            <td className="time">{endTime}</td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td className="empty">N/A</td>
            <td className="arrow" rowSpan="2">
              &rarr;
            </td>
            <td className="empty">N/A</td>
          </tr>
        </tbody>
      )}
    </StyledDateTimeRangeTable>
  );
};

CaseTable.propTypes = {
  canEdit: PropTypes.bool,
  localeExternalId: PropTypes.string,
  patientCases: PropTypes.array.isRequired,
  timeZone: PropTypes.string,
};

DateTimeRangeTable.propTypes = {
  duringAt: PropTypes.array,
  facilityTimeZone: PropTypes.string,
  timeZone: PropTypes.string,
};
