import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import {
  gridQuickFilterValuesSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid-premium";

import { ExpandableComponent } from "../utils";

// Dev: To support a custom quick filter experience, we need to rewrite this component from source.
// Source:
// https://github.com/mui/mui-x/blob/0625170df48b823dfc8a72e5262d7b5fdd655c31/packages/x-data-grid/src/components/toolbar/GridToolbarQuickFilter.tsx#L50
const GridToolbarQuickFilterRoot = styled(TextField, {
  name: "MuiDataGrid",
  slot: "ToolbarQuickFilter",
  overridesResolver: (props, styles) => styles.toolbarQuickFilter,
})(({ theme }) => ({
  width: "auto",
  // "& input": { },
  "& .MuiInput-underline:before": {
    borderBottom: `1px solid ${(theme.vars || theme).palette.divider}`,
  },
  [`& input[type=search]::-ms-clear,
& input[type=search]::-ms-reveal`]: {
    /* clears the 'X' icon from IE */
    display: "none",
    width: 0,
    height: 0,
  },
  [`& input[type="search"]::-webkit-search-decoration,
  & input[type="search"]::-webkit-search-cancel-button,
  & input[type="search"]::-webkit-search-results-button,
  & input[type="search"]::-webkit-search-results-decoration`]: {
    /* clears the 'X' icon from Chrome */
    display: "none",
  },
}));

// DEV: Case specific at the moment
const isDate = (date) =>
  new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
const isNum = (str) => /^\d+$/.test(str);
const isUpperCase = (str) => str === str.toUpperCase();

// Convert to filterModel.items Props { field:, operator:, value: }
const defaultSearchValueParser = (searchText) => {
  let mapping = [];
  const tokens = searchText.split(" ").filter((word) => word !== "");

  switch (tokens.length) {
    case 0:
      break; // don't search
    // case 1: // likely id? facility? anything really. date //include dash?
    //   mapping.push({ field: "any", operator: "contains", value: searchText });
    //   break;
    // case 2: // not date, emr, facility
    //   mapping.push({ field: "any", operator: "contains", value: searchText });
    //   break;
    default:
      mapping.push({ field: "any", operator: "contains", value: searchText });
      break;
  }
  return mapping;
};

// const defaultSearchValueParser = (searchText) => {
//   return searchText.split(" ").filter((word) => word !== "");
// };

const defaultSearchValueFormatter = (values) =>
  values
    .reduce((memo, v) => {
      memo.push(Object.values(v)[0]);
      return memo;
    }, [])
    .join(" ");
// const defaultSearchValueFormatter = (values) => values.join(" ");

// export type GridToolbarQuickFilterProps = TextFieldProps & {
//   /**
//    * Function responsible for parsing text input in an array of independent values for quick filtering.
//    * @param {string} input The value entered by the user
//    * @returns {any[]} The array of value on which quick filter is applied
//    * @default (searchText: string) => searchText
//    *   .split(' ')
//    *   .filter((word) => word !== '')
//    */
//   quickFilterParser?: (input: string) => any[];
//   /**
//    * Function responsible for formatting values of quick filter in a string when the model is modified
//    * @param {any[]} values The new values passed to the quick filter model
//    * @returns {string} The string to display in the text field
//    * @default (values: string[]) => values.join(' ')
//    */
//   quickFilterFormatter?: (values: NonNullable<GridFilterModel['quickFilterValues']>) => string;
//   /**
//    * The debounce time in milliseconds.
//    * @default 150
//    */
//   debounceMs?: number;
// };

function GridToolbarQuickFilter(props) {
  // type GridToolbarQuickFilterProps
  const apiRef = useGridApiContext();
  const inputRef = createRef();
  const rootProps = useGridRootProps();
  const quickFilterValues = useGridSelector(
    apiRef,
    gridQuickFilterValuesSelector
  );

  const {
    quickFilterParser = defaultSearchValueParser,
    quickFilterFormatter = defaultSearchValueFormatter,
    debounceMs = rootProps.filterDebounceMs * 10, // 1500
    minLength,
    ...other
  } = props;

  const [searchValue, setSearchValue] = useState(() =>
    quickFilterFormatter(quickFilterValues ?? [])
  );

  const prevQuickFilterValuesRef = useRef(quickFilterValues);

  useEffect(() => {
    if (!_.isEqual(prevQuickFilterValuesRef.current, quickFilterValues)) {
      // The model of quick filter value has been updated
      prevQuickFilterValuesRef.current = quickFilterValues;

      // Update the input value if needed to match the new model
      setSearchValue((prevSearchValue) =>
        _.isEqual(quickFilterParser(prevSearchValue), quickFilterValues)
          ? prevSearchValue
          : quickFilterFormatter(quickFilterValues ?? [])
      );
    }
  }, [quickFilterValues, quickFilterFormatter, quickFilterParser]);

  const updateSearchValue = useCallback(
    (newSearchValue) => {
      const newQuickFilterValues = quickFilterParser(newSearchValue);
      prevQuickFilterValuesRef.current = newQuickFilterValues;
      apiRef.current.setQuickFilterValues(newQuickFilterValues);
    },
    [apiRef, quickFilterParser]
  );

  const debouncedUpdateSearchValue = useMemo(
    () => _.debounce(updateSearchValue, debounceMs),
    [updateSearchValue, debounceMs]
  );

  const handleSearchValueChange = useCallback(
    (e) => {
      const newSearchValue = e.target.value;
      setSearchValue(newSearchValue);
      debouncedUpdateSearchValue(newSearchValue);
    },
    [debouncedUpdateSearchValue]
  );

  const handleSearchReset = useCallback(() => {
    setSearchValue("");
    updateSearchValue("");
  }, [updateSearchValue]);

  return (
    <ExpandableComponent
      handleFocusOnElement={() => {
        inputRef.current.focus();
      }}
      IconComponent={SearchIcon}
      IconComponentProps={{ fontSize: "small" }}
      size={props.size}
      ToolTipProps={{
        title:
          "General search on all visible columns. Search performance is impacted by the types of columns visible. Surgical equipment is excluded from search, due to data volume.",
        placement: "top",
      }}
      value={searchValue}
    >
      <GridToolbarQuickFilterRoot
        as={rootProps.slots.baseTextField}
        ownerState={rootProps}
        value={searchValue}
        onChange={handleSearchValueChange}
        placeholder={apiRef.current.getLocaleText(
          "toolbarQuickFilterPlaceholder"
        )}
        aria-label={apiRef.current.getLocaleText("toolbarQuickFilterLabel")}
        type="search"
        {...other}
        InputProps={{
          startAdornment: <rootProps.slots.quickFilterIcon fontSize="small" />,
          endAdornment: (
            <rootProps.slots.baseIconButton
              aria-label={apiRef.current.getLocaleText(
                "toolbarQuickFilterDeleteIconLabel"
              )}
              size={props.size}
              sx={{ visibility: searchValue ? "visible" : "hidden" }}
              onClick={handleSearchReset}
              {...rootProps.slotProps?.baseIconButton}
            >
              <rootProps.slots.quickFilterClearIcon fontSize="small" />
            </rootProps.slots.baseIconButton>
          ),
          inputRef,
          ...other.InputProps,
          sx: {
            "& .MuiInputBase-input": { padding: "8.5px 0 !important" },
          },
        }}
        {...rootProps.slotProps?.baseTextField}
        inputProps={{ minLength }}
      />
    </ExpandableComponent>
  );
}

GridToolbarQuickFilter.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // | To update them edit the TypeScript types and run "yarn proptypes"  |
  // ----------------------------------------------------------------------
  /**
   * The debounce time in milliseconds.
   * @default 150
   */
  debounceMs: PropTypes.number,
  /**
   * Function responsible for formatting values of quick filter in a string when the model is modified
   * @param {any[]} values The new values passed to the quick filter model
   * @returns {string} The string to display in the text field
   * @default (values: string[]) => values.join(' ')
   */
  quickFilterFormatter: PropTypes.func,
  /**
   * Function responsible for parsing text input in an array of independent values for quick filtering.
   * @param {string} input The value entered by the user
   * @returns {any[]} The array of value on which quick filter is applied
   * @default (searchText: string) => searchText
   *   .split(' ')
   *   .filter((word) => word !== '')
   */
  quickFilterParser: PropTypes.func,
}; // as any;

/**
 * Demos:
 * - [Filtering - overview](https://mui.com/x/react-data-grid/filtering/)
 * - [Filtering - quick filter](https://mui.com/x/react-data-grid/filtering/quick-filter/)
 *
 * API:
 * - [GridToolbarQuickFilter API](https://mui.com/x/api/data-grid/grid-toolbar-quick-filter/)
 */
export { GridToolbarQuickFilter };
