import React from "react";

import Procedures from "./Procedures";

import { PROCEDURES_PATH } from "../../../utils/capi";

import mockRequestContext from "../../../specSamples/mockRequestContext";
import { procedureSamples } from "../../../specSamples/procedure.samples";
import { takeSample } from "../../../specSamples/utils";

const { withRequestContext } = mockRequestContext({
  path: PROCEDURES_PATH,
  sample: procedureSamples,
});

export default {
  argTypes: { handleChange: { action: "Input value changed" } },
  component: Procedures,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <Story />
        </div>
      </div>
    ),
    withRequestContext,
  ],
  title: "Equipment Preference/Form/Procedures",
};

const Template = (args) => <Procedures {...args} />;

export const Default = Template.bind({});
Default.args = {
  handleChange: () => {},
  requestUrl: PROCEDURES_PATH,
};

export const IsDisabled = Template.bind({});
IsDisabled.args = {
  ...Default.args,
  isDisabled: true,
};

export const IsNotEditable = Template.bind({});
IsNotEditable.args = {
  ...Default.args,
  isEditable: false,
};

/** Examples of randomizing procedurePattern
  icd10: null,
  icd9: "88.39",
  name: '" Wire Localized Excisional Biopsy Breast"',
  normalized_name: '" wire localized excisional biopsy breast" [88.39]',
  vendor_code: null,
  coded_name: '" Wire Localized Excisional Biopsy Breast" [88.39]',
*/
const toEquipmentPreferencesCriterionAttributes = (
  { attributes: { name } },
  index
) => ({
  id: index,
  physicianId: null,
  procedurePattern: name,
});

export const Selected = Template.bind({});
Selected.args = {
  selected: takeSample(procedureSamples, 5).map(
    toEquipmentPreferencesCriterionAttributes
  ),
};

export const SelectedIsDisabled = Template.bind({});
SelectedIsDisabled.args = {
  ...Selected.args,
  ...IsDisabled.args,
};
//
export const SelectedIsNotEditable = Template.bind({});
SelectedIsNotEditable.args = {
  ...Selected.args,
  ...IsNotEditable.args,
};
