import React from "react";
import PropTypes from "prop-types";

import Show from "./Show";
import Edit from "./Edit";

export default function Pill({
  components = { EquipmentMasterFallbacks: () => {} },
  filterableInventoryItemIds,
  handleCancel,
  handleEdit,
  handleRemove,
  handleSubmit,
  isEditable = true,
  isEditing = false,
  selected,
}) {
  const Controls = isEditable
    ? () => (
        <span className="equipment-controls case-edit-spacing">
          <a
            className="edit-selected-inventory-item-button"
            onClick={handleEdit}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            Edit
          </a>
          <a
            className="remove-selected-inventory-item-button"
            onClick={handleRemove}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            Delete
          </a>
        </span>
      )
    : () => {};

  return (
    <div className="fields">
      {isEditing ? (
        <Edit
          components={{ Controls: Controls }}
          filterableInventoryItemIds={filterableInventoryItemIds}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          selected={selected}
        />
      ) : (
        <Show
          components={{ Controls: Controls }}
          selected={{ ...selected?.meta }}
        />
      )}
      {!isEditing && components?.EquipmentMasterFallbacks()}
    </div>
  );
}

Pill.propTypes = {
  components: PropTypes.object,
  filterableInventoryItemIds: PropTypes.arrayOf(PropTypes.number),
  handleCancel: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  handleRemove: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  isEditing: PropTypes.bool,
  selected: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inventoryItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endOfCase: PropTypes.bool,
    startTime: PropTypes.number,
    meta: PropTypes.shape({
      homeFacility: PropTypes.shape({
        id: PropTypes.number,
        siteCode: PropTypes.string,
        name: PropTypes.string,
      }),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      usage: PropTypes.shape({
        duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        endOfCase: PropTypes.bool,
        startTime: PropTypes.number,
      }),
      url: PropTypes.string, // "http://localhost:3000/inventories/13379",
      collaborativelyPurchased: PropTypes.bool,
      description: PropTypes.string,
      equipmentMaster: PropTypes.shape({
        id: PropTypes.number,
        manufacturerName: PropTypes.string,
        modelTitle: PropTypes.string,
        name: PropTypes.string,
        titleDescription: PropTypes.string,
      }),
      status: PropTypes.string,
      accessoryMasters: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string, quantity: PropTypes.number })
      ),
      rentalCompany: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      codes: PropTypes.shape({
        assetTag: PropTypes.string,
        cohealoTag: PropTypes.string,
        serial: PropTypes.string,
      }).isRequired,
      owningFacility: PropTypes.shape({
        id: PropTypes.number,
        siteCode: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
};
