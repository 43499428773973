import React from "react";
import PropTypes from "prop-types";

// These constants define the finder query limit
//   - please maintain this
const REQUEST_LIMIT = 10;
const REQUEST_LIMIT_ON_LOAD = 5;

// this.props.url determines whether this is a show component or edit component
class MovesNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      movesNotes: this.initializeFirstNote(),
      currentIndex: 0,
      newClinicianNote: this.props.newClinicianNote || "",
      newDriverNote: this.props.newDriverNote || "",
      changed: false,
    };
    /* example of movesNotes
      [
        { moveId, date, equipmentInfo, driverNote, clinicianNote },
        { ... },
        { ... }
      ]
    */
  }

  componentDidMount() {
    if (this.props.url) {
      $(".nav-tooltip").tooltip();
    }

    // Only edit components have a url
    if (this.props.url) {
      this.props.isPickUp
        ? $("#move_pickup_facility_id.confirm-facility-change").on(
            "change",
            this.handleFacilityChange
          )
        : $("#move_dropoff_facility_id.confirm-facility-change").on(
            "change",
            this.handleFacilityChange
          );
    }
  }

  componentDidUpdate() {
    if (this.state.changed === false) this.setState({ changed: true });
  }

  // NOTE: Require that movesNotes are populated in descended order
  // gets similar moves where updated_at is greater than oldestNoteDate
  //   and limits this to the REQUEST_LIMIT
  handleLoadMore = () => {
    const { movesNotes } = this.state;
    const oldestNoteDate = movesNotes[movesNotes.length - 1].date;

    $.ajax({
      type: "GET",
      url: this.props.url,
      data: {
        facility_id: this.props.facility_id,
        date: oldestNoteDate,
        request_limit: REQUEST_LIMIT,
        is_pick_up: this.props.isPickUp,
      },
      success: (response) => {
        if (response.length) {
          const movesNotesCopy = movesNotes.slice().concat(response);

          this.setState({
            movesNotes: movesNotesCopy,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      },
    });
  };

  initializeFirstNote = () => {
    const movesNote = this.props.movesNote ? this.props.movesNote : {};
    const movesNotes = this.props.movesNotes.slice();
    movesNotes.unshift(movesNote);

    return movesNotes;
  };

  handleNavigateRight = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  handleNavigateLeft = () => {
    const { currentIndex, movesNotes } = this.state;
    if (currentIndex === movesNotes.length - 1) return;

    if (movesNotes.length - 2 > currentIndex) {
      this.setState({ currentIndex: currentIndex + 1 });
    } else {
      // Don't ajax for more when we know there
      // are no more movesNotes in DB
      if (movesNotes.length >= REQUEST_LIMIT_ON_LOAD) {
        this.setState({
          currentIndex: currentIndex + 1,
          loading: true,
        });

        this.handleLoadMore();
      } else {
        this.setState({ currentIndex: currentIndex + 1 });
      }
    }
  };

  handleFacilityChange = (e) => {
    this.setState({
      loading: true,
      currentIndex: 0,
      changed: true,
      newClinicianNote: "",
      newDriverNote: "",
      movesNotes: [],
    });

    $.ajax({
      type: "GET",
      url: this.props.url,
      data: {
        facility_id: e.target.value,
        request_limit: REQUEST_LIMIT_ON_LOAD,
        is_pick_up: this.props.isPickUp,
      },
      success: (response) => {
        this.setState({
          loading: false,
          movesNotes: response,
        });
      },
    });
  };

  handleDriverTextAreaChange = (e) => {
    const { currentIndex } = this.state;

    if (currentIndex === 0) {
      this.setState({ newDriverNote: e.target.value });
    } else {
      this.setState({
        newDriverNote: e.target.value,
        newClinicianNote: this.state.movesNotes[currentIndex].clinicianNote,
        currentIndex: 0,
      });
    }
  };

  handleClinicianTextAreaChange = (e) => {
    const { currentIndex } = this.state;

    if (currentIndex === 0) {
      this.setState({ newClinicianNote: e.target.value });
    } else {
      this.setState({
        newClinicianNote: e.target.value,
        newDriverNote: this.state.movesNotes[currentIndex].driverNote,
        currentIndex: 0,
      });
    }
  };

  renderHeaderName = () => {
    const { currentIndex } = this.state;
    if (this.state.loading) {
      return (
        <img
          className="ajax-loader loading"
          src="/images/loading-mini-grey.gif"
        />
      );
    }
    if (currentIndex === 0) return "New message";

    const { moveId, date, equipmentInfo } = this.state.movesNotes[currentIndex];
    return `ID: ${moveId} - ${date} - ${equipmentInfo}`;
  };

  renderNavigateCtrlBtn = (isLeft) => {
    const { currentIndex } = this.state;
    const attrs = {
      disabled: currentIndex === 0,
      className: "control-right btn nav-tooltip",
      iconClassName: "icon-right-caret",
      title: "Next message",
    };

    attrs.onClick = attrs.disabled ? null : this.handleNavigateRight;

    if (isLeft) {
      attrs.disabled =
        currentIndex >= this.state.movesNotes.length - 1 ? true : false;
      attrs.onClick = attrs.disabled ? null : this.handleNavigateLeft;
      attrs.className = "control-left btn nav-tooltip";
      attrs.iconClassName = "icon-left-caret";
      attrs.title = "Previous message";
    }

    return (
      <div
        disabled={attrs.disabled}
        className={attrs.className}
        onClick={attrs.onClick}
        data-title={attrs.title}
        id={attrs.id}
      >
        <i className={attrs.iconClassName} />
      </div>
    );
  };

  renderControls = () => {
    const headerClass = this.state.loading
      ? "moves-note-heading text-center"
      : "moves-note-heading";

    return (
      <div className="control-tab">
        <i className="sprite-icon sprite-mail-s-grey mail-icon" />
        <div className={headerClass}>
          <small>{this.renderHeaderName()}</small>
        </div>
        <div className="moves-notes-controls">
          {this.renderNavigateCtrlBtn(true)}
          {this.renderNavigateCtrlBtn(false)}
        </div>
      </div>
    );
  };

  renderTextArea = (isDriver) => {
    const { movesNotes, currentIndex, newDriverNote, newClinicianNote } =
      this.state;

    const attrs = {
      onClickHandler: this.handleClinicianTextAreaChange,
      text:
        currentIndex === 0
          ? newClinicianNote
          : movesNotes[currentIndex].clinicianNote || "",
      name: this.renderFormName(isDriver) + "[note]",
    };

    if (isDriver) {
      attrs.onClickHandler = this.handleDriverTextAreaChange;
      attrs.text =
        currentIndex === 0
          ? newDriverNote
          : movesNotes[currentIndex].driverNote || "";
    }

    return (
      <textarea
        className="input-block-level text-input"
        name={attrs.name}
        row="4"
        onChange={attrs.onClickHandler}
        value={attrs.text}
        placeholder="Enter message..."
      />
    );
  };

  renderFormName = (isDriver) => {
    const { isPickUp } = this.props;

    if (isDriver) {
      if (isPickUp) {
        return "move[driver_notes_attributes][0]";
      }
      return "move[driver_notes_attributes][1]";
    }
    if (isPickUp) {
      return "move[clinical_notes_attributes][0]";
    }
    return "move[clinical_notes_attributes][1]";
  };

  renderEdit = () => {
    const { isPickUp } = this.props;
    const className = `moves-notes-container ${
      isPickUp ? "pick-up" : "drop-off"
    }`;

    return (
      <div className={className} data-changed={this.state.changed}>
        {this.renderControls()}

        <div className="clinician-notifications">
          <div className="note-label">Clinical Staff Notification:</div>

          {this.renderTextArea(false)}
          <input
            type="hidden"
            name={this.renderFormName(false) + "[id]"}
            value={this.props.clinicianNoteId || ""}
          />
          <input
            type="hidden"
            name={this.renderFormName(false) + "[origin]"}
            value={isPickUp ? "pick_up" : "drop_off"}
          />
        </div>

        <div className="driver-instructions">
          <div className="note-label">Driver Instructions:</div>
          {this.renderTextArea(true)}
          <input
            type="hidden"
            name={this.renderFormName(true) + "[id]"}
            value={this.props.driverNoteId || ""}
          />
          <input
            type="hidden"
            name={this.renderFormName(true) + "[origin]"}
            value={isPickUp ? "pick_up" : "drop_off"}
          />
        </div>
      </div>
    );
  };

  renderNote = (note) =>
    note.length ? note : <p className="text-center empty">None</p>;

  renderShow = () => {
    const { newClinicianNote, newDriverNote } = this.state;

    return (
      <div className="moves-notes-container">
        <div className="clinician-notifications">
          <div className="note-label">
            <b>Clinical Staff Notification:</b>
          </div>

          <div className="note-content">
            {this.renderNote(newClinicianNote)}
          </div>
        </div>

        <div className="driver-instructions">
          <div className="note-label">
            <b> Driver Instructions:</b>
          </div>
          <div className="note-content">{this.renderNote(newDriverNote)}</div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.url) {
      return this.renderEdit();
    }
    return this.renderShow();
  }
}

MovesNotes.propTypes = {
  isPickUp: PropTypes.bool,
  movesNotes: PropTypes.array,
};

export default MovesNotes;
