export const takeSample = (samples, n) => {
  if (n < 0) return null;
  if (n >= samples.length) return samples;

  const sample = [];
  const usedSample = {};

  let sampleTimes = n;
  for (let i = 0; i < sampleTimes; i++) {
    const sampleIdx = Math.floor(Math.random() * samples.length);

    if (usedSample[sampleIdx]) {
      sampleTimes++;
      continue;
    }
    usedSample[sampleIdx] = true;

    sample.push(samples[sampleIdx]);
  }

  return sample;
};
