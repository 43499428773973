import React, { useContext, useState } from "react";

import { EquipmentContext } from "../useEquipmentContext";

import Pill from "./pill/Pill";
import EquipmentMasterFallbacks from "./pill/fallbacks/EquipmentMasterFallbacks";

export default function InventoryItems({
  canCreate,
  handleChange,
  handleFallbackChange,
  isActive = true,
  isArchived = false,
  isDisabled = false,
  isEditable = true,
  value = {},
}) {
  const { disabledFallbacks, inventoryItems } = value;

  // Inventory item crud management
  const [currEditingIndex, setIndex] = useState(null);
  const [newInventoryItem, setNewInventoryItem] = useState(null);

  // All fallback management
  const { equipmentMasterFallbacks } = useContext(EquipmentContext);

  // On load, value should be null or undefined.
  // Only when user makes any submission, will handleChange
  // be invoked and value will be updated.
  // This component will then use value as the selected afterwards
  const selected = inventoryItems || [];
  const fallbackOptionsByMaster = selected.reduce((memo, ii) => {
    const eqmId = ii?.meta?.equipmentMaster?.id;
    const fallbacks = equipmentMasterFallbacks[eqmId] ?? [];

    if (fallbacks.length) {
      memo[eqmId] = fallbacks.map((fb) => {
        const disabledFallback = disabledFallbacks?.find(
          (val) => val.equipmentMasterFallbackId == fb.id
        );

        return {
          id: null,
          equipmentMasterFallbackId: fb.id,
          meta: fb,
          value: false,
          // if PreferenceDisabledFallback is present, we will
          // assign an id and value of true
          ...(disabledFallback && {
            id: disabledFallback.id,
            value: true,
          }),
        };
      });
    }
    return memo;
  }, {});

  // List controls
  const handleAdd = (e) => {
    e.preventDefault();

    // Let the _edit component handle setting defaults
    setIndex(selected.length);
    setNewInventoryItem({});
  };

  const handleEdit = (idx) => {
    if (currEditingIndex == null) setIndex(idx);
  };

  const handleRemove = (idx) => {
    if (newInventoryItem) {
      handleCancel();
    } else {
      let newSelected = selected;
      const field = newSelected[idx];

      if (field.id) {
        field._destroy = true;
      } else {
        newSelected.splice(idx, 1);
      }
      setIndex(null);
      handleChange(newSelected);
    }
  };

  // Pill controls
  const handleCancel = () => {
    if (newInventoryItem) setNewInventoryItem(null);
    setIndex(null);
  };

  const handleSubmit = (change, index) => {
    if (newInventoryItem) {
      selected.push(change);
      setNewInventoryItem(null);
    } else {
      selected[index] = change;
    }
    if (typeof currEditingIndex === "number") setIndex(null);
    handleChange([...selected]);
  };

  const inventoryItemIds = selected.reduce((memo, epii) => {
    if (epii.meta.type !== "RentableInventory")
      memo.push(parseInt(epii.inventoryItemId));
    return memo;
  }, []);

  // NOTE: Only Disabled Fallbacks are committed to the datebase!
  const fallbacksOnChange = (fallbackList) => {
    const newDisabledFallbacks = [...(disabledFallbacks ?? [])];
    fallbackList.forEach((pdfChange) => {
      const idx = newDisabledFallbacks.findIndex(
        (pdf) =>
          pdf.equipmentMasterFallbackId == pdfChange.equipmentMasterFallbackId
      );

      const pdf = newDisabledFallbacks[idx];
      if (pdfChange.value) {
        if (pdf) {
          // newDisabledFallbacks should have all values as true
          if (pdf?.id && pdf?._destroy) pdf._destroy = false;
        } else {
          newDisabledFallbacks.push({
            id: pdfChange?.id,
            equipmentMasterFallbackId: pdfChange.equipmentMasterFallbackId,
          });
        }
        // destroy
      } else {
        if (pdf)
          pdf?.id ? (pdf._destroy = true) : newDisabledFallbacks.splice(idx, 1);
      }
    });
    handleFallbackChange(newDisabledFallbacks);
  };

  const buildInventoryItemPill = (props, index) => {
    const isEditing = currEditingIndex === index;
    // UX, show edit controls for the following condition
    const isPillEditable =
      isEditable && (currEditingIndex == null || isEditing);
    const key = props.id ?? `new_inventory_item_pill_${index}`;

    const options =
      (props?.meta?.type == "RentableInventory"
        ? []
        : fallbackOptionsByMaster[props?.meta?.equipmentMaster?.id]) ?? [];

    const components = options.length
      ? {
          EquipmentMasterFallbacks: () => (
            <EquipmentMasterFallbacks
              isActive={isActive}
              isEditable={isEditable}
              key={`${key}-fallbacks`}
              onChange={fallbacksOnChange}
              options={options}
            />
          ),
        }
      : null;

    return (
      <Pill
        {...{ components }}
        isEditable={!isDisabled && isPillEditable}
        isEditing={isEditing}
        filterableInventoryItemIds={inventoryItemIds}
        handleCancel={() => handleCancel(index)}
        {...(isPillEditable && { handleEdit: () => handleEdit(index) })}
        handleRemove={() => handleRemove(index)}
        handleSubmit={(change) => handleSubmit(change, index)}
        key={key}
        selected={props}
      />
    );
  };

  const EquipmentPills = selected.reduce((memo, ii, index) => {
    if (!ii._destroy) memo.push(buildInventoryItemPill(ii, index));
    return memo;
  }, []);

  if (newInventoryItem)
    EquipmentPills.push(
      buildInventoryItemPill(newInventoryItem, selected.length)
    );

  const canShowAddEquipment =
    isEditable && !(Number.isInteger(currEditingIndex) || newInventoryItem);

  return (
    <div className="row-fluid equipment">
      <div className="span12">
        {EquipmentPills.length ? (
          <div
            className="preference-edit-fields"
            id="equipment_preferences_inventory_items"
          >
            <div id="inventory_items_details">
              <h3 className="equipment-selection-heading">Equipment</h3>
              {EquipmentPills}
              {!isDisabled && canShowAddEquipment && (
                <ButtonAddEquipment onClick={handleAdd} />
              )}
            </div>
          </div>
        ) : (
          <DefaultEmpty
            canCreate={canCreate}
            disabled={isDisabled || isArchived}
            onClick={handleAdd}
          />
        )}
      </div>
    </div>
  );
}

const DefaultEmpty = ({ canCreate, disabled, onClick }) => {
  const disabledKlass = disabled || !canCreate ? " disabled" : "";

  return (
    <div
      className={`equipment_not_required${disabledKlass}`}
      id="no-equipment-info"
      onClick={!!disabledKlass ? () => {} : onClick}
    >
      {canCreate ? (
        <ViewAddEquipment disabledKlass={disabledKlass} />
      ) : (
        <ViewEquipmentNotRequired />
      )}
    </div>
  );
};

const ButtonAddEquipment = ({ onClick }) => (
  <div className="equipment-selector-controls">
    <a
      id="add-more-equipment-link"
      data-automation-id="add_more_equipment_link"
      style={{ display: "inline", cursor: "pointer" }}
      onClick={onClick}
    >
      + Add equipment
    </a>
  </div>
);

const ViewAddEquipment = ({ disabledKlass }) => (
  <a className="target-outline add_nested_fields" href="#">
    <h3>No equipment added</h3>
    <span className={`btn btn-large btn-primary${disabledKlass}`}>
      + Add Equipment
    </span>
    <div className="row-fluid">
      <div className="span8 offset2">
        <div className="alert alert-small" style={{ marginTop: "30px" }}>
          <div className="content">
            <i
              className="sprite-icon sprite-warning-s-fullcolor"
              style={{ marginTop: "3px" }}
            />
            <p>
              Equipment Preferences without equipment will automatically apply
              <code style={{ margin: "0px 3px 0px 3px" }}>
                Equipment not required
              </code>
              to matching cases
            </p>
          </div>
        </div>
      </div>
    </div>
  </a>
);

const ViewEquipmentNotRequired = () => (
  <div className="box-page box-page-dark">
    <div className="box-content">
      <p
        className="lead empty"
        style={{ margin: "0px", padding: "20px", textAlign: "center" }}
      >
        Equipment not required
      </p>
    </div>
  </div>
);
