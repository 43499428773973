import React, { useContext } from "react";
import { components } from "react-select-3";

import EquipmentSelect from "../../../../../forms/selects/EquipmentSelect/EquipmentSelect";
import { EquipmentContext } from "../../../useEquipmentContext";

export default function EquipmentMasterSelect({
  isLoading = false,
  handleChange,
  value,
}) {
  const {
    equipmentMasters: { isLoaded, options },
  } = useContext(EquipmentContext);

  const klassName = "equipment-master-select";
  const name = `edit-${klassName}`;

  // If value is present, but missing from options, we will push
  // a temp master to ensure placeholders are displayed to prompt
  // the user to change the selection.
  //
  // Generally, this use case is only for new prefs (facility can change).
  // The request for masters will filter out unavail master. However,
  // we allow ux to let users know this master no longer has any inventory options.
  let placeholderOption;
  if (value && !options?.find((opt) => opt.value == value?.value)) {
    const {
      value: id,
      data: {
        manufacturer_name: manufacturerName,
        model_title: modelTitle,
        name,
        title_description: titleDescription,
      },
    } = value;
    placeholderOption = {
      data: {
        id,
        manufacturerName: `Previously Selected: '${manufacturerName}' does not have inventory options`,
        modelTitle,
        name: name,
        titleDescription,
      },
      label: "",
      value: value.value,
    };
  }

  return (
    <div className="control-group clearfix">
      <div className="col-left">
        <label>Equipment:</label>
      </div>
      <div className="col-right">
        <EquipmentSelect
          components={{ Option }}
          className={klassName}
          isDisabled={isLoading || !isLoaded}
          key={name}
          name={name}
          options={
            placeholderOption ? [placeholderOption, ...options] : options
          }
          onChange={handleChange}
          placeholder={isLoading ? "loading..." : "Please select equipment"}
          renderContent={renderDataContent}
          value={value}
        />
      </div>
    </div>
  );
}

const renderDataContent = ({
  id,
  manufacturerName,
  modelTitle,
  titleDescription,
}) => (
  <div className="content" key={`content-${id}`}>
    <div className="primary">
      <span className="manufacturer-name">{manufacturerName}</span>
    </div>
    <div className="secondary">
      <span className="model-title">{modelTitle} </span>
      <span className="title-description">{titleDescription}</span>
    </div>
  </div>
);

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const { data, ...restData } = props.data;
  const newProps = {
    ...props,
    data: restData,
    innerProps: rest,
  };

  return (
    <components.Option {...newProps}>
      {renderDataContent(data)}
    </components.Option>
  );
};
