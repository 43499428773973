import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  FACILITIES_PATH,
  SERVICE_LINES_PATH,
  USERS_PATH,
} from "../../utils/capi";
import { RequestProvider } from "../../utils/capiClient";
import { EquipmentProvider } from "./useEquipmentContext";

import { CloneAlert, InProcessingAlert, ReplacedByAlert } from "./alerts";
import { displayDate } from "../../utils/datetime";
import ActivityLog from "./activity_log/ActivityLog";
import AuthorSelect from "./author/AuthorSelect";
import Buttons from "./buttons/Buttons";
import Select from "../../forms/selects/Select";
import InventoryItems from "./inventory_items/InventoryItems";
import Name from "./name/Name";
import Notes from "./notes/Notes";
import PatientCases from "./patient_cases/PatientCases";
import Physicians from "./physicians/Physicians";
import Procedures from "./procedures/Procedures";
import VerifiedSelect from "./verified/VerifiedSelect";
import Versions from "./Versions";
import { SideView, TopView } from "./utils/utils";
import useForm from "../../utils/useForm";
import getForm, { getEquipmentPreferencesInventoryItems } from "./getForm";

const defaultForm = {
  flags: {
    hasUnsavedChanges: false,
    isDisabled: null,
    isEditable: null,
    isCloneOfArchive: null,
  },
  selected: {
    createdById: null,
    criteriaAttributes: [],
    facilityId: null,
    healthSystemId: null,
    name: null,
    notes: "",
    serviceLineId: null,
    verifiedById: null,
    verifiedOnDate: null,
  },
  meta: {
    // Info related to this preference
    attributes: {
      createdAtDate: null,
      createdName: null,
      facilityName: null,
      facilitySiteCode: null,
      replacedById: null,
      replacesId: null,
      serviceLineName: null,
      state: "active",
      updatedAtDate: null,
      verifiedName: null,
    },
    alerts: null,
  },
};

export default function Form({
  capiToken,
  cloneId,
  healthSystemId,
  id: equipmentPreferenceId,
  roles,
  user,
  ...rest
}) {
  const [adminOptions, setAdminOptions] = useState([]);

  const {
    errors,
    flags: { isDeleting, isLoaded, isLoading, isSaving },
    form,
    handleDestroy,
    handleSubmit,
    hasFormChanged,
    request,
    setForm,
    validateForm,
  } = useForm({
    onNewLoad: ({ form, setFormOnLoad }) => {
      setFormOnLoad({
        ...form,
        flags: {
          hasUnsavedChanges: false,
          isDisabled: false,
          isEditable: true,
        },
        selected: {
          ...form.selected,
          createdById: user.id,
          createdName: user.name,
        },
      });
    },
    onEditLoad: async ({ form, request, setFormOnLoad }) => {
      try {
        const [newForm, inventoryItems] = await Promise.all([
          getForm({
            id: form.id || cloneId,
            isClone: !!cloneId,
            request,
          }),
          getEquipmentPreferencesInventoryItems({
            id: form.id || cloneId,
            isClone: !!cloneId,
            request,
          }),
        ]);
        if (inventoryItems)
          newForm.selected.equipmentPreferencesInventoryItemsAttributes =
            inventoryItems;

        const latestForm = {
          ...form,
          ...newForm,
        };

        setFormOnLoad(latestForm);
      } catch (err) {
        console.log(err);
      }
    },
    token: capiToken,
    validations: [
      { field: "name" },
      { field: "facilityId" },
      { field: "serviceLineId" },
      {
        field: "criteriaAttributes",
        fn: (attr) => attr.procedurePattern?.length,
      },
    ],
    value: {
      ...(cloneId && { cloneId }),
      ...defaultForm,
      ...(equipmentPreferenceId && {
        id: cloneId || equipmentPreferenceId || null,
      }),
      selected: {
        ...defaultForm.selected,
        ...(healthSystemId && { healthSystemId }),
        ...(equipmentPreferenceId && {
          id: cloneId || equipmentPreferenceId || null,
        }),
      },
    },
  });

  const preloadAdminOption = useCallback(() => {
    request
      .get(USERS_PATH, { params: { roles: roles.admin } })
      .then(({ data }) => {
        if (data?.data?.length) {
          const resp = data.data.map((opt) => ({
            label: opt.attributes.name,
            value: opt.id,
          }));
          setAdminOptions(resp);
        }
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  }, []);

  useEffect(() => {
    preloadAdminOption();
  }, []);

  const {
    createdAtDate,
    createdName,
    deletedAt,
    facilityName,
    facilitySiteCode,
    primeName,
    replacedById,
    replacesId,
    serviceLineName,
    state,
    updatedAtDate,
    verifiedName,
  } = form.meta.attributes;

  const facilityId = form?.selected?.facilityId;
  const isActive = state === "active";
  const isArchived = !!deletedAt;

  const isNew = !(form?.id || equipmentPreferenceId);
  const providerConfig = useMemo(
    () =>
      isLoaded
        ? {
            options: { admins: adminOptions },
            params: {
              authorRoles: roles.author.toString().trim(),
              cloneId,
              facilityId: facilityId,
              healthSystemId: form.selected.healthSystemId,
              id: equipmentPreferenceId || form?.id,
              user: {
                canCreate: user.canCreate,
                isAdmin: user.isAdmin,
                timeZone: user.timeZone,
              },
              verifierRoles: roles.verifier.toString().trim(),
            },
            request,
          }
        : {},
    [adminOptions.length, isLoaded, facilityId, isNew]
  );

  // Expect change to be an object e.g. { object_id: 123, or_more: foo }
  const handleChange = (change) => {
    const updatedForm = {
      ...form,
      flags: {
        ...form.flags,
        // data key presence means that handleChange was triggered on association load
        // When data is passed, we will preserve the last hasUnsavedChanges state,
        // all else true.
        hasUnsavedChanges: !!change.data ? form.flags.hasUnsavedChanges : true,
      },
      selected: { ...form.selected, ...(change.data ? change.data : change) },
    };

    // Currently present for testing.
    // However, there's no need for handleChange in props
    if (rest.handleChange) rest.handleChange(updatedForm.selected);

    setForm(updatedForm);
  };

  const { flags, selected } = form;
  const formFlags = { ...flags, isDisabled: isLoading || flags.isDisabled };

  const weirdKey = `${
    form?.id || "new"
  }${cloneId}${healthSystemId}${JSON.stringify(user)}${capiToken}`;

  const notices = [];
  if (replacedById)
    notices.push(
      <ReplacedByAlert key="rb-alert" replacedById={replacedById} />
    );
  if (cloneId) notices.push(<CloneAlert key="c-alert" />);
  if (state && state !== "active")
    notices.push(<InProcessingAlert key="ip-alert" />);

  return (
    <section id="content" key={weirdKey}>
      <RequestProvider {...providerConfig}>
        <div id="content-wrapper">
          {!!notices.length && (
            <div className="row-fluid back-link">
              <div className="row-fluid">{notices}</div>
            </div>
          )}

          <div className="row-fluid">
            <ErrorView errors={errors} />
            {
              // order matters in view components
            }
            <TopView>
              <Name
                {...formFlags}
                handleChange={(inputValue) =>
                  handleChange({ name: inputValue })
                }
                value={selected.name}
              />
              <FormSelect
                {...formFlags}
                components={{
                  Label: (attrs) =>
                    `${attrs.name} (${attrs.siteCode || attrs.site_code})`,
                }}
                handleChange={(change) =>
                  handleChange({ facilityId: change.value })
                }
                placeholder="Please select a facility"
                requestUrl={FACILITIES_PATH}
                requestParams={{
                  healthSystemId: selected.healthSystemId,
                  physical: true,
                }}
                title="Facility"
                value={selected.facilityId}
                valueName={
                  facilityName && `${facilityName} (${facilitySiteCode})`
                }
              />
              <FormSelect
                {...formFlags}
                handleChange={(change) =>
                  handleChange({ serviceLineId: change.value })
                }
                placeholder="Please select a service line"
                requestUrl={SERVICE_LINES_PATH}
                requestParams={{
                  healthSystemId: selected.healthSystemId,
                }}
                title="Service Line"
                value={selected.serviceLineId}
                valueName={serviceLineName}
              />
              <VerifiedSelect
                {...formFlags}
                handleChange={(change) =>
                  handleChange({
                    verifiedById: change.verifiedById,
                    verifiedOnDate: change.verifiedOnDate,
                  })
                }
                verifiedById={selected.verifiedById}
                verifiedName={verifiedName}
                verifiedOnDate={selected.verifiedOnDate}
              />
              <AuthorSelect
                {...formFlags}
                createdById={selected.createdById}
                createdName={createdName}
                handleChange={(change) =>
                  handleChange({ createdById: change.value })
                }
              />
              {createdAtDate && (
                <div className="created">
                  <span className="field-title">Created:</span>
                  <span className="field-content">
                    {displayDate(createdAtDate, { format: "DATE" })}
                  </span>
                  <br />
                </div>
              )}
              {updatedAtDate && (
                <div className="updated">
                  <span className="field-title">Updated:</span>
                  <span className="field-content">
                    {displayDate(updatedAtDate, { format: "DATE" })}
                  </span>
                  <br />
                </div>
              )}
            </TopView>
          </div>
          <div className="row-fluid">
            <SideView className="span6" id="left">
              <div className="box-content">
                <Physicians
                  {...formFlags}
                  handleChange={(change) =>
                    handleChange({ criteriaAttributes: change })
                  }
                  selected={selected.criteriaAttributes}
                />
              </div>
              <div className="box-content" id="procedures_list">
                <Procedures
                  {...formFlags}
                  handleChange={(change) =>
                    handleChange({ criteriaAttributes: change })
                  }
                  selected={selected.criteriaAttributes}
                  key={`procedures-${form?.id || "new"}`}
                />
              </div>
              <div className="box-content">
                <div className="row-fluid">
                  <Notes
                    {...formFlags}
                    handleChange={(inputValue) =>
                      handleChange({ notes: inputValue })
                    }
                    value={selected.notes}
                  />
                </div>
              </div>
            </SideView>
            <SideView className="span6" id="right">
              <div className="box-content">
                <EquipmentProvider>
                  <InventoryItems
                    {...formFlags}
                    canCreate={providerConfig.params?.user?.canCreate}
                    handleChange={(change) => {
                      const _change = {
                        equipmentPreferencesInventoryItemsAttributes:
                          change.data ? change.data : change,
                      };
                      handleChange(change.data ? { data: _change } : _change);
                    }}
                    handleFallbackChange={(change) =>
                      handleChange({
                        preferenceDisabledFallbacksAttributes: change,
                      })
                    }
                    isActive={isActive}
                    isArchived={isArchived}
                    value={{
                      disabledFallbacks:
                        selected.preferenceDisabledFallbacksAttributes,
                      inventoryItems:
                        selected.equipmentPreferencesInventoryItemsAttributes,
                    }}
                  />
                </EquipmentProvider>
              </div>
            </SideView>
            <HumanizedText className="row-fluid" />
            <Buttons
              canCreate={providerConfig.params?.user?.canCreate}
              components={{
                ...(cloneId && {
                  primeProps: { id: cloneId, name: primeName },
                }),
              }}
              cloneId={cloneId}
              handleDestroy={handleDestroy}
              handleSubmit={
                form.flags.hasUnsavedChanges || form.flags.isCloneOfArchive
                  ? handleSubmit
                  : null
              }
              flags={{
                isActive,
                isArchived,
                isDeleting,
                isDisabled: (!isActive && !isNew) || !validateForm(),
                isNew,
                isEditable: formFlags.isEditable,
                isSaving,
              }}
              formId={form?.id}
              requireCloneConfirm={!isArchived && hasFormChanged()}
            />
            {!isNew && (
              <div>
                <div className="collapsable-group span9">
                  <div className="row-fluid">
                    <PatientCases isSaving={isSaving} />
                  </div>
                  {providerConfig.params?.user?.isAdmin && (
                    <div className="row-fluid">
                      <ActivityLog isSaving={isSaving} />
                    </div>
                  )}
                </div>
                <div className="span3 row-fluid">
                  <Versions
                    replacedById={replacedById}
                    replacesId={replacesId}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </RequestProvider>
    </section>
  );
}

const ErrorView = ({ errors = [] }) => {
  if (!(errors && errors.length)) return null;

  return (
    <div className="row-fluid">
      <div className="alert alert-error" id="error_explanation">
        <div className="content">
          <i className="icon icon-remove-sign" />
          <strong>
            {`${errors.length} error(s) prohibited this from being saved:`}
          </strong>
          {
            // %ul TODO
            //   - if @equipment_preference.errors[:existing_duplicate_ep_id].present?
            //     - duplicate_ep = EquipmentPreference.find(@equipment_preference.errors[:existing_duplicate_ep_id].first)
            //     - @equipment_preference.errors.delete(:existing_duplicate_ep_id)
            //     %li
            //       = "Criteria matches an existing equipment preference: "
            //       = link_to duplicate_ep.name, equipment_preference_path(duplicate_ep)
            //   - @equipment_preference.errors.full_messages.each do |msg|
            //     %li= msg
          }
        </div>
      </div>
    </div>
  );
};

export const FormSelect = ({ isEditable, title, valueName, ...rest }) => (
  <div>
    <h3 className="required">{`${title}:`}</h3>
    {isEditable ? <Select {...rest} /> : <span>{valueName}</span>}
  </div>
);

const HumanizedText = ({ className }) => (
  <div className={className}>
    <div className="row-fluid humanized-preference">
      {
        //<todo /div> %span= humanize_preference(@equipment_preference)
      }
    </div>
  </div>
);

Form.propTypes = {
  capiToken: PropTypes.string.isRequired,
  cloneId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  healthSystemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  user: PropTypes.object.isRequired,
};

FormSelect.propTypes = {
  isEditable: PropTypes.bool,
  rest: PropTypes.object,
  title: PropTypes.string.isRequired,
  valueName: PropTypes.string,
};

ErrorView.propTypes = {
  errors: PropTypes.array,
};

HumanizedText.propTypes = {
  className: PropTypes.string,
};
