import React from "react";

import { LazySelect } from "./LazySelect";
import { FACILITIES_PATH } from "../../utils/capi";
import { facilitySamples } from "../../specSamples/facility.samples";
import mockRequestContext from "../../specSamples/mockRequestContext";

const { withRequestContext } = mockRequestContext({
  path: FACILITIES_PATH,
  sample: facilitySamples,
});

export default {
  argTypes: { handleChange: { action: "Input value changed" } },
  component: LazySelect,
  decorators: [withRequestContext],
  title: "Form/selects/LazySelect",
};

const Template = (args) => <LazySelect {...args} />;

export const Default = Template.bind({});
Default.args = {
  // alias = {
  //   objectId: null, // physician_id
  //   name: null, // physician
  //   many: null, // Procedures
  // },
  // creatable,
  // filterableIds = [],
  handleChange: () => {},
  // isDisabled = false,
  requestUrl: FACILITIES_PATH,
  // selected,
};

export const Creatable = Template.bind({});
Creatable.args = {
  ...Default.args,
  creatable: true,
  focusOnMount: true,
};

export const Disabled = Template.bind({});
Disabled.args = {
  ...Default.args,
  isDisabled: true,
};

export const FocusOnMount = Template.bind({});
FocusOnMount.args = {
  ...Default.args,
  focusOnMount: true,
};

// take a random data value
const mockObj =
  facilitySamples[Math.floor(Math.random() * facilitySamples.length)];
const selected = {
  label:
    (mockObj.attributes.coded_name || mockObj.attributes.name) ??
    "Invalid Label",
  value: mockObj.id,
};

export const Value = Template.bind({});
Value.args = {
  ...Default.args,
  selected: selected,
};

export const ValueDisabled = Template.bind({});
ValueDisabled.args = {
  ...Value.args,
  isDisabled: true,
};
