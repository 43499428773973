import React from "react";
import PropTypes from "prop-types";

import InventoryAccessories from "./inventory_accessories";
import InventoryInfo from "./inventory_info";

const InventoryPill = ({
  editing,
  enableEditing,
  inventory,
  onClickEdit,
  dimensionInfo,
  weightInfo,
  fallbackFor,
}) => {
  const inventoryPillClasses = `inventory-pill ${editing ? "hidden" : ""}`;
  const lenderName = inventory.isRentableInventory
    ? inventory.homeFacilityName
    : inventory.homeFacilitySiteCode;

  return (
    <div className={inventoryPillClasses}>
      <div className="master-thumb">
        <a href={inventory.link} target="_blank" className="master-thumb-link">
          <img
            className="master-thumb-img"
            src={inventory.imageUrl}
            alt={inventory.name}
          />
        </a>
      </div>
      <div className="master-info">
        {inventory.status == "not-shareable" ? (
          <i className="not-shareable-icon sprite-icon sprite-not-shareable-xs-grey" />
        ) : null}
        <a href={inventory.link} target="_blank">
          {inventory.name}
        </a>
        {enableEditing ? (
          <a
            className="edit-link"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              onClickEdit();
            }}
          >
            <i className="sprite-icon sprite-edit-s-blue edit-icon" />
            <span>Edit</span>
          </a>
        ) : null}
      </div>
      <span className="source-info">
        {inventory.hsShareable ? (
          <span className="shareable-icon">
            <i className="icon icon-globe" />
          </span>
        ) : null}
        <span className="label lender">
          <strong>Lender: {lenderName}</strong>
        </span>
        <InventoryInfo inventory={inventory} />
        {inventory.collaborativelyPurchased ? (
          <span className="label collab">
            <span className="symbol">
              <i className="icon icon-star" />
            </span>
            <span className="content">
              <strong>{inventory.collabLabel}</strong>
            </span>
          </span>
        ) : null}
      </span>
      <div className="source-items clearfix">
        <div className="source-meta">
          <strong>Accessories: </strong>
          <InventoryAccessories inventory={inventory} />
        </div>
        <div className="source-meta-specs">
          <div className="meta-spec-dimension">
            <strong>Dimensions: </strong>
            {dimensionInfo}
          </div>
          <div className="meta-spec-weight">
            <strong>Weight: </strong>
            {weightInfo}
          </div>
        </div>
      </div>
      {fallbackFor != "" ? (
        <div className="source-items clearfix fallbacks">
          <div className="source-meta">
            <i className="icon icon-mail-forward" />
            <strong> Fallback for: </strong>
            {fallbackFor}
          </div>
        </div>
      ) : null}
    </div>
  );
};

InventoryPill.propTypes = {
  editing: PropTypes.bool,
  enableEditing: PropTypes.bool,
  inventory: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
  fallbackFor: PropTypes.string,
};

export default InventoryPill;
