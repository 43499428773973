import React, { useCallback, useEffect, useMemo, useState } from "react";

import _ from "lodash";

import ServerTable from "../../index/tables/ServerTable";
import { capiClient } from "../../utils/capiClient";
import { columns } from "./columns";
import {
  CAMPUS_PATH,
  EQUIPMENT_MASTERS_PATH,
  FACILITIES_PATH,
  HEALTH_SYSTEMS_PATH,
  INVENTORY_ITEMS_PATH,
  PATIENT_CASES_PATH,
  SERVICE_LINES_PATH,
} from "../../utils/capi";
import { getOptions, parseTableData } from "../../index/tables/utils";
import {
  COL_SERVICE_LINE,
  COL_SERVICE_LINE_HS,
} from "../../index/columns/components";

const DEFAULT_HIDDEN_COLS = [
  "campus",
  "closed",
  "emrCaseStatus",
  "emrInstanceId",
  "emrServiceLine",
  "encounterType",
  "externalDatabaseId",
  //"externalId",
  "id",
  "location",
  "mobilized",
  "notes",
  "procedureNotes",
  "surgicalEquipment",
  "updatedBy",
];

const getSearchParams = () => new URLSearchParams(window.location.search);

const setSearchParams = (key, change) => {
  const url = new URL(window.location);

  if (change === null) {
    url.searchParams.delete(key);

    // checks change === {}
  } else if (typeof change === "object" && Object.keys(change).length === 0) {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, JSON.stringify(change));
  }
  history.pushState(null, "", url);
};

export default function Index({
  data: propsData,
  health_system_id,
  token,
  user,
}) {
  const searchParams = getSearchParams();

  const [extraOptions, setExtraOptions] = useState({});
  const [relMaps, setRelMaps] = useState({});
  const [healthSystemId, setHealthSystemId] = useState(
    health_system_id ??
      JSON.parse(searchParams?.get("healthSystemId")) ??
      undefined
  );
  const [pinnedColumns, setPinnedColumns] = useState({ right: ["actions"] });

  const preloadFilterOptions = useCallback((_healthSystemId) => {
    const initialRequests = [
      [CAMPUS_PATH, { healthSystemId: _healthSystemId }],
      [EQUIPMENT_MASTERS_PATH, { healthSystemId: _healthSystemId }],
      [FACILITIES_PATH, { healthSystemId: _healthSystemId, physical: true }],
      [HEALTH_SYSTEMS_PATH, { physical: true }],
      [SERVICE_LINES_PATH, { healthSystemId: _healthSystemId }],
    ].map((props) => getOptions(request, ...props));
    Promise.all(initialRequests).then((resps) => {
      const [
        campuses,
        equipmentMasters,
        facilities,
        healthSystems,
        serviceLines,
      ] = resps;

      const campus = campuses.reduce((memo, camp) => {
        memo[camp.id] = { ...camp.attributes };
        return memo;
      }, {});

      const facilityCampus = facilities.reduce((memo, fac) => {
        if (fac.attributes.campusId)
          memo[fac.id] = campus[fac.attributes.campusId];
        return memo;
      }, {});

      setRelMaps({ facilityCampus });
      setExtraOptions({
        equipmentMasters,
        campuses,
        facilities,
        healthSystems,
        serviceLines,
      });
    });
  }, []);

  useEffect(() => {
    preloadFilterOptions(healthSystemId);
  }, []);

  const indexProvider = useMemo(
    () => ({
      extras: {
        relMaps,
        options: extraOptions,
      },
      params: {
        healthSystemId,
        user,
      },
      request: capiClient(token),
    }),
    [
      token,
      healthSystemId,
      extraOptions.equipmentMasters,
      extraOptions.facilities,
      extraOptions.serviceLines,
      extraOptions.healthSystems,
    ]
  );
  const { request } = indexProvider;

  const fetchData = (params) => {
    return request
      .get(PATIENT_CASES_PATH, params)
      .then(({ data }) => [parseTableData(data), data.meta.total_count])
      .then((resp) => {
        const [patientCases, totalCount] = resp;
        const patientCaseIds = patientCases.map((pc) => pc.id);
        if (patientCaseIds?.length) {
          request
            .get(INVENTORY_ITEMS_PATH, {
              params: {
                patientCaseIds,
                options: {
                  include: [
                    "accessory_masters",
                    "equipment_master",
                    "facility",
                    "rental",
                  ],
                },
              },
            })
            .then(({ data }) => {
              data.data.forEach((ii) => {
                const pc = patientCases.find(
                  (pc) => pc.id == ii.attributes.patientCase.id
                );
                if (pc.inventoryItems === undefined) {
                  pc.inventoryItems = [ii];
                } else {
                  pc.inventoryItems.push(ii);
                }
              });
              return [patientCases, totalCount];
            });
        }
        return [patientCases, totalCount];
      });
  };

  const handleHealthSystemChange = (change) => {
    setHealthSystemId(change);
    setSearchParams("healthSystemId", change);
    preloadFilterOptions(change);
  };

  // Always keep actions right
  const handlePinnedColumnsChange = (change) => {
    const isActionsLast = change.right[change.right.length - 1] === "actions";
    setPinnedColumns(
      isActionsLast
        ? change
        : {
            ...change,
            right: [
              ...change.right.filter((header) => header !== "actions"),
              "actions",
            ],
          }
    );
  };

  const pinnedColumnsProps = {
    pinnedColumns,
    onPinnedColumnsChange: handlePinnedColumnsChange,
  };

  const dynamicColumns = useMemo(
    () =>
      columns.map((col) => {
        if (col.field === "serviceLine")
          return healthSystemId ? COL_SERVICE_LINE_HS : COL_SERVICE_LINE;
        return col;
      }),
    [healthSystemId]
  );

  return (
    <ServerTable
      {...pinnedColumnsProps}
      columns={dynamicColumns}
      data={{ ...propsData, searchParams, setSearchParams }}
      defaultHiddenColumns={DEFAULT_HIDDEN_COLS}
      fetchData={fetchData}
      providerProps={indexProvider}
      slotProps={{
        toolbar: {
          healthSystemId,
          handleHealthSystemChange,
        },
      }}
    />
  );
}
