import { useCallback, useEffect, useMemo, useState } from "react";
import { capiClient } from "./capiClient";
import _ from "lodash";

const PARAM_KEY_BLACKLIST = ["__isNew__", "data", "label", "meta", "value"];

const sanitizeFormSelected = (selected) =>
  _.transform(selected, (memo, val, key) => {
    if (!PARAM_KEY_BLACKLIST.includes(key)) {
      memo[key] = _.isObject(val) ? sanitizeFormSelected(val) : val;
    }
  });

export default function useForm({
  onNewLoad,
  onEditLoad,
  token = null,
  validations = [],
  value = {},
}) {
  const request = useMemo(() => capiClient(token), [token]);

  const [flags, setFlags] = useState({
    isDeleting: false,
    isLoaded: false, // isLoaded is set once, when the initial request is received
    isLoading: false, // isLoading should be continuously set, when the request is received
    isSaving: false,
  });
  const [form, setForm] = useState(value);
  const [initialFormSelected, setInitialFormSelected] = useState(
    _.cloneDeep(value?.selected)
  );

  const isNew = !form?.id;
  const updateFlags = (updated) => setFlags({ ...flags, ...updated });

  const handleDestroy = (url) => {
    if (!request || isNew) return;
    updateFlags({ isDeleting: true });

    request.delete(url, form.selected).finally(() => {
      updateFlags({ isDeleting: false });
      memOnEditLoad();
    });
  };

  const handleSubmit = (url, strongKey) => {
    if (!request) return;

    updateFlags({ isLoading: true, isSaving: true });

    request[isNew ? "post" : "put"](url, {
      [strongKey]: sanitizeFormSelected(form.selected),
    }).then((data) => {
      if (data?.response?.status == 422) {
        updateFlags({ isLoading: false, isSaving: false });
        setForm({
          ...form,
          flags: { ...form.flags, hasUnsavedChanges: false },
        });
      } else {
        if (!isNew) {
          setForm({
            ...form,
            flags: { ...form.flags, hasUnsavedChanges: false },
          });
          memOnEditLoad();
          updateFlags({ isLoading: false, isSaving: false });
        }
        // isNew then capiClient will handle redirect
      }
    });
  };

  const setFormOnLoad = (loadForm) => {
    // why does adding equipment the first time change initialformselected
    setInitialFormSelected(_.cloneDeep(loadForm.selected));
    setForm(loadForm);
  };

  const hasFormChanged = () => !_.isEqual(initialFormSelected, form.selected);

  const validateForm = () =>
    hasFormChanged()
      ? !validations.find((validation) => {
          // find first non-valid
          const val = form.selected[validation.field];

          if (validation.fn) return !val?.find(validation.fn); // find first valid in attrs assoc
          return !val;
        })
      : true;

  const loadProps = {
    form,
    request,
    setFormOnLoad,
    updateFlags,
  };
  const memOnNewLoad = useCallback(() => onNewLoad(loadProps));
  const memOnEditLoad = useCallback(() => onEditLoad(loadProps));

  useEffect(() => {
    if (isNew && !form?.cloneId) {
      memOnNewLoad();
      updateFlags({ isLoaded: true });
    } else {
      updateFlags({ isLoading: true });
      memOnEditLoad();
      updateFlags({ isLoaded: true, isLoading: false });
    }
  }, []);

  return {
    flags,
    form,
    handleDestroy,
    handleSubmit,
    hasFormChanged,
    request,
    setForm,
    validateForm,
  };
}
