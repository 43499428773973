import React, { useState, useEffect, useRef, useCallback } from "react";
import Select, { createFilter } from "react-select-3";
import {
  EQUIPMENT_SELECT_STYLES,
  StyledBlanket,
  StyledControl,
  StyledDropdownIndicator,
  StyledIndicator,
  StyledMenu,
  StyledValue,
} from "./select_styles";

export const DropdownIndicator = () => (
  <StyledDropdownIndicator>
    <svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fillRule="evenodd"
      />
    </svg>
  </StyledDropdownIndicator>
);

const DefaultComponents = {
  ClearIndicator: null,
  DropdownIndicator: DropdownIndicator,
  IndicatorSeparator: null,
  SingleValue: null,
};

export default function EquipmentSelect(props) {
  const [isOpen, setOpen] = useState(false);
  const value = props.value
    ? props.options.find((opt) => opt.value === props.value.value)
    : null;

  const toggleOpen = () => !isDisabled && setOpen(!isOpen);
  const onChange = (change) => {
    toggleOpen();
    props.onChange(change);
  };

  const { isDisabled, placeholder, renderContent } = props;
  return (
    <div className="input-block-level" css={{ position: "relative" }}>
      <StyledControl
        isDisabled={isDisabled}
        onClick={toggleOpen}
        menuIsOpen={isOpen}
      >
        <ValueComponent
          placeholder={placeholder}
          renderContent={renderContent}
          value={value}
        />
        <StyledIndicator>
          <svg
            className="svg"
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
          </svg>
        </StyledIndicator>
      </StyledControl>
      {isOpen ? (
        <SelectComponent {...props} onChange={onChange} value={value} />
      ) : null}
      {isOpen ? <StyledBlanket onClick={toggleOpen} /> : null}
    </div>
  );
}

const SelectComponent = ({
  components,
  className,
  options,
  onChange,
  value,
}) => {
  const selectRef = useRef(null);
  const scrollToSelected = useCallback(() => {
    // https://github.com/JedWatson/react-select/issues/3648#issuecomment-592622713
    // react-select bug: Scroll to selected value + keyboard navigation
    // When `menuIsOpen` flag is true, `onMenuOpen` does not trigger;
    // which I believe handles the scroll functionality.
    //
    // It may be fixed in a newer version of react-select, so please
    // check once we update the package.
    const { select } = selectRef.current;
    const option = select?.state?.selectValue[0];
    if (option) {
      const selectedIndex = select.state.menuOptions.focusable.indexOf(option);
      if (selectedIndex >= 0) {
        select.scrollToFocusedOptionOnUpdate = true;
        select.inputIsHiddenAfterUpdate = false;
        select.setState({
          focusedValue: null,
          focusedOption: option,
        });
      }
    }
  });

  useEffect(() => {
    if (value) scrollToSelected();
  }, []);

  return (
    <StyledMenu>
      <Select
        autoFocus
        backspaceRemovesValue={false}
        className={className}
        closeMenuOnSelect
        components={{ ...DefaultComponents, ...components }}
        controlShouldRenderValue={false}
        filterOption={createFilter({ ignoreAccents: false })}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onChange}
        options={options}
        placeholder="Please start typing..."
        ref={selectRef}
        styles={EQUIPMENT_SELECT_STYLES}
        tabSelectsValue={false}
        value={value ? options.find((opt) => value.value == opt.value) : null}
      />
    </StyledMenu>
  );
};

export const ValueComponent = ({ placeholder, renderContent, value }) => {
  const defaultPlaceholder = placeholder
    ? placeholder
    : "Please select equipment";
  return value ? (
    <StyledValue>{renderContent(value.data)}</StyledValue>
  ) : (
    <StyledValue>
      <div className="placeholder">{defaultPlaceholder}</div>
    </StyledValue>
  );
};
