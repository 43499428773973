import styled from "styled-components";

export const MovesContactComponent = styled.div`
  .Select-option {
    box-sizing: border-box;
    background-color: #fff;
    color: #666666;
    cursor: pointer;
    display: block;
    padding: 8px 10px;

    &:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &.is-selected {
      background-color: #f5faff;
      background-color: rgba(0, 126, 255, 0.04);
      color: #333;
    }

    &.is-focused {
      background-color: #6699ff;
      color: #fff;
      margin: 4px;

      &.empty {
        color: #fff;
      }
    }
  }
`;
