import React, { useMemo, useState } from "react";

import PropTypes from "prop-types";

const { LicenseInfo } = require("@mui/x-data-grid-premium");

import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";

import { Stack, ThemeProvider, createTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import _ from "lodash";
import { sxStyles } from "./styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MUI_X_LICENSE } from "../../utils/capi";
import { DEFAULT_FILTER_MODEL } from "./utils";

LicenseInfo.setLicenseKey(MUI_X_LICENSE);

// https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
const customLocale = {
  filterOperatorContainAnyOf: "contain any of",
  filterOperatorIsReserved: "is reserved",
  filterOperatorIsNotReserved: "is not reserved",
};

// DEV: Please keep styling descriptive and shallow 2 levels max. 3 is pushing it.
const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        // DEV: Normally, overriding the base child styles would work
        // However, some clashing styling with platform styling will
        // override the following component decorations.
        // Instead please override via either:
        // 1. child class through the parent selector (below)
        // 2. parent selector to allow child inheritance
        // 3. style injection via sx.
        //
        // inputSizeSmall: {
        //   fontSize: 13,
        //   padding: "2.5px 0px",
        // },
        root: {
          "&.MuiInputBase-sizeSmall": {
            fontSize: 13,
            "& input": {
              // padding: "8.5px 0px 8.5px 6px",
              lineHeight: "18px",
              textOverflow: "ellipsis",
            },
          },
          // "&.Mui-focused": {
          //   boxShadow:
          //     "inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(82,168,236,0.6)",
          // },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 13,
          marginBottom: 0,
          marginTop: 2,
        },
      },
    },
  },
  typography: {
    fontFamily: ["helvetica", "arial", "sans-serif"].join(","),
  },
});

const MuiThemeWrapper = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </LocalizationProvider>
);

// If col field named "actions" exists, we should
// never allow visibility options for it.
const getTogglableColumns = (cols) =>
  cols.filter((col) => col.field !== "actions").map((col) => col.field);

export default function Table({
  columns,
  data,
  defaultHiddenColumns,
  handleFilterModelChange,
  handleServerFilter,
  handleServerSort,
  slots,
  slotProps,
  ...restProps
}) {
  const searchParams = data?.searchParams;
  const setSearchParams = data?.setSearchParams;

  const defaultColumnVisibilityModel = useMemo(
    () =>
      columns.reduce((memo, col) => {
        memo[col.field] = defaultHiddenColumns
          ? !defaultHiddenColumns.includes(col.field)
          : true;
        return memo;
      }, {}),
    []
  );

  const initialSearchParams = useMemo(() => {
    const colVis = JSON.parse(searchParams?.get("cvm"));
    const _columnVisibilityModel = colVis
      ? Object.keys(defaultColumnVisibilityModel).reduce((memo, field) => {
          memo[field] =
            colVis?.[field] === undefined
              ? defaultColumnVisibilityModel[field]
              : colVis[field];
          return memo;
        }, {})
      : defaultColumnVisibilityModel;

    const _filterModel = JSON.parse(searchParams?.get("fm"));

    return [_columnVisibilityModel, _filterModel];
  }, []);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialSearchParams[0]
  );
  const [filterModel, setFilterModel] = useState(
    initialSearchParams[1] ?? undefined
  );
  const [sortModel, setSortModel] = useState(restProps?.sortModel);

  const handleFilterModel = (model) => {
    setFilterModel(model);
    if (handleServerFilter) handleServerFilter(model);
  };

  const handleFilterDelete = (filterItem) => {
    const idx = filterModel.items.indexOf(filterItem);
    filterModel.items.splice(idx, 1);
    handleFilterModel({ ...filterModel });
  };

  const onColumnVisibilityModelChange = (newModel) => {
    if (searchParams) {
      const newModelParams = Object.keys(newModel).reduce((memo, k) => {
        if (defaultColumnVisibilityModel[k] != newModel[k])
          memo[k] = newModel[k];
        return memo;
      }, {});
      setSearchParams("cvm", newModelParams);
    }
    setColumnVisibilityModel(newModel);
  };

  const onFilterModelChange = (newModel) => {
    if (searchParams) {
      const { items, logicOperator, quickFilterValues } = newModel;

      const newModelParams = {
        items: items.filter((field) => field.value),
        logicOperator,
        quickFilterValues,
      };
      const isDefaultModel = _.isEqual(newModelParams, DEFAULT_FILTER_MODEL);
      setSearchParams("fm", isDefaultModel ? null : newModelParams);
    }
    if (handleFilterModelChange) handleFilterModelChange(newModel);
    handleFilterModel(newModel);
  };

  const onSortModelChange = (newModel) => {
    setSortModel(newModel);
    if (handleServerSort) handleServerSort(newModel);
  };

  return (
    <MuiThemeWrapper>
      <Grid container sx={{ height: "90vh" }}>
        <DataGridPremium
          {...restProps}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          disableAggregation
          disableRowGrouping
          filterModel={filterModel}
          getRowHeight={() => "auto"}
          hideFooter
          localeText={customLocale}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          onFilterModelChange={onFilterModelChange}
          onSortModelChange={onSortModelChange}
          {...(handleServerFilter && { filterMode: "server" })}
          {...(handleServerSort && { sortingMode: "server" })}
          slots={slots}
          slotProps={{
            baseSelect: baseSelectSlotProps,
            columnsPanel: { getTogglableColumns },
            ...slotProps,
          }}
          sortModel={sortModel}
          sx={sxStyles}
        />
      </Grid>
    </MuiThemeWrapper>
  );
}

// DEV: Autocomplete is composed of different internal components from
// baseSelect. Please adjust accordingly.
export const baseAutocompleteSlotProps = {
  ListboxProps: {
    sx: {
      fontSize: 12,
      maxHeight: 450,
      scrollbarWidth: "thin",
    },
  },
  slotProps: {
    popper: {
      sx: {
        width: 150,
        "& .MuiAutocomplete-loading": { fontSize: 13 },
      },
    },
  },
};

const baseSelectSlotProps = {
  native: false, // needs to be here instead of custom BaseSelect
  slotProps: {},
  MenuProps: {
    anchorOrigin: {
      horizontal: "left",
      vertical: "bottom",
    },
    MenuListProps: {
      sx: {
        maxHeight: 450,
        "& .MuiMenuItem-root": {
          fontSize: 12,
        },
      },
    },
    slotProps: {
      paper: {
        sx: {
          scrollbarWidth: "thin",
        },
      },
    },
    transformOrigin: {
      horizontal: "left",
      vertical: "top",
    },
  },
};

export const CellStack = ({ id, value }) => (
  <Stack spacing={1}>
    {value.map((v) => (
      <div key={`cs-${id}-${v}`}>{v}</div>
    ))}
  </Stack>
);
