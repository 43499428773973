import React, { useContext, useEffect, useMemo, useState } from "react";

import _ from "lodash";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextField,
  styled,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { baseAutocompleteSlotProps } from "../Table";
import { getOptions } from "../../../forms/selects/LazySelect";
import { INVENTORY_ITEMS_PATH } from "../../../utils/capi";
import { RequestContext } from "../../../utils/capiClient";
import {
  blue1,
  blueAccent,
  blueLight,
  grey4,
  grey5,
  rental,
  selected as selectedColor,
} from "../../../utils/base";
import { MIN_CHARACTER_LIMIT } from "../ServerTable";

const BaseAutocomplete = (props) => {
  // utilize applyValue in the parent component
  // options, should have default
  const {
    handleChange,
    focusElementRef,
    inputProps,
    item,
    label,
    options,
    value,
    ...rest
  } = props;

  const onInputChange = props.onInputChange
    ? (e) => {
        if (e === null) return;

        props.onInputChange(e);
      }
    : undefined;

  return (
    <Autocomplete
      disableClearable
      getOptionKey={(opt) => `${opt.type}-${opt.id || opt.attributes.name}`}
      onChange={handleChange}
      {...{ onInputChange }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={focusElementRef}
          label={inputProps.label || "Value"}
          {...inputProps}
        />
      )}
      size={props.size || "small"}
      value={value}
      {...baseAutocompleteSlotProps}
      {...rest}
      sx={{
        width: 200,
        ...rest.sx,
      }}
    />
  );
};

// Multi-Select Creatables
// No option selection, only free text.
// e.g. id (field) is any of (operator) 8, 20
export function CreatableMultiSelect(props) {
  const [inputValue, setInputValue] = useState("");

  const { applyValue, item } = props;

  const handleChange = (_, change) => {
    const newChange = change.length
      ? change.reduce((memo, chg) => {
          if (chg.includes(",")) {
            chg.split(",").forEach((token) => memo.push(token));
          } else {
            memo.push(chg);
          }
          return memo;
        }, [])
      : change;
    applyValue({
      ...item,
      value: newChange.length ? newChange : undefined,
    });
    setInputValue("");
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    setInputValue(value);
  };

  return (
    <BaseAutocomplete
      disableClearable={false}
      filterOptions={(x) => x}
      freeSolo
      handleChange={handleChange}
      inputProps={{
        placeholder: props.label,
      }}
      inputValue={inputValue}
      multiple
      onInputChange={handleInputChange}
      options={[]}
      value={item.value}
    />
  );
}

const appendTag = (tags) => {
  const tag = tags.serial
    ? ` SN: ${tags.serial}`
    : tags.cohealoTag
    ? ` Cohealo: ${tags.cohealoTag}`
    : tags.assetTag
    ? ` Asset: ${tags.assetTag}`
    : null;
  return tag ? ` ${tag}` : "";
};

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const GroupHeader = styled("div")((props) => ({
  backgroundColor: props.selected ? selectedColor : blueLight,
  color: props.selected ? "white" : grey4,
  fontSize: 11,
  lineHeight: "inherit",
  padding: "4px 10px",
  position: "sticky",
  top: "-8px",
  zIndex: 1,
  "&:hover": {
    color: grey5,
    textDecoration: "underline",
    cursor: "pointer",
    ...(props.selected && { backgroundColor: blueAccent, color: "white" }),
  },
}));

const GroupItems = styled("ul")((props) => ({
  ...(props.selected && { backgroundColor: blue1 }),
  fontSize: 12,
  margin: 0,
  padding: 0,
  "& .MuiAutocomplete-option": {
    paddingLeft: 4,
    paddingRight: 8,
  },
}));

const renderEquipmentTags = (tagValue, getTagProps) => {
  return tagValue.map((opt, index) => {
    const isMaster = opt.type === "equipmentMasters";
    const isToken = typeof opt === "string";
    const codes = isMaster || isToken ? null : appendTag(opt.attributes.codes);
    const name = isToken
      ? opt
      : isMaster
      ? opt.attributes.name
      : opt.attributes.equipmentMaster.name;

    const textOverflowStyles = {
      textWrap: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    };

    const label = (
      <>
        <span style={textOverflowStyles} title={name}>
          {name}
        </span>
        {codes && (
          <span
            style={{ ...textOverflowStyles, marginLeft: "2px" }}
            title={codes}
          >
            {codes}
          </span>
        )}
      </>
    );

    return (
      <Chip
        {...getTagProps({ index })}
        label={label}
        sx={{
          ...(isMaster && {
            backgroundColor: selectedColor,
            color: "white",
          }),
          ...(opt.attributes?.type === "RentableInventory" && {
            backgroundColor: rental,
            color: "white",
          }),
          ...(typeof opt === "string" && {
            color: selectedColor,
            fontWeight: "bold",
          }),
          fontSize: 12,
          height: "auto",
          "& .MuiChip-label": {
            display: "flex",
            p: "5px 7px",
            flexDirection: "column",
            fontSize: 11,
            whiteSpace: "normal",
          },
        }}
      />
    );
  });
};

export function EquipmentSelect(props) {
  const { params, request } = useContext(RequestContext);
  const { applyValue, item } = props;

  const [masterMapping, setMasterMapping] = useState({});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const requestParams = props.requestParams ?? {};
  const requestTimeout = props.requestTimeout ?? 1500;

  const fetchData = (_inputValue, _params) => {
    if (_inputValue === undefined || _inputValue.length === 0) return;

    setLoading(true);
    request
      .get(INVENTORY_ITEMS_PATH, {
        params: {
          tokens: [_inputValue],
          healthSystemId: params?.healthSystemId,
          options: {
            include: [
              // "accessory_masters",
              "equipment_master",
              "facility",
              "rental",
            ],
            sort: ["facility"],
          },
        },
      })
      .then(({ data }) => {
        data.included.forEach((eqm) => {
          masterMapping[eqm.id] = eqm;
        });

        data.data.forEach((ii) => {
          ii.attributes.equipmentMaster = {
            id: ii.attributes.equipmentMasterId,
            ...masterMapping[ii.attributes.equipmentMasterId].attributes,
          };
        });
        setMasterMapping({ ...masterMapping });
        setOptions(data.data ?? []);
        setLoading(false);
      });
  };

  const handleChange = (_, change) => {
    applyValue({ ...item, value: change });
    setInputValue("");
  };

  const handleInputChange = (e) => {
    if (e === null) return;
    if (e.type === "keydown" && e.code === "Enter") {
      debouncedFetchData.cancel();
      return;
    }

    const { value } = e.target;

    setInputValue(value);
    debouncedFetchData(value, {
      ...(params.healthSystemId && { healthSystemId: params.healthSystemId }),
      ...requestParams,
    });
  };

  // Handler for tokens
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.defaultMuiPrevented = true;
      debouncedFetchData.cancel();

      const { value } = e.target;
      if (value.length >= MIN_CHARACTER_LIMIT)
        handleChange(e, [...(item.value ?? []), value]);
    }
  };

  const debouncedFetchData = useMemo(
    () => _.debounce(fetchData, requestTimeout),
    []
  );

  const renderGroup = (params) => {
    const handleEqmChange = (_) => {
      const equipmentMaster = masterMapping[params.group];

      // const newChange = (item.value ?? []).concat(equipmentMaster);
      let valueContainsMaster = false;
      const newChange = (item.value ?? []).reduce((memo, opt) => {
        if (opt.type === "inventoryItems") {
          if (opt.attributes.equipmentMaster.id != equipmentMaster.id)
            memo.push(opt);
        } else if (opt.id == equipmentMaster.id) {
          valueContainsMaster = true;
        } else {
          memo.push(opt);
        }
        return memo;
      }, []);

      if (!valueContainsMaster) newChange.push(equipmentMaster);

      handleChange(_, newChange ?? []);
    };

    const eqmId = params.group;
    const selected = Boolean(
      item.value?.find(
        (sel) => sel.type === "equipmentMasters" && sel.id == eqmId
      )
    );

    // <li key={`${Math.floor(Math.random() * 10000)}`}>
    return (
      <li key={params.key}>
        <GroupHeader onClick={handleEqmChange} selected={selected}>
          {masterMapping[eqmId].attributes.name}
        </GroupHeader>
        <GroupItems selected={selected}>{params.children}</GroupItems>
      </li>
    );
  };

  const renderOption = (props, option, { selected }, ownerState) => {
    const {
      attributes: { codes, homeFacility },
    } = option;

    return (
      <li {...props}>
        <Checkbox
          checkedIcon={checkedIcon}
          icon={icon}
          size="small"
          checked={selected}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <span>
            {homeFacility.name}
            {option.attributes.type === "RentableInventory" ? " (Rental)" : ""}
          </span>
          {Object.keys(codes).reduce((memo, code) => {
            if (codes[code])
              memo.push(
                <span
                  key={`${option.id}-${code}`}
                  style={{ marginLeft: "3px" }}
                >
                  {`${
                    code == "assetTag"
                      ? "asset"
                      : code == "cohealoTag"
                      ? "cohealo"
                      : code == "serial"
                      ? "sn"
                      : ""
                  }: ${codes[code]}`}
                </span>
              );
            return memo;
          }, [])}
        </Box>
      </li>
    );
  };

  useEffect(() => {
    return () => debouncedFetchData.cancel();
  }, []);

  return (
    <BaseAutocomplete
      disableCloseOnSelect
      filterOptions={(x) => x}
      freeSolo
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) =>
        opt.attributes?.name ?? opt.attributes?.equipmentMaster?.name ?? opt
      }
      groupBy={(opt) => opt.attributes.equipmentMaster.id}
      handleChange={handleChange}
      inputProps={{
        placeholder: "Equipment",
      }}
      isOptionEqualToValue={(option, value) =>
        value.type === "inventoryItems"
          ? option.id == value.id
          : typeof option === "string"
          ? false
          : option.attributes.equipmentMaster.id == value.id
      }
      inputValue={inputValue}
      loading={loading}
      multiple
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      options={options}
      renderGroup={renderGroup}
      renderOption={renderOption}
      renderTags={renderEquipmentTags}
      sx={{
        minWidth: 275,
      }}
      value={item.value ? item.value : []}
    />
  );
}

const renderTags = (tagValue, getTagProps) =>
  tagValue.map((opt, index) => {
    const label = opt?.attributes?.name ?? opt;
    const restProps = {
      label,
      sx: {
        fontSize: 12,
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
        },
      },
      title: label,
    };

    return <Chip {...getTagProps({ index })} {...restProps} />;
  });

export function Select(props) {
  const { extras } = useContext(RequestContext);
  const { applyValue, item } = props;
  const multiple = props.multiple ?? false;

  let options = extras.options?.[props.optionType] ?? [];
  const selectedVals = item.value?.map((val) => val.id ?? val.value);
  if (selectedVals)
    options = options.filter(
      (opt) => !selectedVals.includes(opt.id ?? opt.value)
    );

  const handleChange = (_, change) => {
    applyValue({ ...item, value: change });
  };

  return (
    <BaseAutocomplete
      filterSelectedOptions={multiple}
      getOptionLabel={props.getOptionLabel}
      handleChange={handleChange}
      inputProps={{ label: props.label ?? "" }}
      multiple={multiple}
      options={options}
      {...(multiple && { renderTags })}
      sx={{ minWidth: 250 }}
      value={multiple ? item.value ?? [] : item.value ?? null}
    />
  );
}

export function LazySelect(props) {
  const { extras, params, request } = useContext(RequestContext);
  const initialOptions = useMemo(() => {
    return extras.options?.[props.optionType]?.length
      ? props.requestDataParser(extras.options[props.optionType])
      : [];
  }, []);

  const [options, setOptions] = useState(initialOptions);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { applyValue, item } = props;
  const multiple = props.multiple ?? false;
  const freeSolo = props.freeSolo ?? false;
  const requestDataParser = props.requestDataParser;
  const requestParams = props.requestParams ?? {};
  const requestTimeout = props.requestTimeout ?? 2000;

  const fetchData = (_inputValue, _params) => {
    if (_inputValue.length === 0) {
      setOptions(initialOptions);
      return;
    }
    setLoading(true);
    getOptions([], _inputValue, {
      client: request,
      params: _params,
      url: props.requestUrl,
    }).then((data) => {
      if (data.length === 0 && options.length === 0) return;
      const parsedData = requestDataParser ? requestDataParser(data) : data;
      setOptions(parsedData);
      setLoading(false);
    });
  };
  const debouncedFetchData = useMemo(
    () => _.debounce(fetchData, requestTimeout),
    []
  );

  const handleChange = (_, change) => {
    const newChange = change.length
      ? multiple
        ? change.map((c) => c.label ?? c)
        : change.label ?? change
      : null;
    applyValue({ ...item, value: newChange });
    setInputValue(multiple ? "" : change.label ?? change);
  };

  const handleInputChange = (e) => {
    if (e === null) return;
    if (e.type === "keydown" && e.code === "Enter") return;

    const { value } = e.target;

    setInputValue(value);
    debouncedFetchData(value, {
      ...(params.healthSystemId && { healthSystemId: params.healthSystemId }),
      ...requestParams,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      debouncedFetchData.cancel();
      if ((e.target.value ?? 0).length < MIN_CHARACTER_LIMIT)
        e.defaultMuiPrevented = true;
      return;
    }
  };

  useEffect(() => {
    return () => debouncedFetchData.cancel();
  }, []);

  return (
    <BaseAutocomplete
      filterOptions={(x) => x}
      freeSolo={freeSolo}
      filterSelectedOptions={multiple}
      getOptionKey={(opt) => `${props.label}-${opt.value}`}
      handleChange={handleChange}
      inputProps={{
        placeholder: props.label,
      }}
      inputValue={inputValue}
      loading={loading}
      loadingText={
        inputValue.length >= MIN_CHARACTER_LIMIT
          ? "loading..."
          : `Please enter ${
              MIN_CHARACTER_LIMIT - inputValue.length
            } or more characters.`
      }
      multiple={multiple}
      onKeyDown={handleKeyDown}
      onInputChange={handleInputChange}
      options={
        item.value?.length
          ? options.filter((opt) => !item.value.includes(opt.value))
          : options
      }
      {...(multiple && { renderTags })}
      sx={{ minWidth: 250 }}
      value={item.value ? item.value : multiple ? [] : null}
    />
  );
}
