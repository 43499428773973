import styled from "styled-components";
import { grey2, grey5, grey6, greyLight } from "../../../utils/base";

export const MIN_CONTAINER_HEIGHT = 300;
/** Procedures */
// min-height of parent bug
// display: inline-block;
export const Container = styled.div`
  align-items: stretch;
  display: flex;
  min-height: ${MIN_CONTAINER_HEIGHT}px;
`;

/** Preview */

export const Content = styled.div`
  color: ${grey5};
`;

export const LegacyPlaceholder = styled.div`
  padding-top: 50px;
  text-align: center;

  div {
    padding-top: 20px;
  }
`;

export const PlaceholderIcon = styled.span`
  margin-right: 5px;
`;

export const PreviewContainer = styled.div`
  background: ${greyLight};
  padding: 20px;
`;

const centerPlaceholder = ({ showPlaceholder }) =>
  showPlaceholder &&
  `
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `;

export const Results = styled.div`
  min-height: ${MIN_CONTAINER_HEIGHT}
  overflow-y: auto;
  overflow-x: hidden;
  ${centerPlaceholder}
`;

export const Result = styled.div`
  border-top: 1px solid ${grey2};
  font-size: 11px;
  line-height: 15px;
  padding: 4px 0px;

  &:first-child {
    padding-top: 0px
    border-top: none
  }
`;

export const Title = styled.div`
  color: ${grey6};
  font-weight: bold;
`;
