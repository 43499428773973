import React from "react";
import { render, screen } from "@testing-library/react";
import { composeStories } from "@storybook/react";

import * as stories from "./Name.stories";

const { Default, Disabled, NotEditable, Value, ValueNotEditable } =
  composeStories(stories);

const expectedToHaveLabel = () => {
  const label = screen.getByText(/Name/);
  expect(label).toBeInTheDocument();
};

const expectedNotToHaveLabel = () => {
  const label = screen.queryByTestId(/Name/);
  expect(label).not.toBeInTheDocument();
};

describe("Name", () => {
  describe("when disabled", () => {
    it("should not be editable", () => {
      render(<Disabled />);

      expectedToHaveLabel();
      expect(screen.getByRole("textbox")).toBeDisabled();
    });
  });

  describe("when isEditable false", () => {
    describe("with value", () => {
      it("should have value as header", () => {
        render(<ValueNotEditable />);

        expectedNotToHaveLabel();
        expect(screen.getByText(/foo/i)).toHaveTextContent(/foo/i);
      });
    });

    describe("without value", () => {
      it("should display 'Invalid Name' as header", () => {
        render(<NotEditable />);

        expectedNotToHaveLabel();
        expect(screen.getByText(/invalid name/i)).toHaveTextContent(
          "Invalid Name"
        );
      });
    });
  });

  describe("without value", () => {
    it("should be editable", () => {
      render(<Default />);

      expectedToHaveLabel();

      const input = screen.getByRole("textbox");
      // const input = screen.getByDisplayValue(/foo/i);
      expect(input).toBeInTheDocument();
    });
  });

  describe("with value", () => {
    it("should display value in input", () => {
      render(<Value />);

      expectedToHaveLabel();

      const input = screen.getByDisplayValue(/foo/i);
      expect(input).toHaveValue("Foobar");
      expect(input).toBeInTheDocument();
    });
  });
});
