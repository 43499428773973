import React from "react";
import PropTypes from "prop-types";

class MovesContactPill extends React.Component {
  render() {
    const { name, description, email, phone, primary, index } = this.props;

    return (
      <div className="moves-contact-row inline-block">
        <div className="moves-contact-icon btn btn-link no-edit">
          <span>
            <i
              id={index}
              data-title={primary ? "Primary contact" : "contact"}
              data-placement="right"
              data-delay={{ show: 1000, hide: 100 }}
              className={
                primary
                  ? "sprite-icon sprite-user-s-blue"
                  : "sprite-icon sprite-user-s-outline"
              }
            />
          </span>
        </div>

        <div className="move-contact-show-info">
          <div className="primary-information">
            {name}
            {description ? ", " + description : null}
          </div>
          <div className="secondary-information">
            <small className="truncate">
              {email ? email + (phone ? ", " + phone : "") : phone}
            </small>
          </div>
        </div>
      </div>
    );
  }
}

MovesContactPill.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  primary: PropTypes.bool,
  index: PropTypes.number
};

export default MovesContactPill;
