import React, { useContext, useEffect, useState } from "react";

import { EQUIPMENT_PREFERENCES_PATH } from "../../../utils/capi";
import { RequestContext } from "../../../utils/capiClient";
import { CaseTable } from "./utils";

export default function PatientCases({ isSaving }) {
  const { request, params } = useContext(RequestContext);
  const [state, setState] = useState({
    canEdit: false,
    localeExternalId: null,
    patientCases: [],
  });
  const [isOpen, setisOpenState] = useState(false);

  // Load once on supplied request
  // Do not load when not editable
  useEffect(() => {
    if (isSaving || !request) return;

    request
      .get(`${EQUIPMENT_PREFERENCES_PATH}/${params.id}/patient_cases`, {
        params: { locale: "patient_case.external_id" },
      })
      .then(({ data }) => {
        const responseData = data.data;
        const { included, meta } = data;

        const facilitiesMap = included.reduce((memo, obj) => {
          if (obj.type === "facilities") memo[obj.id] = obj;
          return memo;
        }, {});

        if (responseData) {
          const patientCases = responseData.map((pc) => {
            const facility = facilitiesMap[pc.relationships.facility.data.id];

            return {
              duringAt: pc.attributes.duringAt,
              externalId: pc.attributes.externalId,
              facilityName: facility
                ? `${facility.attributes.name} (${facility.attributes.siteCode})`
                : null,
              facilityTimeZone: facility.attributes?.timeZone,
              id: pc.id,
            };
          });

          setState({
            ...state,
            canEdit: meta.user && meta.user.can_edit,
            localeExternalId: meta.locale && meta.locale.external_id,
            patientCases: patientCases,
          });
        }
      });
  }, [!!request, isSaving]); // check for presence change

  const { canEdit, localeExternalId, patientCases } = state;

  return (
    <div
      className="expand-collapse-group inline-toggle"
      id="equipment-preference-patient-cases"
    >
      <div className="title" onClick={() => setisOpenState(!isOpen)}>
        Patient Cases ({patientCases.length})
        <span className="toggle-icon">
          <i
            className={`sprite-icon sprite-chevron-${
              isOpen ? "up-s-grey collapse" : "down-s-grey expand"
            }`}
          />
        </span>
      </div>
      <div className={`collapse ${isOpen ? "in" : null}`}>
        {patientCases.length ? (
          <CaseTable
            canEdit={canEdit}
            localeExternalId={localeExternalId}
            patientCases={patientCases}
            timeZone={params.user.timeZone}
          />
        ) : (
          <h3 className="empty content">
            There are no future Patient Cases linked to this Equipment
            Preference
          </h3>
        )}
      </div>
    </div>
  );
}
