import React, { useEffect, useRef, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  gridFilterModelSelector,
  gridSortModelSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

import { RequestProvider } from "../../utils/capiClient";

import Table from "./Table";
import ServerToolbar from "./toolbar/ServerToolbar";
import FilterPanel from "./filterPanel/ServerFilterPanel";
import _ from "lodash";
import { DEFAULT_FILTER_MODEL, sanitizeFilterModel } from "./utils";
import { growl } from "../../utils/alert";

// Pagination consts
const INITIAL_DATA_SIZE = 40;
const MAX_ROW_LENGTH = 500;
const MORE_DATA_SIZE = 20;

export const MIN_CHARACTER_LIMIT = 3;

export default function ServerTable({
  columns,
  data: propsData,
  defaultHiddenColumns,
  fetchData, // required
  onPinnedColumnsChange,
  pinnedColumns,
  providerProps,
  slots, // require filterPanel, toolbar
  slotProps,
}) {
  const apiRef = useGridApiRef();
  const prevFilterItems = useRef();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const _fetchData = async (props = {}) => {
    setLoading(true);

    const filterModel = props?.filterModel || gridFilterModelSelector(apiRef);
    const limit = props?.numMoreRows ?? INITIAL_DATA_SIZE;
    const logicOperator = filterModel.logicOperator ?? "and";
    const offset = props?.numMoreRows ? data.length : 0;

    // TODO, when replaceRowData = true move scroll to the top of table
    const replaceRowData = props?.replaceRowData || false;
    const sort = props?.sortModel || gridSortModelSelector(apiRef);
    const health_system_id =
      "healthSystemId" in props
        ? props.healthSystemId
        : providerProps?.params?.healthSystemId;

    const filter = sanitizeFilterModel(filterModel);
    const hasQuickFilter = !!filter.find((item) => item.field === "any");
    prevFilterItems.current = filterModel;

    const params = {
      params: {
        ...(filter.length && {
          filter,
          logic_operator: logicOperator,
        }),
        health_system_id,
        limit,
        offset,
        ...(sort.length && { sort }),
        ...(hasQuickFilter
          ? {
              quick_filter_columns: gridVisibleColumnFieldsSelector(
                apiRef
              ).filter((field) => field !== "actions"),
            }
          : {}),
      },
    };
    fetchData(params)
      .then((resp) => {
        setLoading(false);
        const [respData, totalCount] = resp;

        if (totalCount !== rowCount) setRowCount(totalCount);
        setData(replaceRowData ? respData : data.concat(respData));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          const href = window.location.href.split("#")[0];
          growl(
            "Cohealo Session Expiry",
            "Your current session has expired, or you have signed out in another browser tab.<br/><br/><a href='" +
              href +
              "'>Sign In</a>",
            {
              enableClose: true,
              enableCloseAll: false,
              icon: "signout",
              status: "danger",
            }
          );
        } else {
          console.log(err);
        }
        setLoading(false);
      });
  };

  const handleFilterPanelClose = (params) => {
    if (params.openedPanelValue !== "filters") return;

    const filterModel = gridFilterModelSelector(apiRef);
    const prevFilter = sanitizeFilterModel(prevFilterItems.current);
    const hasNoFilterChanges = _.isEqual(
      prevFilter,
      sanitizeFilterModel(filterModel)
    );

    if (
      hasNoFilterChanges &&
      prevFilterItems.current.logicOperator === filterModel.logicOperator
    )
      return;

    _fetchData({ replaceRowData: true });
  };

  // Currently, case specific. Non-generic way of forwarding the
  // ServerTable's fetch method.
  const handleHealthSystemChange = slotProps.toolbar
    .handleHealthSystemChange && {
    handleHealthSystemChange: (change) => {
      slotProps.toolbar.handleHealthSystemChange(change);

      const setSearchParams = propsData?.setSearchParams;
      if (setSearchParams) setSearchParams("fm", null);

      apiRef.current.setFilterModel(DEFAULT_FILTER_MODEL);

      _fetchData({
        filterModel: DEFAULT_FILTER_MODEL,
        healthSystemId: change,
        replaceRowData: true,
      });
    },
  };

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize === -1) return;
    if (data.length >= MAX_ROW_LENGTH) return;
    if (rowCount === data.length) return;

    _fetchData({ numMoreRows: MORE_DATA_SIZE });
  };

  const handleSortModelChange = (sortModel) =>
    _fetchData({ sortModel, replaceRowData: true });

  const handleQuickFilterChanges = (filterModel) => {
    // Handler is only for Quick Filters
    // For table filter, we rely on onPreferencePanelClose
    const val = filterModel.quickFilterValues?.[0]?.value;
    const prevVal = prevFilterItems.current.quickFilterValues?.[0]?.value;

    let hasQuickFilterChanges = !_.isEqual(val, prevVal);
    if (hasQuickFilterChanges) {
      const isNotValidLen = (val?.length ?? 0) < MIN_CHARACTER_LIMIT;
      const wasNotValidLen = (prevVal?.length ?? 0) < MIN_CHARACTER_LIMIT;
      if (isNotValidLen && wasNotValidLen) hasQuickFilterChanges = false;
    }

    if (!hasQuickFilterChanges) return;

    _fetchData({ filterModel, replaceRowData: true });
  };

  useEffect(() => {
    // triggers on mount or when apiRef is mounted
    if (Boolean(apiRef.current)) _fetchData();
  }, [Boolean(apiRef.current)]);

  return (
    <RequestProvider {...providerProps}>
      <Table
        apiRef={apiRef}
        columns={columns}
        data={propsData}
        defaultHiddenColumns={defaultHiddenColumns}
        handleFilterModelChange={handleQuickFilterChanges}
        handleServerFilter={() => {}} // Noop for turning on server-sided filtering
        handleServerSort={handleSortModelChange}
        hideFooterPagination={true}
        {...(pinnedColumns && { pinnedColumns })}
        loading={loading}
        {...(onPinnedColumnsChange && { onPinnedColumnsChange })}
        onPreferencePanelClose={handleFilterPanelClose}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        rows={data}
        {...(rowCount && { rowCount })}
        slots={{
          filterPanelDeleteIcon: RemoveIcon,
          filterPanel: FilterPanel,
          loadingOverlay: LinearProgress,
          toolbar: ServerToolbar,
          ...slots,
        }}
        slotProps={{
          ...slotProps,
          toolbar: {
            minQuickFilterCharacterLength: MIN_CHARACTER_LIMIT,
            ...slotProps.toolbar,
            ...handleHealthSystemChange,
            handleFilterItemDelete: (filterModel) =>
              _fetchData({ filterModel, replaceRowData: true }),
            user: providerProps.params?.user,
          },
        }}
      />
    </RequestProvider>
  );
}
