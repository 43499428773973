import React from "react";

import Physicians from "./Physicians";

import { PHYSICIANS_PATH } from "../../../utils/capi";

import mockRequestContext from "../../../specSamples/mockRequestContext";
import { physicianSample } from "../../../specSamples/physician.samples";
import { takeSample } from "../../../specSamples/utils";

const { withRequestContext } = mockRequestContext({
  path: PHYSICIANS_PATH,
  sample: physicianSample,
});

export default {
  argTypes: { handleChange: { action: "Input value changed" } },
  component: Physicians,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <Story />
        </div>
      </div>
    ),
    withRequestContext,
  ],
  title: "Equipment Preference/Form/Physicians",
};

const Template = (args) => <Physicians {...args} />;

export const Default = Template.bind({});
Default.args = {
  requestUrl: PHYSICIANS_PATH,
};

export const IsDisabled = Template.bind({});
IsDisabled.args = {
  ...Default.args,
  isDisabled: true,
};

export const IsNotEditable = Template.bind({});
IsNotEditable.args = {
  ...Default.args,
  isEditable: false,
};

/** Examples of randomizing physician names
  name: "BROWN, DEBRA A",
  normalized_name: "brown, debra a [26253]",
  coded_name: "BROWN, DEBRA A [26253]",
*/
const toEquipmentPreferencesCriterionAttributes = (
  { id, attributes: { coded_name } },
  index
) => ({
  data: { coded_name },
  id: index,
  physicianId: id,
  procedurePattern: null,
});

export const Selected = Template.bind({});
Selected.args = {
  selected: takeSample(physicianSample, 5).map(
    toEquipmentPreferencesCriterionAttributes
  ),
};

export const SelectedIsDisabled = Template.bind({});
SelectedIsDisabled.args = {
  ...Selected.args,
  ...IsDisabled.args,
};

export const SelectedIsNotEditable = Template.bind({});
SelectedIsNotEditable.args = {
  ...Selected.args,
  ...IsNotEditable.args,
};
