export const inventoryItemSamples = [
  {
    id: "3021",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      homeFacility: {
        name: "Oscar's House",
        siteCode: "OH",
      },
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-18359",
        cohealoTag: "000231",
        serial: "08L054954",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3021",
    },
  },
  {
    id: "2517",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-27692",
        cohealoTag: "000373",
        serial: "11I030874",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/2517",
    },
  },
  {
    id: "2555",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-27713",
        cohealoTag: "000380",
        serial: "11I037004",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/2555",
    },
  },
  {
    id: "2499",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-27854",
        cohealoTag: "000077",
        serial: "11I036834",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/2499",
    },
  },
  {
    id: "2259",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-15640",
        cohealoTag: "000273",
        serial: "11K034054",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/2259",
    },
  },
  {
    id: "3424",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-24569",
        cohealoTag: "000248",
        serial: "12G015554",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3424",
    },
  },
  {
    id: "3201",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-27750",
        cohealoTag: "000192",
        serial: "11I043834",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3201",
    },
  },
  {
    id: "3203",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-42603",
        cohealoTag: "000194",
        serial: "11I036874",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3203",
    },
  },
  {
    id: "3705",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-18361",
        cohealoTag: "000473",
        serial: "08L059904",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3705",
    },
  },
  {
    id: "2271",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: null,
        cohealoTag: "130",
        serial: null,
      },
    },
    links: {
      self: "http://localhost:3000/inventories/2271",
    },
  },
  {
    id: "3279",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: null,
        cohealoTag: "000455",
        serial: "11K034114",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3279",
    },
  },
  {
    id: "3285",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: null,
        cohealoTag: "000266",
        serial: "10J001264",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3285",
    },
  },
  {
    id: "3256",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-27820",
        cohealoTag: "000508",
        serial: "11I037094",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3256",
    },
  },
  {
    id: "4179",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "500EIN51487",
        cohealoTag: "000829",
        serial: "12B032564",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/4179",
    },
  },
  {
    id: "10220",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-00851",
        cohealoTag: "002916",
        serial: "08G051244",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10220",
    },
  },
  {
    id: "10156",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-10864",
        cohealoTag: "002869",
        serial: "12A029084",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10156",
    },
  },
  {
    id: "10248",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-4666",
        cohealoTag: "002930",
        serial: "08K061794",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10248",
    },
  },
  {
    id: "11239",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "550-90966",
        cohealoTag: "004603",
        serial: "08C016154",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11239",
    },
  },
  {
    id: "10192",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-00850",
        cohealoTag: "002801",
        serial: "08G052304",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10192",
    },
  },
  {
    id: "10215",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-00853",
        cohealoTag: "002903",
        serial: "08G051234",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10215",
    },
  },
  {
    id: "10146",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-00976",
        cohealoTag: "2850",
        serial: "08L003534",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10146",
    },
  },
  {
    id: "10093",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-4705",
        cohealoTag: "002785",
        serial: "08K058674",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10093",
    },
  },
  {
    id: "3358",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-18357",
        cohealoTag: "003093",
        serial: "08L059774",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/3358",
    },
  },
  {
    id: "10434",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "150-101501",
        cohealoTag: "003143",
        serial: null,
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10434",
    },
  },
  {
    id: "10968",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "050-15934",
        cohealoTag: "004166",
        serial: "11G016704",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10968",
    },
  },
  {
    id: "10966",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "050-15929",
        cohealoTag: "004164",
        serial: "11G016594",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10966",
    },
  },
  {
    id: "11273",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "550-93470",
        cohealoTag: "4705",
        serial: "07e050954",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11273",
    },
  },
  {
    id: "11299",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "550-102995",
        cohealoTag: "004720",
        serial: null,
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11299",
    },
  },
  {
    id: "11159",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "100-87064",
        cohealoTag: "004513",
        serial: "12a007694",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11159",
    },
  },
  {
    id: "10178",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-4704",
        cohealoTag: "002878",
        serial: "08K062714",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10178",
    },
  },
  {
    id: "10820",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "100-85274",
        cohealoTag: "004208",
        serial: "11D047104",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10820",
    },
  },
  {
    id: "10139",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "650-00852",
        cohealoTag: "002847",
        serial: "08G052164",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10139",
    },
  },
  {
    id: "10752",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "100-87063",
        cohealoTag: "4192",
        serial: "12F040604",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/10752",
    },
  },
  {
    id: "11158",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "100-87061",
        cohealoTag: "004512",
        serial: "11f065684",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11158",
    },
  },
  {
    id: "11193",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "100-07065",
        cohealoTag: "4553",
        serial: null,
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11193",
    },
  },
  {
    id: "11309",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "550-90972",
        cohealoTag: "004592",
        serial: null,
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11309",
    },
  },
  {
    id: "11370",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "550-90984",
        cohealoTag: "004728",
        serial: "08D038454",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11370",
    },
  },
  {
    id: "11342",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "550-90978",
        cohealoTag: "004655",
        serial: "08C050814",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11342",
    },
  },
  {
    id: "11222",
    type: "inventoryItems",
    attributes: {
      collaborativelyPurchased: false,
      description: null,
      equipmentMasterId: 1103,
      type: "Inventory",
      status: "not-shareable",
      rentalCompanyId: null,
      yearManufactured: null,
      codes: {
        assetTag: "550-93476",
        cohealoTag: "004575",
        serial: "07h005364",
      },
    },
    links: {
      self: "http://localhost:3000/inventories/11222",
    },
  },
];
