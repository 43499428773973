export const procedureSamples = [
  {
    id: "13771",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.39",
      name: '" Wire Localized Excisional Biopsy Breast"',
      normalized_name: '" wire localized excisional biopsy breast" [88.39]',
      vendor_code: null,
      coded_name: '" Wire Localized Excisional Biopsy Breast" [88.39]',
    },
  },
  {
    id: "302119",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"Ex-Lap, hysterectomy BSO" LAPAROTOMY, EXPLORATORY',
      normalized_name:
        '"ex-lap, hysterectomy bso" laparotomy, exploratory [700628]',
      vendor_code: "700628",
      coded_name: '"Ex-Lap, hysterectomy BSO" LAPAROTOMY, EXPLORATORY [700628]',
    },
  },
  {
    id: "130935",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"Home Recovery" Hemicolectomy Right, Laparoscopic, Possible Open, Possible Ostomy (psb)',
      normalized_name:
        '"home recovery" hemicolectomy right, laparoscopic, possible open, possible ostomy (psb) [201819]',
      vendor_code: "201819",
      coded_name:
        '"Home Recovery" Hemicolectomy Right, Laparoscopic, Possible Open, Possible Ostomy (psb) [201819]',
    },
  },
  {
    id: "132105",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"Home Recovery" Robot Assisted Laparoscopic Sigmoidectomy, Take Down Colovesical Fistula, Flexible Sigmoidoscopy, Possible Cystoscopy And Temporary Bilateral Ureteral Catheters',
      normalized_name:
        '"home recovery" robot assisted laparoscopic sigmoidectomy, take down colovesical fistula, flexible sigmoidoscopy, possible cystoscopy and temporary bilateral ureteral catheters [346388]',
      vendor_code: "346388",
      coded_name:
        '"Home Recovery" Robot Assisted Laparoscopic Sigmoidectomy, Take Down Colovesical Fistula, Flexible Sigmoidoscopy, Possible Cystoscopy And Temporary Bilateral Ureteral Catheters [346388]',
    },
  },
  {
    id: "243913",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"LAPAROSCOPIC TRANSGASTRIC ERCP WITH GI"',
      normalized_name: '"laparoscopic transgastric ercp with gi" [139799]',
      vendor_code: "139799",
      coded_name: '"LAPAROSCOPIC TRANSGASTRIC ERCP WITH GI" [139799]',
    },
  },
  {
    id: "47081",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "04.49",
      name: '"Left L3, L4 Medial Branch And Bilateral L5 Dorsal Ramus Radiofrequency Ablation Under Fluoroscopy"',
      normalized_name:
        '"left l3, l4 medial branch and bilateral l5 dorsal ramus radiofrequency ablation under fluoroscopy" [04.49]',
      vendor_code: null,
      coded_name:
        '"Left L3, L4 Medial Branch And Bilateral L5 Dorsal Ramus Radiofrequency Ablation Under Fluoroscopy" [04.49]',
    },
  },
  {
    id: "171140",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"Possible Mv Repair And Cardiac Biopsy.',
      normalized_name: '"possible mv repair and cardiac biopsy. [10700006708]',
      vendor_code: "10700006708",
      coded_name: '"Possible Mv Repair And Cardiac Biopsy. [10700006708]',
    },
  },
  {
    id: "227299",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"right endoscopic sinus surgery with biopsy, possible septoplasty and bilateral inferior turbinate reduction "',
      normalized_name:
        '"right endoscopic sinus surgery with biopsy, possible septoplasty and bilateral inferior turbinate reduction " [210905]',
      vendor_code: "210905",
      coded_name:
        '"right endoscopic sinus surgery with biopsy, possible septoplasty and bilateral inferior turbinate reduction " [210905]',
    },
  },
  {
    id: "264241",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"right parietal CRANIOTOMY FOR TUMOR"',
      normalized_name: '"right parietal craniotomy for tumor" [10700000951]',
      vendor_code: "10700000951",
      coded_name: '"right parietal CRANIOTOMY FOR TUMOR" [10700000951]',
    },
  },
  {
    id: "319232",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"SHOULDER RECONSTRUCTION"',
      normalized_name: '"shoulder reconstruction" [10700000281]',
      vendor_code: "10700000281",
      coded_name: '"SHOULDER RECONSTRUCTION" [10700000281]',
    },
  },
  {
    id: "315385",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"SHOULDER RECONSTRUCTION"',
      normalized_name: '"shoulder reconstruction" [10700000281]',
      vendor_code: "10700000281",
      coded_name: '"SHOULDER RECONSTRUCTION" [10700000281]',
    },
  },
  {
    id: "263021",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"Surgical Wound Debridement" REPAIR,WOUND,GENITALIA,COMPLEX',
      normalized_name:
        '"surgical wound debridement" repair,wound,genitalia,complex [703729]',
      vendor_code: "703729",
      coded_name:
        '"Surgical Wound Debridement" REPAIR,WOUND,GENITALIA,COMPLEX [703729]',
    },
  },
  {
    id: "202575",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '"Z" TOE TENDON LENGTHENING OF THE HALLUX AND POSSIBLE 2ND TOE.',
      normalized_name:
        '"z" toe tendon lengthening of the hallux and possible 2nd toe. [168339]',
      vendor_code: "168339",
      coded_name:
        '"Z" TOE TENDON LENGTHENING OF THE HALLUX AND POSSIBLE 2ND TOE. [168339]',
    },
  },
  {
    id: "217876",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#1 Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#1 insertion of intracavitary high dose rate radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#1 Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "213810",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#1 of 4 Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#1 of 4 insertion of intracavitary high dose rate radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#1 of 4 Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "207392",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#1 of 5 -Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#1 of 5 -insertion of intracavitary high dose radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#1 of 5 -Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "245680",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#1 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading",
      normalized_name:
        "#1 of 5 insertion of gynecological interstitial hight dose rate radiation needles for afterloading [703021]",
      vendor_code: "703021",
      coded_name:
        "#1 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading [703021]",
    },
  },
  {
    id: "207621",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#1 of 5:-Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#1 of 5:-insertion of intracavitary high dose radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#1 of 5:-Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "154198",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#17 And 32",
      normalized_name: "#17 and 32 [10700003252]",
      vendor_code: "10700003252",
      coded_name: "#17 And 32 [10700003252]",
    },
  },
  {
    id: "218490",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#1of 4: Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#1of 4: insertion of intracavitary high dose rate radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#1of 4: Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "218520",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#2 of 4 Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#2 of 4 insertion of intracavitary high dose rate radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#2 of 4 Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "214551",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#2 of 4: Insertion of Intracavitary High Dose Rate Radiation For Afterloading w/Tandem & Ring",
      normalized_name:
        "#2 of 4: insertion of intracavitary high dose rate radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#2 of 4: Insertion of Intracavitary High Dose Rate Radiation For Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "246851",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#2 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading",
      normalized_name:
        "#2 of 5 insertion of gynecological interstitial hight dose rate radiation needles for afterloading [703021]",
      vendor_code: "703021",
      coded_name:
        "#2 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading [703021]",
    },
  },
  {
    id: "207642",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#2 of 5: Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#2 of 5: insertion of intracavitary high dose radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#2 of 5: Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "123992",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#22 Root Tip #23, 24, 25, 27",
      normalized_name: "#22 root tip #23, 24, 25, 27 [10700002109]",
      vendor_code: "10700002109",
      coded_name: "#22 Root Tip #23, 24, 25, 27 [10700002109]",
    },
  },
  {
    id: "218625",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#3 of 4: Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#3 of 4: insertion of intracavitary high dose rate radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#3 of 4: Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "246862",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#3 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading",
      normalized_name:
        "#3 of 5 insertion of gynecological interstitial hight dose rate radiation needles for afterloading [703021]",
      vendor_code: "703021",
      coded_name:
        "#3 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading [703021]",
    },
  },
  {
    id: "209103",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#3 of 5 Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & ring",
      normalized_name:
        "#3 of 5 insertion of intracavitary high dose radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#3 of 5 Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & ring [700768]",
    },
  },
  {
    id: "218755",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#4 of 4: Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#4 of 4: insertion of intracavitary high dose rate radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#4 of 4: Insertion of Intracavitary High Dose Rate Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "246863",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#4 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading",
      normalized_name:
        "#4 of 5 insertion of gynecological interstitial hight dose rate radiation needles for afterloading [703021]",
      vendor_code: "703021",
      coded_name:
        "#4 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading [703021]",
    },
  },
  {
    id: "209104",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#4 of 5 Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#4 of 5 insertion of intracavitary high dose radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#4 of 5 Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "246865",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#5 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading",
      normalized_name:
        "#5 of 5 insertion of gynecological interstitial hight dose rate radiation needles for afterloading [703021]",
      vendor_code: "703021",
      coded_name:
        "#5 of 5 Insertion of Gynecological Interstitial Hight Dose Rate Radiation Needles for Afterloading [703021]",
    },
  },
  {
    id: "211139",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "#5 of 5 Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring",
      normalized_name:
        "#5 of 5 insertion of intracavitary high dose radiation for afterloading w/tandem & ring [700768]",
      vendor_code: "700768",
      coded_name:
        "#5 of 5 Insertion of Intracavitary High Dose Radiation for Afterloading w/Tandem & Ring [700768]",
    },
  },
  {
    id: "186446",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "&",
      normalized_name: "& [37211]",
      vendor_code: "37211",
      coded_name: "& [37211]",
    },
  },
  {
    id: "109578",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "& Clot Evacuation",
      normalized_name: "& clot evacuation [10700000908]",
      vendor_code: "10700000908",
      coded_name: "& Clot Evacuation [10700000908]",
    },
  },
  {
    id: "249702",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "& CYSTOLITHOLAPAXY WITH HOLMIUM LASER,",
      normalized_name: "& cystolitholapaxy with holmium laser, [700932]",
      vendor_code: "700932",
      coded_name: "& CYSTOLITHOLAPAXY WITH HOLMIUM LASER, [700932]",
    },
  },
  {
    id: "267876",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "'",
      normalized_name: "' [opcatiol]",
      vendor_code: "OPCATIOL",
      coded_name: "' [OPCATIOL]",
    },
  },
  {
    id: "177960",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "'Left Levator Resection 105 Minutes, General Anesthesia",
      normalized_name:
        "'left levator resection 105 minutes, general anesthesia [151177]",
      vendor_code: "151177",
      coded_name:
        "'Left Levator Resection 105 Minutes, General Anesthesia [151177]",
    },
  },
  {
    id: "315149",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "( DECAYED NON - RESTORABLE TEETH ) SURGICAL TOOTH EXTRACTION, MULTIPLE # 1 # 2 # 15 # 16 # 18 # 29 # 31",
      normalized_name:
        "( decayed non - restorable teeth ) surgical tooth extraction, multiple # 1 # 2 # 15 # 16 # 18 # 29 # 31 [106408]",
      vendor_code: "106408",
      coded_name:
        "( DECAYED NON - RESTORABLE TEETH ) SURGICAL TOOTH EXTRACTION, MULTIPLE # 1 # 2 # 15 # 16 # 18 # 29 # 31 [106408]",
    },
  },
  {
    id: "132951",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "( Mini) Median Sternotomy",
      normalized_name: "( mini) median sternotomy [10700003140]",
      vendor_code: "10700003140",
      coded_name: "( Mini) Median Sternotomy [10700003140]",
    },
  },
  {
    id: "145551",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: '( Mini) Replacement Aortic Valve ( Tissue), "Edwards Inspirus Valve"',
      normalized_name:
        '( mini) replacement aortic valve ( tissue), "edwards inspirus valve" [10700004668]',
      vendor_code: "10700004668",
      coded_name:
        '( Mini) Replacement Aortic Valve ( Tissue), "Edwards Inspirus Valve" [10700004668]',
    },
  },
  {
    id: "141958",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "( Mini) Replacement Aortic Valve (Inspiris Resilia)",
      normalized_name:
        "( mini) replacement aortic valve (inspiris resilia) [10700004668]",
      vendor_code: "10700004668",
      coded_name:
        "( Mini) Replacement Aortic Valve (Inspiris Resilia) [10700004668]",
    },
  },
  {
    id: "128046",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "( Partial Sternotomy)",
      normalized_name: "( partial sternotomy) [10700003140]",
      vendor_code: "10700003140",
      coded_name: "( Partial Sternotomy) [10700003140]",
    },
  },
  {
    id: "168986",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "( R) Spermatocelectomy",
      normalized_name: "( r) spermatocelectomy [10700005231]",
      vendor_code: "10700005231",
      coded_name: "( R) Spermatocelectomy [10700005231]",
    },
  },
  {
    id: "15013",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "83.39",
      name: "(1) Excision Of Left Axillary Sinus Tract (2) Z-Plasty Left Back, Position Lateral",
      normalized_name:
        "(1) excision of left axillary sinus tract (2) z-plasty left back, position lateral [83.39]",
      vendor_code: null,
      coded_name:
        "(1) Excision Of Left Axillary Sinus Tract (2) Z-Plasty Left Back, Position Lateral [83.39]",
    },
  },
  {
    id: "15062",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "86.63",
      name: "(1) Left Ear Division Of Flap (2) Full Thickness Skin Graft From Left Lower Abdomen To Left Post-Auricular Skin",
      normalized_name:
        "(1) left ear division of flap (2) full thickness skin graft from left lower abdomen to left post-auricular skin [86.63]",
      vendor_code: null,
      coded_name:
        "(1) Left Ear Division Of Flap (2) Full Thickness Skin Graft From Left Lower Abdomen To Left Post-Auricular Skin [86.63]",
    },
  },
  {
    id: "75774",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(1) Left Index Finger, Middle Finger, Ring Finger, Little Finger Intrisic Release (2) Possible Joint Contracture Release Left Index Finger, Middle Finger, Ring Finger, Little Finger Intrisic Release",
      normalized_name:
        "(1) left index finger, middle finger, ring finger, little finger intrisic release (2) possible joint contracture release left index finger, middle finger, ring finger, little finger intrisic release [182475]",
      vendor_code: "182475",
      coded_name:
        "(1) Left Index Finger, Middle Finger, Ring Finger, Little Finger Intrisic Release (2) Possible Joint Contracture Release Left Index Finger, Middle Finger, Ring Finger, Little Finger Intrisic Release [182475]",
    },
  },
  {
    id: "180108",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(1) Right Middle Finger And Right Hand Radical Micro Sympathectomy (2) Botox Injection Bilateral Hands 100 Units Total",
      normalized_name:
        "(1) right middle finger and right hand radical micro sympathectomy (2) botox injection bilateral hands 100 units total [182467]",
      vendor_code: "182467",
      coded_name:
        "(1) Right Middle Finger And Right Hand Radical Micro Sympathectomy (2) Botox Injection Bilateral Hands 100 Units Total [182467]",
    },
  },
  {
    id: "344114",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(27814)OPEN REDUCTION INTERNAL FIXATION FRACTURE ANKLE",
      normalized_name:
        "(27814)open reduction internal fixation fracture ankle [10700003273]",
      vendor_code: "10700003273",
      coded_name:
        "(27814)OPEN REDUCTION INTERNAL FIXATION FRACTURE ANKLE [10700003273]",
    },
  },
  {
    id: "149095",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(27822) Left Open Reduction Internal Fixation Fracture Ankle",
      normalized_name:
        "(27822) left open reduction internal fixation fracture ankle [10700003273]",
      vendor_code: "10700003273",
      coded_name:
        "(27822) Left Open Reduction Internal Fixation Fracture Ankle [10700003273]",
    },
  },
  {
    id: "343984",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(28322)OPEN REDUCTION INTERNAL FIXATION FRACTURE/NONUNION METATARSAL SECOND/THIRD/FOURTH",
      normalized_name:
        "(28322)open reduction internal fixation fracture/nonunion metatarsal second/third/fourth [10700003350]",
      vendor_code: "10700003350",
      coded_name:
        "(28322)OPEN REDUCTION INTERNAL FIXATION FRACTURE/NONUNION METATARSAL SECOND/THIRD/FOURTH [10700003350]",
    },
  },
  {
    id: "13662",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "76.19",
      name: "(29804) - Right And Left Temporomandibular Joint Arthroscopies",
      normalized_name:
        "(29804) - right and left temporomandibular joint arthroscopies [76.19]",
      vendor_code: null,
      coded_name:
        "(29804) - Right And Left Temporomandibular Joint Arthroscopies [76.19]",
    },
  },
  {
    id: "75775",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(3) Evaluation Of Left Small Finger Fracture Site Under Fluoroscopy (4) Possible Removal Of Addition Of Fixation Left Small Finger Metacarpal Based On Surgical Exam (5) Possible Cadaver Bone Graft Left Small Finger Based On Surgical Exam (psb)",
      normalized_name:
        "(3) evaluation of left small finger fracture site under fluoroscopy (4) possible removal of addition of fixation left small finger metacarpal based on surgical exam (5) possible cadaver bone graft left small finger based on surgical exam (psb) [129225]",
      vendor_code: "129225",
      coded_name:
        "(3) Evaluation Of Left Small Finger Fracture Site Under Fluoroscopy (4) Possible Removal Of Addition Of Fixation Left Small Finger Metacarpal Based On Surgical Exam (5) Possible Cadaver Bone Graft Left Small Finger Based On Surgical Exam (psb) [129225]",
    },
  },
  {
    id: "118759",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(3rd Look) Irrigation And Debridement Buttock",
      normalized_name:
        "(3rd look) irrigation and debridement buttock [10700002652]",
      vendor_code: "10700002652",
      coded_name: "(3rd Look) Irrigation And Debridement Buttock [10700002652]",
    },
  },
  {
    id: "14579",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "23.09",
      name: "(41874) - Four Quadrant Alveoloplasties",
      normalized_name: "(41874) - four quadrant alveoloplasties [23.09]",
      vendor_code: null,
      coded_name: "(41874) - Four Quadrant Alveoloplasties [23.09]",
    },
  },
  {
    id: "13595",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "23.09",
      name: "(41899) - Extraction Of Full Bony Teeth 17, 18, 31 And 32",
      normalized_name:
        "(41899) - extraction of full bony teeth 17, 18, 31 and 32 [23.09]",
      vendor_code: null,
      coded_name:
        "(41899) - Extraction Of Full Bony Teeth 17, 18, 31 And 32 [23.09]",
    },
  },
  {
    id: "145760",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(?) Completion Angiogram Leg",
      normalized_name: "(?) completion angiogram leg [10700000068]",
      vendor_code: "10700000068",
      coded_name: "(?) Completion Angiogram Leg [10700000068]",
    },
  },
  {
    id: "17197",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "(A) Cervical Facet Joint (Zygapophyseal) Injection",
      normalized_name:
        "(a) cervical facet joint (zygapophyseal) injection [03.92]",
      vendor_code: null,
      coded_name: "(A) Cervical Facet Joint (Zygapophyseal) Injection [03.92]",
    },
  },
  {
    id: "17200",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "(A) Transforaminal Epidural Steroid Injection",
      normalized_name: "(a) transforaminal epidural steroid injection [03.92]",
      vendor_code: null,
      coded_name: "(A) Transforaminal Epidural Steroid Injection [03.92]",
    },
  },
  {
    id: "131577",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(All 5 Mm Storz Instruments)",
      normalized_name: "(all 5 mm storz instruments) [192274]",
      vendor_code: "192274",
      coded_name: "(All 5 Mm Storz Instruments) [192274]",
    },
  },
  {
    id: "17196",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "(Art) Transforaminal Epidural Steroid Injection",
      normalized_name:
        "(art) transforaminal epidural steroid injection [03.92]",
      vendor_code: null,
      coded_name: "(Art) Transforaminal Epidural Steroid Injection [03.92]",
    },
  },
  {
    id: "213378",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(B) PERC NEPH TUBE PLACEMENT RADIOLOGY ANESTHESIA PROCEDURE",
      normalized_name:
        "(b) perc neph tube placement radiology anesthesia procedure [148608]",
      vendor_code: "148608",
      coded_name:
        "(B) PERC NEPH TUBE PLACEMENT RADIOLOGY ANESTHESIA PROCEDURE [148608]",
    },
  },
  {
    id: "155872",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Bedside) Exploratory Laparotomy",
      normalized_name: "(bedside) exploratory laparotomy [10700002088]",
      vendor_code: "10700002088",
      coded_name: "(Bedside) Exploratory Laparotomy [10700002088]",
    },
  },
  {
    id: "277496",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Bilateral L4-5 laminectomy for stenosis)",
      normalized_name: "(bilateral l4-5 laminectomy for stenosis) [703550]",
      vendor_code: "703550",
      coded_name: "(Bilateral L4-5 laminectomy for stenosis) [703550]",
    },
  },
  {
    id: "39622",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "85.43",
      name: "(Bilateral Skin Sparing Mastectomy, Right Alnd)",
      normalized_name:
        "(bilateral skin sparing mastectomy, right alnd) [85.43]",
      vendor_code: null,
      coded_name: "(Bilateral Skin Sparing Mastectomy, Right Alnd) [85.43]",
    },
  },
  {
    id: "276458",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(BSO) BILATERAL SALPINGO-OOPHORECTOMY, LAPAROSCOPIC",
      normalized_name:
        "(bso) bilateral salpingo-oophorectomy, laparoscopic [701349]",
      vendor_code: "701349",
      coded_name:
        "(BSO) BILATERAL SALPINGO-OOPHORECTOMY, LAPAROSCOPIC [701349]",
    },
  },
  {
    id: "104607",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Cabg X1 Lima To Lad)",
      normalized_name: "(cabg x1 lima to lad) [10700000828]",
      vendor_code: "10700000828",
      coded_name: "(Cabg X1 Lima To Lad) [10700000828]",
    },
  },
  {
    id: "291877",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(CABG) CORONARY ARTERY BYPASS GRAFT X4, REPEAT, WITH ENDOSCOPIC VASCULAR GRAFT PROCUREMENT",
      normalized_name:
        "(cabg) coronary artery bypass graft x4, repeat, with endoscopic vascular graft procurement [700351]",
      vendor_code: "700351",
      coded_name:
        "(CABG) CORONARY ARTERY BYPASS GRAFT X4, REPEAT, WITH ENDOSCOPIC VASCULAR GRAFT PROCUREMENT [700351]",
    },
  },
  {
    id: "198816",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(CLOSURE) AMPUTATION BELOW KNEE",
      normalized_name: "(closure) amputation below knee [10700000043]",
      vendor_code: "10700000043",
      coded_name: "(CLOSURE) AMPUTATION BELOW KNEE [10700000043]",
    },
  },
  {
    id: "140922",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Completed At 11:49am)",
      normalized_name: "(completed at 11:49am) [10700003606]",
      vendor_code: "10700003606",
      coded_name: "(Completed At 11:49am) [10700003606]",
    },
  },
  {
    id: "203925",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(CORONARY ARTERY BYPASS GRAFT) CABG X 2-3, Atriclip, pulmonary vein isolation--Cardiac anesthesia requested Swan desired",
      normalized_name:
        "(coronary artery bypass graft) cabg x 2-3, atriclip, pulmonary vein isolation--cardiac anesthesia requested swan desired [700339]",
      vendor_code: "700339",
      coded_name:
        "(CORONARY ARTERY BYPASS GRAFT) CABG X 2-3, Atriclip, pulmonary vein isolation--Cardiac anesthesia requested Swan desired [700339]",
    },
  },
  {
    id: "76069",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Cranioplasty) Flap Enclosure",
      normalized_name: "(cranioplasty) flap enclosure [10700000902]",
      vendor_code: "10700000902",
      coded_name: "(Cranioplasty) Flap Enclosure [10700000902]",
    },
  },
  {
    id: "253449",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(cystolitholapaxy)",
      normalized_name: "(cystolitholapaxy) [10700001014]",
      vendor_code: "10700001014",
      coded_name: "(cystolitholapaxy) [10700001014]",
    },
  },
  {
    id: "247219",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(DBE) COLONOSCOPY W BIOPSY, DBE with lower approach",
      normalized_name:
        "(dbe) colonoscopy w biopsy, dbe with lower approach [106504]",
      vendor_code: "106504",
      coded_name:
        "(DBE) COLONOSCOPY W BIOPSY, DBE with lower approach [106504]",
    },
  },
  {
    id: "117579",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(DCR) DACRYOCYSTORHINOSTOMY",
      normalized_name: "(dcr) dacryocystorhinostomy [entdacrycyrhost]",
      vendor_code: "ENTDACRYCYRHOST",
      coded_name: "(DCR) DACRYOCYSTORHINOSTOMY [ENTDACRYCYRHOST]",
    },
  },
  {
    id: "347178",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP)",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) [ENDO RCMC 004]",
    },
  },
  {
    id: "327702",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) A208 No C-arm, TB PRECAUTIONS / Anesthesia pending",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) a208 no c-arm, tb precautions / anesthesia pending [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) A208 No C-arm, TB PRECAUTIONS / Anesthesia pending [ENDO RCMC 004]",
    },
  },
  {
    id: "325234",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) and EGD / No C-arm, No TB precautions, No Covid",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) and egd / no c-arm, no tb precautions, no covid [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) and EGD / No C-arm, No TB precautions, No Covid [ENDO RCMC 004]",
    },
  },
  {
    id: "326422",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) Bronch first. En route to OR 4% lidocoine nebulizer with Albuterol. No c-arm, and no tb.",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) bronch first. en route to or 4% lidocoine nebulizer with albuterol. no c-arm, and no tb. [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) Bronch first. En route to OR 4% lidocoine nebulizer with Albuterol. No c-arm, and no tb. [ENDO RCMC 004]",
    },
  },
  {
    id: "328455",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) C126 no c-arm needed covid precautions no TB precautions",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) c126 no c-arm needed covid precautions no tb precautions [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) C126 no c-arm needed covid precautions no TB precautions [ENDO RCMC 004]",
    },
  },
  {
    id: "322903",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) C236. No C-arm needed, No TB, No COVID",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) c236. no c-arm needed, no tb, no covid [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) C236. No C-arm needed, No TB, No COVID [ENDO RCMC 004]",
    },
  },
  {
    id: "324302",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) ENROUTE TO OR 4% LIDOCAINE NEBULIZER WITH ALBUTEROL LIDOCAINE ATOMIZER / No C-arm, No TB precautions, No Covid / Ok per Val",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) enroute to or 4% lidocaine nebulizer with albuterol lidocaine atomizer / no c-arm, no tb precautions, no covid / ok per val [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) ENROUTE TO OR 4% LIDOCAINE NEBULIZER WITH ALBUTEROL LIDOCAINE ATOMIZER / No C-arm, No TB precautions, No Covid / Ok per Val [ENDO RCMC 004]",
    },
  },
  {
    id: "324429",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) Karush EBUS/Bronch per Kelly, No TB, No Covid, No C-ARM. FCFS ok per Michelle.",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) karush ebus/bronch per kelly, no tb, no covid, no c-arm. fcfs ok per michelle. [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) Karush EBUS/Bronch per Kelly, No TB, No Covid, No C-ARM. FCFS ok per Michelle. [ENDO RCMC 004]",
    },
  },
  {
    id: "324267",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB precautions, No Covid / Anesthesia ok per Bonnie",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb precautions, no covid / anesthesia ok per bonnie [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB precautions, No Covid / Anesthesia ok per Bonnie [ENDO RCMC 004]",
    },
  },
  {
    id: "323705",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB precautions, No Covid / Using own anesthesia",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb precautions, no covid / using own anesthesia [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB precautions, No Covid / Using own anesthesia [ENDO RCMC 004]",
    },
  },
  {
    id: "325232",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB precautions, No Covid",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb precautions, no covid [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB precautions, No Covid [ENDO RCMC 004]",
    },
  },
  {
    id: "323129",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB, No Covid USING DR'S KARUSH'S ANESTH. OK PER VAL",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb, no covid using dr's karush's anesth. ok per val [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB, No Covid USING DR'S KARUSH'S ANESTH. OK PER VAL [ENDO RCMC 004]",
    },
  },
  {
    id: "321910",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid / Anesthesia ok per Kierste",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb/covid / anesthesia ok per kierste [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid / Anesthesia ok per Kierste [ENDO RCMC 004]",
    },
  },
  {
    id: "327750",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid / Anesthesia ok per Kierste.",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb/covid / anesthesia ok per kierste. [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid / Anesthesia ok per Kierste. [ENDO RCMC 004]",
    },
  },
  {
    id: "327071",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid, Anesthesia ok per Kierste",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb/covid, anesthesia ok per kierste [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid, Anesthesia ok per Kierste [ENDO RCMC 004]",
    },
  },
  {
    id: "327825",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid. Anesthesia ok per Kierste/Sara.",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no c-arm, no tb/covid. anesthesia ok per kierste/sara. [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No C-arm, No TB/Covid. Anesthesia ok per Kierste/Sara. [ENDO RCMC 004]",
    },
  },
  {
    id: "327104",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NO CARM NO TB/COVID GENERAL ANESTHESIA OK PER SARAH & NANCY",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no carm no tb/covid general anesthesia ok per sarah & nancy [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NO CARM NO TB/COVID GENERAL ANESTHESIA OK PER SARAH & NANCY [ENDO RCMC 004]",
    },
  },
  {
    id: "324023",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NO CARM, NO TB, NO COVID",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no carm, no tb, no covid [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NO CARM, NO TB, NO COVID [ENDO RCMC 004]",
    },
  },
  {
    id: "326574",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No tb and no c-arm. En route to OR 4% lidocaine nebulizer with albuterol, lidocaine atomizer.",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no tb and no c-arm. en route to or 4% lidocaine nebulizer with albuterol, lidocaine atomizer. [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) No tb and no c-arm. En route to OR 4% lidocaine nebulizer with albuterol, lidocaine atomizer. [ENDO RCMC 004]",
    },
  },
  {
    id: "323442",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NO TB, NO CARM, NO TB",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) no tb, no carm, no tb [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NO TB, NO CARM, NO TB [ENDO RCMC 004]",
    },
  },
  {
    id: "325724",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NOT CARM NO TB OK PER STACI",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) not carm no tb ok per staci [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) NOT CARM NO TB OK PER STACI [ENDO RCMC 004]",
    },
  },
  {
    id: "325235",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) Using own anesthesia. No C-arm, No TB, No Covid.",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) using own anesthesia. no c-arm, no tb, no covid. [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) Using own anesthesia. No C-arm, No TB, No Covid. [ENDO RCMC 004]",
    },
  },
  {
    id: "325764",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) with C-ARM. C-ARM ok per Cori Standard bronchoscopy prior enroute to OR, 4% lidocaine nebulizer with albuterol, lidocaine atomizer",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound (ip) with c-arm. c-arm ok per cori standard bronchoscopy prior enroute to or, 4% lidocaine nebulizer with albuterol, lidocaine atomizer [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND (IP) with C-ARM. C-ARM ok per Cori Standard bronchoscopy prior enroute to OR, 4% lidocaine nebulizer with albuterol, lidocaine atomizer [ENDO RCMC 004]",
    },
  },
  {
    id: "324881",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND / No C-arm, No TB, No Covid / Using own anesthesia",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound / no c-arm, no tb, no covid / using own anesthesia [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND / No C-arm, No TB, No Covid / Using own anesthesia [ENDO RCMC 004]",
    },
  },
  {
    id: "327150",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND W/ POSSIBLE BRONCHIAL BIOPSY (IP) En Route to OR 4% Lidocaine Nebulizer w/ Albuterol Lidocaine Atomizer / No C-arm, No TB/Covid / Ok per Val/Kierste",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound w/ possible bronchial biopsy (ip) en route to or 4% lidocaine nebulizer w/ albuterol lidocaine atomizer / no c-arm, no tb/covid / ok per val/kierste [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND W/ POSSIBLE BRONCHIAL BIOPSY (IP) En Route to OR 4% Lidocaine Nebulizer w/ Albuterol Lidocaine Atomizer / No C-arm, No TB/Covid / Ok per Val/Kierste [ENDO RCMC 004]",
    },
  },
  {
    id: "324082",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND with bronchial biopsy with lavage and bronchial brushing (IP). No c-arm, No TB precautions, No Covid. FCFS anes per Cori/Val. 4% lidocaine nebulizer with albuterol, lidocaine atomizer, enroute to OR.",
      normalized_name:
        "(ebus) bronchoscopy with endobronchial ultrasound with bronchial biopsy with lavage and bronchial brushing (ip). no c-arm, no tb precautions, no covid. fcfs anes per cori/val. 4% lidocaine nebulizer with albuterol, lidocaine atomizer, enroute to or. [endo rcmc 004]",
      vendor_code: "ENDO RCMC 004",
      coded_name:
        "(EBUS) BRONCHOSCOPY WITH ENDOBRONCHIAL ULTRASOUND with bronchial biopsy with lavage and bronchial brushing (IP). No c-arm, No TB precautions, No Covid. FCFS anes per Cori/Val. 4% lidocaine nebulizer with albuterol, lidocaine atomizer, enroute to OR. [ENDO RCMC 004]",
    },
  },
  {
    id: "315577",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(ERAS) HEPATECTOMY PARTIAL, and all indicated procedures",
      normalized_name:
        "(eras) hepatectomy partial, and all indicated procedures [106526]",
      vendor_code: "106526",
      coded_name:
        "(ERAS) HEPATECTOMY PARTIAL, and all indicated procedures [106526]",
    },
  },
  {
    id: "273582",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL) LEFT ANATOMICAL TOTAL SHOULDER ARTHROPLASTY & ROTATOR CUFF REPAIR",
      normalized_name:
        "(exparel) left anatomical total shoulder arthroplasty & rotator cuff repair [10700000200]",
      vendor_code: "10700000200",
      coded_name:
        "(EXPAREL) LEFT ANATOMICAL TOTAL SHOULDER ARTHROPLASTY & ROTATOR CUFF REPAIR [10700000200]",
    },
  },
  {
    id: "273583",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL) LEFT BICEPS TENODESIS SHOULDER",
      normalized_name: "(exparel) left biceps tenodesis shoulder [10700005354]",
      vendor_code: "10700005354",
      coded_name: "(EXPAREL) LEFT BICEPS TENODESIS SHOULDER [10700005354]",
    },
  },
  {
    id: "304582",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL) LEFT REVERSE TOTAL SHOULDER ARTRHOPLASTY",
      normalized_name:
        "(exparel) left reverse total shoulder artrhoplasty [10700000200]",
      vendor_code: "10700000200",
      coded_name:
        "(EXPAREL) LEFT REVERSE TOTAL SHOULDER ARTRHOPLASTY [10700000200]",
    },
  },
  {
    id: "300588",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL) OPEN REDUCTION INTERNAL FIXATION RIGHT CLAVICLE FRACTURE WITH CORACOCLAVICULAR LIGAMENT RECONSTRUCTION",
      normalized_name:
        "(exparel) open reduction internal fixation right clavicle fracture with coracoclavicular ligament reconstruction [10700003281]",
      vendor_code: "10700003281",
      coded_name:
        "(EXPAREL) OPEN REDUCTION INTERNAL FIXATION RIGHT CLAVICLE FRACTURE WITH CORACOCLAVICULAR LIGAMENT RECONSTRUCTION [10700003281]",
    },
  },
  {
    id: "304510",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL) RIGHT ANATOMIC TOTAL SHOULDER ARTHROPLASTY",
      normalized_name:
        "(exparel) right anatomic total shoulder arthroplasty [10700000200]",
      vendor_code: "10700000200",
      coded_name:
        "(EXPAREL) RIGHT ANATOMIC TOTAL SHOULDER ARTHROPLASTY [10700000200]",
    },
  },
  {
    id: "294466",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL)REVISION LEFT ANATOMIC SHOULDER ARTHROPLASTY",
      normalized_name:
        "(exparel)revision left anatomic shoulder arthroplasty [10700004976]",
      vendor_code: "10700004976",
      coded_name:
        "(EXPAREL)REVISION LEFT ANATOMIC SHOULDER ARTHROPLASTY [10700004976]",
    },
  },
  {
    id: "276659",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL)RIGHT ANATOMIC TOTAL SHOULDER ARTHROPLASTY",
      normalized_name:
        "(exparel)right anatomic total shoulder arthroplasty [10700000200]",
      vendor_code: "10700000200",
      coded_name:
        "(EXPAREL)RIGHT ANATOMIC TOTAL SHOULDER ARTHROPLASTY [10700000200]",
    },
  },
  {
    id: "276660",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(EXPAREL)TENODESIS BICEPS TENDON RIGHT SHOULDER",
      normalized_name:
        "(exparel)tenodesis biceps tendon right shoulder [10700005354]",
      vendor_code: "10700005354",
      coded_name:
        "(EXPAREL)TENODESIS BICEPS TENDON RIGHT SHOULDER [10700005354]",
    },
  },
  {
    id: "108749",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Falcon) Liver Bx 16g",
      normalized_name: "(falcon) liver bx 16g [137260]",
      vendor_code: "137260",
      coded_name: "(Falcon) Liver Bx 16g [137260]",
    },
  },
  {
    id: "335106",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(FEMALE) / MASTOPEXY",
      normalized_name: "(female) / mastopexy [plas 036]",
      vendor_code: "PLAS 036",
      coded_name: "(FEMALE) / MASTOPEXY [PLAS 036]",
    },
  },
  {
    id: "28541",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(General Anesthesia) Embolization, Transcatheter Therapy",
      normalized_name:
        "(general anesthesia) embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "(General Anesthesia) Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "66411",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(General Anesthesia) Spine Biopsy",
      normalized_name: "(general anesthesia) spine biopsy [148608]",
      vendor_code: "148608",
      coded_name: "(General Anesthesia) Spine Biopsy [148608]",
    },
  },
  {
    id: "116602",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Guillotine) Amputation Below Knee (psb)",
      normalized_name: "(guillotine) amputation below knee (psb) [10700000043]",
      vendor_code: "10700000043",
      coded_name: "(Guillotine) Amputation Below Knee (psb) [10700000043]",
    },
  },
  {
    id: "156415",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Holistic Localizer)Breast Lumpectomy W Needle Localization",
      normalized_name:
        "(holistic localizer)breast lumpectomy w needle localization [346376]",
      vendor_code: "346376",
      coded_name:
        "(Holistic Localizer)Breast Lumpectomy W Needle Localization [346376]",
    },
  },
  {
    id: "33588",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "81.80",
      name: "(Home Recovery) Right Reverse Total Shoulder Replacement (Zimmer)",
      normalized_name:
        "(home recovery) right reverse total shoulder replacement (zimmer) [81.80]",
      vendor_code: null,
      coded_name:
        "(Home Recovery) Right Reverse Total Shoulder Replacement (Zimmer) [81.80]",
    },
  },
  {
    id: "146599",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Index Finger And Middle Finger Mp Joint)",
      normalized_name: "(index finger and middle finger mp joint) [106740]",
      vendor_code: "106740",
      coded_name: "(Index Finger And Middle Finger Mp Joint) [106740]",
    },
  },
  {
    id: "255950",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(INDEX FINGER) FINGER I&D and HWR",
      normalized_name: "(index finger) finger i&d and hwr [145064]",
      vendor_code: "145064",
      coded_name: "(INDEX FINGER) FINGER I&D and HWR [145064]",
    },
  },
  {
    id: "294941",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(IR CASE W/ANES) VENOGRAM, LOWER EXTREMITY, WITH INTERVENTIONAL PROCEDURE.",
      normalized_name:
        "(ir case w/anes) venogram, lower extremity, with interventional procedure. [702380]",
      vendor_code: "702380",
      coded_name:
        "(IR CASE W/ANES) VENOGRAM, LOWER EXTREMITY, WITH INTERVENTIONAL PROCEDURE. [702380]",
    },
  },
  {
    id: "186780",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(L) 1st Toe Washout And Closure",
      normalized_name: "(l) 1st toe washout and closure [10700002652]",
      vendor_code: "10700002652",
      coded_name: "(L) 1st Toe Washout And Closure [10700002652]",
    },
  },
  {
    id: "9715",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(L) Back Mass Bx With Dr. Rosenthal",
      normalized_name: "(l) back mass bx with dr. rosenthal [137260]",
      vendor_code: "137260",
      coded_name: "(L) Back Mass Bx With Dr. Rosenthal [137260]",
    },
  },
  {
    id: "59599",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(L) Hip Girdlestone Procedure",
      normalized_name: "(l) hip girdlestone procedure [10700002318]",
      vendor_code: "10700002318",
      coded_name: "(L) Hip Girdlestone Procedure [10700002318]",
    },
  },
  {
    id: "181515",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(L) Middle Meningeal Artery Embolization",
      normalized_name: "(l) middle meningeal artery embolization [10700001334]",
      vendor_code: "10700001334",
      coded_name: "(L) Middle Meningeal Artery Embolization [10700001334]",
    },
  },
  {
    id: "186205",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(L) Transplant Recipient Kidney From Deceased Donor",
      normalized_name:
        "(l) transplant recipient kidney from deceased donor [10700005528]",
      vendor_code: "10700005528",
      coded_name:
        "(L) Transplant Recipient Kidney From Deceased Donor [10700005528]",
    },
  },
  {
    id: "140230",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(L2-Iliac Psif L4/5/S1 Tlif + Cut Harrington Rod ) L2-S2ai Posterior Segmental Instrumentation + Fusion With L4/5 L5/S1 Posterior Column Osteotomy And Transformainal Lumbar Interbody Fusion With Use Of Bone Morphogenic Protein + Partial Removal Of Herrington Rod.",
      normalized_name:
        "(l2-iliac psif l4/5/s1 tlif + cut harrington rod ) l2-s2ai posterior segmental instrumentation + fusion with l4/5 l5/s1 posterior column osteotomy and transformainal lumbar interbody fusion with use of bone morphogenic protein + partial removal of herrington rod. [166861]",
      vendor_code: "166861",
      coded_name:
        "(L2-Iliac Psif L4/5/S1 Tlif + Cut Harrington Rod ) L2-S2ai Posterior Segmental Instrumentation + Fusion With L4/5 L5/S1 Posterior Column Osteotomy And Transformainal Lumbar Interbody Fusion With Use Of Bone Morphogenic Protein + Partial Removal Of Herrington Rod. [166861]",
    },
  },
  {
    id: "57922",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(L4-L5 Tlif)",
      normalized_name: "(l4-l5 tlif) [10700002624]",
      vendor_code: "10700002624",
      coded_name: "(L4-L5 Tlif) [10700002624]",
    },
  },
  {
    id: "177611",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Lap Vs Open) Diverting Loop Ileostomy",
      normalized_name: "(lap vs open) diverting loop ileostomy [10700006333]",
      vendor_code: "10700006333",
      coded_name: "(Lap Vs Open) Diverting Loop Ileostomy [10700006333]",
    },
  },
  {
    id: "4650",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "70.21",
      name: "(Leep In Or); Need Coated Speculums",
      normalized_name: "(leep in or); need coated speculums [70.21]",
      vendor_code: null,
      coded_name: "(Leep In Or); Need Coated Speculums [70.21]",
    },
  },
  {
    id: "143819",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Left Thigh) Lower Extremity Incision And Drainage",
      normalized_name:
        "(left thigh) lower extremity incision and drainage [106786]",
      vendor_code: "106786",
      coded_name: "(Left Thigh) Lower Extremity Incision And Drainage [106786]",
    },
  },
  {
    id: "27504",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "79.74",
      name: "(Left Thumb Fracture Dislocation Closed Reduction And Pinning)",
      normalized_name:
        "(left thumb fracture dislocation closed reduction and pinning) [79.74]",
      vendor_code: null,
      coded_name:
        "(Left Thumb Fracture Dislocation Closed Reduction And Pinning) [79.74]",
    },
  },
  {
    id: "22048",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "44.67",
      name: "(Linx) Laparoscopic Magnetic Sphincter Augmentation Device Placement, Possible Open, Possible Nissen Fundoplication",
      normalized_name:
        "(linx) laparoscopic magnetic sphincter augmentation device placement, possible open, possible nissen fundoplication [44.67]",
      vendor_code: null,
      coded_name:
        "(Linx) Laparoscopic Magnetic Sphincter Augmentation Device Placement, Possible Open, Possible Nissen Fundoplication [44.67]",
    },
  },
  {
    id: "144964",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Macroplastique)",
      normalized_name: "(macroplastique) [192277]",
      vendor_code: "192277",
      coded_name: "(Macroplastique) [192277]",
    },
  },
  {
    id: "153861",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Mesa,) Possible Tese And Spern Cryopreservation",
      normalized_name:
        "(mesa,) possible tese and spern cryopreservation [10700003155]",
      vendor_code: "10700003155",
      coded_name:
        "(Mesa,) Possible Tese And Spern Cryopreservation [10700003155]",
    },
  },
  {
    id: "142461",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Mini) Replacement Aortic Valve ( Tissue/ Magna)",
      normalized_name:
        "(mini) replacement aortic valve ( tissue/ magna) [10700004668]",
      vendor_code: "10700004668",
      coded_name:
        "(Mini) Replacement Aortic Valve ( Tissue/ Magna) [10700004668]",
    },
  },
  {
    id: "341757",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(MYOSURE), DILATION AND CURETTAGE AND ENDOMETRIAL ABLATION(NOVASURE)",
      normalized_name:
        "(myosure), dilation and curettage and endometrial ablation(novasure) [obgy 229]",
      vendor_code: "OBGY 229",
      coded_name:
        "(MYOSURE), DILATION AND CURETTAGE AND ENDOMETRIAL ABLATION(NOVASURE) [OBGY 229]",
    },
  },
  {
    id: "148361",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Nasal Septoplasty W Turbinate Reduction) Placement Of A Right Latera Implant",
      normalized_name:
        "(nasal septoplasty w turbinate reduction) placement of a right latera implant [173666]",
      vendor_code: "173666",
      coded_name:
        "(Nasal Septoplasty W Turbinate Reduction) Placement Of A Right Latera Implant [173666]",
    },
  },
  {
    id: "239973",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(OLD) MODIFICATION OF OCULAR IMPLANT WITH PLACEMENT OR REPLACEMENT OF PEGS",
      normalized_name:
        "(old) modification of ocular implant with placement or replacement of pegs [65125]",
      vendor_code: "65125",
      coded_name:
        "(OLD) MODIFICATION OF OCULAR IMPLANT WITH PLACEMENT OR REPLACEMENT OF PEGS [65125]",
    },
  },
  {
    id: "250993",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(open reduction internal fixation left shoulder fracture possible rotator cuff repair possible bicipital tenodesis)",
      normalized_name:
        "(open reduction internal fixation left shoulder fracture possible rotator cuff repair possible bicipital tenodesis) [166897]",
      vendor_code: "166897",
      coded_name:
        "(open reduction internal fixation left shoulder fracture possible rotator cuff repair possible bicipital tenodesis) [166897]",
    },
  },
  {
    id: "248267",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(ORIF) Open reduction and internal fixation left lateral malleolus ankle fracture",
      normalized_name:
        "(orif) open reduction and internal fixation left lateral malleolus ankle fracture [702014]",
      vendor_code: "702014",
      coded_name:
        "(ORIF) Open reduction and internal fixation left lateral malleolus ankle fracture [702014]",
    },
  },
  {
    id: "271577",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(ORIF) OPEN REDUCTION INTERNAL FIXATION left trimalleolar ankle fracture",
      normalized_name:
        "(orif) open reduction internal fixation left trimalleolar ankle fracture [700144]",
      vendor_code: "700144",
      coded_name:
        "(ORIF) OPEN REDUCTION INTERNAL FIXATION left trimalleolar ankle fracture [700144]",
    },
  },
  {
    id: "254079",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(ORIF) Open Reduction Internal Fixation Surgery left lateral malleolus fracture",
      normalized_name:
        "(orif) open reduction internal fixation surgery left lateral malleolus fracture [700144]",
      vendor_code: "700144",
      coded_name:
        "(ORIF) Open Reduction Internal Fixation Surgery left lateral malleolus fracture [700144]",
    },
  },
  {
    id: "342268",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(ORIF) RIGHT OLECRANON",
      normalized_name: "(orif) right olecranon [700572]",
      vendor_code: "700572",
      coded_name: "(ORIF) RIGHT OLECRANON [700572]",
    },
  },
  {
    id: "274473",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PATIENT)CERVICAL DISCECTOMY ANTERIOR W CLOWARD FUSION, AUTOLOGOUS",
      normalized_name:
        "(patient)cervical discectomy anterior w cloward fusion, autologous [166805]",
      vendor_code: "166805",
      coded_name:
        "(PATIENT)CERVICAL DISCECTOMY ANTERIOR W CLOWARD FUSION, AUTOLOGOUS [166805]",
    },
  },
  {
    id: "334889",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible direct laryngoscopy and bronchoscopy)",
      normalized_name:
        "(possible direct laryngoscopy and bronchoscopy) [ent 052]",
      vendor_code: "ENT 052",
      coded_name: "(Possible direct laryngoscopy and bronchoscopy) [ENT 052]",
    },
  },
  {
    id: "141913",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible) (Right Before Left) Modifier Right Internal Mammary Artery (psb)",
      normalized_name:
        "(possible) (right before left) modifier right internal mammary artery (psb) [10700007389]",
      vendor_code: "10700007389",
      coded_name:
        "(Possible) (Right Before Left) Modifier Right Internal Mammary Artery (psb) [10700007389]",
    },
  },
  {
    id: "101697",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible) Ascending Aorta Replacement (psb)",
      normalized_name:
        "(possible) ascending aorta replacement (psb) [10700004670]",
      vendor_code: "10700004670",
      coded_name: "(Possible) Ascending Aorta Replacement (psb) [10700004670]",
    },
  },
  {
    id: "111955",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible) Maze Procedure (psb)",
      normalized_name: "(possible) maze procedure (psb) [10700003128]",
      vendor_code: "10700003128",
      coded_name: "(Possible) Maze Procedure (psb) [10700003128]",
    },
  },
  {
    id: "147814",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible) Replace Mitral Valve (Tissue) (psb)",
      normalized_name:
        "(possible) replace mitral valve (tissue) (psb) [10700006708]",
      vendor_code: "10700006708",
      coded_name:
        "(Possible) Replace Mitral Valve (Tissue) (psb) [10700006708]",
    },
  },
  {
    id: "141915",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible) Replacement Aortic Valve (Tissue) (psb)",
      normalized_name:
        "(possible) replacement aortic valve (tissue) (psb) [10700004668]",
      vendor_code: "10700004668",
      coded_name:
        "(Possible) Replacement Aortic Valve (Tissue) (psb) [10700004668]",
    },
  },
  {
    id: "46642",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "58.6",
      name: "(Possible) Urethral Dilation Or Incison Of Stricture",
      normalized_name:
        "(possible) urethral dilation or incison of stricture [58.6]",
      vendor_code: null,
      coded_name: "(Possible) Urethral Dilation Or Incison Of Stricture [58.6]",
    },
  },
  {
    id: "145886",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible)Annuloplasty Tricuspid Valve (psb)",
      normalized_name:
        "(possible)annuloplasty tricuspid valve (psb) [10700000086]",
      vendor_code: "10700000086",
      coded_name: "(Possible)Annuloplasty Tricuspid Valve (psb) [10700000086]",
    },
  },
  {
    id: "145370",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible)Modifier Left Radial Artery Harvest (psb)",
      normalized_name:
        "(possible)modifier left radial artery harvest (psb) [10700007390]",
      vendor_code: "10700007390",
      coded_name:
        "(Possible)Modifier Left Radial Artery Harvest (psb) [10700007390]",
    },
  },
  {
    id: "147813",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible)Replacement Aortic Valve ( Tissue) (psb)",
      normalized_name:
        "(possible)replacement aortic valve ( tissue) (psb) [10700004668]",
      vendor_code: "10700004668",
      coded_name:
        "(Possible)Replacement Aortic Valve ( Tissue) (psb) [10700004668]",
    },
  },
  {
    id: "145885",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Possible)Replacement Aortic Valve(Tissue) (psb)",
      normalized_name:
        "(possible)replacement aortic valve(tissue) (psb) [10700004668]",
      vendor_code: "10700004668",
      coded_name:
        "(Possible)Replacement Aortic Valve(Tissue) (psb) [10700004668]",
    },
  },
  {
    id: "305397",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PRIMARY) C-SECTION; NO TUBAL; GESTATION 38 WEEKS 3 DAYS",
      normalized_name:
        "(primary) c-section; no tubal; gestation 38 weeks 3 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(PRIMARY) C-SECTION; NO TUBAL; GESTATION 38 WEEKS 3 DAYS [700392]",
    },
  },
  {
    id: "343772",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PRIMARY) C-SECTION; NO TUBAL; GESTATION = 36 WEEKS 0 DAYS",
      normalized_name:
        "(primary) c-section; no tubal; gestation = 36 weeks 0 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(PRIMARY) C-SECTION; NO TUBAL; GESTATION = 36 WEEKS 0 DAYS [700392]",
    },
  },
  {
    id: "345609",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PRIMARY) C-SECTION; NO TUBAL; GESTATION = 38 WEEKS 2 DAYS",
      normalized_name:
        "(primary) c-section; no tubal; gestation = 38 weeks 2 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(PRIMARY) C-SECTION; NO TUBAL; GESTATION = 38 WEEKS 2 DAYS [700392]",
    },
  },
  {
    id: "344842",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PRIMARY) C-SECTION; NO TUBAL; GESTATION= 37 WEEKS 1 DAY",
      normalized_name:
        "(primary) c-section; no tubal; gestation= 37 weeks 1 day [700392]",
      vendor_code: "700392",
      coded_name:
        "(PRIMARY) C-SECTION; NO TUBAL; GESTATION= 37 WEEKS 1 DAY [700392]",
    },
  },
  {
    id: "348084",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PRIMARY) C-SECTION; NO TUBAL; GESTATION= 39.0",
      normalized_name:
        "(primary) c-section; no tubal; gestation= 39.0 [700392]",
      vendor_code: "700392",
      coded_name: "(PRIMARY) C-SECTION; NO TUBAL; GESTATION= 39.0 [700392]",
    },
  },
  {
    id: "296992",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PRIMARY) CESAREAN SECTION; NO TUBAL; GESTATION = 39 WEEKS 2 DAYS",
      normalized_name:
        "(primary) cesarean section; no tubal; gestation = 39 weeks 2 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(PRIMARY) CESAREAN SECTION; NO TUBAL; GESTATION = 39 WEEKS 2 DAYS [700392]",
    },
  },
  {
    id: "295129",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PRIMARY) CESAREAN SECTION; NO TUBAL; GESTATION = 39 WEEKS 2 DAYSCESAREAN SECTION",
      normalized_name:
        "(primary) cesarean section; no tubal; gestation = 39 weeks 2 dayscesarean section [700392]",
      vendor_code: "700392",
      coded_name:
        "(PRIMARY) CESAREAN SECTION; NO TUBAL; GESTATION = 39 WEEKS 2 DAYSCESAREAN SECTION [700392]",
    },
  },
  {
    id: "280091",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [106521]",
      vendor_code: "106521",
      coded_name: "(psb) [106521]",
    },
  },
  {
    id: "144356",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [129121]",
      vendor_code: "129121",
      coded_name: "(psb) [129121]",
    },
  },
  {
    id: "134917",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [129175]",
      vendor_code: "129175",
      coded_name: "(psb) [129175]",
    },
  },
  {
    id: "220390",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [144990]",
      vendor_code: "144990",
      coded_name: "(psb) [144990]",
    },
  },
  {
    id: "175430",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [166871]",
      vendor_code: "166871",
      coded_name: "(psb) [166871]",
    },
  },
  {
    id: "63230",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [166885]",
      vendor_code: "166885",
      coded_name: "(psb) [166885]",
    },
  },
  {
    id: "135055",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [189896]",
      vendor_code: "189896",
      coded_name: "(psb) [189896]",
    },
  },
  {
    id: "38877",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(psb)",
      normalized_name: "(psb) [346388]",
      vendor_code: "346388",
      coded_name: "(psb) [346388]",
    },
  },
  {
    id: "38705",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "54.21",
      name: "(psb)",
      normalized_name: "(psb) [54.21]",
      vendor_code: null,
      coded_name: "(psb) [54.21]",
    },
  },
  {
    id: "229573",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PTC) BILIARY TUBE PLACEMENT",
      normalized_name: "(ptc) biliary tube placement [566726]",
      vendor_code: "566726",
      coded_name: "(PTC) BILIARY TUBE PLACEMENT [566726]",
    },
  },
  {
    id: "87397",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PUMP IN BELLY) PUMP REMOVAL",
      normalized_name: "(pump in belly) pump removal [pmremintrapump]",
      vendor_code: "PMREMINTRAPUMP",
      coded_name: "(PUMP IN BELLY) PUMP REMOVAL [PMREMINTRAPUMP]",
    },
  },
  {
    id: "276650",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(PVEP w/ Holmium Laser) PHOTO SELECTIVE VAPORIZATION PROSTATE WITH MORCELLATOR",
      normalized_name:
        "(pvep w/ holmium laser) photo selective vaporization prostate with morcellator [10700007042]",
      vendor_code: "10700007042",
      coded_name:
        "(PVEP w/ Holmium Laser) PHOTO SELECTIVE VAPORIZATION PROSTATE WITH MORCELLATOR [10700007042]",
    },
  },
  {
    id: "186278",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Pvep) Photo Selective Vaporization Prostate With Morcellator",
      normalized_name:
        "(pvep) photo selective vaporization prostate with morcellator [10700007042]",
      vendor_code: "10700007042",
      coded_name:
        "(Pvep) Photo Selective Vaporization Prostate With Morcellator [10700007042]",
    },
  },
  {
    id: "179276",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Amputation Transmetatarsal",
      normalized_name: "(r) amputation transmetatarsal [10700000058]",
      vendor_code: "10700000058",
      coded_name: "(R) Amputation Transmetatarsal [10700000058]",
    },
  },
  {
    id: "186410",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Craniotomy For Subdural Hematoma",
      normalized_name: "(r) craniotomy for subdural hematoma [10700000938]",
      vendor_code: "10700000938",
      coded_name: "(R) Craniotomy For Subdural Hematoma [10700000938]",
    },
  },
  {
    id: "179058",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Forearm Ulna Orif",
      normalized_name: "(r) forearm ulna orif [10700003411]",
      vendor_code: "10700003411",
      coded_name: "(R) Forearm Ulna Orif [10700003411]",
    },
  },
  {
    id: "150825",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Frontal Evd Placement",
      normalized_name: "(r) frontal evd placement [10700003745]",
      vendor_code: "10700003745",
      coded_name: "(R) Frontal Evd Placement [10700003745]",
    },
  },
  {
    id: "186650",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Leg Irrigation And Debridement",
      normalized_name: "(r) leg irrigation and debridement [10700006975]",
      vendor_code: "10700006975",
      coded_name: "(R) Leg Irrigation And Debridement [10700006975]",
    },
  },
  {
    id: "59317",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Long Finger I&D",
      normalized_name: "(r) long finger i&d [10700001108]",
      vendor_code: "10700001108",
      coded_name: "(R) Long Finger I&D [10700001108]",
    },
  },
  {
    id: "136039",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Lung Bx Radiology Anesthesia Procedure",
      normalized_name: "(r) lung bx radiology anesthesia procedure [148608]",
      vendor_code: "148608",
      coded_name: "(R) Lung Bx Radiology Anesthesia Procedure [148608]",
    },
  },
  {
    id: "188318",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) OPEN REPAIR HERNIA INGUINAL, PEDI",
      normalized_name: "(r) open repair hernia inguinal, pedi [10700006798]",
      vendor_code: "10700006798",
      coded_name: "(R) OPEN REPAIR HERNIA INGUINAL, PEDI [10700006798]",
    },
  },
  {
    id: "13127",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Pcnt Placement",
      normalized_name: "(r) pcnt placement [137260]",
      vendor_code: "137260",
      coded_name: "(R) Pcnt Placement [137260]",
    },
  },
  {
    id: "135447",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Radial Head Repair | Reconstruction Ligament Lateral Collateral Elbow",
      normalized_name:
        "(r) radial head repair | reconstruction ligament lateral collateral elbow [10700003960]",
      vendor_code: "10700003960",
      coded_name:
        "(R) Radial Head Repair | Reconstruction Ligament Lateral Collateral Elbow [10700003960]",
    },
  },
  {
    id: "179096",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Ring Replant Finger",
      normalized_name: "(r) ring replant finger [10700004698]",
      vendor_code: "10700004698",
      coded_name: "(R) Ring Replant Finger [10700004698]",
    },
  },
  {
    id: "186516",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(R) Transplant Recipient Kidney From Deceased Donor",
      normalized_name:
        "(r) transplant recipient kidney from deceased donor [10700005528]",
      vendor_code: "10700005528",
      coded_name:
        "(R) Transplant Recipient Kidney From Deceased Donor [10700005528]",
    },
  },
  {
    id: "327441",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Removal of lens material; phacofragmentation, Left Eye",
      normalized_name:
        "(removal of lens material; phacofragmentation, left eye [ophth 013]",
      vendor_code: "OPHTH 013",
      coded_name:
        "(Removal of lens material; phacofragmentation, Left Eye [OPHTH 013]",
    },
  },
  {
    id: "343181",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C- SECTION; NO TUBAL; GESTATION 39 WEEKS 0 DAYS",
      normalized_name:
        "(repeat) c- section; no tubal; gestation 39 weeks 0 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) C- SECTION; NO TUBAL; GESTATION 39 WEEKS 0 DAYS [700392]",
    },
  },
  {
    id: "348076",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C- SECTION; NO TUBAL; GESTATION 39.0",
      normalized_name: "(repeat) c- section; no tubal; gestation 39.0 [700392]",
      vendor_code: "700392",
      coded_name: "(REPEAT) C- SECTION; NO TUBAL; GESTATION 39.0 [700392]",
    },
  },
  {
    id: "345611",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C-SECTION, NO TUBAL; GESTATION= 39 WEEKS 3 DAYS",
      normalized_name:
        "(repeat) c-section, no tubal; gestation= 39 weeks 3 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) C-SECTION, NO TUBAL; GESTATION= 39 WEEKS 3 DAYS [700392]",
    },
  },
  {
    id: "345996",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C-SECTION; NO TUBAL; GESTATION 39 WEEKS, 3 DAYS",
      normalized_name:
        "(repeat) c-section; no tubal; gestation 39 weeks, 3 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) C-SECTION; NO TUBAL; GESTATION 39 WEEKS, 3 DAYS [700392]",
    },
  },
  {
    id: "343534",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C-SECTION; NO TUBAL; GESTATION = 39 WEEKS 0 DAYS",
      normalized_name:
        "(repeat) c-section; no tubal; gestation = 39 weeks 0 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) C-SECTION; NO TUBAL; GESTATION = 39 WEEKS 0 DAYS [700392]",
    },
  },
  {
    id: "313515",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C-SECTION; NO TUBAL; GESTATION= 39 WEEKS 0 DAYS",
      normalized_name:
        "(repeat) c-section; no tubal; gestation= 39 weeks 0 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) C-SECTION; NO TUBAL; GESTATION= 39 WEEKS 0 DAYS [700392]",
    },
  },
  {
    id: "346729",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C-SECTION; NO TUBAL; GESTATION= 39 WEEKS 6 DAYS",
      normalized_name:
        "(repeat) c-section; no tubal; gestation= 39 weeks 6 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) C-SECTION; NO TUBAL; GESTATION= 39 WEEKS 6 DAYS [700392]",
    },
  },
  {
    id: "281579",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) C-SECTION; TUBAL LIGATION; GESTATION= 39 WEEKS 1 DAY",
      normalized_name:
        "(repeat) c-section; tubal ligation; gestation= 39 weeks 1 day [702443]",
      vendor_code: "702443",
      coded_name:
        "(REPEAT) C-SECTION; TUBAL LIGATION; GESTATION= 39 WEEKS 1 DAY [702443]",
    },
  },
  {
    id: "303199",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) CESAREAN SECTION; NO TUBAL; GESTATION = 39 WEEKS 3 DAYS",
      normalized_name:
        "(repeat) cesarean section; no tubal; gestation = 39 weeks 3 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) CESAREAN SECTION; NO TUBAL; GESTATION = 39 WEEKS 3 DAYS [700392]",
    },
  },
  {
    id: "316611",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(REPEAT) CESAREAN SECTION; NO TUBAL; GESTATION= 39 WEEKS 0 DAYS",
      normalized_name:
        "(repeat) cesarean section; no tubal; gestation= 39 weeks 0 days [700392]",
      vendor_code: "700392",
      coded_name:
        "(REPEAT) CESAREAN SECTION; NO TUBAL; GESTATION= 39 WEEKS 0 DAYS [700392]",
    },
  },
  {
    id: "88934",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Restaging) Bladder Transurethral Resection Of Neoplasm",
      normalized_name:
        "(restaging) bladder transurethral resection of neoplasm [150904]",
      vendor_code: "150904",
      coded_name:
        "(Restaging) Bladder Transurethral Resection Of Neoplasm [150904]",
    },
  },
  {
    id: "24346",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "57.49",
      name: "(Restaging) Bladder Transurethral Resection Of Neoplasm",
      normalized_name:
        "(restaging) bladder transurethral resection of neoplasm [57.49]",
      vendor_code: null,
      coded_name:
        "(Restaging) Bladder Transurethral Resection Of Neoplasm [57.49]",
    },
  },
  {
    id: "92794",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Restaging) Bladder Transurethral Resection Of Neoplasm Left Stent Removal",
      normalized_name:
        "(restaging) bladder transurethral resection of neoplasm left stent removal [150904]",
      vendor_code: "150904",
      coded_name:
        "(Restaging) Bladder Transurethral Resection Of Neoplasm Left Stent Removal [150904]",
    },
  },
  {
    id: "70689",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Restaging) Bladder Transurethral Resection Of Neoplasm Possible Stent Removal",
      normalized_name:
        "(restaging) bladder transurethral resection of neoplasm possible stent removal [150904]",
      vendor_code: "150904",
      coded_name:
        "(Restaging) Bladder Transurethral Resection Of Neoplasm Possible Stent Removal [150904]",
    },
  },
  {
    id: "165566",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Restaging) Bladder Transurethral Resection Of Neoplasm With Rigth Stent Removal",
      normalized_name:
        "(restaging) bladder transurethral resection of neoplasm with rigth stent removal [150904]",
      vendor_code: "150904",
      coded_name:
        "(Restaging) Bladder Transurethral Resection Of Neoplasm With Rigth Stent Removal [150904]",
    },
  },
  {
    id: "65899",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Revision) Thoracic Laminectomy Spinal Cord Stimulator Paddle Placement Possible Stimulator/Ipg Revision And All Associated Procedues",
      normalized_name:
        "(revision) thoracic laminectomy spinal cord stimulator paddle placement possible stimulator/ipg revision and all associated procedues [10700002712]",
      vendor_code: "10700002712",
      coded_name:
        "(Revision) Thoracic Laminectomy Spinal Cord Stimulator Paddle Placement Possible Stimulator/Ipg Revision And All Associated Procedues [10700002712]",
    },
  },
  {
    id: "84967",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(RIGHT) KNEE ARTHROSCOPY WITH PARTIAL MEDIAL MENISCECTOMY CHONDROPLASTY MEDIAL PLICA EXCISION EXCISION OF BONE SPURS",
      normalized_name:
        "(right) knee arthroscopy with partial medial meniscectomy chondroplasty medial plica excision excision of bone spurs [orknart]",
      vendor_code: "ORKNART",
      coded_name:
        "(RIGHT) KNEE ARTHROSCOPY WITH PARTIAL MEDIAL MENISCECTOMY CHONDROPLASTY MEDIAL PLICA EXCISION EXCISION OF BONE SPURS [ORKNART]",
    },
  },
  {
    id: "127577",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Saline)",
      normalized_name: "(saline) [673259]",
      vendor_code: "673259",
      coded_name: "(Saline) [673259]",
    },
  },
  {
    id: "159317",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Savi Scout )Breast Lumpectomy Excisional W Sentinel Lymph Node Biopsy",
      normalized_name:
        "(savi scout )breast lumpectomy excisional w sentinel lymph node biopsy [151571]",
      vendor_code: "151571",
      coded_name:
        "(Savi Scout )Breast Lumpectomy Excisional W Sentinel Lymph Node Biopsy [151571]",
    },
  },
  {
    id: "157139",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Savi Scout )Breast Lumpectomy W Needle Localization",
      normalized_name:
        "(savi scout )breast lumpectomy w needle localization [346376]",
      vendor_code: "346376",
      coded_name:
        "(Savi Scout )Breast Lumpectomy W Needle Localization [346376]",
    },
  },
  {
    id: "156060",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Savi Scout) Breast Excisional Biopsy W Needle Localization",
      normalized_name:
        "(savi scout) breast excisional biopsy w needle localization [151556]",
      vendor_code: "151556",
      coded_name:
        "(Savi Scout) Breast Excisional Biopsy W Needle Localization [151556]",
    },
  },
  {
    id: "153555",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Savi Scout) Breast Lumpectomy W Needle Localization",
      normalized_name:
        "(savi scout) breast lumpectomy w needle localization [346376]",
      vendor_code: "346376",
      coded_name:
        "(Savi Scout) Breast Lumpectomy W Needle Localization [346376]",
    },
  },
  {
    id: "157870",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Savi Scout) Breast Lumpectomy W Scout Localization",
      normalized_name:
        "(savi scout) breast lumpectomy w scout localization [346376]",
      vendor_code: "346376",
      coded_name:
        "(Savi Scout) Breast Lumpectomy W Scout Localization [346376]",
    },
  },
  {
    id: "157724",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Savi Scout)- Breast Lumpectomy W Needle Localization",
      normalized_name:
        "(savi scout)- breast lumpectomy w needle localization [346376]",
      vendor_code: "346376",
      coded_name:
        "(Savi Scout)- Breast Lumpectomy W Needle Localization [346376]",
    },
  },
  {
    id: "159347",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Savi Scout)Breast Lumpectomy W Needle Localization",
      normalized_name:
        "(savi scout)breast lumpectomy w needle localization [346376]",
      vendor_code: "346376",
      coded_name:
        "(Savi Scout)Breast Lumpectomy W Needle Localization [346376]",
    },
  },
  {
    id: "152188",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Through Open Incision And Not Laparoscopic) Fenestration Cyst Liver",
      normalized_name:
        "(through open incision and not laparoscopic) fenestration cyst liver [10700002781]",
      vendor_code: "10700002781",
      coded_name:
        "(Through Open Incision And Not Laparoscopic) Fenestration Cyst Liver [10700002781]",
    },
  },
  {
    id: "172636",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(Urolift)",
      normalized_name: "(urolift) [142223]",
      vendor_code: "142223",
      coded_name: "(Urolift) [142223]",
    },
  },
  {
    id: "228589",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(UVULOPALATOPHARYNGOPLASTY with BILATERAL tonsillectomy",
      normalized_name:
        "(uvulopalatopharyngoplasty with bilateral tonsillectomy [701665]",
      vendor_code: "701665",
      coded_name:
        "(UVULOPALATOPHARYNGOPLASTY with BILATERAL tonsillectomy [701665]",
    },
  },
  {
    id: "332735",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "(WAITING FOR MAPPING) AV ACESS AND PERMACATH INSERTION",
      normalized_name:
        "(waiting for mapping) av acess and permacath insertion [cvt 143]",
      vendor_code: "CVT 143",
      coded_name:
        "(WAITING FOR MAPPING) AV ACESS AND PERMACATH INSERTION [CVT 143]",
    },
  },
  {
    id: "200911",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: ")",
      normalized_name: ")",
      vendor_code: null,
      coded_name: ")",
    },
  },
  {
    id: "89722",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: ")",
      normalized_name: ") [169004]",
      vendor_code: "169004",
      coded_name: ") [169004]",
    },
  },
  {
    id: "15045",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "57.32",
      name: ")",
      normalized_name: ") [57.32]",
      vendor_code: null,
      coded_name: ") [57.32]",
    },
  },
  {
    id: "325066",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: ") transurethral resection bladder tumor",
      normalized_name: ") transurethral resection bladder tumor [gu 044]",
      vendor_code: "GU 044",
      coded_name: ") transurethral resection bladder tumor [GU 044]",
    },
  },
  {
    id: "61160",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "81.45",
      name: ")Knee Acl Reconstruction W Quad Tendon Autograft, Standby Allograft (Donor), Meniscus Repair Versus Menisectomy",
      normalized_name:
        ")knee acl reconstruction w quad tendon autograft, standby allograft (donor), meniscus repair versus menisectomy [81.45]",
      vendor_code: null,
      coded_name:
        ")Knee Acl Reconstruction W Quad Tendon Autograft, Standby Allograft (Donor), Meniscus Repair Versus Menisectomy [81.45]",
    },
  },
  {
    id: "18901",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: ")Lumbar Facet Joint (Zygapophyseal) Injection",
      normalized_name: ")lumbar facet joint (zygapophyseal) injection [03.92]",
      vendor_code: null,
      coded_name: ")Lumbar Facet Joint (Zygapophyseal) Injection [03.92]",
    },
  },
  {
    id: "303628",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Bilateral Thyroid Biopsy",
      normalized_name: "* bilateral thyroid biopsy [687702]",
      vendor_code: "687702",
      coded_name: "* Bilateral Thyroid Biopsy [687702]",
    },
  },
  {
    id: "302991",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Cerebral Angiogram",
      normalized_name: "* cerebral angiogram [137260]",
      vendor_code: "137260",
      coded_name: "* Cerebral Angiogram [137260]",
    },
  },
  {
    id: "303294",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Cholangioplasty with Spyglass",
      normalized_name: "* cholangioplasty with spyglass [137260]",
      vendor_code: "137260",
      coded_name: "* Cholangioplasty with Spyglass [137260]",
    },
  },
  {
    id: "304470",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Ct Left TFESI L4/5 injection",
      normalized_name: "* ct left tfesi l4/5 injection [137260]",
      vendor_code: "137260",
      coded_name: "* Ct Left TFESI L4/5 injection [137260]",
    },
  },
  {
    id: "304469",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* CT Si Joint Injection",
      normalized_name: "* ct si joint injection [137260]",
      vendor_code: "137260",
      coded_name: "* CT Si Joint Injection [137260]",
    },
  },
  {
    id: "316095",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* CTG Cryoablation",
      normalized_name: "* ctg cryoablation [137260]",
      vendor_code: "137260",
      coded_name: "* CTG Cryoablation [137260]",
    },
  },
  {
    id: "304752",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* CTG Liver Mass Biopsy",
      normalized_name: "* ctg liver mass biopsy [137260]",
      vendor_code: "137260",
      coded_name: "* CTG Liver Mass Biopsy [137260]",
    },
  },
  {
    id: "304270",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* CTG Liver MWA // Rapid COVID",
      normalized_name: "* ctg liver mwa // rapid covid [137260]",
      vendor_code: "137260",
      coded_name: "* CTG Liver MWA // Rapid COVID [137260]",
    },
  },
  {
    id: "303014",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* CTG Liver MWA",
      normalized_name: "* ctg liver mwa [137260]",
      vendor_code: "137260",
      coded_name: "* CTG Liver MWA [137260]",
    },
  },
  {
    id: "303302",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* CTG Lung Biopsy",
      normalized_name: "* ctg lung biopsy [137260]",
      vendor_code: "137260",
      coded_name: "* CTG Lung Biopsy [137260]",
    },
  },
  {
    id: "303597",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Discogram",
      normalized_name: "* discogram [137260]",
      vendor_code: "137260",
      coded_name: "* Discogram [137260]",
    },
  },
  {
    id: "303633",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Endovenous Laser Ablation",
      normalized_name: "* endovenous laser ablation [137260]",
      vendor_code: "137260",
      coded_name: "* Endovenous Laser Ablation [137260]",
    },
  },
  {
    id: "263478",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* FAKE CASE * EXCISION, GYNECOMASTIA",
      normalized_name: "* fake case * excision, gynecomastia [702678]",
      vendor_code: "702678",
      coded_name: "* FAKE CASE * EXCISION, GYNECOMASTIA [702678]",
    },
  },
  {
    id: "302995",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Fiducial Placement in IR 9 poss CT",
      normalized_name: "* fiducial placement in ir 9 poss ct [137260]",
      vendor_code: "137260",
      coded_name: "* Fiducial Placement in IR 9 poss CT [137260]",
    },
  },
  {
    id: "304745",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Fistulagram",
      normalized_name: "* fistulagram [137260]",
      vendor_code: "137260",
      coded_name: "* Fistulagram [137260]",
    },
  },
  {
    id: "303000",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* G-tube Placement",
      normalized_name: "* g-tube placement [137260]",
      vendor_code: "137260",
      coded_name: "* G-tube Placement [137260]",
    },
  },
  {
    id: "38137",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* General Anesthesia* Embolization, Transcatheter Therapy",
      normalized_name:
        "* general anesthesia* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "* General Anesthesia* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "110537",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* General Anesthesia* Embolization, Transcatheter Therapy Avm Rml/Lll",
      normalized_name:
        "* general anesthesia* embolization, transcatheter therapy avm rml/lll [566732]",
      vendor_code: "566732",
      coded_name:
        "* General Anesthesia* Embolization, Transcatheter Therapy Avm Rml/Lll [566732]",
    },
  },
  {
    id: "143705",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* General Anesthesia* Kyphoplasty",
      normalized_name: "* general anesthesia* kyphoplasty [204850]",
      vendor_code: "204850",
      coded_name: "* General Anesthesia* Kyphoplasty [204850]",
    },
  },
  {
    id: "303327",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Hickman",
      normalized_name: "* hickman [137260]",
      vendor_code: "137260",
      coded_name: "* Hickman [137260]",
    },
  },
  {
    id: "304482",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* IVC Filter Removal",
      normalized_name: "* ivc filter removal [137260]",
      vendor_code: "137260",
      coded_name: "* IVC Filter Removal [137260]",
    },
  },
  {
    id: "303306",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Kyphoplasty",
      normalized_name: "* kyphoplasty [137260]",
      vendor_code: "137260",
      coded_name: "* Kyphoplasty [137260]",
    },
  },
  {
    id: "303609",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* L4/5 and 5/1 Discogram Injection",
      normalized_name: "* l4/5 and 5/1 discogram injection [137260]",
      vendor_code: "137260",
      coded_name: "* L4/5 and 5/1 Discogram Injection [137260]",
    },
  },
  {
    id: "304487",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Left Arm Venous Reconstruction",
      normalized_name: "* left arm venous reconstruction [137260]",
      vendor_code: "137260",
      coded_name: "* Left Arm Venous Reconstruction [137260]",
    },
  },
  {
    id: "303330",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Left Neck LN Biopsy / Thyroid Biopsy",
      normalized_name: "* left neck ln biopsy / thyroid biopsy [137260]",
      vendor_code: "137260",
      coded_name: "* Left Neck LN Biopsy / Thyroid Biopsy [137260]",
    },
  },
  {
    id: "90370",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Not Ready* Knee Replacement Total- Home Recovery",
      normalized_name:
        "* not ready* knee replacement total- home recovery [150898]",
      vendor_code: "150898",
      coded_name: "* Not Ready* Knee Replacement Total- Home Recovery [150898]",
    },
  },
  {
    id: "302983",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* PAE",
      normalized_name: "* pae [137260]",
      vendor_code: "137260",
      coded_name: "* PAE [137260]",
    },
  },
  {
    id: "302990",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Petrosal Vein Sampling",
      normalized_name: "* petrosal vein sampling [137260]",
      vendor_code: "137260",
      coded_name: "* Petrosal Vein Sampling [137260]",
    },
  },
  {
    id: "303010",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Port catheter check, possible exchange",
      normalized_name: "* port catheter check, possible exchange [137260]",
      vendor_code: "137260",
      coded_name: "* Port catheter check, possible exchange [137260]",
    },
  },
  {
    id: "303605",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Pudendal nerve Cryoablation",
      normalized_name: "* pudendal nerve cryoablation [137260]",
      vendor_code: "137260",
      coded_name: "* Pudendal nerve Cryoablation [137260]",
    },
  },
  {
    id: "314985",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* R/S* RT EXCISIONAL BIOPSY, MASS, LOWER EXTREMITY",
      normalized_name:
        "* r/s* rt excisional biopsy, mass, lower extremity [703057]",
      vendor_code: "703057",
      coded_name: "* R/S* RT EXCISIONAL BIOPSY, MASS, LOWER EXTREMITY [703057]",
    },
  },
  {
    id: "346167",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* REPAIR, ROTATOR CUFF, ARTHROSCOPIC WITH BICEPS TENODESIS",
      normalized_name:
        "* repair, rotator cuff, arthroscopic with biceps tenodesis [700213]",
      vendor_code: "700213",
      coded_name:
        "* REPAIR, ROTATOR CUFF, ARTHROSCOPIC WITH BICEPS TENODESIS [700213]",
    },
  },
  {
    id: "303309",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Right Leg Angiogram",
      normalized_name: "* right leg angiogram [137260]",
      vendor_code: "137260",
      coded_name: "* Right Leg Angiogram [137260]",
    },
  },
  {
    id: "303009",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Sclerotherapy",
      normalized_name: "* sclerotherapy [137260]",
      vendor_code: "137260",
      coded_name: "* Sclerotherapy [137260]",
    },
  },
  {
    id: "303599",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Sclerotherapy with Conscious Sedation",
      normalized_name: "* sclerotherapy with conscious sedation [137260]",
      vendor_code: "137260",
      coded_name: "* Sclerotherapy with Conscious Sedation [137260]",
    },
  },
  {
    id: "302999",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Sinogram",
      normalized_name: "* sinogram [137260]",
      vendor_code: "137260",
      coded_name: "* Sinogram [137260]",
    },
  },
  {
    id: "303600",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Subclavian Angiogram with Intervention (Allergy)",
      normalized_name:
        "* subclavian angiogram with intervention (allergy) [137260]",
      vendor_code: "137260",
      coded_name: "* Subclavian Angiogram with Intervention (Allergy) [137260]",
    },
  },
  {
    id: "302994",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* TACE with Powder",
      normalized_name: "* tace with powder [137260]",
      vendor_code: "137260",
      coded_name: "* TACE with Powder [137260]",
    },
  },
  {
    id: "66302",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* This Is An Indirect Bypass *",
      normalized_name: "* this is an indirect bypass * [165070]",
      vendor_code: "165070",
      coded_name: "* This Is An Indirect Bypass * [165070]",
    },
  },
  {
    id: "302986",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Thyroid Ablation with RFA // Contrast Enhanced US",
      normalized_name:
        "* thyroid ablation with rfa // contrast enhanced us [137260]",
      vendor_code: "137260",
      coded_name:
        "* Thyroid Ablation with RFA // Contrast Enhanced US [137260]",
    },
  },
  {
    id: "302998",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* UAE",
      normalized_name: "* uae [137260]",
      vendor_code: "137260",
      coded_name: "* UAE [137260]",
    },
  },
  {
    id: "303304",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* USG Abdominal Mass Biopsy",
      normalized_name: "* usg abdominal mass biopsy [137260]",
      vendor_code: "137260",
      coded_name: "* USG Abdominal Mass Biopsy [137260]",
    },
  },
  {
    id: "303006",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Venaseal",
      normalized_name: "* venaseal [137260]",
      vendor_code: "137260",
      coded_name: "* Venaseal [137260]",
    },
  },
  {
    id: "19392",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "21.84",
      name: "* Venture * 4 Hours 30 Min",
      normalized_name: "* venture * 4 hours 30 min [21.84]",
      vendor_code: null,
      coded_name: "* Venture * 4 Hours 30 Min [21.84]",
    },
  },
  {
    id: "142793",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Venture Case, 4 Hrs 45 Min *",
      normalized_name: "* venture case, 4 hrs 45 min * [173679]",
      vendor_code: "173679",
      coded_name: "* Venture Case, 4 Hrs 45 Min * [173679]",
    },
  },
  {
    id: "73080",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Venture Case, 4.5 Hrs *",
      normalized_name: "* venture case, 4.5 hrs * [173679]",
      vendor_code: "173679",
      coded_name: "* Venture Case, 4.5 Hrs * [173679]",
    },
  },
  {
    id: "321934",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* would prefer to do case robotically if room is available laparoscopic assisted myomectomy if robot is available would like to do case robotically",
      normalized_name:
        "* would prefer to do case robotically if room is available laparoscopic assisted myomectomy if robot is available would like to do case robotically [obgy 048]",
      vendor_code: "OBGY 048",
      coded_name:
        "* would prefer to do case robotically if room is available laparoscopic assisted myomectomy if robot is available would like to do case robotically [OBGY 048]",
    },
  },
  {
    id: "303325",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y-90 Mapping (Allergy)",
      normalized_name: "* y-90 mapping (allergy) [137260]",
      vendor_code: "137260",
      coded_name: "* Y-90 Mapping (Allergy) [137260]",
    },
  },
  {
    id: "302980",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y-90 Mapping (ER/TH)",
      normalized_name: "* y-90 mapping (er/th) [137260]",
      vendor_code: "137260",
      coded_name: "* Y-90 Mapping (ER/TH) [137260]",
    },
  },
  {
    id: "304467",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y-90 Mapping",
      normalized_name: "* y-90 mapping [137260]",
      vendor_code: "137260",
      coded_name: "* Y-90 Mapping [137260]",
    },
  },
  {
    id: "302981",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y-90 Mapping possible TACE w/Powder (ER/TH)",
      normalized_name: "* y-90 mapping possible tace w/powder (er/th) [137260]",
      vendor_code: "137260",
      coded_name: "* Y-90 Mapping possible TACE w/Powder (ER/TH) [137260]",
    },
  },
  {
    id: "304184",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y-90 Mapping Possible TACE with Powder",
      normalized_name: "* y-90 mapping possible tace with powder [137260]",
      vendor_code: "137260",
      coded_name: "* Y-90 Mapping Possible TACE with Powder [137260]",
    },
  },
  {
    id: "303326",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y-90 Treatment (Allergy)",
      normalized_name: "* y-90 treatment (allergy) [137260]",
      vendor_code: "137260",
      coded_name: "* Y-90 Treatment (Allergy) [137260]",
    },
  },
  {
    id: "304468",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y-90 Treatment",
      normalized_name: "* y-90 treatment [137260]",
      vendor_code: "137260",
      coded_name: "* Y-90 Treatment [137260]",
    },
  },
  {
    id: "303005",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "* Y90 Mapping",
      normalized_name: "* y90 mapping [137260]",
      vendor_code: "137260",
      coded_name: "* Y90 Mapping [137260]",
    },
  },
  {
    id: "260073",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** FRANCES RESCH**EGD WITH ANESTHESIA",
      normalized_name: "** frances resch**egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "** FRANCES RESCH**EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "38690",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "** General Anesthesia** Angiogram, Bilateral Lower Extermities",
      normalized_name:
        "** general anesthesia** angiogram, bilateral lower extermities [88.40]",
      vendor_code: null,
      coded_name:
        "** General Anesthesia** Angiogram, Bilateral Lower Extermities [88.40]",
    },
  },
  {
    id: "136654",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** General Anesthesia** Possible Vertebral Artery Stenting, Embolization, Transcatheter Therapy",
      normalized_name:
        "** general anesthesia** possible vertebral artery stenting, embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "** General Anesthesia** Possible Vertebral Artery Stenting, Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "343807",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** keep in depot / my be inpt / diane will fix in morn / LAPAROTOMY, EXPLORATORY",
      normalized_name:
        "** keep in depot / my be inpt / diane will fix in morn / laparotomy, exploratory [700628]",
      vendor_code: "700628",
      coded_name:
        "** keep in depot / my be inpt / diane will fix in morn / LAPAROTOMY, EXPLORATORY [700628]",
    },
  },
  {
    id: "2368",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "44.67",
      name: "** Linx Procedure** Nissen Fundoplication By Abdominal Approach Laparoscopic",
      normalized_name:
        "** linx procedure** nissen fundoplication by abdominal approach laparoscopic [44.67]",
      vendor_code: null,
      coded_name:
        "** Linx Procedure** Nissen Fundoplication By Abdominal Approach Laparoscopic [44.67]",
    },
  },
  {
    id: "280262",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** NEED HW/VENDOR INFO** LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, TIBIA, PLATEAU",
      normalized_name:
        "** need hw/vendor info** lt open reduction internal fixation (orif), fracture, tibia, plateau [701545]",
      vendor_code: "701545",
      coded_name:
        "** NEED HW/VENDOR INFO** LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, TIBIA, PLATEAU [701545]",
    },
  },
  {
    id: "284140",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** NO SHOW **RT EPIDIDYMECTOMY",
      normalized_name: "** no show **rt epididymectomy [700598]",
      vendor_code: "700598",
      coded_name: "** NO SHOW **RT EPIDIDYMECTOMY [700598]",
    },
  },
  {
    id: "71642",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** Right Edas/ Edams **",
      normalized_name: "** right edas/ edams ** [165070]",
      vendor_code: "165070",
      coded_name: "** Right Edas/ Edams ** [165070]",
    },
  },
  {
    id: "117233",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** This Is An Indirect Bypass, Edams, Possible Edas",
      normalized_name:
        "** this is an indirect bypass, edams, possible edas [165070]",
      vendor_code: "165070",
      coded_name:
        "** This Is An Indirect Bypass, Edams, Possible Edas [165070]",
    },
  },
  {
    id: "99319",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "** This Is An Left Indirect Bypass **",
      normalized_name: "** this is an left indirect bypass ** [165070]",
      vendor_code: "165070",
      coded_name: "** This Is An Left Indirect Bypass ** [165070]",
    },
  },
  {
    id: "25944",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "81.0x",
      name: "*** Cervical Discectomy W Cervical Fusion, Anterior Technique",
      normalized_name:
        "*** cervical discectomy w cervical fusion, anterior technique [81.0x]",
      vendor_code: null,
      coded_name:
        "*** Cervical Discectomy W Cervical Fusion, Anterior Technique [81.0x]",
    },
  },
  {
    id: "38182",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia *** Embolization, Transcatheter Therapy",
      normalized_name:
        "*** general anesthesia *** embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*** General Anesthesia *** Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "18874",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia *** Radiology Anesthesia Procedure - Mr Brain Without Contrast",
      normalized_name:
        "*** general anesthesia *** radiology anesthesia procedure - mr brain without contrast [148608]",
      vendor_code: "148608",
      coded_name:
        "*** General Anesthesia *** Radiology Anesthesia Procedure - Mr Brain Without Contrast [148608]",
    },
  },
  {
    id: "17916",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia *** Radiology Anesthesia Procedure - Mr Lumbar Spine Without Contrast",
      normalized_name:
        "*** general anesthesia *** radiology anesthesia procedure - mr lumbar spine without contrast [148608]",
      vendor_code: "148608",
      coded_name:
        "*** General Anesthesia *** Radiology Anesthesia Procedure - Mr Lumbar Spine Without Contrast [148608]",
    },
  },
  {
    id: "18966",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia *** Radiology Anesthesia Procedure- Mr Brain With And Without Contrast",
      normalized_name:
        "*** general anesthesia *** radiology anesthesia procedure- mr brain with and without contrast [148608]",
      vendor_code: "148608",
      coded_name:
        "*** General Anesthesia *** Radiology Anesthesia Procedure- Mr Brain With And Without Contrast [148608]",
    },
  },
  {
    id: "18968",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia *** Radiology Anesthesia Procedure- Mr Knee Without Contrast Right",
      normalized_name:
        "*** general anesthesia *** radiology anesthesia procedure- mr knee without contrast right [148608]",
      vendor_code: "148608",
      coded_name:
        "*** General Anesthesia *** Radiology Anesthesia Procedure- Mr Knee Without Contrast Right [148608]",
    },
  },
  {
    id: "18965",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia *** Radiology Anesthesia Procedure- Mr Spectroscopy And Mr Brain With And Without Contrast",
      normalized_name:
        "*** general anesthesia *** radiology anesthesia procedure- mr spectroscopy and mr brain with and without contrast [148608]",
      vendor_code: "148608",
      coded_name:
        "*** General Anesthesia *** Radiology Anesthesia Procedure- Mr Spectroscopy And Mr Brain With And Without Contrast [148608]",
    },
  },
  {
    id: "39665",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia ***Embolization, Transcatheter Therapy",
      normalized_name:
        "*** general anesthesia ***embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*** General Anesthesia ***Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "127918",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia*** Angiogram",
      normalized_name: "*** general anesthesia*** angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*** General Anesthesia*** Angiogram [192267]",
    },
  },
  {
    id: "40158",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** General Anesthesia*** Embolization, Transcatheter Therapy",
      normalized_name:
        "*** general anesthesia*** embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*** General Anesthesia*** Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "22845",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.09",
      name: "*** Lumbar Laminotomy And Foraminotomy",
      normalized_name: "*** lumbar laminotomy and foraminotomy [03.09]",
      vendor_code: null,
      coded_name: "*** Lumbar Laminotomy And Foraminotomy [03.09]",
    },
  },
  {
    id: "148573",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*** Lumbar Laminotomy And Foraminotomy",
      normalized_name: "*** lumbar laminotomy and foraminotomy [166862]",
      vendor_code: "166862",
      coded_name: "*** Lumbar Laminotomy And Foraminotomy [166862]",
    },
  },
  {
    id: "297022",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "****AMPUTATION, LEFT FOOT, TRANSMETATARSAL REVISION",
      normalized_name:
        "****amputation, left foot, transmetatarsal revision [750096]",
      vendor_code: "750096",
      coded_name:
        "****AMPUTATION, LEFT FOOT, TRANSMETATARSAL REVISION [750096]",
    },
  },
  {
    id: "312459",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "****OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, CLAVICLE RIGHT",
      normalized_name:
        "****open reduction internal fixation (orif), fracture, clavicle right [700411]",
      vendor_code: "700411",
      coded_name:
        "****OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, CLAVICLE RIGHT [700411]",
    },
  },
  {
    id: "297165",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "****OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HIP",
      normalized_name:
        "****open reduction internal fixation (orif), fracture, hip [702007]",
      vendor_code: "702007",
      coded_name:
        "****OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HIP [702007]",
    },
  },
  {
    id: "84547",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "****POSS MYRINGOPLASTY***",
      normalized_name: "****poss myringoplasty*** [enmyr]",
      vendor_code: "ENMYR",
      coded_name: "****POSS MYRINGOPLASTY*** [ENMYR]",
    },
  },
  {
    id: "296728",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "****RADICAL RESECTION BUTTOCK SCC",
      normalized_name: "****radical resection buttock scc [702488]",
      vendor_code: "702488",
      coded_name: "****RADICAL RESECTION BUTTOCK SCC [702488]",
    },
  },
  {
    id: "122983",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***Anesthesia-Mac*** Carotid Angioplasty W Stent",
      normalized_name:
        "***anesthesia-mac*** carotid angioplasty w stent [181742]",
      vendor_code: "181742",
      coded_name: "***Anesthesia-Mac*** Carotid Angioplasty W Stent [181742]",
    },
  },
  {
    id: "17096",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "22.9",
      name: "***Balloon Dilation Of The Left Eustachian Tube (Ear)***",
      normalized_name:
        "***balloon dilation of the left eustachian tube (ear)*** [22.9]",
      vendor_code: null,
      coded_name:
        "***Balloon Dilation Of The Left Eustachian Tube (Ear)*** [22.9]",
    },
  },
  {
    id: "18275",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia***",
      normalized_name: "***general anesthesia*** [148608]",
      vendor_code: "148608",
      coded_name: "***General Anesthesia*** [148608]",
    },
  },
  {
    id: "130877",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia*** Angiogram",
      normalized_name: "***general anesthesia*** angiogram [192267]",
      vendor_code: "192267",
      coded_name: "***General Anesthesia*** Angiogram [192267]",
    },
  },
  {
    id: "88783",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia*** Biopsy, Lung",
      normalized_name: "***general anesthesia*** biopsy, lung [566720]",
      vendor_code: "566720",
      coded_name: "***General Anesthesia*** Biopsy, Lung [566720]",
    },
  },
  {
    id: "128346",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia*** Carotid Stent, Embolization, Transcatheter Therapy",
      normalized_name:
        "***general anesthesia*** carotid stent, embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "***General Anesthesia*** Carotid Stent, Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "194161",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***GENERAL ANESTHESIA*** CEREBRAL ANGIOGRAM",
      normalized_name: "***general anesthesia*** cerebral angiogram [192267]",
      vendor_code: "192267",
      coded_name: "***GENERAL ANESTHESIA*** CEREBRAL ANGIOGRAM [192267]",
    },
  },
  {
    id: "40283",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia*** Deep Bone Bx & Lp W. It Chemo",
      normalized_name:
        "***general anesthesia*** deep bone bx & lp w. it chemo [566726]",
      vendor_code: "566726",
      coded_name:
        "***General Anesthesia*** Deep Bone Bx & Lp W. It Chemo [566726]",
    },
  },
  {
    id: "37073",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia*** Embolization, Transcatheter Therapy",
      normalized_name:
        "***general anesthesia*** embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "***General Anesthesia*** Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "41248",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia*** Lp With Chemo- Ir",
      normalized_name: "***general anesthesia*** lp with chemo- ir [566726]",
      vendor_code: "566726",
      coded_name: "***General Anesthesia*** Lp With Chemo- Ir [566726]",
    },
  },
  {
    id: "36542",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***General Anesthesia***Embolization, Transcatheter Therapy",
      normalized_name:
        "***general anesthesia***embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "***General Anesthesia***Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "268731",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***INJECTION, BOTULINUM TOXIN, ANAL REGION",
      normalized_name: "***injection, botulinum toxin, anal region [703112]",
      vendor_code: "703112",
      coded_name: "***INJECTION, BOTULINUM TOXIN, ANAL REGION [703112]",
    },
  },
  {
    id: "315887",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***LEFT SHOULDER ARTHROSCOPY POSSIBLE CAPSULORRHAPHY, SUBACROMIAL DECOMPRESSION",
      normalized_name:
        "***left shoulder arthroscopy possible capsulorrhaphy, subacromial decompression [700228]",
      vendor_code: "700228",
      coded_name:
        "***LEFT SHOULDER ARTHROSCOPY POSSIBLE CAPSULORRHAPHY, SUBACROMIAL DECOMPRESSION [700228]",
    },
  },
  {
    id: "316683",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***need to know what hardware screw***REMOVAL, right foot",
      normalized_name:
        "***need to know what hardware screw***removal, right foot [700758]",
      vendor_code: "700758",
      coded_name:
        "***need to know what hardware screw***REMOVAL, right foot [700758]",
    },
  },
  {
    id: "316783",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***NO INSURANCE AUTH***AQUABLATION OF THE PROSTATE",
      normalized_name:
        "***no insurance auth***aquablation of the prostate [703889]",
      vendor_code: "703889",
      coded_name: "***NO INSURANCE AUTH***AQUABLATION OF THE PROSTATE [703889]",
    },
  },
  {
    id: "58618",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***Stage 1*** Anterior - Osteotomy Pelvis/Sacrum",
      normalized_name:
        "***stage 1*** anterior - osteotomy pelvis/sacrum [10700003520]",
      vendor_code: "10700003520",
      coded_name:
        "***Stage 1*** Anterior - Osteotomy Pelvis/Sacrum [10700003520]",
    },
  },
  {
    id: "58621",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "***Stage 2*** Posterior - Sacrectomy Partial Spine",
      normalized_name:
        "***stage 2*** posterior - sacrectomy partial spine [10700005186]",
      vendor_code: "10700005186",
      coded_name:
        "***Stage 2*** Posterior - Sacrectomy Partial Spine [10700005186]",
    },
  },
  {
    id: "282767",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**>7days out** EGD WITH ANESTHESIA",
      normalized_name: "**>7days out** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**>7days out** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "312353",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**appt time conflict** COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "**appt time conflict** colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**appt time conflict** COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "293021",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**APPT TIME** EGD/COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "**appt time** egd/colonoscopy with anesthesia [702109]",
      vendor_code: "702109",
      coded_name: "**APPT TIME** EGD/COLONOSCOPY WITH ANESTHESIA [702109]",
    },
  },
  {
    id: "275897",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**booking too soon** EGD WITH ANESTHESIA",
      normalized_name: "**booking too soon** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**booking too soon** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "316371",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**call office for new DOS** Left shoulder RTC repair, removal of loose body, possible open biceps tenodesis",
      normalized_name:
        "**call office for new dos** left shoulder rtc repair, removal of loose body, possible open biceps tenodesis [700228]",
      vendor_code: "700228",
      coded_name:
        "**call office for new DOS** Left shoulder RTC repair, removal of loose body, possible open biceps tenodesis [700228]",
    },
  },
  {
    id: "277200",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**call out to office for DOS** REMOVAL, HARDWARE, FOOT OR ANKLE",
      normalized_name:
        "**call out to office for dos** removal, hardware, foot or ankle [700758]",
      vendor_code: "700758",
      coded_name:
        "**call out to office for DOS** REMOVAL, HARDWARE, FOOT OR ANKLE [700758]",
    },
  },
  {
    id: "278834",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**canceled** APPENDECTOMY, LAPAROSCOPIC",
      normalized_name: "**canceled** appendectomy, laparoscopic [700169]",
      vendor_code: "700169",
      coded_name: "**canceled** APPENDECTOMY, LAPAROSCOPIC [700169]",
    },
  },
  {
    id: "309078",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CAROTID ACCESS**HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE(psb)",
      normalized_name:
        "**carotid access**hybrid transfemoral percutaneous aortic valve(psb) [cvt 204]",
      vendor_code: "CVT 204",
      coded_name:
        "**CAROTID ACCESS**HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE(psb) [CVT 204]",
    },
  },
  {
    id: "209427",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**Case Pending IR Results** LAPAROSCOPY, DIAGNOSTIC",
      normalized_name:
        "**case pending ir results** laparoscopy, diagnostic [700916]",
      vendor_code: "700916",
      coded_name:
        "**Case Pending IR Results** LAPAROSCOPY, DIAGNOSTIC [700916]",
    },
  },
  {
    id: "346314",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CHRISY FIX DATES* ESOPHAGOGASTRODUODENOSCOPY (EGD) WITH ANESTHESIA",
      normalized_name:
        "**chrisy fix dates* esophagogastroduodenoscopy (egd) with anesthesia [702107]",
      vendor_code: "702107",
      coded_name:
        "**CHRISY FIX DATES* ESOPHAGOGASTRODUODENOSCOPY (EGD) WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "278835",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cld** INCISION AND DRAINAGE, WOUND, UPPER EXTREMITY",
      normalized_name:
        "**cld** incision and drainage, wound, upper extremity [700865]",
      vendor_code: "700865",
      coded_name:
        "**cld** INCISION AND DRAINAGE, WOUND, UPPER EXTREMITY [700865]",
    },
  },
  {
    id: "348022",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CLXD**CHOLECYSTECTOMY, LAPAROSCOPIC, possible intraoperative cholangiogram",
      normalized_name:
        "**clxd**cholecystectomy, laparoscopic, possible intraoperative cholangiogram [701764]",
      vendor_code: "701764",
      coded_name:
        "**CLXD**CHOLECYSTECTOMY, LAPAROSCOPIC, possible intraoperative cholangiogram [701764]",
    },
  },
  {
    id: "277616",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CONFLICTS unable to schedule** ERCP, WITH FLUOROSCOPIC GUIDANCE AND ANESTHESIA",
      normalized_name:
        "**conflicts unable to schedule** ercp, with fluoroscopic guidance and anesthesia [702104]",
      vendor_code: "702104",
      coded_name:
        "**CONFLICTS unable to schedule** ERCP, WITH FLUOROSCOPIC GUIDANCE AND ANESTHESIA [702104]",
    },
  },
  {
    id: "278815",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**COURTNEY-provider/time conflict** EGD WITH ANESTHESIA",
      normalized_name:
        "**courtney-provider/time conflict** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name:
        "**COURTNEY-provider/time conflict** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "302780",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxl'd** HEMIARTHROPLASTY, HIP, BIPOLAR",
      normalized_name: "**cxl'd** hemiarthroplasty, hip, bipolar [700813]",
      vendor_code: "700813",
      coded_name: "**cxl'd** HEMIARTHROPLASTY, HIP, BIPOLAR [700813]",
    },
  },
  {
    id: "276911",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxl'd** LAPAROSCOPIC BILATERAL TUBAL LIGATION VIA SALPINGECTOMY",
      normalized_name:
        "**cxl'd** laparoscopic bilateral tubal ligation via salpingectomy [702732]",
      vendor_code: "702732",
      coded_name:
        "**cxl'd** LAPAROSCOPIC BILATERAL TUBAL LIGATION VIA SALPINGECTOMY [702732]",
    },
  },
  {
    id: "345126",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld need to r/s** RT RELEASE, CARPAL TUNNEL",
      normalized_name:
        "**cxld need to r/s** rt release, carpal tunnel [700371]",
      vendor_code: "700371",
      coded_name: "**cxld need to r/s** RT RELEASE, CARPAL TUNNEL [700371]",
    },
  },
  {
    id: "280924",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld procedure** 23H- LT SUBTALAR & TALONAVICULAR ARTHRODESIS, TAL, PERONEAL TENDON LENGTHENING, POSSIBLE 1ST TMT FUSION, POSSIBLE CALCANEOCUBOID ARTHRODESIS",
      normalized_name:
        "**cxld procedure** 23h- lt subtalar & talonavicular arthrodesis, tal, peroneal tendon lengthening, possible 1st tmt fusion, possible calcaneocuboid arthrodesis [702528]",
      vendor_code: "702528",
      coded_name:
        "**cxld procedure** 23H- LT SUBTALAR & TALONAVICULAR ARTHRODESIS, TAL, PERONEAL TENDON LENGTHENING, POSSIBLE 1ST TMT FUSION, POSSIBLE CALCANEOCUBOID ARTHRODESIS [702528]",
    },
  },
  {
    id: "278749",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld r/s w/ office** 23H- RT UNICOMPARTMENTAL TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**cxld r/s w/ office** 23h- rt unicompartmental total knee replacement [701570]",
      vendor_code: "701570",
      coded_name:
        "**cxld r/s w/ office** 23H- RT UNICOMPARTMENTAL TOTAL KNEE REPLACEMENT [701570]",
    },
  },
  {
    id: "311923",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** 23H- RT REVERSE TOTAL SHOULDER",
      normalized_name: "**cxld** 23h- rt reverse total shoulder [702604]",
      vendor_code: "702604",
      coded_name: "**cxld** 23H- RT REVERSE TOTAL SHOULDER [702604]",
    },
  },
  {
    id: "317525",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** 23H- RT TOTAL SHOULDER REPLACEMENT",
      normalized_name: "**cxld** 23h- rt total shoulder replacement [701572]",
      vendor_code: "701572",
      coded_name: "**cxld** 23H- RT TOTAL SHOULDER REPLACEMENT [701572]",
    },
  },
  {
    id: "282030",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** 23hr--LT TOTAL KNEE REPLACEMENT",
      normalized_name: "**cxld** 23hr--lt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "**cxld** 23hr--LT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "280762",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD** CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "**cxld** cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "**CXLD** CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "267967",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD** CHOLECYSTECTOMY, LAPAROSCOPIC, WITH CHOLANGIOGRAM",
      normalized_name:
        "**cxld** cholecystectomy, laparoscopic, with cholangiogram [700404]",
      vendor_code: "700404",
      coded_name:
        "**CXLD** CHOLECYSTECTOMY, LAPAROSCOPIC, WITH CHOLANGIOGRAM [700404]",
    },
  },
  {
    id: "302685",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** CO2 LASER VAPORIZATION OF CERVIX",
      normalized_name: "**cxld** co2 laser vaporization of cervix [700386]",
      vendor_code: "700386",
      coded_name: "**cxld** CO2 LASER VAPORIZATION OF CERVIX [700386]",
    },
  },
  {
    id: "305098",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** CREATION, TRACHEOSTOMY",
      normalized_name: "**cxld** creation, tracheostomy [702132]",
      vendor_code: "702132",
      coded_name: "**cxld** CREATION, TRACHEOSTOMY [702132]",
    },
  },
  {
    id: "317526",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** EGD WITH ANESTHESIA",
      normalized_name: "**cxld** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**cxld** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "283972",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** FUSION, JOINT RT GREAT TOE",
      normalized_name: "**cxld** fusion, joint rt great toe [701994]",
      vendor_code: "701994",
      coded_name: "**cxld** FUSION, JOINT RT GREAT TOE [701994]",
    },
  },
  {
    id: "312645",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD** INSERTION, GAMMA NAIL, RIGHT FEMUR",
      normalized_name: "**cxld** insertion, gamma nail, right femur [750039]",
      vendor_code: "750039",
      coded_name: "**CXLD** INSERTION, GAMMA NAIL, RIGHT FEMUR [750039]",
    },
  },
  {
    id: "316232",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD** INSERTION, TROCHANTERIC NAIL, FEMUR",
      normalized_name: "**cxld** insertion, trochanteric nail, femur [702367]",
      vendor_code: "702367",
      coded_name: "**CXLD** INSERTION, TROCHANTERIC NAIL, FEMUR [702367]",
    },
  },
  {
    id: "318233",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** LAPAROTOMY, EXPLORATORY",
      normalized_name: "**cxld** laparotomy, exploratory [700628]",
      vendor_code: "700628",
      coded_name: "**cxld** LAPAROTOMY, EXPLORATORY [700628]",
    },
  },
  {
    id: "312422",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** Left shoulder debridement vs repair of RTC, debridement of SLAP, open biceps tenodesis",
      normalized_name:
        "**cxld** left shoulder debridement vs repair of rtc, debridement of slap, open biceps tenodesis [700228]",
      vendor_code: "700228",
      coded_name:
        "**cxld** Left shoulder debridement vs repair of RTC, debridement of SLAP, open biceps tenodesis [700228]",
    },
  },
  {
    id: "313602",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD** LT ARTHROSCOPY, SHOULDER, WITH BICEPS TENODESIS",
      normalized_name:
        "**cxld** lt arthroscopy, shoulder, with biceps tenodesis [702393]",
      vendor_code: "702393",
      coded_name:
        "**CXLD** LT ARTHROSCOPY, SHOULDER, WITH BICEPS TENODESIS [702393]",
    },
  },
  {
    id: "300726",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** LT DEQUERVAIN'S RELEASE",
      normalized_name: "**cxld** lt dequervain's release [700544]",
      vendor_code: "700544",
      coded_name: "**cxld** LT DEQUERVAIN'S RELEASE [700544]",
    },
  },
  {
    id: "298129",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLd** LT KNEE MANIPULATION UNDER ANESTHESIA",
      normalized_name:
        "**cxld** lt knee manipulation under anesthesia [700995]",
      vendor_code: "700995",
      coded_name: "**CXLd** LT KNEE MANIPULATION UNDER ANESTHESIA [700995]",
    },
  },
  {
    id: "348054",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** LT RADIUS FRACTURE ORIF",
      normalized_name: "**cxld** lt radius fracture orif [702389]",
      vendor_code: "702389",
      coded_name: "**cxld** LT RADIUS FRACTURE ORIF [702389]",
    },
  },
  {
    id: "282908",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** REMOVAL, HARDWARE, EXTREMITY - Right tibial nail dynamization",
      normalized_name:
        "**cxld** removal, hardware, extremity - right tibial nail dynamization [700754]",
      vendor_code: "700754",
      coded_name:
        "**cxld** REMOVAL, HARDWARE, EXTREMITY - Right tibial nail dynamization [700754]",
    },
  },
  {
    id: "279108",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD** RT DUPUYTRENS CONTRACTURE RELEASE",
      normalized_name: "**cxld** rt dupuytrens contracture release [700557]",
      vendor_code: "700557",
      coded_name: "**CXLD** RT DUPUYTRENS CONTRACTURE RELEASE [700557]",
    },
  },
  {
    id: "278981",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD** RT EXCISION, GANGLION CYST",
      normalized_name: "**cxld** rt excision, ganglion cyst [700720]",
      vendor_code: "700720",
      coded_name: "**CXLD** RT EXCISION, GANGLION CYST [700720]",
    },
  },
  {
    id: "303311",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** RT HALLUX EXCISION, EXOSTOSIS",
      normalized_name: "**cxld** rt hallux excision, exostosis [700623]",
      vendor_code: "700623",
      coded_name: "**cxld** RT HALLUX EXCISION, EXOSTOSIS [700623]",
    },
  },
  {
    id: "347738",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**Cxld** RT INCISION AND DRAINAGE, WOUND, RIGHT THUMB",
      normalized_name:
        "**cxld** rt incision and drainage, wound, right thumb [700865]",
      vendor_code: "700865",
      coded_name:
        "**Cxld** RT INCISION AND DRAINAGE, WOUND, RIGHT THUMB [700865]",
    },
  },
  {
    id: "292603",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** RT OSTEOTOMY, METATARSAL BONE",
      normalized_name: "**cxld** rt osteotomy, metatarsal bone [750050]",
      vendor_code: "750050",
      coded_name: "**cxld** RT OSTEOTOMY, METATARSAL BONE [750050]",
    },
  },
  {
    id: "295020",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld** RT RING TRIGGER DIGIT RELEASE",
      normalized_name: "**cxld** rt ring trigger digit release [701616]",
      vendor_code: "701616",
      coded_name: "**cxld** RT RING TRIGGER DIGIT RELEASE [701616]",
    },
  },
  {
    id: "345096",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**23H AQUABLATION",
      normalized_name: "**cxld**23h aquablation [700937]",
      vendor_code: "700937",
      coded_name: "**cxld**23H AQUABLATION [700937]",
    },
  },
  {
    id: "344926",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**23H- RT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "**cxld**23h- rt total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "**cxld**23H- RT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "344752",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**AQUABLATION",
      normalized_name: "**cxld**aquablation [703889]",
      vendor_code: "703889",
      coded_name: "**cxld**AQUABLATION [703889]",
    },
  },
  {
    id: "347285",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "**cxld**cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "**cxld**CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "345305",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**CREATION, COLOSTOMY, LAPAROSCOPIC VS OPEN",
      normalized_name:
        "**cxld**creation, colostomy, laparoscopic vs open [700445]",
      vendor_code: "700445",
      coded_name: "**cxld**CREATION, COLOSTOMY, LAPAROSCOPIC VS OPEN [700445]",
    },
  },
  {
    id: "346573",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**LT REPAIR ANTERIOR TIBIAL TENDON TEAR LEFT",
      normalized_name:
        "**cxld**lt repair anterior tibial tendon tear left [701504]",
      vendor_code: "701504",
      coded_name: "**cxld**LT REPAIR ANTERIOR TIBIAL TENDON TEAR LEFT [701504]",
    },
  },
  {
    id: "343544",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**RT ARTHROSCOPY, KNEE",
      normalized_name: "**cxld**rt arthroscopy, knee [700223]",
      vendor_code: "700223",
      coded_name: "**cxld**RT ARTHROSCOPY, KNEE [700223]",
    },
  },
  {
    id: "319749",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**CXLD**RT HEEL SPUR RESECTION/ACHILLES DEBRIDEMENT",
      normalized_name:
        "**cxld**rt heel spur resection/achilles debridement [703132]",
      vendor_code: "703132",
      coded_name:
        "**CXLD**RT HEEL SPUR RESECTION/ACHILLES DEBRIDEMENT [703132]",
    },
  },
  {
    id: "347241",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**RT INDEX FINGER DIP AMPUTATION",
      normalized_name: "**cxld**rt index finger dip amputation [700129]",
      vendor_code: "700129",
      coded_name: "**cxld**RT INDEX FINGER DIP AMPUTATION [700129]",
    },
  },
  {
    id: "341087",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**RT RADIUS FRACTURE ORIF",
      normalized_name: "**cxld**rt radius fracture orif [702389]",
      vendor_code: "702389",
      coded_name: "**cxld**RT RADIUS FRACTURE ORIF [702389]",
    },
  },
  {
    id: "346881",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**RT REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC / UMBILICAL HERNIA",
      normalized_name:
        "**cxld**rt repair, hernia, inguinal, laparoscopic / umbilical hernia [700791]",
      vendor_code: "700791",
      coded_name:
        "**cxld**RT REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC / UMBILICAL HERNIA [700791]",
    },
  },
  {
    id: "342794",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld**RT TUNNEL RELEASE CUBITAL",
      normalized_name: "**cxld**rt tunnel release cubital [750030]",
      vendor_code: "750030",
      coded_name: "**cxld**RT TUNNEL RELEASE CUBITAL [750030]",
    },
  },
  {
    id: "342789",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld, need to r/s** EXCISION NASAL TURBINALE SUBMUCOSAL/ DRUG INDUCED SLEEP ENDOSCOPY",
      normalized_name:
        "**cxld, need to r/s** excision nasal turbinale submucosal/ drug induced sleep endoscopy [703355]",
      vendor_code: "703355",
      coded_name:
        "**cxld, need to r/s** EXCISION NASAL TURBINALE SUBMUCOSAL/ DRUG INDUCED SLEEP ENDOSCOPY [703355]",
    },
  },
  {
    id: "285745",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld, poss r/s** INCISION AND DRAINAGE, FOOT",
      normalized_name:
        "**cxld, poss r/s** incision and drainage, foot [703063]",
      vendor_code: "703063",
      coded_name: "**cxld, poss r/s** INCISION AND DRAINAGE, FOOT [703063]",
    },
  },
  {
    id: "315927",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld-AMA** INCISION AND DRAINAGE HAND",
      normalized_name: "**cxld-ama** incision and drainage hand [700865]",
      vendor_code: "700865",
      coded_name: "**cxld-AMA** INCISION AND DRAINAGE HAND [700865]",
    },
  },
  {
    id: "316410",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld-AMA** INPAT- LT AMPUTATION, DIGIT 2ND TOE",
      normalized_name:
        "**cxld-ama** inpat- lt amputation, digit 2nd toe [700129]",
      vendor_code: "700129",
      coded_name: "**cxld-AMA** INPAT- LT AMPUTATION, DIGIT 2ND TOE [700129]",
    },
  },
  {
    id: "281957",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxld-ill** 23 hrs - RT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**cxld-ill** 23 hrs - rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "**cxld-ill** 23 hrs - RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "316171",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**cxled** CARPAL TUNNEL RELEASE- left",
      normalized_name: "**cxled** carpal tunnel release- left [700371]",
      vendor_code: "700371",
      coded_name: "**cxled** CARPAL TUNNEL RELEASE- left [700371]",
    },
  },
  {
    id: "318067",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**DUP/DO NOT DELETE YET** DOSD- LT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**dup/do not delete yet** dosd- lt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name:
        "**DUP/DO NOT DELETE YET** DOSD- LT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "346236",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**FAKE CASE FOR SAC APPT** CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name:
        "**fake case for sac appt** cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name:
        "**FAKE CASE FOR SAC APPT** CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "94027",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anestesia-Mac Carotid Angioplasty W Stent",
      normalized_name:
        "**general anestesia-mac carotid angioplasty w stent [181742]",
      vendor_code: "181742",
      coded_name:
        "**General Anestesia-Mac Carotid Angioplasty W Stent [181742]",
    },
  },
  {
    id: "105332",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia - Embolization",
      normalized_name: "**general anesthesia - embolization [566726]",
      vendor_code: "566726",
      coded_name: "**General Anesthesia - Embolization [566726]",
    },
  },
  {
    id: "78904",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia** Ct Guide Drain Placement; Rectouterine Pouch",
      normalized_name:
        "**general anesthesia** ct guide drain placement; rectouterine pouch [566720]",
      vendor_code: "566720",
      coded_name:
        "**General Anesthesia** Ct Guide Drain Placement; Rectouterine Pouch [566720]",
    },
  },
  {
    id: "41426",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia** Ct Guide For Biopsy, Spine",
      normalized_name:
        "**general anesthesia** ct guide for biopsy, spine [566720]",
      vendor_code: "566720",
      coded_name: "**General Anesthesia** Ct Guide For Biopsy, Spine [566720]",
    },
  },
  {
    id: "36755",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia** Drainage, Biliary Percutaneous Transhepatic Catheter",
      normalized_name:
        "**general anesthesia** drainage, biliary percutaneous transhepatic catheter [566729]",
      vendor_code: "566729",
      coded_name:
        "**General Anesthesia** Drainage, Biliary Percutaneous Transhepatic Catheter [566729]",
    },
  },
  {
    id: "34663",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia** Embolization, Transcatheter Therapy,",
      normalized_name:
        "**general anesthesia** embolization, transcatheter therapy, [566732]",
      vendor_code: "566732",
      coded_name:
        "**General Anesthesia** Embolization, Transcatheter Therapy, [566732]",
    },
  },
  {
    id: "75508",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia** Guide For Liver Biopsy",
      normalized_name: "**general anesthesia** guide for liver biopsy [566720]",
      vendor_code: "566720",
      coded_name: "**General Anesthesia** Guide For Liver Biopsy [566720]",
    },
  },
  {
    id: "42039",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "55.03",
      name: "**General Anesthesia** Nephrostomy Percutaneous, Ureteral Stent Placement Left",
      normalized_name:
        "**general anesthesia** nephrostomy percutaneous, ureteral stent placement left [55.03]",
      vendor_code: null,
      coded_name:
        "**General Anesthesia** Nephrostomy Percutaneous, Ureteral Stent Placement Left [55.03]",
    },
  },
  {
    id: "35397",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia** Pulmonary Avm, Artery Embolization By Endovascular Coil",
      normalized_name:
        "**general anesthesia** pulmonary avm, artery embolization by endovascular coil [362544]",
      vendor_code: "362544",
      coded_name:
        "**General Anesthesia** Pulmonary Avm, Artery Embolization By Endovascular Coil [362544]",
    },
  },
  {
    id: "75403",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia** Right Carotid Angioplasty W Stent",
      normalized_name:
        "**general anesthesia** right carotid angioplasty w stent [181742]",
      vendor_code: "181742",
      coded_name:
        "**General Anesthesia** Right Carotid Angioplasty W Stent [181742]",
    },
  },
  {
    id: "136724",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia*** Angiogram",
      normalized_name: "**general anesthesia*** angiogram [192267]",
      vendor_code: "192267",
      coded_name: "**General Anesthesia*** Angiogram [192267]",
    },
  },
  {
    id: "41486",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia**, Ct Guide For Biopsy, Spine",
      normalized_name:
        "**general anesthesia**, ct guide for biopsy, spine [566720]",
      vendor_code: "566720",
      coded_name: "**General Anesthesia**, Ct Guide For Biopsy, Spine [566720]",
    },
  },
  {
    id: "221705",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**GENERAL ANESTHESIA**, LUMBAR PUNCTURE",
      normalized_name: "**general anesthesia**, lumbar puncture [137260]",
      vendor_code: "137260",
      coded_name: "**GENERAL ANESTHESIA**, LUMBAR PUNCTURE [137260]",
    },
  },
  {
    id: "219270",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**GENERAL ANESTHESIA**,US GUIDE FOR BIOPSY, LIVER",
      normalized_name:
        "**general anesthesia**,us guide for biopsy, liver [566720]",
      vendor_code: "566720",
      coded_name: "**GENERAL ANESTHESIA**,US GUIDE FOR BIOPSY, LIVER [566720]",
    },
  },
  {
    id: "213081",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**GENERAL ANESTHESIA**KYPHOPLASTY, T12",
      normalized_name: "**general anesthesia**kyphoplasty, t12 [204850]",
      vendor_code: "204850",
      coded_name: "**GENERAL ANESTHESIA**KYPHOPLASTY, T12 [204850]",
    },
  },
  {
    id: "188683",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**General Anesthesia**Us Guide Renal Biopsy",
      normalized_name: "**general anesthesia**us guide renal biopsy [566720]",
      vendor_code: "566720",
      coded_name: "**General Anesthesia**Us Guide Renal Biopsy [566720]",
    },
  },
  {
    id: "196121",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**GENERAL ANESTHESIA- CERBRAL ANGIOGRAM WITH EMOBOLIZATION",
      normalized_name:
        "**general anesthesia- cerbral angiogram with emobolization [142201]",
      vendor_code: "142201",
      coded_name:
        "**GENERAL ANESTHESIA- CERBRAL ANGIOGRAM WITH EMOBOLIZATION [142201]",
    },
  },
  {
    id: "318092",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**hold - need new time**EGD WITH ANESTHESIA",
      normalized_name: "**hold - need new time**egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**hold - need new time**EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "342637",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**hold for charting**CYSTOLITHOLAPAXY",
      normalized_name: "**hold for charting**cystolitholapaxy [700960]",
      vendor_code: "700960",
      coded_name: "**hold for charting**CYSTOLITHOLAPAXY [700960]",
    },
  },
  {
    id: "341120",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**hold fpr Mickie** COLONOSCOPY WITH ANESTHESIA IN THE OR",
      normalized_name:
        "**hold fpr mickie** colonoscopy with anesthesia in the or [702115]",
      vendor_code: "702115",
      coded_name:
        "**hold fpr Mickie** COLONOSCOPY WITH ANESTHESIA IN THE OR [702115]",
    },
  },
  {
    id: "318269",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLD** 23H ROBOT ASSISTED,ABLATION,PROSTATE,USING WATERJET,WITH IMAGING GUIDANCE",
      normalized_name:
        "**hold** 23h robot assisted,ablation,prostate,using waterjet,with imaging guidance [703889]",
      vendor_code: "703889",
      coded_name:
        "**HOLD** 23H ROBOT ASSISTED,ABLATION,PROSTATE,USING WATERJET,WITH IMAGING GUIDANCE [703889]",
    },
  },
  {
    id: "318279",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**hold** COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "**hold** colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**hold** COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "285733",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**hold** COLOSTOMY REVERSAL",
      normalized_name: "**hold** colostomy reversal [703204]",
      vendor_code: "703204",
      coded_name: "**hold** COLOSTOMY REVERSAL [703204]",
    },
  },
  {
    id: "280140",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLD**RT URETEROSCOPY with thulium laser",
      normalized_name: "**hold**rt ureteroscopy with thulium laser [701652]",
      vendor_code: "701652",
      coded_name: "**HOLD**RT URETEROSCOPY with thulium laser [701652]",
    },
  },
  {
    id: "341500",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLDING cxld** APPENDECTOMY, LAPAROSCOPIC",
      normalized_name: "**holding cxld** appendectomy, laparoscopic [700169]",
      vendor_code: "700169",
      coded_name: "**HOLDING cxld** APPENDECTOMY, LAPAROSCOPIC [700169]",
    },
  },
  {
    id: "343741",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLDING cxld** Open gastrostomy tube placement",
      normalized_name:
        "**holding cxld** open gastrostomy tube placement [700628]",
      vendor_code: "700628",
      coded_name: "**HOLDING cxld** Open gastrostomy tube placement [700628]",
    },
  },
  {
    id: "262247",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLDING SUMBISSION OF CASE UNTIL CARDIO CLEARED ** Bilateral Breast Mastectomy, Right Sentinel Lymph Node Biopsy",
      normalized_name:
        "**holding sumbission of case until cardio cleared ** bilateral breast mastectomy, right sentinel lymph node biopsy [702016]",
      vendor_code: "702016",
      coded_name:
        "**HOLDING SUMBISSION OF CASE UNTIL CARDIO CLEARED ** Bilateral Breast Mastectomy, Right Sentinel Lymph Node Biopsy [702016]",
    },
  },
  {
    id: "313013",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLDING** CHOLECYSTECTOMY, LAPAROSCOPIC, possible intraoperative cholangiogram",
      normalized_name:
        "**holding** cholecystectomy, laparoscopic, possible intraoperative cholangiogram [701764]",
      vendor_code: "701764",
      coded_name:
        "**HOLDING** CHOLECYSTECTOMY, LAPAROSCOPIC, possible intraoperative cholangiogram [701764]",
    },
  },
  {
    id: "313504",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**holding** LEFT COLECTOMY, LAPAROSCOPIC, POSS OPEN",
      normalized_name:
        "**holding** left colectomy, laparoscopic, poss open [702286]",
      vendor_code: "702286",
      coded_name:
        "**holding** LEFT COLECTOMY, LAPAROSCOPIC, POSS OPEN [702286]",
    },
  },
  {
    id: "263994",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLDING** RT TIBIAL PILON FRACTURE ORIF, EX FIX REMOVAL",
      normalized_name:
        "**holding** rt tibial pilon fracture orif, ex fix removal [700144]",
      vendor_code: "700144",
      coded_name:
        "**HOLDING** RT TIBIAL PILON FRACTURE ORIF, EX FIX REMOVAL [700144]",
    },
  },
  {
    id: "301048",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**HOLDING- DL to r/s** COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "**holding- dl to r/s** colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**HOLDING- DL to r/s** COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "280177",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**MICKIE TO VERIFY 23HR LAPARSCOPIC TUBAL LIGATION BY BILATERAL PARTIAL SALPINECTOMY",
      normalized_name:
        "**mickie to verify 23hr laparscopic tubal ligation by bilateral partial salpinectomy [701349]",
      vendor_code: "701349",
      coded_name:
        "**MICKIE TO VERIFY 23HR LAPARSCOPIC TUBAL LIGATION BY BILATERAL PARTIAL SALPINECTOMY [701349]",
    },
  },
  {
    id: "177718",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**Myelomeningocele Repair",
      normalized_name: "**myelomeningocele repair [106326]",
      vendor_code: "106326",
      coded_name: "**Myelomeningocele Repair [106326]",
    },
  },
  {
    id: "286443",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**n/s CXLD** LT EXTRACTION, CATARACT, WITH IOL INSERTION",
      normalized_name:
        "**n/s cxld** lt extraction, cataract, with iol insertion [700378]",
      vendor_code: "700378",
      coded_name:
        "**n/s CXLD** LT EXTRACTION, CATARACT, WITH IOL INSERTION [700378]",
    },
  },
  {
    id: "281017",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEED CPT CODES** ARTHROSCOPY, SHOULDER; RCR; EXCISION DISTAL CLAVICAL",
      normalized_name:
        "**need cpt codes** arthroscopy, shoulder; rcr; excision distal clavical [700228]",
      vendor_code: "700228",
      coded_name:
        "**NEED CPT CODES** ARTHROSCOPY, SHOULDER; RCR; EXCISION DISTAL CLAVICAL [700228]",
    },
  },
  {
    id: "282427",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need h/w details** LT REMOVAL, HARDWARE, LOWER EXTREMITY",
      normalized_name:
        "**need h/w details** lt removal, hardware, lower extremity [750131]",
      vendor_code: "750131",
      coded_name:
        "**need h/w details** LT REMOVAL, HARDWARE, LOWER EXTREMITY [750131]",
    },
  },
  {
    id: "280998",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need h/w vendor** 23hrs - RT TOTAL HIP REPLACEMENT. Posterior approach",
      normalized_name:
        "**need h/w vendor** 23hrs - rt total hip replacement. posterior approach [701564]",
      vendor_code: "701564",
      coded_name:
        "**need h/w vendor** 23hrs - RT TOTAL HIP REPLACEMENT. Posterior approach [701564]",
    },
  },
  {
    id: "322584",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need ICD-10 code**COLONOSCOPY WITH ANESTHESIA IN THE OR",
      normalized_name:
        "**need icd-10 code**colonoscopy with anesthesia in the or [702115]",
      vendor_code: "702115",
      coded_name:
        "**need ICD-10 code**COLONOSCOPY WITH ANESTHESIA IN THE OR [702115]",
    },
  },
  {
    id: "318278",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need Ins** COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "**need ins** colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**need Ins** COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "311576",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need ins** EGD WITH ANESTHESIA",
      normalized_name: "**need ins** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**need ins** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "291310",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need ins** EGD/COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "**need ins** egd/colonoscopy with anesthesia [702109]",
      vendor_code: "702109",
      coded_name: "**need ins** EGD/COLONOSCOPY WITH ANESTHESIA [702109]",
    },
  },
  {
    id: "281939",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEED SAC APPOINT**DOSD - LT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "**need sac appoint**dosd - lt total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "**NEED SAC APPOINT**DOSD - LT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "281937",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEED SAC APPOINTMENT **23HR LT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**need sac appointment **23hr lt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name:
        "**NEED SAC APPOINTMENT **23HR LT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "286493",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEED SAC APPOINTMENT 23hr RT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**need sac appointment 23hr rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name:
        "**NEED SAC APPOINTMENT 23hr RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "281200",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEED SAC DATE -23h LT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**need sac date -23h lt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "**NEED SAC DATE -23h LT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "283838",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to be r/s** INSERTION,NEUROSTIMULATOR,HYPOGLOSSAL",
      normalized_name:
        "**need to be r/s** insertion,neurostimulator,hypoglossal [703337]",
      vendor_code: "703337",
      coded_name:
        "**need to be r/s** INSERTION,NEUROSTIMULATOR,HYPOGLOSSAL [703337]",
    },
  },
  {
    id: "278534",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s w/ office** LT REPAIR, TENDON, HAND - index finger FDP repair",
      normalized_name:
        "**need to r/s w/ office** lt repair, tendon, hand - index finger fdp repair [701505]",
      vendor_code: "701505",
      coded_name:
        "**need to r/s w/ office** LT REPAIR, TENDON, HAND - index finger FDP repair [701505]",
    },
  },
  {
    id: "284421",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** 23 hrs - RT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**need to r/s** 23 hrs - rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "**need to r/s** 23 hrs - RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "284418",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** 23H- LT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**need to r/s** 23h- lt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "**need to r/s** 23H- LT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "284538",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** 23H- RT TOTAL KNEE REPLACEMENT",
      normalized_name:
        "**need to r/s** 23h- rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "**need to r/s** 23H- RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "284430",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** 23HR - HYSTERECTOMY, TOTAL, LAPAROSCOPIC, WITH BILATERAL SALPINGECTOMY OMNI CO2 LASER",
      normalized_name:
        "**need to r/s** 23hr - hysterectomy, total, laparoscopic, with bilateral salpingectomy omni co2 laser [703240]",
      vendor_code: "703240",
      coded_name:
        "**need to r/s** 23HR - HYSTERECTOMY, TOTAL, LAPAROSCOPIC, WITH BILATERAL SALPINGECTOMY OMNI CO2 LASER [703240]",
    },
  },
  {
    id: "284425",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** 23hr - LT TOTAL HIP REPLACEMENT",
      normalized_name:
        "**need to r/s** 23hr - lt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "**need to r/s** 23hr - LT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "284612",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** ADMIT - LT INCISION AND DRAINAGE, HIP",
      normalized_name:
        "**need to r/s** admit - lt incision and drainage, hip [702418]",
      vendor_code: "702418",
      coded_name:
        "**need to r/s** ADMIT - LT INCISION AND DRAINAGE, HIP [702418]",
    },
  },
  {
    id: "284590",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** BILATERAL HYDROCELECTOMY",
      normalized_name: "**need to r/s** bilateral hydrocelectomy [700817]",
      vendor_code: "700817",
      coded_name: "**need to r/s** BILATERAL HYDROCELECTOMY [700817]",
    },
  },
  {
    id: "284462",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "**need to r/s** cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "**need to r/s** CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "284575",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** CYSTOLITHOLAPAXY",
      normalized_name: "**need to r/s** cystolitholapaxy [700960]",
      vendor_code: "700960",
      coded_name: "**need to r/s** CYSTOLITHOLAPAXY [700960]",
    },
  },
  {
    id: "284540",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** DOSD - LT TOTAL SHOULDER REPLACEMENT",
      normalized_name:
        "**need to r/s** dosd - lt total shoulder replacement [701572]",
      vendor_code: "701572",
      coded_name:
        "**need to r/s** DOSD - LT TOTAL SHOULDER REPLACEMENT [701572]",
    },
  },
  {
    id: "284529",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** DOSD- LT TOTAL KNEE REPLACEMENT WITH HARDWARE REMOVAL",
      normalized_name:
        "**need to r/s** dosd- lt total knee replacement with hardware removal [701568]",
      vendor_code: "701568",
      coded_name:
        "**need to r/s** DOSD- LT TOTAL KNEE REPLACEMENT WITH HARDWARE REMOVAL [701568]",
    },
  },
  {
    id: "284570",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** INSERTION, STENT, URETER, CYSTOSCOPIC",
      normalized_name:
        "**need to r/s** insertion, stent, ureter, cystoscopic [700518]",
      vendor_code: "700518",
      coded_name:
        "**need to r/s** INSERTION, STENT, URETER, CYSTOSCOPIC [700518]",
    },
  },
  {
    id: "284435",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT ARTHROSCOPY, HIP",
      normalized_name: "**need to r/s** lt arthroscopy, hip [700222]",
      vendor_code: "700222",
      coded_name: "**need to r/s** LT ARTHROSCOPY, HIP [700222]",
    },
  },
  {
    id: "280120",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT CARPAL TUNNEL RELEASE",
      normalized_name: "**need to r/s** lt carpal tunnel release [700371]",
      vendor_code: "700371",
      coded_name: "**need to r/s** LT CARPAL TUNNEL RELEASE [700371]",
    },
  },
  {
    id: "284563",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT DEQUERVAIN'S RELEASE",
      normalized_name: "**need to r/s** lt dequervain's release [700544]",
      vendor_code: "700544",
      coded_name: "**need to r/s** LT DEQUERVAIN'S RELEASE [700544]",
    },
  },
  {
    id: "284528",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT MIDDLE FINGER EXCISION, MASS, FINGER",
      normalized_name:
        "**need to r/s** lt middle finger excision, mass, finger [702154]",
      vendor_code: "702154",
      coded_name:
        "**need to r/s** LT MIDDLE FINGER EXCISION, MASS, FINGER [702154]",
    },
  },
  {
    id: "284450",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT ORIF, FRACTURE, PHALANX, FINGER",
      normalized_name:
        "**need to r/s** lt orif, fracture, phalanx, finger [700682]",
      vendor_code: "700682",
      coded_name: "**need to r/s** LT ORIF, FRACTURE, PHALANX, FINGER [700682]",
    },
  },
  {
    id: "284452",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT ORIF, FRACTURE, RADIUS, DISTAL",
      normalized_name:
        "**need to r/s** lt orif, fracture, radius, distal [703367]",
      vendor_code: "703367",
      coded_name: "**need to r/s** LT ORIF, FRACTURE, RADIUS, DISTAL [703367]",
    },
  },
  {
    id: "292916",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT shoulder arthroscopic Bankart repair, possible remplissage",
      normalized_name:
        "**need to r/s** lt shoulder arthroscopic bankart repair, possible remplissage [700228]",
      vendor_code: "700228",
      coded_name:
        "**need to r/s** LT shoulder arthroscopic Bankart repair, possible remplissage [700228]",
    },
  },
  {
    id: "284440",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT TUNNEL RELEASE CUBITAL",
      normalized_name: "**need to r/s** lt tunnel release cubital [750030]",
      vendor_code: "750030",
      coded_name: "**need to r/s** LT TUNNEL RELEASE CUBITAL [750030]",
    },
  },
  {
    id: "284574",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** LT WRIST REMOVAL, HARDWARE",
      normalized_name: "**need to r/s** lt wrist removal, hardware [750132]",
      vendor_code: "750132",
      coded_name: "**need to r/s** LT WRIST REMOVAL, HARDWARE [750132]",
    },
  },
  {
    id: "284429",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** NOVASURE ABLATION, ENDOMETRIUM, WITH D&C HYSTEROSCOPY",
      normalized_name:
        "**need to r/s** novasure ablation, endometrium, with d&c hysteroscopy [702255]",
      vendor_code: "702255",
      coded_name:
        "**need to r/s** NOVASURE ABLATION, ENDOMETRIUM, WITH D&C HYSTEROSCOPY [702255]",
    },
  },
  {
    id: "284438",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** NOVASURE/MYOSURE ABLATION, ENDOMETRIUM, WITH D&C HYSTEROSCOPY",
      normalized_name:
        "**need to r/s** novasure/myosure ablation, endometrium, with d&c hysteroscopy [702255]",
      vendor_code: "702255",
      coded_name:
        "**need to r/s** NOVASURE/MYOSURE ABLATION, ENDOMETRIUM, WITH D&C HYSTEROSCOPY [702255]",
    },
  },
  {
    id: "284569",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** PHOTOVAPORIZATION, PROSTATE Thulium laser enucleation of the prostate",
      normalized_name:
        "**need to r/s** photovaporization, prostate thulium laser enucleation of the prostate [702706]",
      vendor_code: "702706",
      coded_name:
        "**need to r/s** PHOTOVAPORIZATION, PROSTATE Thulium laser enucleation of the prostate [702706]",
    },
  },
  {
    id: "284619",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** possible 10/20 or 10/21 1500 INJECTION, STEROID, EPIDURAL, TRANSFORAMINAL APPROACH",
      normalized_name:
        "**need to r/s** possible 10/20 or 10/21 1500 injection, steroid, epidural, transforaminal approach [702284]",
      vendor_code: "702284",
      coded_name:
        "**need to r/s** possible 10/20 or 10/21 1500 INJECTION, STEROID, EPIDURAL, TRANSFORAMINAL APPROACH [702284]",
    },
  },
  {
    id: "284620",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** possible 10/20/or 10/21 1530 INJECTION, JOINT OR BURSA",
      normalized_name:
        "**need to r/s** possible 10/20/or 10/21 1530 injection, joint or bursa [703113]",
      vendor_code: "703113",
      coded_name:
        "**need to r/s** possible 10/20/or 10/21 1530 INJECTION, JOINT OR BURSA [703113]",
    },
  },
  {
    id: "284622",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** possible 10/28 INJECTION, STEROID, EPIDURAL, TRANSFORAMINAL APPROACH, Right L5 TFESI",
      normalized_name:
        "**need to r/s** possible 10/28 injection, steroid, epidural, transforaminal approach, right l5 tfesi [702284]",
      vendor_code: "702284",
      coded_name:
        "**need to r/s** possible 10/28 INJECTION, STEROID, EPIDURAL, TRANSFORAMINAL APPROACH, Right L5 TFESI [702284]",
    },
  },
  {
    id: "284456",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** REPAIR, TENDON, HAND - right epl tendon repair, possible radial sensory nerve repair",
      normalized_name:
        "**need to r/s** repair, tendon, hand - right epl tendon repair, possible radial sensory nerve repair [701505]",
      vendor_code: "701505",
      coded_name:
        "**need to r/s** REPAIR, TENDON, HAND - right epl tendon repair, possible radial sensory nerve repair [701505]",
    },
  },
  {
    id: "284448",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** RT 1ST DIGIT AMPUTATION MPJ; POTENTIAL RT PARTIAL FIRST RAY AMPUTATION",
      normalized_name:
        "**need to r/s** rt 1st digit amputation mpj; potential rt partial first ray amputation [700129]",
      vendor_code: "700129",
      coded_name:
        "**need to r/s** RT 1ST DIGIT AMPUTATION MPJ; POTENTIAL RT PARTIAL FIRST RAY AMPUTATION [700129]",
    },
  },
  {
    id: "284520",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** RT CARPAL TUNNEL RELEASE",
      normalized_name: "**need to r/s** rt carpal tunnel release [700371]",
      vendor_code: "700371",
      coded_name: "**need to r/s** RT CARPAL TUNNEL RELEASE [700371]",
    },
  },
  {
    id: "284434",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** RT CARPAL TUNNEL RELEASE- right",
      normalized_name:
        "**need to r/s** rt carpal tunnel release- right [700371]",
      vendor_code: "700371",
      coded_name: "**need to r/s** RT CARPAL TUNNEL RELEASE- right [700371]",
    },
  },
  {
    id: "284451",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** TRIGGER DIGIT RELEASE- Bilateral thumb trigger release",
      normalized_name:
        "**need to r/s** trigger digit release- bilateral thumb trigger release [701616]",
      vendor_code: "701616",
      coded_name:
        "**need to r/s** TRIGGER DIGIT RELEASE- Bilateral thumb trigger release [701616]",
    },
  },
  {
    id: "284568",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s** TUR-BT (TRANSURETHRAL RESECTION OF BLADDER TUMOR)",
      normalized_name:
        "**need to r/s** tur-bt (transurethral resection of bladder tumor) [701590]",
      vendor_code: "701590",
      coded_name:
        "**need to r/s** TUR-BT (TRANSURETHRAL RESECTION OF BLADDER TUMOR) [701590]",
    },
  },
  {
    id: "284428",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s**23HR RT TOTAL HIP REPLACEMENT",
      normalized_name: "**need to r/s**23hr rt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "**need to r/s**23HR RT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "284556",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s**RT MENISCECTOMY, KNEE, ARTHROSCOPIC",
      normalized_name:
        "**need to r/s**rt meniscectomy, knee, arthroscopic [700206]",
      vendor_code: "700206",
      coded_name: "**need to r/s**RT MENISCECTOMY, KNEE, ARTHROSCOPIC [700206]",
    },
  },
  {
    id: "284542",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to r/s**RT REPAIR, ABDUCTOR TENDON, HIP",
      normalized_name:
        "**need to r/s**rt repair, abductor tendon, hip [702139]",
      vendor_code: "702139",
      coded_name: "**need to r/s**RT REPAIR, ABDUCTOR TENDON, HIP [702139]",
    },
  },
  {
    id: "284623",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to resch CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "**need to resch cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "**need to resch CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "279169",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need to verify insurance PARATHYROIDECTOMY",
      normalized_name: "**need to verify insurance parathyroidectomy [750080]",
      vendor_code: "750080",
      coded_name: "**need to verify insurance PARATHYROIDECTOMY [750080]",
    },
  },
  {
    id: "283072",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**need vendor info** 23HR LT TOTAL HIP REPLACEMENT",
      normalized_name:
        "**need vendor info** 23hr lt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "**need vendor info** 23HR LT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "280258",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEED VENDOR** LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HUMERUS, PROXIMAL",
      normalized_name:
        "**need vendor** lt open reduction internal fixation (orif), fracture, humerus, proximal [702056]",
      vendor_code: "702056",
      coded_name:
        "**NEED VENDOR** LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HUMERUS, PROXIMAL [702056]",
    },
  },
  {
    id: "302585",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEEDING DOS** LT REPAIR, TENDON, ACHILLES",
      normalized_name: "**needing dos** lt repair, tendon, achilles [701499]",
      vendor_code: "701499",
      coded_name: "**NEEDING DOS** LT REPAIR, TENDON, ACHILLES [701499]",
    },
  },
  {
    id: "302571",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**NEEDING DOS** RT FUSION, JOINT, FOOT",
      normalized_name: "**needing dos** rt fusion, joint, foot [701994]",
      vendor_code: "701994",
      coded_name: "**NEEDING DOS** RT FUSION, JOINT, FOOT [701994]",
    },
  },
  {
    id: "282999",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**needing sac & joing class** 23 hrs - RT TOTAL HIP REPLACEMENT",
      normalized_name:
        "**needing sac & joing class** 23 hrs - rt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name:
        "**needing sac & joing class** 23 hrs - RT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "279387",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**needs PPL** REPAIR, HERNIA, UMBILICAL, LAPAROSCOPIC",
      normalized_name:
        "**needs ppl** repair, hernia, umbilical, laparoscopic [700798]",
      vendor_code: "700798",
      coded_name:
        "**needs PPL** REPAIR, HERNIA, UMBILICAL, LAPAROSCOPIC [700798]",
    },
  },
  {
    id: "283814",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**needs SAC-office notified** 23hrs - RT TOTAL HIP REPLACEMENT",
      normalized_name:
        "**needs sac-office notified** 23hrs - rt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name:
        "**needs SAC-office notified** 23hrs - RT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "280418",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**noshow need to r/s** LT REPAIR, TENDON THUMB AT WRIST",
      normalized_name:
        "**noshow need to r/s** lt repair, tendon thumb at wrist [701510]",
      vendor_code: "701510",
      coded_name:
        "**noshow need to r/s** LT REPAIR, TENDON THUMB AT WRIST [701510]",
    },
  },
  {
    id: "278475",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**noshow sbto for r/s** RT THUMB TRIGGER DIGIT RELEASE",
      normalized_name:
        "**noshow sbto for r/s** rt thumb trigger digit release [701616]",
      vendor_code: "701616",
      coded_name:
        "**noshow sbto for r/s** RT THUMB TRIGGER DIGIT RELEASE [701616]",
    },
  },
  {
    id: "283036",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**OPS working on discharging patient** 23H- RT TOTAL SHOULDER REPLACEMENT",
      normalized_name:
        "**ops working on discharging patient** 23h- rt total shoulder replacement [701572]",
      vendor_code: "701572",
      coded_name:
        "**OPS working on discharging patient** 23H- RT TOTAL SHOULDER REPLACEMENT [701572]",
    },
  },
  {
    id: "278293",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**pending mgr approval** SIGMOIDOSCOPY, FLEXIBLE",
      normalized_name:
        "**pending mgr approval** sigmoidoscopy, flexible [702091]",
      vendor_code: "702091",
      coded_name: "**pending mgr approval** SIGMOIDOSCOPY, FLEXIBLE [702091]",
    },
  },
  {
    id: "309136",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**PICS LIVE CASE**PERC BLN VALVULOPLASTY AORTIC VALUE paravalvular leak closure, s/p TAVR W/ANESTHESIA",
      normalized_name:
        "**pics live case**perc bln valvuloplasty aortic value paravalvular leak closure, s/p tavr w/anesthesia [angio 157a]",
      vendor_code: "ANGIO 157A",
      coded_name:
        "**PICS LIVE CASE**PERC BLN VALVULOPLASTY AORTIC VALUE paravalvular leak closure, s/p TAVR W/ANESTHESIA [ANGIO 157A]",
    },
  },
  {
    id: "309142",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**PICS LIVE CASE**TMVIV (HYBRID TRANSCATHETER MITRAL VALVE IN VALVE REPLACEMENT) GENERAL ANESTHESIA W/TEE GUIDANCE(psb)",
      normalized_name:
        "**pics live case**tmviv (hybrid transcatheter mitral valve in valve replacement) general anesthesia w/tee guidance(psb) [cvt 204]",
      vendor_code: "CVT 204",
      coded_name:
        "**PICS LIVE CASE**TMVIV (HYBRID TRANSCATHETER MITRAL VALVE IN VALVE REPLACEMENT) GENERAL ANESTHESIA W/TEE GUIDANCE(psb) [CVT 204]",
    },
  },
  {
    id: "309141",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**PICS LIVE CASE**TMVIV (TRANSCATHETER MITRAL VALVE IN VALVE REPLACEMENT) GENERAL ANESTHESIA W/TEE GUIDANCE",
      normalized_name:
        "**pics live case**tmviv (transcatheter mitral valve in valve replacement) general anesthesia w/tee guidance [cc 018]",
      vendor_code: "CC 018",
      coded_name:
        "**PICS LIVE CASE**TMVIV (TRANSCATHETER MITRAL VALVE IN VALVE REPLACEMENT) GENERAL ANESTHESIA W/TEE GUIDANCE [CC 018]",
    },
  },
  {
    id: "45377",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "81.62",
      name: "**Placement Of Halo Under Conscious Sedation** This Is Not An Open Surgical Procedure",
      normalized_name:
        "**placement of halo under conscious sedation** this is not an open surgical procedure [81.62]",
      vendor_code: null,
      coded_name:
        "**Placement Of Halo Under Conscious Sedation** This Is Not An Open Surgical Procedure [81.62]",
    },
  },
  {
    id: "345163",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**Provider conflict**EGD WITH ANESTHESIA",
      normalized_name: "**provider conflict**egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**Provider conflict**EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "278408",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**provider/time conflict** EGD WITH ANESTHESIA",
      normalized_name:
        "**provider/time conflict** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**provider/time conflict** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "317103",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**PSEUDOCHOLINESTERASE DEFICIENCY may move to main or**RT CARPAL TUNNEL RELEASE",
      normalized_name:
        "**pseudocholinesterase deficiency may move to main or**rt carpal tunnel release [700371]",
      vendor_code: "700371",
      coded_name:
        "**PSEUDOCHOLINESTERASE DEFICIENCY may move to main or**RT CARPAL TUNNEL RELEASE [700371]",
    },
  },
  {
    id: "280186",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**pt cxl**CHOLECYSTECTOMY, LAPAROSCOPIC, WITH POSS CHOLANGIOGRAM",
      normalized_name:
        "**pt cxl**cholecystectomy, laparoscopic, with poss cholangiogram [700404]",
      vendor_code: "700404",
      coded_name:
        "**pt cxl**CHOLECYSTECTOMY, LAPAROSCOPIC, WITH POSS CHOLANGIOGRAM [700404]",
    },
  },
  {
    id: "283310",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**pt cxld- r/s?**RT CARPAL TUNNEL RELEASE",
      normalized_name: "**pt cxld- r/s?**rt carpal tunnel release [700371]",
      vendor_code: "700371",
      coded_name: "**pt cxld- r/s?**RT CARPAL TUNNEL RELEASE [700371]",
    },
  },
  {
    id: "316180",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s as per Diane, she will call back to confirm 1/11 SR** CHOLECYSTECTOMY, LAPAROSCOPIC, WITH CHOLANGIOGRAM",
      normalized_name:
        "**r/s as per diane, she will call back to confirm 1/11 sr** cholecystectomy, laparoscopic, with cholangiogram [700404]",
      vendor_code: "700404",
      coded_name:
        "**r/s as per Diane, she will call back to confirm 1/11 SR** CHOLECYSTECTOMY, LAPAROSCOPIC, WITH CHOLANGIOGRAM [700404]",
    },
  },
  {
    id: "277786",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** 23H- LT TOTAL HIP REPLACEMENT",
      normalized_name: "**r/s** 23h- lt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "**r/s** 23H- LT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "278775",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** 23H- OPEN VENTRAL W/ COMPONENT SEPARATION",
      normalized_name:
        "**r/s** 23h- open ventral w/ component separation [700799]",
      vendor_code: "700799",
      coded_name: "**r/s** 23H- OPEN VENTRAL W/ COMPONENT SEPARATION [700799]",
    },
  },
  {
    id: "282038",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** ADMIT - RT TOTAL KNEE REVISION",
      normalized_name: "**r/s** admit - rt total knee revision [701328]",
      vendor_code: "701328",
      coded_name: "**r/s** ADMIT - RT TOTAL KNEE REVISION [701328]",
    },
  },
  {
    id: "282641",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** Lap Inguinal Hernia / POSS RT W/ MESH",
      normalized_name: "**r/s** lap inguinal hernia / poss rt w/ mesh [702313]",
      vendor_code: "702313",
      coded_name: "**r/s** Lap Inguinal Hernia / POSS RT W/ MESH [702313]",
    },
  },
  {
    id: "280726",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** LT EPIDIDYMECTOMY",
      normalized_name: "**r/s** lt epididymectomy [700598]",
      vendor_code: "700598",
      coded_name: "**r/s** LT EPIDIDYMECTOMY [700598]",
    },
  },
  {
    id: "282078",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HUMERUS, PROXIMAL",
      normalized_name:
        "**r/s** lt open reduction internal fixation (orif), fracture, humerus, proximal [702056]",
      vendor_code: "702056",
      coded_name:
        "**r/s** LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HUMERUS, PROXIMAL [702056]",
    },
  },
  {
    id: "281496",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** LT TOTAL SHOULDER REPLACEMENT",
      normalized_name: "**r/s** lt total shoulder replacement [701572]",
      vendor_code: "701572",
      coded_name: "**r/s** LT TOTAL SHOULDER REPLACEMENT [701572]",
    },
  },
  {
    id: "278989",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** REPAIR, HERNIA, UMBILICAL",
      normalized_name: "**r/s** repair, hernia, umbilical [700797]",
      vendor_code: "700797",
      coded_name: "**r/s** REPAIR, HERNIA, UMBILICAL [700797]",
    },
  },
  {
    id: "281779",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** REPAIR, HERNIA, UMBILICAL, LAPAROSCOPIC",
      normalized_name:
        "**r/s** repair, hernia, umbilical, laparoscopic [700798]",
      vendor_code: "700798",
      coded_name: "**r/s** REPAIR, HERNIA, UMBILICAL, LAPAROSCOPIC [700798]",
    },
  },
  {
    id: "282029",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** RT MENISCECTOMY, KNEE, ARTHROSCOPIC",
      normalized_name: "**r/s** rt meniscectomy, knee, arthroscopic [700206]",
      vendor_code: "700206",
      coded_name: "**r/s** RT MENISCECTOMY, KNEE, ARTHROSCOPIC [700206]",
    },
  },
  {
    id: "315031",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**r/s** WIDE LOCAL EXCISION OF SCALP MELANOMA SENTINEL LYMPH NODE BIOPSY (NECK)",
      normalized_name:
        "**r/s** wide local excision of scalp melanoma sentinel lymph node biopsy (neck) [702017]",
      vendor_code: "702017",
      coded_name:
        "**r/s** WIDE LOCAL EXCISION OF SCALP MELANOMA SENTINEL LYMPH NODE BIOPSY (NECK) [702017]",
    },
  },
  {
    id: "282295",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**Rachael-time conflict** COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "**rachael-time conflict** colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name:
        "**Rachael-time conflict** COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "264820",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**RE SCD**COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "**re scd**colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**RE SCD**COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "41878",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "02.34",
      name: "**Repair Of A Cervical Meningocele** & Vp Shunt",
      normalized_name:
        "**repair of a cervical meningocele** & vp shunt [02.34]",
      vendor_code: null,
      coded_name: "**Repair Of A Cervical Meningocele** & Vp Shunt [02.34]",
    },
  },
  {
    id: "176540",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**Repair Of Myelomeningocele",
      normalized_name: "**repair of myelomeningocele [165049]",
      vendor_code: "165049",
      coded_name: "**Repair Of Myelomeningocele [165049]",
    },
  },
  {
    id: "278479",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**RESCH WITH OFFICE** THULIUM LASER ENUCLEATION OF THE PROSTATE",
      normalized_name:
        "**resch with office** thulium laser enucleation of the prostate [702706]",
      vendor_code: "702706",
      coded_name:
        "**RESCH WITH OFFICE** THULIUM LASER ENUCLEATION OF THE PROSTATE [702706]",
    },
  },
  {
    id: "344841",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**RESCHEDULING**EGD WITH ANESTHESIA",
      normalized_name: "**rescheduling**egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**RESCHEDULING**EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "310447",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**RESEARCH TRILUMINATE** TRICUSPIDE VALVE INTERVENTION W/ANESTHESIA with TEE",
      normalized_name:
        "**research triluminate** tricuspide valve intervention w/anesthesia with tee [angio 158a]",
      vendor_code: "ANGIO 158A",
      coded_name:
        "**RESEARCH TRILUMINATE** TRICUSPIDE VALVE INTERVENTION W/ANESTHESIA with TEE [ANGIO 158A]",
    },
  },
  {
    id: "310332",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**RESEARCH**HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE(psb)",
      normalized_name:
        "**research**hybrid transfemoral percutaneous aortic valve(psb) [cvt 204]",
      vendor_code: "CVT 204",
      coded_name:
        "**RESEARCH**HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE(psb) [CVT 204]",
    },
  },
  {
    id: "310331",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**RESEARCH**TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT)",
      normalized_name:
        "**research**tavr (transcatheter aortic valve replacement) [cc 018]",
      vendor_code: "CC 018",
      coded_name:
        "**RESEARCH**TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT) [CC 018]",
    },
  },
  {
    id: "310514",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**SHOCKWAVE**HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE(psb)",
      normalized_name:
        "**shockwave**hybrid transfemoral percutaneous aortic valve(psb) [cvt 204]",
      vendor_code: "CVT 204",
      coded_name:
        "**SHOCKWAVE**HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE(psb) [CVT 204]",
    },
  },
  {
    id: "310513",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**SHOCKWAVE**TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT)",
      normalized_name:
        "**shockwave**tavr (transcatheter aortic valve replacement) [cc 018]",
      vendor_code: "CC 018",
      coded_name:
        "**SHOCKWAVE**TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT) [CC 018]",
    },
  },
  {
    id: "220849",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**THIS IS A DIRECT/INDIRECT BYPASS",
      normalized_name: "**this is a direct/indirect bypass [165070]",
      vendor_code: "165070",
      coded_name: "**THIS IS A DIRECT/INDIRECT BYPASS [165070]",
    },
  },
  {
    id: "102689",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**This Is A Generator Replacement**",
      normalized_name: "**this is a generator replacement** [192681]",
      vendor_code: "192681",
      coded_name: "**This Is A Generator Replacement** [192681]",
    },
  },
  {
    id: "207644",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**THIS IS A REPAIR OF A LUMBAR MYELOMENINGOCELE**",
      normalized_name:
        "**this is a repair of a lumbar myelomeningocele** [284230]",
      vendor_code: "284230",
      coded_name: "**THIS IS A REPAIR OF A LUMBAR MYELOMENINGOCELE** [284230]",
    },
  },
  {
    id: "78592",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**This Is An Indirect Bypass (Edas)**",
      normalized_name: "**this is an indirect bypass (edas)** [165070]",
      vendor_code: "165070",
      coded_name: "**This Is An Indirect Bypass (Edas)** [165070]",
    },
  },
  {
    id: "44789",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "**This Is An Intrathecal Pain Pump Placement**",
      normalized_name: "**this is an intrathecal pain pump placement** [03.92]",
      vendor_code: null,
      coded_name: "**This Is An Intrathecal Pain Pump Placement** [03.92]",
    },
  },
  {
    id: "303409",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**time conflict** COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "**time conflict** colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**time conflict** COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "283668",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**time conflict** EGD WITH ANESTHESIA",
      normalized_name: "**time conflict** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**time conflict** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "345311",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**time conflict**COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "**time conflict**colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**time conflict**COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "278406",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**too soon to schedule** EGD WITH ANESTHESIA",
      normalized_name: "**too soon to schedule** egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**too soon to schedule** EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "271626",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**WAITING FOR CODES**EGD WITH ANESTHESIA",
      normalized_name: "**waiting for codes**egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**WAITING FOR CODES**EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "264059",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**WAITING ON CODES**COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "**waiting on codes**colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "**WAITING ON CODES**COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "261631",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**WAITING ON CODES**EGD WITH ANESTHESIA",
      normalized_name: "**waiting on codes**egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "**WAITING ON CODES**EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "261620",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**WAITING ON CODES**EGD/COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "**waiting on codes**egd/colonoscopy with anesthesia [702109]",
      vendor_code: "702109",
      coded_name:
        "**WAITING ON CODES**EGD/COLONOSCOPY WITH ANESTHESIA [702109]",
    },
  },
  {
    id: "275345",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "**which digit** LEFT AMPUTATION, DIGIT",
      normalized_name: "**which digit** left amputation, digit [700129]",
      vendor_code: "700129",
      coded_name: "**which digit** LEFT AMPUTATION, DIGIT [700129]",
    },
  },
  {
    id: "301153",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*7 DAYS PRIOR* COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "*7 days prior* colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "*7 DAYS PRIOR* COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "296186",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*7 DAYS PRIOR* EGD WITH ANESTHESIA",
      normalized_name: "*7 days prior* egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "*7 DAYS PRIOR* EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "298844",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*7day prior* EGD WITH DILATION",
      normalized_name: "*7day prior* egd with dilation [702260]",
      vendor_code: "702260",
      coded_name: "*7day prior* EGD WITH DILATION [702260]",
    },
  },
  {
    id: "291539",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*ADD ON* APPENDECTOMY, LAPAROSCOPIC",
      normalized_name: "*add on* appendectomy, laparoscopic [700169]",
      vendor_code: "700169",
      coded_name: "*ADD ON* APPENDECTOMY, LAPAROSCOPIC [700169]",
    },
  },
  {
    id: "291540",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*ADD ON* INCISION AND DRAINAGE, ABSCESS left labia",
      normalized_name:
        "*add on* incision and drainage, abscess left labia [700866]",
      vendor_code: "700866",
      coded_name: "*ADD ON* INCISION AND DRAINAGE, ABSCESS left labia [700866]",
    },
  },
  {
    id: "306939",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*AMULET DEVICE*LEFT ATRIAL APPENDAGE CLOSURE DEVICE IMPLANT",
      normalized_name:
        "*amulet device*left atrial appendage closure device implant [angio 96]",
      vendor_code: "ANGIO 96",
      coded_name:
        "*AMULET DEVICE*LEFT ATRIAL APPENDAGE CLOSURE DEVICE IMPLANT [ANGIO 96]",
    },
  },
  {
    id: "140336",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Anesthesia (Dr Rodriguez Mda Notified)* Ct Guide Abdominal Drain Placement",
      normalized_name:
        "*anesthesia (dr rodriguez mda notified)* ct guide abdominal drain placement [566720]",
      vendor_code: "566720",
      coded_name:
        "*Anesthesia (Dr Rodriguez Mda Notified)* Ct Guide Abdominal Drain Placement [566720]",
    },
  },
  {
    id: "200327",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*ANESTHESIA* CT GUIDE ABDOMINAL ASPIRATION",
      normalized_name: "*anesthesia* ct guide abdominal aspiration [566720]",
      vendor_code: "566720",
      coded_name: "*ANESTHESIA* CT GUIDE ABDOMINAL ASPIRATION [566720]",
    },
  },
  {
    id: "303607",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Aneurysm Coiling",
      normalized_name: "*aneurysm coiling [137260]",
      vendor_code: "137260",
      coded_name: "*Aneurysm Coiling [137260]",
    },
  },
  {
    id: "303606",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Aneurysm Embolization",
      normalized_name: "*aneurysm embolization [137260]",
      vendor_code: "137260",
      coded_name: "*Aneurysm Embolization [137260]",
    },
  },
  {
    id: "266046",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*BILATERAL ENDOSCOPIC NASAL SINUS SURGERY",
      normalized_name: "*bilateral endoscopic nasal sinus surgery [700587]",
      vendor_code: "700587",
      coded_name: "*BILATERAL ENDOSCOPIC NASAL SINUS SURGERY [700587]",
    },
  },
  {
    id: "303620",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Botox Injection",
      normalized_name: "*botox injection [137260]",
      vendor_code: "137260",
      coded_name: "*Botox Injection [137260]",
    },
  },
  {
    id: "264851",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CABG (CORONARY ARTERY BYPASS GRAFT) X 3 Likely left radial artery harvest, Atriclip, Swan desired",
      normalized_name:
        "*cabg (coronary artery bypass graft) x 3 likely left radial artery harvest, atriclip, swan desired [700339]",
      vendor_code: "700339",
      coded_name:
        "*CABG (CORONARY ARTERY BYPASS GRAFT) X 3 Likely left radial artery harvest, Atriclip, Swan desired [700339]",
    },
  },
  {
    id: "266775",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CANCELLED - SURGERY COMPLETED ELSEWHERE* Left Axilla Ultrasound Guided Excisional Biopsy",
      normalized_name:
        "*cancelled - surgery completed elsewhere* left axilla ultrasound guided excisional biopsy [700972]",
      vendor_code: "700972",
      coded_name:
        "*CANCELLED - SURGERY COMPLETED ELSEWHERE* Left Axilla Ultrasound Guided Excisional Biopsy [700972]",
    },
  },
  {
    id: "315605",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CANCELLED WRONG PT* URETEROSCOPY, USING THULIUM",
      normalized_name:
        "*cancelled wrong pt* ureteroscopy, using thulium [700940]",
      vendor_code: "700940",
      coded_name: "*CANCELLED WRONG PT* URETEROSCOPY, USING THULIUM [700940]",
    },
  },
  {
    id: "292649",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Cancelled: pending new date** Left Breast Ductal Excision",
      normalized_name:
        "*cancelled: pending new date** left breast ductal excision [703111]",
      vendor_code: "703111",
      coded_name:
        "*Cancelled: pending new date** Left Breast Ductal Excision [703111]",
    },
  },
  {
    id: "267831",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CANT SCHEDULE TILL NEEDS ARE IN CASE* URETEROSCOPY, USING LASER",
      normalized_name:
        "*cant schedule till needs are in case* ureteroscopy, using laser [700940]",
      vendor_code: "700940",
      coded_name:
        "*CANT SCHEDULE TILL NEEDS ARE IN CASE* URETEROSCOPY, USING LASER [700940]",
    },
  },
  {
    id: "309077",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CAROTID ACCESS**TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT)",
      normalized_name:
        "*carotid access**tavr (transcatheter aortic valve replacement) [cc 018]",
      vendor_code: "CC 018",
      coded_name:
        "*CAROTID ACCESS**TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT) [CC 018]",
    },
  },
  {
    id: "185834",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Carotid Angioplasty And Stenting* Angiogram",
      normalized_name: "*carotid angioplasty and stenting* angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*Carotid Angioplasty And Stenting* Angiogram [192267]",
    },
  },
  {
    id: "303603",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Cerebral Angiogram",
      normalized_name: "*cerebral angiogram [137260]",
      vendor_code: "137260",
      coded_name: "*Cerebral Angiogram [137260]",
    },
  },
  {
    id: "189522",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Cholecystectomy Laparoscopic*",
      normalized_name: "*cholecystectomy laparoscopic* [106532]",
      vendor_code: "106532",
      coded_name: "*Cholecystectomy Laparoscopic* [106532]",
    },
  },
  {
    id: "303598",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Cholecystostomy Tube exchange",
      normalized_name: "*cholecystostomy tube exchange [137260]",
      vendor_code: "137260",
      coded_name: "*Cholecystostomy Tube exchange [137260]",
    },
  },
  {
    id: "314443",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CHRISTY NEEDS INS AND CPT* COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "*christy needs ins and cpt* colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name:
        "*CHRISTY NEEDS INS AND CPT* COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "27508",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "02.06",
      name: "*Cranioplasty",
      normalized_name: "*cranioplasty [02.06]",
      vendor_code: null,
      coded_name: "*Cranioplasty [02.06]",
    },
  },
  {
    id: "269900",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* 23H- LT TOTAL HIP REPLACEMENT",
      normalized_name: "*cx* 23h- lt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "*CX* 23H- LT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "272379",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* 23H- LT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "*cx* 23h- lt total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "*CX* 23H- LT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "266634",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* 23H- LT TOTAL KNEE REPLACEMENT",
      normalized_name: "*cx* 23h- lt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*CX* 23H- LT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "265166",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* 23H- RT TOTAL HIP REPLACEMENT",
      normalized_name: "*cx* 23h- rt total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "*CX* 23H- RT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "290931",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* 23H- RT TOTAL KNEE REPLACEMENT",
      normalized_name: "*cx* 23h- rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*CX* 23H- RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "298378",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* 23H- TLH BS",
      normalized_name: "*cx* 23h- tlh bs [703240]",
      vendor_code: "703240",
      coded_name: "*CX* 23H- TLH BS [703240]",
    },
  },
  {
    id: "265094",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* APPENDECTOMY, LAPAROSCOPIC",
      normalized_name: "*cx* appendectomy, laparoscopic [700169]",
      vendor_code: "700169",
      coded_name: "*CX* APPENDECTOMY, LAPAROSCOPIC [700169]",
    },
  },
  {
    id: "270989",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* BIOPSY, ARTERY, TEMPORAL",
      normalized_name: "*cx* biopsy, artery, temporal [700180]",
      vendor_code: "700180",
      coded_name: "*CX* BIOPSY, ARTERY, TEMPORAL [700180]",
    },
  },
  {
    id: "291400",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "*cx* cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "*CX* CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "270963",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* CYSTOSCOPY WITH STENT PLACEMENT",
      normalized_name: "*cx* cystoscopy with stent placement [700524]",
      vendor_code: "700524",
      coded_name: "*CX* CYSTOSCOPY WITH STENT PLACEMENT [700524]",
    },
  },
  {
    id: "305334",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* CYSTOSCOPY, WITH SUPRAPUBIC CATHETER INSERTION",
      normalized_name:
        "*cx* cystoscopy, with suprapubic catheter insertion [702297]",
      vendor_code: "702297",
      coded_name:
        "*CX* CYSTOSCOPY, WITH SUPRAPUBIC CATHETER INSERTION [702297]",
    },
  },
  {
    id: "303516",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* CYSTOSCOPY,WITH PERIURETHRAL BULKING AGENT INJECTION",
      normalized_name:
        "*cx* cystoscopy,with periurethral bulking agent injection [703369]",
      vendor_code: "703369",
      coded_name:
        "*CX* CYSTOSCOPY,WITH PERIURETHRAL BULKING AGENT INJECTION [703369]",
    },
  },
  {
    id: "261850",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* DOSD- RT REVERSE TOTAL SHOULDER",
      normalized_name: "*cx* dosd- rt reverse total shoulder [702604]",
      vendor_code: "702604",
      coded_name: "*CX* DOSD- RT REVERSE TOTAL SHOULDER [702604]",
    },
  },
  {
    id: "302897",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* DOSD- RT TOTAL KNEE REPLACEMENT",
      normalized_name: "*cx* dosd- rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*CX* DOSD- RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "265734",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* EXAM UNDER ANESTHESIA, RECTUM - botox injection, excision anal tag, exparel injection.",
      normalized_name:
        "*cx* exam under anesthesia, rectum - botox injection, excision anal tag, exparel injection. [700619]",
      vendor_code: "700619",
      coded_name:
        "*CX* EXAM UNDER ANESTHESIA, RECTUM - botox injection, excision anal tag, exparel injection. [700619]",
    },
  },
  {
    id: "268272",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* FASCIA CLOSURE MIDLINE ABDOMEN",
      normalized_name: "*cx* fascia closure midline abdomen [702177]",
      vendor_code: "702177",
      coded_name: "*CX* FASCIA CLOSURE MIDLINE ABDOMEN [702177]",
    },
  },
  {
    id: "299107",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* INCISION AND DRAINAGE, SACRAL WOUND",
      normalized_name: "*cx* incision and drainage, sacral wound [702336]",
      vendor_code: "702336",
      coded_name: "*CX* INCISION AND DRAINAGE, SACRAL WOUND [702336]",
    },
  },
  {
    id: "286484",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* INCISION AND DRAINAGE, WOUND, UPPER EXTREMITY",
      normalized_name:
        "*cx* incision and drainage, wound, upper extremity [700865]",
      vendor_code: "700865",
      coded_name: "*CX* INCISION AND DRAINAGE, WOUND, UPPER EXTREMITY [700865]",
    },
  },
  {
    id: "298992",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* INSERTION, STENT, URETER, CYSTO",
      normalized_name: "*cx* insertion, stent, ureter, cysto [701644]",
      vendor_code: "701644",
      coded_name: "*CX* INSERTION, STENT, URETER, CYSTO [701644]",
    },
  },
  {
    id: "318691",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* LAPAROSCOPY POSSIBLE FULGURATION AND BIOPSY",
      normalized_name:
        "*cx* laparoscopy possible fulguration and biopsy [700513]",
      vendor_code: "700513",
      coded_name: "*CX* LAPAROSCOPY POSSIBLE FULGURATION AND BIOPSY [700513]",
    },
  },
  {
    id: "295404",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* left lower extremity debridement",
      normalized_name: "*cx* left lower extremity debridement [700864]",
      vendor_code: "700864",
      coded_name: "*CX* left lower extremity debridement [700864]",
    },
  },
  {
    id: "291509",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* LIGATION, FALLOPIAN TUBE, LAPAROSCOPIC",
      normalized_name: "*cx* ligation, fallopian tube, laparoscopic [700256]",
      vendor_code: "700256",
      coded_name: "*CX* LIGATION, FALLOPIAN TUBE, LAPAROSCOPIC [700256]",
    },
  },
  {
    id: "274799",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* LIGATION, FALLOPIAN TUBE, POSTPARTUM",
      normalized_name: "*cx* ligation, fallopian tube, postpartum [701261]",
      vendor_code: "701261",
      coded_name: "*CX* LIGATION, FALLOPIAN TUBE, POSTPARTUM [701261]",
    },
  },
  {
    id: "269659",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* LT 5TH DIGIT WOUND IRRIGATION AND DEBRIDEMENT",
      normalized_name:
        "*cx* lt 5th digit wound irrigation and debridement [750048]",
      vendor_code: "750048",
      coded_name: "*CX* LT 5TH DIGIT WOUND IRRIGATION AND DEBRIDEMENT [750048]",
    },
  },
  {
    id: "260679",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* LT ANKLE ARTHOROSCOPY, DEBRIDEMENT",
      normalized_name: "*cx* lt ankle arthoroscopy, debridement [700218]",
      vendor_code: "700218",
      coded_name: "*CX* LT ANKLE ARTHOROSCOPY, DEBRIDEMENT [700218]",
    },
  },
  {
    id: "297857",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* LT BUTTOCKS CYST EXCISION",
      normalized_name: "*cx* lt buttocks cyst excision [700959]",
      vendor_code: "700959",
      coded_name: "*CX* LT BUTTOCKS CYST EXCISION [700959]",
    },
  },
  {
    id: "297993",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, ANKLE",
      normalized_name:
        "*cx* lt open reduction internal fixation (orif), fracture, ankle [700144]",
      vendor_code: "700144",
      coded_name:
        "*CX* LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, ANKLE [700144]",
    },
  },
  {
    id: "269383",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* REPAIR, HERNIA, UMBILICAL",
      normalized_name: "*cx* repair, hernia, umbilical [700797]",
      vendor_code: "700797",
      coded_name: "*CX* REPAIR, HERNIA, UMBILICAL [700797]",
    },
  },
  {
    id: "270988",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* RIGHT DIRECT ANTERIOR TOTAL HIP ARTHROPLASTY",
      normalized_name:
        "*cx* right direct anterior total hip arthroplasty [701565]",
      vendor_code: "701565",
      coded_name: "*CX* RIGHT DIRECT ANTERIOR TOTAL HIP ARTHROPLASTY [701565]",
    },
  },
  {
    id: "274784",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* RT MIDDLE FINGER TRIGGER DIGIT RELEASE",
      normalized_name: "*cx* rt middle finger trigger digit release [701616]",
      vendor_code: "701616",
      coded_name: "*CX* RT MIDDLE FINGER TRIGGER DIGIT RELEASE [701616]",
    },
  },
  {
    id: "271140",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* RT OPEN REPAIR OF GREAT TOE, 2ND AND 3RD METATARSAL DISLOCATION",
      normalized_name:
        "*cx* rt open repair of great toe, 2nd and 3rd metatarsal dislocation [702332]",
      vendor_code: "702332",
      coded_name:
        "*CX* RT OPEN REPAIR OF GREAT TOE, 2ND AND 3RD METATARSAL DISLOCATION [702332]",
    },
  },
  {
    id: "291465",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* RT REPAIR, ABDUCTOR TENDON, HIP W/POSSIBLE ALLOGRAFT AUGMENTATION",
      normalized_name:
        "*cx* rt repair, abductor tendon, hip w/possible allograft augmentation [702139]",
      vendor_code: "702139",
      coded_name:
        "*CX* RT REPAIR, ABDUCTOR TENDON, HIP W/POSSIBLE ALLOGRAFT AUGMENTATION [702139]",
    },
  },
  {
    id: "261890",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* RT SHOULDER ARTHROSCOPIC DEBRIDEMENT, SUBACROMIAL DECOMPRESSION, POSSIBLE RCR",
      normalized_name:
        "*cx* rt shoulder arthroscopic debridement, subacromial decompression, possible rcr [700228]",
      vendor_code: "700228",
      coded_name:
        "*CX* RT SHOULDER ARTHROSCOPIC DEBRIDEMENT, SUBACROMIAL DECOMPRESSION, POSSIBLE RCR [700228]",
    },
  },
  {
    id: "293191",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CX* URETEROSCOPY WITH THULIUM",
      normalized_name: "*cx* ureteroscopy with thulium [701652]",
      vendor_code: "701652",
      coded_name: "*CX* URETEROSCOPY WITH THULIUM [701652]",
    },
  },
  {
    id: "313363",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*cxld* 23H- RT TOTAL KNEE REPLACEMENT",
      normalized_name: "*cxld* 23h- rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*cxld* 23H- RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "299703",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*cxld* APPENDECTOMY, LAPAROSCOPIC",
      normalized_name: "*cxld* appendectomy, laparoscopic [700169]",
      vendor_code: "700169",
      coded_name: "*cxld* APPENDECTOMY, LAPAROSCOPIC [700169]",
    },
  },
  {
    id: "314445",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*cxld* CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "*cxld* cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "*cxld* CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "317523",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CXLD* EXPLORATORY LAPAROSCOPY W/ LYSIS OF ADHESIONS",
      normalized_name:
        "*cxld* exploratory laparoscopy w/ lysis of adhesions [700916]",
      vendor_code: "700916",
      coded_name:
        "*CXLD* EXPLORATORY LAPAROSCOPY W/ LYSIS OF ADHESIONS [700916]",
    },
  },
  {
    id: "313683",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*CXLD* LT ARTHROSCOPY, SHOULDER, WITH BICEPS TENODESIS",
      normalized_name:
        "*cxld* lt arthroscopy, shoulder, with biceps tenodesis [702393]",
      vendor_code: "702393",
      coded_name:
        "*CXLD* LT ARTHROSCOPY, SHOULDER, WITH BICEPS TENODESIS [702393]",
    },
  },
  {
    id: "300724",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*cxld-r/s** 23H- RT TOTAL KNEE REPLACEMENT",
      normalized_name: "*cxld-r/s** 23h- rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*cxld-r/s** 23H- RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "300769",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*cxld/need to r/s* AQUABLATION",
      normalized_name: "*cxld/need to r/s* aquablation [700937]",
      vendor_code: "700937",
      coded_name: "*cxld/need to r/s* AQUABLATION [700937]",
    },
  },
  {
    id: "281759",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Delayed due to admit* Left Breast Needle Localized Lumpectomy",
      normalized_name:
        "*delayed due to admit* left breast needle localized lumpectomy [700311]",
      vendor_code: "700311",
      coded_name:
        "*Delayed due to admit* Left Breast Needle Localized Lumpectomy [700311]",
    },
  },
  {
    id: "281753",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Delayed due to illness/exposure* Left Breast Needle Localized Excisional Biopsy",
      normalized_name:
        "*delayed due to illness/exposure* left breast needle localized excisional biopsy [703124]",
      vendor_code: "703124",
      coded_name:
        "*Delayed due to illness/exposure* Left Breast Needle Localized Excisional Biopsy [703124]",
    },
  },
  {
    id: "265074",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*doublebooked*COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "*doublebooked*colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "*doublebooked*COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "263657",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*FAKE CASE * EXCISION, GYNECOMASTIA",
      normalized_name: "*fake case * excision, gynecomastia [702678]",
      vendor_code: "702678",
      coded_name: "*FAKE CASE * EXCISION, GYNECOMASTIA [702678]",
    },
  },
  {
    id: "260825",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*FAKE CASE FOR SAC* REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC",
      normalized_name:
        "*fake case for sac* repair, hernia, inguinal, laparoscopic [700791]",
      vendor_code: "700791",
      coded_name:
        "*FAKE CASE FOR SAC* REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC [700791]",
    },
  },
  {
    id: "260656",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*FAKE CASE* 23H- TLH BSO",
      normalized_name: "*fake case* 23h- tlh bso [703240]",
      vendor_code: "703240",
      coded_name: "*FAKE CASE* 23H- TLH BSO [703240]",
    },
  },
  {
    id: "314144",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*FINISH 2ND PAGE* COLONOSCOPY WITH ANESTHESIA",
      normalized_name: "*finish 2nd page* colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "*FINISH 2ND PAGE* COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "179826",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesestheisa* Us Bx Abd Mass By Ir Md, Deep Bone Bx By Dr Mathias & Picc Placement By Picc Nurse",
      normalized_name:
        "*general anesestheisa* us bx abd mass by ir md, deep bone bx by dr mathias & picc placement by picc nurse [566720]",
      vendor_code: "566720",
      coded_name:
        "*General Anesestheisa* Us Bx Abd Mass By Ir Md, Deep Bone Bx By Dr Mathias & Picc Placement By Picc Nurse [566720]",
    },
  },
  {
    id: "98533",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anestheisa* Angiogram, Carotid Stenting",
      normalized_name:
        "*general anestheisa* angiogram, carotid stenting [566725]",
      vendor_code: "566725",
      coded_name: "*General Anestheisa* Angiogram, Carotid Stenting [566725]",
    },
  },
  {
    id: "24259",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "*General Anesthesia * Angiogram",
      normalized_name: "*general anesthesia * angiogram [88.40]",
      vendor_code: null,
      coded_name: "*General Anesthesia * Angiogram [88.40]",
    },
  },
  {
    id: "79980",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia - Cerebral Embolization",
      normalized_name: "*general anesthesia - cerebral embolization [142201]",
      vendor_code: "142201",
      coded_name: "*General Anesthesia - Cerebral Embolization [142201]",
    },
  },
  {
    id: "183875",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia",
      normalized_name: "*general anesthesia [181742]",
      vendor_code: "181742",
      coded_name: "*General Anesthesia [181742]",
    },
  },
  {
    id: "124845",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Aneurysm Repair By Endovascular Coil Embolization",
      normalized_name:
        "*general anesthesia* aneurysm repair by endovascular coil embolization [142201]",
      vendor_code: "142201",
      coded_name:
        "*General Anesthesia* Aneurysm Repair By Endovascular Coil Embolization [142201]",
    },
  },
  {
    id: "139553",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Angiogram",
      normalized_name: "*general anesthesia* angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*General Anesthesia* Angiogram [192267]",
    },
  },
  {
    id: "214505",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* ANGIOGRAM",
      normalized_name: "*general anesthesia* angiogram [566724]",
      vendor_code: "566724",
      coded_name: "*GENERAL ANESTHESIA* ANGIOGRAM [566724]",
    },
  },
  {
    id: "19644",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "*General Anesthesia* Angiogram",
      normalized_name: "*general anesthesia* angiogram [88.40]",
      vendor_code: null,
      coded_name: "*General Anesthesia* Angiogram [88.40]",
    },
  },
  {
    id: "160582",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Angiogram And Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* angiogram and embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Angiogram And Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "135880",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Angiogram, Lower Extremity, Right Leg",
      normalized_name:
        "*general anesthesia* angiogram, lower extremity, right leg [566724]",
      vendor_code: "566724",
      coded_name:
        "*General Anesthesia* Angiogram, Lower Extremity, Right Leg [566724]",
    },
  },
  {
    id: "143742",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Angiogram, Lower Extremity, Unilateral",
      normalized_name:
        "*general anesthesia* angiogram, lower extremity, unilateral [566724]",
      vendor_code: "566724",
      coded_name:
        "*General Anesthesia* Angiogram, Lower Extremity, Unilateral [566724]",
    },
  },
  {
    id: "140002",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Angiogram, Lower Extremity, Unilateral, Right",
      normalized_name:
        "*general anesthesia* angiogram, lower extremity, unilateral, right [566724]",
      vendor_code: "566724",
      coded_name:
        "*General Anesthesia* Angiogram, Lower Extremity, Unilateral, Right [566724]",
    },
  },
  {
    id: "98520",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Angiogram, Upper Extremity, Left",
      normalized_name:
        "*general anesthesia* angiogram, upper extremity, left [566725]",
      vendor_code: "566725",
      coded_name:
        "*General Anesthesia* Angiogram, Upper Extremity, Left [566725]",
    },
  },
  {
    id: "192618",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* ANGIOPLASTLY / STENTING EMBOLIZATION, TRANSCATHETER THERAPY",
      normalized_name:
        "*general anesthesia* angioplastly / stenting embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*GENERAL ANESTHESIA* ANGIOPLASTLY / STENTING EMBOLIZATION, TRANSCATHETER THERAPY [566732]",
    },
  },
  {
    id: "227959",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* ARTERY EMBOLIZATION BY ENDOVASCULAR COIL",
      normalized_name:
        "*general anesthesia* artery embolization by endovascular coil [362544]",
      vendor_code: "362544",
      coded_name:
        "*GENERAL ANESTHESIA* ARTERY EMBOLIZATION BY ENDOVASCULAR COIL [362544]",
    },
  },
  {
    id: "20027",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Artery Embolization By Endovascular Coil-Splenic Aneurysm",
      normalized_name:
        "*general anesthesia* artery embolization by endovascular coil-splenic aneurysm [362544]",
      vendor_code: "362544",
      coded_name:
        "*General Anesthesia* Artery Embolization By Endovascular Coil-Splenic Aneurysm [362544]",
    },
  },
  {
    id: "157393",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Bili Stone Sweep",
      normalized_name: "*general anesthesia* bili stone sweep [566726]",
      vendor_code: "566726",
      coded_name: "*General Anesthesia* Bili Stone Sweep [566726]",
    },
  },
  {
    id: "227961",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* BILIARY TUBE PLACEMENT",
      normalized_name: "*general anesthesia* biliary tube placement [566726]",
      vendor_code: "566726",
      coded_name: "*GENERAL ANESTHESIA* BILIARY TUBE PLACEMENT [566726]",
    },
  },
  {
    id: "66242",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "00.61",
      name: "*General Anesthesia* Carotid Angioplasty W Stent",
      normalized_name:
        "*general anesthesia* carotid angioplasty w stent [00.61]",
      vendor_code: null,
      coded_name: "*General Anesthesia* Carotid Angioplasty W Stent [00.61]",
    },
  },
  {
    id: "163202",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Carotid Stent / Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* carotid stent / embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Carotid Stent / Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "151155",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Carotid Stenting* Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* carotid stenting* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Carotid Stenting* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "74486",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Cerebral Angiogram",
      normalized_name: "*general anesthesia* cerebral angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*General Anesthesia* Cerebral Angiogram [192267]",
    },
  },
  {
    id: "161256",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Cerebral Angiogram",
      normalized_name: "*general anesthesia* cerebral angiogram [566726]",
      vendor_code: "566726",
      coded_name: "*General Anesthesia* Cerebral Angiogram [566726]",
    },
  },
  {
    id: "108683",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Cerebral Angiogram Hx Of Stroke",
      normalized_name:
        "*general anesthesia* cerebral angiogram hx of stroke [566726]",
      vendor_code: "566726",
      coded_name:
        "*General Anesthesia* Cerebral Angiogram Hx Of Stroke [566726]",
    },
  },
  {
    id: "148607",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Cerebral Embolization",
      normalized_name: "*general anesthesia* cerebral embolization [142201]",
      vendor_code: "142201",
      coded_name: "*General Anesthesia* Cerebral Embolization [142201]",
    },
  },
  {
    id: "209138",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* CEREBRAL EMBOLIZATION",
      normalized_name: "*general anesthesia* cerebral embolization [192267]",
      vendor_code: "192267",
      coded_name: "*GENERAL ANESTHESIA* CEREBRAL EMBOLIZATION [192267]",
    },
  },
  {
    id: "170235",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Cerebral Stenting/Angiogram",
      normalized_name:
        "*general anesthesia* cerebral stenting/angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*General Anesthesia* Cerebral Stenting/Angiogram [192267]",
    },
  },
  {
    id: "98409",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Ct Abdominal Drainage",
      normalized_name: "*general anesthesia* ct abdominal drainage [566720]",
      vendor_code: "566720",
      coded_name: "*General Anesthesia* Ct Abdominal Drainage [566720]",
    },
  },
  {
    id: "224220",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* CT CHEST ABD PELVIS",
      normalized_name: "*general anesthesia* ct chest abd pelvis [148608]",
      vendor_code: "148608",
      coded_name: "*GENERAL ANESTHESIA* CT CHEST ABD PELVIS [148608]",
    },
  },
  {
    id: "107880",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Ct Guide Abdominal Abscess",
      normalized_name:
        "*general anesthesia* ct guide abdominal abscess [566720]",
      vendor_code: "566720",
      coded_name: "*General Anesthesia* Ct Guide Abdominal Abscess [566720]",
    },
  },
  {
    id: "201485",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* CT GUIDE FOR BIOPSY, DEEP BONE",
      normalized_name:
        "*general anesthesia* ct guide for biopsy, deep bone [566720]",
      vendor_code: "566720",
      coded_name:
        "*GENERAL ANESTHESIA* CT GUIDE FOR BIOPSY, DEEP BONE [566720]",
    },
  },
  {
    id: "219281",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* CT MYELOGRAM / EMBOLIZATION, TRANSCATHETER THERAPY",
      normalized_name:
        "*general anesthesia* ct myelogram / embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*GENERAL ANESTHESIA* CT MYELOGRAM / EMBOLIZATION, TRANSCATHETER THERAPY [566732]",
    },
  },
  {
    id: "105123",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Embolization Of Oral Bleeding",
      normalized_name:
        "*general anesthesia* embolization of oral bleeding [566726]",
      vendor_code: "566726",
      coded_name: "*General Anesthesia* Embolization Of Oral Bleeding [566726]",
    },
  },
  {
    id: "30934",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Embolization, Transcatheter Therapy (Right Carotid Stent)",
      normalized_name:
        "*general anesthesia* embolization, transcatheter therapy (right carotid stent) [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Embolization, Transcatheter Therapy (Right Carotid Stent) [566732]",
    },
  },
  {
    id: "19874",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "136855",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Embolization, Transcatheter Therapy Pulm Avm",
      normalized_name:
        "*general anesthesia* embolization, transcatheter therapy pulm avm [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Embolization, Transcatheter Therapy Pulm Avm [566732]",
    },
  },
  {
    id: "162784",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Hdtc Placement",
      normalized_name: "*general anesthesia* hdtc placement [566731]",
      vendor_code: "566731",
      coded_name: "*General Anesthesia* Hdtc Placement [566731]",
    },
  },
  {
    id: "162606",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Ir Fluoro Guided Sclerotherapy Fluoroscopy Guide For Bx Or Asp Or Inj",
      normalized_name:
        "*general anesthesia* ir fluoro guided sclerotherapy fluoroscopy guide for bx or asp or inj [566721]",
      vendor_code: "566721",
      coded_name:
        "*General Anesthesia* Ir Fluoro Guided Sclerotherapy Fluoroscopy Guide For Bx Or Asp Or Inj [566721]",
    },
  },
  {
    id: "114311",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Kyphoplasty",
      normalized_name: "*general anesthesia* kyphoplasty [204850]",
      vendor_code: "204850",
      coded_name: "*General Anesthesia* Kyphoplasty [204850]",
    },
  },
  {
    id: "144851",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Kyphoplasty, L1, L2",
      normalized_name: "*general anesthesia* kyphoplasty, l1, l2 [204850]",
      vendor_code: "204850",
      coded_name: "*General Anesthesia* Kyphoplasty, L1, L2 [204850]",
    },
  },
  {
    id: "217959",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* KYPHOPLASTY, T1",
      normalized_name: "*general anesthesia* kyphoplasty, t1 [204850]",
      vendor_code: "204850",
      coded_name: "*GENERAL ANESTHESIA* KYPHOPLASTY, T1 [204850]",
    },
  },
  {
    id: "162785",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Left Perc Nephrostomy Tube",
      normalized_name:
        "*general anesthesia* left perc nephrostomy tube [148942]",
      vendor_code: "148942",
      coded_name: "*General Anesthesia* Left Perc Nephrostomy Tube [148942]",
    },
  },
  {
    id: "182280",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Liver Abscess",
      normalized_name: "*general anesthesia* liver abscess [566720]",
      vendor_code: "566720",
      coded_name: "*General Anesthesia* Liver Abscess [566720]",
    },
  },
  {
    id: "214291",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA* MYELOGRAM / EMBOLIZATION, TRANSCATHETER THERAPY",
      normalized_name:
        "*general anesthesia* myelogram / embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*GENERAL ANESTHESIA* MYELOGRAM / EMBOLIZATION, TRANSCATHETER THERAPY [566732]",
    },
  },
  {
    id: "176690",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Myelogram",
      normalized_name: "*general anesthesia* myelogram [566726]",
      vendor_code: "566726",
      coded_name: "*General Anesthesia* Myelogram [566726]",
    },
  },
  {
    id: "148100",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Nephrostomy Percutaneous Bilateral Exchange",
      normalized_name:
        "*general anesthesia* nephrostomy percutaneous bilateral exchange [148942]",
      vendor_code: "148942",
      coded_name:
        "*General Anesthesia* Nephrostomy Percutaneous Bilateral Exchange [148942]",
    },
  },
  {
    id: "112758",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Picc Placement",
      normalized_name: "*general anesthesia* picc placement [566726]",
      vendor_code: "566726",
      coded_name: "*General Anesthesia* Picc Placement [566726]",
    },
  },
  {
    id: "161827",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Pipeline, Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* pipeline, embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Pipeline, Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "124062",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Ptc Placement",
      normalized_name: "*general anesthesia* ptc placement [566729]",
      vendor_code: "566729",
      coded_name: "*General Anesthesia* Ptc Placement [566729]",
    },
  },
  {
    id: "144842",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Pulmonary Avm",
      normalized_name: "*general anesthesia* pulmonary avm [148608]",
      vendor_code: "148608",
      coded_name: "*General Anesthesia* Pulmonary Avm [148608]",
    },
  },
  {
    id: "165348",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Spinal Angiogram",
      normalized_name: "*general anesthesia* spinal angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*General Anesthesia* Spinal Angiogram [192267]",
    },
  },
  {
    id: "31053",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "*General Anesthesia* Spinal Angiogram",
      normalized_name: "*general anesthesia* spinal angiogram [88.40]",
      vendor_code: null,
      coded_name: "*General Anesthesia* Spinal Angiogram [88.40]",
    },
  },
  {
    id: "31033",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Spinal Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* spinal embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Spinal Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "26983",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "*General Anesthesia* Spine Angiogram",
      normalized_name: "*general anesthesia* spine angiogram [88.40]",
      vendor_code: null,
      coded_name: "*General Anesthesia* Spine Angiogram [88.40]",
    },
  },
  {
    id: "182281",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Spine Bx",
      normalized_name: "*general anesthesia* spine bx [566720]",
      vendor_code: "566720",
      coded_name: "*General Anesthesia* Spine Bx [566720]",
    },
  },
  {
    id: "169704",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Stent / Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* stent / embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Stent / Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "162061",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Stent, Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia* stent, embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia* Stent, Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "110514",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Tube Exchange, Gastrointestinal",
      normalized_name:
        "*general anesthesia* tube exchange, gastrointestinal [566727]",
      vendor_code: "566727",
      coded_name:
        "*General Anesthesia* Tube Exchange, Gastrointestinal [566727]",
    },
  },
  {
    id: "105122",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Tube Placement, Gastrointestinal",
      normalized_name:
        "*general anesthesia* tube placement, gastrointestinal [566727]",
      vendor_code: "566727",
      coded_name:
        "*General Anesthesia* Tube Placement, Gastrointestinal [566727]",
    },
  },
  {
    id: "140467",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Us Guide For Biopsy, Thyroid And Lymph Node",
      normalized_name:
        "*general anesthesia* us guide for biopsy, thyroid and lymph node [566720]",
      vendor_code: "566720",
      coded_name:
        "*General Anesthesia* Us Guide For Biopsy, Thyroid And Lymph Node [566720]",
    },
  },
  {
    id: "66750",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Us Guided Cervical Mass Biopsy",
      normalized_name:
        "*general anesthesia* us guided cervical mass biopsy [566720]",
      vendor_code: "566720",
      coded_name:
        "*General Anesthesia* Us Guided Cervical Mass Biopsy [566720]",
    },
  },
  {
    id: "178939",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Us Pleural Drain Placement",
      normalized_name:
        "*general anesthesia* us pleural drain placement [137260]",
      vendor_code: "137260",
      coded_name: "*General Anesthesia* Us Pleural Drain Placement [137260]",
    },
  },
  {
    id: "170019",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia* Visceral Angiogram",
      normalized_name: "*general anesthesia* visceral angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*General Anesthesia* Visceral Angiogram [192267]",
    },
  },
  {
    id: "24548",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "*General Anesthesia*Angiogram",
      normalized_name: "*general anesthesia*angiogram [88.40]",
      vendor_code: null,
      coded_name: "*General Anesthesia*Angiogram [88.40]",
    },
  },
  {
    id: "193792",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*GENERAL ANESTHESIA*CT GUIDE FOR BIOPSY, DEEP BONE",
      normalized_name:
        "*general anesthesia*ct guide for biopsy, deep bone [566720]",
      vendor_code: "566720",
      coded_name: "*GENERAL ANESTHESIA*CT GUIDE FOR BIOPSY, DEEP BONE [566720]",
    },
  },
  {
    id: "18792",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anesthesia*Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anesthesia*embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anesthesia*Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "23868",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "*General Anesthsia * Angiogram",
      normalized_name: "*general anesthsia * angiogram [88.40]",
      vendor_code: null,
      coded_name: "*General Anesthsia * Angiogram [88.40]",
    },
  },
  {
    id: "181896",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anethesia* Angiogram",
      normalized_name: "*general anethesia* angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*General Anethesia* Angiogram [192267]",
    },
  },
  {
    id: "34232",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Anethesia* Embolization, Transcatheter Therapy",
      normalized_name:
        "*general anethesia* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Anethesia* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "28920",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Ansthesia* Carotid Stenting Embolization, Transcatheter Therapy",
      normalized_name:
        "*general ansthesia* carotid stenting embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Ansthesia* Carotid Stenting Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "31361",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*General Ansthesia* Embolization, Transcatheter Therapy",
      normalized_name:
        "*general ansthesia* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*General Ansthesia* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "139028",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Genral Anesthesis* Visceral Angiogram",
      normalized_name: "*genral anesthesis* visceral angiogram [148608]",
      vendor_code: "148608",
      coded_name: "*Genral Anesthesis* Visceral Angiogram [148608]",
    },
  },
  {
    id: "303619",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Hickman",
      normalized_name: "*hickman [137260]",
      vendor_code: "137260",
      coded_name: "*Hickman [137260]",
    },
  },
  {
    id: "315944",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD IN DEPOT TILL 1/11/23 END OF DAY* LT ORIF, DISTAL RADIUS FRACTURE",
      normalized_name:
        "*hold in depot till 1/11/23 end of day* lt orif, distal radius fracture [702389]",
      vendor_code: "702389",
      coded_name:
        "*HOLD IN DEPOT TILL 1/11/23 END OF DAY* LT ORIF, DISTAL RADIUS FRACTURE [702389]",
    },
  },
  {
    id: "277775",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD TO RESCHEDULE** 23H- RT REVERSE TOTAL SHOULDER",
      normalized_name:
        "*hold to reschedule** 23h- rt reverse total shoulder [702604]",
      vendor_code: "702604",
      coded_name:
        "*HOLD TO RESCHEDULE** 23H- RT REVERSE TOTAL SHOULDER [702604]",
    },
  },
  {
    id: "304675",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* 23H- ANTERIOR CERVICAL DISC & FUSION C3-4 C4-5 C6-7",
      normalized_name:
        "*hold* 23h- anterior cervical disc & fusion c3-4 c4-5 c6-7 [702854]",
      vendor_code: "702854",
      coded_name:
        "*HOLD* 23H- ANTERIOR CERVICAL DISC & FUSION C3-4 C4-5 C6-7 [702854]",
    },
  },
  {
    id: "266403",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* CYSTOSCOPY, URETEROSCOPY, LASER LITHOTRIPSY, POSS STENT",
      normalized_name:
        "*hold* cystoscopy, ureteroscopy, laser lithotripsy, poss stent [703091]",
      vendor_code: "703091",
      coded_name:
        "*HOLD* CYSTOSCOPY, URETEROSCOPY, LASER LITHOTRIPSY, POSS STENT [703091]",
    },
  },
  {
    id: "302293",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* DEBRIDEMENT, ARTHROSCOPIC",
      normalized_name: "*hold* debridement, arthroscopic [700203]",
      vendor_code: "700203",
      coded_name: "*HOLD* DEBRIDEMENT, ARTHROSCOPIC [700203]",
    },
  },
  {
    id: "265636",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* FUSION, JOINT, ANKLE",
      normalized_name: "*hold* fusion, joint, ankle [702168]",
      vendor_code: "702168",
      coded_name: "*HOLD* FUSION, JOINT, ANKLE [702168]",
    },
  },
  {
    id: "272363",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* FUSION, JOINT, INVOLVING METATARSAL BONE",
      normalized_name:
        "*hold* fusion, joint, involving metatarsal bone [701046]",
      vendor_code: "701046",
      coded_name: "*HOLD* FUSION, JOINT, INVOLVING METATARSAL BONE [701046]",
    },
  },
  {
    id: "300500",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* LT BUNIONECTOMY, WITH METATARSAL OSTEOTOMY",
      normalized_name:
        "*hold* lt bunionectomy, with metatarsal osteotomy [700325]",
      vendor_code: "700325",
      coded_name: "*HOLD* LT BUNIONECTOMY, WITH METATARSAL OSTEOTOMY [700325]",
    },
  },
  {
    id: "265365",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, FEMUR, DISTAL",
      normalized_name:
        "*hold* lt open reduction internal fixation (orif), fracture, femur, distal [702060]",
      vendor_code: "702060",
      coded_name:
        "*HOLD* LT OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, FEMUR, DISTAL [702060]",
    },
  },
  {
    id: "270172",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* LT OSTEOTOMY, METATARSAL BONE",
      normalized_name: "*hold* lt osteotomy, metatarsal bone [750050]",
      vendor_code: "750050",
      coded_name: "*HOLD* LT OSTEOTOMY, METATARSAL BONE [750050]",
    },
  },
  {
    id: "301268",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* LT RELEASE, CARPAL TUNNEL",
      normalized_name: "*hold* lt release, carpal tunnel [700371]",
      vendor_code: "700371",
      coded_name: "*HOLD* LT RELEASE, CARPAL TUNNEL [700371]",
    },
  },
  {
    id: "265355",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* REPAIR, HAMMER TOE",
      normalized_name: "*hold* repair, hammer toe [700744]",
      vendor_code: "700744",
      coded_name: "*HOLD* REPAIR, HAMMER TOE [700744]",
    },
  },
  {
    id: "304072",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* REPAIR, ROTATOR CUFF, ARTHROSCOPIC",
      normalized_name: "*hold* repair, rotator cuff, arthroscopic [700213]",
      vendor_code: "700213",
      coded_name: "*HOLD* REPAIR, ROTATOR CUFF, ARTHROSCOPIC [700213]",
    },
  },
  {
    id: "261037",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* RT FOOT WOUND I&D, CLOSURE",
      normalized_name: "*hold* rt foot wound i&d, closure [700864]",
      vendor_code: "700864",
      coded_name: "*HOLD* RT FOOT WOUND I&D, CLOSURE [700864]",
    },
  },
  {
    id: "285725",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* RT STRAYER PROCEDURE",
      normalized_name: "*hold* rt strayer procedure [701465]",
      vendor_code: "701465",
      coded_name: "*HOLD* RT STRAYER PROCEDURE [701465]",
    },
  },
  {
    id: "311658",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* RT TOTAL KNEE REPLACEMENT",
      normalized_name: "*hold* rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*HOLD* RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "270061",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* TRANSPERINEAL PROSTATE BIOPSY",
      normalized_name: "*hold* transperineal prostate biopsy [701274]",
      vendor_code: "701274",
      coded_name: "*HOLD* TRANSPERINEAL PROSTATE BIOPSY [701274]",
    },
  },
  {
    id: "264743",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* TRANSPERINEAL US GUIDED BIOPSY OF THE PROSTATE",
      normalized_name:
        "*hold* transperineal us guided biopsy of the prostate [702159]",
      vendor_code: "702159",
      coded_name:
        "*HOLD* TRANSPERINEAL US GUIDED BIOPSY OF THE PROSTATE [702159]",
    },
  },
  {
    id: "270733",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLD* URETEROSCOPY, USING LASER",
      normalized_name: "*hold* ureteroscopy, using laser [700940]",
      vendor_code: "700940",
      coded_name: "*HOLD* URETEROSCOPY, USING LASER [700940]",
    },
  },
  {
    id: "266048",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*HOLDING* RT TIBIAL PILON FRACTURE ORIF, EX FIX REMOVAL",
      normalized_name:
        "*holding* rt tibial pilon fracture orif, ex fix removal [700144]",
      vendor_code: "700144",
      coded_name:
        "*HOLDING* RT TIBIAL PILON FRACTURE ORIF, EX FIX REMOVAL [700144]",
    },
  },
  {
    id: "265652",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*INS* CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "*ins* cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "*INS* CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "269529",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*INS* EGD WITH ANESTHESIA",
      normalized_name: "*ins* egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "*INS* EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "265649",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*INS* REPAIR, HERNIA, UMBILICAL, OPEN, W/ MESH",
      normalized_name:
        "*ins* repair, hernia, umbilical, open, w/ mesh [700797]",
      vendor_code: "700797",
      coded_name: "*INS* REPAIR, HERNIA, UMBILICAL, OPEN, W/ MESH [700797]",
    },
  },
  {
    id: "279386",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*insurance NOT covered at Valley at ValleyPARATHYROIDECTOMY",
      normalized_name:
        "*insurance not covered at valley at valleyparathyroidectomy [750080]",
      vendor_code: "750080",
      coded_name:
        "*insurance NOT covered at Valley at ValleyPARATHYROIDECTOMY [750080]",
    },
  },
  {
    id: "303604",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Kyphoplasty",
      normalized_name: "*kyphoplasty [137260]",
      vendor_code: "137260",
      coded_name: "*Kyphoplasty [137260]",
    },
  },
  {
    id: "308726",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*L CAROTID ACCESS*HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE GENERAL ANESETHESIA W/TEE GUIDANCE(psb)",
      normalized_name:
        "*l carotid access*hybrid transfemoral percutaneous aortic valve general anesethesia w/tee guidance(psb) [cvt 204]",
      vendor_code: "CVT 204",
      coded_name:
        "*L CAROTID ACCESS*HYBRID TRANSFEMORAL PERCUTANEOUS AORTIC VALVE GENERAL ANESETHESIA W/TEE GUIDANCE(psb) [CVT 204]",
    },
  },
  {
    id: "308725",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*L CAROTID ACCESS*TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT) GENERAL ANESETHESIA W/TEE GUIDANCE",
      normalized_name:
        "*l carotid access*tavr (transcatheter aortic valve replacement) general anesethesia w/tee guidance [cc 018]",
      vendor_code: "CC 018",
      coded_name:
        "*L CAROTID ACCESS*TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT) GENERAL ANESETHESIA W/TEE GUIDANCE [CC 018]",
    },
  },
  {
    id: "345515",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*LAPAROSCOPY, DIAGNOSTIC",
      normalized_name: "*laparoscopy, diagnostic [700916]",
      vendor_code: "700916",
      coded_name: "*LAPAROSCOPY, DIAGNOSTIC [700916]",
    },
  },
  {
    id: "303565",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*left posterior paraspinal muscle trigger point CSI",
      normalized_name:
        "*left posterior paraspinal muscle trigger point csi [137260]",
      vendor_code: "137260",
      coded_name:
        "*left posterior paraspinal muscle trigger point CSI [137260]",
    },
  },
  {
    id: "269589",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*LITHOTRIPSY, EXTRACORPOREAL SHOCKWAVE LITHOTRIPSY (ESWL)",
      normalized_name:
        "*lithotripsy, extracorporeal shockwave lithotripsy (eswl) [700635]",
      vendor_code: "700635",
      coded_name:
        "*LITHOTRIPSY, EXTRACORPOREAL SHOCKWAVE LITHOTRIPSY (ESWL) [700635]",
    },
  },
  {
    id: "303602",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*liver Bx",
      normalized_name: "*liver bx [137260]",
      vendor_code: "137260",
      coded_name: "*liver Bx [137260]",
    },
  },
  {
    id: "139404",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Local With Mac* Embolization, Transcatheter Therapy",
      normalized_name:
        "*local with mac* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*Local With Mac* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "303569",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Lower Extremity Angiogram / Revascularization",
      normalized_name:
        "*lower extremity angiogram / revascularization [137260]",
      vendor_code: "137260",
      coded_name: "*Lower Extremity Angiogram / Revascularization [137260]",
    },
  },
  {
    id: "151153",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Lumbar Puncture With Fluoroscopy* General Anesthesia* Embolization, Transcatheter Therapy",
      normalized_name:
        "*lumbar puncture with fluoroscopy* general anesthesia* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "*Lumbar Puncture With Fluoroscopy* General Anesthesia* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "341257",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*lvm to call me back*COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "*lvm to call me back*colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name: "*lvm to call me back*COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "33881",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "88.40",
      name: "*Mac/General Anesthesia*Angiogram",
      normalized_name: "*mac/general anesthesia*angiogram [88.40]",
      vendor_code: null,
      coded_name: "*Mac/General Anesthesia*Angiogram [88.40]",
    },
  },
  {
    id: "303621",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Medical Liver Bx (16g Needle)",
      normalized_name: "*medical liver bx (16g needle) [137260]",
      vendor_code: "137260",
      coded_name: "*Medical Liver Bx (16g Needle) [137260]",
    },
  },
  {
    id: "38886",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "81.0x",
      name: "*Medtronic Rialto Case Reassigned To Dr. Ganocy Will Need O Arm Jackson Table D/W Susan",
      normalized_name:
        "*medtronic rialto case reassigned to dr. ganocy will need o arm jackson table d/w susan [81.0x]",
      vendor_code: null,
      coded_name:
        "*Medtronic Rialto Case Reassigned To Dr. Ganocy Will Need O Arm Jackson Table D/W Susan [81.0x]",
    },
  },
  {
    id: "14826",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "86.74",
      name: "*Micro* Anterolateral Free Flap Reconstruction Of Right Lower Extremity, Split Thickness Skin Graft Equipment: Microscope, Micro Set-Up Wound Vac Dermatome Handheld Harmonic",
      normalized_name:
        "*micro* anterolateral free flap reconstruction of right lower extremity, split thickness skin graft equipment: microscope, micro set-up wound vac dermatome handheld harmonic [86.74]",
      vendor_code: null,
      coded_name:
        "*Micro* Anterolateral Free Flap Reconstruction Of Right Lower Extremity, Split Thickness Skin Graft Equipment: Microscope, Micro Set-Up Wound Vac Dermatome Handheld Harmonic [86.74]",
    },
  },
  {
    id: "22477",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "85.89",
      name: "*Micro* Bilateral Breast Recon With Diep Flaps",
      normalized_name: "*micro* bilateral breast recon with diep flaps [85.89]",
      vendor_code: null,
      coded_name: "*Micro* Bilateral Breast Recon With Diep Flaps [85.89]",
    },
  },
  {
    id: "20201",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "85.89",
      name: "*Micro* Bilateral Diep Flap Reconstruction, Bilateral Implant Removal And Capsulectomy",
      normalized_name:
        "*micro* bilateral diep flap reconstruction, bilateral implant removal and capsulectomy [85.89]",
      vendor_code: null,
      coded_name:
        "*Micro* Bilateral Diep Flap Reconstruction, Bilateral Implant Removal And Capsulectomy [85.89]",
    },
  },
  {
    id: "11256",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "85.89",
      name: "*Micro* Breast Reconstruction W Microvascular Free Flap, Bilateral",
      normalized_name:
        "*micro* breast reconstruction w microvascular free flap, bilateral [85.89]",
      vendor_code: null,
      coded_name:
        "*Micro* Breast Reconstruction W Microvascular Free Flap, Bilateral [85.89]",
    },
  },
  {
    id: "22000",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "85.89",
      name: "*Micro* Breast Reconstruction W Microvascular Free Flap; Bilateral Delayed Breast Recon With Diep Flap Assist: Liu And O'Rielly Or Gallus",
      normalized_name:
        "*micro* breast reconstruction w microvascular free flap; bilateral delayed breast recon with diep flap assist: liu and o'rielly or gallus [85.89]",
      vendor_code: null,
      coded_name:
        "*Micro* Breast Reconstruction W Microvascular Free Flap; Bilateral Delayed Breast Recon With Diep Flap Assist: Liu And O'Rielly Or Gallus [85.89]",
    },
  },
  {
    id: "14296",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "86.74",
      name: "*Micro* Free Anterolateral Thigh Flap To Right Lower Extremity Equipment: Handheld Harmonic Micro Tray, Instruments, Couplers Microscope -Pentero 900 Dermatome Sterile And Non-Sterile Doppler Bipolar",
      normalized_name:
        "*micro* free anterolateral thigh flap to right lower extremity equipment: handheld harmonic micro tray, instruments, couplers microscope -pentero 900 dermatome sterile and non-sterile doppler bipolar [86.74]",
      vendor_code: null,
      coded_name:
        "*Micro* Free Anterolateral Thigh Flap To Right Lower Extremity Equipment: Handheld Harmonic Micro Tray, Instruments, Couplers Microscope -Pentero 900 Dermatome Sterile And Non-Sterile Doppler Bipolar [86.74]",
    },
  },
  {
    id: "15755",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "86.74",
      name: "*Micro* Free Flap Reconstruction Of Right Lower Extremity Wound Joint Case With Craft Equipment: Microscope Micro Equipment 18 In Arm Tourniquet Bipolar X 2",
      normalized_name:
        "*micro* free flap reconstruction of right lower extremity wound joint case with craft equipment: microscope micro equipment 18 in arm tourniquet bipolar x 2 [86.74]",
      vendor_code: null,
      coded_name:
        "*Micro* Free Flap Reconstruction Of Right Lower Extremity Wound Joint Case With Craft Equipment: Microscope Micro Equipment 18 In Arm Tourniquet Bipolar X 2 [86.74]",
    },
  },
  {
    id: "13750",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "86.72",
      name: "*Micro* Radial Forearm Free Flap Reconstruction Of Right Scalp Wound, Split Thickness Skin Graft, Wound Vac Placement",
      normalized_name:
        "*micro* radial forearm free flap reconstruction of right scalp wound, split thickness skin graft, wound vac placement [86.72]",
      vendor_code: null,
      coded_name:
        "*Micro* Radial Forearm Free Flap Reconstruction Of Right Scalp Wound, Split Thickness Skin Graft, Wound Vac Placement [86.72]",
    },
  },
  {
    id: "26237",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "85.89",
      name: "*Micro* Right Breast Reconstruction With Diep Flap",
      normalized_name:
        "*micro* right breast reconstruction with diep flap [85.89]",
      vendor_code: null,
      coded_name: "*Micro* Right Breast Reconstruction With Diep Flap [85.89]",
    },
  },
  {
    id: "17041",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "85.89",
      name: "*Micro* Right Delayed Breast Reconstruction W Microvascular Free Flap",
      normalized_name:
        "*micro* right delayed breast reconstruction w microvascular free flap [85.89]",
      vendor_code: null,
      coded_name:
        "*Micro* Right Delayed Breast Reconstruction W Microvascular Free Flap [85.89]",
    },
  },
  {
    id: "265505",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*MISSING INFO* CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "*missing info* cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "*MISSING INFO* CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "185678",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Myelogram* Angiogram",
      normalized_name: "*myelogram* angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*Myelogram* Angiogram [192267]",
    },
  },
  {
    id: "180647",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Myelogram* Embolization, Transcatheter Therapy",
      normalized_name:
        "*myelogram* embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name: "*Myelogram* Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "303610",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Neck lymph node biopsy any MD",
      normalized_name: "*neck lymph node biopsy any md [137260]",
      vendor_code: "137260",
      coded_name: "*Neck lymph node biopsy any MD [137260]",
    },
  },
  {
    id: "295865",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NEED CPT CODES* RT RECONSTRUCTION, KNEE, ACL, Autograft",
      normalized_name:
        "*need cpt codes* rt reconstruction, knee, acl, autograft [700199]",
      vendor_code: "700199",
      coded_name:
        "*NEED CPT CODES* RT RECONSTRUCTION, KNEE, ACL, Autograft [700199]",
    },
  },
  {
    id: "279710",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Need verifcation(SEE NOTE) Right Breast Needle Localized Excisional Biopsy",
      normalized_name:
        "*need verifcation(see note) right breast needle localized excisional biopsy [703124]",
      vendor_code: "703124",
      coded_name:
        "*Need verifcation(SEE NOTE) Right Breast Needle Localized Excisional Biopsy [703124]",
    },
  },
  {
    id: "312548",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NEEDING NEW DATE* RT TOTAL KNEE REPLACEMENT",
      normalized_name: "*needing new date* rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*NEEDING NEW DATE* RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "264885",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NEEDS TO BE MAIN* RT LONG FINGER REPAIR, TENDON",
      normalized_name:
        "*needs to be main* rt long finger repair, tendon [701505]",
      vendor_code: "701505",
      coded_name: "*NEEDS TO BE MAIN* RT LONG FINGER REPAIR, TENDON [701505]",
    },
  },
  {
    id: "271299",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NO PRIV* LARYNGOSCOPY, CO 2 LASER EXCISION OF TUMOR, RESECTION TRACHEAL STENOSIS, RIGID BRONCHOSCOPY, CENTRAL TRACHIAL DILATION",
      normalized_name:
        "*no priv* laryngoscopy, co 2 laser excision of tumor, resection tracheal stenosis, rigid bronchoscopy, central trachial dilation [703225]",
      vendor_code: "703225",
      coded_name:
        "*NO PRIV* LARYNGOSCOPY, CO 2 LASER EXCISION OF TUMOR, RESECTION TRACHEAL STENOSIS, RIGID BRONCHOSCOPY, CENTRAL TRACHIAL DILATION [703225]",
    },
  },
  {
    id: "296196",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NO TIME AVAILABLE* EGD WITH ANESTHESIA",
      normalized_name: "*no time available* egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "*NO TIME AVAILABLE* EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "300508",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NO TIME LEFT IN BLOCK* REPAIR, TENDON, HAND - Left middle finger",
      normalized_name:
        "*no time left in block* repair, tendon, hand - left middle finger [701505]",
      vendor_code: "701505",
      coded_name:
        "*NO TIME LEFT IN BLOCK* REPAIR, TENDON, HAND - Left middle finger [701505]",
    },
  },
  {
    id: "316823",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*no vm set up need to r/s*COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "*no vm set up need to r/s*colonoscopy with anesthesia [702115]",
      vendor_code: "702115",
      coded_name:
        "*no vm set up need to r/s*COLONOSCOPY WITH ANESTHESIA [702115]",
    },
  },
  {
    id: "346025",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NOT AT VH* EGD WITH ANESTHESIA",
      normalized_name: "*not at vh* egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "*NOT AT VH* EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "346315",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NOT AT VH* Left Breast Re-Excision Lumpectomy",
      normalized_name:
        "*not at vh* left breast re-excision lumpectomy [700311]",
      vendor_code: "700311",
      coded_name: "*NOT AT VH* Left Breast Re-Excision Lumpectomy [700311]",
    },
  },
  {
    id: "92140",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Not Ready* Knee Replacement Total- Home Recovery",
      normalized_name:
        "*not ready* knee replacement total- home recovery [150898]",
      vendor_code: "150898",
      coded_name: "*Not Ready* Knee Replacement Total- Home Recovery [150898]",
    },
  },
  {
    id: "342208",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*NS* LT ORIF, SHOULDER",
      normalized_name: "*ns* lt orif, shoulder [701373]",
      vendor_code: "701373",
      coded_name: "*NS* LT ORIF, SHOULDER [701373]",
    },
  },
  {
    id: "303543",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*OVER BLOCK TIME- PLEASE RESCHEDULE* CYSTOSCOPY, WITH SUPRAPUBIC CATHETER INSERTION",
      normalized_name:
        "*over block time- please reschedule* cystoscopy, with suprapubic catheter insertion [702297]",
      vendor_code: "702297",
      coded_name:
        "*OVER BLOCK TIME- PLEASE RESCHEDULE* CYSTOSCOPY, WITH SUPRAPUBIC CATHETER INSERTION [702297]",
    },
  },
  {
    id: "303564",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*PAE",
      normalized_name: "*pae [137260]",
      vendor_code: "137260",
      coded_name: "*PAE [137260]",
    },
  },
  {
    id: "303618",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Paracentesis",
      normalized_name: "*paracentesis [137260]",
      vendor_code: "137260",
      coded_name: "*Paracentesis [137260]",
    },
  },
  {
    id: "295666",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Patient Cancelled: pending new date, desires early 2023** Left Breast Ductal Excision",
      normalized_name:
        "*patient cancelled: pending new date, desires early 2023** left breast ductal excision [703111]",
      vendor_code: "703111",
      coded_name:
        "*Patient Cancelled: pending new date, desires early 2023** Left Breast Ductal Excision [703111]",
    },
  },
  {
    id: "169137",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Peds General Anesthesia* Angiogram",
      normalized_name: "*peds general anesthesia* angiogram [192267]",
      vendor_code: "192267",
      coded_name: "*Peds General Anesthesia* Angiogram [192267]",
    },
  },
  {
    id: "105192",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Peds Sedation Team* Nephrostomy- Right Placement",
      normalized_name:
        "*peds sedation team* nephrostomy- right placement [142705]",
      vendor_code: "142705",
      coded_name: "*Peds Sedation Team* Nephrostomy- Right Placement [142705]",
    },
  },
  {
    id: "111027",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Peds Sedation* Ct Guide For Biopsy, Left Renal",
      normalized_name:
        "*peds sedation* ct guide for biopsy, left renal [566720]",
      vendor_code: "566720",
      coded_name: "*Peds Sedation* Ct Guide For Biopsy, Left Renal [566720]",
    },
  },
  {
    id: "269930",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*PENDING 8/5 OC* Right Breast Re-Excision Lumpectomy",
      normalized_name:
        "*pending 8/5 oc* right breast re-excision lumpectomy [700311]",
      vendor_code: "700311",
      coded_name:
        "*PENDING 8/5 OC* Right Breast Re-Excision Lumpectomy [700311]",
    },
  },
  {
    id: "292298",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Pending reschedule/resolution of oral concerns* Left Breast 2 wire / 2 site Needle Localized Lumpectomy, Sentinel Lymph Node Biopsy",
      normalized_name:
        "*pending reschedule/resolution of oral concerns* left breast 2 wire / 2 site needle localized lumpectomy, sentinel lymph node biopsy [701993]",
      vendor_code: "701993",
      coded_name:
        "*Pending reschedule/resolution of oral concerns* Left Breast 2 wire / 2 site Needle Localized Lumpectomy, Sentinel Lymph Node Biopsy [701993]",
    },
  },
  {
    id: "297049",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*PENDING SURGERY DATE* Left SAVI Scout Seed Guided Excisional Biopsy",
      normalized_name:
        "*pending surgery date* left savi scout seed guided excisional biopsy [703124]",
      vendor_code: "703124",
      coded_name:
        "*PENDING SURGERY DATE* Left SAVI Scout Seed Guided Excisional Biopsy [703124]",
    },
  },
  {
    id: "304430",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*PENDING SURGERY DATE* Right SAVI Seed Guided Excisional Biopsy",
      normalized_name:
        "*pending surgery date* right savi seed guided excisional biopsy [703124]",
      vendor_code: "703124",
      coded_name:
        "*PENDING SURGERY DATE* Right SAVI Seed Guided Excisional Biopsy [703124]",
    },
  },
  {
    id: "194352",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Pericardiectomy* (unable to find actual procedure), excision of pericardium",
      normalized_name:
        "*pericardiectomy* (unable to find actual procedure), excision of pericardium [106445]",
      vendor_code: "106445",
      coded_name:
        "*Pericardiectomy* (unable to find actual procedure), excision of pericardium [106445]",
    },
  },
  {
    id: "299996",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*PLEASE ADD CODES* URETEROSCOPY WITH THULIUM",
      normalized_name: "*please add codes* ureteroscopy with thulium [700940]",
      vendor_code: "700940",
      coded_name: "*PLEASE ADD CODES* URETEROSCOPY WITH THULIUM [700940]",
    },
  },
  {
    id: "291401",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*PLEASE FINISH CASE* EGD/COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "*please finish case* egd/colonoscopy with anesthesia [702109]",
      vendor_code: "702109",
      coded_name:
        "*PLEASE FINISH CASE* EGD/COLONOSCOPY WITH ANESTHESIA [702109]",
    },
  },
  {
    id: "303614",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Port check, repositioning, exchange",
      normalized_name: "*port check, repositioning, exchange [137260]",
      vendor_code: "137260",
      coded_name: "*Port check, repositioning, exchange [137260]",
    },
  },
  {
    id: "291532",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*PT CANCELLED* CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "*pt cancelled* cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "*PT CANCELLED* CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "316481",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*r/s* LT BUNIONECTOMY, DMO with akin osteotomy",
      normalized_name:
        "*r/s* lt bunionectomy, dmo with akin osteotomy [700328]",
      vendor_code: "700328",
      coded_name: "*r/s* LT BUNIONECTOMY, DMO with akin osteotomy [700328]",
    },
  },
  {
    id: "303601",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Renal bx",
      normalized_name: "*renal bx [137260]",
      vendor_code: "137260",
      coded_name: "*Renal bx [137260]",
    },
  },
  {
    id: "22219",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "07.62",
      name: "*Repair Of Cerebrospinal Fluid",
      normalized_name: "*repair of cerebrospinal fluid [07.62]",
      vendor_code: null,
      coded_name: "*Repair Of Cerebrospinal Fluid [07.62]",
    },
  },
  {
    id: "310559",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RESEARCH **TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT)",
      normalized_name:
        "*research **tavr (transcatheter aortic valve replacement) [cc 018]",
      vendor_code: "CC 018",
      coded_name:
        "*RESEARCH **TAVR (TRANSCATHETER AORTIC VALVE REPLACEMENT) [CC 018]",
    },
  },
  {
    id: "310312",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*research Trilluminate TRICUSPIDE VALVE clip INTERVENTION W/ANESTHESIA",
      normalized_name:
        "*research trilluminate tricuspide valve clip intervention w/anesthesia [angio 158a]",
      vendor_code: "ANGIO 158A",
      coded_name:
        "*research Trilluminate TRICUSPIDE VALVE clip INTERVENTION W/ANESTHESIA [ANGIO 158A]",
    },
  },
  {
    id: "303608",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*right parotid FNAB, not for asu",
      normalized_name: "*right parotid fnab, not for asu [137260]",
      vendor_code: "137260",
      coded_name: "*right parotid FNAB, not for asu [137260]",
    },
  },
  {
    id: "303566",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RLE venogram and pelvic vein reconstruction",
      normalized_name: "*rle venogram and pelvic vein reconstruction [137260]",
      vendor_code: "137260",
      coded_name: "*RLE venogram and pelvic vein reconstruction [137260]",
    },
  },
  {
    id: "260315",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS DUE TO # OF EGDS* EGD WITH ANESTHESIA",
      normalized_name: "*rs due to # of egds* egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "*RS DUE TO # OF EGDS* EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "261724",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS* 23H- LT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "*rs* 23h- lt total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "*RS* 23H- LT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "299129",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS* Aquablation",
      normalized_name: "*rs* aquablation [700937]",
      vendor_code: "700937",
      coded_name: "*RS* Aquablation [700937]",
    },
  },
  {
    id: "261725",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS* DOSD- RT REVERSE TOTAL SHOULDER",
      normalized_name: "*rs* dosd- rt reverse total shoulder [702604]",
      vendor_code: "702604",
      coded_name: "*RS* DOSD- RT REVERSE TOTAL SHOULDER [702604]",
    },
  },
  {
    id: "260357",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS* POSSIBLE STENT PLACEMENT, LOOPOSCOPY, LOOPOGRAM",
      normalized_name:
        "*rs* possible stent placement, looposcopy, loopogram [701644]",
      vendor_code: "701644",
      coded_name:
        "*RS* POSSIBLE STENT PLACEMENT, LOOPOSCOPY, LOOPOGRAM [701644]",
    },
  },
  {
    id: "270620",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS* RT KNEE ARTHROSCOPY WITH LATERAL MENISCECTOMY",
      normalized_name:
        "*rs* rt knee arthroscopy with lateral meniscectomy [700206]",
      vendor_code: "700206",
      coded_name: "*RS* RT KNEE ARTHROSCOPY WITH LATERAL MENISCECTOMY [700206]",
    },
  },
  {
    id: "312948",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS* RT LATERAL ULNAR COLLATERAL LIGAMENT REPAIR OF ELBOW WITH LOCAL TISSUE",
      normalized_name:
        "*rs* rt lateral ulnar collateral ligament repair of elbow with local tissue [700953]",
      vendor_code: "700953",
      coded_name:
        "*RS* RT LATERAL ULNAR COLLATERAL LIGAMENT REPAIR OF ELBOW WITH LOCAL TISSUE [700953]",
    },
  },
  {
    id: "261734",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*RS* RT SHOULDER ARTHROSCOPIC DEBRIDEMENT, SUBACROMIAL DECOMPRESSION, POSSIBLE RCR",
      normalized_name:
        "*rs* rt shoulder arthroscopic debridement, subacromial decompression, possible rcr [700228]",
      vendor_code: "700228",
      coded_name:
        "*RS* RT SHOULDER ARTHROSCOPIC DEBRIDEMENT, SUBACROMIAL DECOMPRESSION, POSSIBLE RCR [700228]",
    },
  },
  {
    id: "302270",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*SEND BACK* 23H- RT TOTAL KNEE REPLACEMENT",
      normalized_name: "*send back* 23h- rt total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "*SEND BACK* 23H- RT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "53383",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Stage 1* Posterior - Revision Instrumentation",
      normalized_name:
        "*stage 1* posterior - revision instrumentation [10700006279]",
      vendor_code: "10700006279",
      coded_name:
        "*Stage 1* Posterior - Revision Instrumentation [10700006279]",
    },
  },
  {
    id: "53790",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Stage 1* Posterior Sacral Decompression With Ligation Of S1 Nerve Root",
      normalized_name:
        "*stage 1* posterior sacral decompression with ligation of s1 nerve root [10700001176]",
      vendor_code: "10700001176",
      coded_name:
        "*Stage 1* Posterior Sacral Decompression With Ligation Of S1 Nerve Root [10700001176]",
    },
  },
  {
    id: "59238",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Stage 1* Resection Heterotopic Ossification Bone Hip",
      normalized_name:
        "*stage 1* resection heterotopic ossification bone hip [10700004781]",
      vendor_code: "10700004781",
      coded_name:
        "*Stage 1* Resection Heterotopic Ossification Bone Hip [10700004781]",
    },
  },
  {
    id: "53385",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Stage 2* Anterior Resection Thoracic Mass",
      normalized_name:
        "*stage 2* anterior resection thoracic mass [10700007080]",
      vendor_code: "10700007080",
      coded_name: "*Stage 2* Anterior Resection Thoracic Mass [10700007080]",
    },
  },
  {
    id: "54744",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Stage 2* En Bloc Sacrectomy",
      normalized_name: "*stage 2* en bloc sacrectomy [10700005187]",
      vendor_code: "10700005187",
      coded_name: "*Stage 2* En Bloc Sacrectomy [10700005187]",
    },
  },
  {
    id: "59239",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Stage 2* Resection Heterotopic Ossification Bone Hip",
      normalized_name:
        "*stage 2* resection heterotopic ossification bone hip [10700004781]",
      vendor_code: "10700004781",
      coded_name:
        "*Stage 2* Resection Heterotopic Ossification Bone Hip [10700004781]",
    },
  },
  {
    id: "53792",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Stage Ii* Anterior S1 Nerve Root Tumor Resection",
      normalized_name:
        "*stage ii* anterior s1 nerve root tumor resection [10700007082]",
      vendor_code: "10700007082",
      coded_name:
        "*Stage Ii* Anterior S1 Nerve Root Tumor Resection [10700007082]",
    },
  },
  {
    id: "303635",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*TACE with Powder",
      normalized_name: "*tace with powder [137260]",
      vendor_code: "137260",
      coded_name: "*TACE with Powder [137260]",
    },
  },
  {
    id: "332711",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*TEMPORARY SCHEDULE HOLD* CASTLE ORTHO/PODIATRY CPT TBD",
      normalized_name:
        "*temporary schedule hold* castle ortho/podiatry cpt tbd [rcmc castle temp]",
      vendor_code: "RCMC CASTLE TEMP",
      coded_name:
        "*TEMPORARY SCHEDULE HOLD* CASTLE ORTHO/PODIATRY CPT TBD [RCMC CASTLE TEMP]",
    },
  },
  {
    id: "303611",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Thyroid Bx with Sedation",
      normalized_name: "*thyroid bx with sedation [137260]",
      vendor_code: "137260",
      coded_name: "*Thyroid Bx with Sedation [137260]",
    },
  },
  {
    id: "263660",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*TIME IS BLOCKED FOR LUNCH- DENIED BY RENEE* EGD WITH BANDING",
      normalized_name:
        "*time is blocked for lunch- denied by renee* egd with banding [702783]",
      vendor_code: "702783",
      coded_name:
        "*TIME IS BLOCKED FOR LUNCH- DENIED BY RENEE* EGD WITH BANDING [702783]",
    },
  },
  {
    id: "260290",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*TIME UNAVAILABLE EGD WITH ANESTHESIA",
      normalized_name: "*time unavailable egd with anesthesia [702107]",
      vendor_code: "702107",
      coded_name: "*TIME UNAVAILABLE EGD WITH ANESTHESIA [702107]",
    },
  },
  {
    id: "256105",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*TO BE DONE IN IR ROOM OK PER JODY* CATHETERIZATION, HEART, LEFT LHC VIA UNKNOWN APPROACH W/ POSS STENT",
      normalized_name:
        "*to be done in ir room ok per jody* catheterization, heart, left lhc via unknown approach w/ poss stent [700366]",
      vendor_code: "700366",
      coded_name:
        "*TO BE DONE IN IR ROOM OK PER JODY* CATHETERIZATION, HEART, LEFT LHC VIA UNKNOWN APPROACH W/ POSS STENT [700366]",
    },
  },
  {
    id: "304465",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*WADA Test",
      normalized_name: "*wada test [137260]",
      vendor_code: "137260",
      coded_name: "*WADA Test [137260]",
    },
  },
  {
    id: "293168",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*WAITING ON RS* EGD/COLONOSCOPY WITH ANESTHESIA",
      normalized_name:
        "*waiting on rs* egd/colonoscopy with anesthesia [702109]",
      vendor_code: "702109",
      coded_name: "*WAITING ON RS* EGD/COLONOSCOPY WITH ANESTHESIA [702109]",
    },
  },
  {
    id: "274796",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*WE CAN NOT DO TOTALS IN VDSC* REVERSE TOTAL SHOULDER REPLACEMENT",
      normalized_name:
        "*we can not do totals in vdsc* reverse total shoulder replacement [701572]",
      vendor_code: "701572",
      coded_name:
        "*WE CAN NOT DO TOTALS IN VDSC* REVERSE TOTAL SHOULDER REPLACEMENT [701572]",
    },
  },
  {
    id: "303617",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Y-90 Mapping",
      normalized_name: "*y-90 mapping [137260]",
      vendor_code: "137260",
      coded_name: "*Y-90 Mapping [137260]",
    },
  },
  {
    id: "303632",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "*Yttrium-90 Radioembolization",
      normalized_name: "*yttrium-90 radioembolization [137260]",
      vendor_code: "137260",
      coded_name: "*Yttrium-90 Radioembolization [137260]",
    },
  },
  {
    id: "303020",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ / - AVR",
      normalized_name: "+ / - avr [106440]",
      vendor_code: "106440",
      coded_name: "+ / - AVR [106440]",
    },
  },
  {
    id: "55951",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ 27601-Left Repair Achilles Tendon",
      normalized_name: "+ 27601-left repair achilles tendon [10700004339]",
      vendor_code: "10700004339",
      coded_name: "+ 27601-Left Repair Achilles Tendon [10700004339]",
    },
  },
  {
    id: "94403",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ 27601-Left Repair Achilles Tendon, Posterior Compartment Fasciotomy,",
      normalized_name:
        "+ 27601-left repair achilles tendon, posterior compartment fasciotomy, [10700004339]",
      vendor_code: "10700004339",
      coded_name:
        "+ 27601-Left Repair Achilles Tendon, Posterior Compartment Fasciotomy, [10700004339]",
    },
  },
  {
    id: "98053",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ 27691- Right Excision Accessory Navicular Foot",
      normalized_name:
        "+ 27691- right excision accessory navicular foot [10700001523]",
      vendor_code: "10700001523",
      coded_name:
        "+ 27691- Right Excision Accessory Navicular Foot [10700001523]",
    },
  },
  {
    id: "171090",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ 27871-Right Tibiotalar And Syndesmotic Open Fusion",
      normalized_name:
        "+ 27871-right tibiotalar and syndesmotic open fusion [10700000141]",
      vendor_code: "10700000141",
      coded_name:
        "+ 27871-Right Tibiotalar And Syndesmotic Open Fusion [10700000141]",
    },
  },
  {
    id: "277669",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ Bravo intraesophageal pH study",
      normalized_name: "+ bravo intraesophageal ph study [106490]",
      vendor_code: "106490",
      coded_name: "+ Bravo intraesophageal pH study [106490]",
    },
  },
  {
    id: "199131",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ ICD RA lead revision to follow with SK only - with RA lead dislodgement - has BS DC ICD 10/2/19",
      normalized_name:
        "+ icd ra lead revision to follow with sk only - with ra lead dislodgement - has bs dc icd 10/2/19 [142222]",
      vendor_code: "142222",
      coded_name:
        "+ ICD RA lead revision to follow with SK only - with RA lead dislodgement - has BS DC ICD 10/2/19 [142222]",
    },
  },
  {
    id: "145325",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+ Percutaneous Screws",
      normalized_name: "+ percutaneous screws [10700001175]",
      vendor_code: "10700001175",
      coded_name: "+ Percutaneous Screws [10700001175]",
    },
  },
  {
    id: "105908",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+- Replacement Mitral Valve",
      normalized_name: "+- replacement mitral valve [10700006708]",
      vendor_code: "10700006708",
      coded_name: "+- Replacement Mitral Valve [10700006708]",
    },
  },
  {
    id: "104434",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- Commando Procedure (psb)",
      normalized_name: "+/- commando procedure (psb) [10700002392]",
      vendor_code: "10700002392",
      coded_name: "+/- Commando Procedure (psb) [10700002392]",
    },
  },
  {
    id: "176865",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- Endoscopic Ultrasound",
      normalized_name: "+/- endoscopic ultrasound [129150]",
      vendor_code: "129150",
      coded_name: "+/- Endoscopic Ultrasound [129150]",
    },
  },
  {
    id: "310772",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- ERCP W/ ANESTHESIA",
      normalized_name: "+/- ercp w/ anesthesia [endo 256a]",
      vendor_code: "ENDO 256A",
      coded_name: "+/- ERCP W/ ANESTHESIA [ENDO 256A]",
    },
  },
  {
    id: "268546",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- harvest of the Lt radial artery",
      normalized_name: "+/- harvest of the lt radial artery [701298]",
      vendor_code: "701298",
      coded_name: "+/- harvest of the Lt radial artery [701298]",
    },
  },
  {
    id: "314746",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- POSSIBLE SCLERAL BUCKLE, VITRECTOMY, SILICONE OIL VERSUS GAS FLUID EXCHANGE",
      normalized_name:
        "+/- possible scleral buckle, vitrectomy, silicone oil versus gas fluid exchange [703637]",
      vendor_code: "703637",
      coded_name:
        "+/- POSSIBLE SCLERAL BUCKLE, VITRECTOMY, SILICONE OIL VERSUS GAS FLUID EXCHANGE [703637]",
    },
  },
  {
    id: "281087",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- PUSH ENTEROSCOPY WITH ANESTHESIA",
      normalized_name: "+/- push enteroscopy with anesthesia [702112]",
      vendor_code: "702112",
      coded_name: "+/- PUSH ENTEROSCOPY WITH ANESTHESIA [702112]",
    },
  },
  {
    id: "291567",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- RADIAL UPPER ENDOSCOPIC ULTRASOUND WITH ANESTHESIA",
      normalized_name:
        "+/- radial upper endoscopic ultrasound with anesthesia [702268]",
      vendor_code: "702268",
      coded_name:
        "+/- RADIAL UPPER ENDOSCOPIC ULTRASOUND WITH ANESTHESIA [702268]",
    },
  },
  {
    id: "308106",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- TVT",
      normalized_name: "+/- tvt [gu 025]",
      vendor_code: "GU 025",
      coded_name: "+/- TVT [GU 025]",
    },
  },
  {
    id: "221639",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/- VITRECTOMY, SUBRETINAL TISSUE PLASMINOGEN ACTIVATOR",
      normalized_name:
        "+/- vitrectomy, subretinal tissue plasminogen activator [703073]",
      vendor_code: "703073",
      coded_name:
        "+/- VITRECTOMY, SUBRETINAL TISSUE PLASMINOGEN ACTIVATOR [703073]",
    },
  },
  {
    id: "311043",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/-ERCP W/ ANESTHESIA",
      normalized_name: "+/-ercp w/ anesthesia [endo 256a]",
      vendor_code: "ENDO 256A",
      coded_name: "+/-ERCP W/ ANESTHESIA [ENDO 256A]",
    },
  },
  {
    id: "321530",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+/-EUS W/ ANESTHESIA, GJ stent",
      normalized_name: "+/-eus w/ anesthesia, gj stent [endo 259a]",
      vendor_code: "ENDO 259A",
      coded_name: "+/-EUS W/ ANESTHESIA, GJ stent [ENDO 259A]",
    },
  },
  {
    id: "108640",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+Cataract Surgery W Iol-Phaco S/P Rk Wants Os Set Near Needs 30 Min Pre Op Xx Optom With Me.",
      normalized_name:
        "+cataract surgery w iol-phaco s/p rk wants os set near needs 30 min pre op xx optom with me. [150897]",
      vendor_code: "150897",
      coded_name:
        "+Cataract Surgery W Iol-Phaco S/P Rk Wants Os Set Near Needs 30 Min Pre Op Xx Optom With Me. [150897]",
    },
  },
  {
    id: "42045",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+General Anesthesia+ Embolization, Transcatheter Therapy",
      normalized_name:
        "+general anesthesia+ embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "+General Anesthesia+ Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "144401",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "+Rectal Exam Under Anesthesia",
      normalized_name: "+rectal exam under anesthesia [106812]",
      vendor_code: "106812",
      coded_name: "+Rectal Exam Under Anesthesia [106812]",
    },
  },
  {
    id: "120588",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- 3.5 Hrs",
      normalized_name: "- 3.5 hrs [208331]",
      vendor_code: "208331",
      coded_name: "- 3.5 Hrs [208331]",
    },
  },
  {
    id: "186487",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-",
      normalized_name: "- [t10]",
      vendor_code: "T10",
      coded_name: "- [T10]",
    },
  },
  {
    id: "17894",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "- A Cervical Epidural Steroid Injection",
      normalized_name: "- a cervical epidural steroid injection [03.92]",
      vendor_code: null,
      coded_name: "- A Cervical Epidural Steroid Injection [03.92]",
    },
  },
  {
    id: "24209",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "04.49",
      name: "- Care Call Radiofrequency Neurolysis",
      normalized_name: "- care call radiofrequency neurolysis [04.49]",
      vendor_code: null,
      coded_name: "- Care Call Radiofrequency Neurolysis [04.49]",
    },
  },
  {
    id: "18337",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "- Cervical Epidural Steroid Injection",
      normalized_name: "- cervical epidural steroid injection [03.92]",
      vendor_code: null,
      coded_name: "- Cervical Epidural Steroid Injection [03.92]",
    },
  },
  {
    id: "345626",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- checkl BP prior to scheduling",
      normalized_name: "- checkl bp prior to scheduling [106330]",
      vendor_code: "106330",
      coded_name: "- checkl BP prior to scheduling [106330]",
    },
  },
  {
    id: "262450",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- cystoscopy with left ureteral stent (preincision)",
      normalized_name:
        "- cystoscopy with left ureteral stent (preincision) [700518]",
      vendor_code: "700518",
      coded_name:
        "- cystoscopy with left ureteral stent (preincision) [700518]",
    },
  },
  {
    id: "120479",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Double Lumen Hickman Central Line Insertion",
      normalized_name: "- double lumen hickman central line insertion [209131]",
      vendor_code: "209131",
      coded_name: "- Double Lumen Hickman Central Line Insertion [209131]",
    },
  },
  {
    id: "128268",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- General Anesthesia -Embolization, Transcatheter Therapy",
      normalized_name:
        "- general anesthesia -embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "- General Anesthesia -Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "96755",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- General Anesthesia- Embolization, Transcatheter Therapy",
      normalized_name:
        "- general anesthesia- embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "- General Anesthesia- Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "94933",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Home Recovery - Left Total Hip Replacement",
      normalized_name: "- home recovery - left total hip replacement [106731]",
      vendor_code: "106731",
      coded_name: "- Home Recovery - Left Total Hip Replacement [106731]",
    },
  },
  {
    id: "95700",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Home Recovery - Left Total Knee Replacement",
      normalized_name: "- home recovery - left total knee replacement [150898]",
      vendor_code: "150898",
      coded_name: "- Home Recovery - Left Total Knee Replacement [150898]",
    },
  },
  {
    id: "80531",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Home Recovery - Right Total Hip Replacement",
      normalized_name: "- home recovery - right total hip replacement [106731]",
      vendor_code: "106731",
      coded_name: "- Home Recovery - Right Total Hip Replacement [106731]",
    },
  },
  {
    id: "91119",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Home Recovery - Right Total Knee Replacement",
      normalized_name:
        "- home recovery - right total knee replacement [150898]",
      vendor_code: "150898",
      coded_name: "- Home Recovery - Right Total Knee Replacement [150898]",
    },
  },
  {
    id: "9749",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "81.51",
      name: "- Home Recovery -Hip Replacement Total",
      normalized_name: "- home recovery -hip replacement total [81.51]",
      vendor_code: null,
      coded_name: "- Home Recovery -Hip Replacement Total [81.51]",
    },
  },
  {
    id: "168016",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Home Recovery Hip Replacement Anterior Approach Total",
      normalized_name:
        "- home recovery hip replacement anterior approach total [220154]",
      vendor_code: "220154",
      coded_name:
        "- Home Recovery Hip Replacement Anterior Approach Total [220154]",
    },
  },
  {
    id: "267798",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Home Recovery KNEE REPLACEMENT TOTAL",
      normalized_name: "- home recovery knee replacement total [150898]",
      vendor_code: "150898",
      coded_name: "- Home Recovery KNEE REPLACEMENT TOTAL [150898]",
    },
  },
  {
    id: "93680",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Home Recovery Left Knee Replacement Total",
      normalized_name: "- home recovery left knee replacement total [150898]",
      vendor_code: "150898",
      coded_name: "- Home Recovery Left Knee Replacement Total [150898]",
    },
  },
  {
    id: "21037",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "81.54",
      name: "- Home Recovery-Knee Replacement Total",
      normalized_name: "- home recovery-knee replacement total [81.54]",
      vendor_code: null,
      coded_name: "- Home Recovery-Knee Replacement Total [81.54]",
    },
  },
  {
    id: "328266",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- hysteropexy vaginal sacrospinous ligament procedure hysteropexy",
      normalized_name:
        "- hysteropexy vaginal sacrospinous ligament procedure hysteropexy [obgy 043]",
      vendor_code: "OBGY 043",
      coded_name:
        "- hysteropexy vaginal sacrospinous ligament procedure hysteropexy [OBGY 043]",
    },
  },
  {
    id: "330436",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Hysteroscopy with Dilation and curettage, endometrial ablation possible operative hysteroscope",
      normalized_name:
        "- hysteroscopy with dilation and curettage, endometrial ablation possible operative hysteroscope [obgy 012]",
      vendor_code: "OBGY 012",
      coded_name:
        "- Hysteroscopy with Dilation and curettage, endometrial ablation possible operative hysteroscope [OBGY 012]",
    },
  },
  {
    id: "175124",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Knee Replacement Total - Home Recovery",
      normalized_name: "- knee replacement total - home recovery [150898]",
      vendor_code: "150898",
      coded_name: "- Knee Replacement Total - Home Recovery [150898]",
    },
  },
  {
    id: "178484",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Knee Replacement Total- Home Recovery",
      normalized_name: "- knee replacement total- home recovery [150898]",
      vendor_code: "150898",
      coded_name: "- Knee Replacement Total- Home Recovery [150898]",
    },
  },
  {
    id: "229323",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- KPHC Validation",
      normalized_name: "- kphc validation [106515]",
      vendor_code: "106515",
      coded_name: "- KPHC Validation [106515]",
    },
  },
  {
    id: "96042",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Laparoscopic Removal Of Essure Implants. Possible Hysteroscopy",
      normalized_name:
        "- laparoscopic removal of essure implants. possible hysteroscopy [106615]",
      vendor_code: "106615",
      coded_name:
        "- Laparoscopic Removal Of Essure Implants. Possible Hysteroscopy [106615]",
    },
  },
  {
    id: "95384",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Left Home Recovery Knee Replacement Total",
      normalized_name: "- left home recovery knee replacement total [150898]",
      vendor_code: "150898",
      coded_name: "- Left Home Recovery Knee Replacement Total [150898]",
    },
  },
  {
    id: "17593",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "- Lumbar Epidural Steroid Injection",
      normalized_name: "- lumbar epidural steroid injection [03.92]",
      vendor_code: null,
      coded_name: "- Lumbar Epidural Steroid Injection [03.92]",
    },
  },
  {
    id: "17572",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "- Lumbar Epidural Steroid Injection L4/5",
      normalized_name: "- lumbar epidural steroid injection l4/5 [03.92]",
      vendor_code: null,
      coded_name: "- Lumbar Epidural Steroid Injection L4/5 [03.92]",
    },
  },
  {
    id: "20063",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "- Lumbar Facet Joint (Zygapophyseal) Injection",
      normalized_name: "- lumbar facet joint (zygapophyseal) injection [03.92]",
      vendor_code: null,
      coded_name: "- Lumbar Facet Joint (Zygapophyseal) Injection [03.92]",
    },
  },
  {
    id: "96935",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Mac Anesthesia- Embolization, Transcatheter Therapy",
      normalized_name:
        "- mac anesthesia- embolization, transcatheter therapy [566732]",
      vendor_code: "566732",
      coded_name:
        "- Mac Anesthesia- Embolization, Transcatheter Therapy [566732]",
    },
  },
  {
    id: "117180",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Not Ready - Knee Replacement Total",
      normalized_name: "- not ready - knee replacement total [150898]",
      vendor_code: "150898",
      coded_name: "- Not Ready - Knee Replacement Total [150898]",
    },
  },
  {
    id: "112390",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Not Ready - Knee Replacement Total, Metal Allergy Implants",
      normalized_name:
        "- not ready - knee replacement total, metal allergy implants [150898]",
      vendor_code: "150898",
      coded_name:
        "- Not Ready - Knee Replacement Total, Metal Allergy Implants [150898]",
    },
  },
  {
    id: "110827",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Not Ready - Knee Replacement Total- Home Recovery",
      normalized_name:
        "- not ready - knee replacement total- home recovery [150898]",
      vendor_code: "150898",
      coded_name:
        "- Not Ready - Knee Replacement Total- Home Recovery [150898]",
    },
  },
  {
    id: "21145",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "04.81",
      name: "- Peripheral Nerve Block",
      normalized_name: "- peripheral nerve block [04.81]",
      vendor_code: null,
      coded_name: "- Peripheral Nerve Block [04.81]",
    },
  },
  {
    id: "20066",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "04.49",
      name: "- Radiofrequency Neurolysis",
      normalized_name: "- radiofrequency neurolysis [04.49]",
      vendor_code: null,
      coded_name: "- Radiofrequency Neurolysis [04.49]",
    },
  },
  {
    id: "20021",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "04.49",
      name: "- Radiofrequency Neurolysis R C2,3",
      normalized_name: "- radiofrequency neurolysis r c2,3 [04.49]",
      vendor_code: null,
      coded_name: "- Radiofrequency Neurolysis R C2,3 [04.49]",
    },
  },
  {
    id: "222986",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- REPAIR LIGAMENT ANKLE(psb)",
      normalized_name: "- repair ligament ankle(psb) [10700004518]",
      vendor_code: "10700004518",
      coded_name: "- REPAIR LIGAMENT ANKLE(psb) [10700004518]",
    },
  },
  {
    id: "316893",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- RIGHT BACK",
      normalized_name: "- right back [129257]",
      vendor_code: "129257",
      coded_name: "- RIGHT BACK [129257]",
    },
  },
  {
    id: "327543",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Right dequervains release",
      normalized_name: "- right dequervains release [hndelb 075]",
      vendor_code: "HNDELB 075",
      coded_name: "- Right dequervains release [HNDELB 075]",
    },
  },
  {
    id: "93064",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Right Home Recovery Knee Replacement Total",
      normalized_name: "- right home recovery knee replacement total [150898]",
      vendor_code: "150898",
      coded_name: "- Right Home Recovery Knee Replacement Total [150898]",
    },
  },
  {
    id: "276676",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "- Special Equipment: Drill and Orbis Microscope",
      normalized_name:
        "- special equipment: drill and orbis microscope [10700006071]",
      vendor_code: "10700006071",
      coded_name:
        "- Special Equipment: Drill and Orbis Microscope [10700006071]",
    },
  },
  {
    id: "64140",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "77.51",
      name: "- Synthes Small Frag",
      normalized_name: "- synthes small frag [77.51]",
      vendor_code: null,
      coded_name: "- Synthes Small Frag [77.51]",
    },
  },
  {
    id: "18423",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: "03.92",
      name: "- Transforaminal Epidural Steroid Injection",
      normalized_name: "- transforaminal epidural steroid injection [03.92]",
      vendor_code: null,
      coded_name: "- Transforaminal Epidural Steroid Injection [03.92]",
    },
  },
  {
    id: "291529",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-- 23HR-- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "-- 23hr-- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "-- 23HR-- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "251085",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-- ischial bursa injection",
      normalized_name: "-- ischial bursa injection [snshlhdrjt]",
      vendor_code: "SNSHLHDRJT",
      coded_name: "-- ischial bursa injection [SNSHLHDRJT]",
    },
  },
  {
    id: "345903",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-------23 HOUR-------1ST - LLIF L2-3",
      normalized_name: "-------23 hour-------1st - llif l2-3 [702680]",
      vendor_code: "702680",
      coded_name: "-------23 HOUR-------1ST - LLIF L2-3 [702680]",
    },
  },
  {
    id: "314742",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-------DOSD--- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "-------dosd--- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "-------DOSD--- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "262799",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "------23 HR---TOTAL HIP REPLACEMENT, ANTERIOR APPROACH RIGHT",
      normalized_name:
        "------23 hr---total hip replacement, anterior approach right [701565]",
      vendor_code: "701565",
      coded_name:
        "------23 HR---TOTAL HIP REPLACEMENT, ANTERIOR APPROACH RIGHT [701565]",
    },
  },
  {
    id: "343339",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-----23 HOUR----INSERTION, SHUNT, LUMBOPERITONEAL, LAPAROSCOPIC",
      normalized_name:
        "-----23 hour----insertion, shunt, lumboperitoneal, laparoscopic [702773]",
      vendor_code: "702773",
      coded_name:
        "-----23 HOUR----INSERTION, SHUNT, LUMBOPERITONEAL, LAPAROSCOPIC [702773]",
    },
  },
  {
    id: "343685",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-----23 HOUR----ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY-XI",
      normalized_name:
        "-----23 hour----robot assisted laparoscopic partial nephrectomy-xi [702954]",
      vendor_code: "702954",
      coded_name:
        "-----23 HOUR----ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY-XI [702954]",
    },
  },
  {
    id: "343313",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-----23 hour---RIGHT L3-5 HEMILAMINECTOMY",
      normalized_name: "-----23 hour---right l3-5 hemilaminectomy [702865]",
      vendor_code: "702865",
      coded_name: "-----23 hour---RIGHT L3-5 HEMILAMINECTOMY [702865]",
    },
  },
  {
    id: "296047",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-----23 HOUR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "-----23 hour---right total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "-----23 HOUR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "296038",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-----23 HOUR---RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "-----23 hour---right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "-----23 HOUR---RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "343691",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-----23 HOUR---ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY-XI POSSIBLE OPEN, POSSIBLE RADICAL",
      normalized_name:
        "-----23 hour---robot assisted laparoscopic partial nephrectomy-xi possible open, possible radical [702954]",
      vendor_code: "702954",
      coded_name:
        "-----23 HOUR---ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY-XI POSSIBLE OPEN, POSSIBLE RADICAL [702954]",
    },
  },
  {
    id: "343314",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "-----23 HOUR---THYROIDECTOMY BILATERAL",
      normalized_name: "-----23 hour---thyroidectomy bilateral [701535]",
      vendor_code: "701535",
      coded_name: "-----23 HOUR---THYROIDECTOMY BILATERAL [701535]",
    },
  },
  {
    id: "341515",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR ----- LLIF L2-3",
      normalized_name: "----23 hour ----- llif l2-3 [702680]",
      vendor_code: "702680",
      coded_name: "----23 HOUR ----- LLIF L2-3 [702680]",
    },
  },
  {
    id: "343723",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 hour--- L2-4 Laminectomy",
      normalized_name: "----23 hour--- l2-4 laminectomy [702865]",
      vendor_code: "702865",
      coded_name: "----23 hour--- L2-4 Laminectomy [702865]",
    },
  },
  {
    id: "299566",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 hour--- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "----23 hour--- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----23 hour--- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "342967",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR----- BIOPSY, LYMPH NODE, CERVICAL LEFT",
      normalized_name:
        "----23 hour----- biopsy, lymph node, cervical left [700973]",
      vendor_code: "700973",
      coded_name: "----23 HOUR----- BIOPSY, LYMPH NODE, CERVICAL LEFT [700973]",
    },
  },
  {
    id: "346932",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 hour----CYSTOSCOPY, WITH TURP",
      normalized_name: "----23 hour----cystoscopy, with turp [702445]",
      vendor_code: "702445",
      coded_name: "----23 hour----CYSTOSCOPY, WITH TURP [702445]",
    },
  },
  {
    id: "285510",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR----LEFT TOTAL HIP REPLACEMENT REVISION",
      normalized_name:
        "----23 hour----left total hip replacement revision [701327]",
      vendor_code: "701327",
      coded_name: "----23 HOUR----LEFT TOTAL HIP REPLACEMENT REVISION [701327]",
    },
  },
  {
    id: "304654",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 hour---ANKLE FUSION RIGHT ANKLE, SUBTALAR JOINT FUSION RIGHT ANKLE",
      normalized_name:
        "----23 hour---ankle fusion right ankle, subtalar joint fusion right ankle [702168]",
      vendor_code: "702168",
      coded_name:
        "----23 hour---ANKLE FUSION RIGHT ANKLE, SUBTALAR JOINT FUSION RIGHT ANKLE [702168]",
    },
  },
  {
    id: "343680",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 hour---Completion thyroidectomy",
      normalized_name: "----23 hour---completion thyroidectomy [701535]",
      vendor_code: "701535",
      coded_name: "----23 hour---Completion thyroidectomy [701535]",
    },
  },
  {
    id: "295647",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 hour---FUNDOPLICATION, NISSEN, LAPAROSCOPIC",
      normalized_name:
        "----23 hour---fundoplication, nissen, laparoscopic [701125]",
      vendor_code: "701125",
      coded_name: "----23 hour---FUNDOPLICATION, NISSEN, LAPAROSCOPIC [701125]",
    },
  },
  {
    id: "301966",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 hour---INCISION AND DRAINAGE, RIGHT SHOULDER REGION, POSSIBLE REVISION RIGHT TOTAL SHOULDER TO REVERSE TOTAL SHOULDER, POSSIBLE CEMENT SPACER",
      normalized_name:
        "----23 hour---incision and drainage, right shoulder region, possible revision right total shoulder to reverse total shoulder, possible cement spacer [702320]",
      vendor_code: "702320",
      coded_name:
        "----23 hour---INCISION AND DRAINAGE, RIGHT SHOULDER REGION, POSSIBLE REVISION RIGHT TOTAL SHOULDER TO REVERSE TOTAL SHOULDER, POSSIBLE CEMENT SPACER [702320]",
    },
  },
  {
    id: "296048",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR---LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "----23 hour---left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----23 HOUR---LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "344361",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR---OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HIP RIGHT",
      normalized_name:
        "----23 hour---open reduction internal fixation (orif), fracture, hip right [701327]",
      vendor_code: "701327",
      coded_name:
        "----23 HOUR---OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HIP RIGHT [701327]",
    },
  },
  {
    id: "343799",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR---OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HIP RIGHT",
      normalized_name:
        "----23 hour---open reduction internal fixation (orif), fracture, hip right [702007]",
      vendor_code: "702007",
      coded_name:
        "----23 HOUR---OPEN REDUCTION INTERNAL FIXATION (ORIF), FRACTURE, HIP RIGHT [702007]",
    },
  },
  {
    id: "343806",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "----23 hour---right total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "----23 HOUR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "346589",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR---THYROIDECTOMY bilateral",
      normalized_name: "----23 hour---thyroidectomy bilateral [701535]",
      vendor_code: "701535",
      coded_name: "----23 HOUR---THYROIDECTOMY bilateral [701535]",
    },
  },
  {
    id: "312465",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----23 HOUR---WIRE LOCALIZED RADICAL RESECTION OF GLUTEAL TUMOR, INTRAOPERATIVE NERVE MONITORING",
      normalized_name:
        "----23 hour---wire localized radical resection of gluteal tumor, intraoperative nerve monitoring [702488]",
      vendor_code: "702488",
      coded_name:
        "----23 HOUR---WIRE LOCALIZED RADICAL RESECTION OF GLUTEAL TUMOR, INTRAOPERATIVE NERVE MONITORING [702488]",
    },
  },
  {
    id: "286824",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----AM ADMIT---RIGHT TOTAL HIP REVISION",
      normalized_name: "----am admit---right total hip revision [701327]",
      vendor_code: "701327",
      coded_name: "----AM ADMIT---RIGHT TOTAL HIP REVISION [701327]",
    },
  },
  {
    id: "292005",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----AM ADMIT---RIGHT TOTAL HIP REVISION-DAA",
      normalized_name: "----am admit---right total hip revision-daa [701565]",
      vendor_code: "701565",
      coded_name: "----AM ADMIT---RIGHT TOTAL HIP REVISION-DAA [701565]",
    },
  },
  {
    id: "346544",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----dosd--- LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "----dosd--- left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----dosd--- LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "346545",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----dosd---- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "----dosd---- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----dosd---- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "299559",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----DOSD----LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "----dosd----left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----DOSD----LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "295455",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----DOSD----PARTIAL LEFT KNEE REPLACEMENT",
      normalized_name: "----dosd----partial left knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----DOSD----PARTIAL LEFT KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "267618",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----dosd----TOTAL KNEE REPLACEMENT LEFT",
      normalized_name: "----dosd----total knee replacement left [701568]",
      vendor_code: "701568",
      coded_name: "----dosd----TOTAL KNEE REPLACEMENT LEFT [701568]",
    },
  },
  {
    id: "321568",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----DOSD---LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "----dosd---left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----DOSD---LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "301075",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----DOSD---RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "----dosd---right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----DOSD---RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "313280",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----DOSD--RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "----dosd--right total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "----DOSD--RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "274312",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----DOSD--RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "----dosd--right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "----DOSD--RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "277100",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "----possible 23 hour----ROBOT ASSISTED LAPAROSCOPIC INCISIONAL HERNIA REPAIR",
      normalized_name:
        "----possible 23 hour----robot assisted laparoscopic incisional hernia repair [702523]",
      vendor_code: "702523",
      coded_name:
        "----possible 23 hour----ROBOT ASSISTED LAPAROSCOPIC INCISIONAL HERNIA REPAIR [702523]",
    },
  },
  {
    id: "343420",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR -----EXTENSION OF POSTERIOR FUSION @ L4-5 TO L3-4 AND L5-S1",
      normalized_name:
        "---23 hour -----extension of posterior fusion @ l4-5 to l3-4 and l5-s1 [702877]",
      vendor_code: "702877",
      coded_name:
        "---23 HOUR -----EXTENSION OF POSTERIOR FUSION @ L4-5 TO L3-4 AND L5-S1 [702877]",
    },
  },
  {
    id: "314757",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR--- BURIED PENIS, LIMITED PANNICULECTOMY, STSG PENIS",
      normalized_name:
        "---23 hour--- buried penis, limited panniculectomy, stsg penis [701200]",
      vendor_code: "701200",
      coded_name:
        "---23 HOUR--- BURIED PENIS, LIMITED PANNICULECTOMY, STSG PENIS [701200]",
    },
  },
  {
    id: "292591",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR--- LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hour--- left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 HOUR--- LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "317669",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR--- PARTIAL RIGHT KNEE ARTHROPLASTY",
      normalized_name: "---23 hour--- partial right knee arthroplasty [701568]",
      vendor_code: "701568",
      coded_name: "---23 HOUR--- PARTIAL RIGHT KNEE ARTHROPLASTY [701568]",
    },
  },
  {
    id: "276942",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR--- REPAIR, HERNIA, HIATAL, LAPAROSCOPIC AND INSPECTION OF GASTRIC SLEEVE",
      normalized_name:
        "---23 hour--- repair, hernia, hiatal, laparoscopic and inspection of gastric sleeve [700780]",
      vendor_code: "700780",
      coded_name:
        "---23 HOUR--- REPAIR, HERNIA, HIATAL, LAPAROSCOPIC AND INSPECTION OF GASTRIC SLEEVE [700780]",
    },
  },
  {
    id: "282763",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR--- RIGHT REVERSE TOTAL SHOULDER",
      normalized_name: "---23 hour--- right reverse total shoulder [702604]",
      vendor_code: "702604",
      coded_name: "---23 HOUR--- RIGHT REVERSE TOTAL SHOULDER [702604]",
    },
  },
  {
    id: "298860",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR--- RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "---23 hour--- right total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HOUR--- RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "269848",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour--- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hour--- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 hour--- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "291304",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---- C3 LAMINECTOMY WITH DOME LAMINECTOMY OF C4",
      normalized_name:
        "---23 hour---- c3 laminectomy with dome laminectomy of c4 [702917]",
      vendor_code: "702917",
      coded_name:
        "---23 HOUR---- C3 LAMINECTOMY WITH DOME LAMINECTOMY OF C4 [702917]",
    },
  },
  {
    id: "342963",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---- L3-L5 laminectomy",
      normalized_name: "---23 hour---- l3-l5 laminectomy [702865]",
      vendor_code: "702865",
      coded_name: "---23 HOUR---- L3-L5 laminectomy [702865]",
    },
  },
  {
    id: "283414",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR----ACDF C4-7",
      normalized_name: "---23 hour----acdf c4-7 [702854]",
      vendor_code: "702854",
      coded_name: "---23 HOUR----ACDF C4-7 [702854]",
    },
  },
  {
    id: "345527",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR----RIGHT HIP WOUND EXPLORATION & DEBRIDMENT",
      normalized_name:
        "---23 hour----right hip wound exploration & debridment [702488]",
      vendor_code: "702488",
      coded_name:
        "---23 HOUR----RIGHT HIP WOUND EXPLORATION & DEBRIDMENT [702488]",
    },
  },
  {
    id: "298595",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR----RIGHT TOTAL HIP ARTHROPLASTY - LATERAL WITH PEGBOARD",
      normalized_name:
        "---23 hour----right total hip arthroplasty - lateral with pegboard [701564]",
      vendor_code: "701564",
      coded_name:
        "---23 HOUR----RIGHT TOTAL HIP ARTHROPLASTY - LATERAL WITH PEGBOARD [701564]",
    },
  },
  {
    id: "343004",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR----THYROIDECTOMY",
      normalized_name: "---23 hour----thyroidectomy [701535]",
      vendor_code: "701535",
      coded_name: "---23 HOUR----THYROIDECTOMY [701535]",
    },
  },
  {
    id: "298190",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---ACDF C5-6 AND C6-7",
      normalized_name: "---23 hour---acdf c5-6 and c6-7 [702853]",
      vendor_code: "702853",
      coded_name: "---23 HOUR---ACDF C5-6 AND C6-7 [702853]",
    },
  },
  {
    id: "342653",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---C4-6 ACDF",
      normalized_name: "---23 hour---c4-6 acdf [702853]",
      vendor_code: "702853",
      coded_name: "---23 HOUR---C4-6 ACDF [702853]",
    },
  },
  {
    id: "321567",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---CHOLECYSTECTOMY, LAPAROSCOPIC",
      normalized_name: "---23 hour---cholecystectomy, laparoscopic [701764]",
      vendor_code: "701764",
      coded_name: "---23 hour---CHOLECYSTECTOMY, LAPAROSCOPIC [701764]",
    },
  },
  {
    id: "295693",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---CREATION, ARTERIAL VENOUS FISTULA, USING GRAFT LEFT",
      normalized_name:
        "---23 hour---creation, arterial venous fistula, using graft left [750144]",
      vendor_code: "750144",
      coded_name:
        "---23 HOUR---CREATION, ARTERIAL VENOUS FISTULA, USING GRAFT LEFT [750144]",
    },
  },
  {
    id: "317578",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---CREATION, ARTERIAL VENOUS FISTULA, USING GRAFT RIGHT",
      normalized_name:
        "---23 hour---creation, arterial venous fistula, using graft right [750144]",
      vendor_code: "750144",
      coded_name:
        "---23 HOUR---CREATION, ARTERIAL VENOUS FISTULA, USING GRAFT RIGHT [750144]",
    },
  },
  {
    id: "292274",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---excision penile & scrotal lymphedema complex scrotoplasty possible excision of penile shaft skin STSG to penile shaft & possibly scrotum",
      normalized_name:
        "---23 hour---excision penile & scrotal lymphedema complex scrotoplasty possible excision of penile shaft skin stsg to penile shaft & possibly scrotum [701399]",
      vendor_code: "701399",
      coded_name:
        "---23 hour---excision penile & scrotal lymphedema complex scrotoplasty possible excision of penile shaft skin STSG to penile shaft & possibly scrotum [701399]",
    },
  },
  {
    id: "272243",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---HNP / LEFT HEMI LAMINECTOMY L4-5",
      normalized_name: "---23 hour---hnp / left hemi laminectomy l4-5 [700908]",
      vendor_code: "700908",
      coded_name: "---23 HOUR---HNP / LEFT HEMI LAMINECTOMY L4-5 [700908]",
    },
  },
  {
    id: "275903",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---HNP, RIGHT SIDED HEMI LAMINECTOMY L5-S1",
      normalized_name:
        "---23 hour---hnp, right sided hemi laminectomy l5-s1 [700908]",
      vendor_code: "700908",
      coded_name:
        "---23 HOUR---HNP, RIGHT SIDED HEMI LAMINECTOMY L5-S1 [700908]",
    },
  },
  {
    id: "271281",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---ILEAL CONDUIT",
      normalized_name: "---23 hour---ileal conduit [702663]",
      vendor_code: "702663",
      coded_name: "---23 hour---ILEAL CONDUIT [702663]",
    },
  },
  {
    id: "346978",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---IOUS R LOWER LEG MASS RESECTION",
      normalized_name: "---23 hour---ious r lower leg mass resection [702488]",
      vendor_code: "702488",
      coded_name: "---23 HOUR---IOUS R LOWER LEG MASS RESECTION [702488]",
    },
  },
  {
    id: "302804",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---L3-4 MIDLIF",
      normalized_name: "---23 hour---l3-4 midlif [701423]",
      vendor_code: "701423",
      coded_name: "---23 HOUR---L3-4 MIDLIF [701423]",
    },
  },
  {
    id: "283721",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---L4-5 POSTLATERAL DECOMPRESSION AND FUSION WITH DECOMPRESSION AT L3-4",
      normalized_name:
        "---23 hour---l4-5 postlateral decompression and fusion with decompression at l3-4 [701423]",
      vendor_code: "701423",
      coded_name:
        "---23 HOUR---L4-5 POSTLATERAL DECOMPRESSION AND FUSION WITH DECOMPRESSION AT L3-4 [701423]",
    },
  },
  {
    id: "302802",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---L4-S1 PLDF WITH LEFT L5-S1 MIDLIF",
      normalized_name:
        "---23 hour---l4-s1 pldf with left l5-s1 midlif [702878]",
      vendor_code: "702878",
      coded_name: "---23 HOUR---L4-S1 PLDF WITH LEFT L5-S1 MIDLIF [702878]",
    },
  },
  {
    id: "271627",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LAMINECTOMY L3-4 AND L4-5",
      normalized_name: "---23 hour---laminectomy l3-4 and l4-5 [702865]",
      vendor_code: "702865",
      coded_name: "---23 HOUR---LAMINECTOMY L3-4 AND L4-5 [702865]",
    },
  },
  {
    id: "274969",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LAMINECTOMY L4-5, AND L5-S1",
      normalized_name: "---23 hour---laminectomy l4-5, and l5-s1 [702865]",
      vendor_code: "702865",
      coded_name: "---23 HOUR---LAMINECTOMY L4-5, AND L5-S1 [702865]",
    },
  },
  {
    id: "342257",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LEFT HIP GREATER TROCHANTER BURSITIS EXCISION W/ REPAIR TO GLUTEUS MINIMUS AND MEDIUS",
      normalized_name:
        "---23 hour---left hip greater trochanter bursitis excision w/ repair to gluteus minimus and medius [701564]",
      vendor_code: "701564",
      coded_name:
        "---23 HOUR---LEFT HIP GREATER TROCHANTER BURSITIS EXCISION W/ REPAIR TO GLUTEUS MINIMUS AND MEDIUS [701564]",
    },
  },
  {
    id: "317118",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LEFT PERCUTANEOUS NEPHROLITHOTOMY",
      normalized_name:
        "---23 hour---left percutaneous nephrolithotomy [701638]",
      vendor_code: "701638",
      coded_name: "---23 HOUR---LEFT PERCUTANEOUS NEPHROLITHOTOMY [701638]",
    },
  },
  {
    id: "286830",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LEFT SIDED HEMI LAMINECTOMHY AND DISCECTOMY OF L5-S1",
      normalized_name:
        "---23 hour---left sided hemi laminectomhy and discectomy of l5-s1 [700908]",
      vendor_code: "700908",
      coded_name:
        "---23 HOUR---LEFT SIDED HEMI LAMINECTOMHY AND DISCECTOMY OF L5-S1 [700908]",
    },
  },
  {
    id: "282484",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LEFT TOTAL HIP REPLACEMENT",
      normalized_name: "---23 hour---left total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "---23 HOUR---LEFT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "273388",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "---23 hour---left total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HOUR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "275185",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH BONE MARROW ASPIRATE",
      normalized_name:
        "---23 hour---left total hip replacement, anterior approach bone marrow aspirate [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HOUR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH BONE MARROW ASPIRATE [701565]",
    },
  },
  {
    id: "285777",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---left TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hour---left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 hour---left TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "275876",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---LYMPHADENECTOMY, SUPRACLAVICULAR",
      normalized_name: "---23 hour---lymphadenectomy, supraclavicular [701135]",
      vendor_code: "701135",
      coded_name: "---23 hour---LYMPHADENECTOMY, SUPRACLAVICULAR [701135]",
    },
  },
  {
    id: "295134",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---MID URETHRAL SLING/CYSTO",
      normalized_name: "---23 hour---mid urethral sling/cysto [700268]",
      vendor_code: "700268",
      coded_name: "---23 HOUR---MID URETHRAL SLING/CYSTO [700268]",
    },
  },
  {
    id: "285175",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---OPEN VENTRAL HERNIA REPAIR",
      normalized_name: "---23 hour---open ventral hernia repair [700799]",
      vendor_code: "700799",
      coded_name: "---23 hour---OPEN VENTRAL HERNIA REPAIR [700799]",
    },
  },
  {
    id: "302716",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---Percutaneaus Nephrolithotomy with intraop access left",
      normalized_name:
        "---23 hour---percutaneaus nephrolithotomy with intraop access left [701638]",
      vendor_code: "701638",
      coded_name:
        "---23 hour---Percutaneaus Nephrolithotomy with intraop access left [701638]",
    },
  },
  {
    id: "343007",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---PERCUTANEOUS NEPHROLITHOTOMY",
      normalized_name: "---23 hour---percutaneous nephrolithotomy [701638]",
      vendor_code: "701638",
      coded_name: "---23 HOUR---PERCUTANEOUS NEPHROLITHOTOMY [701638]",
    },
  },
  {
    id: "342233",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---PERCUTANEOUS NEPHROLITHOTOMY WITH POSSIBLE STENT LEFT",
      normalized_name:
        "---23 hour---percutaneous nephrolithotomy with possible stent left [701638]",
      vendor_code: "701638",
      coded_name:
        "---23 HOUR---PERCUTANEOUS NEPHROLITHOTOMY WITH POSSIBLE STENT LEFT [701638]",
    },
  },
  {
    id: "305421",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---POSTERIOR SPINAL FUSION WITH TRANSFORAMINAL LUMBAR INTERBODY FUSION AND LAMINECTOMY L4-5",
      normalized_name:
        "---23 hour---posterior spinal fusion with transforaminal lumbar interbody fusion and laminectomy l4-5 [701423]",
      vendor_code: "701423",
      coded_name:
        "---23 HOUR---POSTERIOR SPINAL FUSION WITH TRANSFORAMINAL LUMBAR INTERBODY FUSION AND LAMINECTOMY L4-5 [701423]",
    },
  },
  {
    id: "300748",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC, TOTAL EXTRAPERITONEAL APPROACH BILATERAL",
      normalized_name:
        "---23 hour---repair, hernia, inguinal, laparoscopic, total extraperitoneal approach bilateral [702314]",
      vendor_code: "702314",
      coded_name:
        "---23 HOUR---REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC, TOTAL EXTRAPERITONEAL APPROACH BILATERAL [702314]",
    },
  },
  {
    id: "277693",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC, TOTAL EXTRAPERITONEAL APPROACH RIGHT",
      normalized_name:
        "---23 hour---repair, hernia, inguinal, laparoscopic, total extraperitoneal approach right [702314]",
      vendor_code: "702314",
      coded_name:
        "---23 HOUR---REPAIR, HERNIA, INGUINAL, LAPAROSCOPIC, TOTAL EXTRAPERITONEAL APPROACH RIGHT [702314]",
    },
  },
  {
    id: "312931",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---REPAIR, HERNIA, VENTRAL",
      normalized_name: "---23 hour---repair, hernia, ventral [700799]",
      vendor_code: "700799",
      coded_name: "---23 HOUR---REPAIR, HERNIA, VENTRAL [700799]",
    },
  },
  {
    id: "316396",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---RIGHT PARTIAL KNEE ARTHROPLASTY",
      normalized_name: "---23 hour---right partial knee arthroplasty [701568]",
      vendor_code: "701568",
      coded_name: "---23 HOUR---RIGHT PARTIAL KNEE ARTHROPLASTY [701568]",
    },
  },
  {
    id: "283741",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---RIGHT TOTAL HIP REPLACEMENT",
      normalized_name: "---23 hour---right total hip replacement [701564]",
      vendor_code: "701564",
      coded_name: "---23 HOUR---RIGHT TOTAL HIP REPLACEMENT [701564]",
    },
  },
  {
    id: "282146",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "---23 hour---right total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HOUR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "278298",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hour---right total knee replacement [701328]",
      vendor_code: "701328",
      coded_name: "---23 HOUR---RIGHT TOTAL KNEE REPLACEMENT [701328]",
    },
  },
  {
    id: "267724",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hour---right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 HOUR---RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "313046",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---RIGHT TOTAL KNEE REPLACEMENT Possible Hinge, Possible MCL Reconstruction",
      normalized_name:
        "---23 hour---right total knee replacement possible hinge, possible mcl reconstruction [701568]",
      vendor_code: "701568",
      coded_name:
        "---23 HOUR---RIGHT TOTAL KNEE REPLACEMENT Possible Hinge, Possible MCL Reconstruction [701568]",
    },
  },
  {
    id: "295015",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---ROBOT ASSISTED LAPAROSCOPIC HIATAL HERNIA REPAIR W/ FUNDOPLASTY",
      normalized_name:
        "---23 hour---robot assisted laparoscopic hiatal hernia repair w/ fundoplasty [702516]",
      vendor_code: "702516",
      coded_name:
        "---23 hour---ROBOT ASSISTED LAPAROSCOPIC HIATAL HERNIA REPAIR W/ FUNDOPLASTY [702516]",
    },
  },
  {
    id: "273345",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---ROBOT ASSISTED LAPAROSCOPIC HIATAL HERNIA REPAIR W/ NISSEN",
      normalized_name:
        "---23 hour---robot assisted laparoscopic hiatal hernia repair w/ nissen [702516]",
      vendor_code: "702516",
      coded_name:
        "---23 hour---ROBOT ASSISTED LAPAROSCOPIC HIATAL HERNIA REPAIR W/ NISSEN [702516]",
    },
  },
  {
    id: "312001",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY-XI RIGHT WITH POSSIBLE OPEN",
      normalized_name:
        "---23 hour---robot assisted laparoscopic partial nephrectomy-xi right with possible open [702954]",
      vendor_code: "702954",
      coded_name:
        "---23 HOUR---ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY-XI RIGHT WITH POSSIBLE OPEN [702954]",
    },
  },
  {
    id: "281969",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---ROBOT ASSISTED LAPAROSCOPIC PROSTATECTOMY AND PELVIC LYMPH NODE DISSECTION - XI",
      normalized_name:
        "---23 hour---robot assisted laparoscopic prostatectomy and pelvic lymph node dissection - xi [702737]",
      vendor_code: "702737",
      coded_name:
        "---23 hour---ROBOT ASSISTED LAPAROSCOPIC PROSTATECTOMY AND PELVIC LYMPH NODE DISSECTION - XI [702737]",
    },
  },
  {
    id: "342632",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---ROBOT ASSISTED LAPAROSCOPIC PROSTATECTOMY AND PELVIC LYMPH NODE DISSECTION - XI,",
      normalized_name:
        "---23 hour---robot assisted laparoscopic prostatectomy and pelvic lymph node dissection - xi, [702737]",
      vendor_code: "702737",
      coded_name:
        "---23 HOUR---ROBOT ASSISTED LAPAROSCOPIC PROSTATECTOMY AND PELVIC LYMPH NODE DISSECTION - XI, [702737]",
    },
  },
  {
    id: "286698",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---ROBOT ASSISTED LAPAROSCOPIC PYELOPLASTY",
      normalized_name:
        "---23 hour---robot assisted laparoscopic pyeloplasty [702956]",
      vendor_code: "702956",
      coded_name:
        "---23 hour---ROBOT ASSISTED LAPAROSCOPIC PYELOPLASTY [702956]",
    },
  },
  {
    id: "285697",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---ROBOT ASSISTED LAPAROSCOPIC VENTRAL HERNIA REPAIR",
      normalized_name:
        "---23 hour---robot assisted laparoscopic ventral hernia repair [702523]",
      vendor_code: "702523",
      coded_name:
        "---23 HOUR---ROBOT ASSISTED LAPAROSCOPIC VENTRAL HERNIA REPAIR [702523]",
    },
  },
  {
    id: "297299",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---ROBOT ASSISTED SIMPLE PROSTATECTOMY",
      normalized_name:
        "---23 hour---robot assisted simple prostatectomy [701852]",
      vendor_code: "701852",
      coded_name: "---23 hour---ROBOT ASSISTED SIMPLE PROSTATECTOMY [701852]",
    },
  },
  {
    id: "301742",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---ROBOTIC ASSISTED LAPAROSCOPIC PROSTATECTOMY, POSSIBLE OPEN, NO LYMPH NODE DISSECTION",
      normalized_name:
        "---23 hour---robotic assisted laparoscopic prostatectomy, possible open, no lymph node dissection [701852]",
      vendor_code: "701852",
      coded_name:
        "---23 HOUR---ROBOTIC ASSISTED LAPAROSCOPIC PROSTATECTOMY, POSSIBLE OPEN, NO LYMPH NODE DISSECTION [701852]",
    },
  },
  {
    id: "270757",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---ROBOTIC LYMPH NODE BIOPSY",
      normalized_name: "---23 hour---robotic lymph node biopsy [702754]",
      vendor_code: "702754",
      coded_name: "---23 HOUR---ROBOTIC LYMPH NODE BIOPSY [702754]",
    },
  },
  {
    id: "317632",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---SPINE CERVICAL DISCECTOMY & FUSION, ANTERIOR C4-5",
      normalized_name:
        "---23 hour---spine cervical discectomy & fusion, anterior c4-5 [700388]",
      vendor_code: "700388",
      coded_name:
        "---23 HOUR---SPINE CERVICAL DISCECTOMY & FUSION, ANTERIOR C4-5 [700388]",
    },
  },
  {
    id: "275520",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---SYMPATHECTOMY, SUPRACLAVICULAR",
      normalized_name: "---23 hour---sympathectomy, supraclavicular [702487]",
      vendor_code: "702487",
      coded_name: "---23 hour---SYMPATHECTOMY, SUPRACLAVICULAR [702487]",
    },
  },
  {
    id: "342608",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---THYROIDECTOMY",
      normalized_name: "---23 hour---thyroidectomy [701535]",
      vendor_code: "701535",
      coded_name: "---23 hour---THYROIDECTOMY [701535]",
    },
  },
  {
    id: "343327",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---THYROIDECTOMY BILATERAL",
      normalized_name: "---23 hour---thyroidectomy bilateral [701535]",
      vendor_code: "701535",
      coded_name: "---23 hour---THYROIDECTOMY BILATERAL [701535]",
    },
  },
  {
    id: "346631",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---TLIF/PSF OF L4-5 WITH LAMINECTOMY",
      normalized_name:
        "---23 hour---tlif/psf of l4-5 with laminectomy [701423]",
      vendor_code: "701423",
      coded_name: "---23 HOUR---TLIF/PSF OF L4-5 WITH LAMINECTOMY [701423]",
    },
  },
  {
    id: "343323",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---TOTAL THYROIDECTOMY",
      normalized_name: "---23 hour---total thyroidectomy [701535]",
      vendor_code: "701535",
      coded_name: "---23 HOUR---TOTAL THYROIDECTOMY [701535]",
    },
  },
  {
    id: "343295",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hour---TOTAL THYROIDECTOMY",
      normalized_name: "---23 hour---total thyroidectomy [703097]",
      vendor_code: "703097",
      coded_name: "---23 hour---TOTAL THYROIDECTOMY [703097]",
    },
  },
  {
    id: "342655",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---TURP (TRANSURETHRAL RESECTION OF PROSTATE)",
      normalized_name:
        "---23 hour---turp (transurethral resection of prostate) [701591]",
      vendor_code: "701591",
      coded_name:
        "---23 HOUR---TURP (TRANSURETHRAL RESECTION OF PROSTATE) [701591]",
    },
  },
  {
    id: "290973",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HOUR---WIDE EXCISION OF RIGHT INGUINAL MALIGNANT SKIN LESION EXCISED DAIMETER >4CM, PARTIAL RESECTION OF PENIS, PARTIAL RESECTION OF SCROTUM",
      normalized_name:
        "---23 hour---wide excision of right inguinal malignant skin lesion excised daimeter >4cm, partial resection of penis, partial resection of scrotum [701128]",
      vendor_code: "701128",
      coded_name:
        "---23 HOUR---WIDE EXCISION OF RIGHT INGUINAL MALIGNANT SKIN LESION EXCISED DAIMETER >4CM, PARTIAL RESECTION OF PENIS, PARTIAL RESECTION OF SCROTUM [701128]",
    },
  },
  {
    id: "260228",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR --- LEFT TOTAL HIP, DIRECT ANTERIOR APPROACH",
      normalized_name:
        "---23 hr --- left total hip, direct anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HR --- LEFT TOTAL HIP, DIRECT ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "259883",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR-- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hr-- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 HR-- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "262025",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR--- LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "---23 hr--- left total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HR--- LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "273050",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hr--- LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hr--- left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 hr--- LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "262011",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR--- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hr--- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 HR--- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "260146",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---ACDF C4-5, C5-6, AND C6-7",
      normalized_name: "---23 hr---acdf c4-5, c5-6, and c6-7 [702854]",
      vendor_code: "702854",
      coded_name: "---23 HR---ACDF C4-5, C5-6, AND C6-7 [702854]",
    },
  },
  {
    id: "265647",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hr---CREATION, AV FISTULA, UPPER EXTREMITY left",
      normalized_name:
        "---23 hr---creation, av fistula, upper extremity left [700174]",
      vendor_code: "700174",
      coded_name:
        "---23 hr---CREATION, AV FISTULA, UPPER EXTREMITY left [700174]",
    },
  },
  {
    id: "264931",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---HNP/LAMINECTOMY L4-5",
      normalized_name: "---23 hr---hnp/laminectomy l4-5 [700908]",
      vendor_code: "700908",
      coded_name: "---23 HR---HNP/LAMINECTOMY L4-5 [700908]",
    },
  },
  {
    id: "263295",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---KNEE MANIPULATION UNDER ANESTHESIA RIGHT",
      normalized_name:
        "---23 hr---knee manipulation under anesthesia right [700995]",
      vendor_code: "700995",
      coded_name:
        "---23 HR---KNEE MANIPULATION UNDER ANESTHESIA RIGHT [700995]",
    },
  },
  {
    id: "260107",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "---23 hr---left total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "260110",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hr---left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 HR---LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "263243",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---RIGHT ANKLE SOFT TISSUE IRRIGATION AND DEBRIDEMENT",
      normalized_name:
        "---23 hr---right ankle soft tissue irrigation and debridement [700864]",
      vendor_code: "700864",
      coded_name:
        "---23 HR---RIGHT ANKLE SOFT TISSUE IRRIGATION AND DEBRIDEMENT [700864]",
    },
  },
  {
    id: "267630",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hr---RIGHT LITHOTRIPSY, ULTRASONIC, PERCUTANEOUS",
      normalized_name:
        "---23 hr---right lithotripsy, ultrasonic, percutaneous [701638]",
      vendor_code: "701638",
      coded_name:
        "---23 hr---RIGHT LITHOTRIPSY, ULTRASONIC, PERCUTANEOUS [701638]",
    },
  },
  {
    id: "269857",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hr---RIGHT TOTAL HIP REPLACEMENT POSTERIOR APPROACH",
      normalized_name:
        "---23 hr---right total hip replacement posterior approach [701564]",
      vendor_code: "701564",
      coded_name:
        "---23 hr---RIGHT TOTAL HIP REPLACEMENT POSTERIOR APPROACH [701564]",
    },
  },
  {
    id: "260140",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "---23 hr---right total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23 HR---RIGHT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "259882",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23 hr---right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23 HR---RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "260780",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---RIGHT TOTAL KNEE REPLACEMENT, UNICOMPARTMENTAL",
      normalized_name:
        "---23 hr---right total knee replacement, unicompartmental [701570]",
      vendor_code: "701570",
      coded_name:
        "---23 HR---RIGHT TOTAL KNEE REPLACEMENT, UNICOMPARTMENTAL [701570]",
    },
  },
  {
    id: "262520",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY RIGHT",
      normalized_name:
        "---23 hr---robot assisted laparoscopic partial nephrectomy right [702954]",
      vendor_code: "702954",
      coded_name:
        "---23 HR---ROBOT ASSISTED LAPAROSCOPIC PARTIAL NEPHRECTOMY RIGHT [702954]",
    },
  },
  {
    id: "262714",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 hr---ROBOT ASSISTED LAPAROSCOPIC PROSTATECTOMY-XI WITH BILATERAL PELVIC LYMPH NODE DISSECTION",
      normalized_name:
        "---23 hr---robot assisted laparoscopic prostatectomy-xi with bilateral pelvic lymph node dissection [702737]",
      vendor_code: "702737",
      coded_name:
        "---23 hr---ROBOT ASSISTED LAPAROSCOPIC PROSTATECTOMY-XI WITH BILATERAL PELVIC LYMPH NODE DISSECTION [702737]",
    },
  },
  {
    id: "264567",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---ROBOT ASSISTED TOTAL LAPAROSCOPIC HYSTERECTOMY-XI / BSO",
      normalized_name:
        "---23 hr---robot assisted total laparoscopic hysterectomy-xi / bso [702734]",
      vendor_code: "702734",
      coded_name:
        "---23 HR---ROBOT ASSISTED TOTAL LAPAROSCOPIC HYSTERECTOMY-XI / BSO [702734]",
    },
  },
  {
    id: "260344",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---ROBOT ASSISTED TOTAL LAPAROSCOPIC HYSTERECTOMY-XI/ BSO",
      normalized_name:
        "---23 hr---robot assisted total laparoscopic hysterectomy-xi/ bso [702734]",
      vendor_code: "702734",
      coded_name:
        "---23 HR---ROBOT ASSISTED TOTAL LAPAROSCOPIC HYSTERECTOMY-XI/ BSO [702734]",
    },
  },
  {
    id: "266121",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---ROBOT ASSISTED TOTAL LAPAROSCOPIC HYSTERECTOMY-XI/BSO, POSSIBLE OMENTECTOMY,LYMPH NODE DISSECTION",
      normalized_name:
        "---23 hr---robot assisted total laparoscopic hysterectomy-xi/bso, possible omentectomy,lymph node dissection [702734]",
      vendor_code: "702734",
      coded_name:
        "---23 HR---ROBOT ASSISTED TOTAL LAPAROSCOPIC HYSTERECTOMY-XI/BSO, POSSIBLE OMENTECTOMY,LYMPH NODE DISSECTION [702734]",
    },
  },
  {
    id: "260426",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR---TONSILLECTOMY",
      normalized_name: "---23 hr---tonsillectomy [701559]",
      vendor_code: "701559",
      coded_name: "---23 HR---TONSILLECTOMY [701559]",
    },
  },
  {
    id: "272535",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23 HR--ROBOT ASSISTED LAPAROSCOPIC HIATAL HERNIA REPAIR W/ NISSEN",
      normalized_name:
        "---23 hr--robot assisted laparoscopic hiatal hernia repair w/ nissen [702516]",
      vendor_code: "702516",
      coded_name:
        "---23 HR--ROBOT ASSISTED LAPAROSCOPIC HIATAL HERNIA REPAIR W/ NISSEN [702516]",
    },
  },
  {
    id: "274327",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23HOUR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH",
      normalized_name:
        "---23hour---left total hip replacement, anterior approach [701565]",
      vendor_code: "701565",
      coded_name:
        "---23HOUR---LEFT TOTAL HIP REPLACEMENT, ANTERIOR APPROACH [701565]",
    },
  },
  {
    id: "280992",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23hr-- LEFT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23hr-- left total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23hr-- LEFT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "291512",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23HR-- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23hr-- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23HR-- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "260403",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23HR--- RIGHT TOTAL KNEE REPLACEMENT",
      normalized_name: "---23hr--- right total knee replacement [701568]",
      vendor_code: "701568",
      coded_name: "---23HR--- RIGHT TOTAL KNEE REPLACEMENT [701568]",
    },
  },
  {
    id: "261073",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---23hr---PARATHYROIDECTOMY",
      normalized_name: "---23hr---parathyroidectomy [750080]",
      vendor_code: "750080",
      coded_name: "---23hr---PARATHYROIDECTOMY [750080]",
    },
  },
  {
    id: "291000",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---AM ADMIT RIGHT TOTAL HIP REPLACEMENT, ANTERIOR",
      normalized_name:
        "---am admit right total hip replacement, anterior [701565]",
      vendor_code: "701565",
      coded_name: "---AM ADMIT RIGHT TOTAL HIP REPLACEMENT, ANTERIOR [701565]",
    },
  },
  {
    id: "305438",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---AM ADMIT RIGHT TOTAL HIP REVISION",
      normalized_name: "---am admit right total hip revision [701327]",
      vendor_code: "701327",
      coded_name: "---AM ADMIT RIGHT TOTAL HIP REVISION [701327]",
    },
  },
  {
    id: "312445",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---AM ADMIT RIGHT TOTAL HIP REVISION POSTERIOR APPROACH - LATERAL WITH PEGBOARD",
      normalized_name:
        "---am admit right total hip revision posterior approach - lateral with pegboard [701327]",
      vendor_code: "701327",
      coded_name:
        "---AM ADMIT RIGHT TOTAL HIP REVISION POSTERIOR APPROACH - LATERAL WITH PEGBOARD [701327]",
    },
  },
  {
    id: "313100",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---AM ADMIT-- LEFT REVISION TOTAL KNEE",
      normalized_name: "---am admit-- left revision total knee [701328]",
      vendor_code: "701328",
      coded_name: "---AM ADMIT-- LEFT REVISION TOTAL KNEE [701328]",
    },
  },
  {
    id: "345839",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---AM ADMIT--- LEFT HIP REVISION",
      normalized_name: "---am admit--- left hip revision [701327]",
      vendor_code: "701327",
      coded_name: "---AM ADMIT--- LEFT HIP REVISION [701327]",
    },
  },
  {
    id: "311985",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---AM ADMIT--- LEFT REVISION TOTAL KNEE ARTHROPLASTY, BOTH COMPONENTS",
      normalized_name:
        "---am admit--- left revision total knee arthroplasty, both components [701328]",
      vendor_code: "701328",
      coded_name:
        "---AM ADMIT--- LEFT REVISION TOTAL KNEE ARTHROPLASTY, BOTH COMPONENTS [701328]",
    },
  },
  {
    id: "316862",
    type: "procedures",
    attributes: {
      icd10: null,
      icd9: null,
      name: "---AM ADMIT--- LEFT TOTAL HIP REVISION- DIRECT ANTERIOR APPROACH (HEAD AND LINER EXCHANGE)",
      normalized_name:
        "---am admit--- left total hip revision- direct anterior approach (head and liner exchange) [701327]",
      vendor_code: "701327",
      coded_name:
        "---AM ADMIT--- LEFT TOTAL HIP REVISION- DIRECT ANTERIOR APPROACH (HEAD AND LINER EXCHANGE) [701327]",
    },
  },
];
