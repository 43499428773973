import MockAdapter from "axios-mock-adapter";
import { withReactContext } from "storybook-react-context";
import { capiClient, RequestContext } from "../utils/capiClient";

const mockOnGet = ({ adapter, params, path, sample, statusCode }) => {
  // .onGet(path, { ...(params ? { params: params } : {}) })
  // const regPath = new RegExp(`${CAPI_BASE_URI}${path}*`);
  const regPath = new RegExp(`${path}*`);
  adapter.onGet(regPath).reply(statusCode ?? 200, {
    data: sample,
  });
};

export default function mockRequestContext({
  context,
  onGet = [], // Array of Objects { path, sample }
  path,
  sample,
  statusCode,
  token = "Test Token",
}) {
  const axiosInst = capiClient(token);

  // Order matters when this is set
  const mockAdapter = new MockAdapter(axiosInst);

  if (path && sample)
    mockOnGet({ adapter: mockAdapter, path, sample, statusCode });

  if (onGet?.length)
    onGet.forEach((props) => mockOnGet({ adapter: mockAdapter, ...props }));

  const withRequestContext = withReactContext({
    Context: RequestContext,
    initialState: {
      extras: {},
      params: {},
      request: axiosInst,
      ...context,
    },
  });

  return { axiosInst, mockAdapter, withRequestContext };
}
