import React, { useEffect } from "react";

export default function TooltipWrapper({
  children,
  id,
  method,
  options,
  style,
  title,
  url = null,
}) {
  useEffect(() => {
    if (!title && !method) return;

    $(`#${id}.tip`).tooltip("destroy");
    method ? $(`#${id}.tip`).tooltip(method) : $(`#${id}.tip`).tooltip();

    return () => $(`#${id}.tip`).tooltip("destroy");
  }, [title, method]);

  const toolTipProps = {
    className: "tip",
    "data-toggle": "tooltip",
    "data-placement": "left",
    id: id,
    ...options,
    style: style,
    title: title,
  };

  if (url) {
    toolTipProps.href = url;
    toolTipProps.target = "_blank";
    toolTipProps.rel = "noopener noreferrer";
  }

  return url ? (
    <a {...toolTipProps}>{children}</a>
  ) : (
    <span {...toolTipProps}>{children}</span>
  );
}
