import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import EquipmentMasterSelect from "./selects/EquipmentMasterSelect";
import InventoryItemSelect from "./selects/InventoryItemSelect";
import Usage from "./usages/Usage";
import { findInventoryShareability } from "./utils";
import { PillContent } from "./styled";
import { RequestContext } from "../../../../utils/capiClient";
import { EquipmentContext } from "../../useEquipmentContext";
import { INVENTORY_ITEMS_PATH } from "../../../../utils/capi";

const DEFAULT_USAGE = {
  duration: null,
  endOfCase: true,
  startTime: 0,
};

// Formatted like EPII
const DEFAULT_STATE = {
  ...DEFAULT_USAGE,
  id: null,
  inventoryItemId: null,
  meta: {
    usage: DEFAULT_USAGE,
  },
};

// example
// const DEFAULT_STATE = {
//   id: 15110,
//   inventoryItemId: "13476",
//   duration: 0,
//   endOfCase: true,
//   startTime: -30,
//   meta: {
//     codes: {
//       assetTag: "",
//       cohealoTag: "6968",
//       serial: "SV7320A",
//     },
//     collaborativelyPurchased: true,
//     homeFacility: { id: 118, siteCode: "IMC" },
//     id: "13476",
//     usage: { duration: 0, endOfCase: true, startTime: -30 },
//     collaborativelyPurchased: true,
//     description: "",
//     equipmentMasterId: 2448,
//     type: "Inventory",
//     status: "shareable",
//     rentalCompanyId: null,
//     yearManufactured: null,
//     owningFacility: { id: 118, siteCode: "IMC" },
//   },
// };

export default function Edit({
  components,
  filterableInventoryItemIds,
  onCancel,
  onSubmit,
  selected,
}) {
  const [state, setState] = useState(
    _.isEmpty(selected) ? DEFAULT_STATE : selected
  );
  const {
    inventoryItemId,
    meta,
    meta: { equipmentMaster, usage },
    meta: inventoryItem,
  } = state;

  const [inventoryItems, setInventoryItems] = useState({
    isLoading: false,
    options: [],
  });

  const {
    request,
    params: { facilityId, healthSystemId },
  } = useContext(RequestContext);
  const { inventoryShareability } = useContext(EquipmentContext);

  let isNotSharedWithFacility = !findInventoryShareability(
    inventoryItem,
    facilityId,
    inventoryShareability
  );

  const filterInventoryItemOptions = (options) => {
    state;
    const filterableInvIds = filterableInventoryItemIds.filter(
      (id) => id != inventoryItemId
    );

    const filterUniqueInventoryItemIds = (opt) =>
      !filterableInvIds?.includes(opt.value);
    const filterShareableWithFacility = (opt) =>
      findInventoryShareability(
        { id: opt.value, status: opt.data.status },
        facilityId,
        inventoryShareability
      );
    const results = filterableInvIds?.length
      ? options?.filter(filterUniqueInventoryItemIds)
      : options ?? [];

    return facilityId ? results.filter(filterShareableWithFacility) : results;
  };

  useEffect(() => {
    if (!(request && equipmentMaster?.id)) return;

    setInventoryItems({ ...inventoryItems, isLoading: true });
    request
      .get(INVENTORY_ITEMS_PATH, {
        params: {
          equipmentMasterId: equipmentMaster.id,
          healthSystemId: healthSystemId,
          options: {
            include: [
              "accessory_masters",
              "equipment_master",
              "facility",
              "rental",
            ],
            sort: ["facility"],
          },
        },
      })
      .then(({ data }) => {
        const __options =
          data?.data?.reduce(
            (memo, { attributes, id, links: { self: url } }) => {
              memo.push({
                data: {
                  ...attributes,
                  url,
                },
                label: "",
                value: parseInt(id),
              });
              return memo;
            },
            []
          ) ?? [];

        // When eqm changes, ii resets.
        // The first valid option is selected.
        // The new default selection cannot be one of the other inv selections
        if (__options.length && (!inventoryItemId || isNotSharedWithFacility)) {
          let defaultSel = filterInventoryItemOptions(__options)[0];
          handleInventoryItemChange(defaultSel);
        }

        setInventoryItems({
          ...inventoryItems,
          isLoading: false,
          options: __options,
        });
      });
  }, [equipmentMaster?.id]);

  const handleEquipmentMasterChange = (change) => {
    const equipmentMaster = {
      ...change.data,
      id:
        typeof change.value === "string"
          ? parseInt(change.value)
          : change.value,
    };

    const newState = {
      ...state,
      meta: {
        ...inventoryItem,
        equipmentMaster,
      },
    };

    // Usually, when reopening pill, the initial state is empty.
    // The master selection will reset, which should not reset
    // inventory when master changes.
    if (inventoryItem.equipmentMaster?.id != change.value) {
      newState.inventoryItemId = null;
      newState.meta = {
        equipmentMaster,
        usage,
      };
    }
    setState(newState);
  };

  const handleInventoryItemChange = (change) => {
    if (!change) return;

    const id =
      typeof change.value === "string" ? parseInt(change.value) : change.value;
    const newState = {
      ...state,
      inventoryItemId: id,
      meta: {
        ...inventoryItem,
        id,
        ...change.data,
      },
    };
    setState(newState);
  };

  const handleUsageChange = (change) => {
    setState({
      ...state,
      ...change,
      meta: {
        ...meta,
        usage: change,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if submit is clicked and no changes are present,
    // onCancel is the expected ux.
    if (_.isEqual(selected, state)) {
      onCancel();
    } else if (inventoryItemId) {
      onSubmit(state);
    }
  };

  let disabledAddEquipment = !equipmentMaster?.id;
  const inventoryItemOptions = filterInventoryItemOptions(
    inventoryItems.options
  );
  disabledAddEquipment = disabledAddEquipment || !inventoryItemOptions.length;

  return (
    <PillContent
      className="equipment-block"
      id="editable"
      showError={isNotSharedWithFacility}
    >
      <div className="equipment-info">
        <Usage selected={usage} onChange={handleUsageChange} />
        <EquipmentMasterSelect
          handleChange={handleEquipmentMasterChange}
          value={
            (equipmentMaster && {
              data: equipmentMaster,
              label: equipmentMaster.name,
              value: equipmentMaster.id,
            }) ??
            null
          }
        />
        <InventoryItemSelect
          handleChange={handleInventoryItemChange}
          isDisabled={!equipmentMaster?.id}
          isLoading={inventoryItems.isLoading}
          options={inventoryItemOptions}
          value={inventoryItem?.id && inventoryItem}
        />
        <div className="row-fluid clearfix">
          <div className="pull-right">
            <button
              className="btn btn-small btn-primary ok-equipment-button"
              disabled={disabledAddEquipment}
              name="button"
              onClick={
                disabledAddEquipment
                  ? (e) => {
                      e.preventDefault();
                    }
                  : handleSubmit
              }
              style={{ marginRight: "10px" }}
              type="button"
            >
              Add equipment
            </button>
            <button
              className="btn btn-small"
              name="button"
              onClick={onCancel}
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      {components.Controls()}
    </PillContent>
  );
}

function RentalConfirmationNumber({ onChange, value }) {
  return (
    <div className="control-group clearfix">
      <div className="col-left">
        <label style={{ marginTop: "0px" }}>Rental Confirmation Number:</label>
      </div>
      <div className="col-right">
        <input
          className="input-mini rental-confirmation-number"
          onChange={onChange}
          type="text"
          value={value}
        />
      </div>
    </div>
  );
}
