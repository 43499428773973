import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import AsyncSelect from "react-select-3/async";
import axios from "axios";

import { SELECT_STYLES } from "./select_styles";

// Expected Props:
// {
//   data { url: admin/procedures/matching.json, }
//   name: "object",
//   selected: [{ id: "patient_cases_object_id", name: "object name", objectId: "object id" }],
//   icon:  sprite-user, sprite-clipboard, etc
// }
class LazyNestedForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: this.initializeSelected(),
      objectNames: this.buildNames(),
      options: this.buildLazyOptions(),
    };
  }

  buildNames = () => {
    const { name } = this.props;
    const nameCap = name.charAt(0).toUpperCase() + name.slice(1);
    return [name, `${name}s`, nameCap, `${nameCap}s`];
  };

  buildLazyOptions = () =>
    this.props.selected.map((sel) => {
      return { label: sel.name, value: sel.objectId };
    });

  initializeSelected = () => {
    let idx = 0;

    return (this.props.selected ?? []).reduce((memo, assoc) => {
      memo[idx] = {
        id: assoc.id,
        objectId: assoc.objectId,
        name: assoc.name,
      };
      idx++;
      return memo;
    }, {});
  };

  handleAddObject = () => {
    const currState = this.state;
    currState.list[Object.keys(currState.list).length] = {};
    this.setState(currState);
  };

  handleChange = (key, change) => {
    const currState = this.state;
    if (change.destroy) {
      // if id, then association is persisted
      if (currState.list[key].id) {
        currState.list[key].destroy = true;
      } else {
        delete currState.list[key];
      }
    } else {
      currState.list[key] = change;
    }
    this.setState(currState);
  };

  render() {
    const { list, objectNames } = this.state;
    const listKeys = Object.keys(list);
    const selectedObjectIds = [];
    for (const key of listKeys) {
      if (!list[key].destroy) selectedObjectIds.push(list[key].objectId);
    }

    return (
      <div className="row-fluid case-edit-row">
        <i className="pull-left sprite-icon case-edit-icon" />
        <i
          className={`pull-left sprite-icon case-edit-icon ${this.props.icon}-m-grey-light`}
        />
        <div className="case-edit-fields">
          <div className="control-group span9">
            <label className="control-label">{objectNames[3]}:</label>
            <div className="controls">
              {listKeys.map((key, index) => {
                const selected = list[key];
                const selectedValue = selected.objectId
                  ? { label: selected.name, value: selected.objectId }
                  : undefined;
                return (
                  <InputField
                    destroy={!!selected.destroy}
                    fieldId={key}
                    filterableObjectIds={selectedObjectIds.filter(
                      (id) => id != selected.objectId
                    )}
                    id={selected.id}
                    key={index}
                    objectNames={objectNames}
                    onChange={this.handleChange}
                    url={this.props.data.url}
                    value={selectedValue}
                  />
                );
              })}
              <a
                className={`add-new-${objectNames[0]}`}
                onClick={this.handleAddObject}
                style={{ cursor: "pointer" }}
              >
                {`+ Add a ${objectNames[0]}`}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LazyNestedForm;

const getOptions = (excludeObjectIds, inputValue, minCharacterLimit, url) => {
  return inputValue.length < minCharacterLimit
    ? Promise.resolve([])
    : axios
        .post(url, {
          health_system_id: $("#patient_case_health_system_id").val(),
          patterns: [inputValue],
          props: true,
        })
        .then(({ data }) => {
          return data.length
            ? data.results.filter(
                (opt) => !excludeObjectIds.includes(opt.value)
              )
            : [];
        })
        .catch((error) => {
          return [];
        });
};

function InputField({
  destroy,
  fieldId,
  filterableObjectIds,
  id,
  minCharacterLimit = 3,
  objectNames,
  onChange,
  url,
  value,
}) {
  const [input, setInput] = useState("");
  const inputNameBase = `patient_case[patient_cases_${objectNames[1]}_attributes][${fieldId}]`;

  const loadOptions = useCallback(
    _.debounce((excludeObjectIds, inputValue, callback) => {
      getOptions(excludeObjectIds, inputValue, minCharacterLimit, url).then(
        (opt) => callback(opt)
      );
    }, 1500),
    []
  );

  const withinMinCharacterLimit = (input) =>
    input && input.length >= minCharacterLimit;
  const messageProps = {
    loadingMessage: () =>
      withinMinCharacterLimit(input)
        ? `Finding ${objectNames[1]}...`
        : `Please enter ${minCharacterLimit - input.length} or more characters`,
    noOptionsMessage: () =>
      input.length === 0
        ? `Please enter ${minCharacterLimit} or more characters to find ${objectNames[1]}`
        : withinMinCharacterLimit(input)
        ? `Cannot find any ${objectNames[1]} matching '${input}'`
        : `Please enter ${minCharacterLimit - input.length} or more characters`,
    placeholder: `Please select ${objectNames[0]}`,
  };

  const changeProps = {
    inputValue: input,
    onInputChange: (newInput) => setInput(newInput),
    onChange: (change) =>
      onChange(fieldId, { id: id, objectId: change.value, name: change.label }),
    value: value,
  };

  return (
    <div className={`fields${destroy ? " hidden" : ""}`}>
      <div className="plus-minus">
        <div className="plus">
          <AsyncSelect
            {...changeProps}
            {...messageProps}
            defaultOptions={[]}
            loadOptions={(inputValue, callback) =>
              loadOptions(filterableObjectIds, inputValue, callback)
            }
            styles={SELECT_STYLES}
          />
        </div>
        <div className="minus" style={{ cursor: "pointer", top: "7px" }}>
          <a
            className={`remove-${objectNames[0]}-button`}
            onClick={() => onChange(fieldId, { id: id, destroy: true })}
          >
            <i className="sprite-icon sprite-x-s-red" />
          </a>
        </div>
      </div>
      <div className="hidden-forms hidden">
        {id && <input name={`${inputNameBase}[id]`} defaultValue={id} />}
        {!destroy && value && (
          <input
            name={`${inputNameBase}[${objectNames[0]}_id]`}
            readOnly
            value={value.value}
          />
        )}
        {destroy && (
          <input name={`${inputNameBase}[_destroy]`} readOnly value={true} />
        )}
      </div>
    </div>
  );
}
