import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";

// TODO: Please DRY booking tile and rental tiles codes
import {
  EquipmentName,
  MobilizationInfo,
  ObjectId,
  Tile,
  TimeRange,
} from "./tile";
import { ContentContainer } from "../ugly_painter";

const tileStyles = css`
  color: #fff;
  background: ${(props) => props.styles.theme.primary};
  border-color: ${(props) => props.styles.theme.secondary};
  width: 100%;

  em.empty {
    color: #ffff;
    opacity: 0.6;
  }

  &:focus {
    background: ${(props) => props.styles.theme.secondary};
  }
  &:hover {
    background: ${(props) => props.styles.theme.secondary};
  }
`;

const mobilizationSectionStyles = css`
  padding: 3px 5px 1px 5px;

  span.booking-type {
    right: 3px;
    top: 3px;
  }
`;

const contentStyles = css`
  padding-top: 3px;
`;

function RecurringBookingIcon() {
  return (
    <span
      className="booking-type tip tile-icon"
      data-toggle="tooltip"
      title="This is a recurring booking"
    >
      <i className="sprite-icon sprite-refresh-s-white" />
    </span>
  );
}

export function RentalBookingTile(props) {
  const [entered, setEntered] = useState(true);
  const bookingPeriod = props.event.extendedProps.bookingType; // weekly, single, etc
  const {
    bookingType,
    end_at,
    facilityProps,
    inventoryProps,
    objectId,
    start_at,
  } = props.event.extendedProps;
  const { display } = props;

  useEffect(() => {
    return () => setEntered(!entered);
  }, []);

  return (
    <Tile
      {...props}
      in={entered}
      handleIn={setEntered}
      tileClass={`event-${bookingPeriod}`}
      styles={{
        theme: props.theme,
        tile: tileStyles,
      }}
    >
      <i className="modal-show icon-resize-ful" />
      <MobilizationInfo
        equipmentMasterId={inventoryProps.equipmentMasterId}
        facilities={facilityProps}
        inventoryId={inventoryProps.id}
        isFarRightViewTile={props.isFarRightViewTile}
        styles={{
          theme: props.theme,
          style: mobilizationSectionStyles,
        }}
        handleEquipmentChange={props.handleEquipmentChange}
      >
        {bookingType !== "single" && <RecurringBookingIcon />}
      </MobilizationInfo>
      <ContentContainer styles={{ content: contentStyles }}>
        <EquipmentName inventory={inventoryProps} />
        <TimeRange start_at={start_at} end_at={end_at} />
        <ObjectId id={objectId} display={display} />
      </ContentContainer>
    </Tile>
  );
}

// TODO: PropTypes
RentalBookingTile.propTypes = {};

export default RentalBookingTile;
