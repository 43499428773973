import React, { useState, useMemo } from "react";
import axios from "axios";
import { Box, Chip, Menu, MenuItem } from "@mui/material";

import { growl } from "../../../../utils/alert";
import {
  blue9,
  blueLight,
  complete,
  incomplete,
  inprogress,
  selected,
  unassigned,
} from "../../../../utils/base";
import {
  CompleteButton,
  IncompleteButton,
  InprogressButton,
  UnassignedButton,
} from "../../../../utils/baseIcons";

const moveStatusColor = {
  complete: complete,
  incomplete: incomplete,
  inprogress: inprogress,
  unassigned: unassigned,
};

const updateMoveStatus = async (id, param, setStatus = () => {}) => {
  return await axios
    .patch(`/moves/${id}/update_move_status`, { move: { state: param } })
    .then(() => {
      setStatus(param);
      growl("Move updated", `Status was changed to '${param}'`, {
        enableClose: true,
      });
    })
    .catch(() => {
      growl(
        "Move update failed",
        `Move may have been updated before status can be changed. Please refresh and try again or contact an admin.`,
        {
          enableClose: true,
          icon: "remove",
          status: "danger",
        }
      );
    });
};

export default function MoveStatus({ id, value, data = {} }) {
  const [status, setStatus] = useState(value ?? "unassigned");
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSetStatus = (newStatus) => {
    setStatus(newStatus);
    if (data.isModal) $(document.body).trigger("move.update");
  };

  const disabled = Boolean(data?.disabled);
  const iconsOnly = Boolean(data?.iconsOnly);

  const menuOptions = useMemo(
    () =>
      disabled
        ? []
        : [
            {
              label: "Change Move Status",
              sx: {
                color: "white",
                backgroundColor: blue9,
              },
              value: "disabled",
            },
            {
              label: "planned",
              value: "unassigned",
              handleClick: () => {
                updateMoveStatus(id, "unassigned", handleSetStatus);
                handleClose();
              },
            },
            {
              label: "incomplete",
              value: "incomplete",
              handleClick: () => {
                updateMoveStatus(id, "incomplete", handleSetStatus);
                handleClose();
              },
            },
            {
              label: "inprogress",
              value: "inprogress",
              handleClick: () => {
                updateMoveStatus(id, "inprogress", handleSetStatus);
                handleClose();
              },
            },
            {
              label: "complete",
              value: "complete",
              handleClick: () => {
                updateMoveStatus(id, "complete", handleSetStatus);
                handleClose();
              },
            },
          ],
    []
  );

  const StatusIcon = iconsOnly ? (
    status === "unassigned" ? (
      <UnassignedButton disabled={disabled} onClick={handleClick} />
    ) : status === "incomplete" ? (
      <IncompleteButton disabled={disabled} onClick={handleClick} />
    ) : status === "inprogress" ? (
      <InprogressButton disabled={disabled} onClick={handleClick} />
    ) : (
      <CompleteButton disabled={disabled} onClick={handleClick} />
    )
  ) : undefined;

  const StatusChip = (
    <Chip
      className="tile-icon"
      label={status === "unassigned" ? "planned" : status}
      onClick={disabled ? undefined : handleClick}
      size="small"
      sx={{
        backgroundColor: moveStatusColor[status],
        color: "white",
        ...(disabled && { cursor: "not-allowed" }),
        display: "inline-flex !important",
        fontSize: 10,
        ...(!disabled && {
          "&:hover": {
            backgroundColor: moveStatusColor[status],
            filter: "brightness(1.2)",
          },
        }),
      }}
    />
  );

  return disabled ? (
    <Box sx={{ display: "inline-block" }}>{StatusIcon ?? StatusChip}</Box>
  ) : (
    <Box sx={{ display: "inline-block" }}>
      {StatusIcon ?? StatusChip}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .Mui-selected": {
            backgroundColor: selected,
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: blueLight,
          },
        }}
      >
        {menuOptions.map((opt, index) => (
          <MenuItem
            disabled={index === 0}
            key={opt.value}
            onClick={opt.handleClick}
            selected={opt.value === status}
            sx={opt.sx}
          >
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
