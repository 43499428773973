import { EQUIPMENT_PREFERENCES_PATH } from "../../utils/capi";

export default function getForm({ id, isClone = false, request }) {
  return request.get(`${EQUIPMENT_PREFERENCES_PATH}/${id}`).then(({ data }) => {
    // data returned, should give us links
    // however, we should use static links for now
    // as the returned links are not special/complex
    const {
      data: { attributes, relationships },
      included,
      meta,
    } = data;

    const aasmState = isClone ? "active" : attributes.aasm_state;
    const cloneForm = isClone
      ? {
          flags: {
            hasUnsavedChanges: false,
            isEditable: true,
            isCloneOfArchive: !!attributes.deleted_at,
          },
          selected: {
            createdById: null,
            name: `${attributes.name} CLONE`,
            verifiedById: null,
            verifiedOnDate: null,
          },
          meta: {
            attributes: {
              createdAtDate: null,
              deletedAt: null,
              primeName: attributes.name,
              replacedById: null,
              replacesId: null,
              updatedAtDate: null,
            },
          },
        }
      : null;

    const form = {
      isLoaded: true,
      // ...form,
      isLoaded: true,
      flags: {
        isDisabled: aasmState != "active",
        isEditable: meta.user.can_edit,
        ...(cloneForm && cloneForm.flags),
      },
      selected: {
        // ...form.selected,
        createdById: relationships.created_by.data?.id,
        name: attributes.name,
        notes: attributes.notes ?? "",
        verifiedById: relationships.verified_by.data?.id,
        verifiedOnDate: attributes.verified_on_date,
        ...(cloneForm && cloneForm.selected),
      },
      meta: {
        // ...form.meta,
        attributes: {
          // ...form.meta.attributes,
          createdAtDate: attributes.created_at,
          deletedAt: attributes.deleted_at,
          replacedById: relationships.replaced_by_equipment_preference.data?.id,
          replacesId: relationships.replaces_equipment_preference.data?.id,
          state: aasmState,
          updatedAtDate: attributes.updated_at,
          ...(cloneForm && cloneForm.meta.attributes),
        },
        alerts: meta.alerts,
      },
    };

    const {
      flags: { isEditable },
      selected,
    } = form;

    included.forEach((el) => {
      switch (el.type) {
        case "preferenceDisabledFallbacks":
          selected.preferenceDisabledFallbacksAttributes =
            selected.preferenceDisabledFallbacksAttributes || [];
          const {
            attributes: { equipmentMasterFallbackId },
            id,
          } = el;

          selected.preferenceDisabledFallbacksAttributes.push({
            id: isClone ? null : id,
            equipmentMasterFallbackId,
          });
          break;
        case "equipment_preferences_criteria":
          selected.criteriaAttributes = selected.criteriaAttributes || [];
          selected.criteriaAttributes.push({
            data: el.attributes,
            id: isClone ? null : el.id,
            physicianId: el.attributes.physician_id,
            procedurePattern: el.attributes.procedure_pattern,
          });
          break;
        case "facilities":
          selected.facilityId = el.id;
          // TODO: Please remove the right condition check on snake_case keys
          // Since we are hotfixing photo removal, master branch is still using
          // older snakecase syntax. We can remove this after photo removal branch
          // is merged to production.
          selected.healthSystemId =
            el.attributes.healthSystemId || el.attributes.health_system_id;
          form.meta.attributes.facilityName = el.attributes.name;
          form.meta.attributes.facilitySiteCode =
            el.attributes.siteCode || el.attributes.site_code;
          break;
        case "serviceLines":
          selected.serviceLineId = el.id;
          form.meta.attributes.serviceLineName = el.attributes.name;
          break;
        // TODO: remove this too
        case "service_lines":
          selected.serviceLineId = el.id;
          form.meta.attributes.serviceLineName = el.attributes.name;
          break;
        case "user":
          if (isClone) break;
          if (selected.createdById && selected.createdById == el.id)
            form.meta.attributes.createdName = el.attributes.name;
          if (selected.verifiedById && selected.verifiedById == el.id)
            form.meta.attributes.verifiedName = el.attributes.name;
          break;
      }
    });
    return form;
  });
}

export function getEquipmentPreferencesInventoryItems({
  id,
  isClone = false,
  request,
}) {
  const path = [EQUIPMENT_PREFERENCES_PATH, id, "inventory_items"].join("/");

  return request
    .get(path, {
      params: {
        options: {
          include: [
            "accessory_masters",
            "equipment_master",
            "facility",
            "rental",
          ],
        },
      },
    })
    .then(({ data }) =>
      data.data.map((props) => buildEpiiProps(props, isClone))
    );
}

// Data should be reformated to display EPII as the parent
// currently,
//   inventory_item: { ..., epii: {...} }
// to
//   epii: { ..., meta: { ..., inventory_item }}
//
// This is to support Rails form string params
export const buildEpiiProps = (props, isClone) => {
  const { attributes, id: inventoryItemId } = props;

  const {
    equipmentMasterId,
    meta: { deleted_at, equipment_preferences_inventory_item_id: id, usage },
    type,
    ...rest
  } = attributes;

  return {
    deletedAt: isClone ? null : deleted_at,
    id: isClone ? null : id,
    inventoryItemId,
    ...usage,
    meta: {
      // stores inventory item attributes (props)
      ...rest,
      id: inventoryItemId,
      type,
      usage,
      url: props.links.self,
    },
  };
};
