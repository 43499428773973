import React, { useContext, useEffect, useState } from "react";

import { EQUIPMENT_PREFERENCES_PATH } from "../../../utils/capi";
import { formatActivityLog, Log } from "./utils";
import { RequestContext } from "../../../utils/capiClient";

export default function ActivityLog({ isSaving }) {
  const { request, params } = useContext(RequestContext);
  const [state, setState] = useState({
    versions: [],
  });
  const [isOpen, setisOpenState] = useState(false);

  useEffect(() => {
    if (isSaving || !request) return;

    request
      .get(`${EQUIPMENT_PREFERENCES_PATH}/${params.id}/versions`)
      .then(({ data }) => {
        const responseData = data.data;
        // const { included, meta } = data;

        if (responseData) {
          const uniqVersionUsers = _.uniq(
            _.map(responseData, "attributes.whodunnit")
          ).filter((n) => n);

          setState({
            ...state,
            uniqVersionUsers: uniqVersionUsers,
            versions: responseData,
          });
        }
      });
  }, [!!request, isSaving]);

  const { versions } = state;
  const activityLogAttrs = formatActivityLog(params.user.timeZone, versions);

  return (
    <div
      className="expand-collapse-group inline-toggle"
      id="equipment-preference-activity-log"
    >
      <div className="title" onClick={() => setisOpenState(!isOpen)}>
        Activity Log ({versions.length})
        <span className="toggle-icon">
          <i
            className={`sprite-icon sprite-chevron-${
              isOpen ? "up-s-grey collapse" : "down-s-grey expand"
            }`}
          />
        </span>
      </div>
      <div className={`collapse ${isOpen ? "in" : null}`}>
        {activityLogAttrs && activityLogAttrs.length ? (
          <Log log={activityLogAttrs} />
        ) : (
          <h3 className="empty content">No version history available</h3>
        )}
      </div>
    </div>
  );
}
