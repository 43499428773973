import React, { forwardRef } from "react";
import styled from "styled-components";

export const StyledButtonGroup = styled.div`
  margin-left: 10px !important;

  &:first-child {
    margin-left: 0px !important;
  }
`;

const StyledButtonIcon = styled.a`
  padding: 11px 20px;

  i {
    @media screen and (max-width: 768px) {
      font-size: 1em !important;
    }
  }
`;

const ButtonGroup = forwardRef(function ButtonGroup({ isOpen, children }, ref) {
  const klassName = `btn-group${isOpen ? " open" : ""}`;
  return (
    <StyledButtonGroup className={klassName} ref={ref}>
      {children}
    </StyledButtonGroup>
  );
});

export default ButtonGroup;

export function ButtonIcon({ buttonClass, isActive, iconClass, ...rest }) {
  return (
    <StyledButtonIcon
      className={`btn${isActive ? " active" : ""}${
        buttonClass ? buttonClass : ""
      }`}
      {...rest}
    >
      <i className={iconClass} />
    </StyledButtonIcon>
  );
}
