import React, { useState } from "react";
import Select from "react-select-3";
import { USAGE_STYLES } from "./select_styles";

function UsageComponent(props) {
  const [options, _] = useState(optionsForUsage());
  // when flipping between radios, partial use values are not reset
  const [usage, setUsage] = useState(props.usage);

  const isFullCase = usage.fullCaseTimeUsage;
  const isPartialUseValues = usage.duration != null || usage.startTime != 0;
  const handleFullCaseChange = () => {
    if (isPartialUseValues) {
      props.onChange({ duration: null, fullCaseTimeUsage: true, startTime: 0 });
    }
    setUsage({ ...usage, fullCaseTimeUsage: true });
  };

  const handlePartialCaseChange = () => {
    const usageChange = { ...usage, fullCaseTimeUsage: false };
    if (isPartialUseValues) props.onChange(usageChange);
    setUsage(usageChange);
  };

  const handlePartialUse = (newUsage) => {
    setUsage(newUsage);
    props.onChange(newUsage);
  };

  return (
    <div className="control-group in-use-container">
      <div className="row-fluid">
        <label className="radio" htmlFor={`full_case_${options.randId}`}>
          <input
            checked={isFullCase}
            className="entire-case-radio"
            id={`full_case_${options.randId}`}
            onChange={handleFullCaseChange}
            type="radio"
            value="entire_case"
          />
          Reserve for full case
        </label>
      </div>
      <div className="row-fluid">
        <label
          className="inline partial-case-label radio"
          htmlFor={`partial_case_${options.randId}`}
        >
          <input
            checked={!isFullCase}
            className="partial-case-radio"
            id={`partial_case_${options.randId}`}
            onChange={handlePartialCaseChange}
            type="radio"
            value="partial_case"
          />
          Reserve for partial case for
        </label>
        {!isFullCase && (
          <PartialUsageSection
            onChange={handlePartialUse}
            options={options}
            usage={usage}
          />
        )}
      </div>
    </div>
  );
}

export default UsageComponent;

const optionsForUsage = () => {
  // options for start time
  const optionsStartTime = [...Array(33).keys()].map((x) => {
    const mins = x * 15;
    return {
      label: `${Math.floor(mins / 60)}h ${mins % 60}m`,
      value: mins,
    };
  });

  // options for duration (first option is 'until end of case')
  const optionsDuration = JSON.parse(JSON.stringify(optionsStartTime));
  optionsDuration[0].label = "until end of case";

  // options for '+/-' start time
  const optionsBeforeAfter = [
    { label: "before", value: "before" },
    { label: "after", value: "after" },
  ];

  return {
    beforeAfter: optionsBeforeAfter,
    duration: optionsDuration,
    randId: Math.floor(Math.random() * 10000),
    startTime: optionsStartTime,
  };
};

function PartialUsageSection({ onChange, options, usage }) {
  const [isBefore, setIsBefore] = useState(parseInt(usage.startTime) < 0);

  const handleBeforeAfterChange = () => {
    setIsBefore(!isBefore);

    // when startTime == 0, negativity should not
    // trigger an onChange
    // NOTE: "0" == 0 is truthy
    if (usage.startTime != 0) {
      const absStartTime = Math.abs(usage.startTime);
      onChange({
        ...usage,
        startTime: isBefore ? absStartTime : absStartTime * -1,
      });
    }
  };

  const handleDurationChange = (change) =>
    onChange({ ...usage, duration: change.value });

  // change.value is always positive
  const handleStartTimeChange = (change) =>
    onChange({
      ...usage,
      startTime: isBefore ? change.value * -1 : change.value,
    });

  const selDuration = usage.duration
    ? options.duration.find((opt) => opt.value == usage.duration)
    : options.duration[0];

  return (
    <div className="in-use-select-wrapper inline">
      <Select
        className="basic-single start-time span2"
        classNamePrefix="select"
        isSearchable={false}
        onChange={handleStartTimeChange}
        options={options.startTime}
        styles={USAGE_STYLES}
        value={
          options.startTime.find(
            (opt) => opt.value == Math.abs(usage.startTime)
          ) || options.startTime[0]
        }
      />
      <Select
        className="basic-single span2"
        classNamePrefix="select"
        isSearchable={false}
        onChange={handleBeforeAfterChange}
        options={options.beforeAfter}
        styles={USAGE_STYLES}
        value={options.beforeAfter[isBefore ? 0 : 1]}
      />
      {" start, for "}
      <Select
        className="basic-single span3"
        classNamePrefix="select"
        isSearchable={false}
        onChange={handleDurationChange}
        options={options.duration}
        styles={USAGE_STYLES}
        value={selDuration}
      />
    </div>
  );
}
