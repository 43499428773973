import React from "react";

import { Link, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LockIcon from "@mui/icons-material/Lock";
import StarIcon from "@mui/icons-material/Star";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { blue9, grey6, rental } from "./base";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

export const AutoresIcon = ({ tooltip, value, ...rest }) => (
  <Link
    href={`/equipment_preferences/${value}/edit`}
    rel="noopener"
    target="_blank"
  >
    <Tooltip
      {...{
        placement: "top",
        title: "Autoreserved",
        ...tooltip,
      }}
    >
      <i className="sprite-icon sprite-autores-s autores tip" style={rest.sx} />
    </Tooltip>
  </Link>
);

export const CollaborativelyPurchasedIcon = ({ tooltip }) => (
  <Tooltip placement="top" title="Collaboratively purchased">
    <StarIcon sx={{ color: blue9, fontSize: 14 }} />
  </Tooltip>
);

export const HomeEqIcon = () => (
  <Tooltip placement="top" title="Sourced from campus">
    <HomeIcon color="primary" sx={{ fontSize: 14 }} />
  </Tooltip>
);

export const InactiveIcon = ({ tooltip }) => (
  <Tooltip
    {...{
      placement: "top",
      title: "Inactive",
      ...tooltip,
    }}
  >
    <VisibilityOffIcon sx={{ color: grey6, fontSize: 14 }} />
  </Tooltip>
);

export const NetworkEqIcon = () => (
  <Tooltip placement="top" title="Sourced from network">
    <LocalShippingIcon color="primary" sx={{ fontSize: 14 }} />
  </Tooltip>
);

export const RentalEqIcon = () => (
  <Tooltip placement="top" title="Rental">
    <LocalShippingIcon sx={{ color: rental, fontSize: 14 }} />
  </Tooltip>
);

export const NotShareableIcon = ({ tooltip }) => (
  <Tooltip
    {...{
      placement: "top",
      title: "Not shareable with network",
      ...tooltip,
    }}
  >
    <LockIcon sx={{ fontSize: 14 }} />
  </Tooltip>
);

/** Move Status Icons */
export const CompleteButton = ({ onClick, disabled }) =>
  disabled ? (
    <Tooltip placement="top" title="Complete">
      <span>
        <IconButton
          disabled
          onClick={onClick}
          size="small"
          sx={{ cursor: "not-allowed" }}
        >
          <CheckCircleOutlineIcon sx={{ color: "white", fontSize: 20 }} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <Tooltip placement="top" title="Complete">
      <IconButton onClick={onClick} size="small">
        <CheckCircleOutlineIcon sx={{ color: "white", fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );

export const IncompleteButton = ({ onClick, disabled }) =>
  disabled ? (
    <Tooltip placement="top" title="Incomplete">
      <span>
        <IconButton
          disabled
          onClick={onClick}
          size="small"
          sx={{ cursor: "not-allowed" }}
        >
          <AccountCircleOutlinedIcon sx={{ color: "white", fontSize: 20 }} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <Tooltip placement="top" title="Incomplete">
      <IconButton onClick={onClick} size="small">
        <AccountCircleOutlinedIcon sx={{ color: "white", fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );

export const InprogressButton = ({ disabled, onClick }) =>
  disabled ? (
    <Tooltip placement="top" title="Inprogress">
      <span>
        <IconButton
          disabled
          onClick={onClick}
          size="small"
          sx={{ cursor: "not-allowed" }}
        >
          <LocalShippingOutlinedIcon sx={{ color: "white", fontSize: 20 }} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <Tooltip placement="top" title="Inprogress">
      <IconButton onClick={onClick} size="small">
        <LocalShippingOutlinedIcon sx={{ color: "white", fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );

export const UnassignedButton = ({ disabled, onClick }) =>
  disabled ? (
    <Tooltip placement="top" title="Planned">
      <span>
        <IconButton
          disabled
          onClick={onClick}
          size="small"
          sx={{ cursor: "not-allowed" }}
        >
          <CircleOutlinedIcon sx={{ color: "white", fontSize: 20 }} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <Tooltip placement="top" title="Planned">
      <IconButton onClick={onClick} size="small">
        <CircleOutlinedIcon sx={{ color: "white", fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );
