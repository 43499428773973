import { blueLight, grey9, selected, white } from "../../utils/base";

export const SELECT_STYLES = {
  clearIndicator: (provided, state) => ({
    ...provided,
    height: "17px",
    marginBottom: "3px",
    padding: "0px",
    width: "17px",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
    paddingRight: "5px",
  }),
  indicatorSeparator: () => {},
  input: (provided, state) => ({
    ...provided,
    input: {
      borderColor: "#FFF !important", // override needed due to select2/bootstrap dep
      boxShadow: "none !important",
      margin: "0px !important",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    margin: "0px",
  }),
  option: (provided, { isSelected }) => {
    return {
      ...provided,
      borderTop: "1px solid #d6d6d6",
      "&:first-of-type": {
        borderTop: "none",
      },
      ...(isSelected
        ? {
            backgroundColor: selected,
            color: "#FFF",
            ".inventory-code": {
              color: "#FFF",
            },
          }
        : {
            "&:hover": {
              backgroundColor: blueLight,
              color: grey9,
            },
          }),
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    paddingLeft: "6px",
  }),
};
