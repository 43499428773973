import React, { useContext } from "react";
import { blue, grey1, grey6, grey9, rental } from "../../utils/base";
import {
  AutoresIcon,
  CollaborativelyPurchasedIcon,
  HomeEqIcon,
  InactiveIcon,
  NetworkEqIcon,
  NotShareableIcon,
  RentalEqIcon,
} from "../../utils/baseIcons";
import { Box, Card, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RequestContext } from "../../utils/capiClient";

const LinkMasterName = ({ children, ...rest }) => (
  <Link
    underline="hover"
    {...rest}
    sx={{
      color: grey9,
      "&:hover": {
        color: grey9,
      },
      ...rest.sx,
    }}
  >
    {children}
  </Link>
);

export const NoEquipmentPill = ({ rowId, value }) => {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: grey1,
        "&:hover": {
          boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)",
        },
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid
          alignItems="center"
          display="flex"
          container
          gap="5px"
          justifyContent="center"
          padding="10px 0px"
          xs
        >
          <span>Equipment not required</span>
          {Boolean(value) && (
            <AutoresIcon key={`${rowId}-autores`} value={value} />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default function EquipmentPill({ colDef, extras, rowId, value }) {
  const {
    extras: { relMaps },
  } = useContext(RequestContext);
  const { attributes } = value;

  const equipmentCampus = relMaps.facilityCampus?.[attributes.homeFacility.id];
  const caseCampus = relMaps.facilityCampus?.[extras.facilityId];

  const hasEquipmentCodes = Boolean(
    Object.values(attributes.codes).find((val) => val?.length > 0)
  );
  const isHome = attributes.homeFacility.id == extras.facilityId;
  const isRental = Boolean(attributes.rentalCompany.id);
  const isSameCampus =
    equipmentCampus !== undefined && equipmentCampus === caseCampus;

  const equipmentBadges = [];
  if (attributes.status === "not-shareable")
    equipmentBadges.push(<NotShareableIcon key={`${rowId}-not-shareable`} />);
  if (attributes.status === "inactive")
    equipmentBadges.push(<InactiveIcon key={`${rowId}-inactive`} />);
  if (attributes.collaborativelyPurchased)
    equipmentBadges.push(
      <CollaborativelyPurchasedIcon key={`${rowId}-collab-purch`} />
    );
  if (attributes.patientCase.autoreserved)
    equipmentBadges.push(
      <AutoresIcon
        key={`${rowId}-autores`}
        value={attributes.patientCase.equipment_preference_id}
      />
    );

  const sourceBadges = [
    <Tooltip
      placement="top"
      key={`${rowId}-site-code`}
      title={`Home at ${attributes.homeFacility.name}${
        equipmentCampus ? ` (${equipmentCampus.name})` : ""
      }`}
    >
      <span>{attributes.homeFacility.siteCode}</span>
    </Tooltip>,
  ];

  if (isRental) {
    sourceBadges.unshift(<RentalEqIcon key={`${rowId}-rental`} />);
  } else if (!isHome) {
    sourceBadges.unshift(
      isSameCampus ? (
        <HomeEqIcon key={`${rowId}-is-home`} />
      ) : (
        <NetworkEqIcon key={`${rowId}-network`} />
      )
    );
  }

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: grey1,
        ...(isSameCampus || isHome
          ? {}
          : { borderRight: `5px solid ${isRental ? rental : blue}` }),
        padding: "3px 5px",
        "&:hover": {
          boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)",
          "& .hidden-codes": { display: "flex" },
        },
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid
          alignItems="center"
          display="flex"
          container
          flexDirection="column"
          gap="5px"
          justifyContent="center"
          xs
        >
          <Grid xs={12}>
            <LinkMasterName
              href={value.links.self}
              rel="noopener"
              sx={{ wordBreak: "break-word" }}
              target="_blank"
            >
              {attributes.equipmentMaster.name}
            </LinkMasterName>
          </Grid>
          <Grid display="flex" gap="4px" xs={12}>
            {equipmentBadges.length > 0 && (
              <Box
                alignItems="center"
                display="inline-flex"
                sx={{
                  borderRight: "1px solid #999",
                  color: grey6,
                  fontSize: 14,
                  gap: "2px",
                  paddingRight: "4px",
                }}
              >
                {equipmentBadges}
              </Box>
            )}
            <Box
              alignItems="center"
              display="inline-flex"
              sx={{
                color: grey6,
                gap: "2px",
                fontSize: 10,
              }}
            >
              {sourceBadges}
            </Box>
          </Grid>
        </Grid>
        {hasEquipmentCodes ? (
          colDef.width >= 325 ? (
            <GridCodes
              rowId={rowId}
              sx={{
                backgroundColor: "#F3F3F3",
                fontSize: 10,
                margin: "-3px -5px",
                marginLeft: 0,
                minWidth: 120,
                padding: "8px 5px",
              }}
              xs={3}
              xsOffset="auto"
              value={attributes.codes}
            />
          ) : (
            <Tooltip
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
              title={
                <GridCodes
                  rowId={rowId}
                  sx={{ maxWidth: "120px" }}
                  value={attributes.codes}
                />
              }
            >
              <Grid
                alignItems="center"
                display="none"
                className="hidden-codes"
                container
                justifyContent="center"
                sx={{
                  backgroundColor: "#f3f3f3",
                  cursor: "default",
                  margin: "-3px -5px",
                  width: "20px",
                  "&:hover": { fontWeight: "bold" },
                }}
              >
                <i>i</i>
              </Grid>
            </Tooltip>
          )
        ) : undefined}
      </Grid>
    </Card>
  );
}

const renderCodeList = (codes, rowId) => {
  const list = [];
  const labelSx = { marginRight: "5px", textWrap: "nowrap" };
  const rowProps = {
    display: "flex",
    sx: {
      width: "100%",
      justifyContent: "space-between",
    },
    xs: 12,
  };
  const valueSx = { wordBreak: "break-word" };

  if (codes?.serial) {
    list.push(
      <Grid key={`${rowId}-label-sn-${codes.serial}`} {...rowProps}>
        <Box sx={labelSx}>SN:</Box>
        <Box sx={valueSx}>{codes.serial}</Box>
      </Grid>
    );
  }

  if (codes?.assetTag) {
    list.push(
      <Grid key={`${rowId}-label-asset-${codes.assetTag}`} {...rowProps}>
        <Box sx={labelSx}>Asset:</Box>
        <Box sx={valueSx}>{codes.assetTag}</Box>
      </Grid>
    );
  }

  if (codes?.cohealoTag) {
    list.push(
      <Grid key={`${rowId}-label-cohealo-${codes.cohealoTag}`} {...rowProps}>
        <Box sx={labelSx}>Cohealo:</Box>
        <Box sx={valueSx}>{codes.cohealoTag}</Box>
      </Grid>
    );
  }
  return list;
};

const GridCodes = ({ rowId, value, ...rest }) => (
  <Grid container {...rest}>
    {renderCodeList(value, rowId)}
  </Grid>
);
