import React, { useState } from "react";

import { facilitySamples } from "../../../specSamples/facility.samples";
import { takeSample } from "../../../specSamples/utils";
import mockRequestContext from "../../../specSamples/mockRequestContext";

import { FACILITIES_PATH } from "../../../utils/capi";

import SelectFields, { transformDataToOption } from "./SelectFields";

const { withRequestContext } = mockRequestContext({
  path: FACILITIES_PATH,
  sample: facilitySamples,
});

export default {
  argTypes: {
    handleChange: { action: "Input value changed" },
  },
  component: SelectFields,
  decorators: [
    (Story) => (
      <div className="span12">
        <div className="box-content">
          <Story />
        </div>
      </div>
    ),
    withRequestContext,
  ],
  title: "Form/selects/SelectFields",
};

const StatefulComponent = (args) => {
  const [selected, setSelected] = useState(args.selected ?? null);

  const handleChange = (change) => setSelected(change);

  return (
    <SelectFields {...args} handleChange={handleChange} selected={selected} />
  );
};

const Template = (args) => <StatefulComponent {...args} />;

export const Default = Template.bind({});
Default.args = {
  alias: {
    objectId: "facilityId",
    name: "foo facility",
    many: "Foo facility",
  },
  // classNameExtras,
  // components = {
  //   FieldLabel: () => <li id="placeholder-label">None selected</li>,
  //   Placeholder: () => <span className="empty">Cannot edit list</span>,
  // },
  // creatable = false,
  handleChange: () => {},
  // isDisabled,
  // isEditable,
  requestUrl: FACILITIES_PATH,
  // required = false,
  // selected,
};

export const Creatable = Template.bind({});
Creatable.args = {
  ...Default.args,
  creatable: true,
};

export const IsDisabled = Template.bind({});
IsDisabled.args = {
  ...Default.args,
  isDisabled: true,
};

export const IsNotEditable = Template.bind({});
IsNotEditable.args = {
  ...Default.args,
  isEditable: false,
};

export const Selected = Template.bind({});
Selected.args = {
  ...Default.args,
  selected: takeSample(facilitySamples, 4).map(transformDataToOption),
};

export const SelectedIsDisabled = Template.bind({});
SelectedIsDisabled.args = {
  ...IsDisabled.args,
  ...Selected.args,
};

export const SelectedIsNotEditable = Template.bind({});
SelectedIsNotEditable.args = {
  ...IsNotEditable.args,
  ...Selected.args,
};
