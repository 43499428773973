import React from "react";
import PropTypes from "prop-types";

import TooltipWrapper from "../../utils/TooltipWrapper";
import {
  AutoresIcon,
  InventoryLabels,
  renderInventoryCodes,
  RentalIcon,
} from "./utils";

export default function InventoryItem(props) {
  const Controls = props.controlComponent;
  const {
    accessoryMasters,
    autoreserved,
    booking,
    codes,
    disposableNotes,
    equipmentMaster,
    equipmentPreference,
    fallback,
    fieldId,
    homeFacility,
    inventoryItemId,
    rentalConfirmationNumber,
    usage,
  } = props;
  const formName = `patient_case[patient_cases_inventory_items_attributes][${fieldId}]`;
  const isRental = props.type === "RentableInventory";

  return props.isDestroyable ? (
    <NestedForms formName={formName} id={props.id} isDestroyable />
  ) : (
    <div className="selected-inventory-item expandable" data-target={fieldId}>
      <div className="source-master row-fluid">
        <div className="master-thumb">
          <a className="pill-link" target="_blank" href={props.url}>
            <img className="master-thumb-img" src={props.urlImgThumb} />
          </a>
        </div>
        <div className="master-info">
          <InventoryLink
            name={equipmentMaster && equipmentMaster.name}
            url={props.url}
          />
          {autoreserved && <AutoresIcon {...equipmentPreference} />}
        </div>
        <span className="source-location">
          {!isRental && booking && <SrcBooking {...booking} />}
          {isRental ? <RentalIcon /> : homeFacility.siteCode}
        </span>
      </div>
      <div className="source-info">
        {!isRental && (
          <InventoryLabels
            collabPurchased={props.collabPurchased}
            homeSiteCode={homeFacility.siteCode}
            status={props.status}
            unavailabilities={props.unavailabilities}
            usages={props.usages}
          />
        )}
        {renderInventoryCodes(codes)}
      </div>
      <div className="source-items">
        <div className="source-meta">
          <strong>Accessories:</strong>
          <AccessoriesList accessories={accessoryMasters} />
          <strong>Disposables:</strong>
          {` ${disposableNotes || "No"} `}
          {isRental && <strong>Rental Confirmation:</strong>}
          {isRental && ` ${rentalConfirmationNumber || "None"}`}
        </div>
        <SourceUsage {...usage} />
      </div>
      {fallback && <Fallback name={fallback.name} />}
      {props.controlComponent && <Controls />}
      <NestedForms
        disposableNotes={disposableNotes}
        equipmentPreference={equipmentPreference}
        formName={formName}
        id={props.id}
        inventoryItemId={inventoryItemId}
        reservedById={props.reservedBy && props.reservedBy.id}
        rentalConfirmationNumber={rentalConfirmationNumber}
        usage={usage}
      />
    </div>
  );
}

const Fallback = ({ name }) => {
  return (
    <div className="source-items">
      <div className="source-meta">
        <i className="icon icon-mail-forward" />
        <strong> Fallback for: </strong>
        {name}
      </div>
    </div>
  );
};

const InventoryLink = ({ name, url }) => (
  <a className="pill-link" target="_blank" href={url}>
    {name}
  </a>
);

const SrcBooking = ({ url }) => (
  <TooltipWrapper
    id="booking"
    title="View associated booking"
    options={{ "data-placement": "top" }}
    style={{
      cursor: "pointer",
      padding: "5px 5px 5px 10px",
    }}
    url={url}
  >
    <i className="sprite-icon sprite-box-arrow-s-grey" />
  </TooltipWrapper>
);

const NestedForms = ({
  disposableNotes,
  equipmentPreference = {},
  formName,
  id,
  inventoryItemId,
  isDestroyable,
  reservedById,
  rentalConfirmationNumber,
  usage = {},
}) => {
  const NestedFormInput = ({ className, value }) => (
    <input
      className={className}
      key={Math.random}
      name={`${formName}[${className.replaceAll("-", "_")}]`}
      type="hidden"
      value={value || ""}
    />
  );

  const renderableInputs = () => {
    const inputs = [];

    // "autoreserved_usage" => "true",
    if (id) {
      inputs.push({ className: "id", value: id });
      if (isDestroyable) {
        inputs.push({ className: "-destroy", value: true });
        return inputs;
      }
      const isAutoreserved = !!(equipmentPreference && equipmentPreference.id);
      inputs.push({
        className: "autoreserved",
        value: isAutoreserved.toString(),
      });
      inputs.push(
        isAutoreserved
          ? {
              className: "equipment-preference-id",
              value: isAutoreserved ? equipmentPreference.id : null,
            }
          : {
              className: "equipment-preference-id", // null value
            }
      );
    }
    if (usage) {
      const isFullCase = Boolean(usage.fullCaseTimeUsage);
      inputs.push(
        {
          className: "autoreserved-usage",
          value: usage.isModified,
        },
        {
          className: "in-use",
          value: isFullCase ? "entire_case" : "partial_case",
        },
        { className: "start-time", value: isFullCase ? 0 : usage.startTime || 0 },
        { className: "duration", value: isFullCase ? null : usage.duration }
      );
    }
    inputs.push(
      { className: "disposable-notes", value: disposableNotes },
      { className: "inventory-item-id", value: inventoryItemId },
      { className: "reserved-by-id", value: reservedById }
    );
    if (rentalConfirmationNumber && rentalConfirmationNumber.length) {
      inputs.push({
        className: "rental-confirmation-number",
        value: rentalConfirmationNumber,
      });
    }
    return inputs;
  };

  return (
    <div className="react-rails-nested-form" style={{ display: "hidden" }}>
      {renderableInputs()?.map((input, index) => (
        <NestedFormInput {...input} key={index} />
      ))}
    </div>
  );
};

const AccessoriesList = ({ accessories }) =>
  accessories?.length
    ? ` ${accessories.map((ac) => `${ac.name} (${ac.quantity})`).join(", ")} `
    : " (0) ";

const SourceUsage = ({
  duration,
  fullCaseTimeUsage,
  isModified,
  startTime,
}) => {
  let content;
  if (fullCaseTimeUsage) {
    content = "Entire case";
  } else {
    const displayFriendlyDuration = (dur) => {
      const absDuration = Math.abs(dur);
      const hours = Math.floor(absDuration / 60);
      const mins = absDuration % 60;

      return `${hours}h ${mins}m`;
    };
    const startTimeParsed = parseInt(startTime || 0);
    content = displayFriendlyDuration(startTimeParsed);
    content +=
      startTimeParsed >= 0 ? " after case start, " : " before case start, ";
    content += duration
      ? `for ${displayFriendlyDuration(duration)}`
      : "until end of case";
  }

  return (
    <div className="in-use">
      <strong>{`${isModified ? "*" : ""}In use: `}</strong>
      {content}
    </div>
  );
};
