import { borderRadiusDefault } from "./base";

/*
  These should be mixins and not static values. Please place those in `base.js`

  Following borders replaces bootstrap _mixins.scss
*/

/*
  Border Radius
*/

export const borderRadius = (radius = borderRadiusDefault) => `
  -webkit-border-radius: ${radius};
  -moz-border-radius: ${radius};
  border-radius: ${radius};
`;

export const borderTopLeftRadius = (radius = borderRadiusDefault) => `
  -webkit-border-top-left-radius: ${radius};
  -moz-border-radius-topleft: ${radius};
  border-top-left-radius: ${radius};
`;

export const borderTopRightRadius = (radius = borderRadiusDefault) => `
  -webkit-border-top-right-radius: ${radius};
  -moz-border-radius-topright: ${radius};
  border-top-right-radius: ${radius};
`;

export const borderBottomRightRadius = (radius = borderRadiusDefault) => `
  -webkit-border-bottom-right-radius: ${radius};
  -moz-border-radius-bottomright: ${radius};
  border-bottom-right-radius: ${radius};
`;

export const borderBottomLeftRadius = (radius = borderRadiusDefault) => `
  -webkit-border-bottom-left-radius: ${radius};
  -moz-border-radius-bottomleft: ${radius};
  border-bottom-left-radius: ${radius};
`;

/*
  Single Side Border Radius
*/

export const borderTopRadius = (radius = borderRadiusDefault) => `
  ${borderTopRightRadius(radius)}
  ${borderTopLeftRadius(radius)}
`;

export const borderRightRadius = (radius = borderRadiusDefault) => `
  ${borderTopRightRadius(radius)}
  ${borderBottomRightRadius(radius)}
`;

export const borderBottomRadius = (radius = borderRadiusDefault) => `
  ${borderBottomRightRadius(radius)}
  ${borderBottomLeftRadius(radius)}
`;

export const borderLeftRadius = (radius = borderRadiusDefault) => `
  ${borderTopLeftRadius(radius)}
  ${borderBottomLeftRadius(radius)}
`;

/*
  Drop Shadows
*/

export const boxShadow = (shadow) => `
  -webkit-box-shadow: ${shadow};
  -moz-box-shadow: ${shadow};
  box-shadow: ${shadow};
`;
