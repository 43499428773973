import React, { useState } from "react";
import PropTypes from "prop-types";

import Select from "react-select-3";
import { SELECT_STYLES } from "./select_styles";

export default function ServiceLineForm(props) {
  const getSelectedOption = (id) =>
    id ? props.options.find((opt) => opt.value == id) : null;

  const [selected, setSelected] = useState(getSelectedOption(props.selectedId));

  const selectedValue = selected?.value ?? "";

  return (
    <div className="row-fluid case-edit-row">
      <i className="sprite-icon sprite-globe-m-grey-light pull-left case-edit-icon" />
      <div className="case-edit-fields">
        <div className="control-group span4">
          <label className="control-label">Service Line:</label>
          <div className="controls">
            <Select
              className="input-block-level"
              classNamePrefix="select"
              isClearable
              options={props.options}
              onChange={(change) => setSelected(change)}
              placeholder="Please select service"
              styles={SELECT_STYLES}
              value={selected}
            />
            <input
              name="patient_case[service_line_id]"
              type="hidden"
              value={selectedValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
