import React from "react";
import PropTypes from "prop-types";

export const SideView = ({ className, id, children }) => (
  <div className={className}>
    {Array.isArray(children) ? (
      children.map((child, index) => (
        <div className="box box-page" key={`sv-${id}-${index}`}>
          {child}
        </div>
      ))
    ) : (
      <div className="box box-page">{children}</div>
    )}
  </div>
);

SideView.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

export const TopView = ({ children }) => (
  <div className="box box-page">
    <div className="box-content">
      <div className="row-fluid header-info">
        <div className="span9">
          <div className="row-fluid">
            <div className="span4">{children[0]}</div>
            <div className="span4">
              <div
                className="cell group facilities"
                id="equipment_preference_facility"
              >
                {children[1]}
              </div>
            </div>
            <div className="span4">
              <div
                className="cell group servicelines"
                id="#equipment_preference_service_line"
              >
                {children[2]}
              </div>
            </div>
          </div>
          <div className="row-fluid">{children[3]}</div>
        </div>
        <div className="span3">
          <span className="meta-info" style={{ display: "block" }}>
            <div className="header">Information:</div>
            {children[4]}
            {children[5]}
            {children[6]}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export const Loader = () => (
  <div className="loader loader-medium loader-container">
    <div className="message">Loading...</div>
    <img
      alt="/images/loading.gif"
      height="32"
      src="/images/loading.gif"
      width="32"
    />
  </div>
);
