export const buildOperatorItems = (items) =>
  items.map((item) => ({
    getApplyFilterFnV7: () => {},
    ...item,
  }));

export const getValueAsStringInventory = (value) => {
  const eqmCount = value.reduce((memo, opt) => {
    // Tokens
    if (typeof opt === "string") {
      memo[opt] = { name: opt };
      return memo;
    }

    const isMaster = opt.type === "equipmentMasters";
    const eqmId = isMaster ? opt.id : opt.attributes.equipmentMaster.id;

    if (isMaster) {
      memo[eqmId] = { name: opt.attributes.name };
    } else {
      if (memo[eqmId]) {
        memo[eqmId].count++;
      } else {
        memo[eqmId] = {
          name: opt.attributes.equipmentMaster.name,
          count: 1,
        };
      }
    }
    return memo;
  }, {});

  return Object.values(eqmCount)
    .map((eq) => `${eq.name}${eq.count ? ` (${eq.count})` : ""}`)
    .join(", ");
};
