import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GridFilterForm,
  GridLogicOperator,
  GridPanelContent,
  GridPanelFooter,
  GridPanelWrapper,
  gridFilterModelSelector,
  gridFilterableColumnDefinitionsSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import _ from "lodash";

const getGridFilter = (col) => ({
  field: col.field,
  operator: col.filterOperators?.[0].value,
  id: Math.round(Math.random() * 1e5),
});

// DEV: TODO:
// Update on customization of filter panel
// https://github.com/mui/mui-x/issues/10938
//
// Initial Copy Paste port of GridFilterPanel
// https://github.com/mui/mui-x/blob/v6.19.0/packages/grid/x-data-grid/src/components/panel/filterPanel/GridFilterPanel.tsx
export default function ServerFilterPanel(props) {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterableColumns = useGridSelector(
    apiRef,
    gridFilterableColumnDefinitionsSelector
  );
  const lastFilterRef = useRef(null);
  const placeholderFilter = useRef(null);
  const [activeFilterModel, setActiveFilterModel] = useState(filterModel);

  const {
    children,
    columnsSort,
    // DEV: removed until needed
    // disableAddFilterButton = false,
    // disableRemoveAllButton = false,
    filterFormProps,
    getColumnForNewFilter,
    logicOperators = [GridLogicOperator.And, GridLogicOperator.Or],
    ...other
  } = props;
  // 1. disable apply on no changes
  // 3. ensure clearing will invoke delete.
  // 4. ensure 'any' looks like a placeholder

  const applyFilter = apiRef.current.upsertFilterItem;

  const applyFilterLogicOperator = useCallback(
    (operator) => apiRef.current.setFilterLogicOperator(operator),
    [apiRef]
  );

  const getDefaultFilter = useCallback(() => {
    let nextColumnWithOperator;
    if (getColumnForNewFilter && typeof getColumnForNewFilter === "function") {
      // To allow override the column for default (first) filter
      const nextFieldName = getColumnForNewFilter({
        currentFilters: filterModel?.items || [],
        columns: filterableColumns,
      });

      if (nextFieldName === null) {
        return null;
      }

      nextColumnWithOperator = filterableColumns.find(
        ({ field }) => field === nextFieldName
      );
    } else {
      nextColumnWithOperator = filterableColumns.find(
        (colDef) => colDef.filterOperators?.length
      );
    }

    if (!nextColumnWithOperator) return null;

    return getGridFilter(nextColumnWithOperator);
  }, [filterModel?.items, filterableColumns, getColumnForNewFilter]);

  const getNewFilter = React.useCallback(() => {
    if (
      getColumnForNewFilter === undefined ||
      typeof getColumnForNewFilter !== "function"
    ) {
      return getDefaultFilter();
    }

    const currentFilters = filterModel.items.length
      ? filterModel.items
      : [getDefaultFilter()].filter(Boolean);

    // If no items are there in filterModel, we have to pass defaultFilter
    const nextColumnFieldName = getColumnForNewFilter({
      currentFilters: currentFilters,
      columns: filterableColumns,
    });

    if (nextColumnFieldName === null) return null;

    const nextColumnWithOperator = filterableColumns.find(
      ({ field }) => field === nextColumnFieldName
    );

    if (!nextColumnWithOperator) return null;

    return getGridFilter(nextColumnWithOperator);
  }, [
    filterModel.items,
    filterableColumns,
    getColumnForNewFilter,
    getDefaultFilter,
  ]);

  const items = useMemo(() => {
    if (filterModel.items.length) return filterModel.items;

    if (!placeholderFilter.current)
      placeholderFilter.current = getDefaultFilter();

    return placeholderFilter.current ? [placeholderFilter.current] : [];
  }, [filterModel.items, getDefaultFilter]);

  const hasMultipleFilters = items.length > 1;

  const addNewFilter = () => {
    const newFilter = getNewFilter();
    if (!newFilter) return;
    apiRef.current.upsertFilterItems([...items, newFilter]);
  };

  const deleteFilter = useCallback(
    (item) => {
      const shouldCloseFilterPanel = items.length === 1;
      apiRef.current.deleteFilterItem(item);
      if (shouldCloseFilterPanel) apiRef.current.hideFilterPanel();
    },
    [apiRef, items.length]
  );

  const handleRemoveAll = () => {
    const newFilterModel = { ...filterModel, items: [] };
    apiRef.current.setFilterModel(newFilterModel);
  };

  useEffect(() => {
    if (
      logicOperators.length > 0 &&
      filterModel.logicOperator &&
      !logicOperators.includes(filterModel.logicOperator)
    )
      applyFilterLogicOperator(logicOperators[0]);
  }, [logicOperators, applyFilterLogicOperator, filterModel.logicOperator]);

  useEffect(() => {
    if (items.length > 0) lastFilterRef.current?.focus();
  }, [items.length]);

  return (
    <GridPanelWrapper
      {...other}
      sx={{
        "& input, .MuiAutocomplete-input": {
          height: "1.4375em",
          textAlign: "start",
          p: "8.5px 14px",
          mb: 0,
          "&:focus": {
            boxShadow: "none",
          },
        },
        "& label": {
          fontSize: 14,
        },
        "& .MuiInputBase-input": {
          fontSize: 13,
        },
        // Customize inputs using css selectors
        "& .MuiDataGrid-filterForm": {
          alignItems: "flex-start",
          gap: "8px",
          p: "10px 8px 8px 4px",
          ":nth-of-type(even)": {
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
            "& .MuiDataGrid-filterFormValueInput input": {
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
            },
          },
          "& .MuiDataGrid-filterFormDeleteIcon": {
            marginTop: "8px",
          },
          "& .MuiDataGrid-filterFormValueInput": {
            minWidth: 150,
            width: "inherit",
            input: {
              border: "none",
              height: 20.125,
            },
          },
          "& .MuiFormControl-root": {
            width: "auto",
            "& .MuiFormControl-fullWidth": {
              width: "100%",
            },
          },
        },
      }}
    >
      <GridPanelContent>
        {items.map((item, index) => (
          <GridFilterForm
            key={`${item.field}-${item.id == null ? index : item.id}`}
            applyFilterChanges={applyFilter}
            applyMultiFilterOperatorChanges={applyFilterLogicOperator}
            columnsSort={columnsSort}
            deleteFilter={deleteFilter}
            disableMultiFilterOperator={index !== 1}
            focusElementRef={index === items.length - 1 ? lastFilterRef : null}
            hasMultipleFilters={hasMultipleFilters}
            item={item}
            logicOperators={logicOperators}
            multiFilterOperator={filterModel.logicOperator}
            showMultiFilterOperators={index > 0}
            {...{
              logicOperatorInputProps: {
                size: "small",
                variant: "outlined",
              },
              columnInputProps: {
                size: "small",
                sx: {
                  minWidth: "185px",
                },
                variant: "outlined",
              },
              operatorInputProps: {
                size: "small",
                variant: "outlined",
              },
              valueInputProps: {
                InputComponentProps: {
                  size: "small",
                  variant: "outlined",
                },
              },
              deleteIconProps: {
                sx: {
                  justifyContent: "center",
                  mr: "6px",
                  "& .MuiSvgIcon-root": { color: "#d32f2f", fontSize: "small" },
                },
              },
            }}
            {...filterFormProps}
          />
        ))}
      </GridPanelContent>
      <GridPanelFooter>
        <Button onClick={addNewFilter} size="small" startIcon={<AddIcon />}>
          Add Filter
        </Button>
        <Button
          onClick={handleRemoveAll}
          size="small"
          startIcon={<ClearIcon />}
        >
          Reset Panel
        </Button>
      </GridPanelFooter>
    </GridPanelWrapper>
  );
}
