import React from "react";

import { equipmentMasterSamples } from "../../../../../specSamples/equipmentMaster.samples";
import mockRequestContext from "../../../../../specSamples/mockRequestContext";
import { EQUIPMENT_MASTERS_PATH } from "../../../../../utils/capi";
import { EquipmentProvider } from "../../../useEquipmentContext";

import EquipmentMasterSelect from "./EquipmentMasterSelect";

const { withRequestContext } = mockRequestContext({
  path: EQUIPMENT_MASTERS_PATH,
  sample: equipmentMasterSamples,
});

export default {
  argTypes: { onChange: { action: "Input value changed" } },
  component: EquipmentMasterSelect,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <EquipmentProvider>
            <Story />
          </EquipmentProvider>
        </div>
      </div>
    ),
    withRequestContext,
  ],
  title: "Equipment Preference/Form/Inventory Items/Pill/EquipmentMasterSelect",
};

const Template = (args) => <EquipmentMasterSelect {...args} />;

export const Default = Template.bind({});
Default.args = {
  placeholder: "Please select foo a master",
  onChange: () => {},
};

export const IsLoading = Template.bind({});
IsLoading.args = {
  ...Default.args,
  isLoading: true,
};

const getRandomValue = () => {
  const {
    id,
    attributes,
    attributes: { name },
  } = equipmentMasterSamples[
    Math.floor(Math.random() * equipmentMasterSamples.length)
  ];

  return {
    data: attributes,
    label: name,
    value: id,
  };
};

export const Value = Template.bind({});
Value.args = {
  ...Default.args,
  value: getRandomValue(),
};
