export const STYLES = {
  clearIndicator: (provided, state) => ({
    ...provided,
    height: "17px",
    marginBottom: "3px",
    padding: "0px",
    width: "17px",
  }),
  container: (provided, state) => ({
    ...provided,
    marginLeft: "10px !important",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    minHeight: "32px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
    paddingRight: "5px",
  }),
  indicatorSeparator: () => {},
  menu: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    margin: "0px",
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: "1px solid #d6d6d6",

    "&:first-of-type": {
      borderTop: "none",
    },
  }),
};
