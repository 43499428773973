import React, { useState } from "react";
import { Control, EnabledIcon, Link, MasterName, Pill } from "./styled";

export default function Item({ isDisabled, onChange, value }) {
  const [enabled, setEnabled] = useState(!value.value);

  const toggleEnabled = () => {
    const nextEnabled = !enabled;
    setEnabled(nextEnabled);

    // props is named disabled, !enabled is expected
    // NOTE: enabled could be passed, but being explict
    // here to denote that enabled is stale, but we want
    // the disabled value, not the enabled value (English)
    onChange(!nextEnabled);
  };

  const { meta } = value;

  return (
    <Pill {...(!enabled && { className: "excluded" })}>
      <div className="row-fluid source-master">
        <MasterName>
          <Link target="_blank" href={meta.url}>
            {meta.name}
          </Link>
        </MasterName>
      </div>
      <Control
        onClick={isDisabled ? () => {} : toggleEnabled}
        isDisabled={isDisabled}
      >
        <EnabledIcon enabled={enabled} />
        {enabled ? "Enabled" : "Disabled"}
      </Control>
    </Pill>
  );
}
