import React from "react";
import PropTypes from "prop-types";

import { StyledButtonContainer } from "./styled";
import FormButton from "./FormButton";

export default function Buttons({
  canCreate,
  components = {},
  handleDestroy,
  handleSubmit,
  flags,
  formId,
  requireCloneConfirm,
}) {
  const { isActive, isArchived, isDisabled, isDeleting, isEditable, isSaving } =
    flags;
  const disabled = isDeleting || isDisabled || isSaving;

  const EditingTools = [];
  if (components.primeProps) {
    EditingTools.push(
      <BtnLinkToPrime {...components.primeProps} key="clone-link-to-prime" />
    );
  } else if (isArchived && canCreate) {
    EditingTools.push(
      <BtnClone id={formId} key="clone-equipment-preference-button" />
    );
  } else if (formId && isEditable) {
    // not new
    EditingTools.push(
      <BtnDelete
        disabled={disabled}
        handleClick={handleDestroy}
        id={formId}
        isActive={isActive}
        key="delete-equipment-preference-button"
        loading={isDeleting}
      />,
      <BtnClone
        id={formId}
        key="clone-equipment-preference-button"
        requiresConfirm={requireCloneConfirm}
      />
    );
  }

  const SavingButtons = [
    <BtnCancel key="cancel-equipment-preference-button">Cancel</BtnCancel>,
  ];

  if (!isArchived && isEditable) {
    SavingButtons.unshift(
      <BtnSave
        disabled={disabled || !handleSubmit}
        handleClick={handleSubmit}
        id={formId}
        isActive={isActive}
        key="save-equipment-preference-button"
        loading={isSaving}
      />
    );
  }

  return (
    <StyledButtonContainer>
      <div className="row-fluid">
        <div className="workflow-nav workflow-nav-bottom clearfix clear">
          <div className="left">{EditingTools}</div>
          <div className="right">{SavingButtons}</div>
        </div>
      </div>
    </StyledButtonContainer>
  );
}

const BtnCancel = ({ children }) => (
  <a
    className="btn"
    onClick={() => {
      window.history.go(-1);
      return false;
    }}
  >
    {children}
  </a>
);

const BtnClone = ({ id, requiresConfirm }) => (
  <FormButton
    className="btn"
    components={{
      Body: `
          Are you sure you want to clone this equipment preference?\n\n
          Any unsaved changes will be lost.
          `,
      ConfirmLabel: "Clone",
      Header: "Warning: Unsaved Changes",
    }}
    key="clone-btn"
    href={`/equipment_preferences/new?clone_id=${id}`}
    requiresConfirm={requiresConfirm}
  >
    Clone
  </FormButton>
);

const BtnDelete = ({ disabled, handleClick, id, isActive, loading }) => (
  <FormButton
    className="btn btn-remove btn-danger btn-outline"
    disabled={disabled}
    components={{
      Header: "Delete Equipment Preference",
      Icon: "trash",
      Body: `
            Are you sure you want to delete this equipment preference?\n
            This action cannot be undone.
          `,
      ConfirmLabel: "Delete",
    }}
    key="delete-btn"
    formId={id}
    isActive={isActive}
    onClick={handleClick}
    requiresConfirm
  >
    {loading ? "Deleting..." : "Delete"}
  </FormButton>
);

const BtnLinkToPrime = ({ id, name }) => (
  <a href={`/equipment_preferences/${id}/edit`}>Cloned from {name}</a>
);

const BtnSave = ({ disabled, handleClick, id, isActive, loading }) => (
  <FormButton
    className="btn btn-primary"
    disabled={disabled}
    formId={id}
    isActive={isActive}
    onClick={handleClick}
  >
    {loading ? "Saving..." : "Save"}
  </FormButton>
);

Buttons.propTypes = {
  components: PropTypes.object,
  handleDestroy: PropTypes.func,
  handleSubmit: PropTypes.func,
  flags: PropTypes.object,
  formId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
