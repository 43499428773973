import React from "react";
import { equipmentMasterSamples } from "../../../specSamples/equipmentMaster.samples";

import EquipmentSelect from "./EquipmentSelect";

export default {
  argTypes: { onChange: { action: "Input value changed" } },
  component: EquipmentSelect,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <Story />
        </div>
      </div>
    ),
  ],
  title: "Form/selects/EquipmentSelect",
};

const Template = (args) => <EquipmentSelect {...args} />;

export const Default = Template.bind({});
Default.args = {
  placeholder: "Please select foo a master",
  onChange: () => {},
};

export const IsDisabled = Template.bind({});
IsDisabled.args = {
  ...Default.args,
  isDisabled: true,
};

const options = equipmentMasterSamples.map(
  ({ id, attributes, attributes: { name } }) => ({
    data: attributes,
    label: name,
    value: id,
  })
);

export const Options = Template.bind({});
Options.args = {
  ...Default.args,
  options: options,
};

export const Value = Template.bind({});
Value.args = {
  ...Options.args,
  value: options[Math.floor(Math.random() * options.length)],
};

export const ValueIsDisabled = Template.bind({});
ValueIsDisabled.args = {
  ...Value.args,
  ...IsDisabled.args,
};
