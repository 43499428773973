import React, { forwardRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const StyledButtonGroup = styled.div`
  &:first-child {
    margin-left: 0px !important;
  }

  .dropdown-menu {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: black;
    font-weight: normal;
    min-width: 160px;

    li {
      display: block;
    }
  }

  .position-left {
    left: auto;
    right: 0;
  }
`;

const ButtonGroup = forwardRef(function ButtonGroup(
  { buttonClass, id, isOpen, children },
  ref
) {
  const klassName = `btn-group${isOpen ? " open" : ""} ${
    buttonClass ? buttonClass : ""
  }`;
  return (
    <StyledButtonGroup className={klassName} id={id} ref={ref}>
      {children}
    </StyledButtonGroup>
  );
});

// TODO: Proptypes
ButtonGroup.propTypes = {};

export default ButtonGroup;
