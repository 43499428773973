import React from "react";
import PropTypes from "prop-types";

const InventoryAccessories = ({ inventory }) => {
  const { accessories, isRentableInventory } = inventory;

  if (isRentableInventory) {
    return <i>Must specify with rental company</i>;
  } else {
    return accessories.length > 0
      ? accessories.map(am => `${am.name} (${am.quantity})`).join(", ")
      : "(0)";
  }
};

InventoryAccessories.propTypes = {
  inventory: PropTypes.object.isRequired
};

export default InventoryAccessories;
