import React from "react";
import PropTypes from "prop-types";

import { StyledFieldName, StyledName } from "./styled";
import Input from "../../../forms/inputs/Input";

export default function Name({ handleChange, isDisabled, isEditable, value }) {
  return (
    <StyledName>
      {isEditable ? (
        <div>
          <h2 className="page-title required">
            <StyledFieldName className="sprite-icon sprite-bolt-m-green" />
            Preference Name:
          </h2>
          <Input
            disabled={isDisabled}
            name="name"
            onChange={handleChange}
            placeholder="Please enter a name"
            value={value}
          />
        </div>
      ) : (
        <h2>{value?.length ? value : "Invalid Name"}</h2>
        //<h2>{value.length ? value : "Invalid Name"}</h2>
      )}
    </StyledName>
  );
}

Name.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  value: PropTypes.string,
};
