import React from "react";

import Select from "./Select";
import { FACILITIES_PATH } from "../../utils/capi";

import { facilitySamples } from "../../specSamples/facility.samples";
import mockRequestContext from "../../specSamples/mockRequestContext";

const { withRequestContext } = mockRequestContext({
  path: FACILITIES_PATH,
  sample: facilitySamples,
});

export default {
  argTypes: { handleChange: { action: "Input value changed" } },
  component: Select,
  decorators: [withRequestContext],
  title: "Form/selects/Select",
};

const Template = (args) => {
  return <Select {...args} />;
};

export const Default = Template.bind({});
Default.args = {
  // value,
  // components = defaultComponents,
  isDisabled: false,
  requestUrl: FACILITIES_PATH,
  // requestParams: ,
  title: "Default",
  // value,
};

export const WithPlacholder = Template.bind({});
WithPlacholder.args = {
  ...Default.args,
  placeholder: "Please select a facility",
};
