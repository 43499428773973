import React from "react";
import PropTypes from "prop-types";

import { displayDateTime } from "../../../utils/datetime";

export const formatActivityLog = (timeZone, versions) => {
  if (!versions.length) return [];

  const attrs = versions.map((v) => {
    const { changeset, created_at: createdAt, event, whodunnit } = v.attributes;
    const fDateTime = displayDateTime(createdAt, {
      datetimeArgZone: timeZone,
      dateFormat: "DATE",
      zone: timeZone,
    });

    return {
      createdAt: fDateTime,
      event: event,
      id: v.id,
      whodunnit: whodunnit,
      changeset: changeset,
      timeZone: timeZone,
    };
  });

  return attrs;
};

const ChangesetItem = ({ id, value }) => {
  const [previousChange, currentChange] = value;
  let current, item, previous;

  current =
    typeof currentChange == "boolean"
      ? currentChange.toString()
      : currentChange;
  previous =
    typeof previousChange == "boolean"
      ? previousChange.toString()
      : previousChange;

  if (!previous) {
    item = <CreatedItem id={id} value={current} />;
  } else if (!current) {
    item = <DestroyedItem id={id} value={previous} />;
  } else {
    item = <UpdatedItem id={id} value={[previous, current]} />;
  }

  return <span>{item}</span>;
};

const ChangesetList = ({ changeset, entryId, timeZone }) => {
  return (
    <ul>
      {changeset &&
        changeset.map((change, index) => {
          return (
            <li key={`${entryId}-${index}`}>
              {change[0] ? (
                <ChangesetItem id={change[0]} value={[change[1], change[2]]} />
              ) : (
                `${change[1]} ${displayDateTime(change[2], {
                  datetimeArgZone: timeZone,
                  dateFormat: "DATE",
                  zone: timeZone,
                })}`
              )}
            </li>
          );
        })}
    </ul>
  );
};

const CreatedItem = ({ id, value }) => {
  return (
    <div className="versions-log-attr">
      <span className="attrs-key">{id} </span>
      <span className="attrs-pad">was set to </span>
      <span className="attrs-val">{value}</span>
    </div>
  );
};

const DestroyedItem = ({ id, value }) => {
  return (
    <div className="versions-log-attr">
      <span className="attrs-key">{id} </span>
      <span className="attrs-pad">containing </span>
      <span className="attrs-val">{value} </span>
      <span className="attrs-pad">was removed</span>
    </div>
  );
};

export const Log = ({ log }) => {
  return (
    <div className="content activity-log-wrapper">
      {log.map((entry) => {
        return (
          <div className="note-item small" key={entry.id}>
            <span className="user">{entry.whodunnit}</span>
            <span className="date time">
              {entry.createdAt} ({entry.event})
            </span>
            <div className="note-message">
              {entry.changeset && entry.event != "destroy" && (
                <ChangesetList changeset={entry.changeset} entryId={entry.id} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const UpdatedItem = ({ id, value }) => {
  const [previousValue, currentValue] = value;

  if (Array.isArray(currentValue) && Array.isArray(previousValue)) {
    currentValue.sort();
    previousValue.sort();
  }

  return (
    <div className="versions-log-attr">
      <span className="attrs-key">{id} </span>
      <span className="attrs-pad">changed from </span>
      <span className="attrs-prev-val">{previousValue} </span>
      <span className="attrs-pad">to </span>
      <span className="attrs-val">{currentValue}</span>
    </div>
  );
};

ChangesetItem.propTypes = {
  id: PropTypes.any,
  value: PropTypes.array,
};

ChangesetList.propTypes = {
  changeset: PropTypes.array,
  entryId: PropTypes.string,
  timeZone: PropTypes.string,
};

CreatedItem.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
};

DestroyedItem.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
};

Log.propTypes = {
  log: PropTypes.array,
};

UpdatedItem.propTypes = {
  id: PropTypes.string,
  value: PropTypes.array,
};
