import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import "react-select/dist/react-select.css";

class SelectDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.focusSelect) {
      this.selectRef?.current?.focus();
      this.props.afterFocusSelect();
    }
  }

  handleChange = (selectedOption) => {
    let selectedProps = { ...selectedOption };

    if (this.isNewMovesContact(selectedOption)) {
      selectedProps = {
        key: Math.random(),
        name: selectedOption.label,
        editing: true,
        focusable: true,
        isNewOption: true,
      };
    } else {
      this.hideOption(selectedOption);
    }

    this.props.handleSelectedContact(selectedProps);
  };

  hideOption = (selectedOption) => {
    const newOptions = this.props.movesContactsList.map((option) => {
      if (option.key === selectedOption.key) {
        return {
          ...option,
          hideable: true,
          editing: false,
          removable: false,
        };
      }
      return option;
    });

    this.props.handleUpdatedContacts(newOptions);
  };

  isNewMovesContact = (option) =>
    option.className === "Select-create-option-placeholder";

  /* custom react-select functions */
  optionRenderer = (option) => {
    const { name, email, description, phone } = option;

    if (option.label === option.value) {
      return <div>{option.label}</div>;
    } else {
      return (
        <div className="moves-contact-option">
          <div className="primary-mc-info">
            {name}
            {description ? ", " + description : null}
          </div>
          <div className="secondary-mc-info empty">
            <small>{email ? email + (phone ? ", " + phone : "") : phone}</small>
          </div>
        </div>
      );
    }
  };

  valueRenderer = (option) => (
    <div>{this.isNewMovesContact ? option.label : option.name}</div>
  );

  render() {
    const optionsWithoutHideable = this.props.movesContactsList.filter(
      (option) => !option.hideable
    );

    return (
      <div className="MovesContactsSelect">
        <Select.Creatable
          name={"moves-contacts-select"}
          arrowRenderer={null}
          onChange={this.handleChange}
          options={optionsWithoutHideable}
          optionRenderer={this.optionRenderer}
          placeholder={<em>Add a contact...</em>}
          promptTextCreator={(label) => `Create "${label}"...`}
          ref={this.selectRef}
          value={undefined}
          valueRenderer={this.valueRenderer}
        />
      </div>
    );
  }
}

SelectDropdown.propTypes = {
  movesContactsList: PropTypes.array,
  handleSelectedContact: PropTypes.func,
  handleUpdatedContacts: PropTypes.func,
};

export default SelectDropdown;
