import React from "react";

import InventoryItemSelect from "./InventoryItemSelect";
import { EquipmentProvider } from "../../../useEquipmentContext";

import mockRequestContext from "../../../../../specSamples/mockRequestContext";
import { inventoryItemSamples } from "../../../../../specSamples/inventoryItem.samples";
import { INVENTORY_ITEMS_PATH } from "../../../../../utils/capi";

const { withRequestContext } = mockRequestContext({
  path: INVENTORY_ITEMS_PATH,
  sample: inventoryItemSamples,
});

export default {
  argTypes: { onChange: { action: "Input value changed" } },
  component: InventoryItemSelect,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <EquipmentProvider>
            <Story />
          </EquipmentProvider>
        </div>
      </div>
    ),
    withRequestContext,
  ],
  title: "Equipment Preference/Form/Inventory Items/Pill/InventoryItemSelect",
};

const Template = (args) => <InventoryItemSelect {...args} />;

export const Default = Template.bind({});
Default.args = {
  // filterableIds = [], TODO
  handleChange: () => {},
};

export const IsError = Template.bind({});
IsError.args = {
  ...Default.args,
  isError: true,
};

export const IsLoading = Template.bind({});
IsLoading.args = {
  ...Default.args,
  isLoading: true,
};

const {
  id,
  attributes,
  attributes: { name },
} = inventoryItemSamples[
  Math.floor(Math.random() * inventoryItemSamples.length)
];

const randomValue = {
  data: attributes,
  label: name,
  value: id,
};

export const WithMaster = Template.bind({});
WithMaster.args = {
  ...Default.args,
  equipmentMasterId: randomValue.data.equipmentMasterId,
};

export const Value = Template.bind({});
Value.args = {
  ...Default.args,
  value: randomValue,
};

export const ValueWithMaster = Template.bind({});
ValueWithMaster.args = {
  ...Value.args,
  ...WithMaster.args,
};
