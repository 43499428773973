import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

export const DEFAULT_DEBOUNCE_TIMEOUT_MS = 1500;

// This hook is used for inputs where we track
// input value changes, e.g. textarea, input, etc.
//
// This hook will only trigger onChange after
// a timeout, in order to prevent large state
// change on the onChange caller.
export const useDelayedInput = (
  inputProps,
  onChange,
  timeout = DEFAULT_DEBOUNCE_TIMEOUT_MS
) => {
  const [inputValue, setInputValue] = useState(inputProps || "");

  // Sometimes, if inputProps is set after the component mounts,
  // we need to update the inputvalue if there's a disconnect
  // between the two.
  useEffect(() => {
    if (inputValue === "" && inputProps && inputProps.length)
      setInputValue(inputProps);
  }, [inputProps]);

  const debounceChange = debounceCallback(onChange, timeout);

  const handleInputChange = (e) => {
    const newInputValue = e.target.value;

    setInputValue(newInputValue);
    debounceChange(newInputValue);
  };

  return [inputValue, handleInputChange];
};

export const useDelayedList = (
  onChange,
  selected,
  timeout = DEFAULT_DEBOUNCE_TIMEOUT_MS
) => {
  const [selectedState, setSelectedState] = useState(selected || []);

  useEffect(() => {
    if (!_.isEqual(selected, selectedState)) setSelectedState(selected);
  }, [_.isEqual(selected, selectedState)]);

  const debounceChange = debounceCallback(onChange, timeout);

  return [selectedState, setSelectedState, debounceChange];
};

export const debounceCallback = (
  onChange,
  timeout = DEFAULT_DEBOUNCE_TIMEOUT_MS
) => useCallback(_.debounce(onChange, timeout), [onChange]);
