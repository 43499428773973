// https://danielmiessler.com/images/url-structure-and-scheme-2022.png
export const CAPI_BASE_URI = process.env.CAPI_BASE_URI;

export const CAMPUS_PATH = "v1/campuses";
export const DATA_TABLES_PATH = "/v1/data_tables";
export const EQUIPMENT_PREFERENCES_PATH = "/v1/equipment_preferences";
export const EQUIPMENT_MASTERS_PATH = "/v1/equipment_masters";
export const EQUIPMENT_MASTER_FALLBACKS_PATH = "/v1/equipment_master_fallbacks";
export const FACILITIES_PATH = "/v1/facilities";
export const HEALTH_SYSTEMS_PATH = "/v1/health_systems";
export const INVENTORY_ITEMS_PATH = "/v1/inventory_items";
export const LOCATIONS_PATH = "/v1/locations";
export const PATIENT_CASES_PATH = "/v1/patient_cases";
export const PHYSICIANS_PATH = "/v1/physicians";
export const PROCEDURES_PATH = "/v1/procedures";
export const ROOMS_PATH = "/v1/rooms";
export const SERVICE_LINES_PATH = "/v1/service_lines";
export const USERS_PATH = "/v1/users";

// Other keys
export const MUI_X_LICENSE = process.env.MUI_X_LICENSE;
