import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Box, Collapse, IconButton, Tooltip } from "@mui/material";
import { MIN_CHARACTER_LIMIT } from "./ServerTable";

export const DEFAULT_FILTER_MODEL = {
  items: [],
  logicOperator: "and",
  quickFilterValues: [],
};

// Column Field Helpers
export const nameOrKeyValueFmt = (params, key) => params.value?.[key || "name"];

export const nameOrKeyValueGtr = (params, key) => params.value?.[key || "name"];

// Field expects an array
export const namesOrKeyValueFmt = (params, key) => {
  const { value } = params;

  if (!value) return value;

  return params.value?.map((v) => v[key || "name"]);
};

export function ExpandableComponent({
  children,
  handleFocusOnElement = null,
  IconComponent,
  IconComponentProps,
  ToolTipProps = null,
  value,
  ...rest
}) {
  const [expanded, setExpanded] = useState(false);
  const toggleCollapsed = () => setExpanded((prev) => !prev);

  const handleIconClick = () => {
    toggleCollapsed();
  };

  useEffect(() => {
    if (handleFocusOnElement && expanded) handleFocusOnElement();
  }, [expanded]);

  useEffect(() => {
    if (expanded && value?.length === 0) setExpanded(false);
  }, [Boolean(value?.length)]);

  const Btn = (
    <IconButton
      color="inherit"
      onClick={handleIconClick}
      {...rest}
      sx={{
        ...rest.sx,
        maxHeight: "30px",
        ml: "9px",
      }}
    >
      <IconComponent {...IconComponentProps} />
    </IconButton>
  );

  const Expander = ToolTipProps ? (
    <Tooltip {...ToolTipProps}>{Btn}</Tooltip>
  ) : (
    Btn
  );

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: 35,
      }}
    >
      {!expanded && Expander}
      <Collapse
        in={expanded}
        onBlur={() => {
          if (value?.length > 0) return;
          toggleCollapsed();
        }}
        orientation="horizontal"
        sx={{ display: expanded ? "block" : "none" }}
      >
        {children}
      </Collapse>
    </Box>
  );
}

export const getOptions = async (request, path, params) =>
  request.get(path, { params }).then(({ data }) => data.data);

export const operatorsWithUndefinedValues = [
  "isEmpty",
  "isNotEmpty",
  "isReserved",
  "isNotReserved",
];

const requestReducer = (memo, item) => {
  if (item.value || operatorsWithUndefinedValues.includes(item.operator)) {
    if (
      item.field === "any" &&
      Boolean(item.value?.length < MIN_CHARACTER_LIMIT)
    )
      return memo;

    memo.push({
      field: item.field,
      operator: item.operator,
      ...(item.value && {
        value: Array.isArray(item.value)
          ? item.value.map((item) => item.value ?? item)
          : item.value,
      }),
    });
  }
  return memo;
};

const optionsReducer = (memo, item) => {
  const field = item.field;
  const operator = item.operator;
  const value = item.value;

  if (operatorsWithUndefinedValues.includes(operator)) {
    memo.push({ field, operator, value: undefined });
  } else if (value) {
    if (field === "any" && Boolean(value?.length < MIN_CHARACTER_LIMIT))
      return memo;

    memo.push({ field, operator, value });
  }
  return memo;
};

const mapFilterValueId = (item) => ({
  ...item,
  value: item.type?.length ? item.id : item.value.id,
});

export const sanitizeFilterModel = (
  filterModel,
  replaceOptionsAsValues = true
) => {
  const sanitized = [
    ...filterModel.items.reduce((memo, item) => {
      const { value } = item;

      // requires null to be checked
      // because null === object, while undefined !== object
      if (value !== null && typeof value === "object") {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            // inventoryItems is special because it contains
            // 3 different data types: inventoryItems, equipmentMastesr, and token
            if (item.field === "inventoryItems") {
              item.value = value.map((itemVal) =>
                typeof itemVal === "object"
                  ? { ...itemVal, value: `${itemVal.type}|${itemVal.id}` }
                  : itemVal
              );
            } else {
              item.value = value.map((item) =>
                typeof item === "object" ? mapFilterValueId(item) : item
              );
            }
            memo.push(item);
          }
          return memo;
        } else if (value.id === undefined) {
          memo.push(item);
          return memo;
        }
        memo.push(mapFilterValueId(item));
      } else {
        memo.push(item);
      }
      return memo;
    }, []),
    ...(filterModel.quickFilterValues ?? []),
  ];
  return replaceOptionsAsValues
    ? sanitized.reduce(requestReducer, [])
    : sanitized.reduce(optionsReducer, []);
};

export const parseTableData = (res) => {
  const relMaps = res.included.reduce((memo, assoc) => {
    if (memo[assoc.type] === undefined) memo[assoc.type] = {};
    memo[assoc.type][assoc.id] = { id: assoc.id, ...assoc.attributes };
    return memo;
  }, {});

  return res.data.map(({ attributes, id, links, relationships }) => ({
    id,
    ...attributes,
    ...Object.keys(relationships).reduce((memo2, rel) => {
      const { data, links } = relationships[rel];

      if (data?.id) {
        memo2[rel] = {
          id: data.id,
          links,
          ...relMaps?.[data.type]?.[data.id],
        };
      } else if (data?.length) {
        memo2[rel] = data.map(({ id, type }) => ({
          id,
          ...relMaps?.[type]?.[id],
        }));
      }
      return memo2;
    }, {}),
    links: links.self,
  }));
};
