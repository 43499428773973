import styled from "styled-components";

export const StyledCaseTable = styled.table`
  tbody {
    tr {
      &:hover {
        cursor: pointer;
        td {
          i {
            visibility: visible;
          }
        }
      }
      td {
        i {
          visibility: hidden;
        }
      }
    }
  }
`;

// TODO: grey-7 and grey-9 colors
export const StyledDateTimeRangeTable = styled.table`
  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
    td {
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
      vertical-align: middle;
    }
    .date,
    .arrow {
      color: #333;
    }
    .time {
      color: #666;
    }
  }
`;
