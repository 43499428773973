import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import ButtonGroup from "./utils";

const StyledButtonDropdown = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  .caret {
    margin-left: 2px;
  }

  &.dropdown {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 11px 16px;
  }
`;

const StyledListItem = styled.li`
  border: none !important;
  cursor: pointer;
  padding: 7px 10px;

  &:hover {
    color: #6f6f6f;
  }

  input {
    cursor: pointer;
  }

  label {
    display: inline;
    margin-left: 10px;
    vertical-align: middle;
  }
`;

function ListItem({ id, label, isChecked, onChange }) {
  const handleChange = () => onChange({ name: id, value: !isChecked });

  return (
    <StyledListItem className="item event-view-control">
      <input
        className="checkbox"
        checked={isChecked}
        id={id}
        onChange={handleChange}
        readOnly
        type="checkbox"
      />
      <label htmlFor={id}>{label}</label>
    </StyledListItem>
  );
}

function Dropdown(props) {
  const { controls, onChange } = props;
  return (
    <ul className="dropdown-menu">
      {Object.keys(controls).map((id, index) => {
        if (id === "version") {
          return null;
        }
        return (
          <ListItem id={id} {...controls[id]} onChange={onChange} key={index} />
        );
      })}
    </ul>
  );
}

export default function EventInfo(props) {
  const eventInfoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { controls, handleControlChange } = props;

  const toggleOpen = () => setOpen(!open);

  const handleClick = (e) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleDropdownClose = (e) => {
    if (!eventInfoRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleDropdownClose, false);
    } else {
      document.removeEventListener("mousedown", handleDropdownClose, false);
    }
    return () =>
      document.removeEventListener("mousedown", handleDropdownClose, false);
  });

  return (
    <ButtonGroup isOpen={open} ref={eventInfoRef}>
      <StyledButtonDropdown
        className={`btn btn-small dropdown ${open ? "active" : ""}`}
        onClick={handleClick}
      >
        Event Info
        <span className="caret" />
      </StyledButtonDropdown>
      {open && <Dropdown controls={controls} onChange={handleControlChange} />}
    </ButtonGroup>
  );
}
