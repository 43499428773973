import React, { useContext, useMemo } from "react";
import ClearIcon from "@mui/icons-material/Clear";

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector, // TODO: New Feature
  GridToolbarFilterButton,
  gridFilterModelSelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import FilterChips from "../FilterChips";
import _ from "lodash";
import { RequestContext } from "../../../utils/capiClient";
import { baseAutocompleteSlotProps } from "../Table";
import { GridToolbarQuickFilter } from "./GridToolbarQuickFilter";
import { DEFAULT_FILTER_MODEL, sanitizeFilterModel } from "../utils";

export default function ServerToolbar(props) {
  const apiRef = useGridApiContext();
  const filterModel = gridFilterModelSelector(apiRef);

  const {
    healthSystemId,
    handleHealthSystemChange,
    handleFilterItemDelete,
    minQuickFilterCharacterLength,
    user,
  } = props;

  const sanitizedItems = sanitizeFilterModel(filterModel, false);
  const isFilterable = Boolean(sanitizedItems.length);

  const fieldToHeaderNameMap = apiRef.current
    .getAllColumns()
    .reduce((memo, col) => {
      if (col.type !== "actions")
        memo[col.field] = {
          name: col.headerName || _.capitalize(col.field),
          type: col.type,
        };
      return memo;
    }, {});

  const handleFilterDelete = (itemIndex) => {
    filterModel.items.splice(itemIndex, 1);
    const newFilterModel = { ...filterModel };
    apiRef.current.setFilterModel(newFilterModel);
    handleFilterItemDelete(newFilterModel);
  };

  const handleClearFilter = () => {
    apiRef.current.setFilterModel(DEFAULT_FILTER_MODEL);
    handleFilterItemDelete(DEFAULT_FILTER_MODEL);
  };

  const labelRowCount = `${
    apiRef.current.getRowsCount() ?? "Loading "
  } results`;

  return (
    <GridToolbarContainer sx={{ mt: 1 }}>
      <Grid container sx={{ width: "100%" }}>
        <Grid container sx={{ flexGrow: 1, mb: 1 }}>
          <Grid
            display="inline-flex"
            sx={{ alignItems: "center", gap: "10px" }}
          >
            <HealthSystemAutocomplete
              onChange={handleHealthSystemChange}
              size="small"
              value={healthSystemId}
            />
            {user.can_create && (
              <NewCaseButton size="small" value={healthSystemId} />
            )}
            <GridToolbarQuickFilter
              size="small"
              minLength={minQuickFilterCharacterLength}
            />
          </Grid>
          <Grid xsOffset="auto">
            {isFilterable && <ClearFilterButton onClick={handleClearFilter} />}
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 1, ml: 1 }} xs={12}>
          <Box sx={{ mr: 1 }}>
            <Typography sx={{ fontWeight: 600 }} variant="overline">
              {labelRowCount}
            </Typography>
          </Box>
          <FilterChips
            data={{
              apiRef,
              logicOperator: filterModel.logicOperator,
              fieldMap: fieldToHeaderNameMap,
            }}
            onClick={() => apiRef.current.showFilterPanel()}
            onDelete={handleFilterDelete}
            title="Filters"
            value={sanitizedItems.filter((item) => item.field !== "any")}
            xs="xs"
          />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

const NewCaseButton = ({ size, value }) =>
  value ? (
    <Button
      href={`/patient_cases/new?health_system_id=${value}`}
      target="_blank"
      rel="noopener"
      size={size}
      sx={{
        alignSelf: "center",
        "&:hover": {
          textDecoration: "none",
        },
        "&:focus": {
          textDecoration: "none",
          outline: 0,
        },
      }}
      variant="outlined"
    >
      + New Case
    </Button>
  ) : null;

const HealthSystemAutocomplete = ({ onChange, size, value }) => {
  const { extras } = useContext(RequestContext);

  const options = [
    { id: null, attributes: { name: "All" } },
    ...(extras.options?.healthSystems ?? []),
  ];

  const optionValue = useMemo(
    () =>
      value ? options.find((opt) => opt.id == value) ?? options[0] : options[0],
    [options.length, value]
  );

  return (
    extras.options?.healthSystems?.length > 1 && (
      <Autocomplete
        disableClearable
        getOptionLabel={({ attributes }) => attributes.name}
        id="health-system-autocomplete"
        onChange={(_, change) => onChange(change.id)}
        options={options}
        sx={{ width: 225 }}
        renderInput={(params) => {
          params.InputProps.sx = { fontSize: "13px" };
          return <TextField {...params} label="Health System" />;
        }}
        size={size}
        value={optionValue}
        {...baseAutocompleteSlotProps}
      />
    )
  );
};

const ClearFilterButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} size="small">
      <ClearIcon fontSize="small" color="red" />
      Clear Filter
    </Button>
  );
};
