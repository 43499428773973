import { useEffect, useRef } from "react";
import _ from "lodash";

// Lodash performs a deep comparison for equality
export const isEqual = (a, b) => {
  const [compA, compB] =
    Array.isArray(a) && Array.isArray(b) ? [_.sortBy(a), _.sortBy(b)] : [a, b];

  return _.isEqual(compA, compB);
};

export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Returns previous value (before update in useEffect above)
  return ref.current;
}
