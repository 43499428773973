import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Select from "react-select-3";
import styled from "styled-components";

import UglyComponent from "./ugly_component";
import { DEFAULT_SELECT_STYLES } from "./select-styles";
import { useQueryParam, HS_K, F_K, FT_K } from "../utils/use_query_params";

const tieFacilityOptionsByHS = (healthSystem, options, selected) => {
  let filtOptions;
  let filtSelected;
  if (healthSystem) {
    filtOptions = options.filter(
      (opt) => opt.data.health_system_id == healthSystem.value
    );
    if (!filtOptions.length) filtOptions = null;
  } else {
    filtOptions = options;
  }

  if (filtOptions) {
    filtSelected =
      healthSystem && selected
        ? selected.filter(
            (sel) => sel.data.health_system_id == healthSystem.value
          )
        : selected;
  }
  return { options: { facilities: filtOptions }, value: filtSelected };
};

export default function Location({ onChange, selected, options }) {
  const selHealthSystem = selected.healthSystem;
  const tiedFacilityOptions = tieFacilityOptionsByHS(
    selHealthSystem,
    options.facilities,
    selected.facilities
  );
  tiedFacilityOptions.options.types = options.facility_types;

  return (
    <UglyComponent title="Health System, Facility">
      <label>Health System:</label>
      <HealthSystemSelect
        onChange={onChange}
        options={options.healthSystems}
        value={selHealthSystem}
      />
      <label>Facility</label>
      <FacilitySelect
        {...tiedFacilityOptions}
        onChange={onChange}
        styles={DEFAULT_SELECT_STYLES}
      />
    </UglyComponent>
  );
}

const StyledFacilityTypes = styled.div`
  display: flex;
  gap: 15px;
  font-size: 12px;
  margin-top: 8px;

  input {
    margin-right: 5px;
    position: relative;
    vertical-align: bottom;
  }
`;

// Reset query strings if selected is empty
// This is needed due to potential tied nature of dependencies
const hasQueryParamsDisconnect = (queryParam, propVal) =>
  queryParam && !propVal;

function HealthSystemSelect({ onChange, options, value }) {
  const [queryStr, setQueryStr] = useQueryParam(HS_K, "");

  useEffect(() => {
    if (hasQueryParamsDisconnect(queryStr, value)) setQueryStr(null);
  });

  return (
    <Select
      className="filter-input"
      id="health-system-id"
      isClearable
      noOptionsMessage={() => "No options for health system"}
      onChange={(change) => {
        setQueryStr(change);
        onChange("healthSystem", change);
      }}
      options={options}
      placeholder=""
      styles={DEFAULT_SELECT_STYLES}
      value={value}
    />
  );
}

function FacilitySelect({ onChange, options, value }) {
  const [queryStr, setQueryStr] = useQueryParam(F_K, "");

  useEffect(() => {
    if (hasQueryParamsDisconnect(queryStr, value)) setQueryStr(null);
  });

  return (
    <div className="filter-input">
      <Select
        closeMenuOnSelect={false}
        id="facility"
        isClearable
        isMulti
        noOptionsMessage={() => "No options for facilities"}
        onChange={(change) => {
          setQueryStr(change);
          onChange("facilities", change);
        }}
        options={options.facilities || []}
        placeholder=""
        styles={DEFAULT_SELECT_STYLES}
        value={value}
      />
      {value && <FacilityTypes onChange={onChange} options={options.types} />}
    </div>
  );
}

const TypeInput = ({ checked, id, label, onChange }) => (
  <label className="filter-checkbox-label" htmlFor={id}>
    <input
      checked={checked}
      className="filter-checkbox"
      id={id}
      onChange={onChange}
      type="checkbox"
    />
    {label}
  </label>
);

function FacilityTypes({ onChange, options }) {
  const [queryStr, setQueryStr] = useQueryParam(FT_K, "");

  useEffect(() => {
    const hasData = !!options.find((opt) => !opt.value);
    if (hasQueryParamsDisconnect(queryStr, hasData)) setQueryStr(null);
  });

  const handleTypeChange = (change) => {
    const typeParams = [];
    const types = [];
    options.forEach((opt) => {
      const { id } = opt.data;
      if (id === change.target.id) opt.value = change.target.checked;
      if (!opt.value) typeParams.push({ label: id, value: id });
      types.push(opt);
    });
    setQueryStr(typeParams);
    onChange(types);
  };

  return (
    <StyledFacilityTypes>
      {options.map(({ data, label, value }) => (
        <TypeInput
          checked={value}
          key={data.id}
          id={data.id}
          label={label}
          onChange={handleTypeChange}
        />
      ))}
    </StyledFacilityTypes>
  );
}

// TODO: Proptypes
Location.propTypes = {};
