import React, { useState, useEffect } from "react";
import styled from "styled-components";

const setStickyTop = (initial = 0) => {
  const DEFAULT_PADDING_OFFSET = 20; // Calculated by the initial sidebar padding
  return `${DEFAULT_PADDING_OFFSET + initial}px`;
};
function callback(mutationsList) {
  mutationsList.forEach((mutation) => {
    if (mutation.attributeName === "class") {
      const tokenList = mutation.target.classList;
      const sideBar = document.querySelector(".view-filter.sticky-content");
      if (tokenList.contains("headroom--not-top")) {
        if (tokenList.contains("slideUp")) sideBar.style.top = setStickyTop();
        if (tokenList.contains("slideDown"))
          sideBar.style.top = setStickyTop(mutation.target.offsetHeight);
      } else {
        sideBar.style.top = setStickyTop(mutation.target.offsetHeight);
      }
    }
  });
}
const mutationObserver = new MutationObserver(callback);

const StickyDiv = styled.div`
  position: sticky;
  transition: 0.2s ease-out;
`;

export default function StickyContent({
  children,
  sticky = false,
  className,
  ...rest
}) {
  const [isSticky, setIsSticky] = useState(false);
  const ref = React.createRef();

  useEffect(() => {
    mutationObserver.observe(document.querySelector(".navbar.headroom"), {
      attributes: true,
    });
    return function () {
      mutationObserver.disconnect();
    };
  }, []);

  const stickyKlass = isSticky ? " isSticky" : "";

  return (
    <StickyDiv
      className={className + " sticky-content" + stickyKlass}
      ref={ref}
      {...rest}
    >
      {children}
    </StickyDiv>
  );
}
