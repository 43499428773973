import styled from "styled-components";

export const StyledFieldName = styled.i`
  margin-right: 5px;
`;

export const StyledName = styled.div`
  input {
    height: 36px;
  }
`;
