import React, { useContext } from "react";
import { RequestContext } from "../../../utils/capiClient";

export const dateFormat = "MM/DD HH:mm";
export const dateFormatY = "MM/DD/YY HH:mm";
export const dateFormatZ = "MM/DD HH:mm z";
export const dateFormatYZ = "MM/DD/YY HH:mm z";

export default function DateRange({ colDef, value }) {
  const { params } = useContext(RequestContext);

  const [min, max] = value.value;
  const sameZone = (params.user.timeZone ?? moment.tz.guess()) === min.tz();
  const sameYear = moment().isSame(min, "year");

  const dtf = sameZone
    ? sameYear
      ? dateFormat
      : dateFormatY
    : sameYear
    ? dateFormatZ
    : dateFormatYZ;

  return [
    <span className="datetimez" key={`${value.id}-dtz-1`}>
      {min.format(dtf)}
    </span>,
    <span className="arrow" key={`${value.id}-arrow`}>
      {colDef.width > 80 ? "→" : "↓"}
    </span>,
    <span className="datetimez" key={`${value.id}-dtz-2`}>
      {max.format(dtf)}
    </span>,
  ];
}
