import { useState, useEffect } from "react";

// React inherently doesn't invoke use of the query params in the url, unless
// we use something like react-router. This is an attempt to react-ify the use
// of query params, so that our sticky params work as expected + working url
// params to set filters correctly.
//
// Please import this in components that require the use of query strings
//
// Inspired by
// https://dev.to/brettfishy/the-easiest-way-to-use-query-parameters-in-react-1ioe
// In case window doesn't work (e.g. with Gatsby), we need to check for type
// if (typeof window !== "undefined") {

export const EI_K = "eihide"; // event info toggler
export const ET_K = "ets"; // event types
export const EQM_K = "eqmid"; // equipment master
export const F_K = "fid"; // facility
export const FCD_K = "fcd"; // fullcalendar start date
export const FCN_K = "fcn"; // fullcalendar calendar type
export const FT_K = "ftid"; // facility types
export const HS_K = "hsid"; // health system
export const II_K = "iiids"; //inventory items
export const MB_K = "mbs"; // move badges
export const MD_K = "mvd"; // move driver
export const MS_K = "mvs"; // move status

export const FILTER_KEYS = [
  ET_K,
  EQM_K,
  F_K,
  FT_K,
  HS_K,
  II_K,
  MB_K,
  MD_K,
  MS_K,
];

export const getQuery = () => new URLSearchParams(window.location.search);

const getFullPath = (q) => {
  const { protocol, pathname, host } = window.location;
  return `${protocol}//${host}${pathname}?${q}`;
};

const getQueryStringVal = (key) => getQuery().get(key);

const rebuildQuery = (key, change) => {
  let newVals;
  if (Array.isArray(change)) {
    newVals = change.length ? change.map((chg) => chg.value) : null;
  } else {
    newVals = change ? change.value : null;
  }

  const query = getQuery();
  newVals ? query.set(key, newVals) : query.delete(key);

  return [newVals, query];
};

// NOTE: By utilizing use_query_params, we are skipping Cohealo.StickyFilter,
// which is used in legacy filters. However, we still need to maintain
// query string params against StickyState that is used throughout platform.
const updateStickyWindowHistory = (query) => {
  window.history.pushState({}, "", getFullPath(query.toString()));
  Cohealo.StickyState._hard_update_cookie();
};

export const setQueryString = (key, change) => {
  const [updatedVal, query] = rebuildQuery(key, change);
  updateStickyWindowHistory(query);
};

export const useQueryParam = (key, defaultVal = null) => {
  const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal);

  useEffect(() => {
    if (!defaultVal) {
      return;
    }
    const [noop, query] = rebuildQuery(key, defaultVal);
    updateStickyWindowHistory(query);
  }, []);

  const updateUrl = (change) => {
    const [updatedVal, query] = rebuildQuery(key, change);
    setQuery(updatedVal);
    updateStickyWindowHistory(query);
  };

  return [query, updateUrl];
};

// Grab values from the query params on page load, so that we can populate the selected
export const syncQueryParams = (
  defaultSelected,
  {
    event_types,
    equipment_masters,
    facilities,
    facility_types,
    health_systems,
    inventory_items,
    move_badges,
    move_drivers,
    move_states,
  }
) => {
  const query = getQuery();

  const hsId = query.get(HS_K);
  if (hsId?.length) {
    const selectedHS = health_systems.find((hs) => hs.value == hsId);
    defaultSelected.healthSystem = selectedHS || null;
  }

  let fIds = query.get(F_K);
  if (fIds?.length) {
    fIds = fIds.split(",").map((id) => parseInt(id));
    const filteredFac = [];
    facilities.forEach((f) => {
      if (fIds.includes(f.value)) {
        if (hsId) {
          if (f.data.health_system_id == hsId) filteredFac.push(f);
        } else {
          filteredFac.push(f);
        }
      }
    });
    defaultSelected.facilities = filteredFac.length ? filteredFac : null;

    if (filteredFac.length) {
      defaultSelected.facilities = filteredFac;
      let fts = query.get(FT_K);
      if (fts?.length) {
        fts = fts.split(",");
        defaultSelected.facilityTypes = facility_types;
        defaultSelected.facilityTypes.forEach(
          (opt) => (opt.value = !fts.includes(opt.data.id))
        );
      }
    } else {
      defaultSelected.facilities = null;
    }
  }

  defaultSelected.eventTypes = event_types;
  let ets = query.get(ET_K);
  if (ets?.length) {
    ets = ets.split(",");
    defaultSelected.eventTypes.forEach(
      (opt) => (opt.value = !ets.includes(opt.data.id))
    );
  }

  let mbs = query.get(MB_K);
  if (mbs?.length) {
    mbs = mbs.split(",");
    defaultSelected.moveBadges = move_badges.filter((opt) =>
      mbs.includes(opt.value.toString())
    );
  }

  let mds = query.get(MD_K);
  if (mds?.length) {
    mds = mds.split(",");
    defaultSelected.moveDrivers = move_drivers.filter((opt) =>
      mds.includes(opt.value.toString())
    );
  }

  let ms = query.get(MS_K);
  if (ms?.length) {
    ms = ms.split(",");
    defaultSelected.moveStates = move_states.filter((opt) =>
      ms.includes(opt.value.toString())
    );
  }

  const eqmId = query.get(EQM_K);
  if (eqmId?.length) {
    defaultSelected.equipmentMaster = equipment_masters.find(
      (opt) => opt.value == eqmId
    );
    if (hsId?.length) {
      if (
        !defaultSelected.equipmentMaster.data.health_system_ids.includes(
          parseInt(hsId)
        )
      ) {
        defaultSelected.equipmentMaster = null;
      }
    }
  }

  if (defaultSelected.equipmentMaster) {
    let iiIds = query.get(II_K);
    if (iiIds?.length) {
      const masterId =
        defaultSelected.equipmentMaster &&
        defaultSelected.equipmentMaster.value;
      const systemId =
        defaultSelected.healthSystem && defaultSelected.healthSystem.value;

      iiIds = iiIds.split(",").map((ii) => parseInt(ii));
      defaultSelected.inventoryItems = [];
      inventory_items.forEach((ii) => {
        if (iiIds.length) {
          const index = iiIds.indexOf(ii.value);
          if (index > -1) {
            if (systemId && systemId != ii.data.health_system_id) return;
            if (masterId && masterId != ii.data.equipment_master_id) return;
            defaultSelected.inventoryItems.push(ii);
            iiIds.splice(index, 1);
          }
        } else {
          return;
        }
      });
    }
  }
  return defaultSelected;
};
