export const equipmentMasterSamples = [
  {
    id: "2778",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "3M",
      modelTitle: "ESPE RotoMix",
      name: "3M ESPE RotoMix Capsule Mixing Device",
      titleDescription: "Capsule Mixing Device",
    },
  },
  {
    id: "1769",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "A-Dec",
      modelTitle: "3420",
      name: "A-Dec 3420 Dental Unit",
      titleDescription: "Dental Unit",
    },
  },
  {
    id: "1744",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ACMI",
      modelTitle: "AEH-4",
      name: "ACMI AEH-4 Electrohydraulic Lithotripter",
      titleDescription: "Electrohydraulic Lithotripter",
    },
  },
  {
    id: "2777",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ADS",
      modelTitle: "EOS",
      name: "ADS EOS Extraoral Suction System",
      titleDescription: "Extraoral Suction System",
    },
  },
  {
    id: "1507",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "AMSCO",
      modelTitle: "OrthoVision",
      name: "AMSCO OrthoVision Table",
      titleDescription: "Table",
    },
  },
  {
    id: "1693",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Acu Lux",
      modelTitle: "AX3001",
      name: "Acu Lux AX3001 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1115",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Addition Technologies",
      modelTitle: "KV2000 - Microkaratome",
      name: "Addition Technologies KV2000 - Microkaratome Eye Vacuum Pump Regulator",
      titleDescription: "Eye Vacuum Pump Regulator",
    },
  },
  {
    id: "543",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aesculap",
      modelTitle: "ELAN-E",
      name: "Aesculap ELAN-E Surgical Bone Drill",
      titleDescription: "Surgical Bone Drill",
    },
  },
  {
    id: "1293",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alaris",
      modelTitle: "8015",
      name: "Alaris 8015 Alarms PC Guardrails",
      titleDescription: "Alarms PC Guardrails",
    },
  },
  {
    id: "142",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alaris",
      modelTitle: "8100",
      name: "Alaris 8100 Infusion Pump",
      titleDescription: "Infusion Pump",
    },
  },
  {
    id: "1628",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "Accurus 400VS",
      name: "Alcon Laboratories Inc Accurus 400VS Phacoemulsification Unit",
      titleDescription: "Phacoemulsification Unit",
    },
  },
  {
    id: "1541",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "Accurus 600DS",
      name: "Alcon Laboratories Inc Accurus 600DS Phacoemulsification Unit",
      titleDescription: "Phacoemulsification Unit",
    },
  },
  {
    id: "1426",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "Accurus 800CS",
      name: "Alcon Laboratories Inc Accurus 800CS Phacoemulsification Unit",
      titleDescription: "Phacoemulsification Unit",
    },
  },
  {
    id: "1615",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "Accurus 800CS",
      name: "Alcon Laboratories Inc Accurus 800CS Phacoemulsification Unit DELETE",
      titleDescription: "Phacoemulsification Unit DELETE",
    },
  },
  {
    id: "2799",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "Accurus Fragmatome",
      name: "Alcon Laboratories Inc Accurus Fragmatome Handpiece",
      titleDescription: "Handpiece",
    },
  },
  {
    id: "1024",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "CONSTELLATION",
      name: "Alcon Laboratories Inc CONSTELLATION Phacoemulsification Unit",
      titleDescription: "Phacoemulsification Unit",
    },
  },
  {
    id: "1542",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "Centurion",
      name: "Alcon Laboratories Inc Centurion Phacoemulsification Unit",
      titleDescription: "Phacoemulsification Unit",
    },
  },
  {
    id: "113",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "INFINITI",
      name: "Alcon Laboratories Inc INFINITI Phacoemulsification Unit",
      titleDescription: "Phacoemulsification Unit",
    },
  },
  {
    id: "140",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Alcon Laboratories Inc",
      modelTitle: "Series 20000 Legacy",
      name: "Alcon Laboratories Inc Series 20000 Legacy Phacoemulsification Unit",
      titleDescription: "Phacoemulsification Unit",
    },
  },
  {
    id: "625",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "Easy Irrigation Tower",
      name: "Allen Medical Systems Easy Irrigation Tower Fluid Management",
      titleDescription: "Fluid Management",
    },
  },
  {
    id: "1698",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "LC25-U019-01",
      name: "Allen Medical Systems LC25-U019-01 Spinal Table",
      titleDescription: "Spinal Table",
    },
  },
  {
    id: "1730",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "PAL Stirrups #10023",
      name: "Allen Medical Systems PAL Stirrups #10023 Blue Stirrups",
      titleDescription: "Blue Stirrups",
    },
  },
  {
    id: "1729",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "SAM III",
      name: "Allen Medical Systems SAM III Stirrups",
      titleDescription: "Stirrups",
    },
  },
  {
    id: "2292",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "Simplicity",
      name: "Allen Medical Systems Simplicity Knee Crutches",
      titleDescription: "Knee Crutches",
    },
  },
  {
    id: "1776",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "Ultrafins",
      name: "Allen Medical Systems Ultrafins Black Stirrups",
      titleDescription: "Black Stirrups",
    },
  },
  {
    id: "2291",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "Unknown",
      name: "Allen Medical Systems Unknown Hand table with clamps",
      titleDescription: "Hand table with clamps",
    },
  },
  {
    id: "189",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Allen Medical Systems",
      modelTitle: "Yellofins Elite",
      name: "Allen Medical Systems Yellofins Elite Stirrups - Surgical Positioner",
      titleDescription: "Stirrups - Surgical Positioner",
    },
  },
  {
    id: "2601",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aloka",
      modelTitle: "Arietta 70",
      name: "Aloka Arietta 70 Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1783",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aloka",
      modelTitle: "ProSound Alpha 5",
      name: "Aloka ProSound Alpha 5 Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "998",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aloka",
      modelTitle: "ProSound Alpha 6",
      name: "Aloka ProSound Alpha 6 Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "1461",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aloka",
      modelTitle: "ProSound Alpha 7",
      name: "Aloka ProSound Alpha 7 Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "2217",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aloka",
      modelTitle: "ProSound F75",
      name: "Aloka ProSound F75 Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1530",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aloka",
      modelTitle: "Unknown",
      name: "Aloka Unknown Robotics Laparoscopic Probe",
      titleDescription: "Robotics Laparoscopic Probe",
    },
  },
  {
    id: "1880",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Amatech Corp",
      modelTitle: "Gravity Fluid Tower",
      name: "Amatech Corp Gravity Fluid Tower Fluid Tower",
      titleDescription: "Fluid Tower",
    },
  },
  {
    id: "1345",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ambu",
      modelTitle: "aView Videoscope",
      name: "Ambu aView Videoscope Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "999",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "American Medical Systems",
      modelTitle: "Aura XP",
      name: "American Medical Systems Aura XP KTP Laser System",
      titleDescription: "KTP Laser System",
    },
  },
  {
    id: "1003",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "American Medical Systems",
      modelTitle: "Greenlight XPS",
      name: "American Medical Systems Greenlight XPS Laser Therapy System",
      titleDescription: "Laser Therapy System",
    },
  },
  {
    id: "1001",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "American Medical Systems",
      modelTitle: "StoneLight",
      name: "American Medical Systems StoneLight Holmium Laser Therapy System",
      titleDescription: "Holmium Laser Therapy System",
    },
  },
  {
    id: "1740",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "AngioDynamics",
      modelTitle: "IntelliFlow",
      name: "AngioDynamics IntelliFlow Infusion Pump",
      titleDescription: "Infusion Pump",
    },
  },
  {
    id: "1741",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "AngioDynamics",
      modelTitle: "Rita Model 1500X",
      name: "AngioDynamics Rita Model 1500X RF Generator",
      titleDescription: "RF Generator",
    },
  },
  {
    id: "506",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "AngioDynamics",
      modelTitle: "Tumescent Delivery System",
      name: "AngioDynamics Tumescent Delivery System High Speed Infiltration",
      titleDescription: "High Speed Infiltration",
    },
  },
  {
    id: "505",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "AngioDynamics",
      modelTitle: "VenaCure 1470",
      name: "AngioDynamics VenaCure 1470 Endovenous Laser Treatment System",
      titleDescription: "Endovenous Laser Treatment System",
    },
  },
  {
    id: "2368",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Anspach",
      modelTitle: "Otologic Curved Micro (OCM)",
      name: "Anspach Otologic Curved Micro (OCM) Drill Attachment",
      titleDescription: "Drill Attachment",
    },
  },
  {
    id: "1117",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Anspach",
      modelTitle: "SC2000",
      name: "Anspach SC2000 Control Console",
      titleDescription: "Control Console",
    },
  },
  {
    id: "1118",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Anspach",
      modelTitle: "SC2100",
      name: "Anspach SC2100 Surgical Motor System",
      titleDescription: "Surgical Motor System",
    },
  },
  {
    id: "1119",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Anspach",
      modelTitle: "SC2101",
      name: "Anspach SC2101 Surgical Motor System",
      titleDescription: "Surgical Motor System",
    },
  },
  {
    id: "1439",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Anspach",
      modelTitle: "SC3002",
      name: "Anspach SC3002 G1 Electric Console, With Irrigation",
      titleDescription: "G1 Electric Console, With Irrigation",
    },
  },
  {
    id: "1832",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Aribex",
      modelTitle: "NOMAD Pro 2",
      name: "Aribex NOMAD Pro 2 Handheld X-Ray",
      titleDescription: "Handheld X-Ray",
    },
  },
  {
    id: "1695",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArjoHuntleigh",
      modelTitle: "Flowtron Excel",
      name: "ArjoHuntleigh Flowtron Excel DVT Pump",
      titleDescription: "DVT Pump",
    },
  },
  {
    id: "2001",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "APS II",
      name: "Arthrex APS II Shaver Handpiece",
      titleDescription: "Shaver Handpiece",
    },
  },
  {
    id: "56",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "APS II AR-8300",
      name: "Arthrex APS II AR-8300 Shaver Handpiece",
      titleDescription: "Shaver Handpiece",
    },
  },
  {
    id: "569",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "AR-1600M",
      name: "Arthrex AR-1600M Shoulder Boom; 3-Point Shoulder Distraction System",
      titleDescription: "Shoulder Boom; 3-Point Shoulder Distraction System",
    },
  },
  {
    id: "2210",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Beach Chair",
      name: "Arthrex Beach Chair Positioner",
      titleDescription: "Positioner",
    },
  },
  {
    id: "149",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Continuous Wave III",
      name: "Arthrex Continuous Wave III Arthroscopy Pump",
      titleDescription: "Arthroscopy Pump",
    },
  },
  {
    id: "472",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "DualWave AR-6480",
      name: "Arthrex DualWave AR-6480 Arthroscopy Pump",
      titleDescription: "Arthroscopy Pump",
    },
  },
  {
    id: "1498",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Hip Distraction System",
      name: "Arthrex Hip Distraction System Table Attachment",
      titleDescription: "Table Attachment",
    },
  },
  {
    id: "1986",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Synergy HD3",
      name: "Arthrex Synergy HD3 Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "1388",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Synergy Resection",
      name: "Arthrex Synergy Resection Shaver Console",
      titleDescription: "Shaver Console",
    },
  },
  {
    id: "1983",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Synergy UHD4 4K",
      name: "Arthrex Synergy UHD4 4K Imaging System Console",
      titleDescription: "Imaging System Console",
    },
  },
  {
    id: "2064",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Synergy UHD4 4K",
      name: "Arthrex Synergy UHD4 4K HD Monitor",
      titleDescription: "HD Monitor",
    },
  },
  {
    id: "1722",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Tower",
      name: "Arthrex Tower Synergy UHD4 4K",
      titleDescription: "Synergy UHD4 4K",
    },
  },
  {
    id: "1723",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Tower",
      name: "Arthrex Tower Arthroscopy",
      titleDescription: "Arthroscopy",
    },
  },
  {
    id: "1707",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Trimano",
      name: "Arthrex Trimano Arm Holder",
      titleDescription: "Arm Holder",
    },
  },
  {
    id: "2850",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Arthrex",
      modelTitle: "Trimano Fortis",
      name: "Arthrex Trimano Fortis Arm Holder",
      titleDescription: "Arm Holder",
    },
  },
  {
    id: "1902",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArthroCare",
      modelTitle: "20924",
      name: "ArthroCare 20924 Coblator Irrigation Pump",
      titleDescription: "Coblator Irrigation Pump",
    },
  },
  {
    id: "365",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArthroCare",
      modelTitle: "Atlas System",
      name: "ArthroCare Atlas System RadioFrequency Generator",
      titleDescription: "RadioFrequency Generator",
    },
  },
  {
    id: "70",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArthroCare",
      modelTitle: "Coblator II",
      name: "ArthroCare Coblator II Surgery System RadioFrequency Generator",
      titleDescription: "Surgery System RadioFrequency Generator",
    },
  },
  {
    id: "1864",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArthroCare",
      modelTitle: "Coblator II Surgery System",
      name: "ArthroCare Coblator II Surgery System Flow Control Valve Unit",
      titleDescription: "Flow Control Valve Unit",
    },
  },
  {
    id: "518",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArthroCare",
      modelTitle: "Quantum 2",
      name: "ArthroCare Quantum 2 Coblation System",
      titleDescription: "Coblation System",
    },
  },
  {
    id: "101",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArthroCare",
      modelTitle: "Quantum RF 12000",
      name: "ArthroCare Quantum RF 12000 RadioFrequency Generator",
      titleDescription: "RadioFrequency Generator",
    },
  },
  {
    id: "1608",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ArthroCare",
      modelTitle: "Unknown",
      name: "ArthroCare Unknown Timer",
      titleDescription: "Timer",
    },
  },
  {
    id: "2121",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Avedro",
      modelTitle: "KXL",
      name: "Avedro KXL Cross Linking System",
      titleDescription: "Cross Linking System",
    },
  },
  {
    id: "1122",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "B Braun Medical",
      modelTitle: "Perfusor Space",
      name: "B Braun Medical Perfusor Space Infusion Pump",
      titleDescription: "Infusion Pump",
    },
  },
  {
    id: "511",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BFW",
      modelTitle: "Maxenon Xi300",
      name: "BFW Maxenon Xi300 Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "1521",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BIOPTICS, Inc.",
      modelTitle: "BioVision",
      name: "BIOPTICS, Inc. BioVision X-Ray Imaging System",
      titleDescription: "X-Ray Imaging System",
    },
  },
  {
    id: "3084",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BK",
      modelTitle: "Activ",
      name: "BK Activ Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1543",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BK Ultrasound",
      modelTitle: "2102 Hawk",
      name: "BK Ultrasound 2102 Hawk Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1727",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BK Ultrasound",
      modelTitle: "2300",
      name: "BK Ultrasound 2300 Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1734",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BK Ultrasound",
      modelTitle: "Flex Focus 800",
      name: "BK Ultrasound Flex Focus 800 Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1606",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Bard Access Systems",
      modelTitle: "Site-Rite 2",
      name: "Bard Access Systems Site-Rite 2 Ultrasound Machine",
      titleDescription: "Ultrasound Machine",
    },
  },
  {
    id: "1565",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Bard Access Systems",
      modelTitle: "Site-Rite 4",
      name: "Bard Access Systems Site-Rite 4 Ultrasound Machine",
      titleDescription: "Ultrasound Machine",
    },
  },
  {
    id: "477",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Bard Access Systems",
      modelTitle: "Site-Rite 6",
      name: "Bard Access Systems Site-Rite 6 Ultrasound Machine",
      titleDescription: "Ultrasound Machine",
    },
  },
  {
    id: "1402",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Baxter",
      modelTitle: "DuploSpray  MIS",
      name: "Baxter DuploSpray  MIS Laproscopic Regulator",
      titleDescription: "Laproscopic Regulator",
    },
  },
  {
    id: "1121",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Baxter",
      modelTitle: "EasySpray",
      name: "Baxter EasySpray Pressure Regulator",
      titleDescription: "Pressure Regulator",
    },
  },
  {
    id: "1704",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Berchtold",
      modelTitle: "Chromophare E550",
      name: "Berchtold Chromophare E550 Surgical Exam Light",
      titleDescription: "Surgical Exam Light",
    },
  },
  {
    id: "2325",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Berchtold",
      modelTitle: "OPERON D-Series",
      name: "Berchtold OPERON D-Series Table",
      titleDescription: "Table",
    },
  },
  {
    id: "1026",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Berkeley",
      modelTitle: "System 15",
      name: "Berkeley System 15 SYNEVAC Vacuum Curettage",
      titleDescription: "SYNEVAC Vacuum Curettage",
    },
  },
  {
    id: "1644",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Berkeley",
      modelTitle: "VC-11",
      name: "Berkeley VC-11 Vacuum Curettage System",
      titleDescription: "Vacuum Curettage System",
    },
  },
  {
    id: "39",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Berkeley",
      modelTitle: "VC-2",
      name: "Berkeley VC-2 Vacuum Curettage System",
      titleDescription: "Vacuum Curettage System",
    },
  },
  {
    id: "1764",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Blue Endo",
      modelTitle: "Moresolution",
      name: "Blue Endo Moresolution Morcellator System",
      titleDescription: "Morcellator System",
    },
  },
  {
    id: "23",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "Genesys HTA System",
      name: "Boston Scientific Genesys HTA System Hydrothermal Ablation",
      titleDescription: "Hydrothermal Ablation",
    },
  },
  {
    id: "1607",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "HydroThermAblator",
      name: "Boston Scientific HydroThermAblator Hydrothermal Ablation",
      titleDescription: "Hydrothermal Ablation",
    },
  },
  {
    id: "1840",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "LithoVue",
      name: "Boston Scientific LithoVue Touch PC",
      titleDescription: "Touch PC",
    },
  },
  {
    id: "3021",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "Rezum",
      name: "Boston Scientific Rezum Generator",
      titleDescription: "Generator",
    },
  },
  {
    id: "2212",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "SpyGlass DS",
      name: "Boston Scientific SpyGlass DS Direct Visualization System",
      titleDescription: "Direct Visualization System",
    },
  },
  {
    id: "1745",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "Swiss LithoClast",
      name: "Boston Scientific Swiss LithoClast Lithotripter",
      titleDescription: "Lithotripter",
    },
  },
  {
    id: "187",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "Swiss LithoClast Select",
      name: "Boston Scientific Swiss LithoClast Select Lithotriptor",
      titleDescription: "Lithotriptor",
    },
  },
  {
    id: "1480",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "Swiss LithoClast Ultra",
      name: "Boston Scientific Swiss LithoClast Ultra Intracorporeal Lithotripter",
      titleDescription: "Intracorporeal Lithotripter",
    },
  },
  {
    id: "1591",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Boston Scientific",
      modelTitle: "Zoom Latitude",
      name: "Boston Scientific Zoom Latitude Programmer/Recorder/Monitor",
      titleDescription: "Programmer/Recorder/Monitor",
    },
  },
  {
    id: "942",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BrainLAB",
      modelTitle: "Curve",
      name: "BrainLAB Curve Image Guided Surgery",
      titleDescription: "Image Guided Surgery",
    },
  },
  {
    id: "1515",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BrainLAB",
      modelTitle: "KICK",
      name: "BrainLAB KICK Navigation Station",
      titleDescription: "Navigation Station",
    },
  },
  {
    id: "41",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BrainLAB",
      modelTitle: "Kolibri Navigation Station 2.0 with Mobile Camera Stand",
      name: "BrainLAB Kolibri Navigation Station 2.0 with Mobile Camera Stand Cranial Navigation System",
      titleDescription: "Cranial Navigation System",
    },
  },
  {
    id: "1549",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BrainLAB",
      modelTitle: "VectorVision",
      name: "BrainLAB VectorVision Image Guided Surgery System",
      titleDescription: "Image Guided Surgery System",
    },
  },
  {
    id: "1503",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "BrainLAB",
      modelTitle: "VectorVision2",
      name: "BrainLAB VectorVision2 Image Guided Surgery",
      titleDescription: "Image Guided Surgery",
    },
  },
  {
    id: "1124",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Buffalo Filter",
      modelTitle: "PlumeSafe Whisper Turbo",
      name: "Buffalo Filter PlumeSafe Whisper Turbo Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "1702",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Buffalo Filter",
      modelTitle: "Viro Vac",
      name: "Buffalo Filter Viro Vac Smoke Evacuation System",
      titleDescription: "Smoke Evacuation System",
    },
  },
  {
    id: "1974",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Burton",
      modelTitle: "Halogen CoolSpot II",
      name: "Burton Halogen CoolSpot II Surgical Light",
      titleDescription: "Surgical Light",
    },
  },
  {
    id: "1028",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Byron Medical",
      modelTitle: "EZ-Pump",
      name: "Byron Medical EZ-Pump Infiltrator",
      titleDescription: "Infiltrator",
    },
  },
  {
    id: "493",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Byron Medical",
      modelTitle: "LySonix 3000 ",
      name: "Byron Medical LySonix 3000  Liposuction Unit",
      titleDescription: "Liposuction Unit",
    },
  },
  {
    id: "40",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Byron Medical",
      modelTitle: "PSI-TEC III",
      name: "Byron Medical PSI-TEC III Aspirator",
      titleDescription: "Aspirator",
    },
  },
  {
    id: "3125",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CAE Blue Phantom",
      modelTitle: "GEN II",
      name: "CAE Blue Phantom GEN II Ultrasound Central Line Training Model",
      titleDescription: "Ultrasound Central Line Training Model",
    },
  },
  {
    id: "1999",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "270123",
      name: "CONMED 270123 Dual Infuser Irrigation Pump",
      titleDescription: "Dual Infuser Irrigation Pump",
    },
  },
  {
    id: "1964",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "270178",
      name: "CONMED 270178 1 Liter Pressure Infuser Irrigation Pump",
      titleDescription: "1 Liter Pressure Infuser Irrigation Pump",
    },
  },
  {
    id: "1826",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "AER Defense",
      name: "CONMED AER Defense Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "312",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "D3000 Console",
      name: "CONMED D3000 Console Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1126",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "GS 1002 40L",
      name: "CONMED GS 1002 40L Insufflator",
      titleDescription: "Insufflator",
    },
  },
  {
    id: "2018",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "HD1080P",
      name: "CONMED HD1080P Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "1583",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "HelixAR",
      name: "CONMED HelixAR Electrosurgical Generator with Argon Beam Coagulation (ABC)",
      titleDescription:
        "Electrosurgical Generator with Argon Beam Coagulation (ABC)",
    },
  },
  {
    id: "2061",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "IM4000",
      name: "CONMED IM4000 HD 1080P Image Console",
      titleDescription: "HD 1080P Image Console",
    },
  },
  {
    id: "1127",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "IM4000",
      name: "CONMED IM4000 High Definition Camera",
      titleDescription: "High Definition Camera",
    },
  },
  {
    id: "1716",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "IM400R",
      name: "CONMED IM400R High Definition Camera",
      titleDescription: "High Definition Camera",
    },
  },
  {
    id: "1128",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "LS7700",
      name: "CONMED LS7700 HD Light Source",
      titleDescription: "HD Light Source",
    },
  },
  {
    id: "1404",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "Pressure Infuser",
      name: "CONMED Pressure Infuser Irrigation Pump",
      titleDescription: "Irrigation Pump",
    },
  },
  {
    id: "363",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "Sabre 180",
      name: "CONMED Sabre 180 Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1697",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "Surgiquest AirSeal IFS",
      name: "CONMED Surgiquest AirSeal IFS Intelligent Flow System",
      titleDescription: "Intelligent Flow System",
    },
  },
  {
    id: "1554",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "System 1200",
      name: "CONMED System 1200 Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "1031",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "System 2450",
      name: "CONMED System 2450 Electrosurgical Generator",
      titleDescription: "Electrosurgical Generator",
    },
  },
  {
    id: "110",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "System 5000",
      name: "CONMED System 5000 Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "150",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "System 7550",
      name: "CONMED System 7550 Argon Beam Coagulator",
      titleDescription: "Argon Beam Coagulator",
    },
  },
  {
    id: "1129",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED",
      modelTitle: "VP4726F",
      name: 'CONMED VP4726F 26" HD LCD Monitor',
      titleDescription: '26" HD LCD Monitor',
    },
  },
  {
    id: "1938",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CONMED Linvatec",
      modelTitle: "10k",
      name: "CONMED Linvatec 10k Fluid System",
      titleDescription: "Fluid System",
    },
  },
  {
    id: "1131",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CUDA",
      modelTitle: "HLS-150",
      name: "CUDA HLS-150 Halogen Light Source",
      titleDescription: "Halogen Light Source",
    },
  },
  {
    id: "1132",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CUDA",
      modelTitle: "XLS-300",
      name: "CUDA XLS-300 Fiberoptic Light Source",
      titleDescription: "Fiberoptic Light Source",
    },
  },
  {
    id: "2820",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Candela",
      modelTitle: "Vbeam Prima",
      name: "Candela Vbeam Prima Pulsed-Dye Laser",
      titleDescription: "Pulsed-Dye Laser",
    },
  },
  {
    id: "1029",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Care Wise",
      modelTitle: "C-Trak - Model CW3000",
      name: "Care Wise C-Trak - Model CW3000 Automatic Analyzer System",
      titleDescription: "Automatic Analyzer System",
    },
  },
  {
    id: "1030",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Care Wise",
      modelTitle: "C-Trak - Model CW4000 Galaxy",
      name: "Care Wise C-Trak - Model CW4000 Galaxy Gamma Probe System",
      titleDescription: "Gamma Probe System",
    },
  },
  {
    id: "2396",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carewise",
      modelTitle: "C-Trak",
      name: "Carewise C-Trak Gamma Probe",
      titleDescription: "Gamma Probe",
    },
  },
  {
    id: "2822",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "750i",
      name: "Carl Zeiss Inc 750i Humphrey Field Analyzer",
      titleDescription: "Humphrey Field Analyzer",
    },
  },
  {
    id: "2785",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "IOLMaster 700",
      name: "Carl Zeiss Inc IOLMaster 700 Optical Tomography",
      titleDescription: "Optical Tomography",
    },
  },
  {
    id: "1915",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "Kinevo 900",
      name: "Carl Zeiss Inc Kinevo 900 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1488",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "MediLive Trio",
      name: "Carl Zeiss Inc MediLive Trio Camera Connection",
      titleDescription: "Camera Connection",
    },
  },
  {
    id: "984",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI 1 FC",
      name: "Carl Zeiss Inc OPMI 1 FC Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1931",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI 1 FC / S8",
      name: "Carl Zeiss Inc OPMI 1 FC / S8 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "172",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI 11 / S21",
      name: "Carl Zeiss Inc OPMI 11 / S21 Microscope",
      titleDescription: "Microscope",
    },
  },
  {
    id: "1950",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI CS / NC31",
      name: "Carl Zeiss Inc OPMI CS / NC31 Neuro Spine Microscope",
      titleDescription: "Neuro Spine Microscope",
    },
  },
  {
    id: "1326",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI Lumera 700",
      name: "Carl Zeiss Inc OPMI Lumera 700 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "474",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI Lumera S7",
      name: "Carl Zeiss Inc OPMI Lumera S7 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "212",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI MD / S3",
      name: "Carl Zeiss Inc OPMI MD / S3 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "87",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI PENTERO 900",
      name: "Carl Zeiss Inc OPMI PENTERO 900 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "175",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI VARIO 700",
      name: "Carl Zeiss Inc OPMI VARIO 700 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "535",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI VISU 200 / S8",
      name: "Carl Zeiss Inc OPMI VISU 200 / S8 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "265",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI Vario / S88",
      name: "Carl Zeiss Inc OPMI Vario / S88 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "534",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMI pico / S100",
      name: "Carl Zeiss Inc OPMI pico / S100 Microscope",
      titleDescription: "Microscope",
    },
  },
  {
    id: "1626",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "OPMi Vario / NC 33",
      name: "Carl Zeiss Inc OPMi Vario / NC 33 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "2022",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "Opmi Visu Series",
      name: "Carl Zeiss Inc Opmi Visu Series Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1954",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "Pentero 800",
      name: "Carl Zeiss Inc Pentero 800 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1692",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "S4",
      name: "Carl Zeiss Inc S4 Ophthalmology Microscope",
      titleDescription: "Ophthalmology Microscope",
    },
  },
  {
    id: "1627",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "S5",
      name: "Carl Zeiss Inc S5 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1599",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "S5 Pro",
      name: "Carl Zeiss Inc S5 Pro Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1770",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "S7 / OPMI Movena",
      name: "Carl Zeiss Inc S7 / OPMI Movena Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1719",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "S7 / OPMI Sensera",
      name: "Carl Zeiss Inc S7 / OPMI Sensera Microscope",
      titleDescription: "Microscope",
    },
  },
  {
    id: "1771",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "S7 / OPMI VISU 150",
      name: "Carl Zeiss Inc S7 / OPMI VISU 150 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "2135",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "Superlux 175",
      name: "Carl Zeiss Inc Superlux 175 Microscope",
      titleDescription: "Microscope",
    },
  },
  {
    id: "1949",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "Superlux 301",
      name: "Carl Zeiss Inc Superlux 301 Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "2308",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "Tivato 700",
      name: "Carl Zeiss Inc Tivato 700 ENT Microscope",
      titleDescription: "ENT Microscope",
    },
  },
  {
    id: "1556",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Carl Zeiss Inc",
      modelTitle: "VISULAS YAG III",
      name: "Carl Zeiss Inc VISULAS YAG III Ophthalmology Laser",
      titleDescription: "Ophthalmology Laser",
    },
  },
  {
    id: "2392",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cheetah Medical",
      modelTitle: "NICOM",
      name: "Cheetah Medical NICOM Hemodynamic Monitoring System",
      titleDescription: "Hemodynamic Monitoring System",
    },
  },
  {
    id: "2599",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cianna Medical, INC",
      modelTitle: "SaviScout",
      name: "Cianna Medical, INC SaviScout Radar Localization System",
      titleDescription: "Radar Localization System",
    },
  },
  {
    id: "2786",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Civco",
      modelTitle: "GUS Unit",
      name: "Civco GUS Unit Endoscope Soak Station",
      titleDescription: "Endoscope Soak Station",
    },
  },
  {
    id: "1430",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cochlear",
      modelTitle: "Osscora",
      name: "Cochlear Osscora Surgical Set",
      titleDescription: "Surgical Set",
    },
  },
  {
    id: "1449",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Codman",
      modelTitle: "MALIS CMC V",
      name: "Codman MALIS CMC V Bipolar Electrosurgical Generator",
      titleDescription: "Bipolar Electrosurgical Generator",
    },
  },
  {
    id: "1453",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Codman",
      modelTitle: "MALIS CMC V Module 1000",
      name: "Codman MALIS CMC V Module 1000 Irrigator",
      titleDescription: "Irrigator",
    },
  },
  {
    id: "1703",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Codman",
      modelTitle: "Malis",
      name: "Codman Malis Precision Bipolar Coagulator",
      titleDescription: "Precision Bipolar Coagulator",
    },
  },
  {
    id: "1579",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Codman",
      modelTitle: "Malis Solution 2.0",
      name: "Codman Malis Solution 2.0 Irrigation Module",
      titleDescription: "Irrigation Module",
    },
  },
  {
    id: "45",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Codman",
      modelTitle: "SYNERGY MALIS - CMC II",
      name: "Codman SYNERGY MALIS - CMC II Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "299",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Codman",
      modelTitle: "SYNERGY MALIS - CMC III",
      name: "Codman SYNERGY MALIS - CMC III Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1550",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cogent Light",
      modelTitle: "SolarTec 100",
      name: "Cogent Light SolarTec 100 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1757",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Coherent Inc",
      modelTitle: "Novus 2000",
      name: "Coherent Inc Novus 2000 Argon Laser",
      titleDescription: "Argon Laser",
    },
  },
  {
    id: "1973",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Colin Medical Instruments",
      modelTitle: "2110",
      name: "Colin Medical Instruments 2110 Patient Monitor",
      titleDescription: "Patient Monitor",
    },
  },
  {
    id: "1972",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Colin Medical Instruments",
      modelTitle: "BP-8800",
      name: "Colin Medical Instruments BP-8800 Press-mate blood pressure monitor",
      titleDescription: "Press-mate blood pressure monitor",
    },
  },
  {
    id: "2067",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cook Medical",
      modelTitle: "DP-M350",
      name: "Cook Medical DP-M350 Doppler Blood Flow Monitor",
      titleDescription: "Doppler Blood Flow Monitor",
    },
  },
  {
    id: "544",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CooperSurgical",
      modelTitle: "CE-2000",
      name: "CooperSurgical CE-2000 Ophthalmic Cryosurgery System",
      titleDescription: "Ophthalmic Cryosurgery System",
    },
  },
  {
    id: "1567",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "CooperSurgical",
      modelTitle: "OptiK Model 1",
      name: "CooperSurgical OptiK Model 1 Colposcope",
      titleDescription: "Colposcope",
    },
  },
  {
    id: "1711",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cosman Medical",
      modelTitle: "RFG-4",
      name: "Cosman Medical RFG-4 Radiofrequency Generator",
      titleDescription: "Radiofrequency Generator",
    },
  },
  {
    id: "2277",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "Barrx",
      name: "Covidien Barrx Radiofrequency Ablation Generator",
      titleDescription: "Radiofrequency Ablation Generator",
    },
  },
  {
    id: "2194",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "ClosureFast",
      name: "Covidien ClosureFast Radiofrequency Generator",
      titleDescription: "Radiofrequency Generator",
    },
  },
  {
    id: "1302",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "Force FX",
      name: "Covidien Force FX Electrosurgical Generator",
      titleDescription: "Electrosurgical Generator",
    },
  },
  {
    id: "63",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "Force FX-C",
      name: "Covidien Force FX-C Electrosurgical Generator",
      titleDescription: "Electrosurgical Generator",
    },
  },
  {
    id: "510",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "ForceTriad",
      name: "Covidien ForceTriad Electrosurgical Generator",
      titleDescription: "Electrosurgical Generator",
    },
  },
  {
    id: "470",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "RapidVac",
      name: "Covidien RapidVac Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "1130",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "SURGII-20",
      name: "Covidien SURGII-20 SurgiStat Electrosurgical Generator II",
      titleDescription: "SurgiStat Electrosurgical Generator II",
    },
  },
  {
    id: "1645",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "SurgiStat",
      name: "Covidien SurgiStat Electrosurgical Generator",
      titleDescription: "Electrosurgical Generator",
    },
  },
  {
    id: "1562",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "Valleylab FT10",
      name: "Covidien Valleylab FT10 Energy Platform",
      titleDescription: "Energy Platform",
    },
  },
  {
    id: "2387",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "Valleylab FX8",
      name: "Covidien Valleylab FX8 Energy Platform",
      titleDescription: "Energy Platform",
    },
  },
  {
    id: "1034",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "Vital Vue",
      name: "Covidien Vital Vue Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "614",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Covidien",
      modelTitle: "superDimension",
      name: "Covidien superDimension Navigation System",
      titleDescription: "Navigation System",
    },
  },
  {
    id: "1709",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cryogenic Technology",
      modelTitle: "Cryo-Pac",
      name: "Cryogenic Technology Cryo-Pac Cryoanalgesic Equipment",
      titleDescription: "Cryoanalgesic Equipment",
    },
  },
  {
    id: "1837",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Cybernet",
      modelTitle: "iOne-MP176",
      name: "Cybernet iOne-MP176 Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "1536",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "D.F. VASCONCELLOS",
      modelTitle: "CP-M2522",
      name: "D.F. VASCONCELLOS CP-M2522 COLPOSCOPE",
      titleDescription: "COLPOSCOPE",
    },
  },
  {
    id: "1443",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Datascope",
      modelTitle: "CS100 IABP",
      name: "Datascope CS100 IABP Intelligent Counterpulsation Balloon Pump",
      titleDescription: "Intelligent Counterpulsation Balloon Pump",
    },
  },
  {
    id: "3017",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Datex-Ohmeda Inc",
      modelTitle: "Unknown",
      name: "Datex-Ohmeda Inc Unknown Two Giraffe Beds",
      titleDescription: "Two Giraffe Beds",
    },
  },
  {
    id: "1438",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "De Soutter Medical",
      modelTitle: "CC5",
      name: "De Soutter Medical CC5 Clean Cast System",
      titleDescription: "Clean Cast System",
    },
  },
  {
    id: "1831",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dentalaire",
      modelTitle: "DTP00509 Ultimate",
      name: "Dentalaire DTP00509 Ultimate Dental Station",
      titleDescription: "Dental Station",
    },
  },
  {
    id: "1061",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Depuy Mitek",
      modelTitle: "FMS SOLO",
      name: "Depuy Mitek FMS SOLO Fluid Management System",
      titleDescription: "Fluid Management System",
    },
  },
  {
    id: "2137",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Depuy Mitek",
      modelTitle: "Hawkins Bell Self-Retaining Shoulder Retractor Set",
      name: "Depuy Mitek Hawkins Bell Self-Retaining Shoulder Retractor Set Tray",
      titleDescription: "Tray",
    },
  },
  {
    id: "366",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Depuy Mitek",
      modelTitle: "VAPR 3",
      name: "Depuy Mitek VAPR 3 Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1060",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Depuy Mitek",
      modelTitle: "VAPR VUE",
      name: "Depuy Mitek VAPR VUE Radiofrequency System",
      titleDescription: "Radiofrequency System",
    },
  },
  {
    id: "2086",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Detecto",
      modelTitle: "750",
      name: "Detecto 750 Weight Indicator",
      titleDescription: "Weight Indicator",
    },
  },
  {
    id: "1640",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dilon Technologies",
      modelTitle: "Navigator 2.0",
      name: "Dilon Technologies Navigator 2.0 Gamma Probe",
      titleDescription: "Gamma Probe",
    },
  },
  {
    id: "1544",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dornier MedTech",
      modelTitle: "Compact Sigma",
      name: "Dornier MedTech Compact Sigma Modular Lithotripter",
      titleDescription: "Modular Lithotripter",
    },
  },
  {
    id: "1134",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dornier MedTech",
      modelTitle: "Medilas H 20",
      name: "Dornier MedTech Medilas H 20 Surgical Laser - Holmium",
      titleDescription: "Surgical Laser - Holmium",
    },
  },
  {
    id: "2162",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dornier MedTech",
      modelTitle: "Medilas H 30",
      name: "Dornier MedTech Medilas H 30 Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "1064",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dornier MedTech",
      modelTitle: "Medilas H Solvo",
      name: "Dornier MedTech Medilas H Solvo Holmium: YAG Laser",
      titleDescription: "Holmium: YAG Laser",
    },
  },
  {
    id: "1566",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dornier MedTech",
      modelTitle: "RELAX +",
      name: "Dornier MedTech RELAX + Multifunctional Patient Table",
      titleDescription: "Multifunctional Patient Table",
    },
  },
  {
    id: "1037",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dutch Ophthalmic",
      modelTitle: "CryoStar",
      name: "Dutch Ophthalmic CryoStar Cryosurgical System",
      titleDescription: "Cryosurgical System",
    },
  },
  {
    id: "1691",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Dutch Ophthalmic",
      modelTitle: "Xenon BrightStar",
      name: "Dutch Ophthalmic Xenon BrightStar Illumination System",
      titleDescription: "Illumination System",
    },
  },
  {
    id: "1700",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ERBE Surgical Systems",
      modelTitle: "APC 2",
      name: "ERBE Surgical Systems APC 2 Argon Plasma Coagulator",
      titleDescription: "Argon Plasma Coagulator",
    },
  },
  {
    id: "1039",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ERBE Surgical Systems",
      modelTitle: "EIP 2",
      name: "ERBE Surgical Systems EIP 2 Irrigation Pump",
      titleDescription: "Irrigation Pump",
    },
  },
  {
    id: "334",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ERBE Surgical Systems",
      modelTitle: "VIO 300 D",
      name: "ERBE Surgical Systems VIO 300 D Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "2707",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Echosens",
      modelTitle: "FibroScan",
      name: "Echosens FibroScan Imaging System",
      titleDescription: "Imaging System",
    },
  },
  {
    id: "1317",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ecolab",
      modelTitle: "ORS-2066 R 6.6L",
      name: "Ecolab ORS-2066 R 6.6L Solution Warmer ",
      titleDescription: "Solution Warmer ",
    },
  },
  {
    id: "1362",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Edwards Lifesciences",
      modelTitle: "Vigileo",
      name: "Edwards Lifesciences Vigileo Patient Monitor",
      titleDescription: "Patient Monitor",
    },
  },
  {
    id: "1649",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ellex",
      modelTitle: "I3 Eye Cubed",
      name: "Ellex I3 Eye Cubed Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1689",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ellex",
      modelTitle: "LP4532",
      name: "Ellex LP4532 Ophthalmolic Laser System",
      titleDescription: "Ophthalmolic Laser System",
    },
  },
  {
    id: "1779",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ellman International",
      modelTitle: "Surgitron 4.0 Duel 120",
      name: "Ellman International Surgitron 4.0 Duel 120 Radiofrequency Generator",
      titleDescription: "Radiofrequency Generator",
    },
  },
  {
    id: "2338",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ellman International",
      modelTitle: "Surgitron F.F.P.F",
      name: "Ellman International Surgitron F.F.P.F Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1496",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Emerson",
      modelTitle: "96-SL",
      name: "Emerson 96-SL Surgical Lamp",
      titleDescription: "Surgical Lamp",
    },
  },
  {
    id: "1513",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Endo Optiks",
      modelTitle: "E2",
      name: "Endo Optiks E2 Ophthalmic Laser Endoscopy System",
      titleDescription: "Ophthalmic Laser Endoscopy System",
    },
  },
  {
    id: "114",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ethicon",
      modelTitle: "GEN11",
      name: "Ethicon GEN11 Endo-Surgery Generator",
      titleDescription: "Endo-Surgery Generator",
    },
  },
  {
    id: "176",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ethicon",
      modelTitle: "GYNECARE MD0100",
      name: "Ethicon GYNECARE MD0100 Tissue Morcellator",
      titleDescription: "Tissue Morcellator",
    },
  },
  {
    id: "484",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ethicon",
      modelTitle: "GYNECARE VERSAPOINT",
      name: "Ethicon GYNECARE VERSAPOINT Bipolar Electrosurgery System",
      titleDescription: "Bipolar Electrosurgery System",
    },
  },
  {
    id: "297",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ethicon",
      modelTitle: "Generator 300",
      name: "Ethicon Generator 300 Harmonic Scalpel",
      titleDescription: "Harmonic Scalpel",
    },
  },
  {
    id: "2351",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ethicon",
      modelTitle: "Gynecare Versapoint",
      name: "Ethicon Gynecare Versapoint Trays",
      titleDescription: "Trays",
    },
  },
  {
    id: "2089",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ethicon",
      modelTitle: "NeuWave DR-000551",
      name: "Ethicon NeuWave DR-000551 Microwave Ablation System",
      titleDescription: "Microwave Ablation System",
    },
  },
  {
    id: "483",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ethicon",
      modelTitle: "Thermachoice ll",
      name: "Ethicon Thermachoice ll Balloon Therapy Unit",
      titleDescription: "Balloon Therapy Unit",
    },
  },
  {
    id: "1356",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Faxitron",
      modelTitle: "BioVision",
      name: "Faxitron BioVision X-ray Imaging",
      titleDescription: "X-ray Imaging",
    },
  },
  {
    id: "1497",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Faxitron",
      modelTitle: "DX-50",
      name: "Faxitron DX-50 X-Ray",
      titleDescription: "X-Ray",
    },
  },
  {
    id: "1588",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Faxitron/Hologic",
      modelTitle: "TruNode",
      name: "Faxitron/Hologic TruNode Gamma Probe System",
      titleDescription: "Gamma Probe System",
    },
  },
  {
    id: "1684",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Frigitronics",
      modelTitle: "CE-82",
      name: "Frigitronics CE-82 Cryosurgical System",
      titleDescription: "Cryosurgical System",
    },
  },
  {
    id: "1424",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GAYMAR",
      modelTitle: "MEDI-THERM III",
      name: "GAYMAR MEDI-THERM III Hyper/Hypothermia System",
      titleDescription: "Hyper/Hypothermia System",
    },
  },
  {
    id: "289",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GE Healthcare",
      modelTitle: "AMX IV",
      name: "GE Healthcare AMX IV Portable X-Ray",
      titleDescription: "Portable X-Ray",
    },
  },
  {
    id: "2346",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GE Healthcare",
      modelTitle: "Carescape R860",
      name: "GE Healthcare Carescape R860 ICU Ventilator",
      titleDescription: "ICU Ventilator",
    },
  },
  {
    id: "1485",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GE Healthcare",
      modelTitle: "OEC 9800 Plus",
      name: "GE Healthcare OEC 9800 Plus Workstation",
      titleDescription: "Workstation",
    },
  },
  {
    id: "344",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GE Healthcare",
      modelTitle: "OEC 9800 Plus",
      name: "GE Healthcare OEC 9800 Plus Mobile C-Arm",
      titleDescription: "Mobile C-Arm",
    },
  },
  {
    id: "1395",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GE Healthcare",
      modelTitle: "OEC 9900 Elite",
      name: "GE Healthcare OEC 9900 Elite Workstation",
      titleDescription: "Workstation",
    },
  },
  {
    id: "319",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GE Healthcare",
      modelTitle: "OEC 9900 Elite",
      name: "GE Healthcare OEC 9900 Elite Mobile C-Arm",
      titleDescription: "Mobile C-Arm",
    },
  },
  {
    id: "1822",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "GE Medical Systems",
      modelTitle: "enFlow Controller Model 121",
      name: "GE Medical Systems enFlow Controller Model 121 Fluid and Blood Warmer",
      titleDescription: "Fluid and Blood Warmer",
    },
  },
  {
    id: "978",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Gyrus ACMI",
      modelTitle: "Berkeley VC-10",
      name: "Gyrus ACMI Berkeley VC-10 Vacuum Curettage System",
      titleDescription: "Vacuum Curettage System",
    },
  },
  {
    id: "1409",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Gyrus ACMI",
      modelTitle: "Diego",
      name: "Gyrus ACMI Diego ENT Director/Drill",
      titleDescription: "ENT Director/Drill",
    },
  },
  {
    id: "1753",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Gyrus ACMI",
      modelTitle: "IDC-1500",
      name: "Gyrus ACMI IDC-1500 Camera Control Unit",
      titleDescription: "Camera Control Unit",
    },
  },
  {
    id: "119",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Gyrus ACMI",
      modelTitle: "PK G400 Workstation - 777000",
      name: "Gyrus ACMI PK G400 Workstation - 777000 Plasma Kinetic Generator",
      titleDescription: "Plasma Kinetic Generator",
    },
  },
  {
    id: "27",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Gyrus ACMI",
      modelTitle: "PK SuperPulse - 744000",
      name: "Gyrus ACMI PK SuperPulse - 744000 Plasma Kinetic Generator",
      titleDescription: "Plasma Kinetic Generator",
    },
  },
  {
    id: "1336",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Gyrus ACMI",
      modelTitle: "Plasma Kinetic",
      name: "Gyrus ACMI Plasma Kinetic Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1560",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Gyrus ACMI",
      modelTitle: "Vista CTR",
      name: "Gyrus ACMI Vista CTR Generator",
      titleDescription: "Generator",
    },
  },
  {
    id: "1139",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "HK Surgical",
      modelTitle: "KIP-II",
      name: "HK Surgical KIP-II Klein Infiltration Pump",
      titleDescription: "Klein Infiltration Pump",
    },
  },
  {
    id: "2828",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "HP",
      modelTitle: "ProBook 650 G5",
      name: "HP ProBook 650 G5 Laerdal Sim Laptop",
      titleDescription: "Laerdal Sim Laptop",
    },
  },
  {
    id: "1847",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Haag-Streit International",
      modelTitle: "BQ 900",
      name: "Haag-Streit International BQ 900 Slit Lamp/Microscope",
      titleDescription: "Slit Lamp/Microscope",
    },
  },
  {
    id: "1135",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Haemonetics",
      modelTitle: "Cell Saver 5+",
      name: "Haemonetics Cell Saver 5+ Autologous Blood Recovery System",
      titleDescription: "Autologous Blood Recovery System",
    },
  },
  {
    id: "1634",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Haemonetics",
      modelTitle: "Cell Saver Elite",
      name: "Haemonetics Cell Saver Elite Autotransfusion System",
      titleDescription: "Autotransfusion System",
    },
  },
  {
    id: "2410",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hamilton Medical",
      modelTitle: "Hamilton-C1",
      name: "Hamilton Medical Hamilton-C1 Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "2395",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hamilton Medical",
      modelTitle: "Hamilton-G5",
      name: "Hamilton Medical Hamilton-G5 ICU Ventilator",
      titleDescription: "ICU Ventilator",
    },
  },
  {
    id: "2600",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hausted",
      modelTitle: "MBC",
      name: "Hausted MBC Mammography/Biopsy Chair",
      titleDescription: "Mammography/Biopsy Chair",
    },
  },
  {
    id: "2085",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Heidelberg",
      modelTitle: "HRT3 OCT",
      name: "Heidelberg HRT3 OCT Imaging Capture Console",
      titleDescription: "Imaging Capture Console",
    },
  },
  {
    id: "1446",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hemostatix Medical Technologies",
      modelTitle: "Precision 8400",
      name: "Hemostatix Medical Technologies Precision 8400 Thermal Scalpel System",
      titleDescription: "Thermal Scalpel System",
    },
  },
  {
    id: "376",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hill-Rom",
      modelTitle: "Affinity 4",
      name: "Hill-Rom Affinity 4 Birthing Bed",
      titleDescription: "Birthing Bed",
    },
  },
  {
    id: "2349",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hill-Rom",
      modelTitle: "Compella",
      name: "Hill-Rom Compella Bariatric Bed",
      titleDescription: "Bariatric Bed",
    },
  },
  {
    id: "320",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hill-Rom",
      modelTitle: "Total Care Bariatric Plus",
      name: "Hill-Rom Total Care Bariatric Plus ICU Bed",
      titleDescription: "ICU Bed",
    },
  },
  {
    id: "2389",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hill-Rom",
      modelTitle: "TotalCare SpO2RT® 2",
      name: "Hill-Rom TotalCare SpO2RT® 2 ICU Bed",
      titleDescription: "ICU Bed",
    },
  },
  {
    id: "1516",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hitachi",
      modelTitle: "Aloka Noblus",
      name: "Hitachi Aloka Noblus Diagnostic Ultrasound System",
      titleDescription: "Diagnostic Ultrasound System",
    },
  },
  {
    id: "2264",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hitachi",
      modelTitle: "Aloka UST-676LP",
      name: "Hitachi Aloka UST-676LP Ultrasound Probe",
      titleDescription: "Ultrasound Probe",
    },
  },
  {
    id: "2363",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hitachi",
      modelTitle: "Arietta 70",
      name: "Hitachi Arietta 70 Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "1586",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hitachi",
      modelTitle: "EUB-5500",
      name: "Hitachi EUB-5500 Ultrasound Diagnostic Scanner",
      titleDescription: "Ultrasound Diagnostic Scanner",
    },
  },
  {
    id: "1677",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hitachi",
      modelTitle: "Hi Vision Avius",
      name: "Hitachi Hi Vision Avius Ultrasound",
      titleDescription: "Ultrasound",
    },
  },
  {
    id: "1043",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "AQL100P Aquilex",
      name: "Hologic AQL100P Aquilex Fluid Control System",
      titleDescription: "Fluid Control System",
    },
  },
  {
    id: "2222",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "FLUENT",
      name: "Hologic FLUENT Fluid Management System",
      titleDescription: "Fluid Management System",
    },
  },
  {
    id: "630",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "Fluoroscan InSight",
      name: "Hologic Fluoroscan InSight Mini C-Arm",
      titleDescription: "Mini C-Arm",
    },
  },
  {
    id: "2132",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "Fluoroscan InSight 2",
      name: "Hologic Fluoroscan InSight 2 Mini  C-Arm",
      titleDescription: "Mini  C-Arm",
    },
  },
  {
    id: "103",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "NovaSure 10",
      name: "Hologic NovaSure 10 Endometrial Ablation System",
      titleDescription: "Endometrial Ablation System",
    },
  },
  {
    id: "346",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "Novasure 09",
      name: "Hologic Novasure 09 Ablation",
      titleDescription: "Ablation",
    },
  },
  {
    id: "499",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "RM-10-500",
      name: "Hologic RM-10-500 MyoSure Hysteroscopic Tissue Removal System",
      titleDescription: "MyoSure Hysteroscopic Tissue Removal System",
    },
  },
  {
    id: "1659",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Hologic",
      modelTitle: "RM-10-550",
      name: "Hologic RM-10-550 MyoSure Hysteroscopic Tissue Removal System",
      titleDescription: "MyoSure Hysteroscopic Tissue Removal System",
    },
  },
  {
    id: "1306",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "HoverTech",
      modelTitle: "HTAIR 1200",
      name: "HoverTech HTAIR 1200 Inflation Regulator",
      titleDescription: "Inflation Regulator",
    },
  },
  {
    id: "121",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "HoverTech",
      modelTitle: "Hovermatt",
      name: "HoverTech Hovermatt MATTRESS",
      titleDescription: "MATTRESS",
    },
  },
  {
    id: "1335",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Huntleigh",
      modelTitle: "Flowtron Excel",
      name: "Huntleigh Flowtron Excel DVT Prophylactic System",
      titleDescription: "DVT Prophylactic System",
    },
  },
  {
    id: "2084",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "IEC",
      modelTitle: "Medispin",
      name: "IEC Medispin Centrifuge",
      titleDescription: "Centrifuge",
    },
  },
  {
    id: "2243",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "IRIDEX",
      modelTitle: "Cyclo G6",
      name: "IRIDEX Cyclo G6 Glaucoma Laser System",
      titleDescription: "Glaucoma Laser System",
    },
  },
  {
    id: "1553",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "IRIDEX",
      modelTitle: "IQ 532",
      name: "IRIDEX IQ 532 Laser Therapy System",
      titleDescription: "Laser Therapy System",
    },
  },
  {
    id: "1652",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "IRIDEX",
      modelTitle: "IQ 810",
      name: "IRIDEX IQ 810 Opthamology Laser",
      titleDescription: "Opthamology Laser",
    },
  },
  {
    id: "1327",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "IRIDEX",
      modelTitle: "OcuLight GLx",
      name: "IRIDEX OcuLight GLx Ophthalmic Laser",
      titleDescription: "Ophthalmic Laser",
    },
  },
  {
    id: "375",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "IRIDEX",
      modelTitle: "OcuLight SL",
      name: "IRIDEX OcuLight SL Ophthalmic Laser",
      titleDescription: "Ophthalmic Laser",
    },
  },
  {
    id: "1436",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "IRIDEX",
      modelTitle: "OcuLight SLx",
      name: "IRIDEX OcuLight SLx Ophthalmic Laser",
      titleDescription: "Ophthalmic Laser",
    },
  },
  {
    id: "1712",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ISOLUX",
      modelTitle: "1300 XSB",
      name: "ISOLUX 1300 XSB Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1312",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Image Diagnostics",
      modelTitle: "100RT",
      name: "Image Diagnostics 100RT Pain Management Table",
      titleDescription: "Pain Management Table",
    },
  },
  {
    id: "1755",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Implant Med",
      modelTitle: "SI-95-115",
      name: "Implant Med SI-95-115 Surgical Drill System",
      titleDescription: "Surgical Drill System",
    },
  },
  {
    id: "2341",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Innovative Medical Products",
      modelTitle: "De Mayo",
      name: "Innovative Medical Products De Mayo Knee Positioner",
      titleDescription: "Knee Positioner",
    },
  },
  {
    id: "1786",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "InstruMed",
      modelTitle: "SP-2CX",
      name: "InstruMed SP-2CX Smartpump Tourniquet",
      titleDescription: "Smartpump Tourniquet",
    },
  },
  {
    id: "925",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Integra LifeSciences",
      modelTitle: "CUSA EXcel",
      name: "Integra LifeSciences CUSA EXcel Ultrasonic Tissue Ablation System",
      titleDescription: "Ultrasonic Tissue Ablation System",
    },
  },
  {
    id: "1584",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Integra LifeSciences",
      modelTitle: "CUSA Excel 2",
      name: "Integra LifeSciences CUSA Excel 2 Ultrasonic Tissue Ablation System",
      titleDescription: "Ultrasonic Tissue Ablation System",
    },
  },
  {
    id: "1435",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Integra LifeSciences",
      modelTitle: "Luxtec MLX",
      name: "Integra LifeSciences Luxtec MLX 300W Xenon Light Source",
      titleDescription: "300W Xenon Light Source",
    },
  },
  {
    id: "2136",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Integra LifeSciences",
      modelTitle: "Mayfield Ultra 360 Patient Positioning System",
      name: "Integra LifeSciences Mayfield Ultra 360 Patient Positioning System Cranial Stabilization System",
      titleDescription: "Cranial Stabilization System",
    },
  },
  {
    id: "2192",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Integra LifeSciences",
      modelTitle: "Model S",
      name: "Integra LifeSciences Model S Dermatome",
      titleDescription: "Dermatome",
    },
  },
  {
    id: "1819",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Intuitive",
      modelTitle: "da Vinci Si",
      name: "Intuitive da Vinci Si Robotic Surgical System",
      titleDescription: "Robotic Surgical System",
    },
  },
  {
    id: "1728",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Intuitive",
      modelTitle: "da Vinci Si",
      name: "Intuitive da Vinci Si Surgeon Console",
      titleDescription: "Surgeon Console",
    },
  },
  {
    id: "2884",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Intuitive",
      modelTitle: "da Vinci SimNow",
      name: "Intuitive da Vinci SimNow Robotic Simulator",
      titleDescription: "Robotic Simulator",
    },
  },
  {
    id: "2172",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Invacare",
      modelTitle: "9650-4",
      name: "Invacare 9650-4 Commode",
      titleDescription: "Commode",
    },
  },
  {
    id: "2223",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Invivo",
      modelTitle: "UroNav",
      name: "Invivo UroNav Prostate Biopsy",
      titleDescription: "Prostate Biopsy",
    },
  },
  {
    id: "1049",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "201331 20",
      name: "Karl Storz 201331 20 SCB Xenon 300 Light Source",
      titleDescription: "SCB Xenon 300 Light Source",
    },
  },
  {
    id: "1937",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "27224P",
      name: "Karl Storz 27224P Continuos Flow Pump",
      titleDescription: "Continuos Flow Pump",
    },
  },
  {
    id: "1378",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "481C",
      name: "Karl Storz 481C Miniature Light Source",
      titleDescription: "Miniature Light Source",
    },
  },
  {
    id: "2069",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "8403 ZX",
      name: "Karl Storz 8403 ZX C-Mac Video Laryngoscope",
      titleDescription: "C-Mac Video Laryngoscope",
    },
  },
  {
    id: "1289",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "AIDA HD Connect (20205520)",
      name: "Karl Storz AIDA HD Connect (20205520) Image Capture Solution",
      titleDescription: "Image Capture Solution",
    },
  },
  {
    id: "2829",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "AIDA HD Connect (20205620)",
      name: "Karl Storz AIDA HD Connect (20205620) Image Capture Solution",
      titleDescription: "Image Capture Solution",
    },
  },
  {
    id: "192",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "CALCUTRIPT",
      name: "Karl Storz CALCUTRIPT Lithotriptor",
      titleDescription: "Lithotriptor",
    },
  },
  {
    id: "1746",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Calcuson 276100 20",
      name: "Karl Storz Calcuson 276100 20 Lithotripter",
      titleDescription: "Lithotripter",
    },
  },
  {
    id: "1993",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "D-light P (20133720)",
      name: "Karl Storz D-light P (20133720) Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "2050",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "EJ-MLA26UK1",
      name: "Karl Storz EJ-MLA26UK1 LCD Monitor",
      titleDescription: "LCD Monitor",
    },
  },
  {
    id: "1980",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Endomat LC (20330320)",
      name: "Karl Storz Endomat LC (20330320) Suction Pump",
      titleDescription: "Suction Pump",
    },
  },
  {
    id: "1287",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Image 1 (22201020)",
      name: "Karl Storz Image 1 (22201020) Camera Control Unit",
      titleDescription: "Camera Control Unit",
    },
  },
  {
    id: "1539",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Image 1: Spies",
      name: "Karl Storz Image 1: Spies Modular Unit ",
      titleDescription: "Modular Unit ",
    },
  },
  {
    id: "1014",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Modulith SLX-F2",
      name: "Karl Storz Modulith SLX-F2 Mobile Urological Workstation (ESWL)",
      titleDescription: "Mobile Urological Workstation (ESWL)",
    },
  },
  {
    id: "2055",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Power LED 175 (201614 20)",
      name: "Karl Storz Power LED 175 (201614 20) Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1916",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "SC-WK32-G1B00",
      name: 'Karl Storz SC-WK32-G1B00 Radiance 32" Monitor',
      titleDescription: 'Radiance 32" Monitor',
    },
  },
  {
    id: "1688",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "SCB Electronic Endoflator (26430520)",
      name: "Karl Storz SCB Electronic Endoflator (26430520) Insufflator",
      titleDescription: "Insufflator",
    },
  },
  {
    id: "1047",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "SCB Thermoflator (26432020)",
      name: "Karl Storz SCB Thermoflator (26432020) Insufflator",
      titleDescription: "Insufflator",
    },
  },
  {
    id: "1928",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "TC 200 Image 1 Spies",
      name: "Karl Storz TC 200 Image 1 Spies Camera Console",
      titleDescription: "Camera Console",
    },
  },
  {
    id: "1785",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "TC 300 Image 1 S H3-Link",
      name: "Karl Storz TC 300 Image 1 S H3-Link Camera Console",
      titleDescription: "Camera Console",
    },
  },
  {
    id: "2031",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "TC 301 Image 1 S X-Link",
      name: "Karl Storz TC 301 Image 1 S X-Link Camera Console",
      titleDescription: "Camera Console",
    },
  },
  {
    id: "1733",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Tower",
      name: "Karl Storz Tower Gynecology",
      titleDescription: "Gynecology",
    },
  },
  {
    id: "1518",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Tower",
      name: "Karl Storz Tower Laparoscopic",
      titleDescription: "Laparoscopic",
    },
  },
  {
    id: "1667",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Tower",
      name: "Karl Storz Tower ENT Video",
      titleDescription: "ENT Video",
    },
  },
  {
    id: "1200",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Tower",
      name: "Karl Storz Tower Video",
      titleDescription: "Video",
    },
  },
  {
    id: "1756",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Tower",
      name: "Karl Storz Tower Endoscopy",
      titleDescription: "Endoscopy",
    },
  },
  {
    id: "2800",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "UP 210",
      name: "Karl Storz UP 210 Endomat Select",
      titleDescription: "Endomat Select",
    },
  },
  {
    id: "1141",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Unidrive GYN ",
      name: "Karl Storz Unidrive GYN  Endoscopic Morcellator",
      titleDescription: "Endoscopic Morcellator",
    },
  },
  {
    id: "979",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Unidrive II (20711120)",
      name: "Karl Storz Unidrive II (20711120) Endoscopic Morcellator",
      titleDescription: "Endoscopic Morcellator",
    },
  },
  {
    id: "380",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Unknown",
      name: "Karl Storz Unknown Sinus Instrument Tray ",
      titleDescription: "Sinus Instrument Tray ",
    },
  },
  {
    id: "1846",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Unknown",
      name: "Karl Storz Unknown Difficult Airway Video Intubation SmartKart",
      titleDescription: "Difficult Airway Video Intubation SmartKart",
    },
  },
  {
    id: "1472",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "V3C-SX18-A173",
      name: "Karl Storz V3C-SX18-A173 Flat Panel Monitor",
      titleDescription: "Flat Panel Monitor",
    },
  },
  {
    id: "928",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "WideView - SC-WU26-A1511",
      name: "Karl Storz WideView - SC-WU26-A1511 Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "1708",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Xenon 615",
      name: "Karl Storz Xenon 615 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "2011",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Karl Storz",
      modelTitle: "Xenon Nova 175 (201315 20)",
      name: "Karl Storz Xenon Nova 175 (201315 20) Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1555",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Keeler",
      modelTitle: "Cryomatic",
      name: "Keeler Cryomatic Cryosurgical System",
      titleDescription: "Cryosurgical System",
    },
  },
  {
    id: "1343",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Kimberly-Clark",
      modelTitle: "PMG-115-TD",
      name: "Kimberly-Clark PMG-115-TD Pain Management RF Generator",
      titleDescription: "Pain Management RF Generator",
    },
  },
  {
    id: "1342",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Kimberly-Clark",
      modelTitle: "TDA-PPU-1",
      name: "Kimberly-Clark TDA-PPU-1 Cooled RF Peristaltic Pump Unit",
      titleDescription: "Cooled RF Peristaltic Pump Unit",
    },
  },
  {
    id: "1911",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Kirwan Surgical",
      modelTitle: "28-2600",
      name: "Kirwan Surgical 28-2600 70W Bipolar Generator",
      titleDescription: "70W Bipolar Generator",
    },
  },
  {
    id: "3038",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Klarity Medical",
      modelTitle: "AirFlow",
      name: "Klarity Medical AirFlow Thermoplastic Oven",
      titleDescription: "Thermoplastic Oven",
    },
  },
  {
    id: "1295",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Kodak",
      modelTitle: "ESP 7250",
      name: "Kodak ESP 7250 Printer",
      titleDescription: "Printer",
    },
  },
  {
    id: "2175",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Konan",
      modelTitle: "NONCON-ROBO",
      name: "Konan NONCON-ROBO Specular Microscope",
      titleDescription: "Specular Microscope",
    },
  },
  {
    id: "2068",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Koven Technology",
      modelTitle: "ES-100X Minidop",
      name: "Koven Technology ES-100X Minidop Surgical Doppler",
      titleDescription: "Surgical Doppler",
    },
  },
  {
    id: "2348",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Kreg",
      modelTitle: "E-Z Wider",
      name: "Kreg E-Z Wider Bariatric Chair-Bed",
      titleDescription: "Bariatric Chair-Bed",
    },
  },
  {
    id: "2372",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Kronner",
      modelTitle: "Side-Kick",
      name: "Kronner Side-Kick Uterine Manipulator Holder",
      titleDescription: "Uterine Manipulator Holder",
    },
  },
  {
    id: "2174",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Laborie",
      modelTitle: "Goby KT",
      name: "Laborie Goby KT Urodynamic System",
      titleDescription: "Urodynamic System",
    },
  },
  {
    id: "2827",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Laerdal",
      modelTitle: "ShockLink",
      name: "Laerdal ShockLink Connector",
      titleDescription: "Connector",
    },
  },
  {
    id: "2825",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Laerdal",
      modelTitle: "SimBaby",
      name: "Laerdal SimBaby Patient Manikin with Trach",
      titleDescription: "Patient Manikin with Trach",
    },
  },
  {
    id: "2826",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Laerdal",
      modelTitle: "SimJunior",
      name: "Laerdal SimJunior Patient Manikin",
      titleDescription: "Patient Manikin",
    },
  },
  {
    id: "1529",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Laerdal",
      modelTitle: "SimMan 3G",
      name: "Laerdal SimMan 3G Patient Manikin",
      titleDescription: "Patient Manikin",
    },
  },
  {
    id: "2824",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Laerdal",
      modelTitle: "SimNewB",
      name: "Laerdal SimNewB Patient Manikin",
      titleDescription: "Patient Manikin",
    },
  },
  {
    id: "2833",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Laerdal",
      modelTitle: "SimPad PLUS",
      name: "Laerdal SimPad PLUS Manikin Controller",
      titleDescription: "Manikin Controller",
    },
  },
  {
    id: "1604",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "LaserSonics",
      modelTitle: "Illumina 740",
      name: "LaserSonics Illumina 740 CO2 Surgical Laser",
      titleDescription: "CO2 Surgical Laser",
    },
  },
  {
    id: "2367",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica",
      modelTitle: "CM1850 UV",
      name: "Leica CM1850 UV Cryostat",
      titleDescription: "Cryostat",
    },
  },
  {
    id: "1145",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M400E",
      name: "Leica Microsystems M400E ENT Surgical Microscope",
      titleDescription: "ENT Surgical Microscope",
    },
  },
  {
    id: "1410",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M520 F40",
      name: "Leica Microsystems M520 F40 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1963",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M525 F20",
      name: "Leica Microsystems M525 F20 Compact Surgical Microscope ",
      titleDescription: "Compact Surgical Microscope ",
    },
  },
  {
    id: "1146",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M525 F40",
      name: "Leica Microsystems M525 F40 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1285",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M525 F50",
      name: "Leica Microsystems M525 F50 Surgical ENT Microscope",
      titleDescription: "Surgical ENT Microscope",
    },
  },
  {
    id: "1484",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M525 MS3",
      name: "Leica Microsystems M525 MS3 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1463",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M525 OH4",
      name: "Leica Microsystems M525 OH4 Neuro Microscope",
      titleDescription: "Neuro Microscope",
    },
  },
  {
    id: "1147",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M680",
      name: "Leica Microsystems M680 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1413",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M690",
      name: "Leica Microsystems M690 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "565",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M720 OH5",
      name: "Leica Microsystems M720 OH5 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1557",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M841",
      name: "Leica Microsystems M841 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1664",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M844 / M820 F40",
      name: "Leica Microsystems M844 / M820 F40 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1680",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M844 F40",
      name: "Leica Microsystems M844 F40 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1393",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M844 F40 / F20",
      name: "Leica Microsystems M844 F40 / F20 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "1650",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "M844/M820/M822/M822 H F40",
      name: "Leica Microsystems M844/M820/M822/M822 H F40 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "983",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leica Microsystems",
      modelTitle: "Wild MEL63",
      name: "Leica Microsystems Wild MEL63 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "2379",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leisegang",
      modelTitle: "1DL-LED",
      name: "Leisegang 1DL-LED Colposcope",
      titleDescription: "Colposcope",
    },
  },
  {
    id: "1458",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Leisegang",
      modelTitle: "OptiK Model 1",
      name: "Leisegang OptiK Model 1 Colposcope",
      titleDescription: "Colposcope",
    },
  },
  {
    id: "1625",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Level 1",
      modelTitle: "H-1000",
      name: "Level 1 H-1000 Fluid Warmer",
      titleDescription: "Fluid Warmer",
    },
  },
  {
    id: "1953",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Level 1",
      modelTitle: "H-1129 NORMOFLO",
      name: "Level 1 H-1129 NORMOFLO Fluid Warming Device",
      titleDescription: "Fluid Warming Device",
    },
  },
  {
    id: "1373",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Level 1",
      modelTitle: "H-1200",
      name: "Level 1 H-1200 Fast Flow Fluid Warmer",
      titleDescription: "Fast Flow Fluid Warmer",
    },
  },
  {
    id: "1407",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Level 1",
      modelTitle: "HL-90",
      name: "Level 1 HL-90 Hotline Fluid Warmer",
      titleDescription: "Hotline Fluid Warmer",
    },
  },
  {
    id: "1460",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Level 1",
      modelTitle: "Hotline 3",
      name: "Level 1 Hotline 3 Blood and Fluid Warmer",
      titleDescription: "Blood and Fluid Warmer",
    },
  },
  {
    id: "1720",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Linvatec",
      modelTitle: "C7000",
      name: "Linvatec C7000 High Flow Laparaflator",
      titleDescription: "High Flow Laparaflator",
    },
  },
  {
    id: "2052",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Linvatec",
      modelTitle: "LIS 8430",
      name: "Linvatec LIS 8430 Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "1408",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "AcuPulse 40ST",
      name: "Lumenis AcuPulse 40ST CO2 Laser System",
      titleDescription: "CO2 Laser System",
    },
  },
  {
    id: "1531",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "AcuPulse DUO",
      name: "Lumenis AcuPulse DUO CO2 Laser",
      titleDescription: "CO2 Laser",
    },
  },
  {
    id: "1971",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "AcuSpot 712 L",
      name: "Lumenis AcuSpot 712 L Micromanipulator",
      titleDescription: "Micromanipulator",
    },
  },
  {
    id: "2813",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "Aluma GA-0012100",
      name: "Lumenis Aluma GA-0012100 RF Energy Laser",
      titleDescription: "RF Energy Laser",
    },
  },
  {
    id: "1534",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "Clearspot 160",
      name: "Lumenis Clearspot 160 Micromanipulator",
      titleDescription: "Micromanipulator",
    },
  },
  {
    id: "1632",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "MOSES PULSE 120H",
      name: "Lumenis MOSES PULSE 120H Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "1524",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "Novus Spectra",
      name: "Lumenis Novus Spectra Integrated Laser System",
      titleDescription: "Integrated Laser System",
    },
  },
  {
    id: "2814",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "One",
      name: "Lumenis One Laser",
      titleDescription: "Laser",
    },
  },
  {
    id: "1784",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "Pulse 100H",
      name: "Lumenis Pulse 100H Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "2216",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "UltraPulse DUO",
      name: "Lumenis UltraPulse DUO CO2 Laser",
      titleDescription: "CO2 Laser",
    },
  },
  {
    id: "50",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "UltraPulse ENCORE",
      name: "Lumenis UltraPulse ENCORE Fractional CO2 Laser",
      titleDescription: "Fractional CO2 Laser",
    },
  },
  {
    id: "1493",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "UltraPulse Surgitouch",
      name: "Lumenis UltraPulse Surgitouch CO2 Laser",
      titleDescription: "CO2 Laser",
    },
  },
  {
    id: "1017",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "VersaPulse 60W",
      name: "Lumenis VersaPulse 60W Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "1016",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Lumenis",
      modelTitle: "VersaPulse Powersuite 100W",
      name: "Lumenis VersaPulse Powersuite 100W Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "1150",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Luxtec Corp",
      modelTitle: "1900",
      name: "Luxtec Corp 1900 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "51",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Luxtec Corp",
      modelTitle: "9100",
      name: "Luxtec Corp 9100 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "193",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Luxtec Corp",
      modelTitle: "9300XDP",
      name: "Luxtec Corp 9300XDP Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1300",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Luxtec Corp",
      modelTitle: "9300XSP",
      name: "Luxtec Corp 9300XSP 300 Watt Xenon Light Source ",
      titleDescription: "300 Watt Xenon Light Source ",
    },
  },
  {
    id: "969",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Luxtec Corp",
      modelTitle: "LX300",
      name: "Luxtec Corp LX300 300W Xenon Light Source",
      titleDescription: "300W Xenon Light Source",
    },
  },
  {
    id: "1051",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Luxtec Corp",
      modelTitle: "MLX",
      name: "Luxtec Corp MLX 300W Xenon Light Source",
      titleDescription: "300W Xenon Light Source",
    },
  },
  {
    id: "1772",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Luxtec Corp",
      modelTitle: "Universal Series 1600",
      name: "Luxtec Corp Universal Series 1600 Fiber Optic Light Source",
      titleDescription: "Fiber Optic Light Source",
    },
  },
  {
    id: "1653",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "M.D. Resource",
      modelTitle: "K Pump",
      name: "M.D. Resource K Pump Tumescent Liposuction Infiltration Pump",
      titleDescription: "Tumescent Liposuction Infiltration Pump",
    },
  },
  {
    id: "1374",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "M.D. Resource",
      modelTitle: "LS2-SP",
      name: "M.D. Resource LS2-SP Single Port Liposuction Aspirator",
      titleDescription: "Single Port Liposuction Aspirator",
    },
  },
  {
    id: "1855",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MAQUET",
      modelTitle: "1132.13B2",
      name: "MAQUET 1132.13B2 Mobile Surgical Table",
      titleDescription: "Mobile Surgical Table",
    },
  },
  {
    id: "1055",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MAQUET",
      modelTitle: "CARDIOSAVE Hybrid",
      name: "MAQUET CARDIOSAVE Hybrid Circulatory Assist",
      titleDescription: "Circulatory Assist",
    },
  },
  {
    id: "2345",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MAQUET",
      modelTitle: "Servo i",
      name: "MAQUET Servo i ICU Ventilator",
      titleDescription: "ICU Ventilator",
    },
  },
  {
    id: "2439",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MAQUET",
      modelTitle: "Servo u",
      name: "MAQUET Servo u ICU Ventilator",
      titleDescription: "ICU Ventilator",
    },
  },
  {
    id: "1330",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MAQUET",
      modelTitle: "VASOVIEW HEMOPRO",
      name: "MAQUET VASOVIEW HEMOPRO Power Supply",
      titleDescription: "Power Supply",
    },
  },
  {
    id: "1690",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MIRA",
      modelTitle: "TR3000",
      name: "MIRA TR3000 Ophthalmic Diathermy",
      titleDescription: "Ophthalmic Diathermy",
    },
  },
  {
    id: "1985",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MIRA",
      modelTitle: "TR4000",
      name: "MIRA TR4000 Ophthalmic Diathermy Unit",
      titleDescription: "Ophthalmic Diathermy Unit",
    },
  },
  {
    id: "2394",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MTRE",
      modelTitle: "CritiCool",
      name: "MTRE CritiCool Cooling Therapy System",
      titleDescription: "Cooling Therapy System",
    },
  },
  {
    id: "321",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mammotome",
      modelTitle: "Neoprobe - Model 2300",
      name: "Mammotome Neoprobe - Model 2300 Gamma Detection System",
      titleDescription: "Gamma Detection System",
    },
  },
  {
    id: "1715",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MedXchange",
      modelTitle: "DRS01",
      name: "MedXchange DRS01 Image Management System",
      titleDescription: "Image Management System",
    },
  },
  {
    id: "1551",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mederi Therapeutics",
      modelTitle: "MDRF1",
      name: "Mederi Therapeutics MDRF1 Generator",
      titleDescription: "Generator",
    },
  },
  {
    id: "498",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medrad",
      modelTitle: "Mark V ProVis",
      name: "Medrad Mark V ProVis Angiographic Injection System",
      titleDescription: "Angiographic Injection System",
    },
  },
  {
    id: "615",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medrad",
      modelTitle: "Possis 5000A Injector",
      name: "Medrad Possis 5000A Injector AngioJet Ultra System Console",
      titleDescription: "AngioJet Ultra System Console",
    },
  },
  {
    id: "2021",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "221320",
      name: "Medtronic 221320 WET-FIELD Coagulator",
      titleDescription: "WET-FIELD Coagulator",
    },
  },
  {
    id: "545",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "40-405-1",
      name: "Medtronic 40-405-1 Aquamantys System Electrosurgical Unit",
      titleDescription: "Aquamantys System Electrosurgical Unit",
    },
  },
  {
    id: "1662",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "AEX",
      name: "Medtronic AEX Generator",
      titleDescription: "Generator",
    },
  },
  {
    id: "2779",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "Cart",
      name: "Medtronic Cart Mobile Dental",
      titleDescription: "Mobile Dental",
    },
  },
  {
    id: "1286",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "Endo-Scrub 2",
      name: "Medtronic Endo-Scrub 2 Scope Cleaner",
      titleDescription: "Scope Cleaner",
    },
  },
  {
    id: "1152",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "Endoscopic Suction Irrigator XHS",
      name: "Medtronic Endoscopic Suction Irrigator XHS Hydrodebrider System",
      titleDescription: "Hydrodebrider System",
    },
  },
  {
    id: "347",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "Fusion ENT",
      name: "Medtronic Fusion ENT Navigation System",
      titleDescription: "Navigation System",
    },
  },
  {
    id: "122",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "Integrated Power Console IPC",
      name: "Medtronic Integrated Power Console IPC Surgical Power Console",
      titleDescription: "Surgical Power Console",
    },
  },
  {
    id: "2220",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "M4",
      name: "Medtronic M4 Microdebrider",
      titleDescription: "Microdebrider",
    },
  },
  {
    id: "2328",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "M5",
      name: "Medtronic M5 Microdebrider",
      titleDescription: "Microdebrider",
    },
  },
  {
    id: "182",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "Midas Rex Legend",
      name: "Medtronic Midas Rex Legend Surgical Drill",
      titleDescription: "Surgical Drill",
    },
  },
  {
    id: "3025",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "NIM Vital",
      name: "Medtronic NIM Vital Nerve Monitoring System",
      titleDescription: "Nerve Monitoring System",
    },
  },
  {
    id: "307",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "NIM-Response 3.0",
      name: "Medtronic NIM-Response 3.0 Nerve Monitoring System",
      titleDescription: "Nerve Monitoring System",
    },
  },
  {
    id: "1292",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "O-Arm",
      name: "Medtronic O-Arm Mobile View Station",
      titleDescription: "Mobile View Station",
    },
  },
  {
    id: "989",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "O-Arm",
      name: "Medtronic O-Arm Surgical Imaging System",
      titleDescription: "Surgical Imaging System",
    },
  },
  {
    id: "352",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "PULSAR II Generator",
      name: "Medtronic PULSAR II Generator Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "324",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "StealthStation S7",
      name: "Medtronic StealthStation S7 Surgical Navigation System",
      titleDescription: "Surgical Navigation System",
    },
  },
  {
    id: "1824",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "StealthStation S8",
      name: "Medtronic StealthStation S8 Surgical Navigation System",
      titleDescription: "Surgical Navigation System",
    },
  },
  {
    id: "492",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "XPS 3000",
      name: "Medtronic XPS 3000 Surgical Drill System",
      titleDescription: "Surgical Drill System",
    },
  },
  {
    id: "1337",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Medtronic",
      modelTitle: "autoLog",
      name: "Medtronic autoLog Autotransfusion System",
      titleDescription: "Autotransfusion System",
    },
  },
  {
    id: "1057",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Megadyne",
      modelTitle: "Mega Vac",
      name: "Megadyne Mega Vac Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "1775",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Megadyne",
      modelTitle: "Mega Vac Plus",
      name: "Megadyne Mega Vac Plus Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "280",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MicroAire Surgical Instruments",
      modelTitle: "1020 Console",
      name: "MicroAire Surgical Instruments 1020 Console Power Assisted Liposuction System",
      titleDescription: "Power Assisted Liposuction System",
    },
  },
  {
    id: "1332",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MicroAire Surgical Instruments",
      modelTitle: "1025 Console",
      name: "MicroAire Surgical Instruments 1025 Console Power Assisted Liposuction System",
      titleDescription: "Power Assisted Liposuction System",
    },
  },
  {
    id: "1655",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "MicroAire Surgical Instruments",
      modelTitle: "5020 Console",
      name: "MicroAire Surgical Instruments 5020 Console Power Assisted Liposuction System",
      titleDescription: "Power Assisted Liposuction System",
    },
  },
  {
    id: "1348",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Microtek Medical",
      modelTitle: "TCT-6LW",
      name: "Microtek Medical TCT-6LW IntraTemp Fluid Warming Unit",
      titleDescription: "IntraTemp Fluid Warming Unit",
    },
  },
  {
    id: "1676",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Midmark",
      modelTitle: "Ritter 354-022",
      name: "Midmark Ritter 354-022 Procedure Light",
      titleDescription: "Procedure Light",
    },
  },
  {
    id: "1675",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Midmark",
      modelTitle: "Ritter 355-022",
      name: "Midmark Ritter 355-022 Portable OR Light",
      titleDescription: "Portable OR Light",
    },
  },
  {
    id: "1059",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Miller Medical",
      modelTitle: "Titan",
      name: "Miller Medical Titan Power Aspirator",
      titleDescription: "Power Aspirator",
    },
  },
  {
    id: "1509",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Miramar Labs",
      modelTitle: "miraDry",
      name: "Miramar Labs miraDry Electromagnetic Energy System",
      titleDescription: "Electromagnetic Energy System",
    },
  },
  {
    id: "1639",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Misonix",
      modelTitle: "BCM-GN",
      name: "Misonix BCM-GN Bone Scalpel",
      titleDescription: "Bone Scalpel",
    },
  },
  {
    id: "1910",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Misonix",
      modelTitle: "SonaStar M360",
      name: "Misonix SonaStar M360 Ultrasonic Surgical Aspiration System",
      titleDescription: "Ultrasonic Surgical Aspiration System",
    },
  },
  {
    id: "1914",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "20 MHz Intraoperative ",
      name: "Mizuho OSI 20 MHz Intraoperative  Doppler System",
      titleDescription: "Doppler System",
    },
  },
  {
    id: "269",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "5803",
      name: "Mizuho OSI 5803 Jackson Table Advanced Control Modular Base",
      titleDescription: "Jackson Table Advanced Control Modular Base",
    },
  },
  {
    id: "143",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "6800 Allegro",
      name: "Mizuho OSI 6800 Allegro Imaging Table",
      titleDescription: "Imaging Table",
    },
  },
  {
    id: "325",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "Hana - 6875",
      name: "Mizuho OSI Hana - 6875 Orthopedic Surgery Table",
      titleDescription: "Orthopedic Surgery Table",
    },
  },
  {
    id: "1314",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "INSITE - 6844",
      name: "Mizuho OSI INSITE - 6844 Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "992",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "Orthopedic Trauma Top",
      name: "Mizuho OSI Orthopedic Trauma Top Modular Spinal Table System",
      titleDescription: "Modular Spinal Table System",
    },
  },
  {
    id: "1434",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "ProFx",
      name: "Mizuho OSI ProFx Attachment Cart",
      titleDescription: "Attachment Cart",
    },
  },
  {
    id: "1433",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "ProFx",
      name: "Mizuho OSI ProFx Pelvic Reconstruction Orthopedic Fracture Table",
      titleDescription: "Pelvic Reconstruction Orthopedic Fracture Table",
    },
  },
  {
    id: "1349",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "Radiolucent Wilson Frame",
      name: "Mizuho OSI Radiolucent Wilson Frame Surgical Table Attachment",
      titleDescription: "Surgical Table Attachment",
    },
  },
  {
    id: "2282",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "Trios 7803",
      name: "Mizuho OSI Trios 7803 Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1315",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Mizuho OSI",
      modelTitle: "proAXIS",
      name: "Mizuho OSI proAXIS Spinal Surgery Table",
      titleDescription: "Spinal Surgery Table",
    },
  },
  {
    id: "2161",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Morgan MEDesign",
      modelTitle: "Positioner Pro EXLT-FW",
      name: "Morgan MEDesign Positioner Pro EXLT-FW Imaging Table",
      titleDescription: "Imaging Table",
    },
  },
  {
    id: "1975",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "NDS Surgical",
      modelTitle: "Radiance G2",
      name: "NDS Surgical Radiance G2 19” Monitor",
      titleDescription: "19” Monitor",
    },
  },
  {
    id: "2445",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Natus Medical",
      modelTitle: "Bio-logic Navigator Pro",
      name: "Natus Medical Bio-logic Navigator Pro Auditory Evoked Potentials hearing tester",
      titleDescription: "Auditory Evoked Potentials hearing tester",
    },
  },
  {
    id: "1987",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Natus Medical",
      modelTitle: "Model 45",
      name: "Natus Medical Model 45 Warm-Lamp ",
      titleDescription: "Warm-Lamp ",
    },
  },
  {
    id: "2350",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Natus Medical",
      modelTitle: "Olympic Vac-Pac",
      name: "Natus Medical Olympic Vac-Pac Size 32 Patient Positioner",
      titleDescription: "Size 32 Patient Positioner",
    },
  },
  {
    id: "7",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Nellcor Puritan Bennett",
      modelTitle: "840",
      name: "Nellcor Puritan Bennett 840 Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "2683",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Nellcor Puritan Bennett",
      modelTitle: "980",
      name: "Nellcor Puritan Bennett 980 5 ICU Ventilators",
      titleDescription: "5 ICU Ventilators",
    },
  },
  {
    id: "2412",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Nellcor Puritan Bennett",
      modelTitle: "980",
      name: "Nellcor Puritan Bennett 980 ICU Ventilator",
      titleDescription: "ICU Ventilator",
    },
  },
  {
    id: "1501",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "NeoMedix",
      modelTitle: "550014 Trabectome I/A Console",
      name: "NeoMedix 550014 Trabectome I/A Console Aspiration Pump",
      titleDescription: "Aspiration Pump",
    },
  },
  {
    id: "1500",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "NeoMedix",
      modelTitle: "550036-01",
      name: "NeoMedix 550036-01 High Frequency Generator",
      titleDescription: "High Frequency Generator",
    },
  },
  {
    id: "1768",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "NeoMedix",
      modelTitle: "550047-01",
      name: "NeoMedix 550047-01 Trabectome Radiofrequency Generator/LP",
      titleDescription: "Trabectome Radiofrequency Generator/LP",
    },
  },
  {
    id: "132",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Neoprobe Corp",
      modelTitle: "NEO 2000",
      name: "Neoprobe Corp NEO 2000 Probes/ Gamma Detection System",
      titleDescription: "Probes/ Gamma Detection System",
    },
  },
  {
    id: "1681",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "NeuroTherm",
      modelTitle: "NT2000",
      name: "NeuroTherm NT2000 RF Generator",
      titleDescription: "RF Generator",
    },
  },
  {
    id: "1710",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "NeuroTherm",
      modelTitle: "NT7210771",
      name: "NeuroTherm NT7210771 Controlled Disc Stimulation Control Unit",
      titleDescription: "Controlled Disc Stimulation Control Unit",
    },
  },
  {
    id: "2025",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Newport Medical Products",
      modelTitle: "Accents",
      name: "Newport Medical Products Accents Tattoo Removal Machine",
      titleDescription: "Tattoo Removal Machine",
    },
  },
  {
    id: "2873",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Nidek",
      modelTitle: "ARK -560A",
      name: "Nidek ARK -560A Keratometer",
      titleDescription: "Keratometer",
    },
  },
  {
    id: "2364",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Nidek",
      modelTitle: "MC-500",
      name: "Nidek MC-500 Multicolor Laser Photocoagulator",
      titleDescription: "Multicolor Laser Photocoagulator",
    },
  },
  {
    id: "2745",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Nihon Kohden",
      modelTitle: "NKV-550",
      name: "Nihon Kohden NKV-550 Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "1778",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Novadaq",
      modelTitle: "Pinpoint Fluorescence",
      name: "Novadaq Pinpoint Fluorescence Imaging System",
      titleDescription: "Imaging System",
    },
  },
  {
    id: "1075",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OR Solutions",
      modelTitle: "Hush Slush ORS-1075HS",
      name: "OR Solutions Hush Slush ORS-1075HS Surgical Slush Machine/Fluid Warmer",
      titleDescription: "Surgical Slush Machine/Fluid Warmer",
    },
  },
  {
    id: "1076",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OR Solutions",
      modelTitle: "ORS 2038D",
      name: "OR Solutions ORS 2038D Solution Warmer",
      titleDescription: "Solution Warmer",
    },
  },
  {
    id: "1077",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OR Solutions",
      modelTitle: "ORS 2057D",
      name: "OR Solutions ORS 2057D Solution Warmer",
      titleDescription: "Solution Warmer",
    },
  },
  {
    id: "927",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OR Solutions",
      modelTitle: "ORS 2066R",
      name: "OR Solutions ORS 2066R Solution Warmer",
      titleDescription: "Solution Warmer",
    },
  },
  {
    id: "1767",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OR Solutions",
      modelTitle: "ORS 2094R",
      name: "OR Solutions ORS 2094R Solution Warmer",
      titleDescription: "Solution Warmer",
    },
  },
  {
    id: "1754",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OR Solutions",
      modelTitle: "ORS-2058D",
      name: "OR Solutions ORS-2058D Solution Warmer",
      titleDescription: "Solution Warmer",
    },
  },
  {
    id: "496",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "ORATEC",
      modelTitle: "Vulcan EAS",
      name: "ORATEC Vulcan EAS ElectroThermal Arthroscopy System",
      titleDescription: "ElectroThermal Arthroscopy System",
    },
  },
  {
    id: "2374",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ohio Medical",
      modelTitle: "Instavac 1",
      name: "Ohio Medical Instavac 1 Portable Suction Pump",
      titleDescription: "Portable Suction Pump",
    },
  },
  {
    id: "1610",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympic Medical",
      modelTitle: "15",
      name: "Olympic Medical 15 Warm Lamp",
      titleDescription: "Warm Lamp",
    },
  },
  {
    id: "1457",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympic Medical",
      modelTitle: "41",
      name: "Olympic Medical 41 Warm-Lamp",
      titleDescription: "Warm-Lamp",
    },
  },
  {
    id: "1788",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "BX46F",
      name: "Olympus BX46F Microscope",
      titleDescription: "Microscope",
    },
  },
  {
    id: "2801",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "CLH-250",
      name: "Olympus CLH-250 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1157",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "CLV-S190",
      name: "Olympus CLV-S190 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1384",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "CLV-S40",
      name: "Olympus CLV-S40 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "2063",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "CelonLab",
      name: "Olympus CelonLab ENT Generator",
      titleDescription: "ENT Generator",
    },
  },
  {
    id: "1502",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Diego Elite",
      name: "Olympus Diego Elite Multidebrider",
      titleDescription: "Multidebrider",
    },
  },
  {
    id: "1787",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EBUS",
      name: "Olympus EBUS Mobile Workstation",
      titleDescription: "Mobile Workstation",
    },
  },
  {
    id: "370",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "ESG-400",
      name: "Olympus ESG-400 HF Generator",
      titleDescription: "HF Generator",
    },
  },
  {
    id: "2083",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EU-ME1",
      name: "Olympus EU-ME1 Universal Ultrasound Processor",
      titleDescription: "Universal Ultrasound Processor",
    },
  },
  {
    id: "1352",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EVIS EXERA CLV-160",
      name: "Olympus EVIS EXERA CLV-160 Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "1418",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EVIS EXERA CLV-190",
      name: "Olympus EVIS EXERA CLV-190 Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "1351",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EVIS EXERA CV-160",
      name: "Olympus EVIS EXERA CV-160 Video processor",
      titleDescription: "Video processor",
    },
  },
  {
    id: "1376",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EVIS EXERA II CLV-180",
      name: "Olympus EVIS EXERA II CLV-180 Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "1158",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EVIS EXERA II CV-180",
      name: "Olympus EVIS EXERA II CV-180 Video System Center",
      titleDescription: "Video System Center",
    },
  },
  {
    id: "1906",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EVIS EXERA III CLV-190",
      name: "Olympus EVIS EXERA III CLV-190 Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "1066",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "EVIS EXERA III CV-190",
      name: "Olympus EVIS EXERA III CV-190 Video Processor",
      titleDescription: "Video Processor",
    },
  },
  {
    id: "2373",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Empower H100",
      name: "Olympus Empower H100 Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "2188",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Empower H35",
      name: "Olympus Empower H35 Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "2353",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Empower H65",
      name: "Olympus Empower H65 Holmium Laser",
      titleDescription: "Holmium Laser",
    },
  },
  {
    id: "1042",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Gyrus ACMI CyberWand",
      name: "Olympus Gyrus ACMI CyberWand Dual Ultrasonic Lithotripsy System",
      titleDescription: "Dual Ultrasonic Lithotripsy System",
    },
  },
  {
    id: "1594",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "HysteroFlow II",
      name: "Olympus HysteroFlow II Fluid Management System",
      titleDescription: "Fluid Management System",
    },
  },
  {
    id: "1422",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "IMH-10",
      name: "Olympus IMH-10 Image Management Hub",
      titleDescription: "Image Management Hub",
    },
  },
  {
    id: "1717",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "LCCONS100",
      name: "Olympus LCCONS100 Lens Cleaner System",
      titleDescription: "Lens Cleaner System",
    },
  },
  {
    id: "1386",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OEP-3",
      name: "Olympus OEP-3 Color Video Printer",
      titleDescription: "Color Video Printer",
    },
  },
  {
    id: "1160",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OEP-4",
      name: "Olympus OEP-4 HD Video Printer",
      titleDescription: "HD Video Printer",
    },
  },
  {
    id: "1067",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OEP-5",
      name: "Olympus OEP-5 Color Video Printer",
      titleDescription: "Color Video Printer",
    },
  },
  {
    id: "1354",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OEV-143",
      name: "Olympus OEV-143 Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "1068",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OEV-261H LCD",
      name: "Olympus OEV-261H LCD HD Monitors",
      titleDescription: "HD Monitors",
    },
  },
  {
    id: "1382",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OEV203",
      name: "Olympus OEV203 Color Video Monitor",
      titleDescription: "Color Video Monitor",
    },
  },
  {
    id: "1417",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OEV262H",
      name: "Olympus OEV262H High Definition LCD Monitor",
      titleDescription: "High Definition LCD Monitor",
    },
  },
  {
    id: "1161",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OFP",
      name: "Olympus OFP Endoscopic Flushing Pump",
      titleDescription: "Endoscopic Flushing Pump",
    },
  },
  {
    id: "1069",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OFP-2",
      name: "Olympus OFP-2 Flushing Pumps",
      titleDescription: "Flushing Pumps",
    },
  },
  {
    id: "1385",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OTV-S7",
      name: "Olympus OTV-S7 Digital Processor",
      titleDescription: "Digital Processor",
    },
  },
  {
    id: "1070",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "OTV-SI",
      name: "Olympus OTV-SI Video System",
      titleDescription: "Video System",
    },
  },
  {
    id: "1869",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "SPL-G ShockPulse - SE",
      name: "Olympus SPL-G ShockPulse - SE Lithotriptor",
      titleDescription: "Lithotriptor",
    },
  },
  {
    id: "2743",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Shockpulse Transducer Set",
      name: "Olympus Shockpulse Transducer Set ",
      titleDescription: null,
    },
  },
  {
    id: "3119",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Soltive Premium",
      name: "Olympus Soltive Premium Thulium Laser",
      titleDescription: "Thulium Laser",
    },
  },
  {
    id: "309",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "SurgMaster UES-40",
      name: "Olympus SurgMaster UES-40 Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "168",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Tower",
      name: "Olympus Tower Video",
      titleDescription: "Video",
    },
  },
  {
    id: "1491",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Tower",
      name: "Olympus Tower Urology",
      titleDescription: "Urology",
    },
  },
  {
    id: "2133",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Tower",
      name: "Olympus Tower Laparoscopic Dual CLV/CV 190 pair",
      titleDescription: "Laparoscopic Dual CLV/CV 190 pair",
    },
  },
  {
    id: "1706",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Tower",
      name: "Olympus Tower Cystoscopy",
      titleDescription: "Cystoscopy",
    },
  },
  {
    id: "2875",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Tower",
      name: "Olympus Tower GI Video",
      titleDescription: "GI Video",
    },
  },
  {
    id: "1071",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "UCR",
      name: "Olympus UCR CO2 Regulation Unit",
      titleDescription: "CO2 Regulation Unit",
    },
  },
  {
    id: "1383",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "UHI-3",
      name: "Olympus UHI-3 High Flow Insufflator",
      titleDescription: "High Flow Insufflator",
    },
  },
  {
    id: "1420",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "UHI-4",
      name: "Olympus UHI-4 High Flow Insufflation",
      titleDescription: "High Flow Insufflation",
    },
  },
  {
    id: "1073",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "USG-400 THUNDERBEAT",
      name: "Olympus USG-400 THUNDERBEAT Generator",
      titleDescription: "Generator",
    },
  },
  {
    id: "1947",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Visera Elite CLV-S190",
      name: "Olympus Visera Elite CLV-S190 Video System",
      titleDescription: "Video System",
    },
  },
  {
    id: "1421",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Olympus",
      modelTitle: "Visera Elite OTV-S190",
      name: "Olympus Visera Elite OTV-S190 Video System",
      titleDescription: "Video System",
    },
  },
  {
    id: "613",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OmniGuide Surgical",
      modelTitle: "C-LAS",
      name: "OmniGuide Surgical C-LAS CO2 Laser",
      titleDescription: "CO2 Laser",
    },
  },
  {
    id: "2452",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Optos",
      modelTitle: "California",
      name: "Optos California Retinal Imaging System",
      titleDescription: "Retinal Imaging System",
    },
  },
  {
    id: "362",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "OrthoScan",
      modelTitle: "HD",
      name: "OrthoScan HD Mini C-Arm",
      titleDescription: "Mini C-Arm",
    },
  },
  {
    id: "1905",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Parks Medical Electronics",
      modelTitle: "614-B",
      name: "Parks Medical Electronics 614-B Obstetrical Doppler",
      titleDescription: "Obstetrical Doppler",
    },
  },
  {
    id: "1927",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Parks Medical Electronics",
      modelTitle: "810-A",
      name: "Parks Medical Electronics 810-A Doppler Flowmeter",
      titleDescription: "Doppler Flowmeter",
    },
  },
  {
    id: "1886",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Parks Medical Electronics",
      modelTitle: "811-B",
      name: "Parks Medical Electronics 811-B Doppler Flow Detector",
      titleDescription: "Doppler Flow Detector",
    },
  },
  {
    id: "1079",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Parks Medical Electronics",
      modelTitle: "811-BL",
      name: "Parks Medical Electronics 811-BL Ultrasonic Doppler Flow Detector",
      titleDescription: "Ultrasonic Doppler Flow Detector",
    },
  },
  {
    id: "1989",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Parks Medical Electronics",
      modelTitle: "811-BTS",
      name: "Parks Medical Electronics 811-BTS Ultrasonic Doppler Flow Detector",
      titleDescription: "Ultrasonic Doppler Flow Detector",
    },
  },
  {
    id: "1969",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Parks Medical Electronics",
      modelTitle: "812",
      name: "Parks Medical Electronics 812 Doppler Flow Detector",
      titleDescription: "Doppler Flow Detector",
    },
  },
  {
    id: "1887",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Parks Medical Electronics",
      modelTitle: "915-BL",
      name: "Parks Medical Electronics 915-BL Dual-Frequency Doppler",
      titleDescription: "Dual-Frequency Doppler",
    },
  },
  {
    id: "1838",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Pedigo",
      modelTitle: "CDS-242",
      name: "Pedigo CDS-242 Case Cart",
      titleDescription: "Case Cart",
    },
  },
  {
    id: "2200",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Pedigo",
      modelTitle: "Unknown",
      name: "Pedigo Unknown Ring Stand",
      titleDescription: "Ring Stand",
    },
  },
  {
    id: "2451",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Pentacam",
      modelTitle: "Oculus",
      name: "Pentacam Oculus Diagnostic Camera",
      titleDescription: "Diagnostic Camera",
    },
  },
  {
    id: "3036",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Pentax",
      modelTitle: "EPK-i",
      name: "Pentax EPK-i Video Processor",
      titleDescription: "Video Processor",
    },
  },
  {
    id: "1714",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Pentax",
      modelTitle: "LH-150PC",
      name: "Pentax LH-150PC Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1363",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips",
      modelTitle: "BV Pulsera",
      name: "Philips BV Pulsera Mobile View Station",
      titleDescription: "Mobile View Station",
    },
  },
  {
    id: "326",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips",
      modelTitle: "BV Pulsera",
      name: "Philips BV Pulsera C-Arm",
      titleDescription: "C-Arm",
    },
  },
  {
    id: "2837",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips",
      modelTitle: "GL30FL Gleamer ",
      name: "Philips GL30FL Gleamer  Examination Light",
      titleDescription: "Examination Light",
    },
  },
  {
    id: "1762",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips",
      modelTitle: "HD11",
      name: "Philips HD11 Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "1089",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips",
      modelTitle: "IntelliVue MP30",
      name: "Philips IntelliVue MP30 Patient Monitor",
      titleDescription: "Patient Monitor",
    },
  },
  {
    id: "1921",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips",
      modelTitle: "iE33",
      name: "Philips iE33 Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "2499",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips Respironics",
      modelTitle: "Trilogy 100",
      name: "Philips Respironics Trilogy 100 Portable Ventilator",
      titleDescription: "Portable Ventilator",
    },
  },
  {
    id: "2417",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Philips Respironics",
      modelTitle: "V60",
      name: "Philips Respironics V60 Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "1865",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Pilling Surgical",
      modelTitle: "529300",
      name: "Pilling Surgical 529300 300W Xenon Light Source",
      titleDescription: "300W Xenon Light Source",
    },
  },
  {
    id: "1172",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Pilling Surgical",
      modelTitle: "XenaLight XLT",
      name: "Pilling Surgical XenaLight XLT Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1694",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Products For Medicine",
      modelTitle: "XL 300",
      name: "Products For Medicine XL 300 White Sun Xenon Light Source",
      titleDescription: "White Sun Xenon Light Source",
    },
  },
  {
    id: "546",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "RMD Instruments",
      modelTitle: "Navigator",
      name: "RMD Instruments Navigator GPS System",
      titleDescription: "GPS System",
    },
  },
  {
    id: "1766",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "RadioTherapeutics",
      modelTitle: "RF 3000",
      name: "RadioTherapeutics RF 3000 Radiofrequency Generator",
      titleDescription: "Radiofrequency Generator",
    },
  },
  {
    id: "1558",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Radlink",
      modelTitle: "GPS 2200",
      name: "Radlink GPS 2200 Imaging Device",
      titleDescription: "Imaging Device",
    },
  },
  {
    id: "1735",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "2232",
      name: "Richard Wolf Medical Instruments 2232 Tem-Pump",
      titleDescription: "Tem-Pump",
    },
  },
  {
    id: "1506",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "2232",
      name: "Richard Wolf Medical Instruments 2232 Laparo-CO2-PNEU",
      titleDescription: "Laparo-CO2-PNEU",
    },
  },
  {
    id: "1173",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "2271",
      name: "Richard Wolf Medical Instruments 2271 Ultrasound Lithotripter",
      titleDescription: "Ultrasound Lithotripter",
    },
  },
  {
    id: "1669",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "4046.00",
      name: "Richard Wolf Medical Instruments 4046.00 Fiber Light Projector",
      titleDescription: "Fiber Light Projector",
    },
  },
  {
    id: "120",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "Bipolar Generator 2352",
      name: "Richard Wolf Medical Instruments Bipolar Generator 2352 Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1685",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "D4002U",
      name: "Richard Wolf Medical Instruments D4002U Fiber Optic Light Source",
      titleDescription: "Fiber Optic Light Source",
    },
  },
  {
    id: "1977",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "LED 1.1",
      name: "Richard Wolf Medical Instruments LED 1.1 Endolight",
      titleDescription: "Endolight",
    },
  },
  {
    id: "1174",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "MorcePower Plus",
      name: "Richard Wolf Medical Instruments MorcePower Plus Morcellator Control Unit",
      titleDescription: "Morcellator Control Unit",
    },
  },
  {
    id: "1976",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Richard Wolf Medical Instruments",
      modelTitle: "Performance HD",
      name: "Richard Wolf Medical Instruments Performance HD Endocam",
      titleDescription: "Endocam",
    },
  },
  {
    id: "2190",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "SAVISCOUT",
      modelTitle: "SSC-01",
      name: "SAVISCOUT SSC-01 Radar Localization System",
      titleDescription: "Radar Localization System",
    },
  },
  {
    id: "1099",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "SSCOR",
      modelTitle: "DUET Flat Black - 2314",
      name: "SSCOR DUET Flat Black - 2314 Aspirator",
      titleDescription: "Aspirator",
    },
  },
  {
    id: "1322",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "4085",
      name: "STERIS 4085 Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1616",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "5085",
      name: "STERIS 5085 Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "919",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "AMSCO 3085 SP",
      name: "STERIS AMSCO 3085 SP Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1431",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "AMSCO Examiner 10",
      name: "STERIS AMSCO Examiner 10 Lighting System",
      titleDescription: "Lighting System",
    },
  },
  {
    id: "371",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "AMSCO OrthoVision",
      name: "STERIS AMSCO OrthoVision Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "2189",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "CO2EFFICIENT",
      name: "STERIS CO2EFFICIENT Endoscopic Insufflator",
      titleDescription: "Endoscopic Insufflator",
    },
  },
  {
    id: "1299",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "Cmax",
      name: "STERIS Cmax Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1765",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "Harmony LED585",
      name: "STERIS Harmony LED585 Portable Surgical Light",
      titleDescription: "Portable Surgical Light",
    },
  },
  {
    id: "1933",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "MOBILE LED 585 ",
      name: "STERIS MOBILE LED 585  Portable Surgical Light",
      titleDescription: "Portable Surgical Light",
    },
  },
  {
    id: "2830",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "OT 1000",
      name: "STERIS OT 1000 Orthopedic Surgical Table",
      titleDescription: "Orthopedic Surgical Table",
    },
  },
  {
    id: "196",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "Power-lift",
      name: "STERIS Power-lift Stirrups",
      titleDescription: "Stirrups",
    },
  },
  {
    id: "195",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "STERIS",
      modelTitle: "SurgiGraphic 6000",
      name: "STERIS SurgiGraphic 6000 Image Guided Surgical Table",
      titleDescription: "Image Guided Surgical Table",
    },
  },
  {
    id: "2012",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "SURGRX",
      modelTitle: "EnSeal RF-60",
      name: "SURGRX EnSeal RF-60 Generator",
      titleDescription: "Generator",
    },
  },
  {
    id: "2780",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sapphire",
      modelTitle: "Plus",
      name: "Sapphire Plus Curing Light",
      titleDescription: "Curing Light",
    },
  },
  {
    id: "1833",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Scale Tronix",
      modelTitle: "5002",
      name: "Scale Tronix 5002 Digital Scale",
      titleDescription: "Digital Scale",
    },
  },
  {
    id: "1748",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Schaerer",
      modelTitle: "Chick LP 10900",
      name: "Schaerer Chick LP 10900 Fracture Table",
      titleDescription: "Fracture Table",
    },
  },
  {
    id: "1825",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "SenoRx",
      modelTitle: "Gamma Finder",
      name: "SenoRx Gamma Finder Gamma Detection System",
      titleDescription: "Gamma Detection System",
    },
  },
  {
    id: "1671",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sirona",
      modelTitle: "46 84 606 D 3302",
      name: "Sirona 46 84 606 D 3302 Digital X-Ray Machine",
      titleDescription: "Digital X-Ray Machine",
    },
  },
  {
    id: "1774",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "1700 Elite",
      name: "Skytron 1700 Elite Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "2324",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "3003 Impulse",
      name: "Skytron 3003 Impulse Imaging Surgical Table",
      titleDescription: "Imaging Surgical Table",
    },
  },
  {
    id: "277",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "3100A",
      name: "Skytron 3100A Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "917",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "3500 Elite",
      name: "Skytron 3500 Elite Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1464",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "3600B Ultraslide",
      name: "Skytron 3600B Ultraslide Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1612",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "6500 Elite",
      name: "Skytron 6500 Elite Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1467",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "6600",
      name: "Skytron 6600 Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1750",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "6700B",
      name: "Skytron 6700B Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1633",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "Aurora Four",
      name: "Skytron Aurora Four Surgical Light",
      titleDescription: "Surgical Light",
    },
  },
  {
    id: "1514",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "Elite 3002",
      name: "Skytron Elite 3002 Vascular Table",
      titleDescription: "Vascular Table",
    },
  },
  {
    id: "1705",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Skytron",
      modelTitle: "ST23",
      name: "Skytron ST23 Surgical OR Light",
      titleDescription: "Surgical OR Light",
    },
  },
  {
    id: "2046",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "560P",
      name: "Smith & Nephew 560P High Definition Camera System",
      titleDescription: "High Definition Camera System",
    },
  },
  {
    id: "2045",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "660HD",
      name: "Smith & Nephew 660HD Image Management System",
      titleDescription: "Image Management System",
    },
  },
  {
    id: "564",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "Arthoscopic Hip Patient Positioning",
      name: "Smith & Nephew Arthoscopic Hip Patient Positioning Hip Distraction Table Attachment",
      titleDescription: "Hip Distraction Table Attachment",
    },
  },
  {
    id: "65",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "DYONICS 300 XL",
      name: "Smith & Nephew DYONICS 300 XL Xenon Light Source",
      titleDescription: "Xenon Light Source",
    },
  },
  {
    id: "479",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "DYONICS POWER",
      name: "Smith & Nephew DYONICS POWER Shaver System",
      titleDescription: "Shaver System",
    },
  },
  {
    id: "519",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "DYONICS POWER II ",
      name: "Smith & Nephew DYONICS POWER II  Control System",
      titleDescription: "Control System",
    },
  },
  {
    id: "500",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "Hysteroscopic",
      name: "Smith & Nephew Hysteroscopic Morcellation System Control Unit",
      titleDescription: "Morcellation System Control Unit",
    },
  },
  {
    id: "501",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "Hysteroscopic",
      name: "Smith & Nephew Hysteroscopic Hysteroscopic Fluid Management System",
      titleDescription: "Hysteroscopic Fluid Management System",
    },
  },
  {
    id: "621",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "SPIDER2",
      name: "Smith & Nephew SPIDER2 Limb Positioner",
      titleDescription: "Limb Positioner",
    },
  },
  {
    id: "486",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "TRUCLEAR System",
      name: "Smith & Nephew TRUCLEAR System Uterine Tissue Removal System",
      titleDescription: "Uterine Tissue Removal System",
    },
  },
  {
    id: "1718",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "Turbo 7000 Drill",
      name: "Smith & Nephew Turbo 7000 Drill Shaver System",
      titleDescription: "Shaver System",
    },
  },
  {
    id: "3020",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "Unknown",
      name: "Smith & Nephew Unknown Hip Positioning System",
      titleDescription: "Hip Positioning System",
    },
  },
  {
    id: "310",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smith & Nephew",
      modelTitle: "VULCAN RF",
      name: "Smith & Nephew VULCAN RF Electrothermal Arthroscopy System (EAS)",
      titleDescription: "Electrothermal Arthroscopy System (EAS)",
    },
  },
  {
    id: "1358",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smiths Medical",
      modelTitle: "HI-2",
      name: "Smiths Medical HI-2 Pressure chamber",
      titleDescription: "Pressure chamber",
    },
  },
  {
    id: "1096",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smiths Medical",
      modelTitle: "HL-390",
      name: "Smiths Medical HL-390 HOTLINE 3 Fluid Warmer",
      titleDescription: "HOTLINE 3 Fluid Warmer",
    },
  },
  {
    id: "96",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smiths Medical",
      modelTitle: "HOTLINE HL-90",
      name: "Smiths Medical HOTLINE HL-90 Blood and Fluid Warming System",
      titleDescription: "Blood and Fluid Warming System",
    },
  },
  {
    id: "1310",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smiths Medical",
      modelTitle: "Level 1 H-1000",
      name: "Smiths Medical Level 1 H-1000 Fast Flow Fluid Warmer",
      titleDescription: "Fast Flow Fluid Warmer",
    },
  },
  {
    id: "1175",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smiths Medical",
      modelTitle: "Level 1 H-1100 NORMOFLO",
      name: "Smiths Medical Level 1 H-1100 NORMOFLO Fluid Warming Device",
      titleDescription: "Fluid Warming Device",
    },
  },
  {
    id: "1350",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smiths Medical",
      modelTitle: "Level 1 H-1129 NORMOFLO",
      name: "Smiths Medical Level 1 H-1129 NORMOFLO Fluid Warming Device",
      titleDescription: "Fluid Warming Device",
    },
  },
  {
    id: "1095",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Smiths Medical",
      modelTitle: "Level 1 H-1200 Fast Flow",
      name: "Smiths Medical Level 1 H-1200 Fast Flow Fluid Warmer",
      titleDescription: "Fluid Warmer",
    },
  },
  {
    id: "2376",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Solta Medical",
      modelTitle: "VentX",
      name: "Solta Medical VentX Liposuction Unit",
      titleDescription: "Liposuction Unit",
    },
  },
  {
    id: "1425",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "SonoSite",
      modelTitle: "180 Plus",
      name: "SonoSite 180 Plus Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "1504",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "SonoSite",
      modelTitle: "M-Turbo",
      name: "SonoSite M-Turbo Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "1477",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "SonoSite",
      modelTitle: "Titan",
      name: "SonoSite Titan Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "1178",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sony",
      modelTitle: "UP-DR80MD",
      name: "Sony UP-DR80MD Medical Grade A4 Printer",
      titleDescription: "Medical Grade A4 Printer",
    },
  },
  {
    id: "1098",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sorin Group",
      modelTitle: "3T",
      name: "Sorin Group 3T Heater-Cooler System",
      titleDescription: "Heater-Cooler System",
    },
  },
  {
    id: "1660",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sound Surgical Technologies",
      modelTitle: "VentX 2.0",
      name: "Sound Surgical Technologies VentX 2.0 Aspirator/Liposuction",
      titleDescription: "Aspirator/Liposuction",
    },
  },
  {
    id: "58",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sound Surgical Technologies",
      modelTitle: "VentX-1",
      name: "Sound Surgical Technologies VentX-1 Aspirator/Liposuction",
      titleDescription: "Aspirator/Liposuction",
    },
  },
  {
    id: "1592",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "St. Jude Medical",
      modelTitle: " MERLIN.NET™",
      name: "St. Jude Medical  MERLIN.NET™ PATIENT CARE NETWORK",
      titleDescription: "PATIENT CARE NETWORK",
    },
  },
  {
    id: "1611",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stackhouse",
      modelTitle: "AirSafe VersaVac 2",
      name: "Stackhouse AirSafe VersaVac 2 Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "1631",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stille",
      modelTitle: "ImageiQ",
      name: "Stille ImageiQ Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "1412",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Storz",
      modelTitle: "US-1",
      name: "Storz US-1 Surgical Microscope",
      titleDescription: "Surgical Microscope",
    },
  },
  {
    id: "2838",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Storz Medical",
      modelTitle: "HoLEP",
      name: "Storz Medical HoLEP Tray",
      titleDescription: "Tray",
    },
  },
  {
    id: "1934",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "1088 HD",
      name: "Stryker 1088 HD High Definition Camera",
      titleDescription: "High Definition Camera",
    },
  },
  {
    id: "1181",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "1188 HD",
      name: "Stryker 1188 HD Autoclavable 3-Chip Camera",
      titleDescription: "Autoclavable 3-Chip Camera",
    },
  },
  {
    id: "1102",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "1288 HD",
      name: "Stryker 1288 HD 3-Chip Endoscopic Camera",
      titleDescription: "3-Chip Endoscopic Camera",
    },
  },
  {
    id: "1182",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "1488 HD",
      name: "Stryker 1488 HD High Definition Camera Console",
      titleDescription: "High Definition Camera Console",
    },
  },
  {
    id: "2181",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "1588 AIM",
      name: "Stryker 1588 AIM Camera with Advanced Imaging Modality",
      titleDescription: "Camera with Advanced Imaging Modality",
    },
  },
  {
    id: "1782",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "40L Highflow Core",
      name: "Stryker 40L Highflow Core Insufflator",
      titleDescription: "Insufflator",
    },
  },
  {
    id: "1105",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "45L PneumoSure XL",
      name: "Stryker 45L PneumoSure XL Insufflator",
      titleDescription: "Insufflator",
    },
  },
  {
    id: "1571",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "986 CastVac",
      name: "Stryker 986 CastVac Cast Cutter System",
      titleDescription: "Cast Cutter System",
    },
  },
  {
    id: "965",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "AHTO",
      name: "Stryker AHTO Irrigation System",
      titleDescription: "Irrigation System",
    },
  },
  {
    id: "2013",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Berchtold Operon D860",
      name: "Stryker Berchtold Operon D860 Surgical Table",
      titleDescription: "Surgical Table",
    },
  },
  {
    id: "381",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Birthing Bed",
      name: "Stryker Birthing Bed Bed",
      titleDescription: "Bed",
    },
  },
  {
    id: "1538",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "CD-3",
      name: "Stryker CD-3 Small Bone Drill",
      titleDescription: "Small Bone Drill",
    },
  },
  {
    id: "2193",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "CORE 2 Console",
      name: "Stryker CORE 2 Console Power Instrument Driver",
      titleDescription: "Power Instrument Driver",
    },
  },
  {
    id: "1301",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "CORE Console",
      name: "Stryker CORE Console Powered Instrument Driver",
      titleDescription: "Powered Instrument Driver",
    },
  },
  {
    id: "2219",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Crossfire 2",
      name: "Stryker Crossfire 2 Integrated Resection and Energy System",
      titleDescription: "Integrated Resection and Energy System",
    },
  },
  {
    id: "1939",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Flosteady",
      name: "Stryker Flosteady Arthroscopy Pump",
      titleDescription: "Arthroscopy Pump",
    },
  },
  {
    id: "1101",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Ideal Eyes HD",
      name: "Stryker Ideal Eyes HD Flexible Control Unit",
      titleDescription: "Flexible Control Unit",
    },
  },
  {
    id: "1862",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "L10 AIM",
      name: "Stryker L10 AIM LED Light Source",
      titleDescription: "LED Light Source",
    },
  },
  {
    id: "1107",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "L9000",
      name: "Stryker L9000 LED Light Source",
      titleDescription: "LED Light Source",
    },
  },
  {
    id: "357",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "LD304",
      name: "Stryker LD304 Birthing Bed",
      titleDescription: "Birthing Bed",
    },
  },
  {
    id: "619",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Neptune 2",
      name: "Stryker Neptune 2 Waste Management System",
      titleDescription: "Waste Management System",
    },
  },
  {
    id: "1673",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Neptune 2 Ultra",
      name: "Stryker Neptune 2 Ultra Waste Management System",
      titleDescription: "Waste Management System",
    },
  },
  {
    id: "1590",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Neptune 3 Rover",
      name: "Stryker Neptune 3 Rover Waste Management System",
      titleDescription: "Waste Management System",
    },
  },
  {
    id: "22",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Neptune Gold Rover",
      name: "Stryker Neptune Gold Rover Waste Management System",
      titleDescription: "Waste Management System",
    },
  },
  {
    id: "1505",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Neptune Rover",
      name: "Stryker Neptune Rover Waste Management System",
      titleDescription: "Waste Management System",
    },
  },
  {
    id: "2718",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Pinpoint",
      name: "Stryker Pinpoint Instrument Trays",
      titleDescription: "Instrument Trays",
    },
  },
  {
    id: "2852",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Pinpoint",
      name: "Stryker Pinpoint Video Processor/Illuminator",
      titleDescription: "Video Processor/Illuminator",
    },
  },
  {
    id: "1641",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "PneumoSure",
      name: "Stryker PneumoSure High Flow Insufflator",
      titleDescription: "High Flow Insufflator",
    },
  },
  {
    id: "1185",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "PneumoSure XL",
      name: "Stryker PneumoSure XL High Flow Insufflator",
      titleDescription: "High Flow Insufflator",
    },
  },
  {
    id: "2182",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Pneumoclear",
      name: "Stryker Pneumoclear CO2 Conditioning Insufflator",
      titleDescription: "CO2 Conditioning Insufflator",
    },
  },
  {
    id: "921",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "RF Multigen",
      name: "Stryker RF Multigen Radiofrequency Generator",
      titleDescription: "Radiofrequency Generator",
    },
  },
  {
    id: "2074",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "RemB",
      name: "Stryker RemB Surgical Power Drill",
      titleDescription: "Surgical Power Drill",
    },
  },
  {
    id: "1981",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SDC Classic",
      name: "Stryker SDC Classic HD Capture Device",
      titleDescription: "HD Capture Device",
    },
  },
  {
    id: "1103",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SDC Ultra",
      name: "Stryker SDC Ultra Image Management System",
      titleDescription: "Image Management System",
    },
  },
  {
    id: "1180",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SDC3",
      name: "Stryker SDC3 Information Management System",
      titleDescription: "Information Management System",
    },
  },
  {
    id: "1109",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SDP1000",
      name: "Stryker SDP1000 Medical Grade Digital Printer",
      titleDescription: "Medical Grade Digital Printer",
    },
  },
  {
    id: "356",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SERFAS Energy ",
      name: "Stryker SERFAS Energy  Radio Frequency Ablation System",
      titleDescription: "Radio Frequency Ablation System",
    },
  },
  {
    id: "1108",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SIDNE Suite",
      name: "Stryker SIDNE Suite Control System",
      titleDescription: "Control System",
    },
  },
  {
    id: "2297",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SPY Elite",
      name: "Stryker SPY Elite Fluorescence Imaging System",
      titleDescription: "Fluorescence Imaging System",
    },
  },
  {
    id: "1419",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SYNK",
      name: "Stryker SYNK Wireless Transmitter",
      titleDescription: "Wireless Transmitter",
    },
  },
  {
    id: "194",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SmartPump",
      name: "Stryker SmartPump Tourniquet System",
      titleDescription: "Tourniquet System",
    },
  },
  {
    id: "975",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "SmartPump Dual Channel",
      name: "Stryker SmartPump Dual Channel Tourniquet System",
      titleDescription: "Tourniquet System",
    },
  },
  {
    id: "49",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Sonopet",
      name: "Stryker Sonopet Ultrasonic Aspirator",
      titleDescription: "Ultrasonic Aspirator",
    },
  },
  {
    id: "2390",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Sonopet iQ",
      name: "Stryker Sonopet iQ Ultrasonic Aspirator System",
      titleDescription: "Ultrasonic Aspirator System",
    },
  },
  {
    id: "1320",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Switchpoint Infinity 3",
      name: "Stryker Switchpoint Infinity 3 Control System",
      titleDescription: "Control System",
    },
  },
  {
    id: "200",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "System 6",
      name: "Stryker System 6 Surgical Drill System",
      titleDescription: "Surgical Drill System",
    },
  },
  {
    id: "171",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "System 7",
      name: "Stryker System 7 Surgical Drill",
      titleDescription: "Surgical Drill",
    },
  },
  {
    id: "1104",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "TPS Console",
      name: "Stryker TPS Console Total Performance System",
      titleDescription: "Total Performance System",
    },
  },
  {
    id: "164",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "TPS Universal Driver - 5100 99",
      name: "Stryker TPS Universal Driver - 5100 99 Total Performance System Drill",
      titleDescription: "Total Performance System Drill",
    },
  },
  {
    id: "2124",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower W/ SPY-PHI & PINPOINT",
      titleDescription: "W/ SPY-PHI & PINPOINT",
    },
  },
  {
    id: "3110",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower 1688 HD",
      titleDescription: "1688 HD",
    },
  },
  {
    id: "180",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower Endoscopy Video w/HD Technology",
      titleDescription: "Endoscopy Video w/HD Technology",
    },
  },
  {
    id: "1875",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower 1488 Video",
      titleDescription: "1488 Video",
    },
  },
  {
    id: "1199",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower Video",
      titleDescription: "Video",
    },
  },
  {
    id: "1617",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower Laparoscopic",
      titleDescription: "Laparoscopic",
    },
  },
  {
    id: "2281",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower 1588 Video",
      titleDescription: "1588 Video",
    },
  },
  {
    id: "1618",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Tower",
      name: "Stryker Tower Endoscopy",
      titleDescription: "Endoscopy",
    },
  },
  {
    id: "1187",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Unknown",
      name: "Stryker Unknown Standard Video Cart",
      titleDescription: "Standard Video Cart",
    },
  },
  {
    id: "2448",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Unknown",
      name: 'Stryker Unknown Secondary 32" 4K Monitor for Spy-Phi',
      titleDescription: 'Secondary 32" 4K Monitor for Spy-Phi',
    },
  },
  {
    id: "1510",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Unknown",
      name: "Stryker Unknown Sternum Saw",
      titleDescription: "Sternum Saw",
    },
  },
  {
    id: "1525",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Unknown",
      name: "Stryker Unknown Motorized Gurney",
      titleDescription: "Motorized Gurney",
    },
  },
  {
    id: "2164",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "Video Tower",
      name: "Stryker Video Tower W/ SPY-PHI",
      titleDescription: "W/ SPY-PHI",
    },
  },
  {
    id: "1188",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: 'Vision Elect 21" Monitor',
      name: 'Stryker Vision Elect 21" Monitor High Definition Surgical Display',
      titleDescription: "High Definition Surgical Display",
    },
  },
  {
    id: "1308",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: 'Vision Elect HDTV 26"',
      name: 'Stryker Vision Elect HDTV 26" Surgical Viewing Monitor',
      titleDescription: "Surgical Viewing Monitor",
    },
  },
  {
    id: "1179",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: 'VisionPro 26"',
      name: 'Stryker VisionPro 26" Tower LED Display',
      titleDescription: "Tower LED Display",
    },
  },
  {
    id: "1926",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: 'VisionPro SYNK 26"',
      name: 'Stryker VisionPro SYNK 26" Wireless LED Display',
      titleDescription: "Wireless LED Display",
    },
  },
  {
    id: "971",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "WiSe HDTV Surgical Display",
      name: "Stryker WiSe HDTV Surgical Display Secondary Monitor",
      titleDescription: "Secondary Monitor",
    },
  },
  {
    id: "1406",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "WiSe HDTV Transmitter",
      name: "Stryker WiSe HDTV Transmitter Transmitter",
      titleDescription: "Transmitter",
    },
  },
  {
    id: "1713",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "X7000",
      name: "Stryker X7000 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1189",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "X8000",
      name: "Stryker X8000 300W Xenon Light Source",
      titleDescription: "300W Xenon Light Source",
    },
  },
  {
    id: "1773",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Stryker",
      modelTitle: "iSwitch",
      name: "Stryker iSwitch Wireless Foot Control",
      titleDescription: "Wireless Foot Control",
    },
  },
  {
    id: "1995",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sunoptics Surgical",
      modelTitle: "LED 2000",
      name: "Sunoptics Surgical LED 2000 Lightsource",
      titleDescription: "Lightsource",
    },
  },
  {
    id: "1481",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sunoptics Surgical",
      modelTitle: "Titan 180",
      name: "Sunoptics Surgical Titan 180 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1546",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Sunoptics Surgical",
      modelTitle: "Titan 300",
      name: "Sunoptics Surgical Titan 300 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "2783",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Symmetry",
      modelTitle: "Greenberg",
      name: "Symmetry Greenberg Brain Retractor Kit",
      titleDescription: "Brain Retractor Kit",
    },
  },
  {
    id: "2393",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Synovis",
      modelTitle: "Gem Flow Coupler",
      name: "Synovis Gem Flow Coupler Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "268",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "TENET",
      modelTitle: "SPIDER Limb Positioner",
      name: "TENET SPIDER Limb Positioner Surgical Positioning Accessory",
      titleDescription: "Surgical Positioning Accessory",
    },
  },
  {
    id: "1353",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "THD",
      modelTitle: "Evolution",
      name: "THD Evolution Doppler Generator",
      titleDescription: "Doppler Generator",
    },
  },
  {
    id: "2821",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Terumo BCT",
      modelTitle: "Spectra Optia",
      name: "Terumo BCT Spectra Optia Apheresis System",
      titleDescription: "Apheresis System",
    },
  },
  {
    id: "2897",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Thermedx",
      modelTitle: "Fluid managment system",
      name: "Thermedx Fluid managment system Lipo",
      titleDescription: "Lipo",
    },
  },
  {
    id: "2101",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Thermedx",
      modelTitle: "P2000",
      name: "Thermedx P2000 37-5 Fluid Management System",
      titleDescription: "37-5 Fluid Management System",
    },
  },
  {
    id: "1742",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Thermo",
      modelTitle: "004480F",
      name: "Thermo 004480F Medilite Centrifuge",
      titleDescription: "Medilite Centrifuge",
    },
  },
  {
    id: "1522",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Trays",
      modelTitle: "Ent Storz",
      name: "Trays Ent Storz Reverse Light Post Sinus Scope Tray",
      titleDescription: "Reverse Light Post Sinus Scope Tray",
    },
  },
  {
    id: "1492",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Trays",
      modelTitle: "Various",
      name: "Trays Various One time move",
      titleDescription: "One time move",
    },
  },
  {
    id: "1535",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Trays",
      modelTitle: "unknown",
      name: "Trays unknown Various",
      titleDescription: "Various",
    },
  },
  {
    id: "2007",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Tri-Anim Surgical Solutions",
      modelTitle: "Lumistar LL300",
      name: "Tri-Anim Surgical Solutions Lumistar LL300 HeadLight Source",
      titleDescription: "HeadLight Source",
    },
  },
  {
    id: "1576",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Trimedyne",
      modelTitle: "1210-VHP OmniPulse MAX",
      name: "Trimedyne 1210-VHP OmniPulse MAX YAG Holmium Laser",
      titleDescription: "YAG Holmium Laser",
    },
  },
  {
    id: "2326",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Tyco",
      modelTitle: "Vital Vue",
      name: "Tyco Vital Vue Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1990",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown ENT Ear Micro Instrument Tray",
      titleDescription: "ENT Ear Micro Instrument Tray",
    },
  },
  {
    id: "2076",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Carpal Tunnel Arthroscopy Tray",
      titleDescription: "Carpal Tunnel Arthroscopy Tray",
    },
  },
  {
    id: "2077",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Bovie Cart",
      titleDescription: "Bovie Cart",
    },
  },
  {
    id: "1526",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Plastics Speciality Tray",
      titleDescription: "Plastics Speciality Tray",
    },
  },
  {
    id: "2446",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown KP-Covid Dialysis Machine",
      titleDescription: "KP-Covid Dialysis Machine",
    },
  },
  {
    id: "2747",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown 3PL Payload",
      titleDescription: "3PL Payload",
    },
  },
  {
    id: "1523",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Ries Suction Elevator",
      titleDescription: "Ries Suction Elevator",
    },
  },
  {
    id: "2080",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Thorascopic Tray",
      titleDescription: "Thorascopic Tray",
    },
  },
  {
    id: "2691",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown KP-Covid Supplies",
      titleDescription: "KP-Covid Supplies",
    },
  },
  {
    id: "1511",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Maxillofacial Tray",
      titleDescription: "Maxillofacial Tray",
    },
  },
  {
    id: "1878",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Tympanomastoid Instrument Tray",
      titleDescription: "Tympanomastoid Instrument Tray",
    },
  },
  {
    id: "2090",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown D&E Instrument Tray",
      titleDescription: "D&E Instrument Tray",
    },
  },
  {
    id: "2081",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Unknown",
      modelTitle: "Unknown",
      name: "Unknown Unknown Thorocotomy Tray",
      titleDescription: "Thorocotomy Tray",
    },
  },
  {
    id: "2120",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "V Mueller",
      modelTitle: "Denis Brown Retractor",
      name: "V Mueller Denis Brown Retractor Instrument Set",
      titleDescription: "Instrument Set",
    },
  },
  {
    id: "491",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "VNUS Medical Technologies",
      modelTitle: "RFG2",
      name: "VNUS Medical Technologies RFG2 Laser Radiofrequency Generator",
      titleDescription: "Laser Radiofrequency Generator",
    },
  },
  {
    id: "1450",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "VNUS Medical Technologies",
      modelTitle: "TPMP-01",
      name: "VNUS Medical Technologies TPMP-01 Infiltration Pump",
      titleDescription: "Infiltration Pump",
    },
  },
  {
    id: "1593",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "EM-2",
      name: "Valleylab EM-2 Electroscope",
      titleDescription: "Electroscope",
    },
  },
  {
    id: "1471",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "Evident",
      name: "Valleylab Evident MW Ablation Pump",
      titleDescription: "MW Ablation Pump",
    },
  },
  {
    id: "1469",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "Evident",
      name: "Valleylab Evident MW Ablation Generator",
      titleDescription: "MW Ablation Generator",
    },
  },
  {
    id: "359",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "Force 2",
      name: "Valleylab Force 2 Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1111",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "Force Argon II System",
      name: "Valleylab Force Argon II System Gas Delivery Unit",
      titleDescription: "Gas Delivery Unit",
    },
  },
  {
    id: "963",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "Force EZc",
      name: "Valleylab Force EZc Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1580",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "Force FX",
      name: "Valleylab Force FX Electrosurgical Generator",
      titleDescription: "Electrosurgical Generator",
    },
  },
  {
    id: "2250",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "Force FX -C",
      name: "Valleylab Force FX -C Electrosurgical Generator",
      titleDescription: "Electrosurgical Generator",
    },
  },
  {
    id: "328",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "ForceTriad",
      name: "Valleylab ForceTriad Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "520",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "OptiMumm",
      name: "Valleylab OptiMumm Smoke Evacuator",
      titleDescription: "Smoke Evacuator",
    },
  },
  {
    id: "1112",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Valleylab",
      modelTitle: "SSE2L",
      name: "Valleylab SSE2L Electrosurgical Unit",
      titleDescription: "Electrosurgical Unit",
    },
  },
  {
    id: "1347",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vascular Technology",
      modelTitle: "8 MHz Surgical Doppler Transceiver",
      name: "Vascular Technology 8 MHz Surgical Doppler Transceiver Intraoperative Doppler System",
      titleDescription: "Intraoperative Doppler System",
    },
  },
  {
    id: "1527",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vascular Technology",
      modelTitle: "VTI",
      name: "Vascular Technology VTI 20 MHz Microvascular Doppler System",
      titleDescription: "20 MHz Microvascular Doppler System",
    },
  },
  {
    id: "1192",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Verathon",
      modelTitle: "BladderScan BVI 9400",
      name: "Verathon BladderScan BVI 9400 Bladder Scanner",
      titleDescription: "Bladder Scanner",
    },
  },
  {
    id: "1290",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Verathon",
      modelTitle: "GlideScope GVL",
      name: "Verathon GlideScope GVL Video Laryngoscope",
      titleDescription: "Video Laryngoscope",
    },
  },
  {
    id: "1570",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Verathon",
      modelTitle: "Glidescope",
      name: "Verathon Glidescope Video Laryngoscope",
      titleDescription: "Video Laryngoscope",
    },
  },
  {
    id: "1298",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Volcano",
      modelTitle: "s5",
      name: "Volcano s5 Imaging System",
      titleDescription: "Imaging System",
    },
  },
  {
    id: "2447",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vyaire Medical",
      modelTitle: "3100A HFOV",
      name: "Vyaire Medical 3100A HFOV Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "2671",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vyaire Medical",
      modelTitle: "Avea",
      name: "Vyaire Medical Avea Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "2482",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vyaire Medical",
      modelTitle: "LTV 1200",
      name: "Vyaire Medical LTV 1200 Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "2428",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vyaire Medical",
      modelTitle: "LTV-1150",
      name: "Vyaire Medical LTV-1150 Ventilator",
      titleDescription: "Ventilator",
    },
  },
  {
    id: "2397",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vyaire Medical",
      modelTitle: "Ventilators",
      name: "Vyaire Medical Ventilators 5 Pallets",
      titleDescription: "5 Pallets",
    },
  },
  {
    id: "2450",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vyaire Medical",
      modelTitle: "Ventilators",
      name: "Vyaire Medical Ventilators 7 Pallets",
      titleDescription: "7 Pallets",
    },
  },
  {
    id: "2453",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Vyaire Medical",
      modelTitle: "Ventilators",
      name: "Vyaire Medical Ventilators 3 Pallets",
      titleDescription: "3 Pallets",
    },
  },
  {
    id: "1725",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Weerda",
      modelTitle: "Diverticuloscope",
      name: "Weerda Diverticuloscope Instrument Set",
      titleDescription: "Instrument Set",
    },
  },
  {
    id: "1282",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Welch Allyn",
      modelTitle: "202-EL Propaq Encore",
      name: "Welch Allyn 202-EL Propaq Encore Monitor",
      titleDescription: "Monitor",
    },
  },
  {
    id: "1678",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Welch Allyn",
      modelTitle: "48740",
      name: "Welch Allyn 48740 Exam Light",
      titleDescription: "Exam Light",
    },
  },
  {
    id: "1613",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Welch Allyn",
      modelTitle: "88200",
      name: "Welch Allyn 88200 Colposcope",
      titleDescription: "Colposcope",
    },
  },
  {
    id: "1670",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Welch Allyn",
      modelTitle: "CL300",
      name: "Welch Allyn CL300 Surgical Illuminator",
      titleDescription: "Surgical Illuminator",
    },
  },
  {
    id: "1194",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Welch Allyn",
      modelTitle: "ProXenon 350",
      name: "Welch Allyn ProXenon 350 Surgical Illuminator",
      titleDescription: "Surgical Illuminator",
    },
  },
  {
    id: "1495",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Welch Allyn",
      modelTitle: "Xenon 300",
      name: "Welch Allyn Xenon 300 Light Source",
      titleDescription: "Light Source",
    },
  },
  {
    id: "1904",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Xomed",
      modelTitle: "18-51000 Endo-Scrub",
      name: "Xomed 18-51000 Endo-Scrub Unknown",
      titleDescription: "Unknown",
    },
  },
  {
    id: "1663",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Xomed",
      modelTitle: "18-95100",
      name: "Xomed 18-95100 XPS MicroResector Console",
      titleDescription: "XPS MicroResector Console",
    },
  },
  {
    id: "1903",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Xomed",
      modelTitle: "18-95500",
      name: "Xomed 18-95500 XPS Irrigator",
      titleDescription: "XPS Irrigator",
    },
  },
  {
    id: "2119",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Xomed",
      modelTitle: "Skeeter Ultra Lite 0to-Tool",
      name: "Xomed Skeeter Ultra Lite 0to-Tool Drill",
      titleDescription: "Drill",
    },
  },
  {
    id: "1674",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Xomed",
      modelTitle: "XPS 2000",
      name: "Xomed XPS 2000 Arthroscopy Shaver System",
      titleDescription: "Arthroscopy Shaver System",
    },
  },
  {
    id: "1399",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ziehm Imaging",
      modelTitle: "Ziehm Vision 2",
      name: "Ziehm Imaging Ziehm Vision 2 Mobile Viewing Station",
      titleDescription: "Mobile Viewing Station",
    },
  },
  {
    id: "1398",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ziehm Imaging",
      modelTitle: "Ziehm Vision 2",
      name: "Ziehm Imaging Ziehm Vision 2 C-Arm",
      titleDescription: "C-Arm",
    },
  },
  {
    id: "1328",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ziehm Imaging",
      modelTitle: "Ziehm Vision R",
      name: "Ziehm Imaging Ziehm Vision R Mobile Viewing Station",
      titleDescription: "Mobile Viewing Station",
    },
  },
  {
    id: "1329",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Ziehm Imaging",
      modelTitle: "Ziehm Vision R",
      name: "Ziehm Imaging Ziehm Vision R C-Arm",
      titleDescription: "C-Arm",
    },
  },
  {
    id: "973",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zimmer",
      modelTitle: "A.T.S. 1500",
      name: "Zimmer A.T.S. 1500 Automatic Tourniquet System",
      titleDescription: "Automatic Tourniquet System",
    },
  },
  {
    id: "481",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zimmer",
      modelTitle: "A.T.S. 2000",
      name: "Zimmer A.T.S. 2000 Automatic Tourniquet System",
      titleDescription: "Automatic Tourniquet System",
    },
  },
  {
    id: "473",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zimmer",
      modelTitle: "A.T.S. 3000",
      name: "Zimmer A.T.S. 3000 Automatic Tourniquet System",
      titleDescription: "Automatic Tourniquet System",
    },
  },
  {
    id: "471",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zimmer",
      modelTitle: "A.T.S. 4000",
      name: "Zimmer A.T.S. 4000 Automatic Tourniquet System",
      titleDescription: "Automatic Tourniquet System",
    },
  },
  {
    id: "2815",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zimmer",
      modelTitle: "Cryo 6",
      name: "Zimmer Cryo 6 Cold Air Chiller",
      titleDescription: "Cold Air Chiller",
    },
  },
  {
    id: "547",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zimmer",
      modelTitle: "Dermatome",
      name: "Zimmer Dermatome Skin Grafting System",
      titleDescription: "Skin Grafting System",
    },
  },
  {
    id: "1313",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zonare",
      modelTitle: "ZS3",
      name: "Zonare ZS3 Ultrasound System",
      titleDescription: "Ultrasound System",
    },
  },
  {
    id: "1512",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zonare",
      modelTitle: "z.one mini",
      name: "Zonare z.one mini Convertible Ultrasound System",
      titleDescription: "Convertible Ultrasound System",
    },
  },
  {
    id: "1307",
    type: "equipmentMasters",
    attributes: {
      manufacturerName: "Zonare",
      modelTitle: "z.one ultra SmartCart",
      name: "Zonare z.one ultra SmartCart Diagnostic Ultrasound System",
      titleDescription: "Diagnostic Ultrasound System",
    },
  },
];
