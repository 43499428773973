import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import StickyContent from "./utils/sticky_content";
import { setQueryString, FILTER_KEYS } from "./utils/use_query_params";

import Location from "./filters/location";
import Equipment from "./filters/equipment";
import MoveFilter from "./filters/move_filter";
import EventType from "./filters/event_type";

export default function UglyFilter({
  equipment_masters,
  facilities,
  handleSubmit,
  health_systems,
  inventory_items,
  move_badges,
  move_drivers,
  move_states,
  selected,
}) {
  const handleFilterChange = (key, changes) =>
    handleSubmit({ ...selected, [key]: changes });

  const selHealthSystem = selected.healthSystem;
  return (
    <StyledSideBar className="sidebar-collapsable hidden-phone">
      <StickyContent className="view-filter" data-automation-id="event_filters">
        <div className="filter-components">
          <Location
            onChange={handleFilterChange}
            options={{
              healthSystems: health_systems,
              facilities: facilities,
              facility_types: selected.facilityTypes,
            }}
            selected={{
              healthSystem: selHealthSystem,
              facilities: selected.facilities,
            }}
          />
          <Equipment
            data={{
              equipmentMasters: equipment_masters,
              healthSystemId: selHealthSystem?.value,
              inventoryItems: inventory_items,
            }}
            onChange={handleFilterChange}
            selected={{
              equipmentMaster: selected.equipmentMaster,
              inventoryItems: selected.inventoryItems,
            }}
          />
          <MoveFilter
            onChange={handleFilterChange}
            options={{
              moveBadges: move_badges,
              moveDrivers: move_drivers,
              moveStates: move_states,
            }}
            selected={{
              moveBadges: selected.moveBadges,
              moveDrivers: selected.moveDrivers,
              moveStates: selected.moveStates,
            }}
          />
          <EventType
            onChange={handleFilterChange}
            options={selected.eventTypes}
          />
          <StyledClearFilter className="apply-clear controls ugly-component">
            <span
              className="btn btn-small btn-block"
              id="clear_filter_button"
              onClick={() => {
                FILTER_KEYS.map((key) => setQueryString(key, { value: null }));
                handleSubmit(null);
              }}
              type="button"
            >
              Clear Filter
            </span>
          </StyledClearFilter>
        </div>
      </StickyContent>
    </StyledSideBar>
  );
}

const StyledClearFilter = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

// padding: 20px 19px 20px 30px;
const StyledSideBar = styled.div`
  background: #ededed;
  border-right: 2px solid #e2e2e2;
  display: table-cell;
  float: none;
  margin: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 285px;
  vertical-align: top;

  .filter-components {
    label {
      margin-bottom: 8px;
    }
  }
`;

// TODO: Proptypes
UglyFilter.propTypes = {};
