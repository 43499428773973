import styled from "styled-components";

export const StyledNotes = styled.div`
  textarea {
    height: 90px;
    font-size: 12px;
    line-height: 1.5;
    resize: vertical;
  }
`;
