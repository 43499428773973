import React from "react";
import PropTypes from "prop-types";

import SelectDropdown from "./select_dropdown";
import MovesContact from "./moves_contact";
import { MovesContactComponent } from "./styled";

class MovesContacts extends React.Component {
  // initialize with existing moves_contact
  constructor(props) {
    super(props);

    const movesContactsList = props.movesContactsList || [];

    this.state = {
      selectedContacts: this.initMovesContacts(
        movesContactsList.filter((mc) => {
          return mc.selected;
        })
      ),
      movesContactsList: this.initMovesContacts(movesContactsList),
      changed: false,
      focusSelect: false,
    };
  }

  componentDidMount() {
    if (this.props.action === "edit") {
      this.props.isPickUp
        ? $("#move_pickup_facility_id.confirm-facility-change").on(
            "change",
            this.handleFacilityChange
          )
        : $("#move_dropoff_facility_id.confirm-facility-change").on(
            "change",
            this.handleFacilityChange
          );
    }
  }

  componentDidUpdate() {
    if (this.state.changed === false) this.setState({ changed: true });
  }

  initMovesContacts = (movesContacts) => {
    if (movesContacts === null) {
      return [];
    }

    return movesContacts.map((mc) => {
      return {
        ...mc,
        key: mc.value,
        primary: mc.primary || false,
        editing: false,
        hideable: mc.selected,
      };
    });
  };

  ensurePrimaryContactsList = (list) => {
    let listCopy = list.slice();
    let hasNoPrimary = listCopy.find((mc) => mc.primary === true) === undefined;

    if (listCopy.length !== 0 && hasNoPrimary) {
      // find the first non-removable contact and ensure primary is enabled for the first one
      let i = 0;
      while (hasNoPrimary && listCopy.length > i) {
        if (listCopy[i].removable !== true) {
          listCopy[i].primary = true;
          hasNoPrimary = false;
          break;
        }
        i++;
      }
    }

    return listCopy;
  };

  handleFacilityChange = (e) => {
    // Remove all associated moves
    let selectedContactsCopy = [];
    this.state.selectedContacts.forEach((mc) => {
      if (mc.moves_contact_id) {
        mc.primary = false;
        mc.removable = true;

        selectedContactsCopy.push(mc);
      }
    });

    this.setState({
      selectedContacts: selectedContactsCopy,
      movesContactsList: [],
      changed: true,
    });

    $.ajax({
      type: "GET",
      url: this.props.url,
      data: {
        facility_id: e.target.value,
        is_pick_up: this.props.isPickUp,
      },
      success: (response) => {
        this.setState({
          movesContactsList: this.initMovesContacts(response),
        });
      },
    });
  };

  /* SelectedDropdown handlers */
  handleSelectedContact = (selectedPill) => {
    // If selectedPIll has moves_contact_id, it means this move is attached to this move
    // and was removed by the user. However, now the user is adding this contact back.
    // Therefore, appending the selectedPill is not necessary.

    const selectedContactsCopy = selectedPill.moves_contact_id
      ? this.state.selectedContacts.map((mc) => {
          if (mc.moves_contact_id == selectedPill.moves_contact_id) {
            mc.removable = false;
          }
          return mc;
        })
      : [...this.state.selectedContacts, selectedPill];

    this.setState({
      // There must be a primary at all times
      selectedContacts: this.ensurePrimaryContactsList(selectedContactsCopy),
    });
  };

  handleUpdatedContacts = (options) =>
    this.setState({ movesContactsList: options });

  /* MovesContact handers */
  // When changes occur on pill/form, update the list
  handleUpdatedSelectedContacts = (updatedOption) => {
    let existingOptions = this.state.selectedContacts.map((option, i) => {
      if (i == updatedOption.index) {
        return updatedOption;
      }
      return option;
    });

    this.setState({
      selectedContacts: existingOptions,
      focusSelect: true,
    });
  };

  afterFocusSelect = () => this.setState({ focusSelect: false });

  // When removing a pill
  // 1. if it is an associated move contact, ensurePrimary is maintained and don't remove from selectedList
  // 2. if it contact is from dropdown, unhide the option in dropdown and remove from selectedList
  // 3. if it is a new contact, remove it from the selectedList
  handleRemovePill = (optionIndex) => {
    let movesContactsListCopy = null;
    let selectedMovesContactsCopy = this.state.selectedContacts.map(
      (mc, index) => {
        if (optionIndex === index) {
          // this moves_contact was selected from the select box
          // - unhide from list of select options
          if (mc.value) {
            movesContactsListCopy = this.state.movesContactsList.map(
              (option) => {
                if (option.value === mc.value) {
                  option.primary = false;
                  option.editing = false;
                  option.hideable = false;

                  if (mc.moves_contact_id) {
                    option.removable = true;
                  }
                }
                return option;
              }
            );
          }

          // this moves_contact is associated with move
          // - removable will append a _destroy: 1
          if (mc.moves_contact_id) {
            mc.primary = false;
            mc.removable = true;

            return mc;
          }

          return null;
        }
        return mc;
      }
    );

    this.setState({
      selectedContacts: this.ensurePrimaryContactsList(
        selectedMovesContactsCopy.filter((mc) => {
          return mc !== null;
        })
      ),
      movesContactsList: movesContactsListCopy || this.state.movesContactsList,
    });
  };

  // Update the primary status of contacts in the selectedContacts list
  handlePrimaryContactSel = (primaryIndex) => {
    let existingOptions = this.state.selectedContacts.map((option, index) => {
      option.primary = primaryIndex === index;

      return option;
    });

    this.setState({ selectedContacts: existingOptions });
  };

  renderMovesContactsList = () => {
    const { selectedContacts } = this.state;
    const isEditPage = this.props.action === "edit";

    let removable_count = 0;
    selectedContacts.forEach((e) => {
      if (e.removable === true) removable_count++;
    });

    const selectedContactList = selectedContacts.map((props, i) => {
      return (
        <MovesContact
          {...props}
          key={i}
          index={i}
          action={isEditPage ? "edit" : "show"}
          isPickUp={this.props.isPickUp}
          handleRemovePill={isEditPage ? this.handleRemovePill : null}
          handlePrimaryContactSel={
            isEditPage ? this.handlePrimaryContactSel : null
          }
          handleUpdatedSelectedContacts={
            isEditPage ? this.handleUpdatedSelectedContacts : null
          }
        />
      );
    });

    // Check if there are no displayable content
    if (selectedContacts.length === removable_count) {
      return (
        <div>
          <p className="text-center empty empty-list">No contacts added</p>
          {selectedContactList}
        </div>
      );
    } else {
      return selectedContactList;
    }
  };

  render() {
    const { movesContactsList } = this.state;

    if (this.props.action === "edit") {
      const className = `moves-contacts-container ${
        this.props.isPickUp ? "pick-up" : "drop-off"
      }`;
      return (
        <MovesContactComponent
          className={className}
          data-changed={this.state.changed}
        >
          <div className="moves-contacts-header">
            <div className="header-title">CONTACTS</div>
            <SelectDropdown
              movesContactsList={movesContactsList}
              handleSelectedContact={this.handleSelectedContact}
              handleUpdatedContacts={this.handleUpdatedContacts}
              focusSelect={this.state.focusSelect}
              afterFocusSelect={this.afterFocusSelect}
            />
          </div>

          <div className="moves-contacts-content">
            <div className="moves-contacts-list">
              {this.renderMovesContactsList()}
            </div>
          </div>
        </MovesContactComponent>
      );
    }

    return (
      <MovesContactComponent className="moves-contacts-container">
        <div className="moves-contacts-header">
          <div className="header-title">CONTACTS</div>
        </div>

        <div className="moves-contacts-content">
          <div className="moves-contacts-list">
            {this.renderMovesContactsList()}
          </div>
        </div>
      </MovesContactComponent>
    );
  }
}

MovesContacts.propTypes = {
  isPickUp: PropTypes.bool,
  selectedContacts: PropTypes.array,
  movesContactsList: PropTypes.array,
  action: PropTypes.string,
};

export default MovesContacts;
