export const selected = {
  id: "13379",
  type: "inventoryItems",
  attributes: {
    collaborativelyPurchased: false,
    description: "",
    equipmentMasterId: 2390,
    type: "Inventory",
    status: "shareable",
    rentalCompanyId: null,
    yearManufactured: null,
    accessoryMasters: [
      {
        name: "Sonopet IQ Universal Angled Hand Piece",
        quantity: 2,
      },
      {
        name: "Advanced Foot Pedal - Wireless",
        quantity: 1,
      },
    ],
    rentalCompany: {
      id: null,
      name: null,
    },
    codes: {
      assetTag: "050-014036",
      cohealoTag: "005565",
      serial: "1933010109",
    },
    equipmentMaster: {
      id: 2390,
      manufacturerName: "Stryker",
      modelTitle: "Sonopet iQ",
      name: "Stryker Sonopet iQ Ultrasonic Aspirator System",
      titleDescription: "Ultrasonic Aspirator System",
    },
    homeFacility: {
      id: 119,
      siteCode: "AMC",
      name: "Anaheim Medical Center",
    },
    owningFacility: {
      id: 176,
      siteCode: "KPX",
      name: "KP Exchange",
    },
    meta: {
      equipment_preference_id: 12345,
      equipment_preferences_inventory_item_id: 1,
      usage: {
        duration: null,
        endOfCase: true,
        startTime: 0,
      },
    },
  },
  links: {
    self: "http://localhost:3000/inventories/13379",
  },
};

export const selectedWithFallback = {
  id: "123456",
  type: "inventoryItems",
  attributes: {
    collaborativelyPurchased: false,
    description: null,
    type: "Inventory",
    status: "not-shareable",
    rentalCompanyId: null,
    yearManufactured: null,
    accessoryMasters: null,
    rentalCompany: {
      id: null,
      name: null,
    },
    codes: {
      assetTag: "050-NHO0474",
      cohealoTag: "005508",
      serial: "M0476D19D0",
    },
    equipmentMaster: {
      id: 2222,
      manufacturer_name: "Hologic",
      model_title: "FLUENT",
      name: "Hologic FLUENT Fluid Management System",
      title_description: "Fluid Management System",
    },
    homeFacility: {
      id: 132,
      siteCode: "BMO",
      name: "Bellflower Medical Offices and Surgery Center",
    },
    owningFacility: {
      id: 132,
      siteCode: "BMO",
      name: "Bellflower Medical Offices and Surgery Center",
    },
    meta: {
      equipment_preference_id: 6104,
      equipment_preferences_inventory_item_id: 15045,
      usage: {
        duration: null,
        endOfCase: true,
        startTime: 0,
      },
    },
  },
  links: {
    self: "http://localhost:3000/inventories/12507",
  },
};

export const requestSamples = [selected, selectedWithFallback];
