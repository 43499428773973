import styled from "styled-components";
import { grey1, grey2, grey3, grey3b, grey4 } from "../utils/base";
import {
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderLeftRadius,
  borderRadius,
  borderRightRadius,
  borderTopRadius,
} from "../utils/baseMixins";

const bevelBorderDarkBottom = `
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 7px;
`;

const buttonStyles = `
  .fc-button,
  .fc-button:disabled,
  .fc-button:hover,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled) {
    margin-bottom: 0;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;
    height: inherit;
    color: #333;
    text-shadow: none;
    box-shadow: none;

    background: ${grey2};
    border: 2px solid ${grey3b};

    @media screen and (max-width: 768px) {
      font-size: 11px;
      padding: 7px 8px;
    }

    // Handle border-collapsing the dirty way
    &.fc-timeGridDay-button {
      border-right-width: 1px;
    }

    &.fc-timeGridWeek-button {
      border-left-width: 1px;
      border-right-width: 1px;
    }

    &.fc-dayGridMonth-button {
      border-left-width: 1px;
    }

    &.fc-prev-button {
      border-right-width: 1px;
    }

    &.fc-today-button {
      border-left-width: 1px;
      border-right-width: 1px;
    }

    &.fc-next-button {
      border-left-width: 1px;
    }

    &:hover {
      background: lighten(${grey2}, 5%);
    }

    // Disabled
    &:disabled {
      color: #333;
      &:hover {
        background: ${grey2};
        cursor: not-allowed;
      }
    }

    // Active
    &.fc-button-active {
      background: ${grey1};
      color: #333;
    }
  }
`;

const frameStyles = `
  .fc-view-harness {
    background: #fff;
  
    // day, week, month blocks - general styles
    .fc-scrollgrid {
      th, td {
        border-color: ${grey2};
  
        .fc-col-header-cell {
          &:first-child {
            border-left-width: 2px;
            border-left-color: ${grey4};
          }

          &:last-child {
            border-right-width: 2px;
            border-right-color: ${grey4};
          }
        }
      }
  
      tr.fc-last {
        td {
          border-bottom-width: 2px;
          border-bottom-color: ${grey4};
  
          &.fc-first {
            ${borderBottomLeftRadius()}
          }
  
          &.fc-last {
            ${borderBottomRightRadius()}
          }
        }
      }
    }
  
    // Day Headers
    .fc-col-header-cell {
      padding: 7px 0;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      background: ${grey1};
      border-top: none;
    }
  
    .fc-daygrid-day-number {
      font-size: 14px;
      line-height: 14px;
      color: #333;
      cursor: default;
      text-decoration: none;
    }
  
    .fc-day-today {
      background: ${grey2};
    }
  
    .fc-col-header-cell-cushion {
      color: #333;
      text-decoration: none;
      cursor: default;
    }
  
    // Event
    .fc-daygrid-event-harness {
      border: none;

      .event {
        line-height: 16px;
  
        .event-title-heading {
          display: block;
          padding: 4px;
          font-size: 11px;
        }
      }
    }

    .fc-daygrid-event {
      white-space: normal;
    }
  
    // Grid (month) view
    .fc-dayGridMonth-view .fc-daygrid-day-numbe {
      margin: 5px 0;
    }
  }
`;

const eventStyles = `
  // Moves/Bookings
  #events-index-page .ugly-calendar {
    .fc-view-harness {
      overflow: visible;
    }
  
    .fc-event {
      background: none;
      text-shadow: none;
      z-index: 10;
  
      .event-enter {
        opacity: 0;
      }
  
      .event-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
      }
  
      .event-appear {
        opacity: 0;
      }
  
      .event-appear-active {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
      }
  
      .event-exit {
        opacity: 1;
      }
  
      .event-exit-active {
        opacity: 0;
        transition: opacity 400ms ease-in-out;
      }
  
      .section-enter {
        opacity: 0;
      }
  
      .section-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
      }
  
      .section-appear {
        opacity: 0;
      }
  
      .section-appear-active {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
      }
  
      .section-exit {
        opacity: 1;
      }
  
      .section-exit-active {
        opacity: 0;
        transition: opacity 400ms ease-in-out;
      }
  
      .code-enter {
        opacity: 0;
      }
  
      .code-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
      }
  
      .code-appear {
        opacity: 0;
      }
  
      .code-appear-active {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
      }
  
      .code-exit {
        opacity: 1;
      }
  
      .code-exit-active {
        opacity: 0;
        transition: opacity 400ms ease-in-out;
      }
  
      // Contents
      .modal-show {
        position: absolute;
        top: 4px;
        right: 3px;
        display: none;
      }
  
      .booking-facility {
        display: block;
        padding: 0 0 4px 0;
  
        ${bevelBorderDarkBottom}
  
        font-weight: bold;
        font-size: 11px;
        line-height: 8px;
      }
  
      // Range bookings
      &.fc-event-end {
        .event {
          ${borderLeftRadius(0)}
        }
      }
  
      &.fc-event-start {
        .event {
          ${borderRightRadius(0)}
        }
      }
  
      &.fc-event-start.fc-event-end {
        .event {
          ${borderRadius("5px")}
        }
      }
  
      .event-range {
        // Align text to left for better legibility
        .booking-facility {
          text-align: left;
        }
      }
  
      // Hover
      &:hover .event {
        z-index: 999 !important;
  
        .modal-show {
          display: block;
        }
      }
    }
  }
`;

export const Content = styled.div`
  .fc {
    width: 100%;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk {
    vertical-align: middle;
  }

  .fc td {
    vertical-align: top;
  }

  // New move button
  .fc-day {
    .new-move {
      display: block;
      margin-bottom: 10px;
      padding: 10px 5px;
      font-size: 10px;
      line-height: 10px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: #555;
      border: 2px dashed #ccc;
      height: 10px;

      ${borderRadius("5px")};

      visibility: hidden;

      &:hover {
        text-decoration: none;
        visilibity: visible;
        background-color: #eee;
      }
    }
  }

  #events-index-page .full-calendar.inventory-scope {
    .fc-day {
      &:hover {
        .new-move {
          visibility: visible;
        }
      }
    }
  }

  #events-index-page {
    .inventory-pill {
      .select2-container {
        .select2-highlighted .serial-number {
          background: #fff;
        }
      }
    }
  }

  .ugly-calendar {
    .fc .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0;
    }

    .fc .fc-toolbar-title {
      font-size: 18px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    .fc-header-toolbar {
      border-collapse: separate;
      background: ${grey3};
      border: 2px solid ${grey4};
      border-bottom: 1px solid ${grey4};

      ${borderTopRadius()}

      .fc-toolbar-chunk {
        padding: 15px 0;

        &:first-child {
          padding-left: 20px;

          @media screen and (max-width: 768px) {
            padding-left: 7px;
          }
        }

        &:nth-child(3) {
          padding-right: 20px;

          @media screen and (max-width: 768px) {
            padding-right: 7px;
          }
        }
      }
    }
    ${buttonStyles}
    ${frameStyles}
    ${eventStyles}
  }
`;
