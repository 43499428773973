import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Select, { components } from "react-select-3";

import { DEFAULT_SELECT_STYLES } from "./select-styles";
import { useQueryParam, MB_K, MD_K, MS_K } from "../utils/use_query_params";
import UglyComponent from "./ugly_component";

export default function MoveFilter({ onChange, selected, options }) {
  return (
    <UglyComponent title="Move Status, Driver, Badges">
      <label>Move Status:</label>
      <StateSelect
        onChange={onChange}
        options={options.moveStates}
        value={selected.moveStates}
      />
      <label>Driver:</label>
      <DriverSelect
        onChange={onChange}
        options={options.moveDrivers}
        value={selected.moveDrivers}
      />
      <label>Badges:</label>
      <BadgeSelect
        onChange={onChange}
        options={options.moveBadges}
        value={selected.moveBadges}
      />
    </UglyComponent>
  );
}

const hasQueryParamsDisconnect = (queryParam, propVal) =>
  queryParam && !propVal;

function BadgeSelect({ onChange, options, value }) {
  const [queryStr, setQueryString] = useQueryParam(MB_K, "");

  useEffect(() => {
    if (hasQueryParamsDisconnect(queryStr, value)) setQueryString(null);
  });

  return (
    <Select
      className="filter-input"
      closeMenuOnSelect={false}
      components={{
        MultiValueLabel: BadgeMultiValueLabel,
        Option: BadgeOption,
      }}
      id="move-badges"
      isClearable
      isMulti
      noOptionsMessage={() => "No more options for Badges"}
      onChange={(change) => {
        setQueryString(change);
        onChange("moveBadges", change);
      }}
      options={options}
      placeholder=""
      styles={BADGE_SELECT_STYLES}
      value={value}
    />
  );
}

const BADGE_SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  multiValueLabel: (provided, state) => ({
    ...provided,
    alignItems: "center",
    display: "flex",
    gap: "5px",
  }),
  option: (provided, state) => ({
    ...provided,
    alignItems: "center",
    borderTop: "1px solid #d6d6d6",
    display: "flex",
    gap: "5px",
    "&:first-of-type": {
      borderTop: "none",
    },
  }),
};

// Lock icon uses the same icon as not-shareable
// Icon is manually added because the option label does not match
// the icon class like the other badges.
const renderIcon = (label) => (
  <i
    className={`sprite-icon sprite-${
      label === "locked" ? "not-shareable" : label
    }-s-black`}
  />
);

const BadgeMultiValueLabel = ({ children, ...rest }) => (
  <components.MultiValueLabel {...rest}>
    {renderIcon(children)}
    {children}
  </components.MultiValueLabel>
);

const BadgeOption = ({ children, ...rest }) => (
  <components.Option {...rest}>
    {renderIcon(children)}
    {children}
  </components.Option>
);

function DriverSelect({ onChange, options, value }) {
  const [queryStr, setQueryString] = useQueryParam(MD_K, "");

  useEffect(() => {
    if (hasQueryParamsDisconnect(queryStr, value)) setQueryString(null);
  });

  return (
    <Select
      className="filter-input"
      closeMenuOnSelect={false}
      id="move-drivers"
      isClearable
      isMulti
      noOptionsMessage={() => "No more options for Drivers"}
      onChange={(change) => {
        setQueryString(change);
        onChange("moveDrivers", change);
      }}
      options={options}
      placeholder=""
      styles={DEFAULT_SELECT_STYLES}
      value={value}
    />
  );
}

function StateSelect({ onChange, options, value }) {
  const [queryStr, setQueryString] = useQueryParam(MS_K, "");

  useEffect(() => {
    if (hasQueryParamsDisconnect(queryStr, value)) setQueryString(null);
  });

  return (
    <Select
      className="filter-input"
      closeMenuOnSelect={false}
      id="move-states"
      isClearable
      isMulti
      noOptionsMessage={() => "No more options for states"}
      onChange={(change) => {
        setQueryString(change);
        onChange("moveStates", change);
      }}
      options={options}
      placeholder=""
      styles={DEFAULT_SELECT_STYLES}
      value={value}
    />
  );
}
