import React from "react";

import Input from "./Input";

export default {
  argTypes: { onChange: { action: "Input value changed" } },
  component: Input,
  title: "Form/Input",
};

const Template = (args) => <Input {...args} />;

export const Default = Template.bind({});

export const WithPlaceholder = Template.bind({});
WithPlaceholder.args = {
  placeholder: "Please enter input",
};

export const WithValue = Template.bind({});
WithValue.args = {
  value: "Foo bar",
};

export const IsDisabled = Template.bind({});
IsDisabled.args = {
  disabled: true,
};
