import styled from "styled-components";

export const StyledButtonContainer = styled.div`
  div.left {
    float: left;

    > *:not(:first-child) {
      margin-left: 6px;
    }
  }

  div.right {
    float: right;

    > *:not(:first-child) {
      margin-left: 9px;
    }
  }
`;
