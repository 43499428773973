import styled from "styled-components";

import { primary } from "../../../utils/base";

export const QUICK_EDIT_SELECT_STYLES = {
  clearIndicator: (provided, state) => ({
    ...provided,
    height: "17px",
    marginBottom: "3px",
    padding: "0px",
    width: "17px",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "13px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
    paddingRight: "5px",
  }),
  indicatorSeparator: () => {},
  input: (provided, state) => ({
    ...provided,
    input: {
      borderColor: "#FFF !important", // override needed due to select2/bootstrap dep
      boxShadow: "none !important",
      margin: "0px !important",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: "13px",
    margin: "0px",
  }),
  option: (provided, state) => {
    const isHighlighted = state.isFocused || state.isSelected;
    return {
      ...provided,
      borderTop: "1px solid #d6d6d6",
      "&:first-of-type": {
        borderTop: "none",
      },
      ...(isHighlighted && {
        backgroundColor: "#15A2E4",
        color: "#FFF",
      }),
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    paddingLeft: "6px",
  }),
};

export const StyledDisplay = styled.div`
  border-bottom: 0px !important;
  display: inline-block;
  white-space: nowrap;

  i.icon {
    color: grey;
    font-size: 14px;
    margin-left: 3px;
  }
  .dynamic {
    &:hover {
      background-color: #fffada !important;
      cursor: pointer;
      i.icon {
        color: ${primary};
      }
    }
  }
`;

export const StyledForm = styled.div`
  position: absolute;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  background-color: #ffffff;
  margin-left: -400px;
  margin-top: -25px;
  padding: 10px;
  z-index: 1030;

  .popover-content .editable-input {
    width: 225px;
  }
`;

export const StyledNotSpecified = styled.span`
  color: #999;
  font-style: italic;
`;
