export const equipmentMasterFallbackSamples = [
  {
    id: "41",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1515,
      id: 41,
      position: 1,
      primary_equipment_master_id: 41,
      name: "BrainLAB KICK Navigation Station",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/41",
    },
  },
  {
    id: "42",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 942,
      id: 42,
      position: 2,
      primary_equipment_master_id: 41,
      name: "BrainLAB Curve Image Guided Surgery",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/42",
    },
  },
  {
    id: "44",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1503,
      id: 44,
      position: 3,
      primary_equipment_master_id: 41,
      name: "BrainLAB VectorVision2 Image Guided Surgery",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/44",
    },
  },
  {
    id: "45",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1549,
      id: 45,
      position: 4,
      primary_equipment_master_id: 41,
      name: "BrainLAB VectorVision Image Guided Surgery System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/45",
    },
  },
  {
    id: "96",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1493,
      id: 96,
      position: 1,
      primary_equipment_master_id: 50,
      name: "Lumenis UltraPulse Surgitouch CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/96",
    },
  },
  {
    id: "117",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1660,
      id: 117,
      position: 1,
      primary_equipment_master_id: 58,
      name: "Sound Surgical Technologies VentX 2.0 Aspirator/Liposuction",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/117",
    },
  },
  {
    id: "26",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2250,
      id: 26,
      position: 1,
      primary_equipment_master_id: 63,
      name: "Valleylab Force FX -C Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/26",
    },
  },
  {
    id: "35",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1542,
      id: 35,
      position: 1,
      primary_equipment_master_id: 113,
      name: "Alcon Laboratories Inc Centurion Phacoemulsification Unit",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/35",
    },
  },
  {
    id: "77",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1583,
      id: 77,
      position: 1,
      primary_equipment_master_id: 150,
      name: "CONMED HelixAR Electrosurgical Generator with Argon Beam Coagulation (ABC)",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/77",
    },
  },
  {
    id: "86",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2282,
      id: 86,
      position: 1,
      primary_equipment_master_id: 269,
      name: "Mizuho OSI Trios 7803 Surgical Table",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/86",
    },
  },
  {
    id: "123",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 3122,
      id: 123,
      position: 2,
      primary_equipment_master_id: 269,
      name: "Unknown Unknown Fracture Attachment",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/123",
    },
  },
  {
    id: "113",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 319,
      id: 113,
      position: 1,
      primary_equipment_master_id: 317,
      name: "GE Healthcare OEC 9900 Elite Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/113",
    },
  },
  {
    id: "114",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 344,
      id: 114,
      position: 2,
      primary_equipment_master_id: 317,
      name: "GE Healthcare OEC 9800 Plus Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/114",
    },
  },
  {
    id: "115",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 318,
      id: 115,
      position: 3,
      primary_equipment_master_id: 317,
      name: "GE Healthcare OEC 9800 C-Arm System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/115",
    },
  },
  {
    id: "110",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 319,
      id: 110,
      position: 1,
      primary_equipment_master_id: 318,
      name: "GE Healthcare OEC 9900 Elite Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/110",
    },
  },
  {
    id: "111",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 344,
      id: 111,
      position: 2,
      primary_equipment_master_id: 318,
      name: "GE Healthcare OEC 9800 Plus Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/111",
    },
  },
  {
    id: "112",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 317,
      id: 112,
      position: 3,
      primary_equipment_master_id: 318,
      name: "GE Healthcare OEC 9600 Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/112",
    },
  },
  {
    id: "107",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 344,
      id: 107,
      position: 1,
      primary_equipment_master_id: 319,
      name: "GE Healthcare OEC 9800 Plus Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/107",
    },
  },
  {
    id: "108",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 318,
      id: 108,
      position: 2,
      primary_equipment_master_id: 319,
      name: "GE Healthcare OEC 9800 C-Arm System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/108",
    },
  },
  {
    id: "109",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 317,
      id: 109,
      position: 3,
      primary_equipment_master_id: 319,
      name: "GE Healthcare OEC 9600 Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/109",
    },
  },
  {
    id: "91",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1824,
      id: 91,
      position: 1,
      primary_equipment_master_id: 324,
      name: "Medtronic StealthStation S8 Surgical Navigation System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/91",
    },
  },
  {
    id: "16",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 510,
      id: 16,
      position: 1,
      primary_equipment_master_id: 328,
      name: "Covidien ForceTriad Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/16",
    },
  },
  {
    id: "10",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1302,
      id: 10,
      position: 2,
      primary_equipment_master_id: 328,
      name: "Covidien Force FX Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/10",
    },
  },
  {
    id: "12",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1580,
      id: 12,
      position: 2,
      primary_equipment_master_id: 328,
      name: "Valleylab Force FX Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/12",
    },
  },
  {
    id: "11",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1562,
      id: 11,
      position: 4,
      primary_equipment_master_id: 328,
      name: "Covidien Valleylab FT10 Energy Platform",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/11",
    },
  },
  {
    id: "105",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 319,
      id: 105,
      position: 1,
      primary_equipment_master_id: 344,
      name: "GE Healthcare OEC 9900 Elite Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/105",
    },
  },
  {
    id: "106",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 318,
      id: 106,
      position: 2,
      primary_equipment_master_id: 344,
      name: "GE Healthcare OEC 9800 C-Arm System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/106",
    },
  },
  {
    id: "104",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 317,
      id: 104,
      position: 3,
      primary_equipment_master_id: 344,
      name: "GE Healthcare OEC 9600 Mobile C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/104",
    },
  },
  {
    id: "92",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1824,
      id: 92,
      position: 1,
      primary_equipment_master_id: 347,
      name: "Medtronic StealthStation S8 Surgical Navigation System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/92",
    },
  },
  {
    id: "29",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1659,
      id: 29,
      position: 1,
      primary_equipment_master_id: 499,
      name: "Hologic RM-10-550 MyoSure Hysteroscopic Tissue Removal System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/29",
    },
  },
  {
    id: "71",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2132,
      id: 71,
      position: 1,
      primary_equipment_master_id: 514,
      name: "Hologic Fluoroscan InSight 2 Mini  C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/71",
    },
  },
  {
    id: "72",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 630,
      id: 72,
      position: 2,
      primary_equipment_master_id: 514,
      name: "Hologic Fluoroscan InSight Mini C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/72",
    },
  },
  {
    id: "1",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1037,
      id: 1,
      position: 1,
      primary_equipment_master_id: 544,
      name: "Dutch Ophthalmic CryoStar Cryosurgical System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/1",
    },
  },
  {
    id: "61",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2105,
      id: 61,
      position: 1,
      primary_equipment_master_id: 611,
      name: "Sharplan 1060 CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/61",
    },
  },
  {
    id: "62",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 612,
      id: 62,
      position: 2,
      primary_equipment_master_id: 611,
      name: "Sharplan 1055 Series CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/62",
    },
  },
  {
    id: "69",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2234,
      id: 69,
      position: 3,
      primary_equipment_master_id: 611,
      name: "SSI Laser Engineering Ultra MD 60 Super Pulse CO2 Surgical Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/69",
    },
  },
  {
    id: "63",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2105,
      id: 63,
      position: 1,
      primary_equipment_master_id: 612,
      name: "Sharplan 1060 CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/63",
    },
  },
  {
    id: "64",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 611,
      id: 64,
      position: 2,
      primary_equipment_master_id: 612,
      name: "Sharplan 1050 Series CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/64",
    },
  },
  {
    id: "70",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2234,
      id: 70,
      position: 3,
      primary_equipment_master_id: 612,
      name: "SSI Laser Engineering Ultra MD 60 Super Pulse CO2 Surgical Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/70",
    },
  },
  {
    id: "83",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1590,
      id: 83,
      position: 1,
      primary_equipment_master_id: 619,
      name: "Stryker Neptune 3 Rover Waste Management System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/83",
    },
  },
  {
    id: "84",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1673,
      id: 84,
      position: 2,
      primary_equipment_master_id: 619,
      name: "Stryker Neptune 2 Ultra Waste Management System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/84",
    },
  },
  {
    id: "74",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2132,
      id: 74,
      position: 1,
      primary_equipment_master_id: 630,
      name: "Hologic Fluoroscan InSight 2 Mini  C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/74",
    },
  },
  {
    id: "75",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 514,
      id: 75,
      position: 2,
      primary_equipment_master_id: 630,
      name: "GE Healthcare MiniView 6800 Mini C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/75",
    },
  },
  {
    id: "40",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1515,
      id: 40,
      position: 1,
      primary_equipment_master_id: 942,
      name: "BrainLAB KICK Navigation Station",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/40",
    },
  },
  {
    id: "51",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 41,
      id: 51,
      position: 2,
      primary_equipment_master_id: 942,
      name: "BrainLAB Kolibri Navigation Station 2.0 with Mobile Camera Stand Cranial Navigation System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/51",
    },
  },
  {
    id: "52",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1503,
      id: 52,
      position: 3,
      primary_equipment_master_id: 942,
      name: "BrainLAB VectorVision2 Image Guided Surgery",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/52",
    },
  },
  {
    id: "53",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1549,
      id: 53,
      position: 4,
      primary_equipment_master_id: 942,
      name: "BrainLAB VectorVision Image Guided Surgery System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/53",
    },
  },
  {
    id: "37",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1784,
      id: 37,
      position: 1,
      primary_equipment_master_id: 1016,
      name: "Lumenis Pulse 100H Holmium Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/37",
    },
  },
  {
    id: "5",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1118,
      id: 5,
      position: 1,
      primary_equipment_master_id: 1117,
      name: "Anspach SC2100 Surgical Motor System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/5",
    },
  },
  {
    id: "94",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1064,
      id: 94,
      position: 1,
      primary_equipment_master_id: 1134,
      name: "Dornier MedTech Medilas H Solvo Holmium: YAG Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/94",
    },
  },
  {
    id: "33",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1393,
      id: 33,
      position: 1,
      primary_equipment_master_id: 1146,
      name: "Leica Microsystems M844 F40 / F20 Surgical Microscope",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/33",
    },
  },
  {
    id: "100",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2159,
      id: 100,
      position: 1,
      primary_equipment_master_id: 1244,
      name: "BK Ultrasound bk5000 Ultrasound",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/100",
    },
  },
  {
    id: "18",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1580,
      id: 18,
      position: 1,
      primary_equipment_master_id: 1302,
      name: "Valleylab Force FX Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/18",
    },
  },
  {
    id: "19",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 510,
      id: 19,
      position: 2,
      primary_equipment_master_id: 1302,
      name: "Covidien ForceTriad Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/19",
    },
  },
  {
    id: "20",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 328,
      id: 20,
      position: 3,
      primary_equipment_master_id: 1302,
      name: "Valleylab ForceTriad Electrosurgical Unit",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/20",
    },
  },
  {
    id: "21",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1562,
      id: 21,
      position: 4,
      primary_equipment_master_id: 1302,
      name: "Covidien Valleylab FT10 Energy Platform",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/21",
    },
  },
  {
    id: "95",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1313,
      id: 95,
      position: 1,
      primary_equipment_master_id: 1307,
      name: "Zonare ZS3 Ultrasound System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/95",
    },
  },
  {
    id: "119",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1332,
      id: 119,
      position: 1,
      primary_equipment_master_id: 1374,
      name: "MicroAire Surgical Instruments 1025 Console Power Assisted Liposuction System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/119",
    },
  },
  {
    id: "80",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 998,
      id: 80,
      position: 1,
      primary_equipment_master_id: 1461,
      name: "Aloka ProSound Alpha 6 Ultrasound System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/80",
    },
  },
  {
    id: "81",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1783,
      id: 81,
      position: 2,
      primary_equipment_master_id: 1461,
      name: "Aloka ProSound Alpha 5 Ultrasound System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/81",
    },
  },
  {
    id: "97",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 50,
      id: 97,
      position: 1,
      primary_equipment_master_id: 1493,
      name: "Lumenis UltraPulse ENCORE Fractional CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/97",
    },
  },
  {
    id: "57",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1549,
      id: 57,
      position: 1,
      primary_equipment_master_id: 1503,
      name: "BrainLAB VectorVision Image Guided Surgery System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/57",
    },
  },
  {
    id: "54",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1515,
      id: 54,
      position: 2,
      primary_equipment_master_id: 1503,
      name: "BrainLAB KICK Navigation Station",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/54",
    },
  },
  {
    id: "55",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 942,
      id: 55,
      position: 3,
      primary_equipment_master_id: 1503,
      name: "BrainLAB Curve Image Guided Surgery",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/55",
    },
  },
  {
    id: "56",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 41,
      id: 56,
      position: 4,
      primary_equipment_master_id: 1503,
      name: "BrainLAB Kolibri Navigation Station 2.0 with Mobile Camera Stand Cranial Navigation System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/56",
    },
  },
  {
    id: "46",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 942,
      id: 46,
      position: 1,
      primary_equipment_master_id: 1515,
      name: "BrainLAB Curve Image Guided Surgery",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/46",
    },
  },
  {
    id: "47",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 41,
      id: 47,
      position: 2,
      primary_equipment_master_id: 1515,
      name: "BrainLAB Kolibri Navigation Station 2.0 with Mobile Camera Stand Cranial Navigation System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/47",
    },
  },
  {
    id: "48",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1503,
      id: 48,
      position: 3,
      primary_equipment_master_id: 1515,
      name: "BrainLAB VectorVision2 Image Guided Surgery",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/48",
    },
  },
  {
    id: "49",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1549,
      id: 49,
      position: 4,
      primary_equipment_master_id: 1515,
      name: "BrainLAB VectorVision Image Guided Surgery System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/49",
    },
  },
  {
    id: "34",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 113,
      id: 34,
      position: 1,
      primary_equipment_master_id: 1542,
      name: "Alcon Laboratories Inc INFINITI Phacoemulsification Unit",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/34",
    },
  },
  {
    id: "22",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1302,
      id: 22,
      position: 1,
      primary_equipment_master_id: 1562,
      name: "Covidien Force FX Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/22",
    },
  },
  {
    id: "23",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1580,
      id: 23,
      position: 2,
      primary_equipment_master_id: 1562,
      name: "Valleylab Force FX Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/23",
    },
  },
  {
    id: "24",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 510,
      id: 24,
      position: 3,
      primary_equipment_master_id: 1562,
      name: "Covidien ForceTriad Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/24",
    },
  },
  {
    id: "25",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 328,
      id: 25,
      position: 4,
      primary_equipment_master_id: 1562,
      name: "Valleylab ForceTriad Electrosurgical Unit",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/25",
    },
  },
  {
    id: "13",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1302,
      id: 13,
      position: 1,
      primary_equipment_master_id: 1580,
      name: "Covidien Force FX Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/13",
    },
  },
  {
    id: "14",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 510,
      id: 14,
      position: 2,
      primary_equipment_master_id: 1580,
      name: "Covidien ForceTriad Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/14",
    },
  },
  {
    id: "15",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 328,
      id: 15,
      position: 3,
      primary_equipment_master_id: 1580,
      name: "Valleylab ForceTriad Electrosurgical Unit",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/15",
    },
  },
  {
    id: "17",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1562,
      id: 17,
      position: 4,
      primary_equipment_master_id: 1580,
      name: "Covidien Valleylab FT10 Energy Platform",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/17",
    },
  },
  {
    id: "76",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 150,
      id: 76,
      position: 1,
      primary_equipment_master_id: 1583,
      name: "CONMED System 7550 Argon Beam Coagulator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/76",
    },
  },
  {
    id: "2",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1505,
      id: 2,
      position: 1,
      primary_equipment_master_id: 1590,
      name: "Stryker Neptune Rover Waste Management System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/2",
    },
  },
  {
    id: "3",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1673,
      id: 3,
      position: 2,
      primary_equipment_master_id: 1590,
      name: "Stryker Neptune 2 Ultra Waste Management System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/3",
    },
  },
  {
    id: "4",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 619,
      id: 4,
      position: 3,
      primary_equipment_master_id: 1590,
      name: "Stryker Neptune 2 Waste Management System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/4",
    },
  },
  {
    id: "31",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 499,
      id: 31,
      position: 1,
      primary_equipment_master_id: 1659,
      name: "Hologic RM-10-500 MyoSure Hysteroscopic Tissue Removal System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/31",
    },
  },
  {
    id: "116",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 58,
      id: 116,
      position: 1,
      primary_equipment_master_id: 1660,
      name: "Sound Surgical Technologies VentX-1 Aspirator/Liposuction",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/116",
    },
  },
  {
    id: "122",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2850,
      id: 122,
      position: 1,
      primary_equipment_master_id: 1707,
      name: "Arthrex Trimano Fortis Arm Holder",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/122",
    },
  },
  {
    id: "36",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1016,
      id: 36,
      position: 1,
      primary_equipment_master_id: 1784,
      name: "Lumenis VersaPulse Powersuite 100W Holmium Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/36",
    },
  },
  {
    id: "88",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1576,
      id: 88,
      position: 2,
      primary_equipment_master_id: 1784,
      name: "Trimedyne 1210-VHP OmniPulse MAX YAG Holmium Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/88",
    },
  },
  {
    id: "93",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 347,
      id: 93,
      position: 1,
      primary_equipment_master_id: 1824,
      name: "Medtronic Fusion ENT Navigation System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/93",
    },
  },
  {
    id: "59",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 612,
      id: 59,
      position: 1,
      primary_equipment_master_id: 2105,
      name: "Sharplan 1055 Series CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/59",
    },
  },
  {
    id: "60",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 611,
      id: 60,
      position: 2,
      primary_equipment_master_id: 2105,
      name: "Sharplan 1050 Series CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/60",
    },
  },
  {
    id: "68",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2234,
      id: 68,
      position: 3,
      primary_equipment_master_id: 2105,
      name: "SSI Laser Engineering Ultra MD 60 Super Pulse CO2 Surgical Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/68",
    },
  },
  {
    id: "73",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 630,
      id: 73,
      position: 1,
      primary_equipment_master_id: 2132,
      name: "Hologic Fluoroscan InSight Mini C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/73",
    },
  },
  {
    id: "28",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 514,
      id: 28,
      position: 2,
      primary_equipment_master_id: 2132,
      name: "GE Healthcare MiniView 6800 Mini C-Arm",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/28",
    },
  },
  {
    id: "8",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1031,
      id: 8,
      position: 1,
      primary_equipment_master_id: 2145,
      name: "CONMED System 2450 Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/8",
    },
  },
  {
    id: "9",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1583,
      id: 9,
      position: 2,
      primary_equipment_master_id: 2145,
      name: "CONMED HelixAR Electrosurgical Generator with Argon Beam Coagulation (ABC)",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/9",
    },
  },
  {
    id: "90",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1134,
      id: 90,
      position: 1,
      primary_equipment_master_id: 2162,
      name: "Dornier MedTech Medilas H 20 Surgical Laser - Holmium",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/90",
    },
  },
  {
    id: "124",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1043,
      id: 124,
      position: 1,
      primary_equipment_master_id: 2222,
      name: "Hologic AQL100P Aquilex Fluid Control System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/124",
    },
  },
  {
    id: "65",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2105,
      id: 65,
      position: 1,
      primary_equipment_master_id: 2234,
      name: "Sharplan 1060 CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/65",
    },
  },
  {
    id: "66",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 612,
      id: 66,
      position: 2,
      primary_equipment_master_id: 2234,
      name: "Sharplan 1055 Series CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/66",
    },
  },
  {
    id: "67",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 611,
      id: 67,
      position: 3,
      primary_equipment_master_id: 2234,
      name: "Sharplan 1050 Series CO2 Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/67",
    },
  },
  {
    id: "27",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 63,
      id: 27,
      position: 1,
      primary_equipment_master_id: 2250,
      name: "Covidien Force FX-C Electrosurgical Generator",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/27",
    },
  },
  {
    id: "103",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 269,
      id: 103,
      position: 1,
      primary_equipment_master_id: 2282,
      name: "Mizuho OSI 5803 Jackson Table Advanced Control Modular Base",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/103",
    },
  },
  {
    id: "58",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2220,
      id: 58,
      position: 1,
      primary_equipment_master_id: 2328,
      name: "Medtronic M4 Microdebrider",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/58",
    },
  },
  {
    id: "85",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1064,
      id: 85,
      position: 1,
      primary_equipment_master_id: 2353,
      name: "Dornier MedTech Medilas H Solvo Holmium: YAG Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/85",
    },
  },
  {
    id: "89",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2353,
      id: 89,
      position: 1,
      primary_equipment_master_id: 2373,
      name: "Olympus Empower H65 Holmium Laser",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/89",
    },
  },
  {
    id: "87",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 998,
      id: 87,
      position: 1,
      primary_equipment_master_id: 2601,
      name: "Aloka ProSound Alpha 6 Ultrasound System",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/87",
    },
  },
  {
    id: "121",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1707,
      id: 121,
      position: 1,
      primary_equipment_master_id: 2850,
      name: "Arthrex Trimano Arm Holder",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/121",
    },
  },
  {
    id: "101",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 269,
      id: 101,
      position: 1,
      primary_equipment_master_id: 2865,
      name: "Mizuho OSI 5803 Jackson Table Advanced Control Modular Base",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/101",
    },
  },
  {
    id: "102",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 2282,
      id: 102,
      position: 2,
      primary_equipment_master_id: 2865,
      name: "Mizuho OSI Trios 7803 Surgical Table",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/102",
    },
  },
  {
    id: "120",
    type: "equipment_master_fallbacks",
    attributes: {
      fallback_equipment_master_id: 1014,
      id: 120,
      position: 1,
      primary_equipment_master_id: 2872,
      name: "Karl Storz Modulith SLX-F2 Mobile Urological Workstation (ESWL)",
    },
    links: {
      self: "http://localhost:3000/equipment_masters/120",
    },
  },
];
