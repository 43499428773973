import React from "react";

import Usage from "./Usage";

export default {
  argTypes: { onChange: { action: "Input value changed" } },
  component: Usage,
  decorators: [
    (Story) => (
      <div className="box box-page">
        <div className="box-content">
          <Story />
        </div>
      </div>
    ),
    //withRequestContext,
  ],
  title: "Equipment Preference/Form/Inventory Items/Pill/Usage",
};

const Template = (args) => <Usage {...args} />;

export const Default = Template.bind({});
Default.args = {
  onChange: () => {},
};

// endOfCase: PropTypes.bool,
// startTime: PropTypes.number,
// duration: PropTypes.number,
export const PartialUse = Template.bind({});
PartialUse.args = {
  selected: {
    duration: null,
    endOfCase: true,
    startTime: 15,
  },
  onChange: () => {},
};
