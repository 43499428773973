import React, { useMemo } from "react";
import PropTypes from "prop-types";

import Item from "./item";
import { Component } from "./styled";

export default function EquipmentMasterFallbacks({
  isActive,
  isEditable,
  onChange,
  options,
}) {
  const toggleChange = (isDisabled, index) => {
    if (isDisabled) {
      if (!options[index].value) options[index].value = true;
    } else {
      if (options[index].value) options[index].value = false;
    }
    onChange(options);
  };

  return (
    <Component>
      <div className="title">Fallbacks</div>
      <div className="fallback-list">
        {options.map((item, index) => (
          <Item
            isDisabled={!isActive || !isEditable}
            key={`fallback-${item.meta.id}-${item.meta.position}`}
            onChange={(isDisabled) => toggleChange(isDisabled, index)}
            value={item}
          />
        ))}
      </div>
    </Component>
  );
}

EquipmentMasterFallbacks.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
};
