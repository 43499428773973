import React from "react";
import PropTypes from "prop-types";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import BookingTile from "./calendar/tiles/booking_tile";
import MoveTile from "./calendar/tiles/move_tile";
import RentalBookingTile from "./calendar/tiles/rental_booking_tile";
import { setQueryString, FCD_K, FCN_K } from "./utils/use_query_params";
import { alertFailure, alertSuccess } from "../utils/window";

/*
  UglyCalendar - How data is passed
  - API calls to fetch data depends on eventSource (Move, Booking sources)
    - any state change to the calendar component will refetch from api
    - e.g. filter data, full calendar itself, or navigational elements
    - also when calendar component mounts
    - NOTE: removing an eventSource will remove all events tied to that source,
            the reverse is true for adding
    - NOTE: caching, under the covers, full calendar has some internal caching
            for event data
  - Events specific api calls include adding/remove event tiles
    - e.g. adding a delivery move
    - support for dragging/dropping (TBD)
    - these calls do not affect the rest of the calendar data
  - Event content handler decides which tile type to display and pass data
*/

class UglyCalendar extends React.Component {
  constructor(props) {
    super(props);

    // NOTE: Color Palettes for move tiles
    // console.log here for colors/templates for UglyPainter
    //
    // const rainbowPal = JSON.stringify(generatePaletteConstant());
    this.state = {
      initialDate: props.initialView.date,
      initialView: props.initialView.type || "dayGridMonth",
    };
    this.calendarRef = React.createRef();
  }

  refetchEvents = (types) => {
    const calRef = this.calendarRef.current.getApi();

    return types.map((type) => {
      const sourceId = this.props.eventSources[type].id;
      const eventSource = calRef.getEventSourceById(sourceId);
      if (eventSource) {
        eventSource.refetch();
      } else {
        calRef.addEventSource(this.buildEventSource(type));
      }
    });
  };

  removeEvents = (types) => {
    const calRef = this.calendarRef.current.getApi();

    return types.map((type) => {
      const sourceId = this.props.eventSources[type].id;
      const eventSource = calRef.getEventSourceById(sourceId);
      if (eventSource) {
        eventSource.remove();
      } // else nothing
    });
  };

  componentDidMount() {
    // TODO: Please update this when events modal becomes a React component
    $(document.body).on("booking.update move.update", () => {
      const eventTypes = this.getEventSources()?.map((es) => es.eventType);
      if (eventTypes?.length) {
        this.refetchEvents(eventTypes);
      }
    });
  }

  // Triggers when downstream from parent has changes.
  // This means that filters have changed, and should update events.
  // Navigating on the calendar will not invoke this lifecycle.
  componentDidUpdate(prevProps) {
    const prevData = prevProps.data;
    const currData = this.props.data;

    if (prevData.version == currData.version) return;

    const primaryDataChanged = [
      "equipment_master_id",
      "facility_ids",
      "facility_types",
      "health_system_id",
      "inventory_item_ids",
      "move_badge_ids",
      "move_driver_ids",
      "move_states",
    ].some((dataKey) => prevData[dataKey] !== currData[dataKey]);

    let removedEventTypes = prevData.event_types.filter(
      (x) => !currData.event_types.includes(x)
    );
    let addedEventTypes = currData.event_types.filter(
      (x) => !prevData.event_types.includes(x)
    );

    // TODO: efficiency update
    // This component is expected to be invoked with only one change at a time.
    //
    // May need to uncomment and reuse checks if we accept >1 change per update.
    // e.g. this means when users uncheck or add additional filter elements, have
    // a waiter to wait X seconds before aggregating all the api filter changes at once.
    if (removedEventTypes.length && addedEventTypes.length) {
      this.removeEvents(removedEventTypes); // specific order, removeEvent is faster than refetch
      this.refetchEvents(addedEventTypes);
    } else {
      if (removedEventTypes.length) {
        this.removeEvents(removedEventTypes);

        // Refresh is needed when new changes to data causes stale events
        if (primaryDataChanged) {
          this.refetchEvents(currData.event_types);
        }
      } else if (addedEventTypes.length) {
        // Do not selectively refresh events if new data exists
        if (primaryDataChanged) {
          this.refetchEvents(currData.event_types);
        } else {
          this.refetchEvents(addedEventTypes);
        }
      } else {
        // Note: componentDidUpdate can trigger regardless of filter changes.
        //       Therefore, only refresh data when needed
        if (primaryDataChanged) {
          this.refetchEvents(currData.event_types);
        }
      }
    }
  }

  /*
    EventSource
   */
  getEventSources = () =>
    this.props.data.event_types.map((type) => this.buildEventSource(type));

  buildEventSource = (type) => {
    const source = this.props.eventSources[type];
    return {
      ...source,
      method: "GET",
      extraParams: () => {
        // NOTE: constant init cannot be DRY'd up with outer loop conditional
        //       else staleness/ weird things will happen due to js scoping
        const { data } = this.props;
        return {
          ...data,
          event_types: [type],
          is_requested: data.event_types.includes(type),
        };
      },
      success: (response) => response,
      failure: () => {
        alertFailure(`There was an issue finding ${type}s.`);
      },
    };
  };

  // Support for dragging across dates
  // handleDateSelect = (selectInfo) => {};

  handleSelectAllow = (selectInfo) => {
    const max = selectInfo.end.getTime() / 1000;
    const min = selectInfo.start.getTime() / 1000;

    return max - min <= 86400 ? true : false;
  };

  handleDateClick = (selectInfo) => {
    const { inventory_item_ids } = this.props.data;

    if (!this.props.currentUser.canCreateMove) return;

    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection

    const { dateStr } = selectInfo;
    if (confirm(`Add a move for ${dateStr}?`)) {
      $.ajax({
        url: `/moves.json`,
        method: "POST",
        data: {
          move: {
            move_on: moment(dateStr).format("MM/DD/YYYY"),
            inventory_id: inventory_item_ids[0],
          },
        },
        success: (response) => {
          const moveSource = this.getEventSources()?.find(
            (source) => source.eventType === "move"
          );

          calendarApi.addEvent(response.moves, moveSource.id);
          alertSuccess(`Move created on ${dateStr}`);
        },
        error: (response) => {
          alertFailure(
            `Failed to create move on ${dateStr}`,
            `[${response.status}] ${response.responseJSON.errors[0].title}`
          );
        },
      });
    }
  };

  handleEventClick = (e) => {
    e.jsEvent.preventDefault();

    const targetEl = e.jsEvent.target;

    // Clicking on any tile icon should not open modal
    if (targetEl.closest(".tile-icons") !== null) return;

    const { type, paths } = e.event.extendedProps;

    // NOTE: rental_bookings share the same listener action `booking.click`
    //       with regular bookings.
    const modalListener = type === "Move" ? "move.click" : "booking.click";

    $(document).trigger(modalListener, paths.json);
    return;
  };

  /*
    Renderers & Displays
  */
  handleTileContent = (data) => {
    const { type } = data.event.extendedProps;
    let isFarRightViewTile = false;
    let themeStyles = this.props.timelines.color_map[data.event.groupId];

    if (!themeStyles) {
      themeStyles = {
        primary: "#3e3e3e",
        secondary: "#545454",
        tertiary: "#f1f1f1",
      };
    }
    const endDateStr = data.event.end || data.event.start;

    if (data.event.start.toDateString() == endDateStr.toDateString()) {
      isFarRightViewTile =
        data.view.type == "dayGridDay" || data.event.start.getDay() == 6;
    } else {
      if (data.view.type == "dayGridMonth" || data.view.type == "dayGridWeek") {
        if (!data.isEnd || (data.isEnd && data.event.end.getDay() == 6)) {
          isFarRightViewTile = true;
        }
      } else {
        //dayGridDay
        isFarRightViewTile = true;
      }
    }

    const props = {
      ...data,
      display: this.props.eventDisplay,
      theme: themeStyles,
      handleEquipmentChange: this.props.handleEquipmentChange,
      isFarRightViewTile: isFarRightViewTile,
    };
    if (type === "Booking") {
      return <BookingTile {...props} />;
    } else if (type === "RentalBooking") {
      return <RentalBookingTile {...props} />;
    } else if (type === "Move") {
      return (
        <MoveTile
          {...props}
          canManage={this.props.currentUser.can_manage_deliveries}
        />
      );
    } else {
      alertFailure(
        "Event failed to display",
        `Please check with admin on ${type} events`
      );
    }
  };

  handleEventDidMount = (data) => {
    // Hijacks the toplevel <a /> to inject an href
    // in order to support 'open in new tab' within context menu
    data.el.href = data.event.extendedProps.paths.html;
    $(".tip").tooltip({ container: "body" });
  };

  // Mainly used to maintain query string params
  handleDateSet = ({ view }) => {
    const newStart = moment.utc(view.currentStart).format("YYYY-MM-DD");
    setQueryString(FCD_K, { value: newStart });

    // dayGridMonth, "dayGridWeek", "dayGridDay"
    setQueryString(FCN_K, { value: view.type });
  };

  // noop
  handleEvents = (events) => {};

  handleLoading = (isLoading) => {
    if (isLoading) {
      $("#ugly-calendar-loader").show();
    } else {
      $("#ugly-calendar-loader").hide();
    }
  };

  render() {
    const fullCalendarConfig = {
      height: "auto",
      initialView: this.state.initialView,
      initialDate: this.state.initialDate,
      selectMirror: true,
      dayMaxEvents: false,
      datesSet: this.handleDateSet,
      headerToolbar: {
        left: "prev,today,next",
        center: "title",
        right: "dayGridDay,dayGridWeek,dayGridMonth",
      },
      plugins: [dayGridPlugin, interactionPlugin],
      timeZone: "UTC",
      viewDidMount: this.handleViewMount,
      weekends: true,
    };

    const interactionConfig = {
      editable: false,
      loading: this.handleLoading,
      progressiveEventRendering: true,
      selectable: this.props.currentUser.canCreateMove,
      selectAllow: this.handleSelectAllow,
      // select: this.handleDateSelect, // drag and drop
      dateClick: this.handleDateClick,
      eventAdd: function () {}, // disable eventAdd
    };

    const eventConfig = {
      eventContent: this.handleTileContent, // custom render function
      eventClick: this.handleEventClick,
      eventDidMount: this.handleEventDidMount,
      eventSources: this.getEventSources(),
      eventsSet: this.handleEvents, // called after events are initialized/added/changed/removed
      //initialEvents={this.state.events} // NOTE: add in mock data from event-utils
    };

    return (
      <div className="ugly-calendar">
        <FullCalendar
          {...fullCalendarConfig}
          {...interactionConfig}
          {...eventConfig}
          ref={this.calendarRef}
        />
      </div>
    );
  }
}

// TODO: Proptypes
UglyCalendar.propTypes = {};

export default UglyCalendar;
