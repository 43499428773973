import React from "react";
import PropTypes from "prop-types";

import FallbackSelect from "./fallback_select";

class FallbackPill extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id || null,
      primaryId: this.props.primaryId,
      fallbackId: this.props.fallbackId || null,
      name: this.props.name || null,
      allowedFacilities: this.props.allowedFacilities || [],
      restrictedFacilities: this.props.restrictedFacilities || [],
      position: this.props.position,
      photo: this.props.photo || null,
      link: this.props.link || null,
      editing: this.props.editing || false,
      hover: false,
      newFallbackID: null,
      showDeletePopup: false,
      preventSave: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextProps.reorderingEnabled;
  }

  hoverOn = () => {
    if (this.props.hoverDisabled) return;

    this.setState({ hover: true });
  };

  hoverOff = () => {
    this.setState({ hover: false, showDeletePopup: false });
  };

  onClickEdit = () => {
    this.props.disableHover(true);
    this.setState({ editing: true, showDeletePopup: false });
  };

  onCancelEdit = () => {
    this.props.disableHover(false);
    this.setState({ editing: false });
  };

  facilitiesList = (facilities) => {
    const facilitiesListItems = [];
    facilities &&
      facilities.map((f) => {
        facilitiesListItems.push(f.label);
      });
    return facilitiesListItems.join(", \n");
  };

  onClickSubmit = (
    allowedFacilities,
    restrictedFacilities,
    fallbackId,
    pill
  ) => {
    if (this.state.preventSave) return;

    if (
      JSON.stringify([allowedFacilities, restrictedFacilities, fallbackId]) ==
      JSON.stringify([
        this.state.allowedFacilities,
        this.state.restrictedFacilities,
        this.state.fallbackId,
      ])
    ) {
      this.onCancelEdit();
      pill.setState({ preventSave: false });
      return;
    }

    const params = {
      equipment_master_fallback: {
        allowed_facilities:
          allowedFacilities.length == 0
            ? [{ value: null, label: null }]
            : allowedFacilities,
        restricted_facilities:
          restrictedFacilities.length == 0
            ? [{ value: null, label: null }]
            : restrictedFacilities,
        fallback_equipment_master_id: fallbackId,
      },
    };

    this.setState({ preventSave: true });

    $.ajax({
      url: `../equipment_master_fallbacks/${this.state.id}`,
      method: "PUT",
      data: params,
      success: (fallback) => {
        // May be reenabled following upgrade to rails 2.6 and no longer need window.location.reload()

        // this.setState({
        //   editing: false,
        //   allowedFacilities: fallback.allowed_facilities,
        //   restrictedFacilities: fallback.restricted_facilities,
        //   fallbackId: fallback.id,
        //   name: fallback.name,
        //   photo: fallback.photo,
        //   link: fallback.link,
        //   preventSave: false
        // });

        // this.props.disableHover(false)

        Cohealo.Growl("", {
          header: "Fallback Successfully Updated",
          status: "success",
          icon: "ok",
        });

        // Reload page so fallback change appears in UI table
        window.location.reload();
      },
      error: (event, data) => {
        pill.setState({ preventSave: false });
        this.setState({ preventSave: false });

        Cohealo.Growl(event.responseJSON.errors.join(", "), {
          header: "Fallback Update Failed",
          status: "danger",
          icon: "remove",
        });
      },
    });
  };

  onClickDelete = (event) => {
    this.props.onClickDelete(this.state.id);
    this.setState({ showDeletePopup: false });
  };

  fallbackSelect = () => (
    <FallbackSelect
      id={this.state.id}
      onCancelEdit={this.props.onCancelCreate || this.onCancelEdit}
      onClickSubmit={this.props.onClickCreate || this.onClickSubmit}
      primaryId={this.state.primaryId}
      fallbackId={this.state.fallbackId}
      name={this.state.name}
      allowedFacilities={this.state.allowedFacilities}
      restrictedFacilities={this.state.restrictedFacilities}
      position={this.state.position}
      photo={this.state.photo}
      link={this.state.link}
      equipmentMasters={this.props.equipmentMasters}
      facilities={this.props.facilities}
    />
  );

  render() {
    const {
      name,
      allowedFacilities,
      restrictedFacilities,
      position,
      photo,
      link,
    } = this.state;

    const allowedFacilitiesList = allowedFacilities
      ? this.facilitiesList(allowedFacilities)
      : "Everywhere";
    const restrictedFacilitiesList = allowedFacilities.length
      ? ""
      : this.facilitiesList(restrictedFacilities);
    const positionDisplay = position + ".";

    const masterThumb = this.props.reorderingEnabled ? (
      <div className="master-thumb">
        <img className="master-thumb-img" src={photo} alt={name} />
      </div>
    ) : (
      <a href={link} target="_blank" className="master-thumb-link">
        <div className="master-thumb">
          <img className="master-thumb-img" src={photo} alt={name} />
        </div>
      </a>
    );

    const fallbackName = this.props.reorderingEnabled ? (
      <div className="fallback-name span6">{name}</div>
    ) : (
      <a href={link} target="_blank" className="pill-link span6">
        <div className="fallback-name">{name}</div>
      </a>
    );

    return (
      <div className="fallback-wrapper">
        <div
          className="fallback-container"
          onMouseEnter={this.hoverOn}
          onMouseLeave={this.hoverOff}
        >
          <div className="position-container">
            <div className="position"> {positionDisplay} </div>
          </div>
          <div
            className={
              this.state.editing
                ? "fallback-equipment-master-pill hidden"
                : "fallback-equipment-master-pill show-pill"
            }
          >
            {masterThumb}
            {fallbackName}

            <div
              className="span3 allowed-facilities muted text-center tip"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title={allowedFacilitiesList}
            >
              {allowedFacilitiesList}
            </div>
            <div
              className="span3 denied-facilities muted text-center tip"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title={restrictedFacilitiesList}
            >
              {restrictedFacilitiesList}
            </div>
          </div>
          {!this.state.editing && (
            <span
              className={
                this.state.hover &&
                this.props.editable &&
                !this.props.reorderingEnabled
                  ? "controls-container"
                  : "controls-container hidden"
              }
            >
              <a className="fallback-edit-button" onClick={this.onClickEdit}>
                Edit
              </a>
              <a
                className={`fallback-delete-button ${
                  this.props.reorderingEnabled ? "hidden" : ""
                }`}
                onClick={() => this.setState({ showDeletePopup: true })}
              >
                Delete
              </a>
              <div
                className="action-popup popup pop-nav remove-popup hintarrow arrow-bottom arrow-center"
                style={{
                  display: `${this.state.showDeletePopup ? "block" : "none"}`,
                }}
              >
                <div className="pop-nav-header color-danger">
                  <i className="icon-remove" />
                </div>
                <div className="pop-nav-content">
                  <p className="head">Delete this fallback?</p>
                  <div className="controls text-center">
                    <div
                      className="btn btn-block btn-mini"
                      onClick={this.onClickDelete}
                    >
                      Delete
                    </div>
                    <div
                      className="btn btn-block btn-mini btn-link"
                      onClick={() => this.setState({ showDeletePopup: false })}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
        <div
          className={
            this.state.editing ? "fallback-select" : "fallback-select hidden"
          }
        >
          {this.props.reorderingEnabled ? null : this.fallbackSelect()}
        </div>
      </div>
    );
  }
}

FallbackPill.propTypes = {
  id: PropTypes.number,
  primaryId: PropTypes.number,
  fallbackId: PropTypes.number,
  name: PropTypes.string,
  allowedFacilities: PropTypes.array,
  restrictedFacilities: PropTypes.array,
  position: PropTypes.number,
  photo: PropTypes.string,
  link: PropTypes.string,
  equipmentMasters: PropTypes.array,
  facilities: PropTypes.array,
  editing: PropTypes.bool,
  onCancelCreate: PropTypes.func,
  onClickCreate: PropTypes.func,
  editable: PropTypes.bool,
  reorderingEnabled: PropTypes.bool,
  hoverDisabled: PropTypes.bool,
  disableHover: PropTypes.func,
};

export default FallbackPill;
