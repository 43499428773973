import React, { useEffect, useState } from "react";

const modalEl = document.getElementById("confirmation-modal");

const hideModal = () => $(modalEl).modal("hide");
const showModal = () => $(modalEl).modal("show");

// e.g.
// components: {
//   Body: () => (
//     <div>
//       <strong>
//         Are you sure you want to delete this equipment
//         preference?
//       </strong>
//       <br />
//       <br />
//       <em>This action cannot be undone.</em>)
//     </div>
//   ),
//   ConfirmLabel: () => "Delete",
//   Header: () => "Delete Equipment Preference",
//   Icon: () => "trash",
// },
// onCancel: () => {},
// onConfirm: () => {},

export const useModal = (props) => {
  if (!props) return {};

  const [toggleMount, setToggleMount] = useState(false);

  const mountConfirmationModal = ({
    Body,
    ConfirmLabel,
    Header,
    Icon,
    cancel,
    confirm,
    href,
  }) => {
    if (!modalEl) return modalEl;

    modalEl.querySelector(".modal-header h3.subject").textContent =
      Header || "Confirm";

    let parent = modalEl.querySelector(".icon-container");
    while (parent.firstElementChild)
      parent.removeChild(parent.firstElementChild);

    const icon = document.createElement("i");
    icon.className = `icon-${Icon || "warning-sign"}`;
    parent.appendChild(icon);

    modalEl.querySelector(".modal-body .message").textContent =
      Body || "Please confirm changes before saving";

    modalEl.querySelector(".btn.confirm").textContent =
      ConfirmLabel || "Confirm";
    modalEl.querySelector(".btn.cancel").textContent = "Back to edit";

    modalEl.querySelector(".cancel").addEventListener("click", cancel);
    modalEl.querySelector(".close").addEventListener("click", cancel);
    if (href) {
      // Replacing nodes will remove all eventListeners by design
      const oldNode = modalEl.querySelector(".confirm");
      const newNode = oldNode.cloneNode(true);
      newNode.setAttribute("href", href);
      oldNode.parentNode.replaceChild(newNode, oldNode);
    } else {
      if (modalEl.querySelector(".btn.confirm").href.includes("new?clone_id")) {
        modalEl.querySelector(".btn.confirm").removeAttribute("href");
        modalEl.querySelector(".btn.confirm").setAttribute("href", "#");
      }
      modalEl.querySelector(".confirm").addEventListener("click", confirm);
    }
  };

  const unmountConfirmationModal = ({ cancel, confirm }) => {
    modalEl?.querySelector(".cancel").removeEventListener("click", cancel);
    modalEl?.querySelector(".close").removeEventListener("click", cancel);
    modalEl?.querySelector(".confirm").removeEventListener("click", confirm);
  };

  useEffect(() => {
    if (!props) return;

    const modalProps = {
      ...props.components,
      href: props.href,
      cancel: () => {
        hideModal();
        if (props.onCancel) props.onCancel();
      },
      confirm: () => {
        hideModal();
        props.onConfirm();
      },
    };
    mountConfirmationModal({ ...modalProps });

    return () => unmountConfirmationModal({ ...modalProps });
  }, [toggleMount]);

  const toggleMountFn = () => setToggleMount(!toggleMount);

  return { hideModal, showModal, toggleMount: toggleMountFn };
};
