import React from "react";
import PropTypes from "prop-types";

import { StyledNotes } from "./styled";
import { useDelayedInput } from "../../../utils/useDelayedChange";

const FieldNotes = ({ disabled, onChange, value }) => {
  const [inputValue, handleInputChange] = useDelayedInput(value, onChange);

  return (
    <textarea
      className="input-block-level"
      disabled={disabled}
      onChange={handleInputChange}
      name="equipment_preference[notes]"
      value={inputValue}
    />
  );
};

export default function Notes({ handleChange, isDisabled, isEditable, value }) {
  return (
    <StyledNotes>
      <h3>Notes:</h3>
      {isEditable ? (
        <FieldNotes
          disabled={isDisabled}
          onChange={handleChange}
          value={value}
        />
      ) : (
        <div>{value?.length ? value : "N/A"}</div>
      )}
    </StyledNotes>
  );
}

FieldNotes.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

Notes.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  notes: PropTypes.string,
};
