import React from "react";

import { Chip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import _ from "lodash";
import { operatorsWithUndefinedValues } from "./utils";
import { getValueAsStringInventory } from "../columns/utils";

export default function FilterChips({
  data,
  onClick,
  onDelete,
  title,
  value,
  xs,
}) {
  const getLabel = (props) => {
    const { field, operator, value } = props;

    let label;
    if (field === "inventoryItems" && operator === "isAnyOf") {
      label = getValueAsStringInventory(value);
    } else {
      const filterValue =
        typeof value === "object"
          ? Array.isArray(value)
            ? value.some((val) => typeof val === "object")
              ? value.map((val) => val.attributes.name)
              : value
            : value.attributes.name
          : value;
      const mapping = data.fieldMap[field];

      const operatorLabel = `${data.apiRef.current.getLocaleText(
        `filterOperator${_.upperFirst(operator)}`
      )}`;

      // OPTIMIZE mapping field instead of type or maybe both
      if (operator === "containAnyOf") {
        label = `Contain ${filterValue.join(", ")}`;
      } else if (operator === "is") {
        label = ["false", "true"].includes(filterValue)
          ? `${filterValue === "false" ? "not " : ""}${mapping.name}`
          : filterValue;
      } else if (operator === "isAnyOf") {
        label = `Any ${
          Array.isArray(filterValue) ? filterValue.join(", ") : filterValue
        }`;
      } else if (operator === "not") {
        label = `Not ${filterValue}`;
      } else if (operatorsWithUndefinedValues.includes(operator)) {
        label = `${
          field === "inventoryItems" ? "Equipment" : mapping?.name ?? field
        } ${operatorLabel}`;
      }
      if (label) return label;

      switch (mapping.type) {
        case "boolean":
          label = `${filterValue === "false" ? "Not " : ""}${mapping.name}`;
          break;
        default:
          label = `${operatorLabel} ${filterValue}`;
      }
    }
    return label;
  };

  return value.length ? (
    <Grid container spacing={1} xs={xs}>
      <Grid display="flex" alignItems="center" xs="auto">
        <Typography variant="overline" display="block">
          {title}
          {data.logicOperator === "or" && (
            <Chip label="or" size="small" sx={{ ml: "5px" }} />
          )}
        </Typography>
      </Grid>
      <Grid xs>
        {value.map((chip, index) => (
          <Chip
            key={`chip-${chip.field}-${index}`}
            label={getLabel(chip)}
            {...(onClick && { onClick })}
            onDelete={() => onDelete(index)}
            sx={{ fontSize: 11, ml: 1, maxWidth: 500 }}
            title={getLabel(chip)}
            variant="outlined"
          />
        ))}
      </Grid>
    </Grid>
  ) : null;
}
