import React from "react";
import PropTypes from "prop-types";

import InventoryItem from "./inventory_item";
import InventoryItemEdit from "./inventory_item_edit";

const SHORT_NOTICE_DEP_INPUTS = [
  "#patient_case_during_at_begin_date",
  "#patient_case_facility_id",
].join(", ");

const UNAVAIL_DEP_INPUTS = [
  "#patient_case_during_at_begin_date",
  "#patient_case_during_at_end_date",
  "#patient_case_during_at_begin_time",
  "#patient_case_during_at_end_time",
  "#patient_case_facility_id",
  "#patient_case_location_id",
  "#patient_case_room_id",
].join(", ");

class InventoryItemForm extends React.Component {
  constructor(props) {
    super(props);

    let invMap = {};
    let idx = 0;
    for (let invProps of Object.values(this.props.equipment)) {
      invMap[idx] = {
        ...invProps,
        dataVersion: this.getDataVersion(),
        editable: false,
      };
      idx++;
    }
    this.state = {
      currentUser: { ...this.props.currentUser, editor: true },
      inventoryItems: invMap,
      isLoading: true,
      isEditingFieldId: null,
      options: { equipmentMasters: [] },
    };

    // Some inputs are tied and triggers >1 change
    // debounce() is used here to allow only the latest
    // change to take effect. Which, helps eliminate
    // useless ajax calls
    this.refreshInventoryStates = _.debounce(
      () => this.getInventoryStates(),
      25
    );
    if (this.isWritable()) {
      this.refreshShortNotice = _.debounce(
        () => this.handleBorrowingOnShortNotice(),
        25
      );
    }
  }

  getDataVersion = () => new Date().getTime();

  isWritable = () => !this.props.readOnly;

  componentDidMount() {
    this.setEquipmentMasters();
    if (this.props.isRequested) this.refreshInventoryStates; // prop is returned after validation error
    this.handleBorrowingOnShortNotice();

    if (this.isWritable()) {
      $(SHORT_NOTICE_DEP_INPUTS).on("change", this.refreshShortNotice);
    }

    $(UNAVAIL_DEP_INPUTS).on("change", this.refreshInventoryStates);

    $("#hidden-updated-by").on("change", this.manageInventoryByCurrentUser);
  }

  componentWillUnmount() {
    if (this.isWritable()) {
      $(SHORT_NOTICE_DEP_INPUTS).off("change", this.refreshShortNotice);
    }
    $(UNAVAIL_DEP_INPUTS).off("change", this.refreshInventoryStates);
    $("#hidden-updated-by").off("change", this.manageInventoryByCurrentUser);
  }

  handleBorrowingOnShortNotice = () => {
    const hideBanner = () =>
      $("#short-notice-msg").not(".hidden").addClass("hidden");

    if (this.hasNoActiveEquipment()) {
      hideBanner();
      return true;
    }

    // this.props.readOnly
    const today = moment().startOf("days");
    const threshold = moment().add(2, "days");
    const case_start = moment(
      $("#patient_case_during_at_begin_date").val(),
      "MM-DD-YY"
    );

    if (case_start.isBetween(today, threshold, "days", "[]")) {
      const showBanner = () =>
        $("#short-notice-msg.hidden").removeClass("hidden");
      const facilityId = $("#patient_case_facility_id").val();
      const campusId = $("#patient_case_campus_id").val();

      // Find if any inventory is being borrowed
      // Note: truthy is when inventory is not within campus or the same facility
      const isBorrowing = !!this.getActiveEquipment()?.find((sel) => {
        const invHomeId = sel.homeFacility.id;
        if (facilityId == invHomeId) {
          return false;
        } else {
          const invCampusId = sel.homeFacility.campusId;
          // Presence check against null/undef/""/0
          return !!campusId && !!invCampusId
            ? !(campusId == invCampusId)
            : true;
        }
      });
      isBorrowing ? showBanner() : hideBanner();
    } else {
      hideBanner();
    }
  };

  handleManuallyModified = () => {
    $(".editable-requires-equipment").editable("setValue", false, false);
  };

  manageInventoryByCurrentUser = (e) => {
    const currentUserId = e.target.value;
    if (this.state.currentUser.id != currentUserId) {
      const currState = this.state;
      const newUser = {
        ...currState.currentUser,
        id: currentUserId,
        isNonDefaultUser: true,
        name: $("#patient_case_updated_by_id").text().trim(),
      };
      for (const key of Object.keys(currState.inventoryItems)) {
        const invProps = currState.inventoryItems[key];
        if (!invProps.editable && invProps.reservedBy.editor) {
          invProps.reservedBy = newUser;
        }
      }
      this.setState({ ...currState, currentUser: newUser });
    }
  };

  setEquipmentMasters = () => {
    $.ajax({
      url: this.props.urls.equipmentMasters,
      method: "GET",
      data: {
        facility_id: $("#patient_case_facility_id").val(),
        health_system_id: $("#patient_case_health_system_id").val(),
      },
      success: (response) => {
        this.setState({
          ...this.state,
          isLoading: false,
          options: { equipmentMasters: JSON.parse(response.data) },
        });
      },
      error: (response) => {
        // console.log(respopnse);
        this.setState({ ...this.state, isLoading: false });
        return response;
      },
    });
  };

  // Inventory list can contain rentals or inventory that is pending removal
  // This check is in support for "active" equipment or equipment pending to reserve
  getActiveEquipment = () =>
    Object.values(this.state.inventoryItems).filter(
      (inv) =>
        inv.inventoryItemId &&
        !inv.isDestroyable &&
        !inv.editable &&
        inv.type !== "RentableInventory"
    );

  hasNoActiveEquipment = () => (this.getActiveEquipment()?.length ?? 0) == 0;

  getInventoryStates = () => {
    const { inventoryItems } = this.state;
    if (this.hasNoActiveEquipment()) {
      // Component can have inventory that is being edited.
      // We need to refresh component to trigger an ajax refresh
      // due to external inputs
      let dataVersion;
      let currState = this.state;
      for (const k of Object.keys(inventoryItems)) {
        if (inventoryItems[k].editable) {
          if (!dataVersion) dataVersion = this.getDataVersion();
          currState.inventoryItems[k].dataVersion = dataVersion;
        }
      }
      if (dataVersion) this.setState({ ...currState }); // refresh data for open pills
      return;
    }

    const url = this.props.urls.inventoryItems;
    const usages = {};
    const inventoryItemIds = this.getActiveEquipment()?.map((pcii) => {
      const inventoryItemId = pcii.inventoryItemId;
      usages[inventoryItemId] = {
        start_time: pcii.usage.startTime || 0,
        duration: pcii.usage.duration || 0,
      };
      return inventoryItemId;
    });
    const startDate = moment(
      $("#patient_case_during_at_begin_date").val(),
      "MM-DD-YY"
    ).format("YYYY-MM-DD");
    const startTime = $("#patient_case_during_at_begin_time").val();
    const endDate = moment(
      $("#patient_case_during_at_end_date").val(),
      "MM-DD-YY"
    ).format("YYYY-MM-DD");
    const endTime = $("#patient_case_during_at_end_time").val();

    $.ajax({
      url: url,
      method: "GET",
      data: {
        end_at: `${endDate} ${endTime}`,
        facility_id: $("#patient_case_facility_id").val(),
        inventory_item_ids: inventoryItemIds,
        location_id: $("#patient_case_location_id").val(),
        patient_case_id: $("#edit_patient_case").data("patientCaseId"),
        room_id: $("#patient_case_room_id").val(),
        start_at: `${startDate} ${startTime}`,
        type: "edit",
        usages: usages,
      },
      success: (response) => {
        const currInv = this.state.inventoryItems;
        const data = JSON.parse(response.data);
        const dataVersion = this.getDataVersion();
        const map = {};
        // Write to map { invId => response.data }
        // for faster mapping to existing inv states
        if (data.length) {
          data.forEach((d) => (map[parseInt(d.value)] = d.data));
        }

        for (const key of Object.keys(currInv)) {
          const invProps = currInv[key];
          // Updating dataVersion allows downstream to refresh accordingly
          invProps.dataVersion = dataVersion;

          // For pills with selected inventory
          // including edit pills (in case user clicks cancel)
          if (map[invProps.inventoryItemId]) {
            currInv[key] = {
              ...invProps,
              ...map[invProps.inventoryItemId],
            };
          }
        }
        this.setState({
          ...this.state,
          inventoryItems: currInv,
        });
      },
      error: (response) => {},
    });
  };

  handleAdd = (e) => {
    e.preventDefault();
    const currState = this.state;
    const keys = Object.keys(currState.inventoryItems);
    const fieldId = keys.length ? parseInt(keys[keys.length - 1]) + 1 : 0;
    currState.inventoryItems[fieldId] = {
      editable: true,
      isNew: true,
    };

    this.setState({ ...currState, isEditingFieldId: fieldId.toString() });
  };

  handleCancel = (form) => {
    const currState = this.state;
    if (form.isNew) {
      delete currState.inventoryItems[form.fieldId];
    } else {
      currState.inventoryItems[form.fieldId].editable = false;
    }
    this.setState({ ...currState, isEditingFieldId: null });
  };

  handleEdit = (fieldId) => {
    const currState = this.state;
    if (!currState.inventoryItems[fieldId].editable) {
      currState.inventoryItems[fieldId].editable = true;
      this.setState({ ...currState, isEditingFieldId: fieldId });
    }
  };

  handleRemove = (fieldId) => {
    const currState = this.state;
    const field = currState.inventoryItems[fieldId];
    if (field.id) {
      currState.inventoryItems[fieldId] = {
        id: field.id,
        isDestroyable: true,
      };
    } else {
      delete currState.inventoryItems[fieldId];
    }
    this.setState({ ...currState, isEditingFieldId: null }, () => {
      this.handleBorrowingOnShortNotice();
      // NOTE:
      // Behavior mimics prod, although UX is non-optimal.
      // Deleting equipment equipment will flip requires equipment to be false
      // However, deleting equipment that is being edited will not flip the flag
      // Please update this, once we determine a better UX surround manually
      // modified/ requires equipment flag.
      if (!field.editable) this.handleManuallyModified();
    });
  };

  usageChanged = (usageA, usageB) =>
    usageA.startTime != usageB.startTime || usageA.duration != usageB.duration;

  handleSubmit = (form) => {
    const currState = this.state;
    if (form.isNew) {
      delete currState.inventoryItems[form.fieldId];
    } else {
      const initialState = this.props.equipment[form.fieldId];
      const changes = { ...form, editable: false };

      // Retain autoreserved states if equipment is still the same
      if (
        initialState &&
        initialState.autoreserved &&
        initialState.inventoryItemId == changes.inventoryItemId
      ) {
        // User can mess around with partial use without reseting autoreserved_usage
        if (!this.usageChanged(initialState.usage, changes.usage)) {
          changes.usage = initialState.usage;
        }
        changes.autoreserved = true;
        changes.equipmentPreference = initialState.equipmentPreference;
      }

      // Maintain full usage state
      const { usage } = changes;
      if (usage && !usage.fullCaseTimeUsage) {
        const defaultStart = !usage.startTime || usage.startTime == 0;
        const defaultDuration = !usage.duration || usage.duration == 0;
        if (defaultStart && defaultDuration)
          changes.usage.fullCaseTimeUsage = true;
      }

      currState.inventoryItems[form.fieldId] = changes;
    }
    this.setState({ ...currState, isEditingFieldId: null }, () =>
      this.handleBorrowingOnShortNotice()
    );
  };

  renderAddEquipmentComponent = () => {
    const { isEditingFieldId, isLoading } = this.state;
    const inventoryItems = Object.values(this.state.inventoryItems);
    const activeEquipmentLength = inventoryItems.filter(
      (inv) => !inv.isDestroyable
    ).length;

    let AddEquipmentComponent = null;
    if (activeEquipmentLength > 0) {
      if (this.isWritable() && !isLoading && !isEditingFieldId) {
        AddEquipmentComponent = <ButtonAddEquipment onClick={this.handleAdd} />;
      }
    } else {
      AddEquipmentComponent = (
        <ButtonNoEquipment
          onClick={this.handleAdd}
          disabled={isLoading}
          isWritable={this.isWritable()}
        />
      );
    }
    return AddEquipmentComponent;
  };

  // Unavailable or In use equipment can be saved to case due
  // to a mixture of conflict management checks and past date check
  checkIsUnavailableToSave = ({ unavailabilities, usages }) => {
    // NOTE: !! helps turn these into bool values
    // when checking both, e.g. 0 && 0 => 0 or 0 && 7 => 0
    // js sucks...
    const hasUnavailables = !!(unavailabilities && unavailabilities.length);
    if (hasUnavailables) return true;

    const hasUsageConflicts = !!(usages && usages.length);
    if (hasUsageConflicts) {
      let isConflictMgmtEnabled =
        $("#patient_case_conflict_management").val() === "true";
      if (isConflictMgmtEnabled) {
        const caseZone =
          $("#patient_case_conflict_management").data("timeZone") || null;
        const startDate = moment.tz(
          $("#patient_case_during_at_begin_date").val(),
          "MM-DD-YY",
          caseZone
        );
        const nowThreshold = moment().tz(caseZone).startOf("day").add(1, "d");

        // Same logic as enforce_conflict_management check in patient_case.rb
        // conflitcs are off for past case
        if (startDate.isBefore(nowThreshold)) isConflictMgmtEnabled = false;
      }
      if (isConflictMgmtEnabled) return true;
    }
    return false;
  };

  render() {
    const { inventoryItems } = this.state;
    const inventoryItemPillKeys = Object.keys(inventoryItems);
    const { isEditingFieldId, isLoading } = this.state;

    const selectedInventoryIds = inventoryItemPillKeys.flatMap((k) => {
      const inv = inventoryItems[k];
      return inv.inventoryItemId && inv.type !== "RentableInventory"
        ? [parseInt(inv.inventoryItemId)]
        : [];
    });

    return (
      <div className="row-fluid case-edit-row">
        <i className="sprite-icon sprite-plus-m-grey-light pull-left case-edit-icon" />
        <div className="case-edit-fields" id="patient_cases_inventory_items">
          <div id="inventory_items_details">
            <h3 className="equipment-selection-heading">Equipment</h3>
            {inventoryItemPillKeys.map((k) => {
              const inventoryItemProps = inventoryItems[k];
              const propInventoryId = inventoryItemProps.inventoryItemId;
              const isEnabled =
                !isLoading &&
                (isEditingFieldId == null || isEditingFieldId == k);

              return (
                <InventoryItemPill
                  editable={inventoryItemProps.editable}
                  enabled={isEnabled}
                  fieldId={k}
                  handleEdit={this.handleEdit}
                  handleRemove={this.handleRemove}
                  isUnavailable={
                    inventoryItemProps.editable
                      ? false
                      : this.checkIsUnavailableToSave(inventoryItemProps)
                  }
                  key={inventoryItems[k].id || `new_${k}`}
                  readOnly={this.props.readOnly || false}
                >
                  {inventoryItemProps.editable ? (
                    <InventoryItemEdit
                      {...inventoryItemProps}
                      currentUser={this.state.currentUser}
                      dataVersion={inventoryItemProps.dataVersion}
                      filterableInventory={
                        propInventoryId
                          ? selectedInventoryIds.filter(
                              (id) => propInventoryId != id
                            )
                          : selectedInventoryIds
                      }
                      handleCancel={this.handleCancel}
                      handleSubmit={this.handleSubmit}
                      options={{ ...this.state.options }}
                      urlInventoryItems={this.props.urls.inventoryItems}
                    />
                  ) : (
                    <InventoryItem {...inventoryItemProps} />
                  )}
                </InventoryItemPill>
              );
            })}
            {this.renderAddEquipmentComponent()}
          </div>
        </div>
      </div>
    );
  }
}
export default InventoryItemForm;

const InventoryItemPill = (props) => {
  const Controls = () => (
    <span className="equipment-controls case-edit-spacing">
      <a
        className="edit-selected-inventory-item-button"
        onClick={() => props.handleEdit(props.fieldId)}
        style={{ cursor: "pointer", textDecoration: "none" }}
      >
        Edit
      </a>
      <a
        className="remove-selected-inventory-item-button"
        onClick={() => props.handleRemove(props.fieldId)}
        style={{ cursor: "pointer", textDecoration: "none" }}
      >
        Delete
      </a>
    </span>
  );

  return (
    <div
      className={`fields${props.isUnavailable ? " unavailable-inventory" : ""}`}
      data-inventory-item-id={props.id}
      id={props.fieldId}
    >
      {React.cloneElement(props.children, {
        controlComponent: !props.readOnly && props.enabled && Controls,
        fieldId: props.fieldId,
      })}
    </div>
  );
};

const ButtonNoEquipment = ({ disabled, isWritable, onClick }) => {
  const isDisabled = disabled || !isWritable;
  const content = isWritable ? (
    <a className="target-outline" onClick={isDisabled ? undefined : onClick}>
      <h3>No equipment added yet</h3>
      <p className="lead">
        Start tracking utilization by adding equipment to this case
      </p>
      <span
        className={`btn btn-primary btn-large${isDisabled ? " disabled" : ""}`}
      >
        + Add Equipment
      </span>
    </a>
  ) : (
    <a className="target-outline" onClick={isDisabled ? undefined : onClick}>
      <h3>Equipment cannot be added to this case</h3>
    </a>
  );

  return (
    <div
      id="no-equipment-info"
      style={{ cursor: isDisabled ? "default" : "pointer" }}
    >
      {content}
    </div>
  );
};

const ButtonAddEquipment = ({ onClick }) => (
  <div className="equipment-selector-controls">
    <a
      id="add-more-equipment-link"
      data-automation-id="add_more_equipment_link"
      style={{ display: "inline", cursor: "pointer" }}
      onClick={onClick}
    >
      + Add equipment
    </a>
  </div>
);
