export const physicianSample = [
  {
    id: "2903",
    type: "physicians",
    attributes: {
      name: "HENRY, REYNOLD (M.D.)",
      normalized_name: "henry, reynold (m.d.) [736733]",
      coded_name: "HENRY, REYNOLD (M.D.) [736733]",
    },
  },
  {
    id: "11753",
    type: "physicians",
    attributes: {
      name: "Ripley, Leila C",
      normalized_name: "ripley, leila c",
      coded_name: "Ripley, Leila C",
    },
  },
  {
    id: "4612",
    type: "physicians",
    attributes: {
      name: "Calmet, Fernando H",
      normalized_name: "calmet, fernando h",
      coded_name: "Calmet, Fernando H",
    },
  },
  {
    id: "5350",
    type: "physicians",
    attributes: {
      name: "Zlatev, Dimitar V (Primary - 1)",
      normalized_name: "zlatev, dimitar v (primary - 1)",
      coded_name: "Zlatev, Dimitar V (Primary - 1)",
    },
  },
  {
    id: "9779",
    type: "physicians",
    attributes: {
      name: "BROWN, DEBRA A",
      normalized_name: "brown, debra a [26253]",
      coded_name: "BROWN, DEBRA A [26253]",
    },
  },
  {
    id: "2614",
    type: "physicians",
    attributes: {
      name: "ALKHUZIEM, MAHA MOHAMMAD (M.D.)",
      normalized_name: "alkhuziem, maha mohammad (m.d.) [789170]",
      coded_name: "ALKHUZIEM, MAHA MOHAMMAD (M.D.) [789170]",
    },
  },
  {
    id: "9251",
    type: "physicians",
    attributes: {
      name: "MUFFLY, MATTHEW TIMOTHY (M.D.)",
      normalized_name: "muffly, matthew timothy (m.d.) [775772]",
      coded_name: "MUFFLY, MATTHEW TIMOTHY (M.D.) [775772]",
    },
  },
  {
    id: "311",
    type: "physicians",
    attributes: {
      name: "LAU, JAMES HON-KIT (M.D.)",
      normalized_name: "lau, james hon-kit (m.d.) [10080873]",
      coded_name: "LAU, JAMES HON-KIT (M.D.) [10080873]",
    },
  },
  {
    id: "8359",
    type: "physicians",
    attributes: {
      name: "NOROOZ, MOSTAFA",
      normalized_name: "norooz, mostafa [3218]",
      coded_name: "NOROOZ, MOSTAFA [3218]",
    },
  },
  {
    id: "7576",
    type: "physicians",
    attributes: {
      name: "MOMAH, KINGSON I, MD",
      normalized_name: "momah, kingson i, md [2499]",
      coded_name: "MOMAH, KINGSON I, MD [2499]",
    },
  },
  {
    id: "2968",
    type: "physicians",
    attributes: {
      name: "LONGORIA, TERESA CODINI (M.D.)",
      normalized_name: "longoria, teresa codini (m.d.) [360204]",
      coded_name: "LONGORIA, TERESA CODINI (M.D.) [360204]",
    },
  },
  {
    id: "1372",
    type: "physicians",
    attributes: {
      name: "TAKAMI, CAROL YUMIKO (M.D.)",
      normalized_name: "takami, carol yumiko (m.d.) [10000359]",
      coded_name: "TAKAMI, CAROL YUMIKO (M.D.) [10000359]",
    },
  },
  {
    id: "11269",
    type: "physicians",
    attributes: {
      name: "MACCUMBER, MATHEW W",
      normalized_name: "maccumber, mathew w [10733]",
      coded_name: "MACCUMBER, MATHEW W [10733]",
    },
  },
  {
    id: "6392",
    type: "physicians",
    attributes: {
      name: "Hutter, Matthew M (Primary - 1)",
      normalized_name: "hutter, matthew m (primary - 1)",
      coded_name: "Hutter, Matthew M (Primary - 1)",
    },
  },
  {
    id: "11831",
    type: "physicians",
    attributes: {
      name: "PARK, HANNAH K",
      normalized_name: "park, hannah k [70838]",
      coded_name: "PARK, HANNAH K [70838]",
    },
  },
  {
    id: "10267",
    type: "physicians",
    attributes: {
      name: "SULLIVAN, MARY LORAINE (M.D.)",
      normalized_name: "sullivan, mary loraine (m.d.) [1067033]",
      coded_name: "SULLIVAN, MARY LORAINE (M.D.) [1067033]",
    },
  },
  {
    id: "11949",
    type: "physicians",
    attributes: {
      name: "AHMAD, SHAHJEHAN",
      normalized_name: "ahmad, shahjehan [58616]",
      coded_name: "AHMAD, SHAHJEHAN [58616]",
    },
  },
  {
    id: "4572",
    type: "physicians",
    attributes: {
      name: "Hirsch, Joshua A",
      normalized_name: "hirsch, joshua a",
      coded_name: "Hirsch, Joshua A",
    },
  },
  {
    id: "1543",
    type: "physicians",
    attributes: {
      name: "ANANDA, AJAY KUMAR (M.D.)",
      normalized_name: "ananda, ajay kumar (m.d.) [174340]",
      coded_name: "ANANDA, AJAY KUMAR (M.D.) [174340]",
    },
  },
  {
    id: "2363",
    type: "physicians",
    attributes: {
      name: "XU, MING-MING (M.D.)",
      normalized_name: "xu, ming-ming (m.d.) [739500]",
      coded_name: "XU, MING-MING (M.D.) [739500]",
    },
  },
  {
    id: "2842",
    type: "physicians",
    attributes: {
      name: "DHESI, PAVITTARPAUL SINGH (M.D.)",
      normalized_name: "dhesi, pavittarpaul singh (m.d.) [550493]",
      coded_name: "DHESI, PAVITTARPAUL SINGH (M.D.) [550493]",
    },
  },
  {
    id: "1761",
    type: "physicians",
    attributes: {
      name: "KOHORN, RONALD STEVEN (M.D.)",
      normalized_name: "kohorn, ronald steven (m.d.) [10061114]",
      coded_name: "KOHORN, RONALD STEVEN (M.D.) [10061114]",
    },
  },
  {
    id: "3103",
    type: "physicians",
    attributes: {
      name: "HARRIS, MITCHEL B",
      normalized_name: "harris, mitchel b [1005940]",
      coded_name: "HARRIS, MITCHEL B [1005940]",
    },
  },
  {
    id: "1433",
    type: "physicians",
    attributes: {
      name: "SHI, LEI (M.D.)",
      normalized_name: "shi, lei (m.d.) [561711]",
      coded_name: "SHI, LEI (M.D.) [561711]",
    },
  },
  {
    id: "1163",
    type: "physicians",
    attributes: {
      name: "MUSOKE, LOIS NAKIBUKA (M.D.)",
      normalized_name: "musoke, lois nakibuka (m.d.) [10000602]",
      coded_name: "MUSOKE, LOIS NAKIBUKA (M.D.) [10000602]",
    },
  },
  {
    id: "9803",
    type: "physicians",
    attributes: {
      name: "GUTIERREZ, SARAH LUCIA (C.N.M.)",
      normalized_name: "gutierrez, sarah lucia (c.n.m.) [158905]",
      coded_name: "GUTIERREZ, SARAH LUCIA (C.N.M.) [158905]",
    },
  },
  {
    id: "10558",
    type: "physicians",
    attributes: {
      name: "JOHNSON, SHALINI",
      normalized_name: "johnson, shalini [16065]",
      coded_name: "JOHNSON, SHALINI [16065]",
    },
  },
  {
    id: "8726",
    type: "physicians",
    attributes: {
      name: "ERIKSSON, EVERT",
      normalized_name: "eriksson, evert [1118]",
      coded_name: "ERIKSSON, EVERT [1118]",
    },
  },
  {
    id: "9494",
    type: "physicians",
    attributes: {
      name: "FRENCH, FREDERICK P, MD",
      normalized_name: "french, frederick p, md [12042]",
      coded_name: "FRENCH, FREDERICK P, MD [12042]",
    },
  },
  {
    id: "11675",
    type: "physicians",
    attributes: {
      name: "Harris, Mitchel B",
      normalized_name: "harris, mitchel b",
      coded_name: "Harris, Mitchel B",
    },
  },
  {
    id: "3670",
    type: "physicians",
    attributes: {
      name: "DALY, SUN CHOE (M.D.)",
      normalized_name: "daly, sun choe (m.d.) [587627]",
      coded_name: "DALY, SUN CHOE (M.D.) [587627]",
    },
  },
  {
    id: "9913",
    type: "physicians",
    attributes: {
      name: "Choi, Bryan D",
      normalized_name: "choi, bryan d",
      coded_name: "Choi, Bryan D",
    },
  },
  {
    id: "10537",
    type: "physicians",
    attributes: {
      name: "HOUTING, THOMAS V",
      normalized_name: "houting, thomas v [171]",
      coded_name: "HOUTING, THOMAS V [171]",
    },
  },
  {
    id: "5571",
    type: "physicians",
    attributes: {
      name: "Nehra, Ajay (Primary - 2)",
      normalized_name: "nehra, ajay (primary - 2)",
      coded_name: "Nehra, Ajay (Primary - 2)",
    },
  },
  {
    id: "5901",
    type: "physicians",
    attributes: {
      name: "Cone, Eugene B (Primary)",
      normalized_name: "cone, eugene b (primary)",
      coded_name: "Cone, Eugene B (Primary)",
    },
  },
  {
    id: "7832",
    type: "physicians",
    attributes: {
      name: "SCHMIDT, BRENDA J, MD",
      normalized_name: "schmidt, brenda j, md [68643]",
      coded_name: "SCHMIDT, BRENDA J, MD [68643]",
    },
  },
  {
    id: "1421",
    type: "physicians",
    attributes: {
      name: "COFFMAN, KATHLEEN M (P.A.)",
      normalized_name: "coffman, kathleen m (p.a.) [138706]",
      coded_name: "COFFMAN, KATHLEEN M (P.A.) [138706]",
    },
  },
  {
    id: "1482",
    type: "physicians",
    attributes: {
      name: "WATANABE, FREDERICK DANIEL (M.D.)",
      normalized_name: "watanabe, frederick daniel (m.d.) [5454]",
      coded_name: "WATANABE, FREDERICK DANIEL (M.D.) [5454]",
    },
  },
  {
    id: "7425",
    type: "physicians",
    attributes: {
      name: "PEREZ, KARA L, PA-C",
      normalized_name: "perez, kara l, pa-c [223887]",
      coded_name: "PEREZ, KARA L, PA-C [223887]",
    },
  },
  {
    id: "9622",
    type: "physicians",
    attributes: {
      name: "GOFF, JAMES S",
      normalized_name: "goff, james s [23824]",
      coded_name: "GOFF, JAMES S [23824]",
    },
  },
  {
    id: "5466",
    type: "physicians",
    attributes: {
      name: "Morrison, Christopher (Primary)",
      normalized_name: "morrison, christopher (primary)",
      coded_name: "Morrison, Christopher (Primary)",
    },
  },
  {
    id: "935",
    type: "physicians",
    attributes: {
      name: "GREEN, DANIEL ALLEN (M.D.)",
      normalized_name: "green, daniel allen (m.d.) [10009245]",
      coded_name: "GREEN, DANIEL ALLEN (M.D.) [10009245]",
    },
  },
  {
    id: "2478",
    type: "physicians",
    attributes: {
      name: "LEE, ERNEST ENOCH (M.D.)",
      normalized_name: "lee, ernest enoch (m.d.) [183794]",
      coded_name: "LEE, ERNEST ENOCH (M.D.) [183794]",
    },
  },
  {
    id: "7142",
    type: "physicians",
    attributes: {
      name: "PARK, HAYOUNG (M.D.)",
      normalized_name: "park, hayoung (m.d.) [718203]",
      coded_name: "PARK, HAYOUNG (M.D.) [718203]",
    },
  },
  {
    id: "1371",
    type: "physicians",
    attributes: {
      name: "NG, ROWONA ANNA (M.D.)",
      normalized_name: "ng, rowona anna (m.d.) [10036199]",
      coded_name: "NG, ROWONA ANNA (M.D.) [10036199]",
    },
  },
  {
    id: "6281",
    type: "physicians",
    attributes: {
      name: "HOLLANDSWORTH, HANNAH MICHELLE (M.D.)",
      normalized_name: "hollandsworth, hannah michelle (m.d.) [1012714]",
      coded_name: "HOLLANDSWORTH, HANNAH MICHELLE (M.D.) [1012714]",
    },
  },
  {
    id: "11590",
    type: "physicians",
    attributes: {
      name: "Tobert, Daniel G",
      normalized_name: "tobert, daniel g",
      coded_name: "Tobert, Daniel G",
    },
  },
  {
    id: "7011",
    type: "physicians",
    attributes: {
      name: "Brown, Tommy A",
      normalized_name: "brown, tommy a",
      coded_name: "Brown, Tommy A",
    },
  },
  {
    id: "2584",
    type: "physicians",
    attributes: {
      name: "CHANG, ALLEN (M.D.)",
      normalized_name: "chang, allen (m.d.) [165468]",
      coded_name: "CHANG, ALLEN (M.D.) [165468]",
    },
  },
  {
    id: "10867",
    type: "physicians",
    attributes: {
      name: "Ringel, Barak",
      normalized_name: "ringel, barak",
      coded_name: "Ringel, Barak",
    },
  },
  {
    id: "307",
    type: "physicians",
    attributes: {
      name: "NAKANO, KATHY JEAN MARIKO (M.D.)",
      normalized_name: "nakano, kathy jean mariko (m.d.) [10027265]",
      coded_name: "NAKANO, KATHY JEAN MARIKO (M.D.) [10027265]",
    },
  },
  {
    id: "76",
    type: "physicians",
    attributes: {
      name: "CHANG, DENNIS FAN (M.D.)",
      normalized_name: "chang, dennis fan (m.d.) [394893]",
      coded_name: "CHANG, DENNIS FAN (M.D.) [394893]",
    },
  },
  {
    id: "2487",
    type: "physicians",
    attributes: {
      name: "WINOVITCH, KIM CHRISTIE (M.D.)",
      normalized_name: "winovitch, kim christie (m.d.) [10064019]",
      coded_name: "WINOVITCH, KIM CHRISTIE (M.D.) [10064019]",
    },
  },
  {
    id: "5123",
    type: "physicians",
    attributes: {
      name: "Stephen, Antonia Elizabeth (Primary)",
      normalized_name: "stephen, antonia elizabeth (primary)",
      coded_name: "Stephen, Antonia Elizabeth (Primary)",
    },
  },
  {
    id: "3956",
    type: "physicians",
    attributes: {
      name: "KOWALLIS, DAVID WRIGHT (P.A.)",
      normalized_name: "kowallis, david wright (p.a.) [475136]",
      coded_name: "KOWALLIS, DAVID WRIGHT (P.A.) [475136]",
    },
  },
  {
    id: "2475",
    type: "physicians",
    attributes: {
      name: "NEUMAN, STANLEY (M.D.)",
      normalized_name: "neuman, stanley (m.d.) [10085342]",
      coded_name: "NEUMAN, STANLEY (M.D.) [10085342]",
    },
  },
  {
    id: "2563",
    type: "physicians",
    attributes: {
      name: "STEPHAN, AUDREY FALLON (M.D.)",
      normalized_name: "stephan, audrey fallon (m.d.) [24145]",
      coded_name: "STEPHAN, AUDREY FALLON (M.D.) [24145]",
    },
  },
  {
    id: "2024",
    type: "physicians",
    attributes: {
      name: "SCHARNWEBER, RUDI FREDRIK (M.D.)",
      normalized_name: "scharnweber, rudi fredrik (m.d.) [611780]",
      coded_name: "SCHARNWEBER, RUDI FREDRIK (M.D.) [611780]",
    },
  },
  {
    id: "9787",
    type: "physicians",
    attributes: {
      name: "OLIVER-ALLEN, HUNTER S",
      normalized_name: "oliver-allen, hunter s [109759]",
      coded_name: "OLIVER-ALLEN, HUNTER S [109759]",
    },
  },
  {
    id: "1313",
    type: "physicians",
    attributes: {
      name: "GUTTA, RAVI (M.D.)",
      normalized_name: "gutta, ravi (m.d.) [10010631]",
      coded_name: "GUTTA, RAVI (M.D.) [10010631]",
    },
  },
  {
    id: "9976",
    type: "physicians",
    attributes: {
      name: "Williamson, Theresa L",
      normalized_name: "williamson, theresa l",
      coded_name: "Williamson, Theresa L",
    },
  },
  {
    id: "11434",
    type: "physicians",
    attributes: {
      name: "Freeman, Adriana V (Primary - 2)",
      normalized_name: "freeman, adriana v (primary - 2)",
      coded_name: "Freeman, Adriana V (Primary - 2)",
    },
  },
  {
    id: "9280",
    type: "physicians",
    attributes: {
      name: "MCMILLAN, ROBERT RYAN (M.D.)",
      normalized_name: "mcmillan, robert ryan (m.d.) [760256]",
      coded_name: "MCMILLAN, ROBERT RYAN (M.D.) [760256]",
    },
  },
  {
    id: "7100",
    type: "physicians",
    attributes: {
      name: "GREENE, ALEXANDRA (M.D.)",
      normalized_name: "greene, alexandra (m.d.) [788587]",
      coded_name: "GREENE, ALEXANDRA (M.D.) [788587]",
    },
  },
  {
    id: "10868",
    type: "physicians",
    attributes: {
      name: "SIDDIQI, TAUSEEF AFAQ (M.D.)",
      normalized_name: "siddiqi, tauseef afaq (m.d.) [438192]",
      coded_name: "SIDDIQI, TAUSEEF AFAQ (M.D.) [438192]",
    },
  },
  {
    id: "10502",
    type: "physicians",
    attributes: {
      name: "SLOMINSKI, COREY L",
      normalized_name: "slominski, corey l [13149]",
      coded_name: "SLOMINSKI, COREY L [13149]",
    },
  },
  {
    id: "5302",
    type: "physicians",
    attributes: {
      name: "Lahey, Edward T (Primary - 1)",
      normalized_name: "lahey, edward t (primary - 1)",
      coded_name: "Lahey, Edward T (Primary - 1)",
    },
  },
  {
    id: "8282",
    type: "physicians",
    attributes: {
      name: "MANISTA, ANDREW P",
      normalized_name: "manista, andrew p [11373]",
      coded_name: "MANISTA, ANDREW P [11373]",
    },
  },
  {
    id: "9616",
    type: "physicians",
    attributes: {
      name: "HADLEY, SCOTT R",
      normalized_name: "hadley, scott r [45238]",
      coded_name: "HADLEY, SCOTT R [45238]",
    },
  },
  {
    id: "3698",
    type: "physicians",
    attributes: {
      name: "LIOU, ROBERT DAR-TEH (M.D.)",
      normalized_name: "liou, robert dar-teh (m.d.) [224821]",
      coded_name: "LIOU, ROBERT DAR-TEH (M.D.) [224821]",
    },
  },
  {
    id: "11836",
    type: "physicians",
    attributes: {
      name: "MARTIRES, JOANNE S",
      normalized_name: "martires, joanne s [66210]",
      coded_name: "MARTIRES, JOANNE S [66210]",
    },
  },
  {
    id: "3641",
    type: "physicians",
    attributes: {
      name: "Fernandez-Del Castillo, Carlos",
      normalized_name: "fernandez-del castillo, carlos",
      coded_name: "Fernandez-Del Castillo, Carlos",
    },
  },
  {
    id: "1465",
    type: "physicians",
    attributes: {
      name: "PFEFFER, THOMAS ALAN (M.D.)",
      normalized_name: "pfeffer, thomas alan (m.d.) [10016179]",
      coded_name: "PFEFFER, THOMAS ALAN (M.D.) [10016179]",
    },
  },
  {
    id: "1719",
    type: "physicians",
    attributes: {
      name: "COOPER, SCOTT THOMAS (DPM)",
      normalized_name: "cooper, scott thomas (dpm) [10028655]",
      coded_name: "COOPER, SCOTT THOMAS (DPM) [10028655]",
    },
  },
  {
    id: "3687",
    type: "physicians",
    attributes: {
      name: "MAZZARESE, PETER MATTHEW (P.A.)",
      normalized_name: "mazzarese, peter matthew (p.a.) [219068]",
      coded_name: "MAZZARESE, PETER MATTHEW (P.A.) [219068]",
    },
  },
  {
    id: "952",
    type: "physicians",
    attributes: {
      name: "BUI, DAVID KHOA ANH (M.D.)",
      normalized_name: "bui, david khoa anh (m.d.) [516657]",
      coded_name: "BUI, DAVID KHOA ANH (M.D.) [516657]",
    },
  },
  {
    id: "1572",
    type: "physicians",
    attributes: {
      name: "LOERA, GLADYS ESTELA (M.D.)",
      normalized_name: "loera, gladys estela (m.d.) [10027011]",
      coded_name: "LOERA, GLADYS ESTELA (M.D.) [10027011]",
    },
  },
  {
    id: "2357",
    type: "physicians",
    attributes: {
      name: "ALFARO QUEZADA, JOSE (M.D.)",
      normalized_name: "alfaro quezada, jose (m.d.) [746651]",
      coded_name: "ALFARO QUEZADA, JOSE (M.D.) [746651]",
    },
  },
  {
    id: "3291",
    type: "physicians",
    attributes: {
      name: "Hardenbrook, Mitchell A",
      normalized_name: "hardenbrook, mitchell a",
      coded_name: "Hardenbrook, Mitchell A",
    },
  },
  {
    id: "11933",
    type: "physicians",
    attributes: {
      name: "RUBACH, BRYAN W",
      normalized_name: "rubach, bryan w [1841]",
      coded_name: "RUBACH, BRYAN W [1841]",
    },
  },
  {
    id: "7609",
    type: "physicians",
    attributes: {
      name: "SEKHRI, VISHAL, MD",
      normalized_name: "sekhri, vishal, md [12937]",
      coded_name: "SEKHRI, VISHAL, MD [12937]",
    },
  },
  {
    id: "7303",
    type: "physicians",
    attributes: {
      name: "VAN DYKE, RUFUS O, MD",
      normalized_name: "van dyke, rufus o, md [79761]",
      coded_name: "VAN DYKE, RUFUS O, MD [79761]",
    },
  },
  {
    id: "4383",
    type: "physicians",
    attributes: {
      name: "Kelly, Sean P",
      normalized_name: "kelly, sean p",
      coded_name: "Kelly, Sean P",
    },
  },
  {
    id: "5431",
    type: "physicians",
    attributes: {
      name: "Feldman, Adam Scott (Primary - 1)",
      normalized_name: "feldman, adam scott (primary - 1)",
      coded_name: "Feldman, Adam Scott (Primary - 1)",
    },
  },
  {
    id: "11797",
    type: "physicians",
    attributes: {
      name: "ALMASGANJ, FARNAZ",
      normalized_name: "almasganj, farnaz [64844]",
      coded_name: "ALMASGANJ, FARNAZ [64844]",
    },
  },
  {
    id: "758",
    type: "physicians",
    attributes: {
      name: "HENDRY, WILLIAM PAUL (P.A.)",
      normalized_name: "hendry, william paul (p.a.) [10102276]",
      coded_name: "HENDRY, WILLIAM PAUL (P.A.) [10102276]",
    },
  },
  {
    id: "6063",
    type: "physicians",
    attributes: {
      name: "Kaiser, Philip B",
      normalized_name: "kaiser, philip b",
      coded_name: "Kaiser, Philip B",
    },
  },
  {
    id: "7982",
    type: "physicians",
    attributes: {
      name: "KUNDE, KEVIN J",
      normalized_name: "kunde, kevin j [89801]",
      coded_name: "KUNDE, KEVIN J [89801]",
    },
  },
  {
    id: "3370",
    type: "physicians",
    attributes: {
      name: "Freiberg, Andrew A",
      normalized_name: "freiberg, andrew a",
      coded_name: "Freiberg, Andrew A",
    },
  },
  {
    id: "5345",
    type: "physicians",
    attributes: {
      name: "Mohebali, Jahan (Primary - 2)",
      normalized_name: "mohebali, jahan (primary - 2)",
      coded_name: "Mohebali, Jahan (Primary - 2)",
    },
  },
  {
    id: "4200",
    type: "physicians",
    attributes: {
      name: "HOU, RANDY (M.D.)",
      normalized_name: "hou, randy (m.d.) [359673]",
      coded_name: "HOU, RANDY (M.D.) [359673]",
    },
  },
  {
    id: "1044",
    type: "physicians",
    attributes: {
      name: "OMALEV, THOMAS B (M.D.)",
      normalized_name: "omalev, thomas b (m.d.) [10059239]",
      coded_name: "OMALEV, THOMAS B (M.D.) [10059239]",
    },
  },
  {
    id: "7760",
    type: "physicians",
    attributes: {
      name: "RUTTER, CAROLYN J, MD",
      normalized_name: "rutter, carolyn j, md [7571]",
      coded_name: "RUTTER, CAROLYN J, MD [7571]",
    },
  },
  {
    id: "2362",
    type: "physicians",
    attributes: {
      name: "LEE, ELIZABETH MYUNG-HI (M.D.)",
      normalized_name: "lee, elizabeth myung-hi (m.d.) [5089]",
      coded_name: "LEE, ELIZABETH MYUNG-HI (M.D.) [5089]",
    },
  },
  {
    id: "1822",
    type: "physicians",
    attributes: {
      name: "BARTZ, STEPHEN HUGH (M.D.)",
      normalized_name: "bartz, stephen hugh (m.d.) [485]",
      coded_name: "BARTZ, STEPHEN HUGH (M.D.) [485]",
    },
  },
  {
    id: "7280",
    type: "physicians",
    attributes: {
      name: "BUTLIN, ASHLEY J, MD",
      normalized_name: "butlin, ashley j, md [43970]",
      coded_name: "BUTLIN, ASHLEY J, MD [43970]",
    },
  },
  {
    id: "7097",
    type: "physicians",
    attributes: {
      name: "Choi, Bryan D",
      normalized_name: "choi, bryan d",
      coded_name: "Choi, Bryan D",
    },
  },
  {
    id: "7852",
    type: "physicians",
    attributes: {
      name: "SCHOEFF, STEPHEN, MD",
      normalized_name: "schoeff, stephen, md [88908]",
      coded_name: "SCHOEFF, STEPHEN, MD [88908]",
    },
  },
  {
    id: "1051",
    type: "physicians",
    attributes: {
      name: "CLAPPER, MARK FREDERICK (M.D.)",
      normalized_name: "clapper, mark frederick (m.d.) [10032876]",
      coded_name: "CLAPPER, MARK FREDERICK (M.D.) [10032876]",
    },
  },
  {
    id: "10979",
    type: "physicians",
    attributes: {
      name: "KESHAVARZIAN, ALI",
      normalized_name: "keshavarzian, ali [10640]",
      coded_name: "KESHAVARZIAN, ALI [10640]",
    },
  },
];
