import React, { createContext, useContext, useEffect, useState } from "react";
import {
  DATA_TABLES_PATH,
  EQUIPMENT_MASTER_FALLBACKS_PATH,
  EQUIPMENT_MASTERS_PATH,
} from "../../utils/capi";
import { RequestContext } from "../../utils/capiClient";

export const EquipmentContext = createContext({
  equipmentMasters: {},
  equipmentMasterFallbacks: {},
  inventoryShareability: null,
  isLoading: false,
});

export const EquipmentProvider = ({ children }) => {
  const {
    request,
    params: { facilityId, healthSystemId },
  } = useContext(RequestContext);

  const [state, setState] = useState({
    equipmentMasters: {},
    equipmentMasterFallbacks: {}, // this is loaded once and cannot be updated
    inventoryShareability: null,
    isLoading: false,
  });

  // Usually, the response data is transformed into
  // object with label and id. However, the child
  // component has a custom display, so options
  // will not be preprocessed beforehand.
  const getEquipmentMasters = async () => {
    return await request
      .get(EQUIPMENT_MASTERS_PATH, {
        params: { facilityId, healthSystemId },
      })
      .then(({ data }) => ({
        equipmentMasters: {
          isLoaded: true,
          options: data.data.map((eqm) => ({
            data: eqm.attributes,
            label: eqm.attributes.name,
            value: parseInt(eqm.id),
          })),
        },
      }));
  };

  const getEquipmentMasterFallbacks = async () => {
    return await request
      .get(EQUIPMENT_MASTER_FALLBACKS_PATH, {
        params: { facilityId, healthSystemId },
      })
      .then(({ data }) => ({
        equipmentMasterFallbacks:
          data?.data?.reduce((memo, fb) => {
            const {
              fallback_equipment_master_id,
              position,
              primary_equipment_master_id,
              name,
            } = fb.attributes;

            if (!memo[primary_equipment_master_id])
              memo[primary_equipment_master_id] = [];

            memo[primary_equipment_master_id].splice(position, 0, {
              equipmentMasterId: fallback_equipment_master_id,
              id: fb.id,
              name,
              position,
              url: fb.links.self,
            });

            return memo;
          }, {}) ?? {},
      }));
  };

  const getInventoryShareability = async () => {
    return await request
      .get(DATA_TABLES_PATH, {
        params: {
          klass: "InventoryShareability",
          filterBy: {
            health_system_id: healthSystemId,
            status: "not-shareable",
          },
        },
      })
      .then(({ data }) => ({ inventoryShareability: data.data }));
  };

  // Load Equipment Master options
  useEffect(() => {
    if (!request || !healthSystemId) return;

    const requests = [getEquipmentMasters()];
    if (!state.inventoryShareability) requests.push(getInventoryShareability());
    if (facilityId) requests.push(getEquipmentMasterFallbacks());

    setState({ ...state, isLoading: true });
    Promise.all(requests).then((resps) => {
      const newState = resps.reduce(
        (updatedState, resp) => {
          Object.assign(updatedState, resp, { isLoading: false });
          return updatedState;
        },
        { ...state }
      );

      setState(newState);
    });
  }, [request, facilityId, healthSystemId]);

  return (
    <EquipmentContext.Provider value={state}>
      {children}
    </EquipmentContext.Provider>
  );
};
