import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select-3";
import _ from "lodash";

import { USERS_PATH } from "../../../utils/capi";
import { RequestContext } from "../../../utils/capiClient";
import { findSelectedOption } from "../../../utils/finders";

import {
  QUICK_EDIT_SELECT_STYLES,
  StyledDisplay,
  StyledForm,
  StyledNotSpecified,
} from "./styled";

export default function AuthorSelect({
  createdById,
  createdName,
  handleChange,
  isDisabled,
  isEditable,
}) {
  const { options, params, request } = useContext(RequestContext);
  const [state, setState] = useState({
    isLoaded: null,
    roleOptions: [],
    selectOptions: [],
    showForm: false,
  });
  const idRef = useRef(null);

  const handleCancel = () => {
    setState({ ...state, showForm: false });

    idRef.current.setValue(
      findSelectedOption(createdById, state.selectOptions)
    );
  };

  const handleSubmit = () => {
    let id = null;

    const selectedId = idRef?.current?.getValue()[0];

    handleChange(selectedId);

    setState({ ...state, showForm: false });
  };

  const handleTextClick = () => {
    if (state.showForm) {
      idRef.current.setValue(
        findSelectedOption(createdById, state.selectOptions)
      );
    }

    setState({ ...state, showForm: !state.showForm });
  };

  // Load once on supplied request
  useEffect(() => {
    if (!isEditable || !request) return;

    request
      .get(USERS_PATH, {
        params: {
          healthSystemIds: params.healthSystemId,
          roles: params.authorRoles,
        },
      })
      .then(({ data }) => {
        const resp = data?.data?.length
          ? data.data.map((opt) => ({
              label: opt.attributes.name,
              value: opt.id,
            }))
          : [];

        setState({
          ...state,
          roleOptions: resp,
        });
      });
  }, [!!request, isEditable]); // check for presence change

  useEffect(() => {
    if (isEditable && state.selectOptions?.length && createdById) {
      idRef.current?.setValue(
        findSelectedOption(createdById, state.selectOptions)
      );
    }
  }, [state.selectOptions, createdById, createdName]);

  useEffect(() => {
    const combinedOptions = _.sortBy(
      [...state?.roleOptions, ...options?.admins],
      "label"
    );

    setState({
      ...state,
      isLoaded: true,
      selectOptions: combinedOptions,
    });
  }, [options?.admins?.length, state.roleOptions?.length]);

  const { isLoaded, selectOptions, showForm } = state;
  let name = null;
  const isClickable = !isDisabled && isEditable;

  if (isEditable && selectOptions?.length && createdById) {
    const selectedOption = findSelectedOption(createdById, selectOptions);
    name = selectedOption?.label || null;
  }

  return (
    <div className="author">
      <span className="key field-title">Author:</span>
      <span className="field-content">
        <span>
          {
            // createdName order matters
          }
          <StyledDisplay id="author-editable-display">
            {isClickable ? (
              <ClickableText
                createdById={createdById}
                createdName={name || createdName}
                handleCancel={handleCancel}
                handleClick={!isDisabled && handleTextClick}
                handleSubmit={handleSubmit}
                idRef={idRef}
                isDisabled={isDisabled}
                isLoaded={isLoaded}
                options={selectOptions}
                showForm={showForm}
              />
            ) : (
              <CreatedName createdName={createdName} />
            )}
          </StyledDisplay>
        </span>
      </span>
    </div>
  );
}

const ClickableText = ({
  createdById,
  createdName,
  handleCancel,
  handleClick,
  handleSubmit,
  idRef,
  isDisabled,
  isLoaded,
  options,
  showForm,
}) => {
  return (
    <div>
      <div className="dynamic" onClick={handleClick}>
        <CreatedName createdName={createdName} />
        <i className="icon icon-edit" />
      </div>
      <StyledForm
        className="fade in editable-container hintarrow arrow-right-middle"
        id="author-editable-form"
        style={{
          display: `${showForm ? "block" : "none"}`,
        }}
      >
        <h3 className="popover-title">Select Author</h3>
        <div className="popover-content">
          <div className="editable-input">
            <Select
              className="input-block-level"
              classNamePrefix="select"
              isDisabled={isDisabled || !isLoaded}
              key={`author-${createdById}`}
              options={options}
              ref={idRef}
              styles={QUICK_EDIT_SELECT_STYLES}
            />
          </div>
          <div className="editable-buttons">
            <div
              className="btn btn-primary editable-submit"
              id="author-submit"
              onClick={handleSubmit}
            >
              <i className="icon-ok icon-white" />
            </div>
            <div
              className="btn editable-cancel"
              id="author-cancel"
              onClick={handleCancel}
            >
              <i className="icon-remove" />
            </div>
          </div>
        </div>
      </StyledForm>
    </div>
  );
};

const CreatedName = ({ createdName }) => {
  return (
    <span>
      {createdName || <StyledNotSpecified>Not specified</StyledNotSpecified>}
    </span>
  );
};

AuthorSelect.propTypes = {
  createdById: PropTypes.any,
  createdName: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
};
