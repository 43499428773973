import React from "react";

import Name from "./Name";

export default {
  argTypes: { handleChange: { action: "Input value changed" } },
  component: Name,
  title: "Equipment Preference/Form/Name",
};

const Template = (args) => <Name {...args} />;

export const Default = Template.bind({});
Default.args = {
  handleChange: () => {},
  isDisabled: false,
  isEditable: true,
  value: null,
};

export const Disabled = Template.bind({});
Disabled.args = {
  ...Default.args,
  isDisabled: true,
};

export const NotEditable = Template.bind({});
NotEditable.args = {
  ...Default.args,
  isEditable: false,
};

export const Value = Template.bind({});
Value.args = {
  ...Default.args,
  value: "Foobar",
};

export const ValueDisabled = Template.bind({});
ValueDisabled.args = {
  ...Value.args,
  isDisabled: true,
};

export const ValueNotEditable = Template.bind({});
ValueNotEditable.args = {
  ...Value.args,
  isEditable: false,
};

export const ValueDisabledNotEditable = Template.bind({});
ValueDisabledNotEditable.args = {
  ...ValueDisabled.args,
  isEditable: false,
};
