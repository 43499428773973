export const openInCurrentTab = (url) => {
  window.open(`${window.location.origin}${url}`, "_self");
};

// Security: href vs. js for opening links
// https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

// TODO consolodate alerts
export const alertSuccess = (headerMsg = "", bodyMsg = "") => {
  Cohealo.Growl(bodyMsg, {
    header: headerMsg,
    status: "success",
    icon: "ok",
  });
};

export const alertFailure = (headerMsg = "", bodyMsg = "") => {
  Cohealo.Growl(bodyMsg, {
    header: headerMsg,
    status: "danger",
    icon: "remove",
  });
};
