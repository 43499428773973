import React, { useEffect } from "react";

import usePrevious, { isEqual } from "../../../utils/usePrevious";
import usePreviewMatches from "./usePreviewMatches";
import { PROCEDURES_PATH } from "../../../utils/capi";
import {
  Content,
  MIN_CONTAINER_HEIGHT,
  PlaceholderIcon,
  PreviewContainer,
  Result,
  Results,
  Title,
} from "./styles";

export default function Preview({ className, selected }) {
  const [{ data, isLoading }, { setParams }] = usePreviewMatches(
    false,
    PROCEDURES_PATH
  );
  const tokens = (selected || []).sort().join("|");
  const prevTokens = usePrevious(tokens);

  useEffect(() => {
    setPreviewHeight(selected?.length ?? 0);

    // Don't request if data has not requested (is null)
    // and there are no tokens
    if (!data && !tokens.length) return;

    // Don't request if prev request tokens are the same
    if (isEqual(tokens, prevTokens)) return;

    setParams(tokens.length ? { tokens } : null);
  }, [tokens?.length > 0, isEqual(tokens, prevTokens)]);

  const hasNoMatches = !isLoading && data;
  const hasTokenLength = tokens?.length > 0;

  return (
    <PreviewContainer className={className}>
      <Title>
        {hasNoMatches && (
          <div id="title-content">
            <p>{data.length} total matches:</p>
          </div>
        )}
        {isLoading && <Loader />}
      </Title>
      <Content>
        <Results
          id="procedure-pattern-results"
          showPlaceholder={!hasTokenLength}
        >
          {hasTokenLength ? (
            (data?.length ? data : ["No matches found"]).map(
              (result, index) => <Result key={index}>{result}</Result>
            )
          ) : (
            <div>
              <p>
                <PlaceholderIcon className="sprite-icon sprite-eye-open-m-grey-light" />
                All matching procedures will populate here
              </p>
            </div>
          )}
        </Results>
      </Content>
    </PreviewContainer>
  );
}

const setPreviewHeight = (numFields) => {
  const resultsEl = document.getElementById("procedure-pattern-results");

  // heading, add link height, padding/margin, input margins: 18 + 14 + 50 + 34
  let height = numFields * 38 + 106;
  if (height < MIN_CONTAINER_HEIGHT) height = MIN_CONTAINER_HEIGHT;

  if (height == resultsEl.offsetHeight) return;

  resultsEl.style.height = `${height}px`;
};

const Loader = () => (
  <div id="procedure-preview-loader">
    <img
      alt="ajax loading"
      src="/images/loading-mini.gif"
      width="16"
      height="16"
    />
    Loading matches...
  </div>
);

// TODO: May not need to show legacy anymore
// <div id="legacy-procedures-view">
//   <i className="sprite-icon sprite-warning-m-black"></i>
//   <div>
//     This is a legacy preference, so matching procedures are limited to
//     the list on the left.
//   </div>
//   <div>
//     <b>
//       Modify the procedures on the left to update this preference.
//     </b>
//   </div>
// </div>
