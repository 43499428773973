import React from "react";
import EquipmentSelect from "../../../../../forms/selects/EquipmentSelect/EquipmentSelect";
import { components } from "react-select-3";
import { InventoryLabels, renderInventoryCodes } from "../utils";

const PLACEHOLDER_DEFAULT = "Please select inventory";
const PLACEHOLDER_ERROR =
  "Error retrieving inventory options. Please try re-opening pill or refreshing the page.";
const PLACEHOLDER_LOADING = "Finding inventories...";
const PLACEHOLDER_NO_MASTER_SEL =
  "Options will populate after equipment selection";
const PLACEHOLDER_NO_INVENT_SEL =
  "There are no inventories found for this equipment. Please select another equipment.";

export default function InventoryItemSelect({
  handleChange,
  isDisabled = false,
  isError = false,
  isLoading = false,
  options,
  value,
}) {
  const placeholder = isError
    ? PLACEHOLDER_ERROR
    : isLoading
    ? PLACEHOLDER_LOADING
    : isDisabled
    ? PLACEHOLDER_NO_MASTER_SEL
    : options.length
    ? PLACEHOLDER_DEFAULT
    : PLACEHOLDER_NO_INVENT_SEL;

  let optionValue;
  if (value?.id) {
    const { id, ...rest } = value;

    optionValue = options?.find((opt) => opt.value == value.id) ?? {
      data: rest,
      label: "",
      value: value.id,
    };
  }
  optionValue = optionValue ?? options?.[0];

  const disabled = isDisabled || isLoading || !options.length;
  const klassName = "inventory-item-select";
  const name = `edit-${klassName}`;

  return (
    <div className="control-group clearfix">
      <div className="col-left">
        <label>Inventory:</label>
      </div>
      <div className="col-right">
        <EquipmentSelect
          isDisabled={disabled}
          components={{ Option }}
          className={klassName}
          key={name}
          name={name}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          renderContent={renderDataContent}
          value={optionValue}
        />
      </div>
    </div>
  );
}

const handleMouseDown = (e) => {
  const isUsageLabel = $(e.target).closest(".usage-labels").length;
  const isUnavailLabel = $(e.target).closest("#unavail[href]").length;

  if (isUsageLabel || isUnavailLabel) {
    e.stopPropagation();
    e.preventDefault();
  }
};

const renderDataContent = ({
  accessoryMasters,
  codes,
  collaborativelyPurchased,
  homeFacility,
  id,
  rentalCompany,
  status,
  type,
}) => (
  <div className="content" key={`content-${type}-${id}`}>
    <div className="source">
      <span className="source-location">{homeFacility?.name}</span>
      <span className="source-location-code">
        {` ${homeFacility?.siteCode || rentalCompany?.name}`}
      </span>
    </div>
    <div className="inventory-code">
      {renderInventoryCodes(codes)}
      <InventoryLabels
        isRental={type === "RentableInventory"}
        collaborativelyPurchased={collaborativelyPurchased}
        homeSiteCode={homeFacility?.siteCode}
        isRight
        status={status}
      />
    </div>
    <div className="inventory-meta">
      <strong>Accessories: </strong>
      <span className="accessories">
        {type === "RentableInventory" ? (
          <i>Must specify with rental company</i>
        ) : (
          accessoryMasters
            ?.map((am) => `${am.name} (${am.quantity})`)
            .join(", ") ?? "(0)"
        )}
      </span>
    </div>
  </div>
);

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const { data, ...restData } = props.data;
  const newProps = {
    ...props,
    data: restData,
    innerProps: rest,
  };
  return (
    <div onMouseDown={(e) => handleMouseDown(e)}>
      <components.Option {...newProps}>
        {renderDataContent(data)}
      </components.Option>
    </div>
  );
};
