import { act, renderHook } from "@testing-library/react";
import { useState } from "react";
import usePrevious, { isEqual } from "./usePrevious";

describe("isEqual", () => {
  test("it should do a strict comparison on primitive types", () => {
    expect(isEqual(1, "1")).toBe(false);
    expect(isEqual(1, 1)).toBe(true);
  });

  test("it should deep compare unsorted arrays of primitive types", () => {
    const a = [1, 2, 3, 4];
    const b = [2, 3, 4, 10000];
    const c = [4, 3, 2, 1];

    expect(isEqual(a, b)).toBe(false);
    expect(isEqual(a, c)).toBe(true);
  });

  test("it compares for exact array sizes", () => {
    const a = [1];
    const b = [1, 2];

    expect(isEqual(a, b)).toBe(false);
  });
});

const useExample = (value) => {
  const [state, setState] = useState(value);
  const prevState = usePrevious(state);

  return { prevState, state, setState: (newState) => setState(newState) };
};

// Testing custom hooks
describe("usePrevious", () => {
  test("it holds previous values", () => {
    const value = "foo";
    const nextValue = "bar";
    const futureValue = "moo";

    const { result } = renderHook(() => useExample(value));

    expect(result.current.prevState).toBe(undefined);
    expect(result.current.state).toBe(value);

    act(() => {
      result.current.setState(nextValue);
    });

    expect(result.current.prevState).toBe(value);
    expect(result.current.state).toBe(nextValue);

    act(() => {
      result.current.setState(futureValue);
    });

    expect(result.current.prevState).toBe(nextValue);
    expect(result.current.state).toBe(futureValue);
  });
});
